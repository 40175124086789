import React from "react";
import CustomTable from "src/components/CustomTable";
import { Fab, IconButton } from "@material-ui/core";
import { Delete, Edit, GetApp, RemoveRedEye } from "@material-ui/icons";

const TableWorkerInformation = ({ data, loading, setModal, setDel, setView }) => {
  const columns = [
    {
      title: "NOMBRE DEL DOCUMENTO",
      dataIndex: "detail",
      key: "detail",
      align: "center",
      sorter: true,
      filter: true,
    },
    {
      title: "FECHA DE SUBIDA",
      dataIndex: "date_upload",
      key: "date_upload",
      align: "center",
      sorter: true,
      filter: true,
    },
    {
      title: "TRABAJADORES",
      dataIndex: "workerList",
      key: "workerList",
      align: "center",
      sorter: false,
      filter: false,
      render: (value) => {
        let counter = 0;
        value.forEach((e) => e.status && counter++);
        return (
          <Fab
            variant="extended"
            color="inherit"
            size="small"
            onClick={() => setView({ open: true, data: value })}
          >
            <RemoveRedEye />
            VISTO POR {counter}/{value.length}
          </Fab>
        );
      },
    },
    {
      title: "ARCHIVO",
      dataIndex: "file",
      key: "file",
      align: "center",
      sorter: false,
      filter: false,
      render: (value) => (
        <IconButton color="primary" component="a" href={value} target="_blank">
          <GetApp />
        </IconButton>
      ),
    },
    {
      title: "ACCIONES",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      sorter: false,
      filter: false,
      render: (value, row) => (
        <React.Fragment>
          <IconButton color="primary" onClick={() => setModal({ open: true, data: row })}>
            <Edit />
          </IconButton>
          <IconButton color="secondary" onClick={() => setDel({ open: true, id: value })}>
            <Delete />
          </IconButton>
        </React.Fragment>
      ),
    },
  ];

  return <CustomTable columns={columns} data={data} loading={loading} pagination={false} />;
};

export default TableWorkerInformation;
