import React, { useEffect, useState } from "react"
import { notification, Table, Tag } from "antd"
import { ButtonGroup, Button, TextField, Avatar, IconButton, Tooltip } from "@material-ui/core"
import AvatarGroup from "@material-ui/lab/AvatarGroup"
import Highlighter from "react-highlight-words"
import ClearIcon from "@material-ui/icons/Clear"
import SearchIcon from "@material-ui/icons/Search"
import SearchOutlined from "@material-ui/icons/SearchOutlined"
import DeleteIcon from "@material-ui/icons/Delete"
import MoreIcon from "@material-ui/icons/More"
import moment from "moment"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import ModalTransferActivos from "../Equipos/ModalTransferEquipos"
import SwapHorizIcon from "@material-ui/icons/SwapHoriz"
import AddIcon from "@material-ui/icons/AddOutlined"
import PlusOneIcon from "@material-ui/icons/PlusOne"
import axios from "axios"
import ConfirmDelete from "../ModalConfirmDelete"
import { postEnableInventory } from "../../requests"
import { apiInventario } from "../../../../config/api.contable"
import DetailMuebles from "./DetailMuebles"
import TimelineIcon from "@material-ui/icons/Timeline"
import DetailTransferencias from "../DetailTransferencias"
import EditIcon from "@material-ui/icons/Edit"
import EditMuebles from "./EditMuebles"
import napformat from "../../../../config/napformat"
import { RemoveCircleOutline } from "@material-ui/icons"
import ModalConfirmDelete from "src/components/ModalConfirmDelete"
import ModalRemoveDocument from "../ModalRemoveDocument"
import CreateMemorandum from "../CreateMemorandum"
import {
  firstPendingAction,
  isHiddenByDelete,
  isHiddenByTransfer,
  selection,
} from "../../Memorandums"

const TableMuebles = (props) => {
  const {
    dataTable,
    reloadFunction,
    nameLocation,
    idLocation,
    setLoading,
    categories,
    loading,
    reloadCategories,
    onlyview = false,
    isActive,
    isCompact = false,
    memorandum = { open: false, type: "", data: [], keys: [] },
    setMemorandum,
  } = props

  const [searchedColumn, setsearchedColumn] = useState("")
  const [searchText, setsearchText] = useState("")
  const [dataMuebles, setDataMuebles] = useState([])
  const [modalDetail, setModalDetail] = useState({
    data: {},
    open: false,
  })
  const [detailTransferencias, setDetailTransferencias] = useState({
    idAsset: "",
    open: false,
  })
  const [openModalTransferMuebles, setOpenModalTransferMuebles] = useState({
    open: false,
  })
  const [modalConfirmDelete, setModalConfirmDelete] = useState({
    idMueble: "",
  })
  const [modalEditMuebles, setModalEditMuebles] = useState({
    data: {},
    open: false,
  })
  const [delRecord, setdelRecord] = useState({ open: false, id: null })
  const [imgModal, setImgModal] = useState({ open: false, id: null, img: null })

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <TextField
          size="small"
          variant="outlined"
          value={selectedKeys}
          placeholder="Buscar..."
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onKeyPress={(e) => e.key === "Enter" && handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => handleReset(clearFilters, selectedKeys, setSelectedKeys)}
          style={{ width: 90 }}
        >
          Limpiar <ClearIcon />
        </Button>{" "}
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
        >
          Buscar <SearchIcon />
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "" : "" }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()

    setsearchText(selectedKeys[0])
    setsearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters, selectedKeys, setSelectedKeys) => {
    clearFilters()
    setsearchText("")
    setSelectedKeys([""])
  }

  const enableInventory = async (idRow, record) => {
    setLoading(true)
    try {
      await postEnableInventory("fixed-asset", idRow)
      reloadFunction()
      notification["success"]({
        message: "¡ÉXITO!",
        description: `Se habilitó correctamente el registro ${record.correlative}.`,
      })
    } catch (error) {
      notification["error"]({
        message: "¡ERROR!",
        description: `No se pudo habilitar el registro ${record.correlative}.`,
      })
    } finally {
      setLoading(false)
    }
  }

  const uploadDocs = async (code, files) => {
    if (files.length > 0) {
      setLoading(true)
      let dataImages = new FormData()
      for (let i = 0; i < files.length; i++) {
        dataImages.append("docs", files[i])
      }
      await axios
        .post(`${apiInventario}/upload-doc-asset/${code}`, dataImages)
        .then((res) => {
          notification["success"]({
            message: "¡ÉXITO!",
            description: "El/Los documento(s) se guardaron correctamente.",
          })
          reloadFunction()
        })
        .catch((error) => {
          notification["error"]({
            message: "¡ERROR!",
            description: `No se pudieron agregar el/los documento(s). ${error}`,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const uploadImages = async (code, files) => {
    if (files.length > 0) {
      setLoading(true)
      let dataImages = new FormData()
      for (let i = 0; i < files.length; i++) {
        dataImages.append("images", files[i])
      }
      await axios
        .post(`${apiInventario}/upload-img-asset/${code}`, dataImages)
        .then((res) => {
          notification["success"]({
            message: "¡ÉXITO!",
            description: "La(s) imagen(es) se guardaron correctamente.",
          })
          reloadFunction()
        })
        .catch((error) => {
          notification["error"]({
            message: "¡ERROR!",
            description: `No se pudieron agregar la(s) imagen(es). ${error}`,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const columns = [
    {
      title: "ACCIONES",
      dataIndex: "_id",
      key: "_idAccion",
      width: 205,
      align: "center",
      render: (text, record) => (
        <ButtonGroup style={{ width: "100%", justifyContent: "center", background: "#fff" }}>
          {!onlyview && !isActive && !isCompact && (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => setdelRecord({ open: true, id: text })}
            >
              <Tooltip title="ELIMINAR">
                <DeleteIcon />
              </Tooltip>
            </Button>
          )}
          {!onlyview &&
            !isCompact &&
            (isActive ? (
              isHiddenByDelete(record) && (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => setModalConfirmDelete({ idMueble: text, open: true })}
                >
                  <Tooltip title="Deshabilitar">
                    <RemoveCircleOutline />
                  </Tooltip>
                </Button>
              )
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ backgroundColor: "#4caf50" }}
                onClick={() => enableInventory(text, record)}
              >
                <Tooltip title="Habilitar">
                  <PlusOneIcon />
                </Tooltip>
              </Button>
            ))}
          {!onlyview && isActive && !isCompact && (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => setModalEditMuebles({ data: record, open: true })}
            >
              <Tooltip title="Editar">
                <EditIcon />
              </Tooltip>
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() =>
              setModalDetail({
                data: record,
                open: true,
              })
            }
          >
            <Tooltip title="Detalles">
              <MoreIcon />
            </Tooltip>
          </Button>
          {!onlyview && isActive && !isCompact && isHiddenByTransfer(record) && (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => {
                setOpenModalTransferMuebles({ open: true })
                setDataMuebles(record)
              }}
            >
              <Tooltip title="Transferir a otra locación">
                <SwapHorizIcon />
              </Tooltip>
            </Button>
          )}
          {!isCompact && (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => setDetailTransferencias({ idAsset: text, open: true })}
            >
              <Tooltip title="Historial de transferencia">
                <TimelineIcon />
              </Tooltip>
            </Button>
          )}
        </ButtonGroup>
      ),
    },
    {
      title: "CORRELATIVO",
      dataIndex: "correlative",
      key: "correlative",
      width: 200,
      align: "center",
      sorter: (a, b) => {
        if (a.correlative > b.correlative) return 1
        if (a.correlative < b.correlative) return -1
      },
      ...getColumnSearchProps("correlative"),
      render: (value, record) => (
        <>
          {value}
          <br />
          {((record.memoInfo?.delete || record.memoInfo?.transfer) && record.memoInfo?.memorandum?.active) && (
            <Tag style={{ background: "#FFC107", color: "#fff", borderColor: "#FFC107" }}>
              {`${record.memoInfo?.delete ? "ELIMINACIÓN" : ""} 
                ${record.memoInfo?.delete ? (record.memoInfo?.transfer ? "Y" : "") : ""} 
                ${record.memoInfo?.transfer ? "TRANSFERENCIA" : ""}`}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "CATEGORÍA DEL BIEN",
      dataIndex: "_id",
      key: "_id",
      width: 200,
      align: "center",
      render: (a, b) => (
        <span>
          {b.category.name} <sub>{b.category.account_number}</sub>
        </span>
      ),
    },
    {
      title: "SUBCATEGORÍA",
      dataIndex: "_id",
      key: "_idCat",
      width: 200,
      align: "center",
      render: (a, b) => b.subcategory?.name ?? "",
    },
    {
      title: "DESCRIPCIÓN",
      dataIndex: "description",
      key: "description",
      align: "center",
      width: 250,
      ...getColumnSearchProps("description"),
      sorter: (a, b) => {
        if (a.description > b.description) return 1
        if (a.description < b.description) return -1
      },
      render: (text, all, index) => <span>{text}</span>,
    },
    {
      title: "VALOR UNITARIO",
      dataIndex: "unit_value",
      key: "unit_value",
      width: 175,
      align: "center",
      ...getColumnSearchProps("unit_value"),
      sorter: (a, b) => {
        if (a.unit_value > b.unit_value) return 1
        if (a.unit_value < b.unit_value) return -1
      },
      render: (text, record, index) => (
        <span>
          <sup>S/</sup>
          {napformat(text)}
        </span>
      ),
    },
    {
      title: "FECHA ADQUISIÓN",
      dataIndex: "acquisition_date",
      key: "acquisition_date",
      width: 150,
      align: "center",
      sorter: (a, b) => {
        if (a.acquisition_date > b.acquisition_date) return 1
        if (a.acquisition_date < b.acquisition_date) return -1
      },
      render: (text) => moment(text).utc(false).format("DD/MM/YYYY"),
    },
    {
      title: "DIMENSIONES",
      children: [
        {
          title: "ALTURA",
          dataIndex: "height",
          key: "height",
          width: 150,
          align: "center",
          sorter: (a, b) => {
            if (a.height > b.height) return 1
            if (a.height < b.height) return -1
          },
          render: (text, all, index) => text && <span>{napformat(text)}cm</span>,
        },
        {
          title: "LONGITUD",
          dataIndex: "length",
          key: "length",
          width: 150,
          align: "center",
          sorter: (a, b) => {
            if (a.length > b.length) return 1
            if (a.length < b.length) return -1
          },
          render: (text, all, index) => text && <span>{napformat(text)}cm</span>,
        },
        {
          title: "PROFUNDIDAD",
          dataIndex: "depth",
          key: "depth",
          width: 150,
          align: "center",
          sorter: (a, b) => {
            if (a.depth > b.depth) return 1
            if (a.depth < b.depth) return -1
          },
          render: (text, all, index) => text && <span>{napformat(text)}cm</span>,
        },
      ],
    },
    {
      title: "MODELO",
      dataIndex: "model",
      key: "model",
      width: 150,
      align: "center",
      ...getColumnSearchProps("model"),
      sorter: (a, b) => {
        if (a.model > b.model) return 1
        if (a.model < b.model) return -1
      },
    },
    {
      title: "MARCA",
      dataIndex: "trademark",
      key: "trademark",
      width: 150,
      align: "center",
      ...getColumnSearchProps("trademark"),
      sorter: (a, b) => {
        if (a.trademark > b.trademark) return 1
        if (a.trademark < b.trademark) return -1
      },
    },
    {
      title: "HABILITADO",
      dataIndex: "active",
      key: "active",
      width: 125,
      // ...getColumnSearchProps( "active" ),
      align: "center",
      sorter: (a, b) => {
        if (a.active > b.active) return 1
        if (a.active < b.active) return -1
      },
      render: (text, record, index) =>
        text === true ? (
          <FiberManualRecordIcon style={{ color: "#4caf50" }} />
        ) : (
          <FiberManualRecordIcon style={{ color: "#e53935" }} />
        ),
    },
    {
      title: "ESTADO",
      dataIndex: "status",
      key: "status",
      width: 125,
      ...getColumnSearchProps("status"),
      align: "center",
      sorter: (a, b) => {
        if (a.status > b.status) return 1
        if (a.status < b.status) return -1
      },
    },
    {
      title: "IMAGENES",
      dataIndex: "images",
      key: "images",
      align: "center",
      width: 175,
      render: (text, record, index) => (
        <AvatarGroup max={4} style={{ paddingLeft: 5 }}>
          {onlyview === false && (
            <IconButton
              style={{
                padding: 0,
                height: 40,
                width: 40,
                backgroundColor: "#0688d1",
                color: "white",
              }}
              component="label"
            >
              <AddIcon />
              <input
                style={{ display: "none" }}
                accept="image/*"
                type="file"
                onChange={(event) => uploadImages(record._id, event.target.files)}
                multiple
              />
            </IconButton>
          )}
          {text.slice(0, 3).map((ev, index) => (
            <Avatar
              src={ev}
              onClick={() =>
                setImgModal({
                  open: true,
                  img: index,
                  id: record._id,
                })
              }
              style={{
                cursor: "pointer",
                border: "1px solid black",
              }}
            />
          ))}
          {text.length > 3 && <Avatar>+{text.length - 3}</Avatar>}
        </AvatarGroup>
      ),
    },
    {
      title: "DOCUMENTOS SUSTENTATORIOS",
      dataIndex: "img",
      key: "img",
      align: "center",
      width: 125,
      render: (text, record, index) => (
        <AvatarGroup>
          <>
            {onlyview === false && (
              <IconButton
                style={{
                  padding: 0,
                  height: 40,
                  width: 40,
                  backgroundColor: "#0688d1",
                  color: "white",
                }}
                component="label"
              >
                <AddIcon />
                <input
                  style={{ display: "none" }}
                  accept="application/pdf, image/png, image/jpeg"
                  type="file"
                  onChange={(event) => uploadDocs(record._id, event.target.files)}
                  multiple
                />
              </IconButton>
            )}
            <Avatar>{record.docs.length}</Avatar>
          </>
        </AvatarGroup>
      ),
    },
  ]

  const [columnas, setColumnas] = useState([...columns])

  useEffect(() => {
    if (isCompact) {
      const filtered = [...columnas].filter(
        (ev) => !["CORRELATIVO", "IMAGENES", "DOCUMENTOS SUSTENTATORIOS"].includes(ev.title)
      )
      setColumnas([
        {
          title: "CANTIDAD",
          dataIndex: "quantity",
          key: "quantity",
          width: 150,
          align: "center",
          sorter: (a, b) => {
            if (a.quantity > b.quantity) return 1
            if (a.quantity < b.quantity) return -1
          },
        },
        ...filtered,
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {detailTransferencias.open && (
        <DetailTransferencias
          visible={detailTransferencias.open}
          setVisible={setDetailTransferencias}
          idAsset={detailTransferencias.idAsset}
        />
      )}
      {modalEditMuebles.open && (
        <EditMuebles
          visible={modalEditMuebles.open}
          setVisible={setModalEditMuebles}
          data={modalEditMuebles.data}
          reloadFunction={reloadFunction}
          categories={categories}
          setLoading={setLoading}
          loaging={loading}
          reloadCategories={reloadCategories}
        />
      )}

      {modalConfirmDelete.open && (
        <ConfirmDelete
          disable={true}
          visible={modalConfirmDelete.open}
          setVisible={setModalConfirmDelete}
          reload={reloadFunction}
          endPoint={`fixed-asset/${modalConfirmDelete.idMueble}`}
          title="¿Esta seguro(a) que desea deshabilitar este registro?"
          subtitle="Tener en cuenta que después de realizar esta operación el registro estará en la lista de deshabilitado."
        />
      )}
      {imgModal.open && (
        <ModalRemoveDocument
          disable={true}
          visible={imgModal.open}
          setVisible={setImgModal}
          reload={reloadFunction}
          endPoint={`remove-img-asset/${imgModal.id}/${imgModal.img}`}
          title="ELIMINAR IMAGEN"
          subtitle="¿Esta seguro(a) que desea quitar esta imagen?"
          description="Tener en cuenta que después de realizar esta operación no se podra recuperar la imagen."
        />
      )}
      <Table
        rowSelection={{ type: "checkbox", ...selection(setMemorandum, memorandum) }}
        columns={columnas}
        dataSource={firstPendingAction(dataTable)}
        bordered
        size="small"
        scroll={{ x: "100%" }}
      />
      {openModalTransferMuebles.open && (
        <ModalTransferActivos
          visible={openModalTransferMuebles.open}
          setVisible={setOpenModalTransferMuebles}
          reload={reloadFunction}
          dataSource={dataMuebles}
          nameLocation={nameLocation}
          idLocation={idLocation}
        />
      )}

      {modalDetail.open && (
        <DetailMuebles
          visible={modalDetail.open}
          setVisible={setModalDetail}
          data={modalDetail.data}
          reloadFunction={reloadFunction}
        />
      )}
      {delRecord.open && (
        <ModalConfirmDelete
          visible={delRecord.open}
          setVisible={setdelRecord}
          reload={reloadFunction}
          endpoint={`${apiInventario}/fixed-asset-remove/${delRecord.id}`}
          title="¿SEGURO QUE DESEA ELIMINAR ESTE REGISTRO?"
          subtitle="TEN EN CONSIDERACIÓN QUE EL REGISTRO YA NO ESTARÁ DISPONIBLE PARA SU USO O MODIFICACIÓN."
          disable={loading}
        />
      )}

      {memorandum.open && (
        <CreateMemorandum
          open={memorandum.open}
          setOpen={setMemorandum}
          data={memorandum.data}
          type={memorandum.type}
          handleReload={reloadFunction}
          param="fixedasset"
          action={`${memorandum.type === "delete" ? "DESHABILITACIÓN" : "TRANSFERENCIA"
            } DE MUEBLE(S)`}
        />
      )}
    </>
  )
}

export default TableMuebles
