import React, { useState } from "react";

import FormVoucher from "./FormVoucher.jsx";

import { notification, Icon } from "antd";
import { sendVoucher } from "../../../api/api.contable.js";
import sedeactual from "../../../config/sedeactual.js";

const CrearVoucherList = () => {
  const [datavoucher, setdatavoucher] = useState("");
  const [nVoucher, setnVoucher] = useState("");
  const [periodo, setperiodo] = useState("");

  const sendData = async (voucher) => {
    const res = await sendVoucher(voucher, sedeactual.get());
    if (res) {
      setnVoucher({ ...true });
      notification.open({
        message: "Crear Voucher",
        description: "El Voucher fue creado satisfactoriamente.",
        icon: <Icon type="check" style={{ color: "#49E02D" }} />,
        key: `open${Date.now()}`,
      });
      return res
    } else {
      notification.open({
        message: "Crear Voucher",
        description: "Ocurrio un error al crear el Voucher.",
        icon: <Icon type="close" style={{ color: "#FE2E27" }} />,
        key: `open${Date.now()}`,
      });
    }
  };

  return (
    <FormVoucher
      nVoucher={nVoucher}
      periodo={periodo}
      id={""}
      data={datavoucher}
      edit={false}
      sendFormData={sendData}
    />
  );
};

export default CrearVoucherList;
