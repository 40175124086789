import React, { useState, useEffect } from "react";
import Page from "src/components/Page";
import { Grid, TextField } from "@material-ui/core";
import TableWorkers from "./components/TableWorkers";
import useWorkers from "src/hook/useWorkers";
import moment from "moment";

const WorkersByDates = () => {
  const { workers, loading } = useWorkers();
  const [data, setData] = useState([]);
  const [dates, setDates] = useState({
    start: moment().subtract(5, "years").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    let filtered = workers.filter((e) => moment(e.dateStart).isBetween(dates.start, dates.end));
    setData([...filtered]);
  }, [workers, dates]);

  return (
    <Page
      title="TRABAJADORES POR RANGO DE FECHAS"
      helper="COLABORADORES"
      itemComponent={
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <TextField
              label="Fecha de inicio"
              variant="outlined"
              type="date"
              defaultValue={dates.start}
              onChange={(e) => setDates({ ...dates, start: e.target.value })}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Fecha de finalización"
              variant="outlined"
              type="date"
              defaultValue={dates.end}
              onChange={(e) => setDates({ ...dates, end: e.target.value })}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      }
    >
      <TableWorkers data={data} loading={loading} view="dates" active={true} />
    </Page>
  );
};

export default WorkersByDates;
