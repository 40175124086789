import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import Page from "../../components/Page/Page";
import anio from "../../config/anio";
import sedeactual from "../../config/sedeactual";
import { notification } from "antd";
import SelectSedes from "../Selects/SelectSedes";
import SelectCuenta from "../Selects/SelectCuenta";
import { Button, Grid } from "@material-ui/core";
import TableAc from "./AuxiliarCuentas/TableAc";
import AddBoxIcon from "@material-ui/icons/AddBox";
import {
  postAuxCuentas,
  getAuxCuentas,
  getAccountPlan,
  getHeadquarters,
} from "../../api/api.contable";

const CC = () => {
  const [cuenta5d, setCuentas5d] = useState([]);
  const [cuenta, setCuenta] = useState(null);
  const [visible, setvisible] = useState(false);
  const [description, setdescription] = useState("");
  const [data, setData] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [sede, setSede] = useState(sedeactual.get());
  const [loading, setLoading] = useState(false);

  const obtenerPlan = async () => {
    try {
      let res = await getAccountPlan(sedeactual.get(), anio.get());
      const result = res.filter(
        (element) => element.number.toString().length === sedeactual.digits()
      );
      setCuentas5d(result);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se pudo obtener la lista de auxiliar de cuentas.",
      });
    }
  };

  const obtenerEmpresas = async () => {
    try {
      let res = await getHeadquarters();
      setSedes(res.data);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se pudo obtener las sedes.",
      });
    }
  };

  async function getData() {
    setLoading(true);
    let auxAccounts = await getAuxCuentas(sede, anio.get());
    let refactor = [];
    for (let i = 0; i < auxAccounts.data.length; i++) {
      refactor.push({
        _id: auxAccounts.data[i]._id,
        headquarter: auxAccounts.data[i].headquarter,
        description: auxAccounts.data[i].description,
        account_desc: auxAccounts.data[i].account
          ? auxAccounts.data[i].account.description
          : "",
        account_number: auxAccounts.data[i].account
          ? auxAccounts.data[i].account.number
          : "",
      });
    }
    setData(refactor);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, [sede]);

  useEffect(() => {
    obtenerEmpresas();
    obtenerPlan();
  }, []);

  async function handleOk() {
    try {
      await postAuxCuentas(description, cuenta, sedeactual.get(), anio.get());
      notification["success"]({
        message: "Auxiliar de cuentas",
        description: "El registro fue agregado satisfactoriamente.",
      });
      setdescription("");
      setCuenta(null);
      getData();
    } catch (error) {
      notification["error"]({
        message: "Auxiliar de cuentas",
        description: `Ocurrio un error al agregar el registro. ${error}`,
      });
    }
    setvisible(false);
  }

  return (
    <Page
      helper="AUXILIAR DE CUENTAS"
      title="AUXILIAR DE CUENTAS"
      itemComponent={
        <>
          {sedeactual.getRol() !== "usuario" && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setvisible(true)}
              startIcon={<AddBoxIcon />}
              fullWidth
            >
              Crear
            </Button>
          )}
        </>
      }
    >
      <Grid container spacing={2}>
        {(sedeactual.getRol() === "supernap" ||
          sedeactual.getRol() === "superadmin") && (
          <Grid item xs={12}>
            <SelectSedes
              defaultValue={sede}
              data={sedes}
              onChangeSelect={(e) => {
                setSede(e);
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TableAc data={data} getData={getData} loading={loading} />

          <Modal
            title="Auxiliar de Cuentas"
            visible={visible}
            onOk={handleOk}
            onCancel={() => {
              setvisible(false);
            }}
          >
            <label>N° Cuenta </label>
            <SelectCuenta
              defaultValue={cuenta}
              onChangeSelect={(e) => {
                setCuenta(e);
              }}
              data={cuenta5d}
            />

            <label>Descripción </label>
            <input
              value={description}
              onChange={(evt) => setdescription(evt.target.value)}
              type="text"
              name="username"
              //   disabled={edit}

              className="form-control"
            />
          </Modal>

          {/* <Modal
          onOk={handleOkE}
          onCancel={() => {
            seteliminarvisible(false);
          }}
          title="Eliminar"
          visible={eliminarvisible}
        >
          <p>¿Seguro que desea eliminar el Centro de costo?</p>
        </Modal> */}
        </Grid>
      </Grid>
    </Page>
  );
};

export default CC;
