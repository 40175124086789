import React, { useState, useEffect } from "react";
import { notification } from "antd";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  FormHelperText,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ExportarExcel from "../../components/Global/ExportarExcel";
import "../../assets/exportarBalanceStyle.scss";
import Page from "../../components/Page/Page";
import anio from "../../config/anio";
import sedeactual from "../../config/sedeactual";
import TablaBalance from "./TablaBalance.jsx";
import SelectSedes from "../Usuarios/SelectSedes";
import { getBalanceReport, getActiveSedes } from "../../api/api.contable.js";
import moment from "moment";
import Loader from "../../components/Global/LoaderBackdrop";

const ExportarBalance = () => {
  const [isLoading, setLoading] = useState(false);
  const [dataSedes, setDataSedes] = useState([]);
  const [sede, setSede] = useState(sedeactual.get());
  const [digitos, setdigitos] = useState([]);
  const [digit, setDigit] = useState(6);
  const [month, setMonth] = useState(1);
  const [data, setdata] = useState([]);

  useEffect(() => {
    getSedesAndCuentas();
  }, []);

  const getSedesAndCuentas = async () => {
    setLoading(true);
    try {
      let result = await getActiveSedes();
      setDataSedes(result);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se pudo obtener las sedes y las cuentas.",
      });
    } finally {
      setLoading(false);
    }
  };

  const isAdmin =
    sedeactual.getRol() === "supernap" || sedeactual.getRol() === "superadmin";

  useEffect(() => {
    let comunidad = [2, 3, 4, 5];
    let obra = [2, 3, 4, 5, 6];

    if (dataSedes.length > 0) {
      let foundSede = dataSedes.find((e) => e.id === sede);
      foundSede.digits === 5 ? setdigitos(comunidad) : setdigitos(obra);
    }
  }, [sede, dataSedes]);

  async function traerData() {
    setLoading(true);
    let res = await getBalanceReport(month, sede, anio.get(), digit);
    setdata(res.data);
    setLoading(false);
  }

  const content = (
    <Page
      helper="REPORTES"
      title="BALANCE DE N DÍGITOS"
      itemComponent={
        <Grid container spacing={0} style={{ minWidth: 280 }}>
          <Grid item xs={6}>
            <Link to={{ pathname: "/reportes" }}>
              <Button variant="outlined" color="primary">
                <ArrowBackIosIcon /> VOLVER
              </Button>
            </Link>
          </Grid>
          <Grid item xs={6}>
            <ExportarExcel
              name={`BALANCE_${moment(Date.now()).format()}`}
              endpoint={`balance/export-excel/${sede}/${anio.get()}/${
                sedeactual.company().url
              }`}
              body={{ period: month, digit: digit }}
              method="POST"
              disabled={isLoading}
              small={false}
              api={4}
            />
          </Grid>
        </Grid>
      }
    >
      {isLoading && <Loader />}
      <Grid container spacing={2}>
        {isAdmin && (
          <Grid item xs={12} sm={6} md={4}>
            <FormHelperText>SEDES</FormHelperText>
            <SelectSedes
              defaultValue={sede}
              data={dataSedes}
              onChangeSelect={(e) => setSede(e)}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={3}>
          <FormHelperText>PERIODO</FormHelperText>
          <FormControl variant="outlined" size="small" fullWidth>
            <Select value={month} onChange={(e) => setMonth(e.target.value)}>
              <MenuItem value="1">Enero</MenuItem>
              <MenuItem value="2">Febrero</MenuItem>
              <MenuItem value="3">Marzo</MenuItem>
              <MenuItem value="4">Abril</MenuItem>
              <MenuItem value="5">Mayo</MenuItem>
              <MenuItem value="6">Junio</MenuItem>
              <MenuItem value="7">Julio</MenuItem>
              <MenuItem value="8">Agosto</MenuItem>
              <MenuItem value="9">Setiembre</MenuItem>
              <MenuItem value="10">Octubre</MenuItem>
              <MenuItem value="11">Noviembre</MenuItem>
              <MenuItem value="12">Diciembre</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormHelperText>DÍGITOS</FormHelperText>
          <FormControl variant="outlined" size="small" fullWidth>
            <Select value={digit} onChange={(e) => setDigit(e.target.value)}>
              {digitos.map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormHelperText />
          <Button
            disabled={isLoading}
            onClick={() => traerData()}
            variant="contained"
            color="primary"
            fullWidth
          >
            BUSCAR
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TablaBalance loading={isLoading} data={data} />
        </Grid>
      </Grid>
    </Page>
  );
  return content;
};

export default ExportarBalance;
