import React, { useState, useEffect } from "react";
import { notification } from "antd";
import axios from "axios";
import {
  Button,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
  FormHelperText,
} from "@material-ui/core";
import { apiCuentas } from "../../config/api.contable";
import sedeactual from "../../config/sedeactual";
import SelectCuenta from "../Selects/SelectCuenta";
import SelectCostFormula from "../Selects/SelectCostFormula";

const EditCuenta = (props) => {
  const [loading, setLoading] = useState(false);
  const { data, setEditAccount, getAccounts, cuentas } = props;
  const [description, setDescription] = useState(data.description);
  const [number, setNumber] = useState(data.number);
  const [visible, setVisible] = useState(data.visible);
  const [costoSelected, setCostoSelected] = useState(
    data.cost_id == "" ? null : data.cost_id
  );
  const [debeSelected, setDebeSelected] = useState(
    data.debit_id == "" ? null : data.debit_id
  );
  const [assetSelected, setAssetSelected] = useState(
    data.asset_id == "" ? null : data.asset_id
  );
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    let sixDigits = cuentas.filter(
      (element) => element.number.toString().length === sedeactual.digits()
    );
    setAccounts(sixDigits);
  }, []);

  const SendData = async () => {
    setLoading(true);
    try {
      let datos = {
        description: description,
        number: number,
        visible: visible,
        asset: assetSelected,
        debit: debeSelected,
        cost: costoSelected,
      };
      await axios.put(`${apiCuentas}/accounting-plan/${data._id}`, datos);
      notification["success"]({
        message: "Actualizar Cuenta",
        description: "La cuenta fue actualizada satisfactoriamente.",
      });
      setEditAccount({ visible: false });
      getAccounts();
    } catch (error) {
      notification["error"]({
        message: "Actualizar Cuenta",
        description: "Ocurrio un error al actualizar la cuenta.",
      });
    } finally {
      setLoading(false);
    }
  };

  const content = (
    <div className="container">
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2" color="textPrimary">
            EDITAR CUENTA
          </Typography>
          <Button
            variant="outlined"
            onClick={() => setEditAccount({ visible: false })}
          >
            Volver
          </Button>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FormHelperText>CUENTA</FormHelperText>
          <TextField
            variant="outlined"
            size="small"
            value={number}
            disabled
            onChange={(event) => setNumber(event.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FormHelperText>DESCRIPCIÓN</FormHelperText>
          <TextField
            variant="outlined"
            size="small"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FormHelperText>FORMULA DE COSTOS</FormHelperText>
          <SelectCostFormula
            defaultValue={costoSelected}
            onChangeSelect={(e) => {
              setCostoSelected(e);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <FormHelperText>DEBE</FormHelperText>
          <SelectCuenta
            defaultValue={debeSelected}
            onChangeSelect={(e) => setDebeSelected(e)}
            data={accounts}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <FormHelperText>HABER</FormHelperText>
          <SelectCuenta
            defaultValue={assetSelected}
            onChangeSelect={(e) => setAssetSelected(e)}
            data={accounts}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4} align="center">
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={visible}
                onChange={() => setVisible(!visible)}
              />
            }
            label="Visible"
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={12} align="center">
          <Button
            type="primary"
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={SendData}
          >
            ACTUALIZAR
          </Button>
        </Grid>
      </Grid>
    </div>
  );
  return content;
};

export default EditCuenta;
