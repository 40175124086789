import React, { useState, useEffect } from "react";
import { Card, Grid } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import { notification } from "antd";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Page from "../../components/Page";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import UserServices from "../../services/UserServices";
import sedeactual from "../../config/sedeactual";
import TablePresupuesto from "./components/TablePresupuesto";
import SelectSedes from "../../components/Usuarios/SelectSedes";
import {
  getHeadquarters,
  getBudget,
  getAccountPlan,
} from "../../api/api.contable";
import AddPresupuesto from "./components/AddPresupuesto";
import DeleteAll from "./components/DeleteAll";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: theme.spacing(5, 3),
  },
}));

const ListPresupuesto = () => {
  const classes = useStyles();
  const [sede, setsede] = useState(sedeactual.get());
  const [sedes, setSedes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [modalAddBudget, setModalAddBudget] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [modalDeleteAll, setModalDeleteAll] = useState(false);
  const [accountsDB, setAccountsDB] = useState([]);

  const getBudgetList = async () => {
    setLoading(true);
    try {
      const res = await getBudget(sede, sedeactual.getAnio());
      let refactor = res.map((ev) => {
        let review = {
          _id: ev._id,
          number: ev.account && ev.account.number,
          description: ev.account && ev.account.description,
          amount: ev.estimateAmount,
          headquarter_id: ev.headquarter.id,
        };
        return review;
      });
      setData(refactor.reverse());
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "Ocurrió un error al obtener el presupuesto.",
      });
    } finally {
      setLoading(false);
    }
  };

  const getSedes = async () => {
    try {
      const res = await getHeadquarters();
      const cnts = await getAccountPlan(sede, sedeactual.getAnio());
      setSedes(res.data);
      let finder = await res.data.find((el) => el.id === sede);
      let digit = 6;
      if (finder) {
        digit = finder.work_mode_id === 1 ? 5 : 6;
      }
      let filterAccounts = cnts.filter(
        (ev) => ev.number.toString().length === digit
      );
      setAccountsDB(filterAccounts);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "Ocurrió un error al obtener las sedes y cuentas.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBudgetList();
    getSedes();
  }, [sede]);

  useEffect(() => {
    if (accountsDB.length > 0) {
      data.filter((e) => {
        let finder = accountsDB.find((el) => el.number === e.number);
        if (finder) {
          let indexAccount = accountsDB.findIndex(
            (ev) => ev.number === finder.number
          );
          accountsDB.splice(indexAccount, 1);
        }
      });
      setAccounts(accountsDB);
    }
  }, [accountsDB, data]);

  const content = (
    <Page
      helper="PRESUPUESTO"
      title="PRESUPUESTO"
      itemComponent={
        <Grid container spacing={1}>
          {(sedeactual.getRol() === "supernap" ||
            sedeactual.getRol() === "superadmin") && (
            <Grid item style={{ width: 345 }}>
              <SelectSedes
                defaultValue={sede}
                data={sedes}
                onChangeSelect={(e) => {
                  setsede(e);
                }}
              />
            </Grid>
          )}
          {sedeactual.getRol() !== "usuario" && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setModalAddBudget(true)}
              >
                <AddIcon /> AGREGAR
              </Button>
            </Grid>
          )}
          {(sedeactual.getRol() === "supernap" ||
            sedeactual.getRol() === "superadmin") && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setModalDeleteAll(true)}
              >
                eliminar todo <DeleteForeverIcon />
              </Button>
            </Grid>
          )}
        </Grid>
      }
    >
      <TablePresupuesto
        loading={loading}
        data={data}
        setLoading={setLoading}
        getBudgetList={getBudgetList}
      />
      {modalAddBudget && (
        <AddPresupuesto
          visible={modalAddBudget}
          setVisible={setModalAddBudget}
          cuentas={accounts}
          sede={sede}
          loading={loading}
          setLoading={setLoading}
          getBudgetList={getBudgetList}
        />
      )}
      {modalDeleteAll && (
        <DeleteAll
          visible={modalDeleteAll}
          setVisible={setModalDeleteAll}
          sede={sede}
          loading={loading}
          setLoading={setLoading}
          getBudgetList={getBudgetList}
        />
      )}
    </Page>
  );
  return content;
};

export default ListPresupuesto;
