import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Grid,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { apiContable } from "../../config/api.contable";
import { notification } from "antd";
import moment from "moment";

const ForgotPassword = () => {
  let year = moment().format("YYYY");
  const [user, setuser] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${apiContable}/auth/forgot_password`, { n_doc: user });
      notification["success"]({
        message: "Se envió un mensaje a su correo.",
        description:
          "Revise su bandeja de entrada para continuar con el proceso.",
      });
    } catch (error) {
      notification["error"]({
        message: "Correo no encontrado",
        description:
          "El numero de documento ingresado no coincide con nuestros registros. Verifique!",
      });
    }
  };

  return (
    <Grid
      container
      style={{ minHeight: "100vh" }}
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h3">
              ¿Olvidaste tu Contraseña?
            </Typography>
            <br />
            <Typography variant="subtitle2">RECUPERAR CONTRASEÑA</Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="N° DNI"
                name="n_doc"
                onChange={(e) => setuser(e.target.value)}
                value={user}
                variant="outlined"
                required
                type="text"
              />
              <br />
              <br />
              <Button
                color="secondary"
                size="large"
                type="submit"
                variant="contained"
                fullWidth
              >
                Recuperar
              </Button>
            </form>
            <br />
            <div align="center">
              <a href="https://www.netappperu.com/">
                <small>NetAppPeru © 2019 - {year}</small>
              </a>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
