import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { Table, Input, Icon, notification } from "antd";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import Highlighter from "react-highlight-words";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { changeStatusFile } from "../../../api/api.contable";

const TableArchivosAdmin = ({
  data,
  setfile,
  refresh,
  seturlFile,
  seteditModal,
  setvisibleDelete,
  setvisibleComment,
}) => {
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyPress={(e) => e.key === "Enter" && handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Limpiar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setsearchText(selectedKeys[0]);
    setsearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setsearchText("");
  }

  const changeStatus = async (row, value) => {
    const res = await changeStatusFile(row._id, value);
    refresh();
    if (res.status === 200) {
      notification["success"]({
        message: "Estado del archivo",
        description: "Se cambió el estado archivo satisfactoriamente.",
      });
    } else {
      notification["error"]({
        message: "Estado del archivo",
        description: "Ocurrio un error al cambiar el estado del archivo.",
      });
    }
  };

  const columns = [
    {
      title: "NOMBRE",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: 200,
      ...getColumnSearchProps("name"),
      render: (text, record) => (
        <span style={{ float: "left" }}>{text.toUpperCase()}</span>
      ),
    },
    {
      title: "FECHA",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: 100,
      render: (text) => (
        <span> {moment(text).utc(false).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      title: "FECHA DE SUBIDA",
      dataIndex: "dateUpload",
      key: "dateUpload",
      align: "center",
      width: 100,
      render: (text) => (
        <span> {text != null && moment(text).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      title: "TIPO",
      dataIndex: "company",
      key: "company",
      align: "center",
      width: 100,
      render: (text, row) => (
        <span>{row.headquarter.work_mode_id === 1 ? "COMUNIDAD" : "OBRA"}</span>
      ),
    },
    {
      title: "EMPRESA",
      dataIndex: "company",
      key: "company",
      align: "center",
      width: 200,
      ...getColumnSearchProps("company"),
      render: (company) => (
        <span style={{ float: "left" }}> {company.toUpperCase()}</span>
      ),
    },
    {
      title: "ESTADO",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 150,
      render: (text, row) => (
        <select
          class="form-control"
          value={text}
          onChange={(e) => changeStatus(row, e.target.value)}
          id="exampleFormControlSelect1"
        >
          <option value="OBSERVADO">OBSERVADO</option>
          <option value="ACEPTADO">ACEPTADO</option>
          <option value="POR REVISAR">POR REVISAR</option>
        </select>
      ),
    },
    {
      title: "ACCIONES",
      dataIndex: "url",
      key: "url",
      align: "center",
      width: 250,
      render: (text, record) => (
        <ButtonGroup>
          <Button
            variant="outlined"
            color="primary"
            target="_blank"
            href={text}
          >
            <GetAppIcon />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setvisibleComment(true);
              seteditModal(true);
              setfile(record);
            }}
          >
            Comentario
          </Button>
          <Button
            style={{ background: "#FF1D10", color: "#fff" }}
            onClick={() => {
              seturlFile(record);
              setvisibleDelete(true);
            }}
          >
            <DeleteIcon />
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  return <Table dataSource={data} columns={columns} scroll={{ x: "100%" }} />;
};

export default TableArchivosAdmin;
