import React, { useState, useRef, useEffect } from "react";
import { notification, Icon } from "antd";
import UserServices from "../../services/UserServices";
import { apiCuentas } from "../../config/api.contable";
import sedeactual from "../../config/sedeactual";
import anio from "../../config/anio";
import SelectSedes from "../Usuarios/SelectSedes";
import { getHeadquarters } from "../../api/api.contable";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  FormHelperText,
} from "@material-ui/core";

const ImportAuxAccount = ({ isSuperAdm, sedes }) => {
  const [sede, setsede] = useState(sedeactual.get());
  const [, setState] = useState();
  const [anho] = useState(anio.get());
  const refInput = useRef("");

  const enviarCuenta = () => {
    if (refInput.current.files.length < 1) {
      const key = `open${Date.now()}`;
      notification.open({
        message: "Error de envío de datos",
        description: "No ha seleccionado el archivo",
        icon: <Icon type="close"></Icon>,
        key,
      });
      return;
    }
    // log([0].name)

    let formData = new FormData();
    let file = refInput.current.files[0];
    formData.append("file", file);
    // //Send All
    let req = new Request(
      `${apiCuentas}/aux-account/upload/${sede}/${anho}/${
        sedeactual.company().url
      }`,
      {
        method: "POST",
        headers: UserServices.headersUpload(),
        body: formData,
      }
    );
    fetch(req)
      .then((response) => response.json())
      .then((data) => {
        const key = `open${Date.now()}`;
        notification.open({
          message: "SE HA IMPORTADO EL AUXILIAR DE CUENTAS ",
          icon: <Icon type="check"></Icon>,
          key,
        });
      })
      .catch((err) => {
        const key = `open${Date.now()}`;
        notification.open({
          message: "Error de envío de datos",
          description: "Ocurrio un eror al importar las cuentas.",
          icon: <Icon type="close"></Icon>,
          key,
        });
      });
  };

  const onChangeCuenta = (e) => {
    let files = e.target.files;
    setState(files.length !== 0 ? files[0].name : "");
  };

  return (
    <Card style={{ height: "100%" }}>
      <CardContent>
        <Typography variant="h4" color="textSecondary">
          Importación de Auxiliar de Cuentas
        </Typography>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12}>
            {isSuperAdm ? (
              <>
                <FormHelperText>SEDES</FormHelperText>
                <SelectSedes
                  defaultValue={sede}
                  data={sedes}
                  onChangeSelect={(e) => {
                    setsede(e);
                  }}
                />
              </>
            ) : null}
          </Grid>
          <Grid item xs={12} md={8}>
            <input
              type="file"
              name="file"
              id="file"
              name="file"
              accept=".csv,.xlsm, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              ref={refInput}
              className=""
              onChange={onChangeCuenta}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              style={{ marginTop: "13px" }}
              variant="contained"
              color="primary"
              fullWidth
              disabled={
                refInput.current && refInput.current.files.length > 0 === false
                  ? true
                  : false
              }
              onClick={enviarCuenta}
            >
              Importar
            </Button>
          </Grid>
          <a href="https://filesdic.s3.us-east-2.amazonaws.com/excel/PLANTILLA_AUXILIAR_DE_CUENTAS.xlsx">
            <AttachFileIcon /> Plantilla auxiliar de cuentas
          </a>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ImportAuxAccount;
