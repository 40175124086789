import React, { useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormHelperText,
  Grid,
  Typography,
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
} from "@material-ui/core";
import moment from "moment";
import { AttachFile } from "@material-ui/icons";
import napformat from "src/config/napformat";
import sedeactal from "src/config/sedeactual";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import numberToString from "src/utils/numberToString";

const pageStyle = `
  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    html, body {
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-bottom: 1rem;
      display: block;
      page-break-before: always;
    }
  }

  @page {
    size: 1in 4in;
    margin: 10mm;
    padding: 20mm;
  }
`;

const ModalDetails = ({ open, data, setOpen }) => {
  const { destiny, amount, date, email, details, correlative, concepto } = data;
  const { address, name, logo, ruc } = sedeactal.company();
  const componentRef = useRef(null);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogContent>
        <Paper style={{ backgroundColor: "#eee" }}>
          <div align="right">
            <ReactToPrint
              trigger={() => (
                <Button color="primary" variant="outlined" size="large">
                  <PrintIcon /> IMPRIMIR
                </Button>
              )}
              content={() => componentRef.current}
              documentTitle={`COMPROBANTE_INGRESOS_${destiny?.nombre_o_razon_social}`}
              removeAfterPrint={true}
              pageStyle={pageStyle}
            />
          </div>
          <Box px={5} py={2} style={{ position: "relative" }} ref={componentRef} component="div">
            <img src={logo} style={{ height: 125, position: "absolute", top: 20, left: 40 }} />
            <Grid container spacing={1}>
              <Grid item xs={12} align="center">
                <Typography variant="h3" gutterBottom style={{ maxWidth: 568 }}>
                  <b>{name}</b>
                </Typography>
                <Typography variant="subtitle1">{address}</Typography>
                <Typography variant="subtitle1">
                  <b>RUC: {ruc}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom align="center">
                  <b>RECIBO DE INGRESOS N° {correlative.toString().padStart(10, "0")}</b>
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 20 }}>
                <Typography paragraph style={{ textAlign: "justify" }}>
                  Hemos recibido de <b>{(destiny?.nombre_o_razon_social ?? "").toUpperCase()}</b>
                  {destiny?.n_doc && (
                    <span>
                      {", identificado con "}
                      <b>{destiny?.t_doc}</b>
                      {" Nro.: "}
                      <b>{destiny?.n_doc}</b>
                      {", email: "}
                      <b>{email.toUpperCase()}</b>
                    </span>
                  )}
                  . Por concepto de <b>{concepto}</b>, siendo un monto total de S/
                  {napformat(amount)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table className="tablefe" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">ITEM</TableCell>
                        <TableCell align="center">CONCEPTO</TableCell>
                        <TableCell align="center">S/ MONTO</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {details.map((e, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <Typography variant="inherit" style={{ textTransform: "uppercase" }}>
                              {concepto} del mes de {e.month} del {e.year}
                              {data.description && (
                                <span>
                                  <br />
                                  {data.description}
                                </span>
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">{napformat(e.amount)}</TableCell>
                        </TableRow>
                      ))}
                      <TableRow style={{ backgroundColor: "#ccc" }}>
                        <TableCell colSpan={2}>
                          <b>SON {numberToString(amount)}</b>
                        </TableCell>
                        <TableCell align="right">
                          S/<b>{napformat(amount)}</b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 20 }}>
                <Typography variant="caption">
                  Resolución de superintendencia Nro 252-2019 - Resolución de superintendencia Nro 007-99/SUNAT Artículo
                  4 numeral 6.2 inc b
                </Typography>
                <br />
                <Typography variant="caption">
                  <b>Lima, {moment(date).utc(false).format("dddd D [de] MMMM [del] YYYY")}</b>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box px={5} py={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormHelperText>EVIDENCIA(S): </FormHelperText>
                <Divider />
              </Grid>
              {details.map((e) => (
                <Grid item>
                  <Chip
                    icon={<AttachFile />}
                    label={`S/${napformat(e.amount)}${e.detail ? ` ─ ${e.detail}` : ""}`}
                    component="a"
                    variant="outlined"
                    color="primary"
                    clickable
                    target="_blank"
                    href={e.file}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={() => setOpen({ open: false })}>
          CERRAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDetails;

const Text = ({ title, value }) => {
  return (
    <Typography variant="subtitle1">
      <b>{title}</b>
      {value && <FormHelperText> ─ {value}</FormHelperText>}
    </Typography>
  );
};
