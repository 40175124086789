import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

const SelectSedesNew = (props) => {
  const { data, onChangeSelect, defaultValue } = props;
  const [inputValue, setInputValue] = useState("");
  const [sedeSelected, setSedeSelected] = useState(null);

  useEffect(() => {
    let result = data?.find((h) => h.id === defaultValue);
    setSedeSelected(result);
    onChangeSelect(result);
  }, [data]);

  return (
    <div>
      {sedeSelected && (
        <Autocomplete
          id="combo-box-demo"
          options={data}
          fullWidth
          getOptionLabel={(option) => option.description} //option.description
          renderOption={(option) => (
            <>
              {option.work_mode_id === 1 ? "COMUNIDAD" : "COLEGIO"}
              {" - "}
              {option.description}
            </>
          )}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          defaultValue={sedeSelected}
          onChange={(event, newValue) => {
            onChangeSelect(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} fullWidth label="Sedes" variant="outlined" />
          )}
        />
      )}
    </div>
  );
};

export default SelectSedesNew;
