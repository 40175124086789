import React, { useEffect, useState } from "react";
import Page from "src/components/Page";
import { getDeclaraciones } from "src/utils/req.declaracion";
import { notification } from "antd";
import TablePersonal from "./components/TablePersonal";
import DetailPersonal from "./components/DetailPersonal";

const DeclaracionPersonal = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({ open: false, data: {} });

  useEffect(() => {
    obtainData();
  }, []);

  const obtainData = async () => {
    setLoading(true);
    try {
      const res = await getDeclaraciones("personal");
      setData([...res]);
    } catch (error) {
      notification["error"]({
        message: `Ocurrió un error al realizar la operación`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page helper="DECLARACIÓN JURADA" title="Declaración jurada personal privado">
      <TablePersonal data={data} loading={loading} setDetails={setDetails} />

      {details.open && (
        <DetailPersonal open={details.open} setOpen={setDetails} data={details.data} />
      )}
    </Page>
  );
};

export default DeclaracionPersonal;
