import React, { Suspense, useState, useEffect, Fragment } from "react";
import SessionStorageService from "../../../../auth/SessionStorageService.js";
import SessionTimeService from "../../../../auth/SessionTimeService.js";
import { makeStyles } from "@material-ui/styles";
import {
  LinearProgress,
  Typography,
  FormHelperText,
  Button,
} from "@material-ui/core";
import { Modal, notification } from "antd";
import { NavBar, TopBar } from "../";
import anio from "../../../../config/anio";
import sedeactual from "../../../../config/sedeactual";
import {
  getHeadquartersByUserAndCompany,
  getHeadquartersFilter,
} from "../../../../api/api.contable";
import SelectSedesHome from "../../../../components/Usuarios/SelectSedesHome";
import { SESSION_NAME, SESSION_YEAR } from "../../../../config/session";
import SelectYear from "src/components/Selects/SelectYear";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  topBar: {
    zIndex: 2,
    position: "relative",
  },
  container: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    overflowY: "auto",
    flex: "1 1 auto",
  },
}));

const DashboardLayout = ({ modules, children }) => {
  const [data, setData] = useState([]);
  const [año, setAño] = useState(anio.getActual());
  const [modal, setmodal] = useState(anio.validaranio());
  const [usuarios, setusuarios] = useState([]);
  const [usuario, setusuario] = useState(null);
  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  const getCompanies = async () => {
    try {
      let res = [];
      if (
        sedeactual.getRol() === "supernap" ||
        sedeactual.getRol() === "superadmin"
      ) {
        let { data } = await getHeadquartersFilter(sedeactual.company().url);
        res = [...data];
      } else {
        res = await getHeadquartersByUserAndCompany(
          sedeactual.user_id(),
          sedeactual.company()._id
        );
      }
      setData(res);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: `Se produjo un error al obtener la información. Revise su conexión a intenet.`,
      });
    }
  };

  useEffect(() => {
    if (anio.validaranio()) {
      getCompanies();
    }
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      if (sedeactual.sede_id()) setusuario(sedeactual.sede_id());
      else setusuario(data[0]._id);
      setusuarios(data);
      // if (
      //   sedeactual.getRol() === "supernap" ||
      //   sedeactual.getRol() === "superadmin"
      // ) {
      //   setusuario(data[0]._id);
      // }
    }
  }, [data]);

  function enviar() {
    let user = SessionStorageService.get(SESSION_NAME);
    let ObjetoMalaPractica = {
      expiresAt: user.expiresAt,
      value: user.value,
    };
    let search = data.find((e) => e._id === usuario);
    if (search) {
      ObjetoMalaPractica.value.headquarter = search._id;
      ObjetoMalaPractica.value["current_headquarter"] = search;
      SessionStorageService.remove(SESSION_NAME);
      SessionStorageService.set(SESSION_NAME, ObjetoMalaPractica);
      SessionStorageService.set(SESSION_YEAR, {
        expiresAt: SessionTimeService.estimatedTime(),
        value: año,
      });
      setmodal(false);
      window.location.reload(false);
    } else {
      notification["error"]({
        message: "Ocurrió un error",
        description: `No se pudo asignar la empresa ni el año de trabajo.`,
      });
      window.location.reload(false);
    }
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <TopBar
          className={classes.topBar}
          onOpenNavBarMobile={handleNavBarMobileOpen}
        />
        <div className={classes.container}>
          <NavBar
            onMobileClose={handleNavBarMobileClose}
            openMobile={openNavBarMobile}
            modules={modules}
          />
          <main className={classes.content}>
            <Suspense fallback={<LinearProgress />}>{children}</Suspense>
          </main>
        </div>
      </div>
      <Modal
        title={
          <Typography align="center" variant="h4">
            <b>SELECCIÓN DE AÑO Y SEDE</b>
          </Typography>
        }
        visible={modal}
        footer={[
          <Button variant="contained" color="primary" onClick={enviar}>
            ACEPTAR
          </Button>,
        ]}
        closable={false}
      >
        <FormHelperText>
          SELECCIONE EL AÑO EN EL QUE DESEA TRABAJAR
        </FormHelperText>
        <SelectYear year={año} setYear={setAño} />
        <FormHelperText>
          SELECCIONE LA EMPRESA EN LA QUE DESEA TRABAJAR
        </FormHelperText>
        <SelectSedesHome
          defaultValue={usuario}
          data={usuarios}
          onChangeSelect={setusuario}
        />
      </Modal>
    </Fragment>
  );
};

export default DashboardLayout;
