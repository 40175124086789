import React, { useState }  from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { makeStyles } from "@material-ui/core/styles";
import { Icon, notification,Button } from "antd";
import Fab from "@material-ui/core/Fab";
import Spinner from "../../components/Global/Spinner";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  notificationSuccess: {
    background: "#ECFFE8",
    padding: theme.spacing(4, 2),
  },
  notificationError: {
    background: "#FFE8E8",
    padding: theme.spacing(4, 2),
  },
}));

const ExportarMovements = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const buildInit = () => {
    return {
      method: "POST",
      body: JSON.stringify({
        digits: props.digits,
      }),
      headers: {
        "Content-Type": "application/json",
        /* Authorization: UserServices.getAuthorization() */
      },
    };
  };

  const onClickLink = () => {
    if (
      !(
        props.ruta.toString().includes("null") ||
        props.ruta.toString().includes("undefined")
      )
    ) {
      setLoading(true);
      fetch(props.ruta, buildInit()).then((response) => {
        if (response.ok) {
          setLoading(false);
          response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = `${props.nombre}.xlsx`;
            a.click();
          });

          notification.open({
            message: "Exportar Reporte",
            description: "El reporte se exportó  satisfactoriamente.",
            icon: <Icon type="check" style={{ color: "#49E02D" }} />,
            placement: "bottomRight",
            key: `open${Date.now()}`,
            className: `${classes.notificationSuccess}`,
          });
        }});
        
    } else {
      notification.open({
        message: "Estado del archivo",
        description: "Ocurrio un error al cambiar el estado del archivo.",
        placement: "bottomRight",
        icon: <Icon type="close" style={{ color: "#FE2E27" }} />,
        key: `open${Date.now()}`,
        className: `${classes.notificationError}`,
      });
    }
    //Descargar archivo
  };

  return (
    <>
      {props.small ? (
        <Fab
          className={classes.margin}
          size="small"
          onClick={onClickLink}
          style={{ backgroundColor: "#00bfa5", color: "#ffff" }}
        >
          <GetAppIcon />
        </Fab>
      ) : (
        <Button
          onClick={onClickLink}
          size="medium"
          style={{
            backgroundColor: "#00bfa5",
            color: "#ffff",
            marginRight: "5px",
          }}
        >
          <GetAppIcon />
          EXPORTAR
        </Button>
      )}
      {loading && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "#ffffff99",
            zIndex: "10",
            top: "0",
            left: "0",
            padding: "auto",
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};

export default ExportarMovements;
