import React from "react";
import {
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  ListItemIcon,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { SubdirectoryArrowRight } from "@material-ui/icons";

const DetailsWorkerSee = ({ open, setOpen, data }) => {
  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => setOpen({ open: false })}>
      <DialogContent>
        <List subheader={<ListSubheader>ESTADO DE LA VISTA DE TRABAJADORES</ListSubheader>}>
          {data.map((row, index) => (
            <ListItem key={index} divider button>
              <ListItemIcon>
                <SubdirectoryArrowRight />
              </ListItemIcon>
              <ListItemText primary={`${row.worker.l_name} ${row.worker.f_name}`} />
              <ListItemSecondaryAction>
                <Alert severity={row.status ? "success" : "warning"} style={{ width: 150 }}>
                  {row.status ? "VISTO" : "NO VISTO"}
                </Alert>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default DetailsWorkerSee;
