import React from "react";
import { Link } from "react-router-dom";
import { Card, CardContent, Grid, Button, Typography } from "@material-ui/core";
import Page from "src/components/Page";
import SettingsOverscanIcon from "@material-ui/icons/SettingsOverscan";
import BusinessIcon from "@material-ui/icons/Business";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import WeekendIcon from "@material-ui/icons/Weekend";
import CommuteIcon from "@material-ui/icons/Commute";
import CategoryIcon from "@material-ui/icons/Category";
import GamesIcon from "@material-ui/icons/Games";

const list = [
  {
    title: "TERRENOS",
    icon: SettingsOverscanIcon,
    link: "/inventario/terrenos",
  },
  {
    title: "EDIFICACIONES",
    icon: BusinessIcon,
    link: "/inventario/edificaciones",
  },
  {
    title: "EQUIPOS Y MAQUINARIA",
    icon: DesktopMacIcon,
    link: "/inventario/equipos",
  },
  {
    title: "MUEBLES",
    icon: WeekendIcon,
    link: "/inventario/muebles",
  },
  {
    title: "VEHÍCULOS",
    icon: CommuteIcon,
    link: "/inventario/vehiculos",
  },
  {
    title: "ENSERES",
    icon: CategoryIcon,
    link: "/inventario/enseres",
  },
  {
    title: "INTANGIBLES",
    icon: GamesIcon,
    link: "/inventario/intangibles",
  },
];

const Inventory = () => {
  return (
    <Page
      title="INVENTARIO"
      helper="INVENTARIO"
      style={{ minHeight: "calc((100vh) - 80px)" }}
    >
      <br />
      <Grid container spacing={3}>
        {list.map((ev) => (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3} align="center">
            <Card align="center">
              <CardContent>
                <Typography gutterBottom variant="h4" component="h2">
                  {ev.title}
                  <Typography color="textSecondary" variant="h6">
                    ACTIVO
                  </Typography>
                </Typography>
                <Typography color="textSecondary">
                  <ev.icon style={{ fontSize: 100 }} />
                </Typography>
                <Link to={{ pathname: ev.link }}>
                  <Button variant="contained" color="primary">
                    ingresar
                  </Button>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Page>
  );
};

export default Inventory;
