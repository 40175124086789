import React from "react";
import { Select } from "antd";

const { Option } = Select;
const SelectCompania = (props) => {
  const { onChangeSelect, defaultValue, data } = props;
  const onChange = (number) => {
    onChangeSelect(number);
  };
  const filterOptions = (inputValue, option) => {
    const title = option.props.title.toLowerCase();
    return title.includes(inputValue.toLowerCase(), 0);
  };
  return (
    <Select
      showSearch
      size="large"
      style={{ width: "100%" }}
      value={defaultValue}
      onChange={onChange}
      filterOption={filterOptions}
    >
      {data &&
        data.map((i) => (
          <Option key={i._id} value={i._id} title={`${i.name} - ${i.url}`}>
            <img src={i.logo} height="20px" /> {`${i.name} - ${i.url}`}
          </Option>
        ))}
    </Select>
  );
};
export default SelectCompania;
