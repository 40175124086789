import React from "react"
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@material-ui/core"
import napformat from "src/config/napformat"

const TableReporteEstadoFlujosEfectivo = ( { year, data, lastData } ) => {

  const conciliaciónGanancias = Number( ( ( data?.activos_no_corrientes?.InmuebleMyEDepre - lastData?.activos_no_corrientes?.InmuebleMyEDepre ) * -1 ).toFixed( 2 ) ) +
    Number( ( 0 ).toFixed( 2 ) ) +
    Number( ( 0 ).toFixed( 2 ) ) +
    Number( ( ( ( data?.activos_corrientes?.ctsCobrarComercial ?? 0 ) - ( lastData?.activos_corrientes?.ctsCobrarComercial ?? 0 ) ) * -1 ).toFixed( 2 ) ) +
    Number( ( ( ( data?.activos_corrientes?.existencias ?? 0 ) - ( lastData?.activos_corrientes?.existencias ?? 0 ) ) * -1 ).toFixed( 2 ) ) +
    Number( ( ( ( data?.activos_corrientes?.obgFinancieras ?? 0 ) - ( lastData?.activos_corrientes?.obgFinancieras ?? 0 ) ) * -1 ).toFixed( 2 ) ) +
    Number( ( ( ( data?.activos_corrientes?.rypPagarOFinancieras ?? 0 ) - ( lastData?.activos_corrientes?.rypPagarOFinancieras ?? 0 ) ) * -1 ).toFixed( 2 ) ) +
    Number( ( ( ( data?.activos_corrientes?.ctsCobrarOtras ?? 0 ) - ( lastData?.activos_corrientes?.ctsCobrarOtras ?? 0 ) ) * -1 ).toFixed( 2 ) ) +
    Number( ( ( ( data?.activos_corrientes?.ctsCobrarRelacionadas ?? 0 ) - ( lastData?.activos_corrientes?.ctsCobrarRelacionadas ?? 0 ) ) * -1 ).toFixed( 2 ) ) +
    Number( ( ( ( data?.activos_corrientes?.gtsAnticipados ?? 0 ) - ( lastData?.activos_corrientes?.gtsAnticipados ?? 0 ) ) * -1 ).toFixed( 2 ) ) +
    Number( ( ( ( data?.activos_corrientes?.ctsCobrarOtras ?? 0 ) - ( lastData?.activos_corrientes?.ctsCobrarOtras ?? 0 ) ) * -1 ).toFixed( 2 ) ) +
    Number( ( ( ( data?.activos_corrientes?.cuentaxPagarComercial ?? 0 ) - ( lastData?.activos_corrientes?.cuentaxPagarComercial ?? 0 ) ) * -1 ).toFixed( 2 ) ) +
    Number( ( ( ( data?.pasivo_corriento?.otsCuentasPagar ?? 0 ) - ( lastData?.pasivo_corriento?.otsCuentasPagar ?? 0 ) ) * -1 ).toFixed( 2 ) ) +
    Number( ( ( ( data?.pasivo_corriento?.obgFinancieras ?? 0 ) - ( lastData?.pasivo_corriento?.obgFinancieras ?? 0 ) ) * -1 ).toFixed( 2 ) ) +
    Number( ( ( ( data?.pasivo_corriento?.ipstRentaParticipaciones ?? 0 ) - ( lastData?.pasivo_corriento?.ipstRentaParticipaciones ?? 0 ) ) * -1 ).toFixed( 2 ) ) +
    0

  const flujosActInve = ( ( ( data?.activos_no_corrientes?.InmuebleMyE ?? 0 ) - ( lastData?.activos_no_corrientes?.InmuebleMyE ?? 0 ) ) * -1 > 0 ? ( ( data?.activos_no_corrientes?.InmuebleMyE ?? 0 ) - ( lastData?.activos_no_corrientes?.InmuebleMyE ?? 0 ) ) * -1 : 0 )
    + ( ( ( data?.activos_no_corrientes?.activosIntangibles ?? 0 ) - ( lastData?.activos_no_corrientes?.activosIntangibles ?? 0 ) ) * -1 > 0 ? ( ( data?.activos_no_corrientes?.activosIntangibles ?? 0 ) - ( lastData?.activos_no_corrientes?.activosIntangibles ?? 0 ) ) * -1 : 0 )
    + ( ( ( data?.activos_no_corrientes?.InmuebleMyE ?? 0 ) - ( lastData?.activos_no_corrientes?.InmuebleMyE ?? 0 ) ) * -1 < 0 ? ( ( data?.activos_no_corrientes?.InmuebleMyE ?? 0 ) - ( lastData?.activos_no_corrientes?.InmuebleMyE ?? 0 ) ) * -1 : 0 )
    + ( ( ( data?.activos_no_corrientes?.activosIntangibles ?? 0 ) - ( lastData?.activos_no_corrientes?.activosIntangibles ?? 0 ) ) * -1 < 0 ? ( ( data?.activos_no_corrientes?.activosIntangibles ?? 0 ) - ( lastData?.activos_no_corrientes?.activosIntangibles ?? 0 ) ) * -1 : 0 )
    + ( 0 )

  return (
    <TableContainer component={ Paper }>
      <Table className="tablefe" size="small">
        <TableHead style={ { height: 60 } }>
          <TableRow>
            <CellBold colSpan={ 2 } value="EXPRESADO EN SOLES" align="center" />
            <CellBold value={ year } align="center" />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <CellBold value="FLUJOS DE EFECTIVO DE ACTIVIDAD DE OPERACIÓN" colSpan={ 3 } />
          </TableRow>
          <TableRow>
            <Cell value="Ganancia (Pérdida) Neta del Ejercicio" colSpan={ 2 } />
            <Cell value={ `S/ ${ napformat( data?.patrimonio?.utlOPerdida ) }` } />
          </TableRow>
          <TableRow>
            <CellBold
              value="Ajustes para Concliar con la Ganancia (Pérdida) Neta del Ejercicio con el Efectivo proveniente de las Actividades de Operación por:"
              colSpan={ 3 }
            />
          </TableRow>
          <TableRow>
            <CellFree />
            <CellBold value="Ajustes no monetarios" colSpan={ 2 } />
          </TableRow>
          <TableRow>
            <CellFree />
            <Cell value={ `Depreciación,  Amortización y Agotamiento` } />
            <Cell value={ `S/ ${ napformat( ( ( data?.activos_no_corrientes?.InmuebleMyEDepre ?? 0 ) - ( lastData?.activos_no_corrientes?.InmuebleMyEDepre ?? 0 ) ) * -1 ) }` } />
          </TableRow>
          <TableRow>
            <CellFree />
            <Cell value={ `Pérdida (ganancia) en venta de propiedades de inversión` } />
            <Cell value={ `S/ ${ napformat( 0 ) }` } />
          </TableRow>
          <TableRow>
            <CellFree />
            <Cell value={ `Pérdida (Ganancia) en Venta de Propiedades, Planta y Equipo` } />
            <Cell value={ `S/ ${ napformat( 0 ) }` } />
          </TableRow>
          <TableRow>
            <CellBold
              value="CARGOS Y ABONOS POR CAMBIOS NETOS EN LOS ACTIVOS Y PASIVOS"
              colSpan={ 3 }
            />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `(Aumento) disminución de cuentas por cobrar comerciales y otras cuentas por cobrar` } />
            <Cell value={ `S/ ${ napformat( ( ( data?.activos_corrientes?.ctsCobrarComercial ?? 0 ) - ( lastData?.activos_corrientes?.ctsCobrarComercial ?? 0 ) ) * -1 ) }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `(Aumento) Disminución en Inventarios` } />
            <Cell value={ `S/ ${ napformat( ( ( data?.activos_corrientes?.existencias ?? 0 ) - ( lastData?.activos_corrientes?.existencias ?? 0 ) ) * -1 ) }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `(Aumento) Disminución en Tributos,Aportes al S.de Pens y Salud por Pagar` } />
            <Cell value={ `S/ ${ napformat( ( ( data?.activos_corrientes?.obgFinancieras ?? 0 ) - ( lastData?.activos_corrientes?.obgFinancieras ?? 0 ) ) * -1 ) }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `(Aumento) Disminución en Remuneraciones y Participaciones por pagar` } />
            <Cell value={ `S/ ${ napformat( ( ( data?.activos_corrientes?.rypPagarOFinancieras ?? 0 ) - ( lastData?.activos_corrientes?.rypPagarOFinancieras ?? 0 ) ) * -1 ) }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `(Aumento) Disminución de Cuentas por Cobrar Diversas` } />
            <Cell value={ `S/ ${ napformat( ( ( data?.activos_corrientes?.ctsCobrarOtras ?? 0 ) - ( lastData?.activos_corrientes?.ctsCobrarOtras ?? 0 ) ) * -1 ) }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `(Aumento) Disminución de Ctas. Por Cobrar a Acc. (o Soc. y Personal)` } />
            <Cell value={ `S/ ${ napformat( ( ( data?.activos_corrientes?.ctsCobrarRelacionadas ?? 0 ) - ( lastData?.activos_corrientes?.ctsCobrarRelacionadas ?? 0 ) ) * -1 ) }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `(Aumento) Disminución de Gastos Contratados por Anticipado` } />
            <Cell value={ `S/ ${ napformat( ( ( data?.activos_corrientes?.gtsAnticipados ?? 0 ) - ( lastData?.activos_corrientes?.gtsAnticipados ?? 0 ) ) * -1 ) }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `(Aumento) Disminución de Pasivo Diferido` } />
            <Cell value={ `S/ ${ napformat( ( ( data?.activos_corrientes?.ctsCobrarOtras ?? 0 ) - ( lastData?.activos_corrientes?.ctsCobrarOtras ?? 0 ) ) * -1 ) }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `Aumento (disminución) de cuentas por pagar comerciales y otras cuentas por pagar` } />
            <Cell value={ `S/ ${ napformat( ( ( data?.activos_corrientes?.cuentaxPagarComercial ?? 0 ) - ( lastData?.activos_corrientes?.cuentaxPagarComercial ?? 0 ) ) * -1 ) }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `Aumento (Disminución) de Cuentas por Pagar Diversas-Terceros` } />
            <Cell value={ `S/ ${ napformat( ( ( data?.pasivo_corriento?.otsCuentasPagar ?? 0 ) - ( lastData?.pasivo_corriento?.otsCuentasPagar ?? 0 ) ) * -1 ) }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `Aumento (Disminución) de Obligaciones Financieras` } />
            <Cell value={ `S/ ${ napformat( ( ( data?.pasivo_corriento?.obgFinancieras ?? 0 ) - ( lastData?.pasivo_corriento?.obgFinancieras ?? 0 ) ) * -1 ) }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `Aumento (Disminución) de Pasivos Imp. Renta y Participaciones Diferidos` } />
            <Cell value={ `S/ ${ napformat( ( ( data?.pasivo_corriento?.ipstRentaParticipaciones ?? 0 ) - ( lastData?.pasivo_corriento?.ipstRentaParticipaciones ?? 0 ) ) * -1 ) }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `Aumento (Disminución) de Otras Provisiones` } />
            <Cell value={ `S/ ${ napformat( 0 ) }` } />
          </TableRow>
          <TableRow>
            <CellBold
              value="Total de ajustes por conciliación de ganancias (pérdidas)"
              colSpan={ 2 }
            />
            <Cell value={ `S/ ${ napformat( conciliaciónGanancias ) }` } />
          </TableRow>
          <TableRow>
            <CellBold
              value="Flujos de efectivo y equivalente al efectivo procedente de (utilizados en) operaciones"
              colSpan={ 2 }
            />
            <Cell value={ `S/ ${ napformat( conciliaciónGanancias + data?.patrimonio?.utlOPerdida ) }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `Intereses recibidos (no incluidos en la Actividad de Inversión)` } />
            <Cell value={ `S/ ${ 0 }` } />
          </TableRow>
          <TableRow>
            <CellBold
              value="Flujos de Efectivo y Equivalente al Efectivo Procedente de (Utilizados en) Actividades de Operación"
              colSpan={ 2 }
            />
            <Cell value={ `S/ ${ napformat( conciliaciónGanancias + data?.patrimonio?.utlOPerdida ) }` } />
          </TableRow>
          <TableRow>
            <CellBold value="FLUJOS DE EFECTIVO DE ACTIVIDAD DE INVERSIÓN" colSpan={ 3 } />
          </TableRow>
          <TableRow>
            <CellBold
              value="Clases de cobros en efectivo por actividades de inversión"
              colSpan={ 3 }
            />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `Venta de Propiedades, Planta y Equipo` } />
            <Cell value={ `S/ ${ napformat( ( ( data?.activos_no_corrientes?.InmuebleMyE ?? 0 ) - ( lastData?.activos_no_corrientes?.InmuebleMyE ?? 0 ) ) * -1 > 0 ? ( ( data?.activos_no_corrientes?.InmuebleMyE ?? 0 ) - ( lastData?.activos_no_corrientes?.InmuebleMyE ?? 0 ) ) * -1 : 0 ) }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `Venta de Activos Intangibles` } />
            <Cell value={ `S/ ${ napformat( ( ( data?.activos_no_corrientes?.activosIntangibles ?? 0 ) - ( lastData?.activos_no_corrientes?.activosIntangibles ?? 0 ) ) * -1 > 0 ? ( ( data?.activos_no_corrientes?.activosIntangibles ?? 0 ) - ( lastData?.activos_no_corrientes?.activosIntangibles ?? 0 ) ) * -1 : 0 ) }` } />
          </TableRow>
          <TableRow>
            <CellBold
              value="Clases de pagos en efectivo por actividades de inversión"
              colSpan={ 3 }
            />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `Compra de Propiedades, Planta  y Equipo` } />
            <Cell value={ `S/ ${ napformat( ( ( data?.activos_no_corrientes?.InmuebleMyE ?? 0 ) - ( lastData?.activos_no_corrientes?.InmuebleMyE ?? 0 ) ) * -1 < 0 ? ( ( data?.activos_no_corrientes?.InmuebleMyE ?? 0 ) - ( lastData?.activos_no_corrientes?.InmuebleMyE ?? 0 ) ) * -1 : 0 ) }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `Compra de Activos Intangibles` } />
            <Cell value={ `S/ ${ napformat( ( ( data?.activos_no_corrientes?.activosIntangibles ?? 0 ) - ( lastData?.activos_no_corrientes?.activosIntangibles ?? 0 ) ) * -1 < 0 ? ( ( data?.activos_no_corrientes?.activosIntangibles ?? 0 ) - ( lastData?.activos_no_corrientes?.activosIntangibles ?? 0 ) ) * -1 : 0 ) }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `Otros cobros (pagos) de efectivo relativos a la actividad de inversión` } />
            <Cell value={ `S/ ${ napformat( 0 ) }` } />
          </TableRow>
          <TableRow>
            <CellBold
              value="Flujos de Efectivo y Equivalente al Efectivo Procedente de (Utilizados en) Actividades de Inversión"
              colSpan={ 2 }
            />
            <Cell value={ `S/ ${ napformat( flujosActInve ) }` } />
          </TableRow>
          <TableRow>
            <CellBold value="FLUJOS DE EFECTIVO DE ACTIVIDAD DE FINANCIACIÓN" colSpan={ 3 } />
          </TableRow>
          <TableRow>
            <CellBold value="Clases de cobros en efectivo por actividades de financiación" colSpan={ 3 } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `Obtención de Préstamos ` } />
            <Cell value={ `S/ ${ 0 }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `Préstamos de entidades relacionadas` } />
            <Cell value={ `S/ ${ 0 }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `Cambios en las participaciones en la propiedad de subsidiarias que no resultan en pérdida de control` } />
            <Cell value={ `S/ ${ 0 }` } />
          </TableRow>
          <TableRow>
            <CellBold value="Clases de pagos en efectivo por actividades de financiación:" colSpan={ 3 } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `Amortización o pago de Préstamos` } />
            <Cell value={ `S/ ${ 0 }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `Pasivos por Arrendamiento Financiero` } />
            <Cell value={ `S/ ${ 0 }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `Dividendos pagados` } />
            <Cell value={ `S/ ${ 0 }` } />
          </TableRow>
          <TableRow >
            <CellFree />
            <Cell value={ `Otros cobros (pagos) de efectivo relativos a la actividad de financiación` } />
            <Cell value={ `S/ ${ 0 }` } />
          </TableRow>
          <TableRow>
            <CellBold value="Flujos de Efectivo y Equivalente al Efectivo Procedente de (Utilizados en) Actividades de Financiación" colSpan={ 2 } />
            <CellBold value={ `S/ ${ 0 }` } />
          </TableRow>
          <TableRow>
            <CellBold value="Aumento (Disminución) Neto de Efectivo y Equivalente al Efectivo, antes de las Variaciones en las Tasas de Cambio" colSpan={ 2 } />
            <CellBold value={ `S/ ${ napformat( conciliaciónGanancias + flujosActInve ) }` } />
          </TableRow>
          <TableRow>
            <CellFree />
            <Cell value="Efectos de las Variaciones en las Tasas de Cambio sobre el Efectivo y Equivalentes al Efectivo" />
            <Cell value={ `S/ ${ 0 }` } />
          </TableRow>
          <TableRow>
            <CellBold value="Aumento (Disminución) Neto de Efectivo y Equivalente al Efectivo" colSpan={ 2 } />
            <Cell value={ `S/ ${ napformat( conciliaciónGanancias + flujosActInve ) }` } />
          </TableRow>
          <TableRow>
            <CellBold value="Efectivo y Equivalente al Efectivo al Inicio del Ejercicio" colSpan={ 2 } />
            <Cell value={ `S/ ${ napformat( lastData?.activos_corrientes?.efectivoEquivalentes ) }` } />
          </TableRow>
          <TableRow>
            <CellBold value="EFECTIVO Y EQUIVALENTE AL EFECTIVO AL FINALIZAR EL EJERCICIO" colSpan={ 2 } />
            <CellBold value={ `S/ ${ napformat( conciliaciónGanancias + flujosActInve + lastData?.activos_corrientes?.efectivoEquivalentes ) }` } />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableReporteEstadoFlujosEfectivo

const CellBold = ( { value, ...rest } ) => (
  <TableCell { ...rest }>
    <Typography variant="subtitle1" color="inherit">
      <b>{ value }</b>
    </Typography>
  </TableCell>
)

const Cell = ( { value, ...rest } ) => (
  <TableCell { ...rest }>
    <Typography variant="subtitle2" color="inherit">
      { value }
    </Typography>
  </TableCell>
)

const CellFree = () => <TableCell style={ { width: 50 } } />
