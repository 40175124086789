import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Button,
  Container,
  Grid,
  Paper,
  Box,
  Typography,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import Spinner from "src/components/Global/Spinner"
import { Link, useParams } from "react-router-dom";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { getIncidencesById, getDetailsByIncidence } from "src/utils/reqinc";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import ReactToPrint from "react-to-print";
import { notification } from "antd";
import sedeactual from "src/config/sedeactual";

const pageStyle = `
  @media all {
    .page-break {
      display: none;
      background: #fff;
    }
  }

  @media print {
    html, body {
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-bottom: 1rem;
      display: block;
      page-break-before: always;
    }
  }

  @page {
    size: 1in 4in;
    margin: 5mm;
  }
`;

const ReportIncidence = () => {
  const { idIncidence } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const componentRef = useRef(null);
  const detailsRef = useRef(null);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    obtainData();
    // eslint-disable-next-line
  }, [idIncidence]);

  const obtainData = async () => {
    setLoading(true);
    try {
      const res = await getIncidencesById(idIncidence);
      const resDetails = await getDetailsByIncidence(idIncidence);
      setDetails(resDetails);
      setData(res);
    } catch (error) {
      notification["error"]({
        message: `Ocurrió un error al obtener la información del detalle`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <Grid container justify="space-between" component={Box} pt={1} pb={1}>
            <Grid item>
              <Link to={{ pathname: "/incidencias" }}>
                <Button variant="outlined" color="primary">
                  VOLVER
                </Button>
              </Link>
            </Grid>
            <Grid item>
              <ReactToPrint
                trigger={() => (
                  <Button variant="contained" color="primary">
                    IMPRIMIR
                  </Button>
                )}
                content={() => componentRef.current}
                documentTitle={`REPORTE_DE_INCIDENCIAS_${Date.now()}`}
                removeAfterPrint={true}
                pageStyle={pageStyle}
              />
            </Grid>
          </Grid>
          <Paper elevation={5}>
            <div ref={componentRef} className="page-break">
              <Box p={5}>
                {data && (
                  <Grid container justify="flex-end" spacing={2}>
                    <Grid item xs={12}>
                      <CustomHeader
                        title="FORMATO 1 DEL REGISTRO DE INCIDENCIAS"
                        correlative={data.correlative}
                        siseve={data.siseve}
                        dre={data.dre}
                        ugel={data.ugel}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTitle value="1. DATOS GENERALES" />
                    </Grid>
                    <Grid item xs={11}>
                      <Grid container spacing={2}>
                        <Grid item xs={4} md={4}>
                          <CustomText
                            title="Fecha Actual"
                            value={data.dateRegister}
                          />
                        </Grid>
                        <Grid item xs={8} md={8}>
                          <CustomText
                            title="Tipo de reporte"
                            value={data.typeReport}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTitle value="2. DATOS DE LA PERSONA INFORMANTE DEL CASO" />
                    </Grid>
                    <Grid item xs={11}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                          <CustomText
                            title="Tipo de documento"
                            value={data.infTDoc}
                          />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <CustomText
                            title="Número de documento"
                            value={data.infNDoc}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CustomText
                            title="Nombres y apellidos"
                            value={data.infFullname}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CustomText
                            title="Parentesco"
                            value={data.infParentesco.text}
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                          <CustomText
                            title="Dirección"
                            value={data.infAddress}
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                          <CustomText title="Celular" value={data.infPhone} />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                          <CustomText title="Correo" value={data.infEmail} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTitle value="3. DATOS DE LA SUPUESTA PERSONA AGREDIDA" />
                    </Grid>
                    <Grid item xs={11}>
                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={6} md={4}>
                          <CustomText
                            title="Iniciales"
                            value={data.agdInitils}
                          />
                        </Grid>
                        <Grid item xs={4} sm={6} md={4}>
                          <CustomText title="Edad" value={data.agdAge} />
                        </Grid>
                        <Grid item xs={4} sm={6} md={4}>
                          <CustomText title="Sexo" value={data.agdSexo} />
                        </Grid>
                        <Grid item xs={4} sm={6} md={4}>
                          <CustomText title="Nivel" value={data.agdLevel} />
                        </Grid>
                        <Grid item xs={4} sm={6} md={4}>
                          <CustomText title="Grado" value={data.agdGrade} />
                        </Grid>
                        <Grid item xs={4} sm={6} md={4}>
                          <CustomText title="Sección" value={data.agdSection} />
                        </Grid>
                        <Grid item xs={4} sm={6} md={4}>
                          <CustomText title="Turno" value={data.agdShift} />
                        </Grid>
                        <Grid item xs={4} sm={6} md={4}>
                          <CustomText title="Celular" value={data.agdPhone} />
                        </Grid>
                        <Grid item xs={4} sm={6} md={4}>
                          <CustomText
                            title="Discapacidad"
                            value={
                              data.agdDiscapacidad.value
                                ? data.agdDiscapacidad.text
                                : "NO"
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CustomText
                            title="Pertenece a un pueblo originario o afroperuano"
                            value={
                              data.agdForanero.value
                                ? data.agdForanero.text
                                : "NO"
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTitle value="4. DATOS DEL PRESUNTO AGRESOR O AGRESORES" />
                    </Grid>
                    <Grid item xs={11}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} align="center">
                          <CustomText
                            title={`EL AGRESOR ES ${
                              data.aggressor === "Otro"
                                ? "UNA PERSONA EXTERNA"
                                : `UN ${data.aggressor.toUpperCase()}`
                            }`}
                          />
                        </Grid>
                        <Grid item xs={5} md={5}>
                          <CustomText
                            title="Tipo de documento"
                            value={data.agsTDoc}
                          />
                        </Grid>
                        <Grid item xs={7} md={7}>
                          <CustomText
                            title="Número de documento"
                            value={data.agsNDoc}
                          />
                        </Grid>
                        <Grid item xs={8} md={8} container alignItems="center">
                          <CustomText
                            title={
                              data.aggressor === "Estudiante"
                                ? "Iniciales"
                                : "Nombre Completo"
                            }
                            value={data.agsFullname}
                          />
                        </Grid>
                        <Grid item xs={4} md={4}>
                          <CustomText
                            title="Discapacidad"
                            value={
                              data.agsDiscapacidad.value
                                ? data.agsDiscapacidad.text
                                : "NO"
                            }
                          />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <CustomText title="Edad" value={data.agsAge} />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <CustomText title="Sexo" value={data.agsSexo} />
                        </Grid>

                        {data.aggressor === "Estudiante" ? (
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={3} sm={3} md={3}>
                                <CustomText
                                  title="Nivel"
                                  value={data.agsLevel}
                                />
                              </Grid>
                              <Grid item xs={3} sm={3} md={3}>
                                <CustomText
                                  title="Grado"
                                  value={data.agsGrade}
                                />
                              </Grid>
                              <Grid item xs={3} sm={3} md={3}>
                                <CustomText
                                  title="Sección"
                                  value={data.agsSection}
                                />
                              </Grid>
                              <Grid item xs={3} sm={3} md={3}>
                                <CustomText
                                  title="Turno"
                                  value={data.agsShift}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <CustomText
                                  title="Estudiante"
                                  value={data.prntSchool}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          <Fragment>
                            {data.aggressor === "Personal de la IE" && (
                              <Grid item xs={12}>
                                <CustomText
                                  title="Personal"
                                  value={data.prntSchool}
                                />
                              </Grid>
                            )}
                          </Fragment>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <CustomTitle value="5. ESPECIFIQUE EL TIPO DE VIOLENCIA QUE SUFRIÓ EL O LA ESCOLAR:" />
                    </Grid>
                    <Grid item xs={11}>
                      <Grid container spacing={0}>
                        {data.typeAggression.map((el, index) => (
                          <Grid item xs={4} sm={4} md={4} key={index}>
                            <CustomList value={el.name} icon={ArrowRightIcon} />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTitle value="6. ¿POR QUÉ CREE USTED QUE SE PRODUJO LA VIOLENCIA AL ESCOLAR?" />
                    </Grid>
                    <Grid item xs={11} container>
                      <Grid container spacing={0}>
                        {data.reason.map((el, index) => (
                          <Grid item xs={4} sm={4} md={4} key={index}>
                            <CustomList value={el.name} icon={ArrowRightIcon} />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTitle value="7. BREVE DESCRIPCIÓN DEL HECHO" />
                    </Grid>
                    <Grid item xs={11}>
                      <CustomText value={data.descEvent} />
                    </Grid>

                    <Grid item xs={12}>
                      <CustomTitle value="8. TRÁMITE SEGUIDO FRENTE AL HECHO DE VIOLENCIA ESCOLAR" />
                    </Grid>
                    <Grid item xs={11}>
                      <CustomText value={data.procedure} />
                    </Grid>

                    <Grid item xs={12}>
                      <CustomTitle value="9. MEDIDAS CORRECTIVAS Y DE PROTECCIÓN IMPLEMENTADAS POR LA INSTITUCIÓN EDUCATIVA" />
                    </Grid>
                    <Grid item xs={11}>
                      <CustomText value={data.metrics} />
                    </Grid>
                  </Grid>
                )}
              </Box>
            </div>
          </Paper>
          <br />
          <Grid container justify="flex-end" component={Box} pt={1} pb={1}>
            <Grid item>
              <ReactToPrint
                trigger={() => (
                  <Button variant="contained" color="primary">
                    IMPRIMIR
                  </Button>
                )}
                content={() => detailsRef.current}
                documentTitle={`REPORTE_DE_INCIDENCIAS_${Date.now()}`}
                removeAfterPrint={true}
                pageStyle={pageStyle}
              />
            </Grid>
          </Grid>
          <Paper elevation={5}>
            <div ref={detailsRef} className="page-break">
              <Box p={5}>
                {data && (
                  <Grid container justify="flex-end" spacing={2}>
                    <Grid item xs={12}>
                      <CustomHeader
                        title="FORMATO 2 DEL REGISTRO DE INCIDENCIAS"
                        correlative={data.correlative}
                        siseve={data.siseve}
                        dre={data.dre}
                        ugel={data.ugel}
                      />
                    </Grid>
                    {details.map((el) => (
                      <Fragment>
                        <Grid item xs={12}>
                          <CustomTitle value={el.status} />
                        </Grid>
                        <Grid item xs={11}>
                          <CustomText
                            title="Fecha del Reporte"
                            value={el.date_report}
                          />
                        </Grid>
                        <Grid item xs={11}>
                          <CustomText title="Medidas adoptadas" />
                          {el.actions.map((el) => (
                            <CustomList
                              value={`El ${el.date} ${el.action} ${el.aditional}`}
                              icon={SubdirectoryArrowRightIcon}
                            />
                          ))}
                        </Grid>
                      </Fragment>
                    ))}
                  </Grid>
                )}
              </Box>
            </div>
          </Paper>
          <br />
        </Fragment>
      )}
    </Container>
  );
};

export default ReportIncidence;

const CustomHeader = ({ title, correlative, siseve, dre, ugel }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" align="center">
          <b>{title}</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <CustomText title="CASO N°" value={correlative} align="center" />
      </Grid>
      <Grid item xs={6}>
        <CustomText
          title="N° REGISTRO EN SÍSEVE"
          value={siseve}
          align="center"
        />
      </Grid>
      <Grid item xs={4}>
        <CustomText
          title="I.E."
          value={sedeactual.description()}
          align="center"
        />
      </Grid>
      <Grid item xs={4}>
        <CustomText title="DRE" value={dre} align="center" />
      </Grid>
      <Grid item xs={4}>
        <CustomText title="UGEL" value={ugel} align="center" />
      </Grid>
    </Grid>
  );
};

const CustomText = ({ title, value, align = "flex-start" }) => {
  return (
    <Fragment>
      <Grid container spacing={1} justify={align}>
        {title && (
          <Grid item>
            <Typography variant="body2" color="textPrimary">
              <b>{title}</b>
            </Typography>
          </Grid>
        )}
        {value && (
          <Fragment>
            <Grid item>{" • "}</Grid>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {value}
              </Typography>
            </Grid>
          </Fragment>
        )}
      </Grid>
      <Divider />
    </Fragment>
  );
};

const CustomList = ({ value, icon: Icon }) => {
  return (
    <ListItem dense>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={value} />
    </ListItem>
  );
};

const CustomTitle = ({ value }) => {
  return (
    <Typography component="h2" variant="body1">
      <b>{value}</b>
      <Divider />
    </Typography>
  );
};
