import React from "react";
import { Select } from "antd";
import { typesDocuments } from "src/utils/constants";

const { Option } = Select;
const SelectConcepto = ({ value, setValue }) => {
  const filterOptions = (inputValue, option) => {
    const title = option.props.title.toLowerCase();
    return title.includes(inputValue.toLowerCase(), 0);
  };

  return (
    <Select
      showSearch
      size="large"
      style={{ width: "100%" }}
      value={value}
      onChange={setValue}
      filterOption={filterOptions}
    >
      <Option value={null} title="">
        -- NINGUNO --
      </Option>
      {typesDocuments.map((text, index) => (
        <Option key={index} value={text.key} title={text.label}>
          {text.label}
        </Option>
      ))}
    </Select>
  );
};
export default SelectConcepto;
