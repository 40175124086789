/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Table } from "antd";
import Highlighter from "react-highlight-words";
import { Button, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import moment from 'moment'

const CustomTable = ({ data, columns, loading, ...rest }) => {
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");
  const [allColumns, setAllColumns] = useState([]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <TextField
          variant="outlined"
          placeholder="Buscar..."
          size="small"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch(selectedKeys, confirm, dataIndex);
            }
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={() =>
            handleReset(clearFilters, selectedKeys, setSelectedKeys)
          }
          style={{ width: 90 }}
        >
          Limpiar <ClearIcon />
        </Button>{" "}
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
        >
          Buscar <SearchIcon />
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchIcon color={filtered ? "primary" : ""} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setsearchText(selectedKeys[0]);
    setsearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, selectedKeys, setSelectedKeys) => {
    clearFilters();
    setsearchText("");
    setSelectedKeys([""]);
  };

  useEffect(() => {
    if (columns.length > 0) {
      columns.map((row) => {
        if (row.children) {
          row.children.forEach((el) => {
            if (el.filter) {
              Object.assign(el, { ...getColumnSearchProps(el.dataIndex) });
            }
          });
        }
        if (row.filter) {
          Object.assign(row, { ...getColumnSearchProps(row.dataIndex) });
        }
        if (row.sorter) {
          if (row.type === 'date') row.sorter = (a, b) => (moment(a.date_upload, "DD/MM/YYYY").isAfter(moment(b.date_upload, "DD/MM/YYYY")) ? 1 : -1)
          else row.sorter = (a, b) => (a[row.dataIndex] > b[row.dataIndex] ? 1 : -1)
        }
        return row;
      });
      setAllColumns(columns);
    }
  }, [columns]);

  return (
    <Table
      bordered
      columns={allColumns}
      dataSource={data}
      scroll={{ x: "100%" }}
      rowKey="_id"
      loading={loading}
      {...rest}
    />
  );
};

export default CustomTable;
