import React, { useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import { Delete } from "@material-ui/icons";
import { notification } from "antd";

const MOTIVOS = [
  "RENUNCIA",
  "RENUNCIA CON INCENTIVOS",
  "DESPIDO O DESTITUCIÓN",
  "CESE COLECTIVO",
  "JUBILACIÓN",
  "INVALIDEZ ABSOLUTA PERMANENTE",
  "TERMINACIÓN DE LA OBRA O SERVICIO O VENCIMIENTO DEL PLAZO",
  "MUTUO DISENSO",
  "FALLECIMIENTO",
  "BAJA POR SUCESIÓN EN POSICIÓN DEL EMPLEADOR",
  "EXTINCIÓN O LIQUIDACIÓN DEL EMPLEADOR",
  "NO SE INICIO LA REL. LABORAL O PREST. DE SERVICIOS",
];

const ModalShowDates = ({ isOpen, data, setModal, info, setInfo }) => {
  const handleClose = () => setModal({ isOpen: false, data: null });

  const [disabledButton, setDisabledButton] = useState(false);

  const handleAdd = () => {
    const date = moment(info.dates[info.dates.length - 1].dateEnd).add(1, "day");
    info.dates.push({ dateStart: date, dateEnd: "" });
    setInfo({ ...info });
  };

  const handleRemove = (index) => () => {
    info.dates.splice(index, 1);
    setInfo({ ...info });

    if (info.dates.length === 0) setDisabledButton(false);
  };

  const handleChange = (index, key, value) => {
    info.dates[index][key] = value;
    setInfo({ ...info });
  };

  const handleBlur = (index, key, value) => {
    if (key === "dateEnd") {
      if (moment(value).isSameOrBefore(info?.dates[index - 1]?.dateEnd) && index > 0) {
        notification.warn({
          message:
            "La fecha de finalización no puede ser menor o igual a la fecha de finalización anterior",
        });
        setDisabledButton(true);
      } else {
        setDisabledButton(false);
      }
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Fechas Laborales</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {info.dates.map((item, index) => (
            <>
              <Grid item xs={12} md={3}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Fecha de inicio"
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="date"
                  value={moment(item.dateStart).format("YYYY-MM-DD")}
                  onChange={(e) => handleChange(index, "dateStart", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Fecha de finalización"
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="date"
                  value={moment(item.dateEnd).format("YYYY-MM-DD")}
                  onChange={(e) => handleChange(index, "dateEnd", e.target.value)}
                  onBlur={(e) => handleBlur(index, "dateEnd", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel>Motivo de baja</InputLabel>
                  <Select
                    size="small"
                    label="Motivo de baja"
                    value={item.motivoBaja}
                    onChange={(e) => handleChange(index, "motivoBaja", e.target.value)}
                    variant="outlined"
                  >
                    {MOTIVOS.map((el) => (
                      <MenuItem key={el} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {index === info.dates.length - 1 && (
                <Grid item xs={12} md={1}>
                  <Button variant="contained" color="secondary" onClick={handleRemove(index)}>
                    <Delete />
                  </Button>
                </Grid>
              )}
            </>
          ))}
          <Box style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAdd}
              disabled={
                !info.dates[info.dates.length - 1]?.dateEnd ||
                !info.dates[info.dates.length - 1]?.motivoBaja
              }
            >
              Agregar
            </Button>
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClose}
          disabled={disabledButton}
        >
          CERRAR
        </Button>
        <Button variant="outlined" color="primary" onClick={handleClose} disabled={disabledButton}>
          GUARDAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalShowDates;
