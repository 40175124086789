import React, { useState, useRef } from "react";
import axios from "axios";
import _ from "lodash/fp";
import useForm from "react-hook-form";
import { apiInventario } from "../../../../../config/api.contable";
import {
  Grid,
  FormHelperText,
  Typography,
  DialogActions,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { notification } from "antd";
import { makeStyles } from "@material-ui/styles";
import sedeactual from "../../../../../config/sedeactual";

const useStyles = makeStyles({
  error: {
    color: "red",
  },
});

const ModalAddLocacion = (props) => {
  const classes = useStyles();
  const { visible, setVisible, reload, setLoading } = props;
  const { register, handleSubmit, errors } = useForm();

  const addLocacion = async (newData) => {
    setLoading(true);
    let dataLoc = {
      name: newData.name.toUpperCase(),
      description: newData.description.toUpperCase(),
      sede: sedeactual.get(),
      token: sedeactual.token(),
    };

    await axios
      .post(
        `${apiInventario}/location/${sedeactual.get()}/${
          sedeactual.company().url
        }`,
        dataLoc
      )
      .then((res) => {
        notification["success"]({
          message: `Se agregó correctamente.`,
        });
        reload();
        setVisible(false);
      })
      .catch((error) => {
        notification["error"]({
          message: `No se pudo agregar el registro.`,
          description: `${error}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog maxWidth="md" fullWidth open={visible}>
      <DialogTitle>
        <Typography variant="h3">Agregar Locación</Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(addLocacion)} autoComplete="off">
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                label="Nombre"
                variant="outlined"
                fullWidth
                name="name"
                inputRef={register({
                  required: true,
                  maxLength: 120,
                })}
              />
              {_.get("name.type", errors) === "required" && (
                <FormHelperText className={classes.error}>
                  El nombre de la categoría es requerido.
                </FormHelperText>
              )}
              {_.get("name.type", errors) === "maxLength" && (
                <FormHelperText className={classes.error}>
                  La longitud máxima de caracteres es 120.
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Description"
                variant="outlined"
                fullWidth
                name="description"
                inputRef={register({
                  required: true,
                  maxLength: 120,
                })}
              />
              {_.get("description.type", errors) === "required" && (
                <FormHelperText className={classes.error}>
                  La descipción de la categoría es requerido.
                </FormHelperText>
              )}
              {_.get("description.type", errors) === "maxLength" && (
                <FormHelperText className={classes.error}>
                  La longitud máxima de caracteres es 120.
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setVisible(false)}
          >
            Cancelar
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Registrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalAddLocacion;
