import React from "react";
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";

const TableCostFormula = (props) => {
  const { data } = props;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 100 }}>NÚMERO</TableCell>
            <TableCell style={{ width: 300 }}>DESCRIPCIÓN</TableCell>
            <TableCell align="center">DETALLE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? (
            <>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center" style={{ padding: 0 }}>
                  <Table size="small">
                    <TableHead>
                      <TableCell align="center">COSTO</TableCell>
                      <TableCell align="center">VALOR</TableCell>
                    </TableHead>
                  </Table>
                </TableCell>
              </TableRow>
              {data.map((ev) => (
                <TableRow style={{ borderBottom: "2px solid #0688d175" }}>
                  <TableCell align="center">{ev.id}</TableCell>
                  <TableCell>{ev.description}</TableCell>
                  <TableCell style={{ padding: 0 }}>
                    <Table size="small">
                      <TableBody>
                        {ev.detail.map((e) => (
                          <TableRow>
                            <TableCell align="center" style={{ width: "50%" }}>
                              <Typography variant="body1" color="textSecondary">
                                {e.cost}
                              </Typography>
                            </TableCell>
                            <TableCell align="center" style={{ width: "50%" }}>
                              <Typography variant="body1" color="textSecondary">
                                {e.value}%
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan={2}>
                Aquí se mostrarán las formulas de costos
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableCostFormula;
