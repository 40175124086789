import React, { useState, useEffect } from "react";
import { notification, Icon, Typography } from "antd";
import {
  Button,
  Box,
  Select,
  Card,
  TextField,
  Grid,
  CardContent,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import SelectActiveSedes from "../Global/SelectActiveSedes";
import { getActiveSedes } from "../../api/api.contable";
import { apiContable } from "../../config/api.contable";
import axios from "axios";
import LoaderBackdrop from "../Global/LoaderBackdrop";
import sedeactual from "../../config/sedeactual";
import { useParams } from "react-router-dom";

const CreateUsuario = () => {
  const { idCompany, url } = useParams();
  const [dataSedes, setDataSedes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dni, setDni] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [type, setType] = useState("accountant");
  const [headquarter, setHeadquarter] = useState(null);
  const [username, setUsername] = useState("");

  const obtainedSedes = async () => {
    try {
      const result = await getActiveSedes(url);
      setDataSedes(result);
    } catch (error) {}
  };

  useEffect(() => {
    obtainedSedes();
  }, [obtainedSedes]);

  const sendData = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (headquarter) {
        let dataSend = {
          user: username,
          n_doc: dni,
          headquarter: headquarter,
          fullname: fullName,
          address: address,
          phone: phone,
          email: email,
          rol: type,
          company: idCompany,
        };
        await axios
          .post(`${apiContable}/create-user`, dataSend)
          .then((result) => {
            notification["success"]({
              message: "Éxito!",
              description: "El usuario se creó correctamente.",
            });
            setDni("");
            setFullName("");
            setEmail("");
            setAddress("");
            setPhone("");
            setType("accountant");
            setHeadquarter(null);
            setUsername("");
          })
          .catch((err) => {
            notification["info"]({
              message: "Validación",
              description: "El nombre de usuario ya fué tomado.",
            });
          });
      } else {
        notification["info"]({
          message: "Validación",
          description: "Debe seleccionar la sede.",
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Error",
        description: `Ocurrió un error al crear el usuario. ${error}`,
      });
    }
    setLoading(false);
  };

  const content = (
    <Box p={2}>
      {loading && <LoaderBackdrop visible={loading} />}
      <Card>
        <CardContent>
          <Typography variant="h3" color="textPrimary">
            <b>CREAR USUARIO</b>
          </Typography>
          <br /> <br />
          <form onSubmit={sendData}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={2} xl={3}>
                <TextField
                  label="DNI"
                  variant="outlined"
                  fullWidth
                  required
                  type="number"
                  value={dni}
                  onChange={(e) => setDni(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label="NOMBRE COMPLETO"
                  variant="outlined"
                  fullWidth
                  required
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <TextField
                  label="TEL / CEL"
                  variant="outlined"
                  type="tel"
                  fullWidth
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <TextField
                  label="CORREO"
                  variant="outlined"
                  type="email"
                  fullWidth
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  label="DIRECCIÓN"
                  variant="outlined"
                  type="address"
                  fullWidth
                  required
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>ROL</InputLabel>
                  <Select
                    label="ROL"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <MenuItem value="accountant">CONTADOR</MenuItem>
                    <MenuItem value="admin">ADMINISTRADOR</MenuItem>
                    <MenuItem value="superadmin">SUPERADMIN</MenuItem>
                    <MenuItem value="usuario">SUPERVISOR</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <TextField
                  label="NOMBRE DE USUARIO"
                  variant="outlined"
                  fullWidth
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectActiveSedes
                  defaultValue={headquarter}
                  data={dataSedes}
                  onChangeSelect={(e) => setHeadquarter(e)}
                />
              </Grid>
              <Grid item xs={12} align="center">
                <Button type="submit" color="primary" variant="contained">
                  GUARDAR USUARIO
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
  return content;
};
export default CreateUsuario;
