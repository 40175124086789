import React from "react";
import moment from "moment";
import CustomTable from "src/components/CustomTable";
import { Button, ButtonGroup, Chip, Tooltip } from "@material-ui/core";
import Receipt from "@material-ui/icons/Receipt";
import { CallMade, Delete } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import sedeactual from "src/config/sedeactual";

const TableMemorandums = ({ data, loading, setMemo, setDeleteMemo }) => {
  const navigate = useHistory();

  const handleRedirect = (params) => () => {
    let url = "",
      fixed = "";

    switch (params.param) {
      case "fixedasset":
        if (params.action.includes("EQUIPO")) fixed = "equipos";
        else if (params.action.includes("MUEBLE")) fixed = "muebles";
        else if (params.action.includes("VEHICULO")) fixed = "vehiculos";
        else if (params.action.includes("ENSERES")) fixed = "enseres";
        else if (params.action.includes("INTANGIBLE")) fixed = "intangibles";
        url = `/inventario/${fixed}`;
        break;
      case "ground":
        url = "/inventario/terrenos";
        break;
      case "edification":
        url = "/inventario/edificaciones";
        break;
      default:
        url = "/inventario";
        break;
    }
    return navigate.push(url);
  };

  const columns = [
    {
      title: "RESPONSABLE",
      dataIndex: "to",
      key: "to",
      align: "center",
      width: 200,
      render: (val, record) => record.to.fullname,
    },
    {
      title: "INFORMADOR",
      dataIndex: "from",
      key: "from",
      align: "center",
      width: 200,
      render: (val, record) => record.from.fullname,
    },
    {
      title: "ESTADO",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 150,
      render: (val, record) => (
        <Chip
          label={val}
          variant="default"
          size="small"
          color="primary"
          style={{
            backgroundColor: val === "PENDIENTE" ? "#FFC107" : "#54D62C",
          }}
        />
      ),
    },
    {
      title: "TAREA A REALIZAR",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 200,
    },
    {
      title: "REGISTRO AFECTADO",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      width: 200,
      render: (_, row) => {
        const length = row[row.param].length;
        return row[row.param]
          .map((item) => item.correlative)
          .map((item, index) => (
            <p
              key={index}
              style={{
                margin: 0,
                borderBottom: length === index + 1 ? "none" : "1px solid #eee",
              }}
            >
              {item}
            </p>
          ));
      },
    },
    {
      title: "FECHA",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: 150,
      render: (_, record) =>
        moment(record.createdAt).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "ACCIONES",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      width: 150,
      render: (_, record) => (
        <ButtonGroup variant="contained" size="small">
          <Tooltip title="VER DOCUMENTO">
            <Button
              color="primary"
              onClick={() => setMemo({ open: true, data: record })}
            >
              <Receipt />
            </Button>
          </Tooltip>
          {record.status === "PENDIENTE" && (
            <>
              <Tooltip title="IR A LOCACIÓN">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleRedirect(record)}
                >
                  <CallMade />
                </Button>
              </Tooltip>
              {sedeactual.getRol() !== "usuario" && (
                <Tooltip title="ELIMINAR">
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => setDeleteMemo({ open: true, data: record })}
                  >
                    <Delete />
                  </Button>
                </Tooltip>
              )}
            </>
          )}
        </ButtonGroup>
      ),
    },
  ];

  return (
    <CustomTable
      columns={columns}
      data={data}
      loading={loading}
      pagination={{ pageSize: 20 }}
    />
  );
};

export default TableMemorandums;
