import React, { useEffect, useState } from "react";
import Tables from "./components/TablesIEI";
import getColumns from "./components/ColumnsTrimestral";
import { getHeadquarters } from "../../api/api.contable";
import sedeactual from "../../config/sedeactual";
import ExportarTrimestral from "./components/ExportarTrimestral";
import { apiReportes } from "../../config/api.contable";
import SelectSedes from "../../components/Selects/SelectSedes";
import axios from "axios";
import { notification } from "antd";
import Page from "../../components/Page";
import {
  Grid,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import moment from "moment";

const periods = [
  {
    name: "1ER TRIMESTRE",
    num: 1,
  },
  {
    name: "2DO TRIMESTRE",
    num: 2,
  },
  {
    name: "3ER TRIMESTRE",
    num: 3,
  },
  {
    name: "4TO TRIMESTRE",
    num: 4,
  },
  {
    name: "TOTAL",
    num: 5,
  },
];

const ReporteTrimestral = () => {
  const [sedes, setSedes] = useState([]);
  const [sede, setSede] = useState(sedeactual.get());
  const [ingresos, setingresos] = useState([]);
  const [egresos, setegresos] = useState([]);
  const [inversion, setinversion] = useState([]);
  const [saldos, setSaldos] = useState([]);
  const [number, setnumber] = useState(1);
  const [loading, setloading] = useState(true);
  const [dataSend, setDataSend] = useState(null);

  const getData = async () => {
    setloading(true);
    try {
      let res = await axios.post(
        `${apiReportes}/reporte/trimestral/${sede}/${sedeactual.getAnio()}/${
          sedeactual.company().url
        }`
      );

      setDataSend(res.data);
      setinversion(res.data.inversion);
      setegresos(res.data.egresos);
      setingresos(res.data.ingresos);
      setSaldos(res.data.saldos);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al tratar de obtener la información.",
        description: `${error}`,
      });
    } finally {
      setloading(false);
    }
  };

  const getSedes = async () => {
    try {
      let resSedes = await getHeadquarters();
      setSedes(resSedes.data);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al tratar de obtener las sedes.",
        description: `${error}`,
      });
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sede]);

  useEffect(() => {
    if (
      sedeactual.getRol() === "supernap" ||
      sedeactual.getRol() === "superadmin"
    ) {
      getSedes();
    }
  }, []);

  return (
    <Page
      title="REPORTE TRIMESTRAL"
      helper="REPORTES"
      itemComponent={
        (sedeactual.getRol() === "supernap" ||
          sedeactual.getRol() === "superadmin") && (
          <div style={{ minWidth: 330 }}>
            <SelectSedes
              data={sedes}
              defaultValue={sede}
              onChangeSelect={(e) => setSede(e)}
            />
          </div>
        )
      }
    >
      <RadioGroup
        value={number}
        onChange={(e) => setnumber(Number(e.target.value))}
      >
        <Grid container spacing={1}>
          {periods.map((period) => (
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                fullWidth
                style={{ padding: 0 }}
              >
                <FormControlLabel
                  style={{ margin: 0 }}
                  value={period.num}
                  control={<Radio color="primary" style={{ padding: 6 }} />}
                  label={period.name}
                />
              </Button>
            </Grid>
          ))}
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ExportarTrimestral
              nombre={`TRIMESTRAL_${moment(Date.now()).format()}`}
              ruta={`reporte/trimestral-xlsx/${sede}/${sedeactual.getAnio()}/${
                sedeactual.company().url
              }`}
              body={dataSend}
              disabled={dataSend ? false : true}
            />
          </Grid>
        </Grid>
      </RadioGroup>
      <Tables
        inversion={inversion}
        egresos={egresos}
        ingresos={ingresos}
        saldos={saldos}
        loading={loading}
        columns={getColumns(number)}
      />
    </Page>
  );
};

export default ReporteTrimestral;
