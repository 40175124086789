/* eslint-disable no-unused-vars */
import React, { useEffect } from "react"
import axios from "axios"
import { Link as RouterLink } from "react-router-dom"
import clsx from "clsx"
import { ButtonBase, Grid, Typography } from "@material-ui/core"
import PropTypes from "prop-types"
import AuthenticationService from "../../../../auth/AuthenticationService"
import { makeStyles } from "@material-ui/styles"
import { apiContable } from "../../../../config/api.contable"
import anio from "../../../../config/anio"
import sedeactual from "../../../../config/sedeactual"
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  colors,
  Avatar,
  Tooltip,
} from "@material-ui/core"
import InputIcon from "@material-ui/icons/Input"
import MenuIcon from "@material-ui/icons/Menu"
import { useHistory } from "react-router-dom"
import { RefreshOutlined } from "@material-ui/icons"
import SessionStorageService from "src/auth/SessionStorageService"
import { SESSION_NAV, SESSION_YEAR } from "src/config/session"

const useStyles = makeStyles( ( theme ) => ( {
  root: {
    boxShadow: "none",
    bgcolor: "red !important"
  },
  flexGrow: {
    flexGrow: 1,
  },
  search: {
    backgroundColor: "rgba(255,255,255, 0.1)",
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing( 0, 2 ),
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    marginRight: theme.spacing( 2 ),
    color: "inherit",
  },
  searchInput: {
    flexGrow: 1,
    color: "inherit",
    "& input::placeholder": {
      opacity: 1,
      color: "inherit",
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing( 1 ),
  },
  trialButton: {
    marginLeft: theme.spacing( 2 ),
    color: theme.palette.white,
    backgroundColor: colors.green[ 600 ],
    "&:hover": {
      backgroundColor: colors.green[ 900 ],
    },
  },
  trialIcon: {
    marginRight: theme.spacing( 1 ),
  },
  notificationsButton: {
    marginLeft: theme.spacing( 1 ),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[ 600 ],
  },
  logoutButton: {
    marginLeft: theme.spacing( 1 ),
  },
  logoutIcon: {
    marginRight: theme.spacing( 1 ),
  },
  "MuiToolbar-root": {
    bgcolor: "red !important"

  }
} ) )

const TopBar = ( props ) => {
  const history = useHistory()
  const classes = useStyles()
  const { onOpenNavBarMobile, className } = props
  useEffect( () => {
    let mounted = true
    return () => {
      mounted = false
    }
  }, [] )

  const getValidationToken = async () => {
    let res = await axios.post( `${ apiContable }/validToken`, {
      token: sedeactual.token(),
    } )
    if ( !res.data.message ) {
      handleLogout()
    }
  }

  useEffect( () => {
    getValidationToken()
  }, [] )

  const handleLogout = () => {
    let url = sedeactual.company().url
    AuthenticationService.logout().then( () => {
      history.push( `/${ url }/login` )
    } )
  }

  const changeHead = () => {
    SessionStorageService.remove( SESSION_YEAR )
    SessionStorageService.remove( SESSION_NAV )
    window.location.href = "/"
  }

  return (
    <AppBar className={ clsx( classes.root, className ) }
      style={ { backgroundColor: "60e5cd48328de500159ab9a6" === sedeactual?.current_headquarter()?._id ? "#C72222" : "#0086cf" } }
    >
      <Toolbar>
        <RouterLink to="/">
          <ButtonBase>
            <Typography style={ { color: "#fff", marginRight: 10 } } variant="subtitle1">
              <Avatar
                src={ sedeactual.company().logo }
                alt="Logo Netappperu"
                style={ { height: 60, width: 60, backgroundColor: "#fff" } }
              />
            </Typography>
          </ButtonBase>
        </RouterLink>
        <Text value={ sedeactual.company().name } width={ 300 } />
        <div className={ classes.flexGrow } />
        <Hidden mdDown>
          <Grid container spacing={ 1 } direction="row" justify="flex-end">
            <Grid item style={ { width: 125 } }>
              <Button color="inherit" variant="outlined" onClick={ changeHead } fullWidth>
                <RefreshOutlined /> CAMBIAR
              </Button>
            </Grid>
            <Text value={ anio.get() } width={ 45 } />
            <Text value={ sedeactual.description() ?? "" } width={ 300 } />
            <Grid item style={ { width: 180 } }>
              <Button color="inherit" onClick={ handleLogout } variant="outlined" fullWidth>
                Cerrar Sesión <InputIcon />
              </Button>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={ onOpenNavBarMobile }>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
}

export default TopBar

const Text = ( { value, width } ) => {
  return (
    <Grid item style={ { width } } container alignItems="center">
      <Tooltip title={ value } arrow>
        <Typography variant="h5" color="inherit" noWrap>
          { value }
        </Typography>
      </Tooltip>
    </Grid>
  )
}
