import React from "react";
import { Button, Typography } from "@material-ui/core";
import { Modal, notification } from "antd";
import axios from "axios";
import { apiCuentas } from "../../../config/api.contable";

const DeletePresupuesto = (props) => {
  const { visible, setVisible, loading, setLoading, getBudgetList, data } =
    props;

  const deleteBudget = async () => {
    setLoading(true);
    try {
      await axios.delete(`${apiCuentas}/budget/list/${data._id}`);
      notification["success"]({
        message: "El registro se eliminó correctamente.",
      });
      getBudgetList();
      setVisible(false);
    } catch (error) {
      notification["error"]({
        message: `No se pudo eliminar el registro. ${error}`,
      });
    }
    setTimeout(() => setLoading(false), 1000);
  };

  return (
    <Modal
      title={
        <Typography align="center" variant="h3">
          <b>ELIMINAR PRESUPUESTO</b> <br />
          <Typography variant="body1" color="textSecondary">
            {data.number} - {data.description && data.description.toUpperCase()}
          </Typography>
        </Typography>
      }
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setVisible(false)}
        >
          Cancelar
        </Button>,
        <Button
          variant="contained"
          color="primary"
          onClick={deleteBudget}
          loading={loading}
        >
          confirmar
        </Button>,
      ]}
    >
      <Typography variant="h5" align="center">
        <b>¿Seguro que desea eliminar este registro?</b>
      </Typography>
      <Typography variant="body1" color="textSecondary" align="center">
        Ten en cuenta que ya no ya no estará disponible.
      </Typography>
    </Modal>
  );
};

export default DeletePresupuesto;
