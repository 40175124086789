import React, { useState } from "react";
import { notification, Spin } from "antd";
import { Button, Card, CardContent, Grid, Typography, TextField } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import sedeactual from "src/config/sedeactual";
import { uploadWorkersByCompany } from "src/utils/req.boletas";
import ExportarExcel from "src/components/Global/ExportarExcel";

const ImportWorkersByCompany = () => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const sendData = async () => {
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("file", file);

      await uploadWorkersByCompany(formData);
      setFile(null);
      notification["success"]({
        message: `LA IMPORTACIÓN CONCLUYÓ EXITOSAMENTE!`,
      });
    } catch (error) {
      notification["error"]({
        message: `Ocurrió un error al realizar la operación`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} align="center">
              <Typography variant="h4">
                <b>IMPORTAR TRABAJADORES POR COMPAÑIA</b>
              </Typography>
            </Grid>
            <Grid item xs={12} align="center">
              <ExportarExcel
                name="IMPORTACION_MASIVA_TRABAJADORES"
                endpoint={`company/excel-companies/${sedeactual.company().url}`}
                // body={}
                method="GET"
                disabled={loading}
                small={false}
                api={1}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Seleccionar archivo"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="file"
                inputProps={{
                  accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }}
                onChange={(e) => setFile(e.target.files[0])}
              />
            </Grid>
            <Grid item xs={12} align="center">
              <Button
                onClick={sendData}
                disabled={!file}
                variant="contained"
                color="primary"
                size="large"
              >
                IMPORTAR <PublishIcon />
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Spin>
  );
};

export default ImportWorkersByCompany;
