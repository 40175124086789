import React from "react";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import CreateIcon from "@material-ui/icons/Create";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));
const BtnEdit = ({small, disabled = false}) => {
  const classes = useStyles();
  return (
    <>
      {small ? (
        <Fab size="small" color="primary" className={classes.margin} disabled={disabled}>
          <CreateIcon />
        </Fab>
      ) : (
        <Button
        disabled={disabled}
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<CreateIcon />}
        >
          EDITAR
        </Button>
      )}
    </>
  );
};

export default BtnEdit;
