import { CardContent, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import Spinner from "src/components/Global/Spinner";
import Page from "src/components/Page";
import sedeactual from "src/config/sedeactual";
import TableReporteCambioPatrimonio from "./TableReporteCambioPatrimonio";

const year = sedeactual.getAnio();

const ReporteCambioPatrimonio = () => {
  const [data, setData] = useState("null");
  const [error, setError] = useState(false);

  return (
    <Page title="REPORTE DE ESTADO DE CAMBIO EN EL PATRIMONIO" helper="REPORTE">
      <CardContent align="center">
        <Typography variant="h3" color="textPrimary">
          <b>CEP SANTA ROSA MM.DD.</b>
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          Av. 28 de julio 909-Lima-Huacho
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          RUC N° 20599076894
        </Typography>
        <Typography variant="h5" color="textPrimary">
          <b>ESTADO DE CAMBIO EN EL PATRIMONIO</b>
        </Typography>
        <Typography variant="subtitle2" color="textPrimary">
          Por el año terminado al {moment().format("DD [de] MMMM [del] YYYY")}
        </Typography>
      </CardContent>
      {data ? (
        <TableReporteCambioPatrimonio year={year} />
      ) : error ? (
        <Typography color="error" variant="body2">
          No se pudo obtener la información, intente mas tarde.
        </Typography>
      ) : (
        <Spinner />
      )}
    </Page>
  );
};

export default ReporteCambioPatrimonio;
