import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormHelperText,
  InputAdornment,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import _ from "lodash/fp";
import { makeStyles } from "@material-ui/styles";
import useForm from "react-hook-form";
import moment from "moment";
import { apiInventario } from "../../../../../config/api.contable";
import axios from "axios";
import NumberFormat from "react-number-format";
import { notification } from "antd";

const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: "50%",
    maxWidth: "80%",
  },
  iconText: {
    color: "#aaa",
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //prefix="S/ "
    />
  );
}

const ModalAddVehiculos = (props) => {
  const classes = useStyles();
  const {
    visible,
    setVisible,
    reloadFunction,
    categories,
    idLocation,
    sede,
    loading,
    setLoading,
    company,
  } = props;
  const { register, handleSubmit, errors } = useForm();
  const [inputValueCat, setInputValueCat] = useState("");
  const [customValueCat, setCustomValueCat] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [subcategory, setSubcategory] = useState(null);
  const [values, setValues] = useState({
    historical_value: "",
  });

  const addRegister = async (data) => {
    if (idLocation != null && customValueCat != null) {
      setLoading(true);
      try {
        if (values.historical_value) {
          data.category = customValueCat._id;
          data.subcategory = subcategory ? subcategory : null;
          data.type = "vehicles";
          data.status = "BUENO";
          data.location = idLocation;
          data.sede = sede;
          data.amount = 1;
          data.historical_value = values.historical_value;
          data.bodywork = data.bodywork.toUpperCase();
          data.fuel = data.fuel.toUpperCase();
          data.color = data.color.toUpperCase();
          data.model = data.model.toUpperCase();
          data.vehicle_plate = data.vehicle_plate.toUpperCase();
          data.trademark = data.trademark.toUpperCase();

          if ((!data.model || !data.vehicle_plate) && !data.trademark) {
            notification["info"]({
              message:
                "La descripción es requerida en caso de no ingresar placa, modelo o marca. Verifique y vuelva a intentarlo.",
            });
            setLoading(false);
            return null;
          } else {
            await axios.post(`${apiInventario}/fixed-asset/${sede}/${company}`, data);
            notification["success"]({
              message: "El registro se guardó correctamente.",
            });
            reloadFunction();
            setVisible(false);
          }
        } else {
          notification["info"]({
            message: "Campos requeridos que falta completar.",
          });
        }
      } catch (err) {
        notification["error"]({
          message: "Ocurrió un error al agregar el registro.",
          description: `${err}`,
        });
      } finally {
        setLoading(false);
      }
    } else {
      notification["info"]({
        message: "Debe seleccionar un nombre de cuenta.",
      });
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value.toUpperCase(),
    });
  };

  return (
    <Dialog open={visible} classes={{ paper: classes.dialog }}>
      <DialogTitle>
        <Typography variant="h2" component="h2" color="textPrimary" align="center">
          AGREGAR VEHÍCULO
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(addRegister)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={8}>
              <Autocomplete
                id="combo-box-demo"
                options={categories}
                fullWidth
                getOptionLabel={(option) => option.name.toUpperCase()}
                renderOption={(option) => (
                  <React.Fragment>
                    <Typography component="span" variant="subtitle2">
                      {option.name.toUpperCase()}{" "}
                      <FormHelperText component="span">
                        {option.account_number} {option.account_name}
                      </FormHelperText>
                    </Typography>
                  </React.Fragment>
                )}
                inputValue={inputValueCat}
                onInputChange={(event, newInputValue) => {
                  setInputValueCat(newInputValue);
                }}
                onChange={(event, newValue) => {
                  setCustomValueCat(newValue);
                  setSubCategories([...newValue.subcategory]);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Categoria" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>SubCategorias</InputLabel>
                <Select
                  value={subcategory}
                  onChange={(event) => setSubcategory(event.target.value)}
                  label="SubCategorias"
                >
                  {subCategories.map((el) => (
                    <MenuItem value={el._id}>{el.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={3}>
              <TextField
                label="Marca"
                fullWidth
                variant="outlined"
                name="trademark"
                inputRef={register({
                  required: false,
                  maxLength: 100,
                  // pattern: /^[A-Za-z0-9.,]+$/i,
                })}
              />
              <FormHelperText error>
                {_.get("trademark.type", errors) === "required" &&
                  "La marca comercial es requerida."}
                {_.get("trademark.type", errors) === "maxLength" &&
                  "No debe exceder los 100 caracteres."}
                {_.get("trademark.type", errors) === "pattern" &&
                  "Solo se acepta números y caracteres alfabeticos."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                label="Modelo"
                fullWidth
                variant="outlined"
                name="model"
                inputRef={register({
                  required: false,
                  maxLength: 20,
                  // // pattern: /^[A-Za-z0-9.,]+$/i,
                })}
              />
              <FormHelperText error>
                {_.get("model.type", errors) === "required" && "El modelo es requerido."}
                {_.get("model.type", errors) === "maxLength" &&
                  "No debe exceder los 20 caracteres."}
                {_.get("model.type", errors) === "pattern" &&
                  "Solo se acepta caracteres alfabéticos y números."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <TextField
                label="Placa del Vehículo"
                fullWidth
                variant="outlined"
                name="vehicle_plate"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className={classes.iconText}>
                      N°
                    </InputAdornment>
                  ),
                }}
                inputRef={register({
                  required: false,
                  maxLength: 20,
                  // pattern: /^[a-zA-Z0-9 -_]+$/i,
                })}
              />
              <FormHelperText error>
                {_.get("vehicle_plate.type", errors) === "required" &&
                  "La vehicle_plate es requerida."}
                {_.get("vehicle_plate.type", errors) === "maxLength" &&
                  "No debe exceder los 20 caracteres."}
                {_.get("vehicle_plate.type", errors) === "pattern" &&
                  "Solo se acepta caracteres alfabéticos y números."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <TextField
                label="Número de motor"
                fullWidth
                variant="outlined"
                name="motor_number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className={classes.iconText}>
                      N°
                    </InputAdornment>
                  ),
                }}
                inputRef={register({
                  required: false,
                  maxLength: 20,
                  // pattern: /^[a-zA-Z0-9 -_]+$/i,
                })}
              />
              <FormHelperText error>
                {_.get("motor_number.type", errors) === "required" &&
                  "El número de motor es requerida."}
                {_.get("motor_number.type", errors) === "maxLength" &&
                  "No debe exceder los 20 caracteres."}
                {_.get("motor_number.type", errors) === "pattern" &&
                  "Solo se acepta caracteres alfabéticos y números."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <TextField
                label="Partida Registral"
                fullWidth
                variant="outlined"
                type="number"
                name="registration_starting"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className={classes.iconText}>
                      N°
                    </InputAdornment>
                  ),
                }}
                inputRef={register({
                  required: false,
                  maxLength: 20,
                })}
              />
              <FormHelperText error>
                {_.get("registration_starting.type", errors) === "required" &&
                  "El número de motor es requerida."}
                {_.get("registration_starting.type", errors) === "maxLength" &&
                  "No debe exceder los 20 caracteres."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" component="h5" color="textPrimary" align="center">
                    Descripción técnica
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                  <TextField
                    label="N° de Ejes"
                    fullWidth
                    variant="outlined"
                    type="number"
                    name="axles_number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" className={classes.iconText}>
                          EJES
                        </InputAdornment>
                      ),
                    }}
                    inputRef={register({
                      required: false,
                      maxLength: 20,
                      min: 1,
                      max: 10,
                    })}
                  />
                  <FormHelperText error>
                    {_.get("axles_number.type", errors) === "required" &&
                      "El número de motor es requerida."}
                    {_.get("axles_number.type", errors) === "maxLength" &&
                      "No debe exceder los 20 caracteres."}
                    {_.get("axles_number.type", errors) === "min" && "El valor mínimo es 1."}
                    {_.get("axles_number.type", errors) === "max" && "El valor máximo es 10."}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                  <TextField
                    label="N° de Asientos"
                    fullWidth
                    variant="outlined"
                    type="number"
                    name="seats_number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" className={classes.iconText}>
                          ASIENTOS
                        </InputAdornment>
                      ),
                    }}
                    inputRef={register({
                      required: false,
                      maxLength: 20,
                      min: 1,
                      max: 500,
                    })}
                  />
                  <FormHelperText error>
                    {_.get("seats_number.type", errors) === "required" &&
                      "El número de motor es requerida."}
                    {_.get("seats_number.type", errors) === "maxLength" &&
                      "No debe exceder los 20 caracteres."}
                    {_.get("seats_number.type", errors) === "min" && "El valor mínimo es 1."}
                    {_.get("seats_number.type", errors) === "max" && "Ingrese un número válido."}
                  </FormHelperText>
                </Grid>

                <Grid item xs={12} sm={6} lg={2}>
                  <TextField
                    label="N° de ruedas"
                    fullWidth
                    variant="outlined"
                    type="number"
                    name="wheels_number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" className={classes.iconText}>
                          RUEDAS
                        </InputAdornment>
                      ),
                    }}
                    inputRef={register({
                      required: false,
                      maxLength: 20,
                      min: 1,
                      max: 20,
                    })}
                  />
                  <FormHelperText error>
                    {_.get("wheels_number.type", errors) === "required" &&
                      "El número de motor es requerida."}
                    {_.get("wheels_number.type", errors) === "maxLength" &&
                      "No debe exceder los 20 caracteres."}
                    {_.get("wheels_number.type", errors) === "min" && "El valor mínimo es 1."}
                    {_.get("wheels_number.type", errors) === "max" && "Ingrese un número válido."}
                  </FormHelperText>
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    label="Carrocería"
                    fullWidth
                    variant="outlined"
                    name="bodywork"
                    inputRef={register({
                      required: false,
                      maxLength: 20,
                    })}
                  />
                  <FormHelperText error>
                    {_.get("bodywork.type", errors) === "required" && "La carrocería es requerida."}
                    {_.get("bodywork.type", errors) === "maxLength" &&
                      "No debe exceder los 20 caracteres."}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={12} lg={3}>
                  <TextField
                    label="Combustible"
                    fullWidth
                    variant="outlined"
                    name="fuel"
                    inputRef={register({
                      required: false,
                      maxLength: 20,
                    })}
                  />
                  <FormHelperText error>
                    {_.get("fuel.type", errors) === "required" && "El combustible es requerido."}
                  </FormHelperText>
                  <FormHelperText error>
                    {_.get("fuel.type", errors) === "maxLength" &&
                      "No debe exceder los 20 caracteres."}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    label="Color"
                    fullWidth
                    variant="outlined"
                    name="color"
                    inputRef={register({
                      required: false,
                      maxLength: 20,
                    })}
                  />
                  <FormHelperText error>
                    {_.get("color.type", errors) === "required" && "El color es requerido."}
                    {_.get("color.type", errors) === "maxLength" &&
                      "No debe exceder los 20 caracteres."}
                  </FormHelperText>
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    label="Año de fabricación"
                    fullWidth
                    variant="outlined"
                    name="production_year"
                    type="number"
                    inputRef={register({
                      required: false,
                      maxLength: 4,
                      min: 1800,
                      max: moment(Date.now()).format("YYYY"),
                    })}
                  />
                  <FormHelperText error>
                    {_.get("production_year.type", errors) === "required" &&
                      "El número de motor es requerida."}
                    {_.get("production_year.type", errors) === "maxLength" &&
                      "No debe exceder los 4 caracteres."}
                    {_.get("production_year.type", errors) === "min" && "El valor mínimo es 1800."}
                    {_.get("production_year.type", errors) === "max" &&
                      `El año ingresado no debe superar ${moment(Date.now()).format("YYYY")}`}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    label="Fecha de adquisición"
                    fullWidth
                    variant="outlined"
                    defaultValue={moment(Date.now()).format("YYYY-MM-DD")}
                    type="date"
                    name="acquisition_date"
                    inputRef={register({
                      required: true,
                    })}
                  />
                  <FormHelperText error>
                    {_.get("acquisition_date.type", errors) === "required" &&
                      "La fecha de adquisición es requerida"}
                  </FormHelperText>
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    label="Valor histórico"
                    fullWidth
                    variant="outlined"
                    name="historical_value"
                    value={values.historical_value}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className={classes.iconText}>
                          S/
                        </InputAdornment>
                      ),
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <br />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="outlined" onClick={() => setVisible(false)}>
            Cerrar
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={!customValueCat || !inputValueCat || !idLocation || loading}
          >
            Guardar
          </Button>
        </DialogActions>
        {categories.length > 0 || idLocation ? (
          ""
        ) : (
          <Typography align="right" color="secondary">
            No se encontraron categorías registradas. Registre categorías y vuelva a intentarlo.
          </Typography>
        )}
      </form>
    </Dialog>
  );
};

export default ModalAddVehiculos;
