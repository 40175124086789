import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { Table, Input, Icon } from "antd";
import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";
import Highlighter from "react-highlight-words";
import ButtonGroup from "@material-ui/core/ButtonGroup";

const TableArchivos = ({ data, seteditModal, setfile, setvisibleComment }) => {
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyPress={(e) => e.key === "Enter" && handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Limpiar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setsearchText(selectedKeys[0]);
    setsearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setsearchText("");
  }

  const columns = [
    {
      title: "NOMBRE",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: 200,
      ...getColumnSearchProps("name"),
      render: (text, record) => (
        <span style={{ float: "left" }}>{text.toUpperCase()}</span>
      ),
    },
    {
      title: "FECHA ARCHIVO",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: 100,
      ...getColumnSearchProps("date"),
      render: (text) => (
        <span> {moment(text).utc(false).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      title: "FECHA SUBIDA",
      dataIndex: "dateUpload",
      key: "dateUpload",
      align: "center",
      width: 100,
      ...getColumnSearchProps("dateUpload"),
      render: (text) => (
        <span> {text != null && moment(text).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      title: "ESTADO",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 125,
      ...getColumnSearchProps("status"),
      render: (text) => <span> {text}</span>,
    },
    {
      title: "ACCIONES",
      dataIndex: "url",
      key: "url",
      align: "center",
      width: 200,
      render: (text, record) => (
        <ButtonGroup>
          <Button
            variant="outlined"
            color="primary"
            target="_blank"
            href={text}
          >
            <GetAppIcon />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setvisibleComment(true);
              seteditModal(false);
              setfile(record);
            }}
          >
            Comentario
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      scroll={{ x: "100%" }}
      bordered
    />
  );
};

export default TableArchivos;
