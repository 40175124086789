import React,{useState} from "react";
import { Modal, Button } from "antd";

const ModalValidation = props => {
  const [visible, setvisible] = useState(true);
  function handleOk() {
    window.location.reload();
  }
  return (
    <>
      {" "}
      <div>
        <Modal title="OCURRIO UN ERROR" footer={null}  closable={false} visible={visible} onOk={handleOk}>
          <p>EL VOUCHER YA FUE INGRESADO ANTERIORMENTE</p>
          <button type="button" onClick={()=>handleOk()} className="btn btn-primary">Aceptar</button>
        </Modal>
      </div>
    </>
  );
};

export default ModalValidation;
