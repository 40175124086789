import React, { Fragment, useState, useEffect } from "react";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Details } from "@material-ui/icons";
import DetailsCovid from "./DetailsCovid";
import { Spin } from "antd";

const TableCovidDetails = ({ data = [], loading }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [modal, setModal] = useState({ open: false, data: null });
  const titles = [
    "Hipertensión arterial",
    "Enfermedad cardiovascular",
    "Diabetes Mellitus",
    "Obesidad con IMC de 40 a más",
    "Asma",
    "Enfermedad pulmonar crónica",
    "Insuficiencia renal crónica",
    "Enfermedad o tratamiento inmunosupresor",
    "Cáncer",
    "Otra condición de riesgo",
    "Sensación de alza térmica, fiebre o escalofríos",
    "Dolor de garganta o tos",
    "Estornudos o congestión nasal",
    "Dificultad para respirar",
    "Expectoración o flema amarilla o verdosa",
    "Dolor de músculos o articulaciones",
    "Dolor de cabeza",
    "Fatiga o malestar general",
    "Pérdida de gusto o de olfato",
    "Diarrea",
    "Contacto confirmado", //"Contacto con persona(s) con un caso confirmado de COVID-19",
    "Contacto sospechoso", //"Contacto con un caso sospechoso de COVID - 19",
    "¿Esta tomando alguna medicación?",
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Fragment>
      <Spin spinning={loading}>
        <TableContainer>
          <Table stickyHeader className="tablefe">
            <TableHead>
              <TableRow>
                <TableCell align="center" rowSpan={2}>
                  ACCIONES
                </TableCell>
                <TableCell align="center" rowSpan={2}>
                  NOMBRE COMPLETO
                </TableCell>
                <TableCell align="center" colSpan={10}>
                  COMORBILIDADES
                </TableCell>
                <TableCell align="center" colSpan={13}>
                  SINTOMAS EN LOS ULTIMOS 14 DIAS
                </TableCell>
              </TableRow>
              <TableRow>
                {titles.map((e, index) => (
                  <TableCell key={e} align="center">
                    <Tooltip title={e} arrow placement="top">
                      <Typography
                        noWrap
                        variant="inherit"
                        style={{
                          writingMode: "vertical-rl",
                          transform: "rotate(-180deg)",
                          maxHeight: 150,
                          cursor: "help",
                        }}
                      >
                        {e}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((e, index) => {
                  const {
                    hipertension,
                    cardiovascular,
                    diabetes,
                    obesidad,
                    asma,
                    pulmonar,
                    renal,
                    inmunosupresor,
                    cancer,
                    otra,
                  } = JSON.parse(e.comorbilidad);
                  return (
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          padding: 0,
                          paddingLeft: "20px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setModal({ open: true, data: e })}
                        >
                          <Details />
                        </Button>
                      </TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          paddingLeft: "10px",
                        }}
                      >
                        {e.worker.f_name}, {e.worker.l_name}
                      </TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${
                            hipertension ? "#0086CF" : "#F4F6F8"
                          }`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${
                            cardiovascular ? "#0086CF" : "#F4F6F8"
                          }`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${
                            diabetes ? "#0086CF" : "#F4F6F8"
                          }`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${
                            obesidad ? "#0086CF" : "#F4F6F8"
                          }`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${asma ? "#0086CF" : "#F4F6F8"}`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${
                            pulmonar ? "#0086CF" : "#F4F6F8"
                          }`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${renal ? "#0086CF" : "#F4F6F8"}`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${
                            inmunosupresor ? "#0086CF" : "#F4F6F8"
                          }`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${cancer ? "#0086CF" : "#F4F6F8"}`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${otra ? "#0086CF" : "#F4F6F8"}`,
                        }}
                      ></TableCell>
                      {/* //hdsbfhsdbjfbhsd */}
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${
                            e.fiebre ? "#F73A60" : "#F4F6F8"
                          }`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${
                            e.garganta ? "#F73A60" : "#F4F6F8"
                          }`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${
                            e.estornudos ? "#F73A60" : "#F4F6F8"
                          }`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${
                            e.respirar ? "#F73A60" : "#F4F6F8"
                          }`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${
                            e.expectoracion ? "#F73A60" : "#F4F6F8"
                          }`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${
                            e.musculos ? "#F73A60" : "#F4F6F8"
                          }`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${
                            e.cabeza ? "#F73A60" : "#F4F6F8"
                          }`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${
                            e.fatiga ? "#F73A60" : "#F4F6F8"
                          }`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${e.gusto ? "#F73A60" : "#F4F6F8"}`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${
                            e.diarrea ? "#F73A60" : "#F4F6F8"
                          }`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${
                            e.contactoConfirm ? "#F73A60" : "#F4F6F8"
                          }`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${
                            e.contactoSospe ? "#F73A60" : "#F4F6F8"
                          }`,
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          backgroundColor: `${
                            e.medicacion ? "#F73A60" : "#F4F6F8"
                          }`,
                        }}
                      ></TableCell>
                      {/* {Object.entries(e).map((x, inx) => {
                  console.log(x);
                  return (
                    <TableCell
                      align="center"
                      key={inx}
                      style={{
                        backgroundColor: `${x.status}`,
                        // color: `${x >= 0 ? '#fff' : '#000'}`,
                      }}
                    ></TableCell>
                  );
                })} */}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        {modal.open && (
          <DetailsCovid
            open={modal.open}
            setOpen={setModal}
            data={modal.data}
          />
        )}
      </Spin>
    </Fragment>
  );
};

export default TableCovidDetails;
