import React, { useState } from "react";
import Highlighter from "react-highlight-words";
import BtnDelete from "../../../components/Global/components/Botones/BtnDelete";
import BtnEdit from "../../../components/Global/components/Botones/BtnEdit";
import { Icon, Input, Button, Table } from "antd";
import EditPresupuesto from "./EditPresupuesto";
import DeletePresupuesto from "./DeletePresupuesto";
import napformat from "../../../config/napformat";
import sedeactual from "../../../config/sedeactual";

const TablePresupuesto = ({ data, loading, setLoading, getBudgetList }) => {
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");
  const [modalEditBudget, setModalEditBudget] = useState({
    data: null,
    open: false,
  });
  const [modalDeleteBudget, setModalDeleteBudget] = useState({
    data: null,
    open: false,
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyPress={(e) => e.key === "Enter" && handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Limpiar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setsearchText(selectedKeys[0]);
    setsearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setsearchText("");
  }

  let columns = [
    {
      title: "NÚMERO",
      dataIndex: "number",
      key: "number",
      width: 150,
      ...getColumnSearchProps("number"),
      sorter: (a, b) => a.number - b.number,
    },
    {
      title: "DESCRIPCIÓN",
      dataIndex: "description",
      key: "description",
      width: 400,
      ...getColumnSearchProps("description"),
      render: (text, record, index) =>
        record.description ? record.description.toUpperCase() : "",
    },
    {
      title: "MONTO",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      width: 150,
      ...getColumnSearchProps("amount"),
      sorter: (a, b) => a.amount - b.amount,
      render: (text, record, index) => "S/" + napformat(text),
    },
    {
      title: "ACCIONES",
      dataIndex: "_id",
      key: "_id",
      width: 125,
      render: (e, record) =>
        sedeactual.getRol() !== "usuario" && (
          <>
            <span
              onClick={() => setModalEditBudget({ data: record, open: true })}
            >
              <BtnEdit small />
            </span>
            <span
              onClick={() => setModalDeleteBudget({ data: record, open: true })}
            >
              <BtnDelete small />
            </span>
          </>
        ),
    },
  ];

  return (
    <>
      {modalEditBudget.open && (
        <EditPresupuesto
          visible={modalEditBudget.open}
          setVisible={setModalEditBudget}
          loading={loading}
          setLoading={setLoading}
          getBudgetList={getBudgetList}
          data={modalEditBudget.data}
        />
      )}
      {modalDeleteBudget.open && (
        <DeletePresupuesto
          visible={modalDeleteBudget.open}
          setVisible={setModalDeleteBudget}
          loading={loading}
          setLoading={setLoading}
          getBudgetList={getBudgetList}
          data={modalDeleteBudget.data}
        />
      )}
      <Table
        columns={columns}
        bordered
        dataSource={data}
        loading={loading}
        scroll={{ x: "100%", y: false }}
      />
    </>
  );
};

export default TablePresupuesto;
