import React, { useState, useEffect } from "react";
import { putAuxCuentas } from "../../../api/api.contable";
import { notification, Icon, Modal } from "antd";

const EditarAuxiliarCuentas = (props) => {
  const { visible, setVisible, data, getData } = props;
  const [description, setDescription] = useState(data.description);

  async function handleOkEdit() {
    try {
      await putAuxCuentas(data._id, description);
      notification.open({
        message: "Auxiliar de cuentas",
        description: "El registro fue editado satisfactoriamente.",
        icon: <Icon type="check" style={{ color: "#49E02D" }} />,
        key: `open${Date.now()}`,
      });
      getData();
      setVisible({ visible: false });
    } catch (error) {
      notification.open({
        message: "Auxiliar de cuentas",
        description: `Ocurrio un error al editar el registro. ${error}`,
        icon: <Icon type="close" style={{ color: "#FE2E27" }} />,
        key: `open${Date.now()}`,
      });
    }
  }

  return (
    <Modal
      onOk={handleOkEdit}
      onCancel={() => setVisible({ visible: false })}
      title="Editar"
      visible={visible}
    >
      <label>Descripción</label>
      <input
        type="text"
        value={description}
        onChange={(event) => setDescription(event.target.value)}
        className="form-control"
        name="description"
      />
    </Modal>
  );
};

export default EditarAuxiliarCuentas;
