import { colors } from "@material-ui/core"

const white = "#FFFFFF"
const black = "#000000"
const napcontable = "#0288d1"

export default {
  black,
  white,
  primary: {
    contrastText: white,
    // dark: colors.indigo[900],
    dark: '#3a5eaa',
    main: napcontable,
    light: colors.indigo[ 100 ]
  },
  success: {
    contrastText: "#1de9b6",
    dark: "#1de9b6",
    main: "#1de9b6",
    light: "#1de9b6"
  },
  secondary: {
    contrastText: white,
    dark: colors.red[ 900 ],
    main: "#f42727",
    light: colors.red[ 400 ]
  },
  inherit: {
    main: "#d10c0c",
  },
  error: {
    contrastText: white,
    dark: colors.red[ 900 ],
    main: colors.red[ 600 ],
    light: colors.red[ 400 ]
  },
  text: {
    primary: colors.blueGrey[ 900 ],
    secondary: colors.blueGrey[ 600 ],
    link: colors.blue[ 600 ]
  },
  link: colors.blue[ 800 ],
  icon: colors.blueGrey[ 600 ],
  background: {
    default: "#F4F6F8",
    paper: white
  },
  divider: colors.grey[ 200 ]
}
