import React, { useState, useEffect } from "react";
import { makeStyles, Button } from "@material-ui/core";
import { Dropdown, notification } from "antd";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TablaTerrenos from "./TablaTerrenos";
import Page from "../../../../components/Page";
import ModalAddTerreno from "./components/ModalAddTerreno";
import { getGrounds } from "../../requests";
import ExportarExcel from "../../../../components/Global/ExportarExcel";
import sedeactual from "../../../../config/sedeactual";
import LoaderBackdrop from "../../../../components/Global/LoaderBackdrop";
import { MenuDropDown } from "../../Memorandums";

const useStyles = makeStyles((theme) => ({
  buttonDisable: {
    margin: "10px 0",
    backgroundColor: "#f0f0f0",
    color: "#BD1214",
  },
  buttonEnable: {
    margin: "10px 0",
    backgroundColor: "#f0f0f0",
    color: "#4caf50",
  },
}));

const Terrenos = () => {
  const classes = useStyles();
  const [openModalAddTerreno, setOpenModalAddTerreno] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [memorandum, setMemorandum] = useState({ open: false, type: "", data: [], keys: [] });

  useEffect(() => {
    obtainData();
  }, [isActive]);

  const obtainData = async () => {
    setLoading(true);
    try {
      const res = await getGrounds(isActive);
      setData(res);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al obtener la información.",
        description: `${error}`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page
      title="TERRENOS"
      helper="INVENTARIO"
      itemComponent={
        <>
          <Dropdown
            overlay={
              <MenuDropDown
                onClick={(e) => setMemorandum({ ...memorandum, open: true, type: e.key })}
                toTransfer={false}
              />
            }
            disabled={
              memorandum.data.length === 0 || sedeactual.getRol() === "usuario"
            }
          >
            <Button color="primary" variant="contained">
              MEMORANDUM
            </Button>
          </Dropdown>{" "}
          <Button
            className={!isActive ? classes.buttonEnable : classes.buttonDisable}
            variant="contained"
            onClick={() => setIsActive(!isActive)}
          >
            {!isActive ? <PersonAddIcon /> : <PersonAddDisabledIcon />}{" "}
            {!isActive ? "HABILITADO" : "DESHABILITADO"}
          </Button>{" "}
          <Button variant="contained" color="primary" onClick={() => setOpenModalAddTerreno(true)}   disabled={ sedeactual.getRol() === "usuario" }>
            <AddIcon /> AGREGAR
          </Button>{" "}
          <ExportarExcel
            name={`TERRENOS_${Date.now().toString()}`}
            endpoint={`export-list-grounds/${sedeactual.get()}/${sedeactual.company().url}`}
            body={data}
            method="POST"
            disabled={loading}
            small={false}
            api={2}
          />
        </>
      }
    >
      <br />
      <TablaTerrenos
        data={data}
        reload={obtainData}
        loading={loading}
        setLoading={setLoading}
        isActive={isActive}
        memorandum={memorandum}
        setMemorandum={setMemorandum}
      />
      {openModalAddTerreno && (
        <ModalAddTerreno
          visible={openModalAddTerreno}
          setVisible={setOpenModalAddTerreno}
          reload={obtainData}
          setLoading={setLoading}
        />
      )}
      {loading && <LoaderBackdrop />}
    </Page>
  );
};

export default Terrenos;
