import React, { Fragment, useState } from "react";
import CustomTable from "src/components/CustomTable";
import { Avatar, Fab, IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import GetAppIcon from "@material-ui/icons/GetApp";
import Delete from "@material-ui/icons/Delete";
import ModalConfirmDelete from "src/components/ModalConfirmDelete";
import { apiContable } from "src/config/api.contable";
import sedeactual from "src/config/sedeactual";

const TableBoletas = ({ data, loading, obtainData }) => {
  const [del, setDel] = useState({ open: false, id: "" });

  const columns = [
    {
      title: "#",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      width: 60,
      render: (val, row, index) => (
        <Avatar style={{ backgroundColor: "#0683c9" }}>{index + 1}</Avatar>
      ),
    },
    {
      title: "FECHA DE SUBIDA",
      dataIndex: "date_upload",
      key: "date_upload",
      align: "center",
      width: 150,
      sorter: true,
      filter: true,
    },
    {
      title: "FECHA DE PAGO",
      dataIndex: "date_voucher",
      key: "date_voucher",
      align: "center",
      width: 150,
      sorter: true,
      filter: true,
    },
    {
      title: "COMPROBANTE",
      dataIndex: "detail",
      key: "detail",
      align: "center",
      width: 400,
      sorter: true,
      filter: true,
    },
    {
      title: "ESTADO",
      dataIndex: "isView",
      key: "isView",
      align: "center",
      width: 150,
      sorter: true,
      filter: false,
      render: (value) => (
        <Alert severity={value ? "success" : "info"}>
          {value ? "VISTO" : "PENDIENTE"}
        </Alert>
      ),
    },
    {
      title: "ARCHIVO",
      dataIndex: "file",
      key: "file",
      align: "center",
      width: 100,
      sorter: false,
      filter: false,
      render: (value) => (
        <IconButton
          size="small"
          color="primary"
          component="a"
          href={value}
          target="_blank"
        >
          <GetAppIcon />
        </IconButton>
      ),
    },
    {
      title: "OPC",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      width: 60,
      sorter: false,
      filter: false,
      render: (value) => (
        <Fab
          size="small"
          color="secondary"
          disabled={sedeactual.getRol() === "usuario"}
          onClick={() => setDel({ open: true, id: value })}
        >
          <Delete />
        </Fab>
      ),
    },
  ];

  return (
    <Fragment>
      {del.open && (
        <ModalConfirmDelete
          visible={del.open}
          setVisible={setDel}
          reload={obtainData}
          endpoint={`${apiContable}/voucherfile/${del.id}`}
          title="¿Seguro(a) que desea eliminar este registro?"
          subtitle="Ten en consideración que el comprobante ya no estará disponible."
          disable={false}
        />
      )}

      <CustomTable
        columns={columns}
        data={data}
        loading={loading}
        pagination={false}
      />
    </Fragment>
  );
};

export default TableBoletas;
