import React, { useState } from "react";
import AddFile from "./components/AddFile";
import { FormHelperText, Grid, Button, Typography } from "@material-ui/core";
import { Modal, notification, Select } from "antd";
import sedeactual from "../../config/sedeactual";
import ModalComment from "./components/ModalComment";
import {
  deleteFile,
  getCompanyFiles,
  getAllFiles,
} from "../../api/api.contable";
import Page from "../../components/Page";
import TableArchivos from "./components/TableArchivos";
import TableArchivosAdmin from "./components/TableArchivosAdmin";
import LoaderBackdrop from "../../components/Global/LoaderBackdrop";
import moment from "moment";

const { Option } = Select;

const typeFiles = [
  "Voucher aporte 10% de Comunidades",
  "Voucher Alquiler de Obras",
  "Balance Mensual",
  "Estado de cuenta de Bancos",
  "Otros Documentos",
  "Boletas de remuneraciones",
  "Control de asistencia",
  "Todos",
];

const meses = [
  "ENERO",
  "FEBRERO",
  "MARZO",
  "ABRIL",
  "MAYO",
  "JUNIO",
  "JULIO",
  "AGOSTO",
  "SETIEMBRE",
  "OCTUBRE",
  "NOVIEMBRE",
  "DICIEMBRE",
];

const Archivos = () => {
  const [visible, setvisible] = useState(false);
  const [data, setdata] = useState([]);
  const [month, setmonth] = useState(moment().month() + 1);
  const [visibleComment, setvisibleComment] = useState(false);
  const [visibleDelete, setvisibleDelete] = useState(false);
  const [loading, setloading] = useState(false);
  const [urlFile, seturlFile] = useState();
  const [editModal, seteditModal] = useState(false);
  const [typeFile, settypeFile] = useState("Voucher aporte 10% de Comunidades");
  const [file, setfile] = useState(null);

  async function eliminarFile(row) {
    try {
      await deleteFile(row._id);
      notification["success"]({
        message: "Eliminar archivo",
        description: "El archivo fue eliminado satisfactoriamente.",
      });
      searchAdmin();
    } catch (error) {
      notification["error"]({
        message: "Eliminar archivo",
        description: "Ocurrio un error al eliminar el archivo.",
      });
    } finally {
      setvisibleDelete(false);
    }
  }

  const search = async () => {
    setloading(true);
    const res = await getCompanyFiles(month);
    const newData = res.filter(
      (e) =>
        e.period === month &&
        e.year === sedeactual.getAnio() &&
        (typeFile === "Todos" ? true : e.type === typeFile)
    );
    setdata(newData);
    setloading(false);
  };

  const searchAdmin = async () => {
    setloading(true);
    const response = await getAllFiles(month);
    let arreglado = [];
    response.forEach((e) => {
      if (
        e.period === month &&
        e.year === sedeactual.getAnio() &&
        (e.type === typeFile || typeFile === "Todos")
      ) {
        e.company = e.headquarter.description;
        arreglado.push(e);
      }
    });
    setdata(arreglado);
    setloading(false);
  };

  const reloadFunction = () => {
    if (
      sedeactual.getRol() === "supernap" ||
      sedeactual.getRol() === "superadmin"
    ) {
      searchAdmin();
    } else {
      search();
    }
  };

  return (
    <Page
      title="ARCHIVOS"
      helper="FILES"
      itemComponent={
        <Button
          color="primary"
          variant="contained"
          onClick={() => setvisible(true)}
          fullWidth
          disabled={sedeactual.getRol() === "usuario"}
        >
          SUBIR
        </Button>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={5} lg={6}>
          <FormHelperText>TIPOS</FormHelperText>
          <Select
            value={typeFile}
            onChange={(e) => settypeFile(e)}
            size="large"
            style={{ width: "100%" }}
          >
            {typeFiles.map((e) => (
              <Option value={e}>{e}</Option>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <FormHelperText>SELECCIONE EL MES</FormHelperText>
          <Select
            onChange={(e) => setmonth(e)}
            value={month}
            size="large"
            style={{ width: "100%" }}
          >
            {meses.map((e, index) => (
              <Option value={index + 1}>{e}</Option>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <FormHelperText />
          <Button
            onClick={() =>
              sedeactual.getRol() === "supernap" ||
              sedeactual.getRol() === "superadmin"
                ? searchAdmin()
                : search()
            }
            fullWidth
            color="primary"
            variant="outlined"
          >
            BUSCAR
          </Button>
        </Grid>
        <Grid item xs={12}>
          {sedeactual.getRol() === "supernap" ||
          sedeactual.getRol() === "superadmin" ? (
            <TableArchivosAdmin
              data={data}
              seturlFile={seturlFile}
              setvisibleComment={setvisibleComment}
              setfile={setfile}
              seteditModal={seteditModal}
              setvisibleDelete={setvisibleDelete}
              refresh={reloadFunction}
            />
          ) : (
            <TableArchivos
              data={data}
              setvisibleComment={setvisibleComment}
              seteditModal={seteditModal}
              setfile={setfile}
            />
          )}
        </Grid>
      </Grid>

      {visibleComment && (
        <ModalComment
          visible={visibleComment}
          setvisible={setvisibleComment}
          edit={editModal}
          file={file}
          refresh={reloadFunction}
        />
      )}
      {visibleDelete && (
        <Modal
          title={
            <Typography variant="h4" align="center">
              ELIMINAR
            </Typography>
          }
          visible={visibleDelete}
          onCancel={() => setvisibleDelete(false)}
          footer={[
            <Button
              onClick={() => setvisibleDelete(false)}
              variant="outlined"
              color="primary"
            >
              CANCELAR
            </Button>,
            <Button
              onClick={() => eliminarFile(urlFile)}
              variant="contained"
              color="primary"
            >
              CONFIRMAR
            </Button>,
          ]}
        >
          <Typography variant="body1" align="center" color="textSecondary">
            ¿Esta seguro que desea eliminar el archivo?
          </Typography>
        </Modal>
      )}
      {visible && (
        <AddFile
          typeFiles={typeFiles}
          visible={visible}
          setvisible={setvisible}
          setLoading={setloading}
          refresh={reloadFunction}
        />
      )}
      {loading && <LoaderBackdrop />}
    </Page>
  );
};

export default Archivos;
