import React, { useEffect, useState } from "react";
import { Table } from "antd";
import napformat from "../../../config/napformat";
import BtnEdit from "../../../components/Global/components/Botones/BtnEdit";
import BtnDelete from "../../../components/Global/components/Botones/BtnDelete";
import { FormHelperText } from "@material-ui/core";
const TableFormVoucher = (props) => {
  const [dataInfo, setdataInfo] = useState([]);
  let data = [];
  useEffect(() => {
    let cuentax;
    let glosax;
    let refx;
    let monedax;
    let debex;
    let haberx;
    let accionesx;

    props.data.forEach((cliente, index) => {
      let { id, cuenta, glosa, tipo, monto, tipoDocumento, moneda, ref_payment } = cliente;
      let indexmoneda = props.TipodeCambio.findIndex((e) => e.id == moneda);
      if (indexmoneda >= 0) {
        moneda = props.TipodeCambio[indexmoneda].alp;
      }
      let indexcli = props.cuenta5d.findIndex((e) => e._id == cuenta);
      if (indexcli >= 0) {
        id = props.cuenta5d[indexcli].id;
        cuenta = props.cuenta5d[indexcli].number;
      }
      // else {
      //   return null;
      // }
      glosa = glosa?.toUpperCase() ?? "";

      cuentax = cuenta;
      glosax = glosa;
      refx = tipoDocumento;
      monedax = moneda;
      debex = tipo == "DEBE" ? `S/${napformat(Number((monto * cliente.tipoCambio).toFixed(2)))}` : "";
      haberx = tipo == "HABER" ? `S/${napformat(Number((monto * cliente.tipoCambio).toFixed(2)))}` : "";
      accionesx = {
        index: index,
        id: cliente.id,
        cuenta: cuenta,
      };
      ref_payment = data.push({
        cuenta: cuentax,
        glosa: glosax,
        ref: refx,
        ref_payment: ref_payment,
        moneda: monedax,
        debe: debex,
        haber: haberx,
        acciones: accionesx,
      });
    });
    // diferencia
    data.push({
      cuenta: "",
      glosa: "",
      ref: "",
      moneda: "",
      debe: `S/${napformat(SumaDebe())}`,
      haber: `S/${napformat(SumaHaber())}`,
      acciones: "",
    });
    // final
    data.push({
      cuenta: "",
      glosa: "",
      ref: "",
      moneda: "DIFERENCIA",
      debe: "",
      haber: `S/${napformat(Sumatotal())}`,
      acciones: "",
    });
    setdataInfo([...data]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  function deleteModal(id) {
    const index = props.data.findIndex((e) => e.id == id);

    let cambio = props.data;
    cambio.splice(index, 1);
    props.setData([...cambio]);
  }

  function SumaHaber() {
    let suma = 0;
    props.data.forEach((objeto) => {
      if (objeto.tipo == "HABER") {
        suma += Number((objeto.monto * objeto.tipoCambio).toFixed(2));
      }
    });

    return suma;
  }

  function SumaDebe() {
    let suma = 0;
    props.data.forEach((objeto) => {
      if (objeto.tipo == "DEBE") {
        suma += Number((objeto.monto * objeto.tipoCambio).toFixed(2));
      }
    });
    return suma;
  }

  function Sumatotal() {
    let suma = 0;
    props.data.forEach((objeto) => {
      if (objeto.tipo == "DEBE") {
        suma = Number((suma + Number((objeto.monto * objeto.tipoCambio).toFixed(2))).toFixed(2));
      } else {
        suma = Number((suma - Number((objeto.monto * objeto.tipoCambio).toFixed(2))).toFixed(2));
      }
    });
    props.setsumaTotal(suma);

    return suma;
  }

  let columns = [
    {
      title: "Cuenta",
      dataIndex: "cuenta",
      key: "cuenta",
    },
    {
      title: "Glosa",
      dataIndex: "glosa",
      key: "glosa",
    },
    {
      title: "Ref. M. Pago.",
      dataIndex: "ref_payment",
      key: "ref_payment",
    },
    {
      title: "Moneda",
      dataIndex: "moneda",
      key: "moneda",
    },
    {
      title: "DEBE",
      dataIndex: "debe",
      key: "debe",
      align: "right",
    },
    {
      title: "HABER",
      dataIndex: "haber",
      key: "haber",
      align: "right",
    },
    {
      title: "ACCIONES",
      dataIndex: "acciones",
      key: "acciones",
      width: "15%",
      render: (text, row) => {
        return text.cuenta !== undefined || (props.edit && row.cuenta === null) ? (
          <div>
            <span onClick={() => deleteModal(text.id)}>
              <BtnDelete small={true} />
            </span>
            <span
              onClick={() => {
                props.EditModal(text.index);
              }}
            >
              <BtnEdit small={true} />
            </span>
          </div>
        ) : (
          <></>
        );
      },
    },
  ];
  return <Table pagination={false} columns={columns} bordered dataSource={dataInfo} />;
};

export default TableFormVoucher;
