import { SESSION_NAME, SESSION_YEAR } from "src/config/session";

const user_data = JSON.parse(localStorage.getItem(SESSION_NAME));

class sedeactual {
  static get() {
    let user = user_data;
    return user && user.value.current_headquarter ? user.value.current_headquarter.id : null;
  }

  static getRuc() {
    let user = user_data;
    return user && user.value.current_headquarter ? user.value.current_headquarter.ruc : null;
  }

  static getRol() {
    let user = user_data;
    return user ? user.value.rol : null;
  }

  static token() {
    let user = user_data;
    return user ? user.value.api_token : null;
  }

  static getAnio() {
    let año = JSON.parse(localStorage.getItem(SESSION_YEAR));
    var fecha = new Date();
    var anio = fecha.getFullYear();
    if (año == null) {
      return anio;
    }
    return año.value;
  }

  static digits() {
    let user = user_data;
    return user ? user.value.current_headquarter.digits : 6;
  }

  static validar(e) {
    let user = user_data;
    if (
      user &&
      user.value.current_headquarter &&
      user.value.current_headquarter.id.toString() === e.toString()
    ) {
      return true;
    } else return false;
  }

  static description() {
    let user = user_data;
    return user && user.value.current_headquarter
      ? user.value.current_headquarter.description
      : null;
  }

  static getFullName() {
    let user = user_data;
    return user.value.fullname;
  }
  static getUsername() {
    let user = user_data;
    return user.value.user;
  }
  static isCollege() {
    let user = user_data;
    if (user && user.value.current_headquarter && user.value.current_headquarter.ruc != null) {
      return true;
    } else {
      return false;
    }
  }
  static user_id() {
    let user = user_data;
    return user && user.value ? user.value._id : null;
  }

  // INFORMACIÓN DE LA COMPAÑIA
  static company() {
    let user = user_data;
    return user && user.value ? user.value.company : null;
  }

  static modules() {
    let user = user_data;
    return user && user.value.modules ? user.value.modules : null;
  }

  static sede_id() {
    let user = user_data;
    return user && user.value.current_headquarter ? user.value.current_headquarter._id : null;
  }

  static current_headquarter() {
    let user = user_data;
    return user ? user.value.current_headquarter : null;
  }

  static current_user() {
    let user = user_data;
    return user ? user.value : null;
  }
}

export default sedeactual;
