import React, { useState, useEffect, useRef } from "react";
import {
  Paper,
  Card,
  CardContent,
  Typography,
  FormControl,
  Button,
} from "@material-ui/core";
import { Select } from "antd";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Link } from "react-router-dom";
import sedeactual from "../../../config/sedeactual";
import { makeStyles } from "@material-ui/styles";
import SelectSedes from "../../../components/Usuarios/SelectSedes";
import { apiReportes } from "../../../config/api.contable";
import axios from "axios";
import moment from "moment";
import Spinner from "../../../components/Global/Spinner";
import TableDiario from "./TableCaja";
import { getHeadquarters } from "../../../api/api.contable";
import { notification } from "antd";
import ExportarExcel from "src/components/Global/ExportarExcel";

const useStyles = makeStyles((theme) => ({
  selectsedes: {
    maxWidth: "calc(100% - 650px)",
    minWidth: 310,
    textAlign: "left",
    marginRight: "5px",
  },
}));

const ReporteCaja = () => {
  let anho = sedeactual.getAnio();
  const { Option } = Select;
  const classes = useStyles();
  const componentRef = useRef();
  const rucHeadquarters = sedeactual.getRuc();
  const [reportModeSelected, setReportModeSelected] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dataCaja, setDataCaja] = useState([]);
  const [error, setError] = useState(false);
  const [sedeSelected, setSedeSelected] = useState(sedeactual.get());
  const [sedes, setSedes] = useState([]);
  const [titleReport, setTitleReport] = useState({
    title: sedeactual.description(),
  });

  const getTotalData = async () => {
    try {
      setLoading(true);
      setError(false);
      let data = {
        period: reportModeSelected,
        kind: reportModeSelected === 0 ? "anual" : "mensual",
      };
      let res = await axios.post(
        `${apiReportes}/cash-banks/${anho}/${sedeSelected}/${
          sedeactual.company().url
        }`,
        data
      );
      setDataCaja(res.data);
    } catch (error) {
      setError(true);
      notification["error"]({
        message: `${error}`,
        description: "No se logró obtener la información para el reporte.",
      });
    } finally {
      setLoading(false);
    }
  };

  const getSedes = async () => {
    try {
      let result = await getHeadquarters();
      setSedes(result.data);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se logró obtener las sedes.",
      });
    }
  };

  useEffect(() => {
    getSedes();
  }, []);

  const content = (
    <Paper style={{ padding: 25 }}>
      <div align="right">
        <Link to={{ pathname: "/reportes" }}>
          <Button variant="outlined" color="primary" style={{ float: "left" }}>
            <ArrowBackIosIcon /> VOLVER
          </Button>
        </Link>
        {(sedeactual.getRol() === "supernap" ||
          sedeactual.getRol() === "superadmin") && (
          <FormControl
            variant="outlined"
            size="small"
            className={classes.selectsedes}
          >
            <SelectSedes
              defaultValue={sedeSelected}
              data={sedes}
              setTitle={setTitleReport}
              onChangeSelect={(e) => {
                setSedeSelected(e);
              }}
            />
          </FormControl>
        )}{" "}
        <Select
          defaultValue={reportModeSelected}
          style={{ width: 310 }}
          onChange={(value) => setReportModeSelected(value)}
          size="large"
        >
          <Option value={0}>Anual</Option>
          <Option value={1}>Enero</Option>
          <Option value={2}>Febrero</Option>
          <Option value={3}>Marzo</Option>
          <Option value={4}>Abril</Option>
          <Option value={5}>Mayo</Option>
          <Option value={6}>Junio</Option>
          <Option value={7}>Julio</Option>
          <Option value={8}>Agosto</Option>
          <Option value={9}>Setiembre</Option>
          <Option value={10}>Octubre</Option>
          <Option value={11}>Noviembre</Option>
          <Option value={12}>Diciembre</Option>
        </Select>{" "}
        <Button
          color="primary"
          variant="contained"
          style={{ margin: "0 10px" }}
          disabled={!Boolean(sedeSelected && anho)}
          onClick={getTotalData}
        >
          BUSCAR
        </Button>{" "}
        <ExportarExcel
          name={`CAJA_BANCOS_${Date.now().toString()}`}
          endpoint={`cash-banks/export-excel/${anho}/${sedeactual.get()}/${
            sedeactual.company().url
          }`}
          body={{
            period: reportModeSelected,
            kind: reportModeSelected === 0 ? "anual" : "mensual",
          }}
          method="POST"
          disabled={!Boolean(sedeSelected && anho)}
          small={false}
          api={4}
        />
        {/* <ReactToPrint
          trigger={() => (
            <Button color="primary" variant="outlined">
              <PrintIcon /> IMPRIMIR
            </Button>
          )}
          content={() => componentRef.current}
        /> */}
      </div>
      <Card ref={componentRef} align="center">
        <CardContent>
          <Typography variant="h3" color="textPrimary">
            CAJA Y BANCOS
          </Typography>
          <Typography
            variant="subtitle2"
            color="textPrimary"
            style={{ textTransform: "capitalize" }}
          >
            {reportModeSelected === 0 ? (
              <>Reporte anual {anho}</>
            ) : (
              `${moment(anho + "-" + reportModeSelected + "-01").format(
                "MMMM"
              )} del ${anho}`
            )}
          </Typography>
          <Typography variant="h6" color="textPrimary">
            {titleReport.title && titleReport.title.toUpperCase()}
          </Typography>
          <Typography variant="subtitle1" color="textPrimary">
            N° RUC {rucHeadquarters}
          </Typography>
          <div style={{ marginTop: 25 }}>
            {loading ? (
              <Spinner />
            ) : (
              <>
                {error ? (
                  <Typography variant="h6" color="error">
                    Ocurrió un error al obtener la información. Intente más
                    tarde.
                  </Typography>
                ) : (
                  <TableDiario data={dataCaja} />
                )}
              </>
            )}
          </div>
        </CardContent>
      </Card>
    </Paper>
  );
  return content;
};

export default ReporteCaja;
