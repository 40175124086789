import React from "react";
import { Select } from "antd";

const tipos = [
  {
    code: "M",
    label: "MASCULINO",
  },
  {
    code: "F",
    label: "FEMENINO",
  },
];

const SelectSexo = ({ setSexo, sexo }) => {
  const { Option } = Select;

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const renderOptions = () => {
    return tipos.map((i) => (
      <Option value={i.code} title={i.label}>
        {i.label}
      </Option>
    ));
  };

  const filterOptions = (inputValue, option) => {
    const title = removeAccents(option.props.title.toLowerCase());
    return title.includes(inputValue.toLowerCase());
  };

  return (
    <Select
      size="large"
      style={{ width: "100%" }}
      showSearch
      value={sexo}
      onChange={(e) => setSexo(e)}
      filterOption={filterOptions}
    >
      <Option key={0} value={null} title={""}>
        {" "}
        - Ninguno -{" "}
      </Option>
      {renderOptions()}
    </Select>
  );
};
export default SelectSexo;
