import React, { Fragment } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import napformat from "../../../config/napformat";

const TableBalanceDetallado = ({ data, year, month }) => {
  return (
    <Grid>
      {data.map((dt) => (
        <>
          <Typography variant="h3">{dt.title}</Typography>
          {dt.content.map((fv) => (
            <CustomContent title={fv.title} data={fv.content} year={year} />
          ))}
          {dt.total && <FinallyTotal year={year} data={dt.total} />}
          <br />
          <br />
        </>
      ))}
    </Grid>
  );
};

export default TableBalanceDetallado;

const CustomContent = ({ title, data, year }) => {
  return (
    <Grid item xs={12}>
      <TableContainer>
        <Table size="small">
          {title && (
            <TableHead>
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Typography variant="h3" color="inherit">
                    <b>{title}</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {data.map((el, index) =>
              el.number.toString().length > 2 ? (
                <Fragment key={index}>
                  <TableRow>
                    <TableCell style={{ margin: 0, padding: 0 }} colSpan={3}>
                      <Box ml={5}>
                        <Table size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant={el.number === `TOTAL ${title}` ? "h5" : "inherit"}
                                >
                                  {el.number === `TOTAL ${title}`
                                    ? el.number
                                    : `${el.number} ─ ${el.name ?? ""}`}
                                </Typography>
                              </TableCell>
                              <TableCell style={{ width: 200 }} align="right">
                                {el.number === `TOTAL ${title}` ? (
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 17,
                                    }}
                                  >
                                    s/ {napformat(el[year - 1])}
                                  </span>
                                ) : (
                                  `s/ ${napformat(el[year - 1])}`
                                )}
                              </TableCell>
                              <TableCell style={{ width: 200 }} align="right">
                                {el.number === `TOTAL ${title}` ? (
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 17,
                                    }}
                                  >
                                    s/ {napformat(el[year])}
                                  </span>
                                ) : (
                                  `s/ ${napformat(el[year])}`
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    </TableCell>
                  </TableRow>
                </Fragment>
              ) : el.name ? (
                <TableRow key={index} style={{ backgroundColor: "#0688d125" }}>
                  <TableCell>{el.number}</TableCell>
                  <TableCell>{el.name}</TableCell>
                </TableRow>
              ) : (
                <TableRow key={index} style={{ backgroundColor: "#0688d125" }}>
                  <TableCell>{el.number}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

const FinallyTotal = ({ data, year }) => {
  return (
    <Grid item xs={12}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="right" colSpan={2}>
                <Typography variant="h3" color="inherit">
                  <b>{year - 1}</b>
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="h3" color="inherit">
                  <b>{year}</b>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={{ backgroundColor: "#0688d150" }}>
              <TableCell>
                <b>{data.number}</b>
              </TableCell>
              <TableCell align="right">
                <b>s/ {napformat(data[year - 1])}</b>
              </TableCell>
              <TableCell align="right">
                <b>s/ {napformat(data[year])}</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
