import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import SelectCuentaMajor from "../../components/Selects/SelectCuenta";
import ExportarExcel from "../../components/Global/ExportarExcel";
import anio from "../../config/anio";
import TablaMayor from "./TablaMayor";
import SedeActual from "../../config/sedeactual";
import SelectSedes from "../Usuarios/SelectSedes";
import sedeactual from "../../config/sedeactual";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SearchIcon from "@material-ui/icons/Search";
import Page from "../../components/Page/Page";
import {
  getAccountPlanMayor,
  getActiveSedes,
  getMajorReport,
} from "../../api/api.contable";
import { notification } from "antd";
import moment from "moment";

var ranges = [
  { value: 1, name: "Enero" },
  { value: 2, name: "Febrero" },
  { value: 3, name: "Marzo" },
  { value: 4, name: "Abril" },
  { value: 5, name: "Mayo" },
  { value: 6, name: "Junio" },
  { value: 7, name: "Julio" },
  { value: 8, name: "Agosto" },
  { value: 9, name: "Setiembre" },
  { value: 10, name: "Octubre" },
  { value: 11, name: "Noviembre" },
  { value: 12, name: "Diciembre" },
];

const ExportarMayor = () => {
  const [sede, setsede] = useState(SedeActual.get());
  const [cuenta5d, setCuentas5d] = useState([]);
  const [kind, setkind] = useState("mensual");
  const [desde, setdesde] = useState(1);
  const [hasta, sethasta] = useState(2);

  const [dataAccounts, setdataAccounts] = useState([]);
  const [mes, setmes] = useState(1);
  const [cuenta, setCuenta] = useState();
  const [dataMayor, setdataMayor] = useState([]);
  const [anho, setanho] = useState();
  const [sedes, setSedes] = useState([]);
  const [loading, setLoading] = useState(false);

  const getSedesAndCuentas = async () => {
    setLoading(true);
    try {
      let result = await getActiveSedes();
      let result2 = await getAccountPlanMayor(sede, SedeActual.getAnio());
      setSedes(result);
      setdataAccounts(result2);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se pudo obtener las sedes y las cuentas.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dataAccounts.length > 0) {
      setCuentas5d(dataAccounts);
      setCuenta(dataAccounts[0]._id);
    }
  }, [dataAccounts, sede]);

  const getMajor = async () => {
    try {
    setLoading(true);

    let obj = {
      sede: sede,
      anio: SedeActual.getAnio(),
      period: mes,
      account_id: cuenta,
      kind: kind,
    };

    if (kind == "intervalos") {
      obj.from = Number(desde);
      obj.to = Number(hasta);
    }

    let res = await getMajorReport(obj);

    let data = res.data;
    setdataMayor(data.mayorList);
  } catch (error) {
    notification.error({message:'No se pudo obtener el reporte mayor.'})
  } finally {
    setLoading(false);

  }
  };

  useEffect(() => {
    setanho(anio.get());
    getSedesAndCuentas();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sede]);

  const content = (
    <Page
      helper="REPORTES"
      title="REPORTE MAYOR"
      itemComponent={
        <Grid container style={{ minWidth: 280 }} spacing={1}>
          <Grid item xs={6}>
            <Link to={{ pathname: "/reportes" }}>
              <Button variant="outlined" color="primary" fullWidth>
                <ArrowBackIosIcon /> ATRAS
              </Button>
            </Link>
          </Grid>
          <Grid item xs={6}>
            <ExportarExcel
              name={`MAYOR_${moment(Date.now()).format()}`}
              endpoint={`excel-report-major/${anho}/${sede}/${
                sedeactual.company().url
              }`}
              body={{
                sede: sede,
                anio: anho,
                token: sedeactual.token(),
                period: mes,
                account_id: cuenta,
                kind: kind,
                from: Number(desde),
                to: Number(hasta),
              }}
              method="POST"
              disabled={loading}
              small={false}
              api={4}
            />
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={1}>
        {(sedeactual.getRol() === "supernap" ||
          sedeactual.getRol() === "superadmin") && (
          <Grid item xs={12} sm={6} md={6} lg={6} xl={2}>
            <FormHelperText>SEDE</FormHelperText>
            <SelectSedes
              defaultValue={sede}
              data={sedes}
              onChangeSelect={(e) => {
                setsede(e);
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={6} lg={6} xl={2}>
          <FormHelperText>CUENTA</FormHelperText>
          <SelectCuentaMajor
            defaultValue={cuenta}
            onChangeSelect={(e) => setCuenta(e)}
            data={cuenta5d}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <FormHelperText>MODO</FormHelperText>
          <FormControl variant="outlined" size="small" fullWidth>
            <Select value={kind} onChange={(e) => setkind(e.target.value)}>
              <MenuItem value="mensual">Mensual</MenuItem>
              <MenuItem value="intervalos">Intervalos</MenuItem>
              <MenuItem value="anual">Anual</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {kind === "intervalos" && (
          <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
            <FormHelperText>DESDE</FormHelperText>
            <FormControl variant="outlined" size="small" fullWidth>
              <Select value={desde} onChange={(e) => setdesde(e.target.value)}>
                {ranges.map((e) => (
                  <MenuItem value={e.value}>{e.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {kind === "intervalos" && (
          <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
            <FormHelperText>HASTA</FormHelperText>
            <FormControl variant="outlined" size="small" fullWidth>
              <Select value={hasta} onChange={(e) => sethasta(e.target.value)}>
                {ranges.map((e) => {
                  if (e.value > desde) {
                    return <MenuItem value={e.value}>{e.name}</MenuItem>;
                  }
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
        {kind == "mensual" && (
          <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
            <FormHelperText>MES</FormHelperText>
            <FormControl variant="outlined" size="small" fullWidth>
              <Select value={mes} onChange={(e) => setmes(e.target.value)}>
                <MenuItem value="1">Enero</MenuItem>
                <MenuItem value="2">Febrero</MenuItem>
                <MenuItem value="3">Marzo</MenuItem>
                <MenuItem value="4">Abril</MenuItem>
                <MenuItem value="5">Mayo</MenuItem>
                <MenuItem value="6">Junio</MenuItem>
                <MenuItem value="7">Julio</MenuItem>
                <MenuItem value="8">Agosto</MenuItem>
                <MenuItem value="9">Setiembre</MenuItem>
                <MenuItem value="10">Octubre</MenuItem>
                <MenuItem value="11">Noviembre</MenuItem>
                <MenuItem value="12">Diciembre</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <FormHelperText />
          <Button
            onClick={() => getMajor()}
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
          >
            <SearchIcon />
            BUSCAR
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TablaMayor data={dataMayor} loading={loading} />
        </Grid>
      </Grid>
    </Page>
  );
  return content;
};

export default ExportarMayor;
