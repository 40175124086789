import React, { useState } from "react";
import { Table } from "antd";
import {
  ButtonGroup,
  Button,
  TextField,
  Tooltip,
  Chip,
} from "@material-ui/core";
import Highlighter from "react-highlight-words";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import SearchOutlined from "@material-ui/icons/SearchOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import EditCategory from "../components/ModalEditCategoria";
import ModalConfirmDelete from "../../ModalConfirmDelete";
import AddSubCategory from "../AddSubCategory";
import EditSubCategory from "../EditSubCategory";
import sedeactual from "src/config/sedeactual";

const TableCategory = (props) => {
  const focusUsernameInputField = (input) => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 100);
    }
  };

  const { dataTable, reloadFunction, loading, setLoading, index, accounts } =
    props;
  const [searchedColumn, setsearchedColumn] = useState("");
  const [searchText, setsearchText] = useState("");
  const [modalDeleteCategory, setModalDeleteCategory] = useState({
    idCat: "",
    open: false,
  });
  const [modalEditCategory, setModalEditCategory] = useState({
    data: {},
    open: false,
  });
  const [subCategory, setSubCategory] = useState({
    data: null,
    open: false,
  });
  const [editSubCategory, setEditSubCategory] = useState({
    data: null,
    open: false,
  });
  const [deleteSubCategory, setDeleteSubCategory] = useState({
    _id: null,
    open: false,
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <TextField
          variant="outlined"
          placeholder="Buscar..."
          size="small"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter")
              handleSearch(selectedKeys, confirm, dataIndex);
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={() =>
            handleReset(clearFilters, selectedKeys, setSelectedKeys)
          }
          style={{ width: 90 }}
        >
          Limpiar <ClearIcon />
        </Button>{" "}
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
        >
          Buscar <SearchIcon />
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "" : "" }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setsearchText(selectedKeys[0]);
    setsearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, selectedKeys, setSelectedKeys) => {
    clearFilters();
    setsearchText("");
    setSelectedKeys([""]);
  };

  const columns = [
    {
      title: "NOMBRE",
      dataIndex: "name",
      key: "name",
      width: 150,
      align: "center",
      sorter: (a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
      },
      ...getColumnSearchProps("name"),
      render: (text) => (
        <span style={{ float: "left", marginLeft: "5px" }}>
          {text.toUpperCase()}
        </span>
      ),
    },
    {
      title: "CUENTA",
      dataIndex: "_id",
      key: "_id",
      width: 150,
      align: "center",
      render: (text, record) => (
        <span>
          {record.account_number} {record.account_name}
        </span>
      ),
    },
    {
      title: "SUBCATEGORIAS",
      dataIndex: "_id",
      key: "_id",
      width: 150,
      align: "center",
      render: (text, record) => (
        <span>
          {record.subcategory.map((el) => (
            <Chip
              size="small"
              label={el.name}
              color="primary"
              style={{ margin: 1 }}
              onClick={() => setEditSubCategory({ data: el, open: true })}
              onDelete={() => setEditSubCategory({ data: el, open: true })} //  setDeleteSubCategory({ _id: el._id, open: true })
              deleteIcon={<EditIcon />}
            />
          ))}
        </span>
      ),
    },
    {
      title: "ACCIONES",
      dataIndex: "_id",
      key: "_id",
      width: 90,
      align: "center",
      render: (text, record) => (
        <ButtonGroup size="small" color="primary">
          <Button
            variant="contained"
            onClick={() => setSubCategory({ data: record, open: true })}
            disabled={sedeactual.getRol() === "usuario"}
          >
            <Tooltip title="Agregar SubCategoría" placement="top">
              <AddIcon />
            </Tooltip>
          </Button>
          <Button
            color="primary"
            onClick={() => setModalEditCategory({ data: record, open: true })}
            disabled={sedeactual.getRol() === "usuario"}
          >
            <Tooltip title="Editar" placement="top">
              <EditIcon />
            </Tooltip>
          </Button>
          <Button
            color="secondary"
            disabled={sedeactual.getRol() === "usuario"}
            onClick={() => {
              setModalDeleteCategory({
                idCat: text,
                open: true,
              });
            }}
          >
            <Tooltip title="Eliminar" placement="top">
              <DeleteIcon />
            </Tooltip>
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  return (
    <>
      {modalEditCategory.open && (
        <EditCategory
          visible={modalEditCategory.open}
          setVisible={setModalEditCategory}
          reloadFunction={reloadFunction}
          data={modalEditCategory.data}
          setLoading={setLoading}
          accounts={accounts}
        />
      )}
      {modalDeleteCategory.open && (
        <ModalConfirmDelete
          visible={modalDeleteCategory.open}
          setVisible={setModalDeleteCategory}
          reload={reloadFunction}
          endPoint={`category/${modalDeleteCategory.idCat}`}
          title="¿Esta seguro(a) que desea eliminar esta Categoría?"
          subtitle="Tener en cuenta que la Categoría ya no estará disponible."
        />
      )}
      <Table
        columns={columns}
        dataSource={dataTable}
        size="small"
        bordered
        scroll={{ x: "100%" }}
        pagination={5}
        loading={loading}
      />
      {Boolean(subCategory.open) && (
        <AddSubCategory
          open={subCategory.open}
          setOpen={setSubCategory}
          data={subCategory.data}
          reloadFunction={reloadFunction}
        />
      )}
      {Boolean(editSubCategory.open) && (
        <EditSubCategory
          open={editSubCategory.open}
          setOpen={setEditSubCategory}
          data={editSubCategory.data}
          reloadFunction={reloadFunction}
        />
      )}
      {deleteSubCategory.open && (
        <ModalConfirmDelete
          visible={deleteSubCategory.open}
          setVisible={setDeleteSubCategory}
          reload={reloadFunction}
          endPoint={`subcategory/${deleteSubCategory._id}`}
          title="¿Esta seguro(a) que desea eliminar esta Sub Categoría?"
          subtitle="Tener en cuenta que la Sub Categoría ya no estará disponible."
        />
      )}
    </>
  );
};

export default TableCategory;
