import React, { Fragment, useState } from "react";
import CustomTable from "src/components/CustomTable";
import moment from "moment";
import napformat from "src/config/napformat";
import { ButtonGroup, Button } from "@material-ui/core";
import ModalClains from "./ModalClaims";
import ModalAsnwerClaim from "./ModalAsnwerClaim";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import RateReviewIcon from "@material-ui/icons/RateReview";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { Alert } from "antd";

const TableTransactions = ({ data, loading, setLoading, refreshFunction }) => {
  const [modalAnswer, setModalAnswer] = useState({ open: false, data: null });
  const [modalResume, setModalResume] = useState({
    open: false,
    data: null,
    before: false,
  });

  const columns = [
    {
      title: "ACCIONES",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      sorter: false,
      filter: false,
      width: 200,
      render: (value, row) => (
        <ButtonGroup size="small">
          <Button
            color="secondary"
            variant="outlined"
            onClick={() =>
              setModalResume({ open: true, data: row, before: false })
            }
          >
            <ArrowLeftIcon />
            <PictureAsPdfIcon />
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setModalAnswer({ open: true, data: row })}
            disabled={!Boolean(row.status === "Abierto")}
          >
            <RateReviewIcon />
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() =>
              setModalResume({ open: true, data: row, before: true })
            }
            disabled={!Boolean(row.dateResponse)}
          >
            <PictureAsPdfIcon />
            <ArrowRightIcon />
          </Button>
        </ButtonGroup>
      ),
    },
    {
      title: "TIPO",
      dataIndex: "claimKind",
      key: "claimKind",
      align: "center",
      sorter: false,
      filter: false,
      width: 150,
      render: (value, row) => (
        <span>
          {row.claimKind === "Claim" ? "RECLAMO" : "QUEJA"}
          {" DEL "}
          {row.properly === "Product" ? "PRODUCTO" : "SERVICIO"}
        </span>
      ),
    },
    {
      title: "TIPO DOCUMENTO",
      dataIndex: "csmDocumentKind",
      key: "csmDocumentKind",
      align: "center",
      sorter: false,
      filter: true,
      width: 125,
    },
    {
      title: "DNI",
      dataIndex: "csmDocumentNumber",
      key: "csmDocumentNumber",
      align: "center",
      sorter: false,
      filter: true,
      width: 125,
    },
    {
      title: "NOMBRE",
      dataIndex: "csmName",
      key: "csmName",
      align: "center",
      sorter: true,
      filter: true,
      width: 200,
    },
    {
      title: "CELULAR",
      dataIndex: "csmPhone",
      key: "csmPhone",
      align: "center",
      sorter: true,
      filter: true,
      width: 125,
    },
    {
      title: "MONTO RECLAMADO",
      dataIndex: "reclaimedAmount",
      key: "reclaimedAmount",
      align: "center",
      sorter: false,
      filter: false,
      width: 125,
      render: (value, row) => `S/ ${napformat(value)}`,
    },
    {
      title: "DIRECCIÓN",
      dataIndex: "csmAddress",
      key: "csmAddress",
      align: "center",
      sorter: false,
      filter: true,
      width: 200,
    },
    {
      title: "EMAIL",
      dataIndex: "csmEmail",
      key: "csmEmail",
      align: "center",
      sorter: false,
      filter: true,
      width: 200,
    },
    {
      title: "FECHA",
      dataIndex: "date",
      key: "date",
      align: "center",
      sorter: false,
      filter: false,
      width: 125,
      render: (value) => moment(value).format("ddd D MMM YYYY"),
    },
    {
      title: "ESTADO",
      dataIndex: "status",
      key: "status",
      align: "center",
      sorter: false,
      filter: false,
      width: 120,
      render: (value) =>
        value === "Abierto" ? (
          <Alert message={value} type="info" showIcon />
        ) : (
          <Alert message={value} type="success" showIcon />
        ),
    },
  ];

  return (
    <Fragment>
      <CustomTable
        columns={columns}
        data={data}
        loading={loading}
        pagination={{ pageSize: 20 }}
      />
      {modalResume.open && (
        <ModalClains
          open={modalResume.open}
          setOpen={setModalResume}
          dataClaim={modalResume.data}
          before={modalResume.before}
        />
      )}
      {modalAnswer.open && (
        <ModalAsnwerClaim
          open={modalAnswer.open}
          setOpen={setModalAnswer}
          dataClaim={modalAnswer.data}
          loading={loading}
          setLoading={setLoading}
          refreshFunction={refreshFunction}
        />
      )}
    </Fragment>
  );
};

export default TableTransactions;
