import SessionStorageService from "./SessionStorageService.js";
import SessionTimeService from "./SessionTimeService.js";
import { SESSION_NAME, SESSION_NAV, SESSION_YEAR } from "../config/session.js";
import { apiContable } from "../config/api.contable";
import { log } from "util";

class AuthenticationService {
  static isAuthenticated = false;
  static userData;
  static headers = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  };

  static login(obj) {
    return new Promise((resolve, reject) => {
      const requestUrl = `${apiContable}/login/${obj.url}`;
      const requestData = {
        user: obj.username,
        password: obj.password,
      };
      fetch(requestUrl, {
        method: "POST",
        body: JSON.stringify(requestData),
        headers: this.headers,
      })
        .then((response) => {
          if (response.ok) {
            var data = response.json();
            data.then((res) => {
              let result = res.data;
              result["api_token"] = res.token;
              result["company"] = obj.company;

              this.isAuthenticated = true;
              this.userData = result;
              SessionStorageService.set(SESSION_NAME, {
                expiresAt: SessionTimeService.estimatedTime(),
                value: result,
              });
              resolve(result);
            });
          } else {
            reject(response);
          }
        })
        .catch((error) => log(error));
    });
  }

  static isUserAuthenticated() {
    const userData = SessionStorageService.get(SESSION_NAME);
    this.isAuthenticated =
      !!userData && typeof userData.value.api_token === "string";
    return this.isAuthenticated;
  }

  static logout() {
    return new Promise((resolve, reject) => {
      this.isAuthenticated = false;
      this.userData = null;
      SessionStorageService.remove(SESSION_NAME);
      SessionStorageService.remove(SESSION_YEAR);
      SessionStorageService.remove(SESSION_NAV);
      resolve();
    });
  }
}

export default AuthenticationService;
