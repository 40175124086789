import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  makeStyles
} from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import Spinner from "../components/Global/Spinner";
/* import Empty from './EmptyView'; */

const useStyles = makeStyles((theme) => ({
  paper: { width: '100%' }
}));

const ModalConfirm = (props) => {
  const {
    visible,
    setVisible,
    reload,
    endPoint,
    title,
    subtitle,
    resendEmail,
    loading
  } = props;
  const classes = useStyles();
  
    const [error, setError] = useState(false);

  return (
    <Dialog open={visible} classes={{ paper: classes.paper }}>
      <DialogTitle>
        <Typography variant="h3" color="textSecondary" align="center">
          Confirmar Envío de Correo
        </Typography>
      </DialogTitle>
      {loading ? (
      <Spinner  /> 
      ) : error ? (
        <DialogContent>
          <DialogContentText align="center">Ocurrió un error</DialogContentText>
          
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText align="center">{title}</DialogContentText>
          <Typography variant="p" color="textSecondary" align="center">
            {subtitle}
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={() => setVisible(false)}
          color="primary"
          variant="outlined"
          disabled={loading ? true : false}
        >
          Cancelar
        </Button>
        <Button
          onClick={resendEmail}
          color="primary"
          variant="contained"
          disabled={loading ? true : false}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirm;
