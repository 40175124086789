import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
const SelectDoc = props => {
    const [cuentas,setCuentas] = useState(props.data);
    const [cuentaSelect,setCuentaSelect] = useState(null);
    const onChange = (number) => {
        setCuentaSelect(number);
        props.onChangeSelect(number) 
    }
    useEffect(()=>{
        if(setCuentaSelect!=null){
            setCuentaSelect(props.defaultValue);
        }
        setCuentas(props.data);
    },[props])


    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      } 
    const renderOptions  = () =>{
    return cuentas.map(i=><Option    key={i.id} value={i.id} title={i.code+'-'+i.description}>{i.code}-{i.description}&nbsp;&nbsp;&nbsp;</Option>)
    }
    const filterOptions = (inputValue,option) => {
        const title = removeAccents(option.props.title.toLowerCase()) 
        
        return title.includes(inputValue.toLowerCase())
        
    }
    const {Option} = Select;
	const content = 
        <Select
        size ="large"
        style={{ width: '100%' }} 
            showSearch
            value={cuentaSelect}
            onChange={onChange}
            filterOption={filterOptions}
        >
            <Option key={0} value={null} title={""}> - Ninguno - </Option> 
            {renderOptions()}
        </Select>;
	return content;
}
export default SelectDoc;