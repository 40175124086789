import React from "react";
import { useHistory } from "react-router-dom";
import { Typography, Grid, Card, Paper, Box, CardHeader, CardActionArea, Avatar } from "@material-ui/core";

const list = [
  {
    title: "UTILITARIOS",
    children: [
      {
        name: "MAYOR",
        detail: "MAYOR DE UNA CUENTA",
        link: "/reporte/mayor",
      },
      {
        name: "BALANCE",
        detail: "BALANCE DE N DÍGITOS HOJA DE TRABAJO",
        link: "/reporte/balance",
      },
      {
        name: "REPORTE TRIMESTRAL",
        detail: "REPORTE TRISMESTRAL",
        link: "/reporte/trimestral",
      },
      {
        name: "EJECUCIÓN PRESUPUESTARIA",
        detail: "EJECUCIÓN PRESUPUESTARIA",
        link: "/reporte/ep_ingresos",
      },
      {
        name: "PRESUPUESTO",
        detail: "PRESUPUESTO",
        link: "/reporte/presupuesto",
      },
      {
        name: "RECIBOS POR HONORARIOS",
        detail: "RECIBOS POR HONORARIOS",
        link: "/reporte/recibo-honorario",
      },
    ],
  },
  {
    title: "ESTADOS FINANCIEROS",
    children: [
      {
        name: "EEFF",
        detail: "ESTADO DE SITUACIÓN FINANCIERA",
        link: "/reporte/eeff",
      },
      {
        name: "BALANCE GENERAL DETALLADO",
        detail: "BALANCE GENERAL DETALLADO",
        link: "/reporte/balance-detallado",
      },
      {
        name: "EEGGPP",
        detail: "ESTADO DE RESULTADOS INTEGRAL POR NATURALEZA",
        link: "/reporte/eeggpp",
      },
      {
        name: "ESTADO DE GANANCIAS Y PÉRDIDAS POR NATURALEZA",
        detail: "DETALLADO",
        link: "/reporte/eeggppnn",
      },
      // {
      //   name: "ESTADO DE FLUJOS DE EFECTIVO",
      //   detail: "MÉTODO INDIRECTO",
      //   link: "/reporte/flujos-efectivo",
      // },
      // {
      //   name: "ESTADO DE CAMBIO EN EL PATRIMINIO",
      //   detail: "ESTADO DE CAMBIO EN EL PATRIMINIO",
      //   link: "/reporte/cambio-patrimonio",
      // },
    ],
  },
  {
    title: "LIBROS CONTABLE",
    children: [
      {
        name: "LIBRO DIARIO",
        detail: "LIBRO DIARIO",
        link: "/reporte/diario",
      },
      {
        name: "LIBRO CAJA Y BANCO",
        detail: "CAJA Y BANCO",
        link: "/reporte/caja",
      },
      {
        name: "LIBRO DE INVENTARIOS Y BALANCES",
        detail: "INVENTARIOS Y BALANCES",
        link: "/reporte/inventorios-balances",
      },
      {
        name: "LIBRO MAYOR",
        detail: "LIBRO MAYOR",
        link: "/reporte/mayor-general",
      },
      {
        name: "BALANCE CON ASIENTO DE CIERRE",
        detail: "BALANCE DE N DÍGITOS CON ASIENTO DE CIERRE",
        link: "/reporte/balance-asiento",
      },
      {
        name: "REGISTRO DE COMPRAS",
        detail: "REGISTRO DE COMPRAS",
        link: "/reporte/compras",
      },
      {
        name: "REGISTRO DE VENTAS",
        detail: "REGISTRO DE VENTAS",
        link: "/reporte/ventas",
      },
    ],
  },
];

const Reportes = () => {
  const navigate = useHistory();
  const handleRedirect = (url) => () => navigate.push(url);

  return (
    <Box p={1}>
      <Paper>
        <Box p={2}>
          {list.map((group, iOne) => (
            <Grid container spacing={2} key={iOne} style={{ marginBottom: 30 }}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  <b>{group.title}</b>
                </Typography>
              </Grid>
              {group.children.map((report, iTwo) => (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={iOne + iTwo}>
                  <Card style={{ height: "100%", boxShadow: "0 3px 7px 0 rgba(2,136,209,0.4)" }}>
                    <CardActionArea onClick={handleRedirect(report.link)} style={{ height: "100%" }}>
                      <CardHeader
                        title={report.name}
                        subheader={report.detail}
                        action={
                          <Avatar
                            src={`https://ui-avatars.com/api/?name=${report.name.replace(/\s+/g, "+")}`}
                            style={{ border: "5px solid #0288d1" }}
                          />
                        }
                      />
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ))}
        </Box>
      </Paper>
    </Box>
  );
};

export default Reportes;
