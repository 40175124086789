import React, { useState, useEffect } from "react";
import { TextField, Grid, Box, FormHelperText } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import deLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";
import SelectOrigen from "src/components/Selects/SelectOrigen";
import SelectPeriodo from "src/components/Selects/SelectPeriodo";
import SelectDoc from "src/components/Selects/SelectDoc";
import { getOrigins, getTipoDocs, postRuc } from "src/api/api.contable";
import { notification } from "antd";

const Head = (props) => {
  const { data, setData } = props;
  const [origins, setOrigins] = useState([]);
  const [dataTipos, setDataTipos] = useState([]);

  useEffect(() => {
    getOriginsData();
  }, []);

  const getOriginsData = async () => {
    const data = await getOrigins();
    let result = await getTipoDocs();
    setDataTipos(result);
    setOrigins(data);
  };

  const consultar = async (ndoc, tdoc) => {
    let longitud = tdoc === "ruc" ? 11 : 8;
    if (ndoc.length === longitud) {
      try {
        const res = await postRuc(ndoc, tdoc);
        setData({
          ...data,
          ruc: res.ruc,
          client: res.nombre_o_razon_social,
          customer: res._id,
        });
      } catch (error) {
        notification["warning"]({
          message: `${tdoc === "ruc" ? "RUC" : "DNI"} NO ENCONTRADO`,
        });
      }
    } else {
      notification["warning"]({
        message: `${tdoc === "ruc" ? "RUC" : "DNI"} INVÁLIDO...`,
      });
    }
  };

  return (
    <Box mt={2} mb={2}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4}>
          <FormHelperText>ORIGEN</FormHelperText>
          <SelectOrigen
            defaultValue={data.origen}
            onChangeSelect={(e) => setData({ ...data, origen: e })}
            data={origins}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormHelperText>PERIODO</FormHelperText>
          <SelectPeriodo
            defaultValue={data.periodo}
            onChangeSelect={(e) => setData({ ...data, periodo: e })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormHelperText>FECHA DE OPERACIÓN</FormHelperText>
          <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              inputVariant="outlined"
              size="small"
              format="dd/MM/yyyy"
              margin="normal"
              style={{ margin: 0 }}
              onChange={(e) => setData({ ...data, date: e })}
              value={data.date}
              fullWidth
              placeholder="DD/MM/YYYY"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormHelperText>TIPO DE DOCUMENTO</FormHelperText>
          <SelectDoc
            defaultValue={data.type}
            onChangeSelect={(e) => setData({ ...data, type: e })}
            data={dataTipos}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormHelperText>SERIE</FormHelperText>
          <Text
            label={false}
            value={data.serie}
            onChange={(e) => setData({ ...data, serie: e.target.value })}
            inputProps={{ maxLength: 4 }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormHelperText>Nro COMPROBANTE</FormHelperText>
          <Text
            label={false}
            value={data.correlativo}
            onChange={(e) => setData({ ...data, correlativo: e.target.value })}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormHelperText>{data.type === 1 ? "DNI" : "RUC"}</FormHelperText>
          <Text
            label={false}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                consultar(e.target.value, data.type === 1 ? "dni" : "ruc");
              }
            }}
            value={data.ruc}
            onChange={(e) => setData({ ...data, ruc: e.target.value })}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <FormHelperText>CLIENTE ó PROVEEDOR</FormHelperText>
          <Text label={false} value={data.client} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Head;

const Text = ({ ...rest }) => {
  return (
    <TextField
      size="small"
      fullWidth
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      {...rest}
    />
  );
};
