import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

const types = ["BOLETA", "FACTURA", "OTROS"];

const SelectVoucherType = ({ value, setValue }) => {
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>Tipo de documento</InputLabel>
      <Select label="Tipo de documento" value={value} onChange={(e) => setValue(e.target.value)}>
        {types.map((el, index) => (
          <MenuItem key={index} value={el}>
            {el}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectVoucherType;
