import React, { useState, useEffect } from "react";
import {
  List,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Avatar,
  Typography,
  Button,
  ListItem,
} from "@material-ui/core";
import axios from "axios";
import { apiContable } from "../../config/api.contable";
import { notification } from "antd";

const AsignModulesModal = (props) => {
  const {
    visible,
    setVisible,
    modulosActivos = [],
    endpoint,
    modules = [],
    setLoading,
    reload,
    sede = false,
  } = props;
  const [tempModules, setTempModules] = useState([]);

  useEffect(() => {
    if (sede) {
      formatDataUser();
    } else {
      formatModules();
    }
  }, []);

  const formatDataUser = () => {
    let newData = [];
    let modulesUser = modulosActivos[sede] ? modulosActivos[sede] : [];

    for (let i = 0; i < modules.length; i++) {
      let finder = modulesUser.find((ev) => ev === modules[i]);
      newData.push({ module: modules[i], status: finder ? true : false });
    }
    setTempModules(newData);
  };

  const formatModules = async () => {
    let newData = [];
    for (let i = 0; i < modules.length; i++) {
      let finder = modulosActivos.find((ev) => ev === modules[i]);
      newData.push({ module: modules[i], status: finder ? true : false });
    }
    setTempModules(newData);
  };

  const setModule = (i) => {
    tempModules[i].status = !tempModules[i].status;
    setTempModules([...tempModules]);
  };

  const sendModules = async () => {
    setLoading(true);
    let obj = {
      modules: tempModules,
    };
    if (sede) {
      obj["headquarter"] = sede;
    }

    try {
      await axios.put(`${apiContable}/${endpoint}`, obj);
      notification["success"]({
        message: "¡ÉXITO!",
        description: "Se asignó correctamente los módulos seleccionados",
      });
      reload();
      setVisible({ open: false });
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al obtener los módulos.",
        description: `${error}`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={visible}
      fullWidth
      maxWidth="sm"
      onClose={() => setVisible({ open: false })}
    >
      <DialogTitle>
        <Typography variant="h3" align="center">
          <b>MÓDULOS</b>
        </Typography>
      </DialogTitle>
      <DialogContent>
        {tempModules.length > 0 ? (
          <List dense>
            {tempModules.map((ev, i) => (
              <ListItem divider button>
                <ListItemAvatar>
                  <Avatar>{i + 1}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={`MODULO DE ${ev.module}`} />
                <ListItemSecondaryAction>
                  <Switch checked={ev.status} onChange={() => setModule(i)} />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body1" align="center" color="textSecondary">
            No se encontró módulos activos.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setVisible({ open: false })}
        >
          CANCELAR
        </Button>
        <Button variant="contained" color="primary" onClick={sendModules}>
          guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AsignModulesModal;
