import navitems from "./navItems";
import sedeactual from "src/config/sedeactual";

const rol = sedeactual.getRol();
//-----------------------|| MENU ITEMS ||-----------------------//

let tmpArr = [];
navitems.forEach((rowOne) => {
  if (rowOne.roles.includes(rol)) {
    tmpArr.push({ ...rowOne, pages: [] });
    rowOne.pages.forEach((rowTwo) => {
      if (rowTwo.roles.includes(rol)) {
        let iOne = tmpArr.length - 1;
        if (rowTwo.children) {
          tmpArr[iOne].pages.push({ ...rowTwo, children: [] });
          rowTwo.children.forEach((rowThree) => {
            if (rowThree.roles.includes(rol)) {
              let iTwo = tmpArr[iOne].pages.length - 1;
              tmpArr[iOne].pages[iTwo].children.push(rowThree);
            }
          });
        } else {
          tmpArr[iOne].pages.push(rowTwo);
        }
      }
    });
  }
});

export default tmpArr;
