import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormHelperText,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputAdornment,
  Button,
  Checkbox,
} from "@material-ui/core";
import useForm from "react-hook-form";
import ModalPolitica from "./ModalPolitica";
import axios from "axios";
import _ from "lodash/fp";
import { notification } from "antd";
import { apiReclamos } from "src/config/api.contable";
import { postClaim } from "src/utils/reqinc";

const Form = ({ company, setLoading }) => {
  const { register, handleSubmit, errors } = useForm();
  const [csmDocKind, setCsmDocKind] = useState("DNI");
  const [properly, setProperly] = useState("Product");
  const [claimKind, setClaimKind] = useState("Claim");
  const [checked, setChecked] = useState(false);
  const [modalPolitica, setModalPolitica] = useState(false);
  const [name, setName] = useState("");

  const sendClaim = async (data, event) => {
    if (checked) {
      try {
        setLoading(true);
        data["csmDocumentKind"] = csmDocKind;
        data["headquarter"] = company._id;
        data["date"] = Date.now();
        data["properly"] = properly;
        data["claimKind"] = claimKind;
        data["system"] = "napcontable";

        await postClaim(data);

        notification["success"]({
          message: `Su ${claimKind === "Claim" ? "reclamo" : "queja"} fue enviado correctamente.`,
        });
        event.target.reset();
      } catch (error) {
        notification["error"]({
          message: `Ocurrió un error al enviar su ${
            claimKind === "Claim" ? "reclamo" : "queja"
          }. ${error}`,
        });
      } finally {
        setLoading(false);
      }
    } else {
      notification["warning"]({
        message: "Debe aceptar la política de privacidad para continuar.",
      });
    }
  };

  const consultDocument = async (document) => {
    try {
      let lng = document.toString().length;
      let type_doc = csmDocKind.toLocaleLowerCase();
      if (Boolean(Number(document)) && !Boolean(errors?.csmDocumentNumber)) {
        if (type_doc !== "ce") {
          if ((type_doc === "dni" && lng === 8) || (type_doc === "ruc" && lng === 11)) {
            const res = await axios.post(`${apiReclamos}/consult/${type_doc}`, {
              [type_doc]: document,
            });
            if (res.data.success) {
              setName(res.data.nombre ? res.data.nombre : res.data.nombre_o_razon_social);
            } else {
              notification["info"]({
                message: `No se encontró el número de documento`,
              });
            }
          } else {
            notification["warning"]({
              message: `${csmDocKind} INVÁLIDO`,
            });
          }
        }
      }
    } catch (error) {
      notification["info"]({
        message: `No se encontró el número de documento`,
      });
    }
  };

  return (
    <Box p={4} pt={0}>
      <form onSubmit={handleSubmit(sendClaim)} autoComplete="off">
        <Grid container justify="flex-end" spacing={1}>
          <Grid item xs={12}>
            <Typography component="h2" variant="body1">
              <b>IDENTIFICACIÓN DEL CONSUMIDOR:</b>
            </Typography>
          </Grid>
          <Grid item xs={11} md={5}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Tipo de Documento</FormLabel>
              <RadioGroup
                name="tipoDocumento"
                value={csmDocKind}
                onChange={(e) => setCsmDocKind(e.target.value)}
                inputRef={register({
                  required: true,
                })}
              >
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <FormControlLabel value="DNI" control={<Radio color="primary" />} label="DNI" />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel value="CE" control={<Radio color="primary" />} label="CE" />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel value="RUC" control={<Radio color="primary" />} label="RUC" />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={11} md={6}>
            <TextField
              fullWidth
              label="Número de documento *"
              name="csmDocumentNumber"
              variant="outlined"
              inputRef={register({
                required: true,
                minLength: 8,
                maxLength: 12,
                pattern: /^[0-9]+$/i,
              })}
              onKeyPress={(e) => e.key === "Enter" && consultDocument(e.target.value)}
            />
            <FormHelperText error={Boolean(errors?.csmDocumentNumber)}>
              {Boolean(errors?.csmDocumentNumber)
                ? ""
                : "Ingrese su Nro Documento y presione 'Enter' "}
              {_.get("csmDocumentNumber.type", errors) === "required" &&
                "El número de documento es requerido."}
              {_.get("csmDocumentNumber.type", errors) === "pattern" &&
                "Solo debe contener números."}
              {_.get("csmDocumentNumber.type", errors) === "minLength" &&
                "Debe contener al menos 8 caracteres."}
              {_.get("csmDocumentNumber.type", errors) === "maxLength" &&
                "No debe contener mas de 12 caracteres."}
            </FormHelperText>
          </Grid>
          <Grid item xs={11}>
            <TextField
              fullWidth
              name="csmName"
              label="Nombre *"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              inputRef={register({
                required: true,
                minLength: 2,
              })}
            />
            <FormHelperText error>
              {_.get("csmName.type", errors) === "required" && "El nombre es requerido."}
              {_.get("csmName.type", errors) === "minLength" &&
                "Debe contener al menos 2 caracteres."}
            </FormHelperText>
          </Grid>
          <Grid item xs={11}>
            <TextField
              fullWidth
              label="Domicilio *"
              name="csmAddress"
              variant="outlined"
              inputRef={register({
                required: true,
                minLength: 3,
              })}
            />
            <FormHelperText error>
              {_.get("csmAddress.type", errors) === "required" && "El Domicilio es requerido."}
              {_.get("csmAddress.type", errors) === "minLength" &&
                "Debe contener al menos 3 caracteres."}
            </FormHelperText>
          </Grid>
          <Grid item xs={11} md={5}>
            <TextField
              fullWidth
              label="E-mail *"
              name="csmEmail"
              variant="outlined"
              inputRef={register({
                required: true,
                minLength: 5,
                pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
              })}
            />
            <FormHelperText error>
              {_.get("csmEmail.type", errors) === "required" && "El e-mail es requerido."}
              {_.get("csmEmail.type", errors) === "minLength" &&
                "Debe contener al menos 5 caracteres."}
              {_.get("csmEmail.type", errors) === "pattern" && "Debe ser un correo válido."}
            </FormHelperText>
          </Grid>
          <Grid item xs={11} md={6}>
            <TextField
              fullWidth
              label="Teléfono *"
              name="csmPhone"
              variant="outlined"
              inputRef={register({
                required: true,
                minLength: 7,
                maxLength: 9,
                pattern: /^[0-9]+$/i,
              })}
            />
            <FormHelperText error>
              {_.get("csmPhone.type", errors) === "required" &&
                "El número telefónico es requerido."}
              {_.get("csmPhone.type", errors) === "minLength" &&
                "Debe contener al menos 7 caracteres."}
              {_.get("csmPhone.type", errors) === "maxLength" &&
                "No debe contener mas de 9 caracteres."}
              {_.get("csmPhone.type", errors) === "pattern" && "Solo debe contener números."}
            </FormHelperText>
          </Grid>
          <Grid item xs={11}>
            <TextField
              fullWidth
              label="Padre o madre (en caso de menores de edad)"
              name="csmMinors"
              variant="outlined"
              inputRef={register({
                minLength: 2,
              })}
            />
            <FormHelperText error>
              {_.get("csmMinors.type", errors) === "minLength" &&
                "Debe contener al menos 2 caracteres."}
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h2" variant="body1">
              <b>IDENTIFICACIÓN DEL BIEN CONTRATADO:</b>
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Tipo de bien contratado</FormLabel>
              <RadioGroup value={properly} onChange={(e) => setProperly(e.target.value)}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      value="Product"
                      control={<Radio color="primary" />}
                      label="Producto"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      value="Service"
                      control={<Radio color="primary" />}
                      label="Servicio"
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={11}>
            <TextField
              fullWidth
              label="Monto reclamado *"
              name="reclaimedAmount"
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">S/</InputAdornment>,
              }}
              inputRef={register({
                required: true,
                pattern: /^[0-9]+$/i,
              })}
            />
            <FormHelperText error>
              {_.get("reclaimedAmount.type", errors) === "required" &&
                "El monto reclamado es requerido."}
              {_.get("reclaimedAmount.type", errors) === "pattern" && "Solo debe contener números."}
            </FormHelperText>
          </Grid>
          <Grid item xs={11}>
            <TextField
              label="Descripción"
              multiline
              fullWidth
              variant="outlined"
              rows="8"
              name="properlyDescrip"
              inputRef={register({
                required: true,
                minLength: 15,
              })}
            />
            <FormHelperText error>
              {_.get("properlyDescrip.type", errors) === "required" &&
                "La descripción es requerida."}
              {_.get("properlyDescrip.type", errors) === "minLength" &&
                "La longitud mínima es de 15 caracteres."}
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h2" variant="body1">
              <b>DETALLE DE{claimKind === "Claim" ? "L RECLAMO" : " LA QUEJA"}:</b>
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Tipo</FormLabel>
              <RadioGroup value={claimKind} onChange={(e) => setClaimKind(e.target.value)}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      value="Claim"
                      control={<Radio color="primary" />}
                      label="Reclamo"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      value="Complaint"
                      control={<Radio color="primary" />}
                      label="Queja"
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={11}>
            <FormHelperText>
              {"Disconformidad relacionada a los productos o servicios.".toUpperCase()}
            </FormHelperText>
            <br />
            <TextField
              label="Detalle *"
              multiline
              fullWidth
              variant="outlined"
              rows="4"
              name="claimDetails"
              inputRef={register({
                required: true,
              })}
            />
            <FormHelperText error>
              {_.get("claimDetails.type", errors) === "required" && "El detalle es requerido."}
            </FormHelperText>
          </Grid>
          <Grid item xs={11}>
            <TextField
              label="Pedido *"
              multiline
              fullWidth
              variant="outlined"
              rows="4"
              name="claim_order"
              inputRef={register({
                required: true,
              })}
            />
            <FormHelperText error>
              {_.get("claim_order.type", errors) === "required" &&
                "La orden del pedido es requerido."}
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h2" variant="body1">
              <b>OBSERVACIONES Y ACCIONES ADOPTADOS POR EL PROVEEDOR:</b>
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography component="h1" variant="body2">
              * La respuesta a este reclamo o queja será enviada a la dirección del correo indicado
              en este formulario.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h2" variant="body1">
              <b>VALIDACIÓN DE SEGURIDAD:</b>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={11}>
            <FormControlLabel
              control={
                <Checkbox checked={checked} onChange={() => setChecked(!checked)} color="primary" />
              }
              label={
                <span>
                  Declaro haber leído y acepto la
                  <Button onClick={() => setModalPolitica(true)} component="span">
                    Política de Privacidad.
                  </Button>
                </span>
              }
            />
          </Grid>
          <Grid item xs={12} align="center">
            <Button size="large" color="primary" variant="contained" type="submit">
              ENVIAR
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h5" variant="caption" color="textSecondary">
              * La formulación del reclamo no impide acudir a otras vías de solución de
              controversias ni es requisito previo para interponer una denuncia ante el INDECOPI.
            </Typography>
            <Typography component="h5" variant="caption" color="textSecondary">
              * El proveedor deberá dar respuesta al reclamo en un plazo no mayor a treinta (30)
              días calendario, pudiendo ampliar el plazo hasta por treinta (30) días más, previa
              comunicación al consumidor.
            </Typography>
          </Grid>
        </Grid>
      </form>
      {modalPolitica && (
        <ModalPolitica visible={modalPolitica} setVisible={setModalPolitica} company={company} />
      )}
    </Box>
  );
};

export default Form;
