import axios from "axios";
import { apiContable } from "src/config/api.contable";
import sedeactual from "src/config/sedeactual";

// TRABAJADORES

export const getWorkers = async (sedeId = sedeactual.sede_id()) => {
  let res = await axios.get(`${apiContable}/workers/${sedeId}`);
  return res.data;
};

export const getWorkersByCompany = async () => {
  let res = await axios.get(`${apiContable}/worker/company/${sedeactual.company()._id}`);
  return res.data;
};

export const postWorkers = async (data) => {
  let res = await axios.post(`${apiContable}/workers`, data);
  return res.data;
};

export const putWorkers = async (data, id) => {
  let res = await axios.put(`${apiContable}/workers/${id}`, data);
  return res.data;
};

export const uploadWorkers = async (data) => {
  let res = await axios.post(`${apiContable}/workers/import/${sedeactual.sede_id()}`, data);
  return res.data;
};

export const uploadWorkersByCompany = async (data) => {
  let res = await axios.post(
    `${apiContable}/workers/company/import/${sedeactual.company().url}`,
    data
  );
  return res.data;
};

export const getPlantillaMassiveWorkers = async () => {
  let res = await axios.get(`${apiContable}/company/excel-companies/${sedeactual.company().url}`);
  return res.data;
};

// BOLETAS

export const getFilesVoucher = async (idworker) => {
  let res = await axios.get(`${apiContable}/worker/vouchers/${idworker}/${sedeactual.sede_id()}`);
  return res.data;
};

export const postFileVouchers = async (data) => {
  let res = await axios.post(`${apiContable}/voucherfile`, data);
  return res.data;
};

export const uploadVouchers = async (data) => {
  let res = await axios.post(`${apiContable}/voucherfile/${sedeactual.sede_id()}`, data);
  return res.data;
};

export const getVoucherByHeadquarter = async () => {
  const res = await axios.get(`${apiContable}/vouchers/headquarter/${sedeactual.sede_id()}`);
  return res.data;
};

// INFORMACIÓN GENERAL

export const getWorkerInformation = async (type) => {
  let res = await axios.get(`${apiContable}/worker/information/${sedeactual.sede_id()}/${type}`);
  return res.data;
};

export const postWorkerInformation = async (data) => {
  let res = await axios.post(`${apiContable}/worker/general/information`, data);
  return res.data;
};

export const putWorkerInformation = async (data, id) => {
  let res = await axios.put(`${apiContable}/worker/general/information/${id}`, data);
  return res.data;
};

// WORKER DOCUMENTS

export const getWorkerDocuments = async () => {
  let res = await axios.get(
    `${apiContable}/worker/documents/${sedeactual.sede_id()}/${sedeactual.getAnio()}`
  );
  return res.data;
};

export const getWorkerDocumentsCompany = async () => {
  let res = await axios.get(
    `${apiContable}/worker/documents/company/${sedeactual.company()._id}/${sedeactual.getAnio()}`
  );
  return res.data;
};

export const postWorkerDocuments = async (data) => {
  let res = await axios.post(`${apiContable}/worker/documents`, data);
  return res.data;
};

export const putWorkerDocuments = async (data, id) => {
  let res = await axios.put(`${apiContable}/worker/documents/${id}`, data);
  return res.data;
};

export const uploadWorkerDocuments = async (data) => {
  let res = await axios.post(`${apiContable}/worker/upload-file`, data);
  return res.data;
};

export const sendNotifications = async (data) => {
  const res = await axios.post(`${apiContable}/worker/send-notifications`, data);
  return res.data;
};
