import React from "react";
import {
  Grid,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";
import { apiReclamos } from "src/config/api.contable";
import axios from "axios";
import { notification } from "antd";

const PartTwo = ({
  errors,
  register,
  setInfTDOc,
  infTDoc,
  listTDocs,
  parentesco,
  setParentesco,
  listParentesco,
  eInf,
  setValue,
}) => {
  const handleChangeParentesco = (value) => {
    if (value === "Otro") {
      setParentesco({ text: "", value: true });
    } else {
      setParentesco({ ...parentesco, text: value });
    }
  };

  const consultDocument = async (document) => {
    try {
      let t_doc = infTDoc.toLowerCase();
      let lng = document.toString().length;
      if (Boolean(Number(document)) && !Boolean(errors?.infNDoc)) {
        if (t_doc !== "ce") {
          if ((t_doc === "dni" && lng === 8) || (t_doc === "ruc" && lng === 11)) {
            const res = await axios.post(`${apiReclamos}/consult/${t_doc}`, {
              [t_doc]: document,
            });
            if (res.data.success) {
              setValue(
                "infFullname",
                res.data.nombre ? res.data.nombre : res.data.nombre_o_razon_social
              );
            } else {
              notification["info"]({
                message: `No se encontró el número de documento`,
              });
            }
          } else notification["warning"]({ message: `${infTDoc} INVÁLIDO` });
        }
      }
    } catch (error) {
      notification["info"]({ message: `No se encontró el número de documento` });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <RadioGroup value={infTDoc} onChange={(e) => setInfTDOc(e.target.value)}>
            <Grid container justify="space-between">
              {listTDocs.map((el, index) => (
                <Grid item key={index}>
                  <FormControlLabel value={el} control={<Radio color="primary" />} label={el} />
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          InputLabelProps={{ shrink: true }}
          size="small"
          fullWidth
          label="Documento *"
          name="infNDoc"
          variant="outlined"
          defaultValue={Boolean(eInf) ? eInf.infNDoc : ""}
          inputRef={register({
            required: true,
            minLength: 8,
            maxLength: 12,
            pattern: /^[0-9]+$/i,
          })}
          error={Boolean(errors?.infNDoc)}
          onKeyPress={(e) => e.key === "Enter" && consultDocument(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          size="small"
          fullWidth
          label="Nombres y apellidos *"
          name="infFullname"
          variant="outlined"
          defaultValue={Boolean(eInf) ? eInf.infFullname : ""}
          inputRef={register({ required: true })}
          error={Boolean(errors?.infFullname)}
        />
      </Grid>
      <Grid item xs={12}>
        {parentesco.value ? (
          <TextField
            InputLabelProps={{ shrink: true }}
            size="small"
            fullWidth
            label="Parentesco * (especificar)"
            variant="outlined"
            value={parentesco.text}
            onChange={(e) => setParentesco({ ...parentesco, text: e.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      setParentesco({
                        text: listParentesco[0],
                        value: false,
                      })
                    }
                  >
                    <ReplayIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Parentesco</FormLabel>
            <RadioGroup
              value={parentesco.text}
              onChange={(e) => handleChangeParentesco(e.target.value)}
            >
              <Grid container justify="space-between">
                {listParentesco.map((el, index) => (
                  <Grid item key={index}>
                    <FormControlLabel value={el} control={<Radio color="primary" />} label={el} />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </FormControl>
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          InputLabelProps={{ shrink: true }}
          size="small"
          fullWidth
          label="Dirección *"
          name="infAddress"
          defaultValue={Boolean(eInf) ? eInf.infAddress : ""}
          variant="outlined"
          inputRef={register({
            required: true,
          })}
          error={Boolean(errors?.infAddress)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          InputLabelProps={{ shrink: true }}
          size="small"
          fullWidth
          label="Celular *"
          name="infPhone"
          variant="outlined"
          defaultValue={Boolean(eInf) ? eInf.infPhone : ""}
          inputRef={register({
            required: true,
            minLength: 6,
            maxLength: 10,
            pattern: /^[0-9]+$/i,
          })}
          error={Boolean(errors?.infPhone)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          InputLabelProps={{ shrink: true }}
          size="small"
          fullWidth
          label="Correo *"
          name="infEmail"
          variant="outlined"
          defaultValue={Boolean(eInf) ? eInf.infEmail : ""}
          inputRef={register({
            required: true,
            minLength: 5,
            pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
          })}
          error={Boolean(errors?.infEmail)}
        />
      </Grid>
    </Grid>
  );
};

export default PartTwo;
