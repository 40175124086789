import React, { useState } from "react";
import Page from "src/components/Page";
import {
  Button,
  Grid,
  Typography,
  FormHelperText,
  Menu,
  Box,
  Divider,
  CardActionArea,
} from "@material-ui/core";
import sedeactual from "src/config/sedeactual";
import { ArrowRight } from "@material-ui/icons";
import ImportBoletasByHeadquarter from "./ImportBoletasByHeadquarter";
import ImportWorkers from "./ImportWorkers";
import ImportWorkersByCompany from "./ImportWorkersByCompany";
import ImportBoletasByCompany from "./ImportBoletasByCompany";
import ImportAttendanceWorkersByCompany from "./ImportAttendanceWorkersByCompany";

const Importacion = () => {
  return (
    <Page title="IMPORTACIÓN" helper="BOLETAS DE REMUNERACIONES">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ImportBoletasByHeadquarter />
        </Grid>
        <Grid item xs={12} md={6}>
          <ImportWorkers />
        </Grid>
        {sedeactual.getRol() === "supernap" && (
          <Grid item xs={12} md={6}>
            <ImportWorkersByCompany />
          </Grid>
        )}
        {sedeactual.getRol() === "supernap" && (
          <Grid item xs={12}>
            <ImportBoletasByCompany />
          </Grid>
        )}
        {sedeactual.getRol() === "supernap" && (
          <Grid item xs={12}>
            <ImportAttendanceWorkersByCompany />
          </Grid>
        )}
      </Grid>
    </Page>
  );
};

export default Importacion;

export const ContentTypes = ({ group, data = [] }) => {
  const [toolTip, setToolTip] = useState(null);
  const [headTooltip, setHeadTooltip] = useState(null);

  return (
    <Grid item xs={12}>
      <Typography variant="caption" component="div">
        - Recuerde que los archivos deben estar en formato <b>PDF.</b> <br />- El nombre del archivo
        debe tener el formato: [tipo]-[dni]{group ? "-[sede]" : ""}.pdf | Ejemplo: 15-42586542
        {group ? "-prueba" : ""}.pdf
        <br />
        <Button
          onClick={(e) => setToolTip(e.currentTarget)}
          size="small"
          color="primary"
          variant="outlined"
        >
          VER TIPOS
        </Button>
        {group && (
          <Button
            onClick={(e) => setHeadTooltip(e.currentTarget)}
            size="small"
            color="primary"
            variant="outlined"
          >
            VER PARÁMETROS DE LAS SEDES
          </Button>
        )}
      </Typography>
      <Menu anchorEl={toolTip} keepMounted open={Boolean(toolTip)} onClose={() => setToolTip(null)}>
        <Box px={3} py={1}>
          <FormHelperText>
            01 ─ ENERO
            <br />
            02 ─ FEBRERO
            <br />
            03 ─ MARZO
            <br />
            04 ─ ABRIL
            <br />
            05 ─ MAYO
            <br />
            06 ─ JUNIO
            <br />
            07 ─ JULIO
            <br />
            08 ─ AGOSTO
            <br />
            09 ─ SETIEMBRE
            <br />
            10 ─ OCTUBRE
            <br />
            11 ─ NOVIEMBRE
            <br />
            12 ─ DICIEMBRE
            <br />
            13 ─ GRATIFICACIÓN JULIO
            <br />
            14 ─ GRATIFICACIÓN DICIEMBRE
            <br />
            15 ─ CTS JUNIO
            <br />
            16 ─ CTS NOVIEMBRE
            <br />
            17 ─ CERTIFICADO DE 5TA CATEGORÍA
            <br />
            18 ─ CONTRATO DE TRABAJO
            <br />
            19 ─ OTROS
          </FormHelperText>
        </Box>
      </Menu>

      <Menu
        anchorEl={headTooltip}
        keepMounted
        open={Boolean(headTooltip)}
        onClose={() => setHeadTooltip(null)}
      >
        <Box px={3} py={1}>
          {data.map((e) => (
            <CardActionArea>
              <ArrowRight /> {e.url} - {e.name}
              <Divider />
            </CardActionArea>
          ))}
        </Box>
      </Menu>
    </Grid>
  );
};
