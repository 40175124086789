import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { apiContable } from "../../config/api.contable";
import axios from "axios";
import { Spin, notification } from "antd";
import sedeactual from "src/config/sedeactual";

const ModalUser = ({
  open,
  setOpen,
  data,
  loading,
  setLoading,
  obtainUsers,
}) => {
  const [info, setInfo] = useState({
    n_doc: data.n_doc,
    fullname: data.fullname,
    phone: data.phone,
    email: data.email,
    address: data.address,
    rol: data.rol,
  });

  const handleChange = (event) =>
    setInfo({ ...info, [event.target.name]: event.target.value });

  const handleUpdate = async () => {
    try {
      setLoading(true);
      await axios.put(`${apiContable}/edit-user-info/${data._id}`, info, {
        headers: { Authorization: sedeactual.token() },
      });
      notification["success"]({
        message: `El usuario se actualizó con éxito.`,
      });
      obtainUsers();
      setOpen({ open: false });
    } catch (error) {
      notification["error"]({
        message: `Ocurrió un error al realizar la operación`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={() => setOpen({ open: false })}
    >
      <Spin spinning={loading}>
        <DialogTitle>
          <Typography align="center" component="p" variant="h4">
            EDITAR USUARIO
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={2} xl={3}>
              <TextField
                label="DNI"
                variant="outlined"
                fullWidth
                required
                type="number"
                name="n_doc"
                value={info.n_doc}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label="NOMBRE COMPLETO"
                variant="outlined"
                fullWidth
                required
                name="fullname"
                value={info.fullname}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} xl={3}>
              <TextField
                label="TEL / CEL"
                variant="outlined"
                type="tel"
                fullWidth
                required
                name="phone"
                value={info.phone}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <TextField
                label="CORREO"
                variant="outlined"
                type="email"
                fullWidth
                required
                name="email"
                value={info.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="DIRECCIÓN"
                variant="outlined"
                type="address"
                fullWidth
                required
                name="address"
                value={info.address}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>ROL</InputLabel>
                <Select
                  label="ROL"
                  name="rol"
                  value={info.rol}
                  onChange={handleChange}
                >
                  <MenuItem value="accountant">CONTADOR</MenuItem>
                  <MenuItem value="admin">ADMINISTRADOR</MenuItem>
                  <MenuItem value="superadmin">SUPERADMIN</MenuItem>
                  <MenuItem value="usuario">SUPERVISOR</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => setOpen({ open: false })}
          >
            CANCELAR
          </Button>
          <Button color="primary" variant="contained" onClick={handleUpdate}>
            ACTUALIZAR
          </Button>
        </DialogActions>
      </Spin>
    </Dialog>
  );
};

export default ModalUser;
