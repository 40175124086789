import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import axios from "axios";
import sedeactual from "../../../config/sedeactual";
import { apiContable } from "../../../config/api.contable";
import { notification } from "antd";

const ModalAddCost = (props) => {
  const { visible, setVisible, getCostos, lastCode, setLoading } = props;
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");

  const saveCost = async () => {
    setLoading(true);
    if (
      code !== "" &&
      code != "01" &&
      code != "03" &&
      code != "03" &&
      code.toString().length > 0 &&
      code.toString().length < 3 &&
      description !== ""
    ) {
      try {
        await axios.post(
          `${apiContable}/costs/${
            sedeactual.company().url
          }/${sedeactual.get()}/${sedeactual.getAnio()}`,
          {
            code: code,
            description: description,
          }
        );
        getCostos();
        notification["success"]({
          message: "ÉXITO!",
          description: "El registro de guardó correctamente.",
        });
        setVisible(false);
      } catch (error) {
        notification["error"]({
          message: "ERROR!",
          description: `El código ya fue ingresado. Ocurrió un error al agregar el registro. ${error}`,
        });
      } finally {
        setLoading(false);
      }
    } else {
      notification["info"]({
        message: "El código y descripción son requeridos!",
        description:
          "El código es único. El código ya fué ingresado. La longitud máxima es 2 caracteres.",
      });
    }
  };

  return (
    <Dialog open={visible} onClose={() => setVisible(false)} fullWidth>
      <DialogTitle>
        <Typography variant="h3" color="textSecondary">
          AGREGAR COSTOS
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Código"
              variant="outlined"
              fullWidth
              value={code}
              onChange={(ev) => setCode(ev.target.value)}
              helperText={`Último código ingresado: ${lastCode}.`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Descripción"
              variant="outlined"
              fullWidth
              value={description}
              onChange={(ev) => setDescription(ev.target.value)}
            />
          </Grid>
        </Grid>
        <br />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setVisible(false)}
        >
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={() => saveCost()}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalAddCost;
