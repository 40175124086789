import React, { useState } from "react";
import { Modal } from "antd";
import axios from "axios";
import { apiContable } from "../../../config/api.contable";

const ModalComment = (props) => {
  const { visible, setvisible, edit, file, refresh } = props;
  const [value, setValue] = useState(file.comment);

  const updateComment = async () => {
    let res = await axios.put(`${apiContable}/file/${file._id}`, {
      comment: value,
    });
    console.log(res);
    setvisible(false);
    refresh();
  };

  return (
    <Modal
      title={edit ? "Añadir Comentario" : "Ver Comentario"}
      visible={visible}
      onOk={edit ? () => updateComment() : () => setvisible(false)}
      onCancel={() => setvisible(false)}
    >
      <div class="form-group">
        {edit && (
          <label for="exampleFormControlTextarea1">Escribir comentario</label>
        )}
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={edit ? false : true}
        ></textarea>
      </div>
    </Modal>
  );
};

export default ModalComment;
