import Page from "src/components/Page";
import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Chip,
  Button,
} from "@material-ui/core";
import axios from "axios";
import { apiContable } from "src/config/api.contable";
import sedeactual from "src/config/sedeactual";
import { Empty, notification, Spin } from "antd";
import { Eject } from "@material-ui/icons";

const UsersDisabled = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    obtainData();
  }, []);

  const obtainData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${apiContable}/users/disabled/${sedeactual.sede_id()}`);
      setData([...res.data]);
    } catch (error) {
      notification["error"]({ message: `Ocurrió un error al realizar la operación` });
    } finally {
      setLoading(false);
    }
  };

  const updateUser = async (userId) => {
    try {
      setLoading(true);
      await axios.put(
        `${apiContable}/edit-user-info/${userId}`,
        { status: true },
        { headers: { Authorization: sedeactual.token() } }
      );
      notification["success"]({ message: `Usuario habilitado` });
      obtainData();
    } catch (error) {
      notification["error"]({ message: `Ocurrió un error al realizar la operación` });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page helper="CONFIGURACIÓN" title="USUARIOS DESHABILITADOS">
      <Spin spinning={loading}>
        <TableContainer component={Paper}>
          <Table className="tablefe">
            <TableHead>
              <TableRow>
                <TableCell>FOTO</TableCell>
                <TableCell>DOCUMENTO</TableCell>
                <TableCell>NOMBRE</TableCell>
                <TableCell>DIRECCIÓN</TableCell>
                <TableCell>USUARIO</TableCell>
                <TableCell>CORREO</TableCell>
                <TableCell>CELULAR</TableCell>
                <TableCell>ESTADO</TableCell>
                <TableCell>ROL</TableCell>
                <TableCell>HABILITAR</TableCell>
              </TableRow>
            </TableHead>
            {data.length > 0 ? (
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Avatar src={row.profile_picture} alt={row.profile_picture} />
                    </TableCell>
                    <TableCell>
                      {row.t_doc} {row.n_doc}
                    </TableCell>
                    <TableCell>{row.fullname}</TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>{row.user}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>
                      <Chip label="DESABILITADO" color="secondary" />
                    </TableCell>
                    <TableCell>{row.rol}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => updateUser(row._id)}
                      >
                        <Eject />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <caption>
                <Empty description="AQUÍ SE MOSTRARÁN LOS USUARIOS DESHABILITADOS" />
              </caption>
            )}
          </Table>
        </TableContainer>
      </Spin>
    </Page>
  );
};

export default UsersDisabled;
