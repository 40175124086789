import React, { Fragment, useEffect, useState } from "react";
import CustomTable from "src/components/CustomTable";
import { Avatar, Button, ButtonGroup, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  Edit,
  Delete,
  PersonAddDisabled,
  Description,
} from "@material-ui/icons";
import sedeactual from "src/config/sedeactual";

const TableWorkers = ({
  data,
  loading,
  setModal,
  view,
  setErase,
  setDisable,
  active,
  info,
  setInfo,
}) => {
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    let filtered = data.filter((e) => e.status === active);
    setNewData([...filtered]);
  }, [active, data]);

  let tempSelection = {};

  let columns = [
    {
      title: "#",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      width: 60,
      render: (val, row, index) => (
        <Avatar style={{ backgroundColor: "#0683c9" }}>{index + 1}</Avatar>
      ),
    },
    {
      title: "APELLIDOS",
      dataIndex: "f_name",
      key: "f_name",
      align: "center",
      width: 200,
      sorter: true,
      filter: true,
    },
    {
      title: "NOMBRES",
      dataIndex: "l_name",
      key: "l_name",
      align: "center",
      width: 200,
      sorter: true,
      filter: true,
    },
    {
      title: "CORREO",
      dataIndex: "email",
      key: "email",
      align: "center",
      width: 200,
      sorter: true,
      filter: true,
    },
    {
      title: "DIRECCIÓN",
      dataIndex: "address",
      key: "address",
      align: "center",
      width: 200,
      sorter: true,
      filter: true,
    },
    {
      title: "TIPO DOCUMENTO",
      dataIndex: "t_doc",
      key: "t_doc",
      align: "center",
      width: 150,
      sorter: true,
      filter: true,
    },
    {
      title: "Nro DOCUMENTO",
      dataIndex: "n_doc",
      key: "n_doc",
      align: "center",
      width: 150,
      sorter: true,
      filter: true,
    },
    {
      title: "CELULAR",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      width: 150,
      sorter: true,
      filter: true,
    },
  ];

  const historicalColumns = [
    {
      title: "USUARIO",
      dataIndex: "user",
      key: "user",
      align: "center",
      width: 150,
      sorter: true,
      filter: true,
    },
    {
      title: "ACCIONES",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      width: 200,
      sorter: false,
      filter: false,
      render: (value, row) => (
        <Fragment>
          <ButtonGroup>
            <Tooltip title="VER BOLETAS">
              <Link to={{ pathname: `/boletas/list/${value}/historico` }}>
                <Button size="small" variant="outlined" color="primary">
                  <Description />
                </Button>
              </Link>
            </Tooltip>
            {sedeactual.getRol() !== "usuario" && (
              <>
                <Tooltip title="EDITAR INFORMACIÓN">
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => setModal({ open: true, data: row })}
                  >
                    <Edit />
                  </Button>
                </Tooltip>
                <Tooltip title="ELIMINAR">
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={() => setErase({ open: true, id: value })}
                  >
                    <Delete />
                  </Button>
                </Tooltip>
                <Tooltip title="DESHABILITAR">
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                      setModal({ open: true, data: row, disable: true })
                    }
                  >
                    <PersonAddDisabled />
                  </Button>
                </Tooltip>
              </>
            )}
          </ButtonGroup>
        </Fragment>
      ),
    },
  ];

  const datesColumns = [
    {
      title: "VER BOLETAS",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      width: 100,
      sorter: false,
      filter: false,
      render: (value) => (
        <Link to={{ pathname: `/boletas/list/${value}/xfechas` }}>
          <Button size="small" variant="outlined" color="primary">
            VER
          </Button>
        </Link>
      ),
    },
  ];

  const companyColumns = [
    {
      title: "VER BOLETAS",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      width: 100,
      sorter: false,
      filter: false,
      render: (value) => (
        <Link to={{ pathname: `/boletas/list/${value}/company` }}>
          <Button size="small" variant="outlined" color="primary">
            VER
          </Button>
        </Link>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys: info ? info.data.map((row) => row._id) : [],
    onChange: (selectedRowKeys, selectedRows) => {
      if (setInfo && info && view === "historical")
        setInfo({ ...info, data: [...selectedRows] });
    },
  };

  if (view === "historical") {
    columns = [...columns, ...historicalColumns];
    tempSelection.rowSelection = { type: "checkbox", ...rowSelection };
  } else if (view === "dates") columns = [...columns, ...datesColumns];
  else if (view === "company") columns = [...columns, ...companyColumns];

  return (
    <CustomTable
      columns={columns}
      data={newData}
      loading={loading}
      pagination={false}
      {...tempSelection}
    />
  );
};

export default TableWorkers;
