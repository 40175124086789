import React, { useState, useEffect } from "react";
import { Select } from "antd";
import useFocus from "../../hook/useFocus";

const SelectDoc = (props) => {
  const [cuentas, setCuentas] = useState(props.data);
  const [inputRef, setInputFocus] = useFocus();
  const [cuentaSelect, setCuentaSelect] = useState(null);

  const onChange = (number) => {
    setCuentaSelect(number);
    props.onChangeSelect(number);
  };
  useEffect(() => {
    setCuentaSelect(props.defaultValue);
    setCuentas(props.data);
  }, [props]);

  useEffect(() => {
    if (props.caja == true) {
      setInputFocus();
    }
  }, [props.caja, props.focus]);

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const renderOptions = () => {
    return cuentas.map((i) => (
      <Option key={i._id} value={i._id} title={i.number + "-" + i.description}>
        {i.number + "-" + i.description}
      </Option>
    ));
  };
  const filterOptions = (inputValue, option) => {
    const title = removeAccents(option.props.title.toLowerCase());
    return title.includes(inputValue.toLowerCase());
  };
  const { Option } = Select;
  const content = (
    <Select
      ref={inputRef}
      size="large"
      style={{ width: "100%" }}
      showSearch
      value={cuentaSelect}
      onChange={onChange}
      filterOption={filterOptions}
    >
      <Option key={0} value={null} title={""}>
        {" "}
        - Ninguno -{" "}
      </Option>
      {renderOptions()}
    </Select>
  );
  return content;
};
export default SelectDoc;
