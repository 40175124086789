import React, { useState, useEffect } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import { Button, Grid, Paper } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Page from "../../components/Page/Page";
import SelectSedesNew from "../../components/Usuarios/SelectSedesNew";
import sedeactual from "../../config/sedeactual";
import {
  getHeadquarters,
  postDuplicateAccountPlan,
} from "../../api/api.contable";
import { notification } from "antd";
import LoaderBackdrop from "../../components/Global/LoaderBackdrop";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}));

const PlanCuentas = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [selectYearL, setSelectYearL] = useState(sedeactual.getAnio());
  const [selectYearR, setSelectYearR] = useState();
  const [customValue, setCustomValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const dataAnhos = [2019, 2020, 2021, 2022, 2023, 2024];

  const getHeadquartersData = async () => {
    let res = await getHeadquarters();
    setData(res.data);
  };

  useEffect(() => {
    getHeadquartersData();
  }, []);

  async function postAccountPlan() {
    if (customValue !== null && selectYearR !== null && selectYearL !== null) {
      setLoading(true);
      await postDuplicateAccountPlan(customValue.id, selectYearR, selectYearL)
        .then((res) => {
          notification["success"]({
            message: `Se registró el plan de cuentas para el año ${selectYearR}`,
          });
          //getHeadquartersData();
        })
        .catch((err) => {
          notification["error"]({
            message: `No se pudo registrar el plan de cuentas para el año ${selectYearR}.`,
            description: `${err}`,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <Page
      className={classes.root}
      helper="configuración"
      title="Transferir Plan de Cuentas"
    >
      {loading && <LoaderBackdrop />}
      <Paper style={{ padding: 25, minHeight: "calc(100vh - 120px)" }}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={12}>
            <SelectSedesNew
              defaultValue={sedeactual.get()}
              data={data}
              onChangeSelect={(ev) => setCustomValue(ev)}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Del Año
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                autoWidth
                value={selectYearL}
                onChange={(e) => setSelectYearL(e.target.value)}
                label="year"
              >
                {dataAnhos
                  .filter((opt) => opt <= selectYearL)
                  .map((yearL) => (
                    <MenuItem value={yearL}>Año {yearL}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Al Año
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                autoWidth
                value={selectYearR}
                onChange={(e) => setSelectYearR(e.target.value)}
                label="Age"
              >
                {dataAnhos
                  .filter((opt) => opt > selectYearL)
                  .map((yearR) => (
                    <MenuItem value={yearR} disabled={yearR <= selectYearL}>
                      Año {yearR}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    postAccountPlan();
                  }}
                >
                  Transferir
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Page>
  );
};

export default PlanCuentas;
