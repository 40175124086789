import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PermDataSettingIcon from "@material-ui/icons/PermDataSetting";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import BusinessIcon from "@material-ui/icons/Business";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import DescriptionIcon from "@material-ui/icons/Description";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PeopleIcon from "@material-ui/icons/People";
import NoteIcon from "@material-ui/icons/Note";
import ApartmentIcon from "@material-ui/icons/Apartment";
import LowPriorityIcon from "@material-ui/icons/LowPriority";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import ReceiptIcon from "@material-ui/icons/Receipt";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import SubjectIcon from "@material-ui/icons/Subject";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import { Assessment, List } from "@material-ui/icons";
import { PersonAddDisabled } from "@material-ui/icons";

const list = [
  {
    title: "CONTABILIDAD",
    roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    pages: [
      {
        title: "Voucher",
        icon: DescriptionIcon,
        roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
        children: [
          {
            title: "Ver Vouchers",
            href: "/vouchers",
            roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
          },
          {
            title: "Caja Rápida",
            href: "/cajarapida",
            roles: ["supernap", "superadmin", "admin", "accountant"],
          },
          {
            title: "Agregar Voucher",
            href: "/createvoucher",
            roles: ["supernap", "superadmin", "admin", "accountant"],
          },
        ],
      },
      {
        title: "Pagos y Cobros",
        roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
        href: "/pagoscobros",
        icon: MonetizationOnIcon,
      },
      {
        title: "Auxiliar de Cuentas",
        roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
        href: "/cc",
        icon: FormatListBulletedIcon,
      },
      {
        title: "Reportes",
        href: "/reportes",
        roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
        icon: GetAppIcon,
      },
      {
        title: "Archivos",
        href: "/archivos",
        roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
        icon: DescriptionIcon,
      },
      {
        title: "Presupuesto",
        href: "/presupuesto",
        roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
        icon: AccountBalanceWalletIcon,
      },
      {
        title: "Configuración",
        icon: SettingsIcon,
        roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
        children: [
          {
            title: "Periodo de trabajo ",
            href: "/configperiodo",
            roles: ["supernap", "superadmin"],
          },
          {
            title: "Costos",
            href: "/configcostos",
            roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
          },
          {
            title: "Formulas de Costos",
            href: "/formulas",
            roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
          },
          {
            title: "Pasar Plan de Cuentas",
            href: "/plan-cuentas",
            roles: ["supernap", "superadmin", "admin", "accountant"],
          },
        ],
      },
    ],
  },
  {
    title: "CUENTAS",
    roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    pages: [
      {
        title: "Importación",
        href: "/importacion",
        icon: PublishIcon,
        roles: ["supernap", "superadmin", "admin", "accountant"],
      },
      {
        title: "Plan de Cuentas",
        href: "/cuentas",
        icon: FormatListNumberedIcon,
        roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
      },
    ],
  },
  {
    title: "INVENTARIO",
    roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    pages: [
      {
        title: "Inventario",
        href: "/inventario",
        icon: ListAltIcon,
        roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
      },
      {
        title: "Gestión",
        icon: PermDataSettingIcon,
        roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
        children: [
          {
            title: "Categorias",
            href: "/gestion/categorias",
            roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
          },
          {
            title: "Locaciones",
            href: "/gestion/locacion",
            roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
          },
        ],
      },
      {
        title: "Reportes",
        icon: Assessment,
        href: "/inventario/reportes",
        roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
      },
      {
        title: "Memorandums",
        icon: List,
        href: "/inventario/memorandums",
        roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
      },
    ],
  },
  {
    title: "INGRESOS",
    roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    pages: [
      {
        title: "INGRESOS",
        icon: MonetizationOnIcon,
        href: "/ingresos/registro",
        roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
      },
      {
        title: "INGRESOS POR COMPAÑÍA",
        icon: MonetizationOnIcon,
        href: "/ingresos/registro/company",
        roles: ["supernap"],
      },
      {
        title: "DOCUMENTOS",
        icon: NoteIcon,
        href: "/ingresos/documentos",
        roles: ["supernap", "superadmin", "admin", "accountant"],
      },
    ],
  },
  {
    title: "COLABORADORES",
    roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    pages: [
      {
        title: "Boletas de remuneraciones",
        href: "/boletas/list",
        icon: ReceiptIcon,
        roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
      },
      {
        title: "Boletas por Sede",
        href: "/boletas/trabajadores/headquarter",
        icon: ReceiptIcon,
        roles: ["supernap", "superadmin", "admin", "accountant"],
      },
      {
        title: "Datos de Trabajadores",
        href: "/boletas/trabajadores/historico",
        icon: PeopleIcon,
        roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
      },
      {
        title: "Trabajadores por Fechas",
        href: "/boletas/trabajadores/xfechas",
        icon: PeopleIcon,
        roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
      },
      {
        title: "Trabajadores por Compañia",
        href: "/boletas/trabajadores/company",
        icon: PeopleIcon,
        roles: ["supernap", "superadmin"],
      },
      {
        title: "Declaraciones Juradas",
        icon: PermDataSettingIcon,
        roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
        children: [
          {
            title: "COVID 19 | Declaraciones juradas",
            icon: AcUnitIcon,
            href: "/sintomatologia-covid19",
            roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
          },
          {
            title: "SALUD | Declaraciones juradas",
            icon: NoteIcon,
            href: "/declaracion-salud",
            roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
          },
          {
            title: "PERSONAL PRIVADO | Declaraciones juradas",
            icon: NoteIcon,
            href: "/declaracion-personal",
            roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
          },
        ],
      },
      {
        title: "Información al trabajador",
        icon: AcUnitIcon,
        href: "/trabajador/informacion",
        roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
      },
      {
        title: "Incidencias",
        icon: ContactMailIcon,
        href: "/trabajador/incidencias-register",
        roles: ["supernap", "superadmin", "admin", "accountant"],
      },
      {
        title: "Formatos de asistencia",
        href: "/formatos/asistencia",
        icon: SubjectIcon,
        roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
      },
      {
        title: "Importación",
        href: "/boletas/importacion",
        icon: CloudUploadIcon,
        roles: ["supernap", "superadmin", "admin", "accountant"],
      },
    ],
  },
  {
    title: "RECLAMOS",
    roles: ["supernap", "superadmin", "admin", "usuario"],
    pages: [
      {
        title: "Reclamos",
        icon: RecordVoiceOverIcon,
        href: "/reclamos",
        roles: ["supernap", "superadmin", "admin", "usuario"],
      },
    ],
  },
  {
    title: "INCIDENCIAS",
    roles: ["supernap", "superadmin", "admin", "usuario"],
    pages: [
      {
        title: "Incidencias",
        icon: AccountTreeIcon,
        href: "/incidencias",
        roles: ["supernap", "superadmin", "admin", "usuario"],
      },
    ],
  },
  {
    title: "CONFIGURACIÓN",
    roles: ["supernap", "superadmin", "usuario"],
    pages: [
      {
        title: "Compañias",
        href: "/companies",
        icon: ApartmentIcon,
        roles: ["supernap"],
      },
      {
        title: "Empresas/Sedes",
        href: "/empresa",
        icon: BusinessIcon,
        roles: ["supernap", "superadmin"],
      },
      {
        title: "Usuarios",
        href: "/usuarios",
        icon: PeopleIcon,
        roles: ["supernap", "superadmin"],
      },
      {
        title: "Usuarios dehabilitados",
        href: "/usuarios/dehabilitados",
        icon: PersonAddDisabled,
        roles: ["supernap", "superadmin"],
      },
      {
        title: "Asignación",
        href: "/usersassign",
        icon: LowPriorityIcon,
        roles: ["supernap", "superadmin"],
      },
      {
        title: "Año de Trabajo ",
        href: "/configuracionanio",
        icon: SettingsIcon,
        roles: ["supernap"],
      },
    ],
  },
];

export default list;
