import React, { useState, useEffect } from "react";
import { Select } from "antd";

const SelectSedesHome = (props) => {
  const { Option } = Select;
  const [sedes, setSedes] = useState(props.data);

  const onChange = (number) => props.onChangeSelect(number);

  useEffect(() => {
    setSedes(props.data);
  }, [props]);

  const filterOptions = (inputValue, option) => {
    const title = option.props.title.toLowerCase();
    return title.includes(inputValue.toLowerCase(), 0);
  };

  return (
    <Select
      showSearch
      size="large"
      style={{ width: "100%" }}
      value={props.defaultValue}
      onChange={onChange}
      filterOption={filterOptions}
    >
      {sedes &&
        sedes
          .sort((a, b) => a["description"].localeCompare(b["description"]))
          .map((i) => (
            <Option
              key={i._id}
              value={i._id}
              title={`${i.rubro} - ${i.description} - ${i.address}`}
              onClick={() =>
                props.setTitle &&
                props.setTitle({
                  title: i.description,
                  address: i.address,
                })
              }
            >{`${i.rubro} - ${i.description} - ${i.address}`}</Option>
          ))}
    </Select>
  );
};
export default SelectSedesHome;
