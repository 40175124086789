import React, { useState } from "react";
import { notification, Spin } from "antd";
import { Button, Card, CardContent, Grid, Typography, TextField } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import { uploadWorkers } from "src/utils/req.boletas";
import { AttachFile } from "@material-ui/icons";

const ImportWorkers = () => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const sendData = async () => {
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("file", file);

      await uploadWorkers(formData);
      setFile(null);
      notification["success"]({
        message: `LA IMPORTACIÓN CONCLUYÓ EXITOSAMENTE!`,
      });
    } catch (error) {
      notification["error"]({
        message: `Ocurrió un error al realizar la operación`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} align="center">
              <Typography variant="h4">
                <b>IMPORTAR TRABAJADORES</b>
              </Typography>
            </Grid>
            <Grid item xs={12} align="center">
              <Typography
                component="a"
                variant="caption"
                href="https://filesym.s3.us-east-2.amazonaws.com/IMPORTACION_TRABAJADORES.xlsx"
                target="_blank"
                color="primary"
              >
                <AttachFile />
                <b>PLANTILLA DE IMPORTACIÓN</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Seleccionar archivo"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="file"
                inputProps={{
                  accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }}
                onChange={(e) => setFile(e.target.files[0])}
              />
            </Grid>
            <Grid item xs={12} align="center">
              <Button
                onClick={sendData}
                disabled={!file}
                variant="contained"
                color="primary"
                size="large"
              >
                IMPORTAR <PublishIcon />
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Spin>
  );
};

export default ImportWorkers;
