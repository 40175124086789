import React, { useState, useEffect } from "react";
import Page from "src/components/Page";
import TableWorkers from "./components/TableWorkers";
import { getWorkersByCompany } from "src/utils/req.boletas";
import { notification } from "antd";

const WorkersByCompany = () => {
  const [workers, setWorkers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    obtainWorkers();
  }, []);

  const obtainWorkers = async () => {
    try {
      setLoading(true);
      const res = await getWorkersByCompany();
      setWorkers([...res]);
    } catch (error) {
      notification["error"]({ message: `Ocurrió un error al obtener la lista de trabajadores.` });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page title="TRABAJADORES POR COMPAÑIA" helper="COLABORADORES">
      <TableWorkers data={workers} loading={loading} view="company" active={true} />
    </Page>
  );
};

export default WorkersByCompany;
