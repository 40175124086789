import axios from "axios";
import { apiContable } from "src/config/api.contable";
import sedeactual from "src/config/sedeactual";

// TRABAJADORES

export const getIngresos = async () => {
  let res = await axios.get(`${apiContable}/entry/${sedeactual.sede_id()}/${sedeactual.getAnio()}`);
  return res.data;
};

export const getIngresosCompany = async () => {
  let res = await axios.get(
    `${apiContable}/entry/company/${sedeactual.company()._id}/${sedeactual.getAnio()}`
  );
  return res.data;
};

export const uploadFileAndGetURL = async (data) => {
  let res = await axios.post(`${apiContable}/entry/evidence`, data);
  return res.data;
};

export const postIngresos = async (data, year) => {
  let res = await axios.post(`${apiContable}/entry/${sedeactual.sede_id()}/${year}`, data);
  return res.data;
};

export const putIngresos = async (data, id) => {
  let res = await axios.put(`${apiContable}/entry/${id}`, data);
  return res.data;
};
