import React, { useEffect, useState } from "react";
import Page from "../Page";
import { Button } from "@material-ui/core";
import TableCompany from "./components/TableCompany";
import { notification } from "antd";
import { getAllCompanies, getModules } from "../../api/api.contable";
import ModalCompany from "./components/ModalCompany";

const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalCompany, setModalCompany] = useState(false);
  const [modules, setModules] = useState([]);

  useEffect(() => {
    obtainCompanies();
    obtainModules();
  }, []);

  const obtainCompanies = async () => {
    setLoading(true);
    try {
      const res = await getAllCompanies();
      setCompanies(res);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al obtener la información.",
        description: `${error}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const obtainModules = async () => {
    try {
      let result = await getModules("modules");
      setModules(result);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al obtener los módulos.",
        description: `${error}`,
      });
    }
  };

  return (
    <Page
      helper="EMPRESA"
      title="COMPAÑIAS"
      itemComponent={
        <Button
          color="primary"
          variant="contained"
          onClick={() => setModalCompany(true)}
        >
          AGREGAR
        </Button>
      }
    >
      <TableCompany
        data={companies}
        setLoading={setLoading}
        loading={loading}
        obtainCompanies={obtainCompanies}
        modules={modules}
      />
      {modalCompany && (
        <ModalCompany
          visible={modalCompany}
          setVisible={setModalCompany}
          setLoading={setLoading}
          obtainCompanies={obtainCompanies}
        />
      )}
    </Page>
  );
};

export default Companies;
