import React from "react";
import { Select } from "antd";

const { Option } = Select;
let list = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "setiembre",
  "octubre",
  "noviembre",
  "diciembre",
];
const SelectConcepto = ({ month, setMonth, width = "100%", aditional = [] }) => {
  const filterOptions = (inputValue, option) => {
    const title = option.props.title.toLowerCase();
    return title.includes(inputValue.toLowerCase(), 0);
  };

  return (
    <Select showSearch size="large" style={{ width }} value={month} onChange={setMonth} filterOption={filterOptions}>
      {[...list, ...aditional].map((text, index) => (
        <Option key={index} value={text.toUpperCase()} title={text.toUpperCase()}>
          {text.toUpperCase()}
        </Option>
      ))}
    </Select>
  );
};
export default SelectConcepto;
