import React, { Fragment, useEffect, useState } from "react";
import Page from "src/components/Page";
import TableDocuments from "./components/TableDocuments";
import {
  getWorkerDocuments,
  getWorkerDocumentsCompany,
  putWorkerDocuments,
} from "src/utils/req.boletas";
import { notification } from "antd";
import GenerateDocuments from "./components/GenerateDocuments";
import sedeactual from "src/config/sedeactual";
import { Grid, FormControlLabel, Switch } from "@material-ui/core";
import DocumentsSuperAdmin from "./components/DocumentsSuperAdmin";

const Documents = () => {
  const val = sedeactual.getRol() === "supernap";
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(false);

  useEffect(() => {
    obtainData();
  }, [change]);

  const obtainData = async () => {
    try {
      setLoading(true);
      let res = [];
      if (change) res = await getWorkerDocumentsCompany();
      else res = await getWorkerDocuments();
      setData([...res]);
    } catch (error) {
      notification["error"]({ message: `Ocurrió un error al realizar la operación` });
    } finally {
      setLoading(false);
    }
  };

  const uploadDocument = async (file, field, id) => {
    try {
      setLoading(true);
      const form = new FormData();
      form.append("file", file);
      form.append("field", field);
      await putWorkerDocuments(form, id);
      notification["success"]({ message: `Archivo subido correctamente...` });
      obtainData();
    } catch (error) {
      notification["error"]({ message: `Ocurrió un error al realizar la operación` });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page
      title="DOCUMENTOS"
      helper="COLABORADORES"
      itemComponent={
        val ? (
          <Grid container spacing={1}>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={change}
                    onChange={(a, b) => {
                      setData([]);
                      setChange(b);
                    }}
                    name="view"
                    color="primary"
                  />
                }
                label="Cambiar de vista"
              />
            </Grid>
          </Grid>
        ) : (
          <span />
        )
      }
    >
      {change ? (
        <DocumentsSuperAdmin data={data} />
      ) : (
        <Fragment>
          {data.length > 0 ? (
            <TableDocuments
              data={data}
              loading={loading}
              uploadDocument={uploadDocument}
              isSN={val}
            />
          ) : (
            <GenerateDocuments obtainData={obtainData} setLoading={setLoading} loading={loading} />
          )}
        </Fragment>
      )}
    </Page>
  );
};

export default Documents;
