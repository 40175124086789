import React, { useState, useEffect } from "react";
import { Grid, Container, Card, CardContent, Button } from "@material-ui/core";
import TablaCostos from "./components/TablaCostos";
import Page from "../../components/Page";
import sedeactual from "../../config/sedeactual";
import ModalAddCost from "./components/ModalAddCost";
import { getCentroCostos } from "../../api/api.contable";
import { notification } from "antd";

const Costos = () => {
  const [data, setData] = useState([]);
  const [openModalAddCost, setOpenModalAddCost] = useState(false);
  const [lastCode, setLastCode] = useState("");
  const [loading, setLoading] = useState(false);

  const getCostos = async () => {
    setLoading(true);
    try {
      const result = await getCentroCostos(sedeactual.get());
      setData(result.data);
      setLastCode(
        result.data.length > 0 && result.data[result.data.length - 1].code
      );
    } catch (err) {
      notification["error"]({
        message: "No se pudo obtener la información",
        description: `${err}`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCostos();
  }, []);

  return (
    <Page
      helper="CONFIGURACIÓN"
      title="COSTOS"
      itemComponent={
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenModalAddCost(true)}
          size="large"
          disabled={sedeactual.getRol() === "usuario"}
        >
          AGREGAR
        </Button>
      }
    >
      <TablaCostos
        data={data}
        reload={getCostos}
        loading={loading}
        setLoading={setLoading}
      />
      {openModalAddCost && (
        <ModalAddCost
          visible={openModalAddCost}
          setVisible={setOpenModalAddCost}
          getCostos={getCostos}
          lastCode={lastCode}
          setLoading={setLoading}
        />
      )}
    </Page>
  );
};

export default Costos;
