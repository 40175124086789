import React from "react";
import { useParams } from "react-router-dom";
import { Card, CardContent, Typography, Button, Grid } from "@material-ui/core";
import axios from "axios";
import { apiContable } from "../../config/api.contable";
import { notification } from "antd";
import moment from "moment";

const ConfirmRecoveredPassword = () => {
  const { token } = useParams();
  let year = moment().format("YYYY");

  const sendEmail = async () => {
    try {
      await axios.post(`${apiContable}/auth/reset_password`, { token: token });
      notification["success"]({
        message: "Contraseña enviada correctamente.",
        description: "Revise su bandeja de entrada.",
      });
    } catch (error) {
      notification["success"]({
        message: "Ocurrió un error",
        description: `No se envió el correo. ${error}`,
      });
    }
  };

  return (
    <Grid
      container
      style={{ minHeight: "100vh" }}
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <Card style={{ maxWidth: 400 }} elevation={5}>
          <CardContent>
            <Typography gutterBottom variant="h3">
              RECUPERAR CONTRASEÑA
            </Typography>
            <Typography variant="subtitle2">
              PRESIONA EL SIGUIENTE BOTÓN PARA REESTABLECER SU CONTRASEÑA
            </Typography>
            <br />
            <Button
              color="secondary"
              size="large"
              onClick={sendEmail}
              variant="contained"
              fullWidth
            >
              Recuperar
            </Button>
            <br />
            <br />
            <div align="center">
              <br />
              <a href="https://www.netappperu.com/">
                <small>NetAppPeru © 2019 - {year}</small>
              </a>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export default ConfirmRecoveredPassword;
