import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { postWorkerDocuments } from "src/utils/req.boletas";
import { notification, Spin } from "antd";
import sedeactual from "src/config/sedeactual";
import { monthsList, typesDocuments } from "src/utils/constants";

const GenerateDocuments = ({ setLoading, obtainData, loading }) => {
  const createDocuments = async () => {
    try {
      setLoading(true);
      const toSave = [];
      monthsList.forEach((mth, iOne) => {
        let toPush = {
          order: iOne + 1,
          date_upload: "",
          year: sedeactual.getAnio(),
          month: mth.label,
          headquarter: sedeactual.sede_id(),
          company: sedeactual.company()._id,
        };
        typesDocuments.forEach((type) => Object.assign(toPush, { [type.key]: "" }));
        toSave.push(toPush);
      });

      await postWorkerDocuments({ data: toSave });
      obtainData();
      notification["success"]({
        message: `Los documentos se generaron desde ENERO a DICIEMBRE del ${sedeactual.getAnio()}`,
      });
    } catch (error) {
      notification["error"]({
        message: `Ocurrió un error al realizar la operación`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Grid container alignItems="center" justify="center" style={{ minHeight: 400 }}>
        <Grid item align="center">
          <Typography variant="h4">NO SE GENERÓ LOS ARCHIVOS A SUBIR PARA ESTE AÑO</Typography>
          <br />
          <Typography variant="body1" color="textSecondary">
            ¿DESEA GENERAR LOS DOCUMENTOS PARA ESTE {sedeactual.getAnio()}?
            <br />
            Recuerde que esta acción no podrá deshacerse.
          </Typography>
          <br />
          <br />
          <Button variant="outlined" color="primary" size="large" onClick={createDocuments}>
            GENERAR DOCUMENTOS
          </Button>
        </Grid>
      </Grid>
    </Spin>
  );
};

export default GenerateDocuments;
