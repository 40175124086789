import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogActions,
  Button,
  DialogContent,
  Grid,
  TextField,
  Menu,
  Box,
  FormHelperText,
} from "@material-ui/core";
import moment from "moment";
import { notification, Spin } from "antd";
import { putClaim } from "src/utils/reqinc";

const ModalAsnwerClaim = ({ open, setOpen, dataClaim, loading, setLoading, refreshFunction }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [detailResponse, setDetailResponse] = useState(dataClaim.detailResponse);
  const confirmRef = useRef(null);

  const sendAnwer = async () => {
    setLoading(true);
    try {
      let items = {};
      items["status"] = "Cerrado";
      items["dateResponse"] = Date.now();
      items["detailResponse"] = detailResponse;
      items["system"] = "napcontable";
      items["headquarter"] = dataClaim.headquarter;
      items["edit"] = true;

      await putClaim(items, dataClaim._id);

      notification["success"]({
        message: "La respuesta se envió correctamente.",
      });
      refreshFunction();
      setOpen({ open: false });
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al enviar la respuesta.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography align="center" variant="h3">
          Responder reclamo
        </Typography>
      </DialogTitle>
      <Spin spinning={loading}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <TextField
                label="Fecha"
                variant="outlined"
                type="date"
                fullWidth
                value={moment(dataClaim.dateResponse).format("YYYY-MM-DD")}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <FormControl fullWidth variant="outlined">
                  <InputLabel>Estado</InputLabel>
                  <Select
                    value={status}
                    onChange={(event) => setStatus(event.target.value)}
                    label="Estado"
                  >
                    <MenuItem value="Abierto">Abierto</MenuItem>
                    <MenuItem value="Cerrado">Cerrado</MenuItem>
                  </Select>
                </FormControl> */}
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label="Respuesta"
                variant="outlined"
                fullWidth
                multiline
                value={detailResponse}
                onChange={(e) => setDetailResponse(e.target.value)}
              />
              <FormHelperText>
                Recuerde que la respuesta solo puede ser emitida una vez.
              </FormHelperText>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setOpen({ open: false })}
            ref={confirmRef}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setAnchorEl(confirmRef.current)}
          >
            RESPONDER
          </Button>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <Box p={2} align="center">
              <Typography variant="subtitle1" align="center">
                <b>¿Seguro que desea realizar la respuesta?</b>
              </Typography>
              <FormHelperText>
                RECUERDE QUE UNA VEZ ENVIADA YA NO PODRÁ MODIFICAR SU RESPUESTA.
              </FormHelperText>
              <br />
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={sendAnwer}
                disabled={loading}
              >
                CONFIRMAR
              </Button>
            </Box>
          </Menu>
        </DialogActions>
      </Spin>
    </Dialog>
  );
};

export default ModalAsnwerClaim;
