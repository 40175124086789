import React from "react";
import { Select } from "antd";

const list = [
  { key: "DNI", label: "DNI" },
  { key: "RUC", label: "RUC" },
  { key: "PASAPORTE", label: "PASAPORTE" },
  { key: "CARNET_EXT", label: "CARNET DE EXTRANJERÍA" },
];

const { Option } = Select;

const SelectTypeDoc = ({ document, setDocument, filterBy }) => {
  const filterOptions = (inputValue, option) => {
    const title = option.props.title
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    return title.includes(inputValue.toLowerCase());
  };

  return (
    <Select
      size="large"
      style={{ width: "100%" }}
      showSearch
      value={document}
      onChange={setDocument}
      filterOption={filterOptions}
    >
      {list
        .filter((item) => (filterBy ? item.key === filterBy : true))
        .map((i) => (
          <Option key={i.key} value={i.key} title={`${i.key} ${i.label}`}>
            {i.label}
          </Option>
        ))}
    </Select>
  );
};

export default SelectTypeDoc;
