import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { RecoilRoot } from "recoil";
import "antd/dist/antd.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/custom.scss";
import "bootstrap/dist/js/bootstrap.js";
import "./assets/themes/Lumen/bootstrap.css";
import "./assets/index.scss";
import "moment/locale/es";

ReactDOM.render(
  <RecoilRoot>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </RecoilRoot>,
  document.getElementById("root")
);
