import sedeactual from "src/config/sedeactual";

const types = {
  "01": `COMPROBANTE DE PAGO ─ ENERO ${sedeactual.getAnio()}`,
  "02": `COMPROBANTE DE PAGO ─ FEBRERO ${sedeactual.getAnio()}`,
  "03": `COMPROBANTE DE PAGO ─ MARZO ${sedeactual.getAnio()}`,
  "04": `COMPROBANTE DE PAGO ─ ABRIL ${sedeactual.getAnio()}`,
  "05": `COMPROBANTE DE PAGO ─ MAYO ${sedeactual.getAnio()}`,
  "06": `COMPROBANTE DE PAGO ─ JUNIO ${sedeactual.getAnio()}`,
  "07": `COMPROBANTE DE PAGO ─ JULIO ${sedeactual.getAnio()}`,
  "08": `COMPROBANTE DE PAGO ─ AGOSTO ${sedeactual.getAnio()}`,
  "09": `COMPROBANTE DE PAGO ─ SETIEMBRE ${sedeactual.getAnio()}`,
  10: `COMPROBANTE DE PAGO ─ OCTUBRE ${sedeactual.getAnio()}`,
  11: `COMPROBANTE DE PAGO ─ NOVIEMBRE ${sedeactual.getAnio()}`,
  12: `COMPROBANTE DE PAGO ─ DICIEMBRE ${sedeactual.getAnio()}`,
  13: `COMPROBANTE DE PAGO ─ GRATIFICACIÓN JULIO ${sedeactual.getAnio()}`,
  14: `COMPROBANTE DE PAGO ─ GRATIFICACIÓN DICIEMBRE ${sedeactual.getAnio()}`,
  15: `COMPROBANTE DE PAGO ─ CTS MAYO ${sedeactual.getAnio()}`,
  16: `COMPROBANTE DE PAGO ─ CTS NOVIEMBRE ${sedeactual.getAnio()}`,
  17: `CERTIFICADO DE 5TA CATEGORÍA ${sedeactual.getAnio()}`,
  18: `CONTRATO DE TRABAJO ${sedeactual.getAnio()}`,
};

export default types;
