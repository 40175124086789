import React, { useEffect, useState } from "react";
import { CardContent, Typography } from "@material-ui/core";
import moment from "moment";
import Spinner from "src/components/Global/Spinner";
import Page from "src/components/Page";
import sedeactual from "src/config/sedeactual";
import TableReporteEstadoFlujosEfectivo from "./TableReporteEstadoFlujosEfectivo";
import { apiReportes } from "src/config/api.contable";
import axios from "axios";

const year = sedeactual.getAnio();

const ReporteEstadoFlujosEfectivo = () => {
  const [data, setData] = useState(null);
  const [lastData, setLastData] = useState(null);
  const [error, setError] = useState(false);

  const getTotalData = async () => {
    try {
      setData(null);
      setLastData(null);
      setError(false);
      const [{ data: res1 }, { data: res2 }] = await Promise.all([
        axios.get(
          `${apiReportes}/eeff/${year}/${sedeactual.get()}/${
            sedeactual.company().url
          }`
        ),
        axios.get(
          `${apiReportes}/eeff/${year - 1}/${sedeactual.get()}/${
            sedeactual.company().url
          }`
        ),
      ]);

      setData(res1);
      setLastData(res2);
    } catch (error) {
      setError(true);
    }
  };

  useEffect(() => {
    getTotalData();
  }, []);

  return (
    <Page
      title="REPORTE DE ESTADO DE FLUJOS DE EFECTIVO - MÉTODO INDIRECTO"
      helper="REPORTE"
    >
      <CardContent align="center">
        <Typography variant="h3" color="textPrimary">
          <b>{sedeactual.description()}</b>
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          Av. 28 de julio 909-Lima-Huacho
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          RUC N° 20599076894
        </Typography>
        <Typography variant="h5" color="textPrimary">
          <b>ESTADO DE FLUJOS DE EFECTIVO - MÉTODO INDIRECTO</b>
        </Typography>
        <Typography variant="subtitle2" color="textPrimary">
          Por el año terminado al {moment().format("DD [de] MMMM [del] YYYY")}
        </Typography>
      </CardContent>
      {data && lastData ? (
        <TableReporteEstadoFlujosEfectivo
          year={year}
          data={data}
          lastData={lastData}
        />
      ) : error ? (
        <Typography color="error" variant="body2">
          No se pudo obtener la información, intente mas tarde.
        </Typography>
      ) : (
        <Spinner />
      )}
    </Page>
  );
};

export default ReporteEstadoFlujosEfectivo;
