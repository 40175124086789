import React, { useState } from "react"
import { notification, Table, Tag } from "antd"
import axios from "axios"
import { Button, TextField, Avatar, IconButton, ButtonGroup, Tooltip } from "@material-ui/core"
import { apiInventario } from "../../../../config/api.contable"
import AvatarGroup from "@material-ui/lab/AvatarGroup"
import moment from "moment"
import ConfirmDelete from "../ModalConfirmDelete"
import ModalEditTerreno from "./components/ModalEditTerreno"
import ModalDetailsTerreno from "./components/ModalDetailsTerreno"
import { makeStyles } from "@material-ui/styles"
import EditIcon from "@material-ui/icons/Edit"
import MoreIcon from "@material-ui/icons/More"
import DeleteIcon from "@material-ui/icons/Delete"
import SearchIcon from "@material-ui/icons/Search"
import ClearIcon from "@material-ui/icons/Clear"
import PlusOneIcon from "@material-ui/icons/PlusOne"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import Highlighter from "react-highlight-words"
import AddIcon from "@material-ui/icons/AddOutlined"
import { postEnableInventory } from "../../requests"
import napformat from "../../../../config/napformat"
import CreateMemorandum from "../CreateMemorandum"
import { firstPendingAction, isHiddenByDelete, selection } from "../../Memorandums"

const useStyles = makeStyles( {
  textR: { textAlign: "right", padding: "0 5px" },
  textL: { textAlign: "left", padding: "0 5px" },
} )

const noPhoto = "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"

const TablaTerrenos = ( props ) => {
  const {
    data,
    reload,
    loading,
    setLoading,
    onlyview = false,
    isActive,
    memorandum,
    setMemorandum,
  } = props
  const classes = useStyles()
  const [ searchText, setsearchText ] = useState( "" )
  const [ openModalEditTerreno, setOpenModalEditTerreno ] = useState( false )
  const [ openModalDetailsTerreno, setOpenModalDetailsTerreno ] = useState( false )
  const [ searchedColumn, setsearchedColumn ] = useState( "" )
  const [ dataTerreno, setDataTerreno ] = useState( [] )
  const [ modalConfirmDelete, setModalConfirmDelete ] = useState( {
    idTerreno: "",
    open: false,
  } )

  const handleSearch = ( selectedKeys, confirm, dataIndex ) => {
    confirm()
    setsearchText( selectedKeys[ 0 ] )
    setsearchedColumn( dataIndex )
  }

  const handleReset = ( clearFilters, selectedKeys, setSelectedKeys ) => {
    clearFilters()
    setsearchText( "" )
    setSelectedKeys( [ "" ] )
  }

  const enableInventory = async ( idRow, record ) => {
    setLoading( true )
    try {
      await postEnableInventory( "ground", idRow )
      notification[ "success" ]( {
        message: "¡ÉXITO!",
        description: `Se habilitó correctamente el correlativo ${ record.correlative }.`,
      } )
      reload()
    } catch ( error ) {
      notification[ "error" ]( {
        message: "¡ERROR!",
        description: `No se pudo habilitar el correlativo ${ record.correlative }.`,
      } )
    } finally {
      setLoading( false )
    }
  }

  const uploadImages = async ( code, files ) => {
    if ( files.length > 0 ) {
      setLoading( true )
      try {
        let dataImages = new FormData()
        for ( let i = 0; i < files.length; i++ ) {
          dataImages.append( "images", files[ i ] )
        }
        await axios.post( `${ apiInventario }/upload-img-ground/${ code }`, dataImages )
        notification[ "success" ]( {
          message: "¡ÉXITO!",
          description: "La(s) imagen(es) se guardaron correctamente.",
        } )
        reload()
      } catch ( error ) {
        notification[ "error" ]( {
          message: "¡ERROR!",
          description: `No se pudieron agregar la(s) imagen(es). ${ error }`,
        } )
      } finally {
        setLoading( false )
      }
    }
  }

  const uploadDocs = async ( code, files ) => {
    if ( files.length > 0 ) {
      setLoading( true )
      try {
        let dataImages = new FormData()
        for ( let i = 0; i < files.length; i++ ) {
          dataImages.append( "docs", files[ i ] )
        }
        await axios.post( `${ apiInventario }/upload-doc-ground/${ code }`, dataImages )
        notification[ "success" ]( {
          message: "¡ÉXITO!",
          description: "El/Los documento(s) se guardaron correctamente.",
        } )
        reload()
      } catch ( error ) {
        notification[ "success" ]( {
          message: "¡ERROR!",
          description: `No se pudieron agregar el/los documento(s). ${ error }`,
        } )
        setLoading( false )
      } finally {
        setLoading( false )
      }
    }
  }

  const getColumnSearchProps = ( dataIndex ) => ( {
    filterDropdown: ( { setSelectedKeys, selectedKeys, confirm, clearFilters } ) => (
      <div style={ { padding: 8 } }>
        <TextField
          variant="outlined"
          placeholder="Buscar..."
          size="small"
          value={ selectedKeys[ 0 ] }
          onChange={ ( e ) => {
            setSelectedKeys( e.target.value ? [ e.target.value ] : [] )
          } }
          onPressEnter={ () => handleSearch( selectedKeys, confirm, dataIndex ) }
          style={ { width: 188, marginBottom: 8, display: "block" } }
        />
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={ () => handleReset( clearFilters, selectedKeys, setSelectedKeys ) }
          style={ { width: 90 } }
        >
          Limpiar <ClearIcon />
        </Button>{ " " }
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={ () => handleSearch( selectedKeys, confirm, dataIndex ) }
        >
          Buscar <SearchIcon />
        </Button>
      </div>
    ),
    filterIcon: ( filtered ) => <SearchIcon style={ { color: filtered ? "#1890ff" : undefined } } />,
    onFilter: ( value, record ) =>
      record[ dataIndex ].toString().toLowerCase().includes( value.toLowerCase() ),
    onFilterDropdownVisibleChange: ( visible ) => {
      if ( visible ) {
      }
    },
    render: ( text ) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={ { backgroundColor: "#ffc069", padding: 0 } }
          searchWords={ [ searchText ] }
          autoEscape
          textToHighlight={ text.toString() }
        />
      ) : (
        text
      ),
  } )

  const columns = [
    {
      title: "ACCIONES",
      dataIndex: "_id",
      key: "",
      width: 100,
      align: "center",
      render: ( text, record ) => (
        <ButtonGroup style={ { width: "100%", background: "#fff" } }>
          { onlyview === false &&
            ( isActive ? (
              isHiddenByDelete( record ) && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={ () => setModalConfirmDelete( { idTerreno: text, open: true } ) }
                >
                  <Tooltip title="Deshabilitar" aria-label="disable">
                    <DeleteIcon />
                  </Tooltip>
                </Button>
              )
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={ { backgroundColor: "#4caf50" } }
                onClick={ () => enableInventory( text, record ) }
              >
                <Tooltip title="Habilitar">
                  <PlusOneIcon />
                </Tooltip>
              </Button>
            ) ) }
          { isActive && (
            <Button
              variant="outlined"
              color="primary"
              onClick={ () => {
                setOpenModalEditTerreno( true )
                setDataTerreno( record )
              } }
            >
              <EditIcon />
            </Button>
          ) }
          <Button
            variant="contained"
            color="primary"
            onClick={ () => {
              setOpenModalDetailsTerreno( true )
              setDataTerreno( record )
            } }
          >
            <MoreIcon />
          </Button>
        </ButtonGroup>
      ),
    },
    {
      title: "CORRELATIVO",
      dataIndex: "correlative",
      key: "correlative",
      width: 200,
      align: "center",
      sorter: ( a, b ) => {
        if ( a.correlative > b.correlative ) return 1
        if ( a.correlative < b.correlative ) return -1
      },
      ...getColumnSearchProps( "correlative" ),
      render: ( value, record ) => (
        <>
          { value }
          <br />
          { ( ( record.memoInfo?.delete || record.memoInfo?.transfer ) && record.memoInfo?.memorandum?.active ) && (
            <Tag style={ { background: "#FFC107", color: "#fff", borderColor: "#FFC107" } }>
              { `${ record.memoInfo?.delete ? "ELIMINACIÓN" : "" } 
                ${ record.memoInfo?.delete ? ( record.memoInfo?.transfer ? "Y" : "" ) : "" } 
                ${ record.memoInfo?.transfer ? "TRANSFERENCIA" : "" }` }
            </Tag>
          ) }
        </>
      ),
    },
    {
      title: "DENOMINACIÓN DEL PREDIO",
      dataIndex: "property_name",
      key: "property_name",
      width: "220px",
      align: "center",
      sorter: ( a, b ) => {
        if ( a.property_name > b.property_name ) return 1
        if ( a.property_name < b.property_name ) return -1
      },
      ...getColumnSearchProps( "property_name" ),
      render: ( text, record, index ) => (
        <>
          <div className={ classes.textL }>{ record.property_name ? record.property_name : "-" }</div>
        </>
      ),
    },
    {
      title: "DIRECCIÓN",
      dataIndex: "address",
      key: "address",
      width: "300px",
      align: "center",
      sorter: ( a, b ) => {
        if ( a.address > b.address ) return 1
        if ( a.address < b.address ) return -1
      },
      ...getColumnSearchProps( "address" ),
      render: ( text, record, index ) => (
        <>
          <div className={ classes.textL }>{ record.address ? record.address : "-" }</div>
        </>
      ),
    },
    {
      title: "ÁREA DEL TERRENO",
      dataIndex: "area",
      key: "area",
      width: "160px",
      align: "center",
      sorter: ( a, b ) => {
        if ( a.area > b.area ) return 1
        if ( a.area < b.area ) return -1
      },
      ...getColumnSearchProps( "area" ),
      render: ( text, record, index ) =>
        text ? (
          <>
            { napformat( text ) }mt<sup>2</sup>
          </>
        ) : (
          ""
        ),
    },
    {
      title: "PERÍMETRO",
      children: [
        {
          title: "Lado A",
          dataIndex: "side_a",
          key: "side_a",
          width: "160px",
          align: "center",
          sorter: ( a, b ) => {
            if ( a.side_a > b.side_a ) return 1
            if ( a.side_a < b.side_a ) return -1
          },
          ...getColumnSearchProps( "side_a" ),
          render: ( text, record, index ) => ( text ? <>{ napformat( text ) }mt</> : "" ),
        },
        {
          title: "Lado B",
          dataIndex: "side_b",
          key: "side_b",
          width: "160px",
          align: "center",
          sorter: ( a, b ) => {
            if ( a.side_b > b.side_b ) return 1
            if ( a.side_b < b.side_b ) return -1
          },
          ...getColumnSearchProps( "side_b" ),
          render: ( text, record, index ) => ( text ? <>{ napformat( text ) }mt</> : "" ),
        },
        {
          title: "LADO C",
          dataIndex: "side_c",
          key: "side_c",
          width: "160px",
          align: "center",
          sorter: ( a, b ) => {
            if ( a.side_c > b.side_c ) return 1
            if ( a.side_c < b.side_c ) return -1
          },
          ...getColumnSearchProps( "side_c" ),
          render: ( text, record, index ) => ( text ? <>{ napformat( text ) }mt</> : "" ),
        },
        {
          title: "LADO D",
          dataIndex: "side_d",
          key: "side_d",
          width: "160px",
          align: "center",
          sorter: ( a, b ) => {
            if ( a.side_d > b.side_d ) return 1
            if ( a.side_d < b.side_d ) return -1
          },
          ...getColumnSearchProps( "side_d" ),
          render: ( text, record, index ) => ( text ? <>{ napformat( text ) }mt</> : "" ),
        },
        {
          title: "LADO E",
          dataIndex: "side_another",
          key: "side_another",
          width: "160px",
          align: "center",
          sorter: ( a, b ) => {
            if ( a.side_another > b.side_another ) return 1
            if ( a.side_another < b.side_another ) return -1
          },
          ...getColumnSearchProps( "side_another" ),
          render: ( text, record, index ) => ( text ? <>{ napformat( text ) }mt</> : "" ),
        },
      ],
    },
    {
      title: "N° PARTIDA ELECTRÓNICA",
      dataIndex: "departure_number",
      key: "departure_number",
      width: "220px",
      align: "center",
      sorter: ( a, b ) => {
        if ( a.departure_number > b.departure_number ) return 1
        if ( a.departure_number < b.departure_number ) return -1
      },
      ...getColumnSearchProps( "departure_number" ),
      render: ( text, record, index ) => (
        <>
          <div>{ record.departure_number ? record.departure_number : " - " }</div>
        </>
      ),
    },
    {
      title: "FECHA DE ADQUISICIÓN",
      dataIndex: "acquisition_date",
      key: "acquisition_date",
      width: "220px",
      align: "center",
      sorter: ( a, b ) => {
        if ( a.acquisition_date > b.acquisition_date ) return 1
        if ( a.acquisition_date < b.acquisition_date ) return -1
      },
      ...getColumnSearchProps( "acquisition_date" ),
      render: ( text ) => moment( text ).utc( false ).format( "DD/MM/YYYY" ),
    },
    {
      title: "TOTAL VALOR COMPRA",
      dataIndex: "acquisition_value",
      key: "acquisition_value",
      width: "220px",
      align: "center",
      sorter: ( a, b ) => {
        if ( a.acquisition_value > b.acquisition_value ) return 1
        if ( a.acquisition_value < b.acquisition_value ) return -1
      },
      ...getColumnSearchProps( "acquisition_value" ),
      render: ( text, record, index ) => ( text ? <>S/{ napformat( text ) }</> : "" ),
    },
    {
      title: "VALOR DE AUTOVALUO MUNICIPALIDAD TERRENO",
      dataIndex: "muni_autovalue_ground",
      key: "muni_autovalue_ground",
      width: "220px",
      align: "center",
      sorter: ( a, b ) => {
        if ( a.muni_autovalue_ground > b.muni_autovalue_ground ) return 1
        if ( a.muni_autovalue_ground < b.muni_autovalue_ground ) return -1
      },
      ...getColumnSearchProps( "muni_autovalue_ground" ),
      render: ( text, record, index ) => ( text ? <>S/{ napformat( text ) }</> : "" ),
    },
    {
      title: "OBSERVACIONES",
      dataIndex: "observations",
      key: "observations",
      width: "300px",
      align: "center",
      sorter: ( a, b ) => {
        if ( a.observations > b.observations ) return 1
        if ( a.observations < b.observations ) return -1
      },
      ...getColumnSearchProps( "observations" ),
      render: ( text, record, index ) => (
        <>
          <div className={ classes.textL }>{ record.observations ? record.observations : "-" }</div>
        </>
      ),
    },
    {
      title: "ESTADO",
      dataIndex: "active",
      key: "active",
      width: 125,
      ...getColumnSearchProps( "active" ),
      align: "center",
      sorter: ( a, b ) => {
        if ( a.active > b.active ) return 1
        if ( a.active < b.active ) return -1
      },
      render: ( text, record, index ) =>
        text === true ? (
          <FiberManualRecordIcon style={ { color: "#4caf50" } } />
        ) : (
          <FiberManualRecordIcon style={ { color: "#e53935" } } />
        ),
    },
    {
      title: "IMAGENES",
      dataIndex: "images",
      key: "images",
      width: "175px",
      align: "center",
      render: ( text, record, index ) => (
        <AvatarGroup style={ { paddingLeft: 5 } }>
          <IconButton
            style={ {
              padding: 0,
              height: 40,
              width: 40,
              backgroundColor: "#0688d1",
              color: "white",
            } }
            component="label"
          >
            <AddIcon />
            <input
              style={ { display: "none" } }
              accept="image/*"
              type="file"
              onChange={ ( event ) => uploadImages( record._id, event.target.files ) }
              multiple
            />
          </IconButton>
          { record.images &&
            record.images
              .slice( 0, 3 )
              .map( ( image ) => <Avatar style={ { border: "1px solid #2196f3" } } src={ image } /> ) }
          { record.images && record.images.length > 0 ? (
            record.images.length > 3 && <Avatar>+{ record.images.length - 3 }</Avatar>
          ) : (
            <Avatar src={ noPhoto } />
          ) }
        </AvatarGroup>
      ),
    },
    {
      title: "DOCUMENTOS SUSTENTATORIOS",
      dataIndex: "list_docs",
      key: "list_docs",
      width: "150px",
      align: "center",
      render: ( text, record, index ) => (
        <AvatarGroup>
          <>
            <IconButton
              style={ {
                padding: 0,
                height: 40,
                width: 40,
                backgroundColor: "#0688d1",
                color: "white",
              } }
              component="label"
            >
              <AddIcon />
              <input
                style={ { display: "none" } }
                accept="application/pdf, image/png, image/jpeg"
                type="file"
                multiple
                onChange={ ( event ) => uploadDocs( record._id, event.target.files ) }
              />
            </IconButton>
            <Avatar>{ text.length }</Avatar>
          </>
        </AvatarGroup>
      ),
    },
  ]

  return (
    <>
      <Table
        columns={ columns }
        dataSource={ firstPendingAction( data ) }
        bordered
        //pagination={false}
        loading={ loading }
        scroll={ { x: "auto" } }
        rowSelection={ { type: "checkbox", ...selection( setMemorandum, memorandum ) } }
      />

      { openModalDetailsTerreno && (
        <ModalDetailsTerreno
          visible={ openModalDetailsTerreno }
          setVisible={ setOpenModalDetailsTerreno }
          dataTerreno={ dataTerreno }
          reload={ reload }
        />
      ) }
      { openModalEditTerreno && (
        <ModalEditTerreno
          visible={ openModalEditTerreno }
          setVisible={ setOpenModalEditTerreno }
          dataTerreno={ dataTerreno }
          setDataTerreno={ setDataTerreno }
          reload={ reload }
          setLoading={ setLoading }
        />
      ) }

      { modalConfirmDelete.open && (
        <ConfirmDelete
          disable={ true }
          visible={ modalConfirmDelete.open }
          setVisible={ setModalConfirmDelete }
          reload={ reload }
          endPoint={ `ground/${ modalConfirmDelete.idTerreno }` }
          title="¿Esta seguro(a) que desea deshabilitar este registro?"
          subtitle="Tener en cuenta que después de realizar esta operación el registro estará en la lista de deshabilitado."
        />
      ) }

      { memorandum.open && (
        <CreateMemorandum
          open={ memorandum.open }
          setOpen={ setMemorandum }
          data={ memorandum.data }
          type={ memorandum.type }
          handleReload={ reload }
          param="ground"
          action={ `${ memorandum.type === "delete" ? "DESHABILITACIÓN" : "TRANSFERENCIA"
            } DE TERRENO(S)` }
        />
      ) }
    </>
  )
}

export default TablaTerrenos
