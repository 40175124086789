import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import FormHelperText from "@material-ui/core/Typography"
import {
  getHeadquartersByUserAndCompany,
  getHeadquarters,
} from "../../api/api.contable"
import { notification } from "antd"
import LoaderBackdrop from "../../components/Global/LoaderBackdrop"
import sedeactual from "../../config/sedeactual"
import { TextField } from '@material-ui/core'

const useStyles = makeStyles( () => ( {
  content: {
    width: 340,
    height: 500,
    overflowX: "hidden",
    overflowY: "auto",
  },
} ) )

function not( a, b ) {
  return a.filter( ( value ) => b.indexOf( value ) === -1 )
}

function intersection( a, b ) {
  return a.filter( ( value ) => b.indexOf( value ) !== -1 )
}

function union( a, b ) {
  return [ ...a, ...not( b, a ) ]
}

const GestionTransferList = ( props ) => {
  const { setSedeAssings, idUser } = props
  const [ checked, setChecked ] = useState( [] )
  const [ left, setLeft ] = useState( [] )
  const [ right, setRight ] = useState( [] )
  const leftChecked = intersection( checked, left )
  const rightChecked = intersection( checked, right )
  const [ allSedes, setAllSedes ] = useState( [] )
  const [ loading, setLoading ] = useState( false )

  useEffect( () => {
    if ( idUser && allSedes.length > 0 ) {
      getMySedes( idUser, allSedes )
    }
    // eslint-disable-next-line
  }, [ idUser, allSedes ] )

  useEffect( () => {
    getSedes()
  }, [] )

  const getSedes = async () => {
    try {
      let res = await getHeadquarters() // infoLeft
      setAllSedes( res.data )
    } catch ( error ) {
      notification[ "error" ]( {
        message: `${ error }`,
        description: "Ocurrió un error al obtener las sedes.",
      } )
    }
  }

  const getMySedes = async ( user, sedes ) => {
    setLoading( true )
    try {
      let mysedes = await getHeadquartersByUserAndCompany(
        user,
        sedeactual.company()._id
      ) // infoRight
      let resultAllSedes = sedes?.filter(
        ( mySede ) => !mysedes?.some( ( sedeL ) => mySede._id === sedeL._id )
      )
      setLeft( resultAllSedes )
      setRight( mysedes )
      setSedeAssings( mysedes )
    } catch ( error ) {
      notification[ "error" ]( {
        message: `${ error }`,
        description: "Ocurrió un error al obtener las sedes del usuario.",
      } )
    } finally {
      setLoading( false )
    }
  }

  const handleCheckedRight = () => {
    setRight( right.concat( leftChecked ) )
    setSedeAssings( right.concat( leftChecked ) )
    setLeft( not( left, leftChecked ) )
    setChecked( not( checked, leftChecked ) )
  }

  const handleCheckedLeft = () => {
    setLeft( left.concat( rightChecked ) )
    setRight( not( right, rightChecked ) )
    setSedeAssings( not( right, rightChecked ) )
    setChecked( not( checked, rightChecked ) )
  }

  return (
    <React.Fragment>
      { loading && <LoaderBackdrop /> }
      <Grid container spacing={ 2 } justify="center" alignItems="center">
        <CustomList
          title="SEDES"
          items={ left }
          checked={ checked }
          setChecked={ setChecked }
        />
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant={ leftChecked.length === 0 ? "outlined" : "contained" }
              color="primary"
              onClick={ handleCheckedRight }
              disabled={ leftChecked.length === 0 }
              aria-label="move selected right"
            >
              &gt;
            </Button>
            &nbsp;
            <Button
              variant={ rightChecked.length === 0 ? "outlined" : "contained" }
              color="primary"
              onClick={ handleCheckedLeft }
              disabled={ rightChecked.length === 0 }
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <CustomList
            title="MIS SEDES"
            items={ right }
            checked={ checked }
            setChecked={ setChecked }
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
export default GestionTransferList

const CustomList = ( { title, items, checked, setChecked } ) => {
  const classes = useStyles()
  const [ filtered, setFiltered ] = useState( [] )

  const handleToggle = ( value ) => () => {
    const currentIndex = checked.indexOf( value )
    const newChecked = [ ...checked ]
    if ( currentIndex === -1 ) {
      newChecked.push( value )
    } else {
      newChecked.splice( currentIndex, 1 )
    }
    setChecked( newChecked )
  }

  const numberOfChecked = ( items ) => intersection( checked, items ).length

  const handleToggleAll = ( items ) => () => {
    if ( numberOfChecked( items ) === items.length ) {
      setChecked( not( checked, items ) )
    } else {
      setChecked( union( checked, items ) )
    }
  }

  useEffect( () => {
    setFiltered( items )
  }, [ items ] )

  return (
    <Card>
      <CardHeader
        avatar={
          <Checkbox
            onClick={ handleToggleAll( items ) }
            checked={
              numberOfChecked( items ) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked( items ) !== items.length &&
              numberOfChecked( items ) !== 0
            }
            disabled={ items.length === 0 }
          />
        }
        title={
          <Typography variant="h4" color="textSecondary">
            <b>{ title }</b>
          </Typography>
        }
        subheader={ `${ numberOfChecked( items ) }/${ items.length } seleccionados` }
      />
      <Divider />
      &nbsp;
      <Grid container justify="center">
        <Grid item xs={ 10 }>
          <TextField
            fullWidth
            label="BUSCADOR"
            variant='outlined'
            size='small'
            onChange={ ( e ) => {
              if ( e.target.value ) {

                const temp = items.filter( ( ev ) => ev.description.toLowerCase().includes( e.target.value.toLowerCase() ) )
                setFiltered( temp )
              } else {
                setFiltered( items )
              }
            } }
          />
        </Grid>
      </Grid>
      <List dense className={ classes.content }>
        { filtered.map( ( value ) => (
          <ListItem button onClick={ handleToggle( value ) } divider>
            <ListItemIcon>
              <Checkbox
                checked={ checked.indexOf( value ) !== -1 }
                tabIndex={ -1 }
                disableRipple
              />
            </ListItemIcon>
            <ListItemText
              primary={ `${ value.work_mode_id === 1 ? "COMUNIDAD" : "OBRAS" }` }
              secondary={
                <FormHelperText>
                  { value.description.toUpperCase() }
                </FormHelperText>
              }
            />
          </ListItem>
        ) ) }
      </List>
    </Card>
  )
}
