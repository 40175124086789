import React from "react"
import {
  Grid,
  Typography,
  DialogActions,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  Toolbar,
} from "@material-ui/core"
import moment from "moment"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import { makeStyles } from "@material-ui/styles"
import napformat from "../../../../../config/napformat"

const useStyles = makeStyles( {
  table: { background: "#fff" },
  subtitle: { justifyContent: "center" },
  imagen: {
    border: "5px solid #eee",
    maxWidth: 250,
    maxHeight: "100%",
    borderRadius: 5,
    marginBottom: 5,
  },
} )

const ModalDetailsTerreno = ( props ) => {
  const classes = useStyles()
  const { visible, setVisible, dataTerreno } = props

  return (
    <Dialog fullScreen open={ visible } onClose={ () => setVisible( false ) }>
      <DialogTitle>
        <Grid container>
          <Grid item xs={ 1 }>
          </Grid>
          <Grid item xs={ 10 }>
            <Typography
              variant="h2"
              component="h2"
              color="textSecondary"
              align="center"
            >
              DETALLES
            </Typography>
          </Grid>
          <Grid item xs={ 1 }>
            <Button
              color="secondary"
              variant="contained"
              onClick={ () => setVisible( false ) }
            >
              Cerrar
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={ 1 }>
          <Grid item xs={ 12 }>
            <Grid container spacing={ 1 }>
              <Grid item xs={ 12 } md={ 6 }>
                <TableContainer>
                  <Toolbar className={ classes.subtitle }>
                    <Typography
                      variant="h4"
                      color="textSecondary"
                      gutterBottom
                      align="center"
                    >
                      Información General
                    </Typography>
                  </Toolbar>
                  <Table
                    className={ classes.table }
                    aria-label="simple table"
                    size="small"
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          Denominación del Predio:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataTerreno.property_name
                            ? dataTerreno.property_name
                            : "-" }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          Dirección:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataTerreno.address ? dataTerreno.address : "-" }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          N° Partida Electrónica:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataTerreno.departure_number
                            ? dataTerreno.departure_number
                            : "-" }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          Fecha de Adquisición:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataTerreno.acquisition_date
                            ? moment( dataTerreno.acquisition_date ).format(
                              "DD/MM/YYYY"
                            )
                            : "-" }
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                <TableContainer>
                  <Toolbar className={ classes.subtitle }>
                    <Typography
                      variant="h4"
                      color="textSecondary"
                      gutterBottom
                      align="center"
                    >
                      Valor de Terreno
                    </Typography>
                  </Toolbar>
                  <Table
                    className={ classes.table }
                    aria-label="simple table"
                    size="small"
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          Total Valor Compra:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataTerreno.acquisition_value ? (
                            <>S/{ napformat( dataTerreno.acquisition_value ) }</>
                          ) : (
                            " - "
                          ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          Valor de Autovaluo Municipalidad Terreno:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataTerreno.muni_autovalue_ground ? (
                            <>
                              S/{ napformat( dataTerreno.muni_autovalue_ground ) }
                            </>
                          ) : (
                            " - "
                          ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell scope="row" rowSpan={ 2 }>
                          Observaciones:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataTerreno.observations
                            ? dataTerreno.observations
                            : "-" }
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <Grid container spacing={ 1 }>
              <Grid item xs={ 12 }>
                <TableContainer>
                  <Toolbar className={ classes.subtitle }>
                    <Typography
                      variant="h4"
                      color="textSecondary"
                      gutterBottom
                      align="center"
                    >
                      Área y perimento del Terreno
                    </Typography>
                  </Toolbar>
                  <Table
                    className={ classes.table }
                    aria-label="simple table"
                    size="small"
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          Área:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataTerreno.area ? (
                            <>
                              { napformat( dataTerreno.area ) }mt<sup>2</sup>
                            </>
                          ) : (
                            " - "
                          ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          Lado A:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataTerreno.side_a ? (
                            <>{ napformat( dataTerreno.side_a ) }mt</>
                          ) : (
                            " - "
                          ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          Lado B:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataTerreno.side_a ? (
                            <>{ napformat( dataTerreno.side_b ) }mt</>
                          ) : (
                            " - "
                          ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          Lado C:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataTerreno.side_a ? (
                            <>{ napformat( dataTerreno.side_c ) }mt</>
                          ) : (
                            " - "
                          ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          Lado D:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataTerreno.side_a ? (
                            <>{ napformat( dataTerreno.side_d ) }mt</>
                          ) : (
                            " - "
                          ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          Otro Lado:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataTerreno.side_another ? (
                            <>{ napformat( dataTerreno.side_a ) }mt</>
                          ) : (
                            " - "
                          ) }
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            { dataTerreno.images.length > 0 ? (
              <Grid
                container
                direction="row-reverse"
                justify="space-around"
                alignItems="center"
              >
                { dataTerreno.images.map( ( ev ) => (
                  <a href={ ev } target="_blank">
                    <img
                      src={ ev }
                      alt="Foto del terreno"
                      className={ classes.imagen }
                    />
                  </a>
                ) ) }
              </Grid>
            ) : (
              <div style={ { textAlign: "center" } }>
                <Typography variant="p" color="textSecondary">
                  <br />
                  <br />
                  No se adjuntaron imagenes de la edificación.
                </Typography>
              </div>
            ) }
          </Grid>
          <Grid item xs={ 12 }>
            <Grid container spacing={ 1 }>
              { dataTerreno.list_docs &&
                dataTerreno.list_docs.length > 0 &&
                dataTerreno.list_docs.map( ( ev, index ) => (
                  <Grid item xs={ 12 } md={ 6 }>
                    <embed
                      src={ ev }
                      type="application/pdf"
                      width="100%"
                      height="400px"
                    />
                  </Grid>
                ) ) }
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ModalDetailsTerreno
