import React from "react";
import {
  makeStyles,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
} from "@material-ui/core";
import napformat from "../../../config/napformat";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  divider: {
    fontWeight: "bold",
    backgroundColor: "#01579b30",
    border: "1px solid #E8E8E8",
  },
  th: {
    border: "1px solid #E8E8E8",
  },
  total: {
    fontWeight: "bold",
    backgroundColor: "#01579b",
  },
  boldTotal: {
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  bold: {
    border: "1px solid #E8E8E8",
    fontWeight: "bold",
  },
  card: {
    padding: 20,
  },
}));

const TablaPDetalle = (props) => {
  const classes = useStyles();
  const { dataTable } = props;

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.th}>CÓDIGO</TableCell>
                  <TableCell className={classes.th}>DESCRIPCIÓN</TableCell>
                  <TableCell className={classes.th}>MONTO PARCIAL</TableCell>
                  <TableCell className={classes.th}>SUBTOTAL</TableCell>
                  <TableCell className={classes.th}>TOTAL</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataTable.length > 0 &&
                  dataTable.map((row, index) => (
                    <>
                      {row.total && (
                        <TableRow className={classes.divider}>
                          <TableCell className={classes.bold}>
                            {row.code}
                          </TableCell>
                          <TableCell className={classes.bold}>
                            {row.description.toUpperCase()}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.bold}
                            colSpan={2}
                          ></TableCell>
                          <TableCell align="right" className={classes.bold}>
                            <sup>S/</sup>{napformat(row.total)}
                          </TableCell>
                        </TableRow>
                      )}
                      {row.code.toString().length === 3 && (
                        <TableRow>
                          <TableCell align="right" className={classes.bold}>
                            {row.code}
                          </TableCell>
                          <TableCell className={classes.bold}>
                            {row.description.toUpperCase()}
                          </TableCell>
                          <TableCell align="right" className={classes.bold}>
                            <sup>S/</sup>{napformat(row.montoParcial)}
                          </TableCell>
                          <TableCell align="right" className={classes.bold}>
                            <sup>S/</sup>{napformat(row.subtotal)}
                          </TableCell>
                          <TableCell align="right" className={classes.bold}>
                          </TableCell>
                        </TableRow>
                      )}
                      {row.code.toString().length > 3 && (
                        <TableRow>
                          <TableCell className={classes.th} align="right">
                            {row.code}
                          </TableCell>
                          <TableCell className={classes.th}>
                            {row.description.toUpperCase()}
                          </TableCell>
                          <TableCell className={classes.th} align="right">
                            <sup>S/</sup>{napformat(row.partialAmount)}
                          </TableCell>
                          <TableCell className={classes.th} align="right">
                          </TableCell>
                          <TableCell className={classes.th} align="right">
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default TablaPDetalle;
