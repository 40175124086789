import FormVoucher from "../CreateVoucher/FormVoucher";
import React, { useEffect, useState } from "react";
import { notification, Icon } from "antd";

import { getVoucher, updateVoucher } from "../../../api/api.contable";

const EditarVoucher = (props) => {
  const [loading, setloading] = useState(false);
  const [datavoucher, setdatavoucher] = useState([]);
  const [forceupdate, setforceupdate] = useState();
  useEffect(() => {
    getDatax();
  }, [forceupdate]);

  async function sendData(voucher) {
    const res = await updateVoucher(voucher, props.match.params.id);

    if (res.status == 200) {
      notification.open({
        message: "Editar Voucher",
        description: "El Voucher fue editado satisfactoriamente.",
        icon: <Icon type="check" style={{ color: "#49E02D" }} />,
        key: `open${Date.now()}`,
      });
    } else {
      notification.open({
        message: "Editar Voucher",
        description: "Ocurrio un error al editar el Voucher.",
        icon: <Icon type="close" style={{ color: "#FE2E27" }} />,
        key: `open${Date.now()}`,
      });
    }

    setforceupdate({ ...true });
  }

  async function getDatax() {
    const res = await getVoucher(props.match.params.id);
    setdatavoucher(res.data);
    setloading(true);
  }

  return (
    loading && (
      <FormVoucher
        id={props.match.params.id}
        data={datavoucher}
        edit={true}
        sendFormData={(e) => sendData(e)}
      />
    )
  );
};

export default EditarVoucher;
