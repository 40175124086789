import React, { useState } from "react";
import CustomTable from "src/components/CustomTable";
import Page from "src/components/Page";
import sedeactual from "src/config/sedeactual";
import SelectSedes from "src/components/Usuarios/SelectSedes.jsx";
import { Button } from "@material-ui/core";
import { notification, Spin } from "antd";
import { getHeadquarters } from "src/api/api.contable";
import { useEffect } from "react";
import { apiReportes } from "src/config/api.contable.js";
import Exportar from "src/components/Global/ExportarExcel";
import moment from "moment";
import napformat from "src/config/napformat";

let ranges = [
  { value: "1", name: "Enero" },
  { value: "2", name: "Febrero" },
  { value: "3", name: "Marzo" },
  { value: "4", name: "Abril" },
  { value: "5", name: "Mayo" },
  { value: "6", name: "Junio" },
  { value: "7", name: "Julio" },
  { value: "8", name: "Agosto" },
  { value: "9", name: "Setiembre" },
  { value: "10", name: "Octubre" },
  { value: "11", name: "Noviembre" },
  { value: "12", name: "Diciembre" },
];

const ReporteCompras = () => {
  const [sede, setsede] = useState(sedeactual.get());
  const [kind, setkind] = useState("mensual");
  const [desde, setdesde] = useState("1");
  const [hasta, sethasta] = useState("2");

  const [mes, setMes] = useState(new Date().getMonth() + 1);
  const [sedes, setSedes] = useState([]);

  const [data, setData] = useState([]);

  const [loading, setloading] = useState(false);

  useEffect(() => {
    getSedes();
  }, []);

  const getSedes = async () => {
    try {
      let result = await getHeadquarters();
      setSedes(result.data);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se logró obtener las sedes.",
      });
    }
  };

  const columns = [
    {
      title: "N° VOUCHER",
      dataIndex: "n_voucher",
      key: "voucher",
      align: "center",
      width: 100,
    },
    {
      title: "FECHA",
      dataIndex: "date",
      key: "fecha",
      align: "center",
      width: 100,
    },
    {
      title: "TIPO DE DOCUMENTO",
      dataIndex: "t_doc",
      key: "tipoDocumento",
      align: "center",
      width: 120,
    },
    {
      title: "SERIE",
      dataIndex: "serie",
      key: "serie",
      align: "center",
      width: 80,
    },
    {
      title: "N° DOCUMENTO",
      dataIndex: "correlative",
      key: "numeroDocumento",
      align: "center",
      width: 120,
    },
    {
      title: "RUC",
      dataIndex: "ruc",
      key: "ruc",
      align: "center",
      width: 110,
    },
    {
      title: "RAZÓN SOCIAL",
      dataIndex: "razonSocial",
      key: "razonSocial",
      width: 200,
    },
    {
      title: "CONCEPTO",
      dataIndex: "concepto",
      key: "concepto",
      width: 200,
    },
    {
      title: "BASE IMPONIBLE",
      dataIndex: "baseImponible",
      key: "baseImponible",
      align: "right",
      width: 110,
      render: (text) => napformat(text),
    },
    {
      title: "IGV",
      dataIndex: "igv",
      key: "igv",
      align: "right",
      width: 110,
      render: (text) => napformat(text),
    },
    {
      title: "COMPRAS EXONERADAS",
      dataIndex: "ventasExoneradas",
      key: "ventasExoneradas",
      align: "right",
      width: 115,
      render: (text) => napformat(text),
    },
    {
      title: "COMPRAS IMPORTACIÓN",
      dataIndex: "ventasImportacion",
      key: "ventasImportacion",
      align: "right",
      width: 120,
      render: (text) => napformat(text),
    },
    {
      title: "COMPRAS INAFECTAS",
      dataIndex: "ventasInafectas",
      key: "ventasInafectas",
      align: "right",
      width: 115,
      render: (text) => napformat(text),
    },
    {
      title: "PERCEPCION",
      dataIndex: "percepcion",
      key: "percepcion",
      align: "right",
      width: 110,
      render: (text) => napformat(text),
    },
    {
      title: "TOTAL",
      dataIndex: "total",
      key: "total",
      align: "right",
      width: 110,
      render: (text) => napformat(text),
    },
  ];

  const getReport = async () => {
    try {
      setloading(true);

      let obj = {
        sede: sede,
        token: sedeactual.token(),
        mes: mes,
        kind: kind,
      };

      if (kind === "intervalos") {
        obj.desde = Number(desde);
        obj.hasta = Number(hasta);
      }

      let response = await fetch(
        `${apiReportes}/shopping-record/${sede}/${sedeactual.company().url}/${sedeactual.getAnio()}`,
        {
          method: "POST",
          body: JSON.stringify(obj),
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        }
      );

      let data = await response.json();

      if (response.status === 200) setData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  return (
    <Page
      helper="REPORTES"
      title="REPORTE DE COMPRAS"
      itemComponent={
        <>
          <Button variant="contained" color="primary" onClick={() => window.history.back()}>
            VOLVER
          </Button>
          &nbsp; &nbsp;
          <Exportar
            name={`REPORTE_COMPRAS_${moment().format("DD-MM-YYYY")}`}
            endpoint={`shopping-record/export/${sede}/${sedeactual.company().url}/${sedeactual.getAnio()}`}
            body={{
              sede: sede,
              token: sedeactual.token(),
              mes: mes,
              kind: kind,
              desde: kind === "intervalos" ? Number(desde) : null,
              hasta: kind === "intervalos" ? Number(hasta) : null,
            }}
            method="POST"
            disabled={data.length === 0}
            small={false}
            api={4}
          />
        </>
      }
    >
      <Spin spinning={loading}>
        <div className="row">
          {(sedeactual.getRol() === "supernap" || sedeactual.getRol() === "superadmin") && (
            <div className="col-lg-4">
              <div class="form-group">
                <label for="exampleSelect1">Seleccionar Sede</label>

                <SelectSedes
                  defaultValue={sede}
                  data={sedes}
                  onChangeSelect={(e) => {
                    setsede(e);
                  }}
                ></SelectSedes>
              </div>
            </div>
          )}
          <div className="col-lg-2">
            <label>Modo</label>
            <select
              value={kind}
              onChange={(e) => {
                setkind(e.target.value);
              }}
              className="form-control"
              name="kind"
            >
              <option value="mensual">Mensual</option>
              <option value="intervalos">Intervalos</option>
              <option value="anual">Anual</option>
            </select>
          </div>
          {kind == "mensual" && (
            <div className="col-lg-2">
              <label>Mes</label>
              <select
                value={mes}
                onChange={(e) => {
                  setMes(e.target.value);
                }}
                className="form-control"
                name="mes"
              >
                {" "}
                <option value="1">Enero</option>
                <option value="2">Febrero</option>
                <option value="3">Marzo</option>
                <option value="4">Abril</option>
                <option value="5">Mayo</option>
                <option value="6">Junio</option>
                <option value="7">Julio</option>
                <option value="8">Agosto</option>
                <option value="9">Setiembre</option>
                <option value="10">Octubre</option>
                <option value="11">Noviembre</option>
                <option value="12">Diciembre</option>
              </select>
            </div>
          )}
          {kind == "intervalos" && (
            <>
              {" "}
              <div className="col-lg-2">
                <label>Desde:</label>
                <select
                  value={desde}
                  onChange={(e) => {
                    setdesde(e.target.value);
                  }}
                  className="form-control"
                  name="mes"
                >
                  {ranges.map((e) => {
                    if (e.value !== "12") {
                      return <option value={e.value}>{e.name}</option>;
                    }
                  })}
                </select>
              </div>
              <div className="col-lg-2">
                <label>Hasta</label>
                <select
                  value={hasta}
                  onChange={(e) => {
                    sethasta(e.target.value);
                  }}
                  className="form-control"
                  name="mes"
                >
                  {ranges.map((e) => {
                    if (e.value !== "1") {
                      return <option value={e.value}>{e.name}</option>;
                    }
                  })}
                </select>
              </div>
            </>
          )}

          <div
            className="col-lg-2"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button variant="contained" color="primary" fullWidth onClick={getReport}>
              BUSCAR
            </Button>
          </div>
        </div>

        <CustomTable columns={columns} data={data} pagination={false} />
      </Spin>
    </Page>
  );
};

export default ReporteCompras;
