/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Table, Input, Button, Icon } from "antd";
import numeral from "numeral";
import Highlighter from "react-highlight-words";

import { Link } from "react-router-dom";
import sedeactual from "../../../config/sedeactual";

//import Buttons
import BtnDelete from "../../../components/Global/components/Botones/BtnDelete";
import BtnView from "../../../components/Global/components/Botones/BtnView";
import BtnEdit from "../../../components/Global/components/Botones/BtnEdit";
import { getVouchersPeriod } from "../../../api/api.contable";
import moment from "moment";

const TableVerVoucher = ({ sede, period, year, origins, actualizar, showModal }) => {
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");
  const [vouchers, setvouchers] = useState([]);
  const [loading, setLoading] = useState(false);

  const getVouchers = async () => {
    setLoading(true);
    let vouchers = await getVouchersPeriod(sede, year, period);

    let data = [];
    vouchers.forEach((e) => {
      const typeProv = e.type_provisioning;
      let value = 0;

      e.details.forEach((item) => {
        if (typeProv && typeProv === item.type_detail) {
          value += item.amount;
        }
      });

      let amount = 0;
      e.details.map((f) => {
        amount += parseFloat(f.amount * f.exchange_rate);
      });
      let foundOrigin = origins.find((f) => f.id == e.origin);

      amount = typeProv ? (typeProv === e.type_detail ? amount / 2 : value) : amount / 2;

      data.push({
        _id: e._id,
        n_voucher: e.n_voucher,
        numero: e.voucher_number,
        age: e.cancellation_date,
        origin: foundOrigin?.description,
        serie: e.serie,
        operation_date: moment(e.operation_date).format("DD/MM/YYYY"),
        correlative: e.correlative,
        ruc: e.customer ? (typeof e.customer === "object" ? e.customer.ruc : e.ruc) : "-",
        comment: e.details[0]?.comment?.toUpperCase(),
        amount: `S/ ${numeral(amount).format("0,0.00")}`,
      });
    });
    setvouchers(data);
    setLoading(false);
  };

  useEffect(() => {
    getVouchers();
  }, [actualizar, sede]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onKeyPress={(e) => e.key === "Enter" && handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Limpiar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setsearchText(selectedKeys[0]);
    setsearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setsearchText("");
  }

  let columns = [
    {
      title: "Número",
      dataIndex: "n_voucher",
      key: "n_voucher",
      width: 100,
      ...getColumnSearchProps("n_voucher"),
    },
    {
      title: "Fec. Operación",
      dataIndex: "operation_date",
      key: "operation_date",
      width: 150,
      ...getColumnSearchProps("operation_date"),
    },
    {
      title: "Origen",
      dataIndex: "origin",
      key: "origen",
      width: 100,
      ...getColumnSearchProps("origin"),
    },
    {
      title: "Serie",
      dataIndex: "serie",
      key: "serie",
      width: 100,
      ...getColumnSearchProps("serie"),
    },
    {
      title: "Correlativo",
      dataIndex: "correlative",
      key: "correlative",
      width: 125,
      ...getColumnSearchProps("correlative"),
    },
    {
      title: "Ruc",
      dataIndex: "ruc",
      key: "ruc",
      width: 100,
      ...getColumnSearchProps("ruc"),
      render: (text) =>
        text ? (text !== "-" ? (text.toString().length > 10 ? text : `DNI ${text}`) : "-") : "-",
    },
    {
      title: "Concepto",
      dataIndex: "comment",
      key: "comment",
      width: 150,
      ...getColumnSearchProps("comment"),
    },
    {
      title: "Monto",
      dataIndex: "amount",
      key: "amount",
      width: 100,
      ...getColumnSearchProps("amount"),
    },
    {
      title: "Acciones",
      dataIndex: "_id",
      key: "key",
      width: 190,
      render: (text) =>
        sedeactual.validar(sede) && (
          <div>
            <Link
              to={{
                pathname: `/ver-voucher/${text}`,
              }}
            >
              <BtnView small={true} />
            </Link>
            {sedeactual.getRol() !== "usuario" && (
              <>
                <Link
                  to={{
                    pathname: `/edit-voucher/${text}`,
                  }}
                >
                  <BtnEdit small={true} />
                </Link>
                <span onClick={() => showModal(text)}>
                  <BtnDelete small={true}></BtnDelete>{" "}
                </span>
              </>
            )}
          </div>
        ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        loading={loading}
        bordered
        dataSource={vouchers}
        pagination={{ pageSize: 20 }}
        scroll={{ x: "100%" }}
      />
    </>
  );
};

export default TableVerVoucher;
