import React, { useEffect, useState } from "react";
import Page from "src/components/Page";
import {  getCovidByDates } from "src/utils/request.covid";
import { notification } from "antd";
// import TableCovid from "./components/TableCovid";
import TableCovidDetails from "./components/TableCovidDetails";
import SelectRangeTime from "./components/SelectRangeTime";
import ExportarExcel from "src/components/Global/ExportarExcel";
import moment from "moment";
import sedeactual from "src/config/sedeactual";

const Sintomatologia = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [type, setType] = useState("DIARIO");
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [date1, setDate1] = useState(moment().format("YYYY-MM-DD"));

  useEffect(() => {
    obtainData();
  }, []);

  useEffect(() => {
    if (type === "DIARIO") {
      obtainData();
    } else if (type === "RANGO DE FECHAS") {
      if (date !== date1) obtainData();
    }
  }, [date, date1]);

  const obtainData = async () => {
    setLoading(true);
    try {
      // const res = await getCovid()
      const res = await getCovidByDates({
        type,
        date,
        date1,
      });
      setData(res);
    } catch (error) {
      notification["error"]({
        message: `Ocurrió un error al realizar la operación`,
      });
    } finally {
      setLoading(false);
    }
  };
  const types = ["DIARIO", "RANGO DE FECHAS"];

  return (
    <Page
      helper="FICHAS DE SINTOMATOLOGÍA COVID-19"
      title="DECLARACIONES JURADAS"
      itemComponent={
        <ExportarExcel
          name="REPORTE FORMULARIO COVID"
          endpoint={`form-covid/export/date/${sedeactual.sede_id()}`}
          body={{
            type,
            date,
            date1,
          }}
          method="POST"
          disabled={
            (type === "RANGO DE FECHAS" && date === date1) || loading
              ? true
              : false
          }
          small={false}
          api={1}
        />
      }
    >
      <br />
      <SelectRangeTime
        types={types}
        type={type}
        setType={setType}
        date={date}
        setDate={setDate}
        date1={date1}
        setDate1={setDate1}
      />
      <br />
      <TableCovidDetails data={data} loading={loading} />
    </Page>
  );
};

export default Sintomatologia;
