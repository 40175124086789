import React from "react";
import numeral from "numeral";
import { Card } from "antd";
import { Typography } from "@material-ui/core";
const CarSaldo = (props) => {
  const {
    data: { saldoAnterior, ingresos, gastos },
  } = props;
  let atl = saldoAnterior + ingresos - gastos;
  return (
    <>
      <Card bordered={true}>
        <Typography
          component="p"
          variant="body1"
          color={saldoAnterior >= 0 ? "textSecondary" : "error"}
        >
          <b> Saldo anterior: </b> S/{numeral(saldoAnterior).format("0,0.00")}
        </Typography>
        <Typography component="p" variant="body1" color="textSecondary">
          <b> Ingresos: </b> S/{numeral(ingresos).format("0,0.00")}
          {" | "}
          <b> Gastos: </b> S/{numeral(gastos).format("0,0.00")}
        </Typography>
        <br />
        <Typography
          component="p"
          variant="body1"
          color={atl >= 0 ? "textSecondary" : "error"}
        >
          <b>Saldo Actual:</b> S/{numeral(atl).format("0,0.00")}
        </Typography>
      </Card>
    </>
  );
};

export default CarSaldo;
