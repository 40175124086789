import React, { useState, useEffect } from "react";
import { notification, Select } from "antd";
import { getCostFormula } from "../../api/api.contable";
import sedeactual from "../../config/sedeactual";

const SelectCostFormula = (props) => {
  const { onChangeSelect, defaultValue = null } = props;
  const [dataCostFormula, setDataCostFormula] = useState([]);
  const [cuentaSelect, setCuentaSelect] = useState(null);

  const onChange = (number) => {
    setCuentaSelect(number);
    onChangeSelect(number);
  };

  useEffect(() => {
    setCuentaSelect(defaultValue);
  }, [defaultValue]);

  const getCosts = async () => {
    try {
      let res = await getCostFormula(sedeactual.getAnio(), sedeactual.get());
      setDataCostFormula(res);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se logró obtener las formulas de costos.",
      });
    }
  };

  useEffect(() => {
    getCosts();
  }, []);

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const filterOptions = (inputValue, option) => {
    const title = removeAccents(option.props.title.toLowerCase());
    return title.includes(inputValue.toLowerCase());
  };

  const { Option } = Select;

  return (
    <Select
      size="large"
      style={{ width: "100%" }}
      showSearch
      value={cuentaSelect}
      onChange={onChange}
      filterOption={filterOptions}
    >
      <Option key={0} value={null} title={""}>
        - Ninguno -
      </Option>
      {dataCostFormula.map((i) => (
        <Option key={i._id} value={i.id} title={i.description}>
          {i.id} - {i.description}
        </Option>
      ))}
    </Select>
  );
};
export default SelectCostFormula;
