import React, { useState } from "react";
import { Table } from "antd";
import {
  Button,
  TextField,
} from "@material-ui/core";
import Highlighter from "react-highlight-words";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import SearchOutlined from "@material-ui/icons/SearchOutlined";
import moment from "moment";

const TableTransferencias = (props) => {
  const { dataTable } = props;
  const [searchedColumn, setsearchedColumn] = useState("");
  const [searchText, setsearchText] = useState("");

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <TextField
          variant="outlined"
          placeholder="Buscar..."
          size="small"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onKeyPress={(e) => e.key === "Enter" && handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={() =>
            handleReset(clearFilters, selectedKeys, setSelectedKeys)
          }
          style={{ width: 90 }}
        >
          Limpiar <ClearIcon />
        </Button>{" "}
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
        >
          Buscar <SearchIcon />
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "" : "" }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    setsearchText(selectedKeys[0]);
    setsearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, selectedKeys, setSelectedKeys) => {
    clearFilters();
    setsearchText("");
    setSelectedKeys([""]);
  };

  const columns = [
    {
      title: "ORIGEN",
      dataIndex: "_id",
      key: "origen",
      width: 175,
      align: "center",
      render: (text, record, index) => <span>{record.origin.name}</span>,
    },
    {
      title: "DESTINO",
      dataIndex: "_id",
      key: "destino",
      width: 175,
      align: "center",
      render: (text, record, index) => <span>{record.destination.name}</span>,
    },
    {
      title: "DESCRIPCIÓN",
      dataIndex: "description",
      key: "description",
      width: 200,
      align: "center",
      /* ...getColumnSearchProps("description"),
      sorter: (a, b) => {
        if (a.description > b.description) return 1;
        if (a.description < b.description) return -1;
      }, */
    },
    {
      title: "FECHA DE TRANSFERENCIA",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: 180,
      /* ...getColumnSearchProps("createdAt"), */
      render: (text, record, index) => moment(text).format("DD/MM/YYYY, HH:mm"),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataTable}
      bordered
      size="small"
      scroll={{ x: "100%" }}
    />
  );
};

export default TableTransferencias;
