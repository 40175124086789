/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Input, Icon, notification } from "antd";
import { Avatar, Chip, ButtonGroup, Button, FormHelperText, Tooltip } from "@material-ui/core";
import ContactMailOutlinedIcon from "@material-ui/icons/ContactMailOutlined";
import sedeactuall from "../../config/sedeactual";
import ButtonUI from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Highlighter from "react-highlight-words";
import SelectSedesNew from "../Global/SelectSedesNew";
import {
  getUsersByHeadquarter,
  getHeadquartersFilter,
  getAllCompanies,
  recoveryPasswordByEmail,
} from "../../api/api.contable";
import ModalConfirm from "../ModalConfirm";
import Page from "../Page";
import SettingsOverscanIcon from "@material-ui/icons/SettingsOverscan";
import { getModules } from "../../api/api.contable";
import AsignModulesModal from "../Global/AsignModulesModal";
import sedeactual from "../../config/sedeactual";
import SelectCompania from "../Selects/SelectCompania";
import Edit from "@material-ui/icons/Edit";
import ModalUser from "./ModalUser";
import { PersonAddDisabled } from "@material-ui/icons";
import ModalConfirmDelete from "src/components/ModalConfirmDelete";
import { apiContable } from "src/config/api.contable";

const TablaUsuarios = () => {
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");
  const [sede, setSede] = useState(null);
  const [data, setData] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [idUser, setIdUser] = useState("");
  const [nameUser, setNameUser] = useState("");
  const [visibleModal, setVisibleModal] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [modules, setModules] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(sedeactual.company()._id);
  const [urlCompany, setUrlCompany] = useState(sedeactual.company().url);
  const [edit, setEdit] = useState({ open: false, data: null });
  const [disable, setDisable] = useState({ open: false, id: "" });
  const [asignarModulos, setAsignarModulos] = useState({
    data: null,
    open: false,
  });

  const getSedes = async (url = urlCompany) => {
    try {
      let res = await getHeadquartersFilter(url);
      setSedes(res.data);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error.",
        description: `No se pudo obtener la información. ${error}`,
      });
    }
  };

  useEffect(() => {
    obtainCompanies();
    getSedes();
  }, []);

  const obtainCompanies = async () => {
    try {
      const res = await getAllCompanies();
      setCompanies(res);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al obtener los módulos.",
        description: `${error}`,
      });
    }
  };

  useEffect(() => {
    if (company && companies.length > 0) {
      let filter = companies.find((ev) => ev._id === company);
      if (filter) {
        getSedes(filter.url);
        setUrlCompany(filter.url);
      }
    }
  }, [company]);

  useEffect(() => {
    setModules([]);
    if (sede) {
      let findSede = sedes.find((ev) => ev._id === sede);
      if (findSede) {
        obtainModules(findSede.id);
      }
      obtainUsers();
    }
  }, [sede]);

  const obtainModules = async (code) => {
    try {
      let result = await getModules(`modules/${urlCompany}/${code}`);
      setModules(result);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al obtener los módulos.",
        description: `${error}`,
      });
    }
  };

  const obtainUsers = async () => {
    try {
      setLoadingTable(true);
      let result = await getUsersByHeadquarter(sede);
      setData(result);
      setLoadingTable(false);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error.",
        description: `No se pudo obtener la información. ${error}`,
      });
    }
  };

  useEffect(() => {
    let findSede = sedes.find((ev) => ev.id == sedeactuall.get());
    if (findSede) {
      setSede(findSede._id);
    }
  }, [sedes]);

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();

    setsearchText(selectedKeys[0]);
    setsearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setsearchText("");
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onKeyPress={(e) => e.key === "Enter" && handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          color="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          variant="contained"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Limpiar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const resendEmail = async (idUser) => {
    try {
      setLoadingModal(true);
      await recoveryPasswordByEmail(idUser);
      notification["success"]({
        message: "Envío de contraseña.",
        description: `Se envió correctamente el email con la contraseña`,
      });
      setTimeout(() => setVisibleModal(false), 1500);
      setLoadingModal(false);
    } catch (error) {
      if (error.response.data.error === "userData does not exist") {
        notification["error"]({
          message: "El usuario incorrecto.",
          description: `El usuario no existe. ${error}`,
        });
      } else {
        notification["error"]({
          message: "Ocurrió un error.",
          description: `No se pudo enviar la contraseña. ${error}`,
        });
      }
      setTimeout(() => setVisibleModal(false), 1500);
      setLoadingModal(false);
    }
  };

  let columns = [
    {
      title: "FOTO",
      dataIndex: "profile_picture",
      key: "profile_picture",
      width: 70,
      render: (text) => <Avatar src={text} alt="profile picture" />,
    },
    {
      title: "N° DOC.",
      dataIndex: "n_doc",
      key: "n_doc",
      width: 100,
      ...getColumnSearchProps("n_doc"),
    },
    {
      title: "NOMBRE",
      dataIndex: "fullname",
      key: "fullname",
      width: 150,
      ...getColumnSearchProps("fullname"),
    },
    {
      title: "DIRECCIÓN",
      dataIndex: "address",
      key: "address",
      width: 125,
      ...getColumnSearchProps("address"),
    },
    {
      title: "USUARIO",
      dataIndex: "user",
      key: "user",
      width: 125,
      ...getColumnSearchProps("user"),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      width: 200,
      ...getColumnSearchProps("email"),
    },
    {
      title: "MOVIL",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      width: 100,
      ...getColumnSearchProps("phone"),
    },
    {
      title: "ESTADO",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 100,
      render: (text) =>
        text ? (
          <Chip label="Activo" color="primary" />
        ) : (
          <Chip label="Inactivo" color="secondary" />
        ),
    },
    {
      title: "ROL",
      dataIndex: "rol",
      key: "rol",
      align: "center",
      width: 110,
    },
    {
      title: "ACCIONES",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      width: 185,
      render: (text, record) => (
        <ButtonGroup color="primary" size="small">
          {(sedeactual.getRol() === "supernap" || sedeactual.getRol() === "superadmin") && (
            <Button
              onClick={() => {
                setVisibleModal(true);
                setIdUser(text);
                setNameUser(record.fullname);
              }}
            >
              <ContactMailOutlinedIcon />
            </Button>
          )}
          <Button
            onClick={() => setAsignarModulos({ data: record, open: true })}
            variant="contained"
          >
            <Tooltip title="Asignar módulos">
              <SettingsOverscanIcon />
            </Tooltip>
          </Button>

          <Button onClick={() => setEdit({ data: record, open: true })}>
            <Tooltip title="Editar usuario">
              <Edit />
            </Tooltip>
          </Button>

          <Button onClick={() => setDisable({ id: text, open: true })} color="secondary">
            <Tooltip title="Deshabilitar">
              <PersonAddDisabled />
            </Tooltip>
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  const content = (
    <Page
      title="USUARIOS"
      helper="USUARIOS"
      itemComponent={
        <Link to={`/createusuario/${company}/${urlCompany}`}>
          <ButtonUI variant="contained" color="primary" type="submit" startIcon={<AddBoxIcon />}>
            Agregar
          </ButtonUI>
        </Link>
      }
    >
      {sedeactual.getRol() === "supernap" && (
        <>
          <FormHelperText>SELECCIONAR COMPAÑIA</FormHelperText>
          <SelectCompania
            defaultValue={company}
            data={companies}
            onChangeSelect={(e) => {
              setCompany(e);
              setSede(null);
            }}
          />
        </>
      )}
      <FormHelperText>SELECCIONAR SEDE</FormHelperText>
      <SelectSedesNew defaultValue={sede} data={sedes} onChangeSelect={(e) => setSede(e)} />
      <br />
      <br />

      <Table
        columns={columns}
        bordered
        dataSource={data}
        scroll={{ x: "100%" }}
        loading={loadingTable}
      />

      {visibleModal && (
        <ModalConfirm
          visible={visibleModal}
          setVisible={setVisibleModal}
          resendEmail={() => resendEmail(idUser)}
          title={`¿Esta seguro(a) que desea enviar E-mail para ${nameUser}?`}
          loading={loadingModal}
        />
      )}
      {asignarModulos.open && (
        <AsignModulesModal
          visible={asignarModulos.open}
          setVisible={setAsignarModulos}
          modulosActivos={asignarModulos.data.modules}
          modules={modules}
          setLoading={setLoadingTable}
          reload={obtainUsers}
          endpoint={`user-modules/${asignarModulos.data._id}`}
          sede={sede}
        />
      )}

      {edit.open && (
        <ModalUser
          open={edit.open}
          setOpen={setEdit}
          data={edit.data}
          loading={loadingTable}
          setLoading={setLoadingTable}
          obtainUsers={obtainUsers}
        />
      )}

      {disable.open && (
        <ModalConfirmDelete
          visible={disable.open}
          setVisible={setDisable}
          reload={obtainUsers}
          endpoint={`${apiContable}/user/disable/${disable.id}`}
          title="¿SEGUDO QUE DESEA DESHABILITAR A ESTE USUARIO?"
          subtitle="Ten un consideración que después este usuarios no tendrá acceso al sistema."
          disable
        />
      )}
    </Page>
  );

  console.log(disable)

  return content;
};

export default TablaUsuarios;
