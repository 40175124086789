import React, { useState } from "react";
import { Select } from "antd";

const SelectSeccionEscolar = ({ setSection, grade, section }) => {
  const { Option } = Select;

  const list = ["A", "B", "C", "D", "E"];

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const renderOptions = () => {
    if (grade) {
      return list.map((x) => (
        <Option key={x} value={x} title={x}>
          {x}&nbsp;&nbsp;&nbsp;
        </Option>
      ));
    }
  };

  const filterOptions = (inputValue, option) => {
    const title = removeAccents(option.props.title.toLowerCase());
    return title.includes(inputValue.toLowerCase());
  };

  return (
    <Select
      size="large"
      style={{ width: "100%" }}
      showSearch
      value={section}
      onChange={(e) => setSection(e)}
      filterOption={filterOptions}
      disabled={grade ? false : true}
    >
      <Option key={0} value={null} title={""}>
        {" "}
        - Ninguno -{" "}
      </Option>
      {renderOptions()}
    </Select>
  );
};
export default SelectSeccionEscolar;
