import React, { Fragment, useEffect, useState } from "react"
import Page from "src/components/Page"
import { getVoucherByHeadquarter } from "src/utils/req.boletas"
import moment from "moment"
import { notification } from "antd"
import TableBoletasByHeadquarter from "./components/TableBoletasByHeadquarter"
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Menu,
  MenuItem,
  Popover,
  Select,
} from "@material-ui/core"
import SelectYear from "src/components/Selects/SelectYear"
import SelectPeriodo from "src/components/Selects/SelectPeriodo"
import sedeactual from "src/config/sedeactual"
import SendNotification from "./components/SendNotification"

const BoletasByHeadquarter = () => {
  const [ loading, setLoading ] = useState( false )
  const [ search, setSearch ] = useState( {
    year: sedeactual.getAnio(),
    month: null,
    mode: "all",
  } )
  const [ base, setBase ] = useState( { data: [], bd: [] } )

  useEffect( () => {
    obtainData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  const handleFilter = ( () => {
    const searched = base.bd.filter( ( e ) =>
      !search.month
        ? moment( e.date_voucher ).year() === search.year
        : moment( e.date_voucher ).year() === search.year &&
        moment( e.date_voucher ).month() + 1 === search.month
    )
    // const searched = base.bd.filter( ( e ) =>
    //   search.mode === "all"
    //     ? moment( e.date_voucher ).year() === search.year
    //     : moment( e.date_voucher ).year() === search.year &&
    //     moment( e.date_voucher ).month() + 1 === search.month
    // )
    setBase( { ...base, data: searched } )
  } )

  useEffect( () => {
    handleFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ search ] )

  const obtainData = async () => {
    setLoading( true )
    try {
      const res = await getVoucherByHeadquarter()
      const result = res.map( ( e ) => ( {
        ...e,
        colaborador: e.worker ? `${ e.worker.f_name } ${ e.worker.l_name }` : "─",
        dateUpload: moment( e.date_upload ).toDate(),
        dateVoucher: moment( e.date_voucher ).toDate(),
      } ) )
        .sort( ( a, b ) => ( a.dateVoucher < b.dateVoucher ? 1 : -1 ) )
        .reverse()

      setBase( {
        data: [ ...result.filter( ( e ) => moment( e.date_voucher ).year() === search.year ) ],
        bd: [ ...result ]
      } )
    } catch ( error ) {
      console.log( 'error', error )
      notification[ "warning" ]( { message: `Ocurrió un error al realizar la operación.` } )
    } finally {
      setLoading( false )
    }
  }

  return (
    <Page
      title="BOLETAS DE REMUNERACIONES"
      helper="COMPROBANTES"
      itemComponent={
        <Grid container direction="row" spacing={ 1 } alignItems="flex-end">
          <Grid item>
            <ButtonSelect data={ base.bd } />
          </Grid>
          {/* <Grid item style={ { width: 175 } }>
            <FormControl variant="outlined" size="small" fullWidth>
              <FormHelperText>MODO</FormHelperText>
              <Select
                value={ search.mode }
                onChange={ ( e ) => setSearch( { ...search, mode: e.target.value } ) }
                label="MODO"
              >
                <MenuItem value="custom">PERSONALIZADO</MenuItem>
                <MenuItem value="all">TODOS</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          {/* {search.mode === "custom" && ( */ }
          <Grid item style={ { width: 150 } }>
            <FormHelperText>SELECCIONE AÑO</FormHelperText>
            <SelectYear year={ search.year } setYear={ ( e ) => setSearch( { ...search, year: e } ) } />
          </Grid>
          {/* )} */ }
          {/* { search.mode === "custom" && ( */ }
          <Grid item style={ { width: 150 } }>
            <FormHelperText>SELECCIONE MES</FormHelperText>
            <SelectPeriodo
              defaultValue={ search.month }
              onChangeSelect={ ( e ) => setSearch( { ...search, month: e } ) }
              allOptions={ true }
            />
          </Grid>
          {/* ) } */ }
        </Grid>
      }
    >
      <TableBoletasByHeadquarter data={ base.data } loading={ loading } />
    </Page>
  )
}

export default BoletasByHeadquarter

const ButtonSelect = ( { data } ) => {
  const [ anchorEl, setAnchorEl ] = useState( null )
  const [ modal, setModal ] = useState( { open: false, data: [], type: "" } )

  const workers = []
  data
    .filter( ( row ) => row.worker )
    .map( ( row ) => ( {
      email: row.worker.email,
      phone: row.worker.phone,
      fullname: `${ row.worker.l_name } ${ row.worker.f_name }`,
      code: row.worker._id,
      send: true,
    } ) )
    .forEach( ( row ) => !workers.find( ( item ) => item.code === row.code ) && workers.push( row ) )

  const handleOpen = ( type ) => () => {
    setModal( { open: true, data: workers, type } )
    setAnchorEl( null )
  }

  return (
    <Fragment>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={ ( event ) => setAnchorEl( event.currentTarget ) }
      >
        Enviar recordatorios
      </Button>

      <Popover
        anchorOrigin={ { vertical: "bottom" } }
        anchorEl={ anchorEl }
        open={ Boolean( anchorEl ) }
        onClose={ () => setAnchorEl( null ) }
      >
        <MenuItem onClick={ handleOpen( "CORREO" ) }>Enviar notificación por CORREO</MenuItem>
        <MenuItem onClick={ handleOpen( "WHATSAPP" ) }>Enviar notificación por WHATSAPP</MenuItem>
      </Popover>

      { modal.open && (
        <SendNotification
          open={ modal.open }
          setOpen={ setModal }
          data={ modal.data }
          type={ modal.type }
        />
      ) }
    </Fragment>
  )
}
