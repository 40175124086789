import React, { useState, useEffect } from "react";
import { Select } from "antd";

const SelectActiveSedes = (props) => {
  const [sedes, setSedes] = useState(props.data);
  const onChange = (number) => {
    props.onChangeSelect(number);
  };
  useEffect(() => {
    setSedes(props.data);
  }, [props]);

  const renderOptions = () => {
    return sedes.map((i) => (
      <Option
        key={i._id}
        value={i._id}
        title={i.description}
        onClick={() =>
          props.setTitle &&
          props.setTitle({
            title: i.description,
            address: i.address,
          })
        }
      >{`${i.description} - ${i.address}`}</Option>
    ));
  };

  const filterOptions = (inputValue, option) => {
    if (option.props.title) {
      const title = option.props.title.toLowerCase();
      return title.includes(inputValue.toLowerCase(), 0);
    }
  };

  const { Option } = Select;

  return (
    <Select
      showSearch
      size="large"
      style={{ width: "100%" }}
      value={props.defaultValue}
      onChange={onChange}
      filterOption={filterOptions}
    >
      <Option key={null} value={null}>
        -- NINGUNO --
      </Option>
      {renderOptions()}
    </Select>
  );
};
export default SelectActiveSedes;
