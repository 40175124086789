import React, { useState, useEffect, useRef } from "react";
import {
  Paper,
  Card,
  CardContent,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import { notification } from "antd";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import PrintIcon from "@material-ui/icons/Print";
import sedeactual from "../../config/sedeactual";
import { makeStyles } from "@material-ui/styles";
import { apiReportes } from "../../config/api.contable";
import ReactToPrint from "react-to-print";
import axios from "axios";
import napformat from "../../config/napformat";
import { Link } from "react-router-dom";
import SelectSedes from "../../components/Usuarios/SelectSedes";
import ExportarEEGGPP from "../../components/Global/ExportarEEGGPP";
import Spinner from "../../components/Global/Spinner";
import { getHeadquarters } from "../../api/api.contable";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  logo: {
    position: "absolute",
    top: 10,
    left: 25,
  },
  excel: {
    backgroundColor: "green",
    color: "white",
    width: 121,
    margin: 2,
    "&:hover": {
      backgroundColor: "green",
    },
  },
  select: {
    maxWidth: "calc(100% - 275px)",
    minWidth: 300,
    textAlign: "left",
  },
  selectsedes: {
    maxWidth: "calc(100% - 650px)",
    minWidth: 300,
    textAlign: "left",
  },
}));

const ExportarERIN = (props) => {
  const classes = useStyles();
  const componentRef = useRef();
  const rucHeadquarters = sedeactual.getRuc();
  const [reportModeSelected, setReportModeSelected] = useState(0);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [sedeSelected, setSedeSelected] = useState(sedeactual.get());
  const [titleReport, setTitleReport] = useState({
    title: sedeactual.description(),
  });
  const [sedes, setSedes] = useState([]);

  const getData = async () => {
    try {
      setError(false);
      setData(null);
      let res = await axios.get(
        `${apiReportes}/eeggpp/${
          reportModeSelected === 0
            ? "anual/"
            : "month/" + `${reportModeSelected}/`
        }${sedeactual.getAnio()}/${sedeSelected}/${sedeactual.company().url}`
      );
      setData(res.data);
    } catch (error) {
      setError(true);
      notification["error"]({
        message: `${error}`,
        description: "No se logró obtener la información del reporte.",
      });
    }
  };

  const getSedes = async () => {
    try {
      let result = await getHeadquarters();
      setSedes(result.data);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se logró obtener las sedes.",
      });
    }
  };

  useEffect(() => {
    getSedes();
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportModeSelected, sedeSelected]);

  const content = (
    <Paper style={{ padding: 25, minHeight: "calc(100vh - 65px)" }}>
      <div align="right">
        <Link to={{ pathname: "/reportes" }}>
          <Button variant="outlined" color="primary" style={{ float: "left" }}>
            <ArrowBackIosIcon /> VOLVER
          </Button>
        </Link>
        {(sedeactual.getRol() === "supernap" ||
          sedeactual.getRol() === "superadmin") && (
          <FormControl
            variant="outlined"
            size="small"
            className={classes.selectsedes}
          >
            <SelectSedes
              defaultValue={sedeSelected}
              data={sedes}
              setTitle={setTitleReport}
              onChangeSelect={(e) => {
                setSedeSelected(e);
              }}
            />
          </FormControl>
        )}{" "}
        <FormControl variant="outlined" size="small" className={classes.select}>
          <InputLabel>Seleccione</InputLabel>
          <Select
            label="seleccione"
            value={reportModeSelected}
            onChange={(event) => setReportModeSelected(event.target.value)}
          >
            <MenuItem value={0}>Anual</MenuItem>
            <MenuItem value={1}>Enero</MenuItem>
            <MenuItem value={2}>Febrero</MenuItem>
            <MenuItem value={3}>Marzo</MenuItem>
            <MenuItem value={4}>Abril</MenuItem>
            <MenuItem value={5}>Mayo</MenuItem>
            <MenuItem value={6}>Junio</MenuItem>
            <MenuItem value={7}>Julio</MenuItem>
            <MenuItem value={8}>Agosto</MenuItem>
            <MenuItem value={9}>Setiembre</MenuItem>
            <MenuItem value={10}>Octubre</MenuItem>
            <MenuItem value={11}>Noviembre</MenuItem>
            <MenuItem value={12}>Diciembre</MenuItem>
          </Select>
        </FormControl>{" "}
        <ExportarEEGGPP
          nombre={`EEGGPP_${moment(Date.now()).format(
            "dddd_DD_MMMM_YYYY_HH_mm_ss"
          )}_${Date.now()}`}
          ruta={`${apiReportes}/eeggpp/export-excel-${
            reportModeSelected == 0 ? "anual/" : "month/" + reportModeSelected
          }${sedeactual.getAnio()}${
            reportModeSelected == 0 ? "" : "/" + reportModeSelected
          }/${sedeSelected}/${sedeactual.company().url}`}
        />
        <ReactToPrint
          trigger={() => (
            <Button
              color="primary"
              variant="outlined"
              style={{ marginRight: 10 }}
            >
              <PrintIcon /> IMPRIMIR
            </Button>
          )}
          content={() => componentRef.current}
        />
      </div>
      <Card
        ref={componentRef}
        align="center"
        style={{ position: "relative", minHeight: "100vh" }}
      >
        {/* <img
          src="https://hermanasdicperu.org/wp-content/uploads/2018/11/diclogo.png"
          height="125px"
          className={classes.logo}
        /> */}
        <CardContent style={{ padding: 75 }}>
          <Typography variant="h5" color="textPrimary">
            {titleReport.title && titleReport.title.toUpperCase()}
          </Typography>
          {/* {ruc == "" ? (
            ""
          ) : (
            <Typography variant="subtitle1" color="textPrimary">
              N° RUC {ruc}
            </Typography>
          )} */}
          <Typography variant="subtitle1" color="textPrimary">
            N° RUC {rucHeadquarters}
          </Typography>
          {/* {titleReport.address ? (
            <Typography variant="p" color="textPrimary">
              {titleReport.address}
            </Typography>
          ) : (
            ""
          )} */}
          <br />
          <br />
          <Typography variant="h5" color="textPrimary">
            ESTADO DE RESULTADOS INTEGRAL POR NATURALEZA
          </Typography>
          <Typography variant="subtitle2" color="textPrimary">
            Reporte{" "}
            {reportModeSelected == 0
              ? "anual " + sedeactual.getAnio()
              : "mensual de " +
                moment(
                  sedeactual.getAnio() + "-" + reportModeSelected + "-01"
                ).format("MMMM YYYY")}
            <br />
          </Typography>
          <Typography variant="subtitle2" color="textPrimary">
            (EXPRESADO EN SOLES)
          </Typography>
          {data ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2}>PRODUCCIÓN</TableCell>
                      </TableRow>
                    </TableHead>
                    {data.produccion && (
                      <TableBody>
                        <TableRow>
                          <TableCell>VENTAS NETAS</TableCell>
                          <TableCell align="right">
                            S/{napformat(data.produccion.ventasNetas)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            DESCUENTOS, REBAJAS Y BONIFICACIONES, CONC.
                          </TableCell>
                          <TableCell align="right">
                            S/{napformat(data.produccion.desc_re_bo)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontWeight: 900 }}>
                            TOTAL
                          </TableCell>
                          <TableCell align="right" style={{ fontWeight: 900 }}>
                            S/{napformat(data.produccion.ttproduccion)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2}>VALOR AGREGADO</TableCell>
                      </TableRow>
                    </TableHead>
                    {data.valor_agregado && (
                      <TableBody>
                        <TableRow>
                          <TableCell>COMPRAS (SUMINISTROS)</TableCell>
                          <TableCell align="right">
                            S/{napformat(data.valor_agregado.compras)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            GASTOS DE SERVICIOS PRESTADOS POR TERCEROS
                          </TableCell>
                          <TableCell align="right">
                            S/{napformat(data.valor_agregado.gastos_servicio)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontWeight: 900 }}>
                            TOTAL
                          </TableCell>
                          <TableCell align="right" style={{ fontWeight: 900 }}>
                            S/{napformat(data.valor_agregado.tt_valor_agregado)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2}>
                          EXCEDENTE BRUTO DE EXPLOTACIÓN
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {data.excedente_bruto_explotacion && (
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            GASTOS DE PERSONAL, DIRECTORES Y GERENTES
                          </TableCell>
                          <TableCell align="right">
                            S/
                            {napformat(
                              data.excedente_bruto_explotacion.gastos_personal
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>GASTOS POR TRIBUTOS</TableCell>
                          <TableCell align="right">
                            S/
                            {napformat(
                              data.excedente_bruto_explotacion.gastos_tributos
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontWeight: 900 }}>
                            TOTAL
                          </TableCell>
                          <TableCell align="right" style={{ fontWeight: 900 }}>
                            S/
                            {napformat(
                              data.excedente_bruto_explotacion.tt_exc_bruto
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2}>
                          RESULTADO DE EXPLOTACIÓN
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {data.resultado_explotacion && (
                      <TableBody>
                        <TableRow>
                          <TableCell>OTROS GASTOS DE GESTIÓN</TableCell>
                          <TableCell align="right">
                            S/
                            {napformat(
                              data.resultado_explotacion.ots_gastos_gest
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            VALUACIÓN Y DETERIORO DE ACTIVOS Y PROVISIONES
                          </TableCell>
                          <TableCell align="right">
                            S/
                            {napformat(
                              data.resultado_explotacion.val_dete_act_pro
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>OTROS INGRESOS DE GESTIÓN</TableCell>
                          <TableCell align="right">
                            S/
                            {napformat(
                              data.resultado_explotacion.ots_ingresos_gestion
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontWeight: 900 }}>
                            TOTAL
                          </TableCell>
                          <TableCell align="right" style={{ fontWeight: 900 }}>
                            S/
                            {napformat(
                              data.resultado_explotacion.tt_explotacion
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2}>
                          RESULTADO ANTES DE PARTICIPACIÓN E IMPUESTOS
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {data.resultado_a_part_impuestos && (
                      <TableBody>
                        <TableRow>
                          <TableCell>INGRESOS FINANCIEROS</TableCell>
                          <TableCell align="right">
                            S/
                            {napformat(
                              data.resultado_a_part_impuestos
                                .ingresos_financieros
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            DESCUENTOS, REBAJAS Y BONIFICACIONES OBTENIDAS
                          </TableCell>
                          <TableCell align="right">
                            S/
                            {napformat(
                              data.resultado_a_part_impuestos.desc_reb_bon
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            GANANCIAS POR MED DE ACTIVOS FIN. AL VALOR RA
                          </TableCell>
                          <TableCell align="right">
                            S/
                            {napformat(
                              data.resultado_a_part_impuestos
                                .ganancias_med_activ
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>GASTOS FINANCIEROS</TableCell>
                          <TableCell align="right">
                            S/
                            {napformat(
                              data.resultado_a_part_impuestos.gastos_financieros
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontWeight: 900 }}>
                            TOTAL
                          </TableCell>
                          <TableCell align="right" style={{ fontWeight: 900 }}>
                            S/
                            {napformat(
                              data.resultado_a_part_impuestos
                                .total_resultado_part_impuestos
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          ) : error ? (
            <Typography color="error" variant="body2">
              <br />
              <br />
              No se pudo obtener la información, intente mas tarde.
            </Typography>
          ) : (
            <Spinner />
          )}
        </CardContent>
      </Card>
    </Paper>
  );
  return content;
};

export default ExportarERIN;
