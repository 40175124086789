import React, { useState } from "react";
import axios from "axios";
import _ from "lodash/fp";
import useForm from "react-hook-form";
import moment from "moment";
import NumberFormat from "react-number-format";
import { apiInventario } from "../../../../../config/api.contable";
import {
  Grid,
  FormHelperText,
  Typography,
  DialogActions,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  TextField,
  InputAdornment,
  Card,
  CardMedia,
  CardContent,
  Badge,
  Fab,
} from "@material-ui/core";
import { notification } from "antd";
import { Delete } from "@material-ui/icons";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //prefix="S/ "
    />
  );
}

const ModalEditTerreno = (props) => {
  const { visible, setVisible, reload, dataTerreno, setDataTerreno, setLoading } = props;
  const { register, handleSubmit, errors } = useForm();
  const [selectedDate, setSelectedDate] = useState(moment(Date.now()).format("YYYY-MM-DD"));
  const [values, setValues] = useState({
    area: dataTerreno.area,
    side_a: dataTerreno.side_a,
    side_b: dataTerreno.side_b,
    side_c: dataTerreno.side_c,
    side_d: dataTerreno.side_d,
    side_another: dataTerreno.side_another,
    muni_autovalue_ground: dataTerreno.muni_autovalue_ground,
    acquisition_value: dataTerreno.acquisition_value,
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value.toUpperCase(),
    });
  };

  const editTerreno = async (newData) => {
    setLoading(true);
    const dataT = {
      property_name: newData.property_name.toUpperCase(),
      address: newData.address.toUpperCase(),
      area: values.area,
      side_a: values.side_a,
      side_b: values.side_b,
      side_c: values.side_c,
      side_d: values.side_d,
      side_another: values.side_another,
      departure_number: newData.departure_number.toUpperCase(),
      acquisition_date: selectedDate,
      acquisition_value: values.acquisition_value,
      muni_autovalue_ground: values.muni_autovalue_ground,
      observations: newData.observations.toUpperCase(),
      images: dataTerreno.images,
    };
    axios
      .put(`${apiInventario}/update-ground/${dataTerreno._id}`, dataT)
      .then((res) => {
        notification["success"]({
          message: "El registro se actualizó correctamente.",
        });
        reload();
        setVisible(false);
      })
      .catch((error) => {
        notification["error"]({
          message: "No se guardó el registro.",
          description: `${error}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemoveImage = (index) => {
    dataTerreno.images.splice(index, 1);
    setDataTerreno({ ...dataTerreno });
  };

  return (
    <Dialog maxWidth="xl" fullWidth open={visible}>
      <DialogTitle>
        <Typography variant="h3">Actualizar Terreno</Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(editTerreno)} autoComplete="off">
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                label="Denominación del Predio"
                variant="outlined"
                fullWidth
                defaultValue={dataTerreno.property_name}
                name="property_name"
                inputRef={register({
                  required: true,
                  maxLength: 120,
                })}
              />
              <FormHelperText error>
                {_.get("property_name.type", errors) === "required" &&
                  "La Denominación del Predio es requerida."}
                {_.get("property_name.type", errors) === "maxLength" &&
                  "La longitud máxima de caracteres es 120."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Dirección"
                variant="outlined"
                fullWidth
                defaultValue={dataTerreno.address}
                name="address"
                inputRef={register({
                  required: true,
                  maxLength: 120,
                })}
              />
              <FormHelperText error>
                {_.get("address.type", errors) === "required" && "La Dirección es requerida."}
                {_.get("address.type", errors) === "maxLength" &&
                  "La longitud máxima de caracteres es 120."}
              </FormHelperText>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="body2" color="textSecondary" component="h2">
                    Área y Perímetro
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Área del Terreno"
                    variant="outlined"
                    fullWidth
                    defaultValue={dataTerreno.area}
                    required
                    value={values.area}
                    onChange={handleChange}
                    name="area"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          m<sup>2</sup>
                        </InputAdornment>
                      ),
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Lado A"
                    variant="outlined"
                    fullWidth
                    required
                    name="side_a"
                    defaultValue={dataTerreno.side_a}
                    value={values.side_a}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m</InputAdornment>,
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Lado B"
                    variant="outlined"
                    fullWidth
                    name="side_b"
                    defaultValue={dataTerreno.side_b}
                    value={values.side_b}
                    required
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m</InputAdornment>,
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Lado C"
                    variant="outlined"
                    fullWidth
                    defaultValue={dataTerreno.side_c}
                    value={values.side_c}
                    required
                    onChange={handleChange}
                    name="side_c"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m</InputAdornment>,
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Lado D"
                    variant="outlined"
                    fullWidth
                    defaultValue={dataTerreno.side_d}
                    value={values.side_d}
                    onChange={handleChange}
                    name="side_d"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m</InputAdornment>,
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Lado Restante"
                    variant="outlined"
                    fullWidth
                    defaultValue={dataTerreno.side_another}
                    value={values.side_another}
                    onChange={handleChange}
                    name="side_another"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m</InputAdornment>,
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="N° Partida Electrónica"
                variant="outlined"
                fullWidth
                defaultValue={dataTerreno.departure_number}
                name="departure_number"
                inputRef={register({
                  required: true,
                  maxLength: 20,
                })}
              />
              <FormHelperText error>
                {_.get("departure_number.type", errors) === "required" &&
                  "El N° Partida Electrónica es requerido."}
                {_.get("departure_number.type", errors) === "maxLength" &&
                  "La longitud máxima de caracteres es 20."}
                {_.get("departure_number.type", errors) === "pattern" &&
                  "Solo se permiten números."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Fecha de Adquisición"
                variant="outlined"
                fullWidth
                defaultValue={dataTerreno.acquisition_date}
                name="acquisition_date"
                type="date"
                value={selectedDate}
                onChange={(event) => setSelectedDate(event.target.value.toUpperCase())}
                inputProps={{
                  max: moment(Date.now()).format("YYYY-MM-DD"),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Total Valor Compra"
                variant="outlined"
                fullWidth
                value={values.acquisition_value}
                required
                onChange={handleChange}
                defaultValue={dataTerreno.acquisition_value}
                name="acquisition_value"
                InputProps={{
                  startAdornment: <InputAdornment position="start">s/.</InputAdornment>,
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Valor de Autovaluo Municipalidad Terreno"
                variant="outlined"
                fullWidth
                value={values.muni_autovalue_ground}
                required
                onChange={handleChange}
                defaultValue={dataTerreno.muni_autovalue_ground}
                InputProps={{
                  startAdornment: <InputAdornment position="start">s/.</InputAdornment>,
                  inputComponent: NumberFormatCustom,
                }}
                name="muni_autovalue_ground"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Observaciones"
                variant="outlined"
                fullWidth
                defaultValue={dataTerreno.observations}
                name="observations"
                inputRef={register({
                  maxLength: 15,
                })}
              />
              <FormHelperText error>
                {_.get("acquisition_value.type", errors) === "maxLength" &&
                  "La longitud máxima de caracteres es 15."}
              </FormHelperText>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                {dataTerreno.images?.map((img, index) => (
                  <Grid item key={index}>
                    <Badge
                      badgeContent={
                        <Fab
                          size="small"
                          color="secondary"
                          onClick={() => handleRemoveImage(index)}
                        >
                          <Delete />
                        </Fab>
                      }
                    >
                      <Card>
                        <CardMedia style={{ height: 100, width: 100 }} image={img} />
                      </Card>
                    </Badge>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => setVisible(false)}>
            Cancelar
          </Button>

          <Button color="primary" variant="contained" type="submit">
            Actualizar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalEditTerreno;
