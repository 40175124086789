import React, { useState } from "react";
import { Select } from "antd";
import { Tooltip } from "@material-ui/core";

const { Option } = Select;
const SelectGradoEscolar = ({ setGrade, level, grade }) => {
  const list = [
    {
      level: "INICIAL",
      grades: ["3 AÑOS", "4 AÑOS", "5 AÑOS", "GUARDERIA", "LIBRE"],
    },
    {
      level: "PRIMARIA",
      grades: [
        "1ER GRADO",
        "2DO GRADO",
        "3ER GRADO",
        "4TO GRADO",
        "5TO GRADO",
        "6TO GRADO",
      ],
    },
    {
      level: "SECUNDARIA",
      grades: ["1ER AÑO", "2DO AÑO", "3ER AÑO", "4TO AÑO", "5TO AÑO"],
    },
  ];

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const renderOptions = () => {
    if (level) {
      return list
        .filter((el) => el.level == level.toUpperCase())[0]
        .grades.map((x) => (
          <Option key={x} value={x} title={x.toUpperCase()}>
            {x.toUpperCase()}&nbsp;&nbsp;&nbsp;
          </Option>
        ));
    }
  };

  const filterOptions = (inputValue, option) => {
    const title = removeAccents(option.props.title.toLowerCase());
    return title.includes(inputValue.toLowerCase());
  };

  return (
    <Select
      size="large"
      style={{ width: "100%" }}
      showSearch
      value={grade}
      onChange={(e) => setGrade(e)}
      filterOption={filterOptions}
      disabled={level ? false : true}
    >
      <Option key={0} value={null} title={""}>
        {" "}
        - Ninguno -{" "}
      </Option>
      {renderOptions()}
    </Select>
  );
};
export default SelectGradoEscolar;
