import React, { useState } from "react";
import { notification, Spin } from "antd";
import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import DropZone from "src/components/Global/DropZone";
import PublishIcon from "@material-ui/icons/Publish";
import sedeactual from "src/config/sedeactual";
import moment from "moment";
import { uploadVouchers } from "src/utils/req.boletas";
import sleep from "src/utils/sleep";
import types from "src/utils/typesComprobants";
import { ContentTypes } from "./Importacion";

const year = sedeactual.getAnio();

const ImportBoletasByHeadquarter = () => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const formatData = (filename) => {
    let parse = filename.split("-");
    let date_upload = moment().format("YYYY-MM-DD");
    let n_doc = parse[1].slice(0, parse[1].length - 4);
    let detail = types[parse[0]];
    let date = moment(`${year}-${parse[0]}-${new Date().getDate()}`)
      .endOf("month")
      .format("YYYY-MM-DD");

    if (!detail) detail = `OTROS ─ ${year}`;
    if (parse[0] === "13") date = `${year}-07-31`;
    if (parse[0] === "14") date = `${year}-12-31`;
    if (parse[0] === "15") date = `${year}-05-15`;
    if (parse[0] === "16") date = `${year}-11-15`;
    if (Number(parse[0]) > 16) date = moment().format("YYYY-MM-DD");

    return { n_doc, date, detail, date_upload };
  };

  const sendData = async () => {
    setLoading(true);
    try {
      for (let i = 0; i < files.length; i++) {
        let { n_doc, date, detail, date_upload } = formatData(files[i].name);
        let formData = new FormData();
        formData.append("file", files[i]);
        formData.append("n_doc", n_doc);
        formData.append("date", date);
        formData.append("detail", detail);
        formData.append("date_upload", date_upload);

        await uploadVouchers(formData);
        await sleep(100);
        notification["info"]({ message: `${detail} subido!` });
      }

      notification["success"]({
        message: `LA IMPORTACIÓN CONCLUYÓ EXITOSAMENTE!`,
        description: `Se subieron ${files.length} archivos.`,
      });
      setFiles([]);
    } catch (error) {
      notification["error"]({
        message: `Ocurrió un error al realizar la operación`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} align="center">
              <Typography variant="h4">
                <b>IMPORTAR BOLETAS</b>
              </Typography>
            </Grid>
            <ContentTypes group={false} />
            <Grid item xs={12}>
              <DropZone myFiles={files} setMyFiles={setFiles} accept="application/pdf" />
            </Grid>
            <Grid item xs={12} align="center">
              <Button
                onClick={sendData}
                disabled={files.length === 0}
                variant="contained"
                color="primary"
                size="large"
              >
                IMPORTAR <PublishIcon />
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Spin>
  );
};

export default ImportBoletasByHeadquarter;
