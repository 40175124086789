import React, { useState, useEffect } from "react";
import Page from "src/components/Page";
import { Button, FormHelperText } from "@material-ui/core";
import { notification } from "antd";
import { getFilesVoucher, getWorkers } from "src/utils/req.boletas";
import TableBoletas from "./components/TableBoletas";
import AddIcon from "@material-ui/icons/AddOutlined";
import SelectWorkers from "src/components/Selects/SelectWorkers";
import ModalBoletas from "./components/ModalBoletas";
import moment from "moment";

const Boletas = () => {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({ open: false, data: null });
  const [data, setData] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [worker, setWorker] = useState(null);

  useEffect(() => {
    if (worker) obtainData();
  }, [worker]);

  useEffect(() => {
    obtainWorkers();
  }, []);

  const obtainWorkers = async () => {
    setLoading(true);
    try {
      const res = await getWorkers();
      setWorkers(res);
    } catch (error) {
      notification["error"]({
        message: `Ocurrió un error al realizar la operación.`,
      });
    } finally {
      setLoading(false);
    }
  };

  const obtainData = async () => {
    setLoading(true);
    try {
      const res = await getFilesVoucher(worker._id);
      let result = res.map((el) => ({
        ...el,
        date_upload: moment(el.date_upload).format("DD/MM/YYYY"),
        date_voucher: moment(el.date_voucher).format("DD/MM/YYYY"),
      }));
      setData([...result.reverse()]);
    } catch (error) {
      notification["warning"]({
        message: `Ocurrió un error al realizar la operación.`,
        description: "El trabajador no existe.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page
      title="BOLETAS"
      helper="BOLETAS DE REMUNERACIONES"
      itemComponent={
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => setModal({ open: true, data: null })}
          disabled={!worker}
        >
          <AddIcon /> Agregar
        </Button>
      }
    >
      <FormHelperText>TRABAJADORES</FormHelperText>
      <SelectWorkers
        value={worker?._id ?? null}
        setValue={(e) => setWorker(workers.find((el) => el._id === e))}
        data={workers}
      />
      <TableBoletas data={data} loading={loading} obtainData={obtainData} />

      {modal.open && (
        <ModalBoletas
          open={modal.open}
          setOpen={setModal}
          data={modal.data}
          worker={worker}
          setLoading={setLoading}
          loading={loading}
          refresh={obtainData}
        />
      )}
    </Page>
  );
};

export default Boletas;
