import React, { useEffect, useState } from "react";
import Page from "src/components/Page";
import ImportacionPresupuesto from "./ImportacionPresupuesto";
import ImportacionPlanCuentas from "./ImportacionPlanCuentas";
import ImportacionVoucher from "./ImportacionVoucher";
import ImportAuxAccount from "./ImportAuxAccount";
import { Grid, Box } from "@material-ui/core";
import sedeactual from "src/config/sedeactual";
import { getHeadquarters } from "src/api/api.contable";
import { notification } from "antd";
import ImportacionTxt from "./ImportacionTxt";

const Importacion = () => {
  const [sedes, setSedes] = useState([]);

  let access = sedeactual.getRol() === "supernap" || sedeactual.getRol() === "superadmin";
  const [view, setView] = useState(false);

  useEffect(() => {
    let finder = Object.keys(sedeactual.modules()).find((el) => el === sedeactual.sede_id());
    if (Boolean(finder)) {
      let modulos = sedeactual.modules()[finder];
      if (modulos.includes("CONTABILIDAD")) {
        setView(true);
      }
    }
    if (sedeactual.getRol() === "supernap") {
      setView(true);
    }
    if (access) {
      obtainData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sedeactual.modules(), sedeactual.sede_id()]);

  const obtainData = async () => {
    try {
      const res = await getHeadquarters();
      setSedes(res.data);
    } catch (error) {
      notification["error"]({
        message: `Ocurrió un error al realizar la operación`,
      });
    }
  };

  return (
    <Page helper="IMPORTACIÓN" title="IMPORTACIÓN">
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} xl={4}>
            <ImportacionPlanCuentas isSuperAdm={access} sedes={sedes} />
          </Grid>
          {view && (
            <React.Fragment>
              <Grid item xs={12} md={6} xl={4}>
                <ImportAuxAccount isSuperAdm={access} sedes={sedes} />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <ImportacionPresupuesto isSuperAdm={access} sedes={sedes} />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <ImportacionVoucher isSuperAdm={access} sedes={sedes} />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <ImportacionTxt isSuperAdm={access} sedes={sedes} />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Box>
    </Page>
  );
};

export default Importacion;
