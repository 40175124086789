import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
const SelectCC = props => {
    const [cuentas,setCuentas] = useState(props.data);
    const [cuentaSelect,setCuentaSelect] = useState(null);
    const onChange = (number) => {
        setCuentaSelect(number);
        props.onChangeSelect(number) 
    }
    useEffect(()=>{
        if(setCuentaSelect!=null){
            setCuentaSelect(props.defaultValue);
        }
        setCuentas(props.data);
    },[props])
    const renderOptions  = () =>{
    return cuentas.map(i=><Option    key={i._id} value={i._id} title={i.cuenta+'-'+i.description}>{i.description}&nbsp;&nbsp;&nbsp;</Option>)
    }
    const filterOptions = (inputValue,option) => {
        const title = option.props.title.toString().toLowerCase() 
        
        return title.includes(inputValue.toString().toLowerCase())
        
    }
    const {Option} = Select;
	const content = 
        <Select
        size ="large"
        style={{ width: '100%' }} 
            showSearch
            value={cuentaSelect}
            onChange={onChange}
            filterOption={filterOptions}
        >
            <Option key={0} value={null} title={""}> - Ninguno - </Option> 
            {renderOptions()}
        </Select>;
	return content;
}
export default SelectCC;