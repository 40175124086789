import React, { useState, useEffect } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { getCharts } from "../../api/api.contable";
import sedeactual from "../../config/sedeactual";
import Spinner from "../../components/Global/Spinner";
import { Typography } from "@material-ui/core";

const Anual = () => {
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([]);

  const getChartsData = async () => {
    setLoading(true);
    try {
      let res = await getCharts();
      setdata(res.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (sedeactual.get()) {
      getChartsData();
    }
  }, []);

  return loading ? (
    <Spinner />
  ) : data.length > 0 ? (
    <div
      style={{
        height: "calc((100%) - 275px)",
        minHeight: 300,
      }}
    >
      <ResponsiveContainer>
        <AreaChart data={data}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            dataKey="compras"
            stroke="#8884d8"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
          <Area
            type="monotone"
            dataKey="ventas"
            stroke="#82ca9d"
            fillOpacity={1}
            fill="url(#colorPv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  ) : (
    <Typography align="center" variant="body1" color="textSecondary">
      <br />
      <br />
      <br />
      <br />
      NO SE ENCONTRÓ INFORMACIÓN
    </Typography>
  );
};

export default Anual;
