import React, { useState, useEffect } from "react"
import { notification } from "antd"
import { Button } from "@material-ui/core"
import { useParams } from "react-router-dom"
import Page from "../../../../components/Page"
import { getLocationDetail } from "../../requests"
import Spinner from "../../../../components/Global/Spinner"
import ArrowBackIos from "@material-ui/icons/ArrowBackIos"
import TableDetailLocation from "./components/TableDetailLocation"
import ExportarExcel from "../../../../components/Global/ExportarExcel"
import sedeactual from "../../../../config/sedeactual"

const DetailLocation = () => {
  const [ loading, setLoading ] = useState( false )
  const { idLocation } = useParams()
  const [ data, setData ] = useState( null )

  useEffect( () => {
    getData()
  }, [] )

  const getData = async () => {
    setLoading( true )
    try {
      let res = await getLocationDetail( idLocation )
      setData( res )
    } catch ( error ) {
      notification[ "error" ]( {
        message: "No se logró obtener la información",
        description: `${ error }`,
      } )
    } finally {
      setLoading( false )
    }
  }

  return (
    <Page
      title={ data?.location ? `${ data?.location?.name } - ${ data?.location?.description }` : "DETALLES DE LA LOCACIÓN" }
      helper="INVENTARIO"
      itemComponent={
        <>
          <Button
            color="primary"
            variant="contained"
            onClick={ () => window.history.back() }
          >
            <ArrowBackIos /> VOLVER
          </Button>&nbsp;
          <ExportarExcel
            title="DETALLADO"
            name={ `EQUIPOS_Y_MAQUINARIA_${ Date.now().toString() }` }
            endpoint={ `export-list-fixed-asset/${ idLocation }/${ sedeactual.get() }/${ sedeactual.company().url }` }
            body={ {} }
            method="POST"
            disabled={ loading }
            small={ false }
            api={ 2 }
          />&nbsp;
          <ExportarExcel
            title="AGRUPADO"
            name={ `EQUIPOS_Y_MAQUINARIA_${ Date.now().toString() }` }
            endpoint={ `export-list-fixed-asset-compact/${ idLocation }/${ sedeactual.get() }/${ sedeactual.company().url
              }` }
            body={ {} }
            method="POST"
            disabled={ loading }
            small={ false }
            api={ 2 }
          />
        </>
      }
    >
      { loading && <Spinner /> }
      <TableDetailLocation
        equipos={ data && data.machinery }
        muebles={ data && data.furniture }
        vehiculos={ data && data.vehicles }
        enseres={ data && data.equipment }
        intangibles={ data && data.intangibles }
      />
    </Page>
  )
}

export default DetailLocation
