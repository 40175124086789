import React, { useState } from "react";
import axios from "axios";
import _ from "lodash/fp";
import useForm from "react-hook-form";
import NumberFormat from "react-number-format";
import moment from "moment";
import { apiInventario } from "../../../../../config/api.contable";
import {
  Grid,
  FormHelperText,
  Typography,
  CardActions,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import sedeactual from "../../../../../config/sedeactual";
import { notification } from "antd";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //prefix="S/ "
    />
  );
}

const ModalAddTerreno = (props) => {
  const { visible, setVisible, reload, setLoading } = props;
  const { register, handleSubmit, errors } = useForm();
  const [selectedDate, setSelectedDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [values, setValues] = useState({
    area: "",
    side_a: "",
    side_b: "",
    side_c: "",
    side_d: "",
    side_another: "",
    muni_autovalue_ground: "",
    acquisition_value: "",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const addTerreno = async (newData) => {
    setLoading(true);
    axios
      .post(
        `${apiInventario}/ground/${sedeactual.get()}/${
          sedeactual.company().url
        }`,
        {
          property_name: newData.property_name.toUpperCase(),
          address: newData.address.toUpperCase(),
          area: values.area,
          side_a: values.side_a,
          side_b: values.side_b,
          side_c: values.side_c,
          side_d: values.side_d,
          side_another: values.side_another,
          departure_number: newData.departure_number.toUpperCase(),
          acquisition_date: selectedDate,
          acquisition_value: values.acquisition_value,
          muni_autovalue_ground: values.muni_autovalue_ground,
          observations: newData.observations.toUpperCase(),
          sede: sedeactual.get(),
          token: sedeactual.token(),
        }
      )
      .then((res) => {
        notification["success"]({
          message: "El terreno se guardó correctamente.",
        });
        reload();
        setVisible(false);
      })
      .catch((error) => {
        notification["error"]({
          message: "No se guardó el registro.",
          description: `${error}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog maxWidth="xl" fullWidth open={visible}>
      <DialogTitle>
        <Typography variant="h3">Agregar Terreno</Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(addTerreno)} autoComplete="off">
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                label="Denominación del Predio"
                variant="outlined"
                fullWidth
                name="property_name"
                inputRef={register({
                  required: true,
                  maxLength: 120,
                  minLength: 1,
                })}
              />
              <FormHelperText error>
                {_.get("property_name.type", errors) === "required" &&
                  "La Denominación del Predio es requerida."}
                {_.get("property_name.type", errors) === "maxLength" &&
                  "La longitud máxima de caracteres es 120."}
                {_.get("property_name.type", errors) === "minLength" &&
                  "La longitud minima de caracteres es 1."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Dirección"
                variant="outlined"
                fullWidth
                name="address"
                inputRef={register({
                  required: true,
                  maxLength: 120,
                  minLength: 1,
                })}
              />
              <FormHelperText error>
                {_.get("address.type", errors) === "required" &&
                  "La Dirección es requerida."}
                {_.get("address.type", errors) === "maxLength" &&
                  "La longitud máxima de caracteres es 120."}
                {_.get("address.type", errors) === "minLength" &&
                  "La longitud minima de caracteres es 1."}
              </FormHelperText>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    gutterBottom
                    variant="body2"
                    color="textSecondary"
                    variant="h5"
                    component="h2"
                  >
                    Área y Perímetro
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Área del Terreno"
                    required
                    variant="outlined"
                    fullWidth
                    name="area"
                    value={values.area}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          m<sup>2</sup>
                        </InputAdornment>
                      ),
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Lado A"
                    required
                    variant="outlined"
                    fullWidth
                    name="side_a"
                    value={values.side_a}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m</InputAdornment>
                      ),
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Lado B"
                    required
                    variant="outlined"
                    fullWidth
                    name="side_b"
                    value={values.side_b}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m</InputAdornment>
                      ),
                      inputComponent: NumberFormatCustom,
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Lado C"
                    variant="outlined"
                    fullWidth
                    name="side_c"
                    value={values.side_c}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m</InputAdornment>
                      ),
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Lado D"
                    variant="outlined"
                    fullWidth
                    name="side_d"
                    value={values.side_d}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m</InputAdornment>
                      ),
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Lado Restante"
                    variant="outlined"
                    fullWidth
                    name="side_another"
                    value={values.side_another}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m</InputAdornment>
                      ),
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="N° Partida Electrónica"
                variant="outlined"
                fullWidth
                name="departure_number"
                inputRef={register({
                  required: true,
                  maxLength: 20,
                  minLength: 1,
                })}
              />
              <FormHelperText error>
                {_.get("departure_number.type", errors) === "required" &&
                  "El N° Partida Electrónica es requerido."}
                {_.get("departure_number.type", errors) === "maxLength" &&
                  "La longitud máxima de caracteres es 20."}
                {_.get("departure_number.type", errors) === "minLength" &&
                  "La longitud minima de caracteres es 1."}
                {_.get("departure_number.type", errors) === "pattern" &&
                  "Solo se permiten números."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Fecha de Adquisición"
                required
                variant="outlined"
                fullWidth
                name="acquisition_date"
                type="date"
                value={selectedDate}
                onChange={(event) => setSelectedDate(event.target.value)}
                inputProps={{
                  max: moment(Date.now()).format("YYYY-MM-DD"),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Total Valor Compra"
                required
                variant="outlined"
                fullWidth
                name="acquisition_value"
                value={values.acquisition_value}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">s/.</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Valor de Autovaluo Municipalidad Terreno"
                required
                variant="outlined"
                fullWidth
                name="muni_autovalue_ground"
                value={values.muni_autovalue_ground}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">s/.</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Observaciones"
                variant="outlined"
                fullWidth
                name="observations"
                inputRef={register({
                  minLength: 10,
                  maxLength: 255,
                })}
              />
              <FormHelperText error>
                {_.get("observations.type", errors) === "maxLength" &&
                  "La longitud máxima son 255 caracteres."}
                {_.get("observations.type", errors) === "minLength" &&
                  "La longitud mínima son 10 caracteres."}
              </FormHelperText>
            </Grid>
          </Grid>
        </DialogContent>
        <CardActions>
          <Grid container spacing={1} direction="row" justify="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setVisible(false)}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button color="primary" variant="contained" type="submit">
                Registrar
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </form>
    </Dialog>
  );
};

export default ModalAddTerreno;
