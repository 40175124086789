import React, { useEffect, useState } from "react";
import Page from "src/components/Page";
import { Grid, Card, CardHeader, CardContent, Typography, Button, Fab } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link, useParams } from "react-router-dom";
import { getFilesVoucher } from "src/utils/req.boletas";
import moment from "moment";
import { notification, Empty } from "antd";
import Spinner from "src/components/Global/Spinner";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

const BoletasByWorker = () => {
  const { from, idWorker } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    obtainData();
  }, []);

  const obtainData = async () => {
    setLoading(true);
    try {
      const res = await getFilesVoucher(idWorker);
      setData([...res.reverse()]);
    } catch (error) {
      notification["warning"]({
        message: `Ocurrió un error al realizar la operación.`,
        description: "El trabajador no existe.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page
      title="BOLETAS DE REMUNERACIONES"
      helper="COMPROBANTES"
      itemComponent={
        <Link to={{ pathname: `/boletas/trabajadores/${from}` }}>
          <Button variant="contained" color="primary">
            VOLVER
          </Button>
        </Link>
      }
    >
      {loading ? (
        <Spinner />
      ) : data.length > 0 ? (
        <Grid container spacing={2} style={{ marginTop: 1 }}>
          {data.map((row, index) => (
            <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
              <Card elevation={5} style={{ height: "100%" }}>
                <CardHeader
                  avatar={
                    <Fab color="secondary" size="small" href={row.file} target="_blank">
                      <PictureAsPdfIcon />
                    </Fab>
                  }
                  title={
                    <Typography variant="subtitle1">
                      <b>{row.detail}</b>
                    </Typography>
                  }
                />
                <CardContent>
                  <Typography variant="body2" color="textSecondary" component="p">
                    FECHA DE SUBIDA: {moment(row.date_upload).format("dddd D [de] MMMM [del] YYYY")}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    FECHA DE PAGO: {moment(row.date_voucher).format("dddd D [de] MMMM [del] YYYY")}
                  </Typography>
                  <Alert severity={row.isView ? "success" : "info"}>
                    <span>
                      <b>ESTADO</b>
                      {row.isView ? " ─ VISTO" : " ─ PENDIENTE"}
                    </span>
                  </Alert>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Empty description="El trabajador no tiene boletas adjuntadas." />
      )}
    </Page>
  );
};

export default BoletasByWorker;
