import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Grid,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import SelectUsers from "src/components/Selects/SelectUsers";
import { notification, Spin } from "antd";
import sedeactual from "src/config/sedeactual";
import moment from "moment";
import { postMemorandum } from "../requests";

const CreateMemorandum = ({ open, setOpen, data, type, handleReload, param, action }) => {
  const handleClose = () => setOpen({ open: false, type: "", data: [] });
  const [info, setInfo] = useState({ to: {}, subject: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const notAction = data.filter((row) => (!row.memoInfo.delete && !row.memoInfo.transfer && !row.memoInfo.memorandum) || !row.memoInfo.memorandum?.active);

    setInfo({
      ...info,
      from: {
        fullname: sedeactual.current_user().fullname,
        t_doc: sedeactual.current_user().t_doc,
        n_doc: sedeactual.current_user().n_doc,
        address: sedeactual.current_user().address,
        email: sedeactual.current_user().email,
        phone: sedeactual.current_user().phone,
        _id: sedeactual.current_user()._id,
      },
      date: moment().format("YYYY-MM-DD"),
      [param]: notAction.map((row) => ({ ...row.memoInfo, code: row._id })),
      headquarter: sedeactual.sede_id(),
      company: sedeactual.company()._id,
      type,
      param,
      action,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (key, val) => setInfo({ ...info, [key]: val });

  const handleSendMemorandum = async () => {
    try {
      setLoading(true);
      await postMemorandum(info);
      handleReload();
      notification.success({ message: "El memorandum se creó correctamente." });
      handleClose();
    } catch (error) {
      notification.error({ message: "No se logró crear el memorandum." });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose}>
      <DialogTitle>
        <Typography align="center" variant="h3" component="p">
          CREACIÓN DE MEMORANDUM
        </Typography>
        <Typography align="center" variant="subtitle2">
          Recuerda que para <b>deshabilitar</b> o <b>hacer transferencia de locación</b> para
          registro del inventario (terreno, edificación, equipos, maquinaria, muebles, vehículos,
          enseres e intangibles), debe pasar por un memorandum que se asigne a una persona para que
          realice dicha operación.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Spin spinning={loading}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormHelperText>PARA</FormHelperText>
              <SelectUsers
                idDefaultUser={info.to?._id ?? null}
                setAllUserInfo={(info) =>
                  handleChange("to", {
                    fullname: info.fullname,
                    t_doc: info.t_doc,
                    n_doc: info.n_doc,
                    address: info.address,
                    email: info.email,
                    phone: info.phone,
                    _id: info._id,
                  })
                }
                filterByHeadquarter={sedeactual.sede_id()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Asunto"
                multiline
                rows={5}
                value={info.subject}
                onChange={(event) => handleChange("subject", event.target.value)}
              />
            </Grid>
          </Grid>
        </Spin>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={handleClose} disabled={loading}>
          CANCELAR
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSendMemorandum}
          disabled={loading || !info.to._id || !info.subject}
        >
          GENERAR MEMORANDUM
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateMemorandum;
