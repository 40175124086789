/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import useHttp from "../../hook/http";
import sedeactual from "../../config/sedeactual";
import { Link } from "react-router-dom";
import {
  Table,
  Button,
  Spin,
  Input,
  Icon,
  notification,
  Typography,
} from "antd";
import Exportar from "../Global/ExportarOld";
import anio from "../../config/anio";
import Highlighter from "react-highlight-words";
import numeral from "numeral";
import UserServices from "../../services/UserServices";
import moment from "moment";
import SelectSedes from "../Usuarios/SelectSedes";
import { getMovements, getHeadquarters } from "../../api/api.contable";
import ExportarMovements from "../../components/Global/ExportarMovements";
import { apiCuentas } from "../../config/api.contable";

const ExportarMovimientos = () => {
  const [dataSedes, setDataSedes] = useState([]);
  const [data, setdata] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");
  const [sede, setSede] = useState(sedeactual.get());
  const [digit, setDigit] = useState(2);
  const [digitos, setdigitos] = useState([2, 3, 6]);

  const [loading, setloading] = useState(true);
  const [dataTabla, setdataTabla] = useState([]);

  const getSedes = async () => {
    try {
      let result = await getHeadquarters();
      setDataSedes(result.data);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se logró obtener las sedes.",
      });
    }
  };

  useEffect(() => {
    getSedes();
  }, []);

  useEffect(() => {
    getTable();
  }, [digit, sede]);

  async function getTable() {
    try {
      setloading(true);
      let res = await getMovements(sede, digit);
      setdataTabla(res.data);
      setloading(false);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se logró obtener la información para el reporte.",
      });
    }
  }

  useEffect(() => {
    let arreglado = dataTabla.map((e) => {
      return {
        code: e.code,
        description: e.description,
        january: e.january == 0 ? "" : numeral(e.january).format("0, 0.0"),
        february: e.february == 0 ? "" : numeral(e.february).format("0, 0.00"),
        march: e.march == 0 ? "" : numeral(e.march).format("0, 0.00"),
        april: e.april == 0 ? "" : numeral(e.april).format("0, 0.00"),
        may: e.may == 0 ? "" : numeral(e.may).format("0, 0.00"),
        june: e.june == 0 ? "" : numeral(e.june).format("0, 0.00"),
        july: e.july == 0 ? "" : numeral(e.july).format("0, 0.00"),
        august: e.august == 0 ? "" : numeral(e.august).format("0, 0.00"),
        september:
          e.september == 0 ? "" : numeral(e.september).format("0, 0.00"),
        october: e.october == 0 ? "" : numeral(e.october).format("0, 0.00"),

        november: e.november == 0 ? "" : numeral(e.november).format("0, 0.00"),
        december: e.december == 0 ? "" : numeral(e.december).format("0, 0.00"),
        total: e.total == 0 ? "" : numeral(e.total).format("0, 0.00"),
      };
    });

    setdata([...arreglado]);
  }, [dataTabla]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyPress={(e) => e.key === "Enter" && handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Limpiar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .startsWith(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();

    setsearchText(selectedKeys[0]);
    setsearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setsearchText("");
  }

  const columns = [
    {
      title: "CUENTAS",
      dataIndex: "code",
      key: "code",
      width: 110,
      ...getColumnSearchProps("account_number"),
    },
    {
      title: "DESCRIPCIÓN",
      dataIndex: "description",
      key: "description",
      width: 180,
      ...getColumnSearchProps("description"),
    },
    {
      title: "ENERO",
      dataIndex: "january",
      key: "january",
      width: 100,
    },
    {
      title: "FEBRERO",
      dataIndex: "february",
      key: "february",
      width: 100,
    },
    {
      title: "MARZO",
      dataIndex: "march",
      key: "march",
      width: 100,
    },
    {
      title: "ABRIL",
      dataIndex: "april",
      key: "april",
      width: 100,
    },
    {
      title: "MAYO",
      dataIndex: "may",
      key: "may",
      width: 100,
    },
    {
      title: "JUNIO",
      dataIndex: "june",
      key: "june",
      width: 100,
    },
    {
      title: "JULIO",
      dataIndex: "july",
      key: "july",
      width: 100,
    },
    {
      title: "AGOSTO",
      dataIndex: "august",
      key: "august",
      width: 100,
    },
    {
      title: "SETIEMBRE",
      dataIndex: "september",
      key: "september",
      width: 110,
    },
    {
      title: "OCTUBRE",
      dataIndex: "october",
      key: "october",
      width: 100,
    },
    {
      title: "NOVIEMBRE",
      dataIndex: "november",
      key: "november",
      width: 110,
    },
    {
      title: "DICIEMBRE",
      dataIndex: "december",
      key: "december",
      width: 100,
    },
    {
      title: "TOTAL",
      dataIndex: "total",
      key: "total",
      width: 100,
    },
  ];

  return (
    <>
      {" "}
      <div className="p-4">
        <h2>Movimientos</h2>
        <Link to={{ pathname: "/reportes" }}>
          <Button icon="left">Volver</Button>
        </Link>
        <br />
        <div className="row mb-3">
          {(sedeactual.getRol() === "supernap" || sedeactual.getRol() === "superadmin") ? (
            <div className="col-lg-4">
              <label>Sede</label>

              <SelectSedes
                defaultValue={sede}
                data={dataSedes}
                onChangeSelect={(e) => {
                  setSede(e);
                }}
              ></SelectSedes>
            </div>
          ) : null}
          <div className="col-lg-2">
            <label>Dígitos</label>
            <select
              className="form-control "
              onChange={(evt) => setDigit(evt.target.value)}
            >
              {digitos.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-3 pt-4" style={{ alignContent: "bottom" }}>
            <ExportarMovements
              nombre={`MOVIMIENTOS-${moment(Date.now()).format(
                "DD/MM/YYYY-HH:mm:ss"
              )}`}
              ruta={`${apiCuentas}/movements/export-excel/${anio.get()}/${sede}/${sedeactual.company().url}`}
              digits={digit}
            />
          </div>
        </div>
        <Table
          bordered
          dataSource={data}
          columns={columns}
          scroll={{ x: "100%" }}
          pagination={false}
          loading={loading}
        />
      </div>
    </>
  );
};

export default ExportarMovimientos;
