import React, { useState, useEffect } from "react";
import { FormHelperText } from "@material-ui/core";
import { Select } from "antd";

const SelectActiveSedes = (props) => {
  const { defaultValue, data, onChangeSelect } = props;
  const [sedes, setSedes] = useState([]);

  useEffect(() => {
    if (data) {
      setSedes(data);
    }
  }, [data]);

  const onChange = (number) => {
    onChangeSelect(number);
  };

  const renderOptions = () => {
    return sedes.map((i) => (
      <Option
        key={i._id}
        value={i._id}
        title={i.description}
        onClick={() =>
          props.setTitle &&
          props.setTitle({
            title: i.description,
            address: i.address,
          })
        }
      >
        <FormHelperText component="span">
          {i.work_mode_id === 1 ? "COMUNIDAD" : "OBRAS"}{": "}
          {`${i.description} - ${i.address}`}
        </FormHelperText>
      </Option>
    ));
  };

  const filterOptions = (inputValue, option) => {
    if (option.props.title) {
      const title = option.props.title.toLowerCase();
      return title.includes(inputValue.toLowerCase(), 0);
    }
  };

  const { Option } = Select;

  return (
    <Select
      showSearch
      size="large"
      style={{ width: "100%" }}
      value={defaultValue}
      onChange={onChange}
      filterOption={filterOptions}
    >
      <Option key={null} value={null}>
        -- NINGUNO --
      </Option>
      {renderOptions()}
    </Select>
  );
};
export default SelectActiveSedes;
