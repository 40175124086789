import React, { useState, useEffect } from "react"
import axios from "axios"
import _ from "lodash/fp"
import { apiInventario } from "../../../../../config/api.contable"
import {
  Grid,
  Typography,
  DialogActions,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  TextField,
} from "@material-ui/core"
import { notification } from "antd"
import Autocomplete from "@material-ui/lab/Autocomplete"
import sedeactual from "../../../../../config/sedeactual"

const AddCategory = ( props ) => {
  const {
    visible,
    setVisible,
    token,
    reloadFunction,
    typeCategory,
    nameCategory,
    setLoading,
    accounts,
  } = props
  const [ name, setName ] = useState( "" )
  const [ customValue, setCustomValue ] = useState( null )
  const [ cuentas, setCuentas ] = useState( [] )

  useEffect( () => {
    filterAccounts( typeCategory )
  }, [ typeCategory, accounts ] )

  const filterAccounts = ( type ) => {
    let res = []
    if ( type === "machinery" ) {
      res = accounts.filter( ( el ) => el.number.toString().startsWith( "336" ) || el.number.toString().startsWith( "337" ) )
    } else if ( type === "vehicles" ) {
      res = accounts.filter( ( el ) => el.number.toString().startsWith( "3341" ) )
    } else if ( type === "furniture" ) {
      res = accounts.filter( ( el ) => el.number.toString().startsWith( "3351" ) )
    } else if ( type === "equipment" ) {
      res = accounts.filter( ( el ) => el.number.toString().startsWith( "3352" ) )
    } else if ( type === "intangibles" ) {
      res = accounts.filter( ( el ) => el.number.toString().startsWith( "34" ) || el.number.toString().startsWith( "38" ) )
    }
    setCuentas( res )
  }

  const addCategory = async () => {
    if ( name != "" && name.length > 1 && Boolean( customValue ) ) {
      setLoading( true )
      await axios
        .post( `${ apiInventario }/category`, {
          name: name.toUpperCase(),
          headquarter: sedeactual.sede_id(),
          company: sedeactual.company()._id,
          type: typeCategory,
          account_number: customValue.number,
          account_name: customValue.description,
          token: token,
        } )
        .then( () => {
          notification[ "success" ]( {
            message: "El registro de agregó correctamente.",
          } )
          setVisible( false )
          reloadFunction()
        } )
        .catch( ( error ) => {
          notification[ "error" ]( {
            message: `No se pudo agregar el registro.`,
            description: `${ error }`,
          } )
        } )
        .finally( () => {
          setLoading( false )
          setVisible( true )
        } )
    } else {
      notification[ "info" ]( {
        message:
          "El nombre es requerido y debe tener mínimamente 2 caracteres. La cuenta es requerida",
      } )
    }
  }

  return (
    <Dialog fullWidth open={ visible }>
      <DialogTitle>
        <Typography variant="h3">AGREGAR A { nameCategory }</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={ 1 }>
          <Grid item xs={ 12 } />
          <Grid item xs={ 12 }>
            <TextField
              autoFocus
              label="Nombre de categoría"
              variant="outlined"
              fullWidth
              value={ name }
              onChange={ ( event ) => setName( event.target.value ) }
            />
          </Grid>
          <Grid item xs={ 12 }>
            <Autocomplete
              options={ cuentas }
              fullWidth
              getOptionLabel={ ( option ) =>
                option.number + " " + option.description
              }
              renderOption={ ( option ) => (
                <>
                  { option.number } ({ option.description })
                </>
              ) }
              onChange={ ( event, newValue ) => {
                setCustomValue( newValue )
              } }
              renderInput={ ( params ) => (
                <TextField
                  { ...params }
                  fullWidth
                  label="Nombre de la cuenta"
                  variant="outlined"
                />
              ) }
            />
          </Grid>
          <Grid item xs={ 12 } />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={ () => setVisible( false ) }
        >
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={ () => addCategory() }
        >
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddCategory
