import React, { useState, useEffect } from "react";
import Page from "../../components/Page/Page";
import SelectUsers from "../Selects/SelectUsers";
import GestionTransfer from "./GestionTransfer";
import { Button, FormHelperText, Grid } from "@material-ui/core";
import axios from "axios";
import { apiContable } from "../../config/api.contable";
import { notification } from "antd";
import sedeactual from "../../config/sedeactual";

const GestionUsuarios = () => {
  const [idUser, setIdUser] = useState(sedeactual.user_id());
  const [sedeAssings, setSedeAssings] = useState([]);
  const [sedesID, setSedesID] = useState([]);

  const extraerIDs = () => {
    let resultID = sedeAssings.map((ev) => {
      return ev._id;
    });
    setSedesID(resultID);
  };

  useEffect(() => {
    extraerIDs();
  }, [sedeAssings]);

  const asignarSedes = async () => {
    if (idUser && sedesID.length > 0) {
      try {
        await axios.post(
          `${apiContable}/assign-headquarters/${idUser}`,
          { headquarters: sedesID },
          { headers: { Authorization: sedeactual.token() } }
        );
        notification["success"]({
          message: "La signación se efectuó correctamente!",
          description: "Se asignó correctamente la(s) sede(s).",
        });
      } catch (error) {
        notification["error"]({
          message: `${error}`,
          description: "Ocurrió un error al tratar de asignar las sedes.",
        });
      }
    } else {
      notification["info"]({
        message: "Valores requeridos",
        description: "Debe seleccionar al usuario y asignar sedes.",
      });
    }
  };

  return (
    <Page title="USUARIOS" helper="GESTIÓN DE EMPRESAS">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormHelperText>USUARIOS</FormHelperText>
          <SelectUsers
            idDefaultUser={idUser}
            onChangeSelect={(e) => setIdUser(e)}
          />
        </Grid>
        <Grid item xs={12}>
          <GestionTransfer idUser={idUser} setSedeAssings={setSedeAssings} />
        </Grid>
        <Grid item xs={12} align="center">
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ width: "50%" }}
            onClick={asignarSedes}
          >
            ASIGNAR
          </Button>
        </Grid>
      </Grid>
    </Page>
  );
};

export default GestionUsuarios;
