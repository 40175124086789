import React, { useState } from "react"
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  makeStyles,
} from "@material-ui/core"
import { apiInventario } from "../../../config/api.contable"
import axios from "axios"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"

const useStyles = makeStyles( ( theme ) => ( {
  paper: { width: "100%" },
} ) )

const ModalRemoveDocument = ( props ) => {
  const { visible, setVisible, reload, endPoint, title, subtitle, disable, description } = props
  const classes = useStyles()
  const [ loading, setLoading ] = useState( false )
  const [ error, setError ] = useState( false )
  const [ errorMessage, setErrorMessage ] = useState( "" )

  const __delete = async () => {
    setLoading( true )
    await axios
      .delete( `${ apiInventario }/${ endPoint }` )
      .then( ( res ) => {
        setVisible( false )
      } )
      .catch( ( err ) => {
        setLoading( false )
        setError( true )
        setErrorMessage( err.response.data.data )
      } )
      .finally( () => {
        reload()
        setLoading( false )
      } )
  }

  return (
    <Dialog open={ visible } classes={ { paper: classes.paper } }>
      <DialogTitle>
        <Typography variant="h3" color="textSecondary" align="center">
          { title }
        </Typography>
      </DialogTitle>
      { loading ? (
        <div style={ { margin: "0 auto" } }>
          <CircularProgress />
        </div>
      ) : error ? (
        <DialogContent>
          <DialogContentText align="center" style={ { color: "red" } }>
            { errorMessage }
          </DialogContentText>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText align="center">{ subtitle }</DialogContentText>
          <Typography variant="p" color="textSecondary" align="center">
            { description }
          </Typography>
        </DialogContent>
      ) }
      <DialogActions>
        <Button
          onClick={ () => setVisible( false ) }
          color="primary"
          variant="outlined"
          disabled={ loading ? true : false }
        >
          Cancelar
        </Button>
        <Button
          onClick={ () => __delete() }
          color="secondary"
          variant="contained"
          disabled={ loading ? true : false }
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalRemoveDocument
