import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Spin, notification } from "antd";
import { postWorkerInformation, putWorkerInformation, getWorkers } from "src/utils/req.boletas";
import moment from "moment";
import sedeactual from "src/config/sedeactual";
import { AttachFile } from "@material-ui/icons";

const ModalWorkerInformation = ({ open, setOpen, data, loading, setLoading, handleRefresh }) => {
  const [info, setInfo] = useState({ title: data ? data.detail : "", file: null });

  const saveRegister = async () => {
    try {
      setLoading(true);

      const workers = await getWorkers();
      const workerList = workers.map((wk) => ({ worker: wk._id, status: false }));

      const val = workerList.length === data?.workerList?.length ?? 0;
      const toSend = val && data ? data.workerList : workerList;

      let form = new FormData();
      if (info.file) form.append("file", info.file);
      form.append("detail", info.title);
      form.append("workerList", JSON.stringify(toSend));
      if (!data) {
        form.append("date_upload", moment().format("YYYY-MM-DD"));
        form.append("type", "general");
        form.append("headquarter", sedeactual.sede_id());
        form.append("company", sedeactual.company()._id);
      }

      if (data) await putWorkerInformation(form, data._id);
      else await postWorkerInformation(form);

      notification["success"]({
        message: `El registro se ${data ? "actualizó" : "guardó"} correctamente.`,
      });
      handleRefresh();
      setOpen({ open: false });
    } catch (error) {
      notification["error"]({ message: `Ocurrió un error al realizar la operación.` });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography align="center" variant="h4" component="p">
          {data ? "EDITAR" : "AGREGAR"} ARCHIVO DE INFORMACIÓN
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Spin spinning={loading}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Título del documento"
                variant="outlined"
                value={info.title}
                onChange={(e) => setInfo({ ...info, title: e.target.value })}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Archivo"
                variant="outlined"
                type="file"
                inputProps={{ accept: "application/pdf" }}
                onChange={(e) => setInfo({ ...info, file: e.target.files[0] })}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {data && data.file ? (
                        <IconButton component="a" target="_blank" href={data.file} rel="noreferrer">
                          <AttachFile />
                        </IconButton>
                      ) : (
                        <span />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Spin>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={() => setOpen({ open: false })}>
          CANCELAR
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={saveRegister}
          disabled={loading || !info.title || (!data && !info.file)}
        >
          {data ? "ACTUALIZAR" : "AGREGAR"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalWorkerInformation;
