import React, { Fragment } from "react";
import {
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@material-ui/core";
import napformat from "src/config/napformat";

const TableEEGGPPN = ({ data, year }) => {
  return (
    <Grid container spacing={2}>
      {/* INGRESOS */}

      <CustomContent title="INGRESOS" data={data.ingresos} year={year} />

      {/* EGRESOS */}

      <CustomContent title="EGRESOS" data={data.egresos} year={year} />

      {/* EJERCICIO */}

      <Grid item xs={12}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="right" colSpan={2}>
                  <Typography variant="h3" color="inherit">
                    <b>{year - 1}</b>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h3" color="inherit">
                    <b>{year}</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={{ backgroundColor: "#0688d150" }}>
                <TableCell>
                  <b>{data.ejercicio["number"]}</b>
                </TableCell>
                <TableCell align="right">
                  <b>s/ {napformat(data.ejercicio[year - 1])}</b>
                </TableCell>
                <TableCell align="right">
                  <b>s/ {napformat(data.ejercicio[year])}</b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default TableEEGGPPN;

const CustomContent = ({ title, data, year }) => {
  return (
    <Grid item xs={12}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} align="center">
                <Typography variant="h3" color="inherit">
                  <b>{title}</b>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((el, index) =>
              el.number.toString().length > 2 ? (
                <Fragment key={index}>
                  <TableRow>
                    <TableCell style={{ margin: 0, padding: 0 }} colSpan={3}>
                      <Box ml={5}>
                        <Table size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant={
                                    el.number === "TOTAL EGRESOS" || el.number === "TOTAL INGRESOS"
                                      ? "h5"
                                      : "inherit"
                                  }
                                >
                                  {el.number === "TOTAL EGRESOS" || el.number === "TOTAL INGRESOS"
                                    ? el.number
                                    : `${el.number} ─ ${el.description}`}
                                </Typography>
                              </TableCell>
                              <TableCell style={{ width: 200 }} align="right">
                                {el.number === "TOTAL EGRESOS" || el.number === "TOTAL INGRESOS" ? (
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 17,
                                    }}
                                  >
                                    s/ {napformat(el[year - 1])}
                                  </span>
                                ) : (
                                  `s/ ${napformat(el[year - 1])}`
                                )}
                              </TableCell>
                              <TableCell style={{ width: 200 }} align="right">
                                {el.number === "TOTAL EGRESOS" || el.number === "TOTAL INGRESOS" ? (
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 17,
                                    }}
                                  >
                                    s/ {napformat(el[year])}
                                  </span>
                                ) : (
                                  `s/ ${napformat(el[year])}`
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    </TableCell>
                  </TableRow>
                </Fragment>
              ) : (
                <TableRow key={index} style={{ backgroundColor: "#0688d125" }}>
                  <TableCell>{el.number}</TableCell>
                  <TableCell>{el.description}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
