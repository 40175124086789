import React, { Fragment, useState, useEffect } from "react";
import { Box, Grid, Typography, FormControlLabel, Button, Checkbox } from "@material-ui/core";
import useForm from "react-hook-form";
import ModalPolitica from "./ModalPolitica";
import { notification } from "antd";
import { postIncidence, putIncidence } from "src/utils/reqinc";
import PartZero from "./formComponents/PartZero";
import PartOne from "./formComponents/PartOne";
import PartTwo from "./formComponents/PartTwo";
import PartThree from "./formComponents/PartThree";
import PartFour from "./formComponents/PartFour";
import PartFive from "./formComponents/PartFive";
import PartSix from "./formComponents/PartSix";
import PartSeven from "./formComponents/PartSeven";
import PartEight from "./formComponents/PartEight";
import PartNine from "./formComponents/PartNine";

const listParentesco = ["Padre", "Madre", "Hermano(a)", "Tio(a)", "Docente", "Otro"];
const listTypeReport = ["Personal de la IE a estudiante", "Entre estudiantes"];
const listTDocs = ["DNI", "RUC", "CE"];
const listTypeAgresores = ["Estudiante", "Personal de la IE", "Otro"];
const listPrtSchool = ["del mismo grado", "grado superior", "grado inferior"];

const Form = ({ name, id, setLoading, data: eInf }) => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const [modalPolitica, setModalPolitica] = useState(false);
  const [checked, setChecked] = useState(true);
  const [infTDoc, setInfTDOc] = useState(listTDocs[0]);
  const [aggressor, setAggressor] = useState(listTypeAgresores[0]);
  const [typeReport, setTypeReport] = useState(listTypeReport[0]);
  const [parentesco, setParentesco] = useState({
    text: listParentesco[0],
    value: false,
  });
  // AGREGIDO
  const [agdSexo, setAgdSexo] = useState(null);
  const [agdLevel, setAgdLevel] = useState(null);
  const [agdGrade, setAgdGrade] = useState(null);
  const [agdSection, setAgdSection] = useState(null);
  const [agdShift, setAgdShift] = useState(null);
  const [agdForanero, setAgdForanero] = useState({ text: "", value: false });
  const [agdDiscapacidad, setAgdDiscapacidad] = useState({
    text: "",
    value: false,
  });
  // AGRESOR
  const [agsSexo, setAgsSexo] = useState(null);
  const [agsLevel, setAgsLevel] = useState(null);
  const [agsGrade, setAgsGrade] = useState(null);
  const [agsSection, setAgsSection] = useState(null);
  const [agsShift, setAgsShift] = useState(null);
  const [prntSchool, setPrntSchool] = useState(listPrtSchool[0]);
  const [agsTDoc, setAgsTDoc] = useState(listTDocs[0]);
  const [agsDiscapacidad, setAgsDiscapacidad] = useState({
    text: "",
    value: false,
  });
  const [agsPersonal, setAgsPersonal] = useState({
    text: "",
    value: false,
  });
  const [typeAggression, setTypeAggression] = useState([
    { name: "Físico", check: false },
    { name: "Sexual", check: false },
    { name: "Psicológica", check: false },
  ]);
  const [reasons, setReasons] = useState([
    { name: "Por ser de otro lugar", check: false },
    { name: "Por el acento", check: false },
    { name: "Por el color de su piel", check: false },
    { name: "Porque es o creen que es homosexual", check: false },
    { name: "Por las características físicas", check: false },
    { name: "Por tener alguna discapacidad", check: false },
    { name: "Por sus creencias religiosas", check: false },
    { name: "Por tener más o menos dinero que el resto", check: false },
    { name: "Por su cultura o costumbres", check: false },
    { name: "Por tener notas más bajas o más altas", check: false },
    { name: "Por ser callado o tímido", check: false },
    { name: "Sin motivo alguno, solo por molestar o burlarse", check: false },
  ]);

  useEffect(() => {
    if (eInf) {
      setInfTDOc(eInf.infTDoc);
      setAggressor(eInf.aggressor);
      setTypeReport(eInf.typeReport);
      setParentesco(eInf.infParentesco);
      setAgdSexo(eInf.agdSexo);
      setAgdLevel(eInf.agdLevel);
      setAgdGrade(eInf.agdGrade);
      setAgdSection(eInf.agdSection);
      setAgdShift(eInf.agdShift);
      setAgdForanero(eInf.agdForanero);
      setAgdDiscapacidad(eInf.agdDiscapacidad);
      setAgsSexo(eInf.agsSexo);
      setAgsLevel(eInf.agsLevel);
      setAgsGrade(eInf.agsGrade);
      setAgsSection(eInf.agsSection);
      setAgsShift(eInf.agsShift);
      setPrntSchool(eInf.prntSchool);
      setAgsTDoc(eInf.agsTDoc);
      setAgsDiscapacidad(eInf.agsDiscapacidad);
      setAgsPersonal(eInf.agsPersonal);

      let newTA = [];
      typeAggression.forEach((el) => {
        let check1 = false;
        eInf.typeAggression.forEach((ev) => {
          if (el.name === ev.name) check1 = true;
        });
        newTA.push({ name: el.name, check: check1 });
      });
      setTypeAggression(newTA);

      let newR = [];
      reasons.forEach((el) => {
        let check2 = false;
        eInf.reason.forEach((ev) => {
          if (el.name === ev.name) check2 = true;
        });
        newR.push({ name: el.name, check: check2 });
      });
      setReasons(newR);
    }
  }, [eInf]);

  const sendIncident = async (data, event) => {
    setLoading(true);
    try {
      if (!checked && !Boolean(eInf)) {
        notification["warning"]({
          message: "Debe aceptar la política de privacidad para continuar.",
        });
        return;
      }

      let filterReasons = reasons.filter((el) => el.check === true);
      let filterTAgrs = typeAggression.filter((el) => el.check === true);

      let prepareData = {
        // ONE - GENERAL
        dateRegister: data.dateNow,
        typeReport: typeReport,
        headquearter: id,
        //status: Boolean(eInf) ? "FINALIZADO" : "PENDIENTE",
        status: "PENDIENTE",
        // correlative: "1",
        siseve: Boolean(eInf) ? data.siseve : "",
        dre: Boolean(eInf) ? data.dre : "",
        ugel: Boolean(eInf) ? data.ugel : "",
        // TWO - INFORMANTE
        infTDoc: infTDoc,
        infNDoc: data.infNDoc,
        infFullname: data.infFullname,
        infParentesco: parentesco,
        infAddress: data.infAddress,
        infPhone: data.infPhone,
        infEmail: data.infEmail,
        // THREE - AGREDIDO(A)
        agdInitils: data.agdInitils,
        agdAge: data.agdAge,
        agdSexo: agdSexo,
        agdLevel: agdLevel,
        agdGrade: agdGrade,
        agdSection: agdSection,
        agdShift: agdShift,
        agdPhone: data.agdPhone,
        agdDiscapacidad: agdDiscapacidad,
        agdForanero: agdForanero,
        // FOUR - AGRESOR
        aggressor: aggressor,
        agsTDoc: agsTDoc,
        agsNDoc: data.agsNDoc,
        agsFullname: data.agsFullname,
        agsDiscapacidad: agsDiscapacidad,
        agsAge: data.agsAge,
        agsSexo: agsSexo,
        agsLevel: agsLevel, // Si es estudiante
        agsGrade: agsGrade, // Si es estudiante
        agsSection: agsSection, // Si es estudiante
        agsShift: agsShift, // Si es estudiante
        prntSchool: prntSchool, // Si es estudiante
        agsPersonal: agsPersonal, // Si es personal
        // FIVE - TIPO DE AGRESIÓN
        typeAggression: filterTAgrs,
        // SEX - MOTIVO DE LA AGRESIÓN
        reason: filterReasons,
        // SEVEN - DESCRIPCIÓN
        descEvent: data.descEvent,
        // EIGHT - PROCEDIMIENTO
        procedure: data.procedure ? data.procedure : "",
        // NINE - MEDIDAS A ADOPTAR
        metrics: data.metrics ? data.metrics : "",
      };

      if (eInf) await putIncidence(prepareData, eInf._id);
      else await postIncidence(prepareData, id);
      notification["success"]({
        message: `La incidencia se ${eInf ? "actualizó" : "registró"} correctamente.`,
      });

      if (!eInf) event.target.reset();
    } catch (error) {
      notification["error"]({
        message: "No se logró guardar la incidencia.",
      });
    } finally {
      setLoading(false);
    }
  };

  const changeToUpperCase = (key, value) => setValue(key, value.toUpperCase());

  return (
    <Box p={4} pt={0}>
      <form onSubmit={handleSubmit(sendIncident)} autoComplete="off">
        <Grid container justify="flex-end" spacing={2}>
          {Boolean(eInf) && (
            <Grid item xs={12}>
              <br />
              <PartZero errors={errors} register={register} eInf={eInf} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography component="h2" variant="body1">
              <b>1. DATOS GENERALES</b>
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <PartOne
              errors={errors}
              register={register}
              typeReport={typeReport}
              setTypeReport={setTypeReport}
              listTypeReport={listTypeReport}
              eInf={eInf}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography component="h2" variant="body1">
              <b>2. DATOS DE LA PERSONA INFORMANTE DEL CASO</b>
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <PartTwo
              errors={errors}
              register={register}
              infTDoc={infTDoc}
              setInfTDOc={setInfTDOc}
              listTDocs={listTDocs}
              parentesco={parentesco}
              setParentesco={setParentesco}
              listParentesco={listParentesco}
              eInf={eInf}
              setValue={changeToUpperCase}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography component="h2" variant="body1">
              <b>3. DATOS DE LA SUPUESTA PERSONA AGREDIDA</b>
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <PartThree
              errors={errors}
              register={register}
              agdSexo={agdSexo}
              setAgdSexo={setAgdSexo}
              agdLevel={agdLevel}
              setAgdLevel={setAgdLevel}
              setAgdGrade={setAgdGrade}
              setAgdSection={setAgdSection}
              agdGrade={agdGrade}
              agdSection={agdSection}
              agdShift={agdShift}
              setAgdShift={setAgdShift}
              agdDiscapacidad={agdDiscapacidad}
              setAgdDiscapacidad={setAgdDiscapacidad}
              agdForanero={agdForanero}
              setAgdForanero={setAgdForanero}
              eInf={eInf}
              setValue={changeToUpperCase}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography component="h2" variant="body1">
              <b>4. DATOS DEL PRESUNTO AGRESOR O AGRESORES</b>
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <PartFour
              errors={errors}
              register={register}
              setAggressor={setAggressor}
              aggressor={aggressor}
              listTypeAgresores={listTypeAgresores}
              agsSexo={agsSexo}
              setAgsSexo={setAgsSexo}
              setAgsLevel={setAgsLevel}
              setAgsGrade={setAgsGrade}
              setAgsSection={setAgsSection}
              agsLevel={agsLevel}
              agsGrade={agsGrade}
              agsSection={agsSection}
              agsShift={agsShift}
              setAgsShift={setAgsShift}
              agsDiscapacidad={agsDiscapacidad}
              setAgsDiscapacidad={setAgsDiscapacidad}
              prntSchool={prntSchool}
              setPrntSchool={setPrntSchool}
              listPrtSchool={listPrtSchool}
              agsTDoc={agsTDoc}
              setAgsTDoc={setAgsTDoc}
              listTDocs={listTDocs}
              agsPersonal={agsPersonal}
              setAgsPersonal={setAgsPersonal}
              eInf={eInf}
              setValue={changeToUpperCase}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography component="h2" variant="body1">
              <b>5. ESPECIFIQUE EL TIPO DE VIOLENCIA QUE SUFRIÓ EL O LA ESCOLAR:</b>
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <PartFive typeAggression={typeAggression} setTypeAggression={setTypeAggression} />
          </Grid>

          <Grid item xs={12}>
            <Typography component="h2" variant="body1">
              <b>6. ¿POR QUÉ CREE USTED QUE SE PRODUJO LA VIOLENCIA AL ESCOLAR?</b>
            </Typography>
          </Grid>
          <Grid item xs={11} container>
            <PartSix reasons={reasons} setReasons={setReasons} />
          </Grid>

          <Grid item xs={12}>
            <Typography component="h2" variant="body1">
              <b>7. BREVE DESCRIPCIÓN DEL HECHO</b>
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <PartSeven errors={errors} register={register} eInf={eInf} />
          </Grid>

          {Boolean(eInf) && (
            <Fragment>
              <Grid item xs={12}>
                <Typography component="h2" variant="body1">
                  <b>8. TRÁMITE SEGUIDO FRENTE AL HECHO DE VIOLENCIA ESCOLAR</b>
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <PartEight errors={errors} register={register} eInf={eInf} />
              </Grid>
            </Fragment>
          )}

          {Boolean(eInf) && (
            <Fragment>
              <Grid item xs={12}>
                <Typography component="h2" variant="body1">
                  <b>
                    9. MEDIDAS CORRECTIVAS Y DE PROTECCIÓN IMPLEMENTADAS POR LA INSTITUCIÓN
                    EDUCATIVA
                  </b>
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <PartNine errors={errors} register={register} eInf={eInf} />
              </Grid>
            </Fragment>
          )}

          {!Boolean(eInf) ? (
            <Fragment>
              <Grid item xs={12}>
                <Typography component="h2" variant="body1">
                  <b>VALIDACIÓN DE SEGURIDAD:</b>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                      color="primary"
                    />
                  }
                  label={
                    <span>
                      Declaro haber leído y acepto la
                      <Button onClick={() => setModalPolitica(true)} component="span">
                        Política de Privacidad.
                      </Button>
                    </span>
                  }
                />
              </Grid>
            </Fragment>
          ) : (
            ""
          )}

          {(!eInf || (eInf && eInf.status !== "FINALIZADO")) && (
            <Grid item xs={12} align="center">
              <Button size="large" color="primary" variant="contained" type="submit">
                {Boolean(eInf) ? "ACTUALIZAR" : "ENVIAR"}
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
      {modalPolitica && (
        <ModalPolitica visible={modalPolitica} setVisible={setModalPolitica} name={name} />
      )}
    </Box>
  );
};

export default Form;
