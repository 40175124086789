import React, { useState } from "react";
import { Table, Input, Button, Icon } from "antd";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import moment from "moment";
import BtnView from "../../../../components/Global/components/Botones/BtnView";

export default function TablePagosCobros({ data, loading, type }) {
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyPress={(e) => e.key === "Enter" && handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Limpiar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text?.toString()}
        />
      ) : (
        text
      ),
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setsearchText(selectedKeys[0]);
    setsearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setsearchText("");
  }

  let columns = [
    {
      title: "NÚMERO",
      dataIndex: "n_voucher",
      key: "n_voucher",
      width: 100,
      ...getColumnSearchProps("n_voucher"),
    },
    {
      title: "FEC. OPERACIÓN",
      dataIndex: "operation_date",
      key: "operation_date",
      width: 150,
      ...getColumnSearchProps("operation_date"),
      render: (text) => moment(text).format("DD/MM/YYYY"),
    },
    {
      title: "ORIGEN",
      dataIndex: "origin",
      key: "origin",
      width: 100,
      ...getColumnSearchProps("origin"),
    },
    {
      title: "SERIE",
      dataIndex: "serie",
      key: "serie",
      width: 100,
      ...getColumnSearchProps("serie"),
    },
    {
      title: "CORRELATIVO",
      dataIndex: "correlative",
      key: "correlative",
      width: 150,
      ...getColumnSearchProps("correlative"),
    },
    {
      title: "RUC",
      dataIndex: "ruc",
      key: "ruc",
      width: 100,
      ...getColumnSearchProps("ruc"),
      render: (text) =>
        text
          ? text !== "-"
            ? text.toString().length > 10
              ? text
              : `DNI: ${text}`
            : "-"
          : "-",
    },
    {
      title: "CONCEPTO",
      dataIndex: "comment",
      key: "comment",
      width: 150,
      ...getColumnSearchProps("comment"),
    },
    {
      title: "MONTO",
      dataIndex: "amount",
      key: "amount",
      width: 100,
      ...getColumnSearchProps("amount"),
    },
    {
      title: "ACCIONES",
      dataIndex: "_id",
      key: "_id",
      width: 100,
      render: (value) => (
        <Link to={{ pathname: `/pagoscobros/${value}/${type}` }}>
          <BtnView small={true} />
        </Link>
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data}
      bordered
      scroll={{ x: "100%" }}
      pagination={{ pageSize: 10 }}
      loading={loading}
    />
  );
}
