import { useState, useEffect } from "react";
import UserServices from "../services/UserServices";
import { apiContable } from "../config/api.contable";
import sedeactual from "../config/sedeactual";

let urlCompany = sedeactual.company() ? sedeactual.company().url : null;

const useHttpNew = (url, dependencies) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setfetchedData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${apiContable}/${url}/${urlCompany}`, UserServices.createInit("GET"))
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch.");
        }
        return response.json();
      })
      .then((data) => {
        setfetchedData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, dependencies);
  return [isLoading, fetchedData];
};
export default useHttpNew;
