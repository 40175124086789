import React, { useState } from "react";
import { Button } from "@material-ui/core";
import Page from "src/components/Page";
import Head from "./Head";
import TableDetails from "./TableDetails";
import Import from "./Import";
import moment from "moment";
import { Spin, notification } from "antd";
import { sendVoucher } from "src/api/api.contable";
import sedeactual from "src/config/sedeactual";

const ImportVoucher = () => {
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    origen: null,
    periodo: moment().month() + 1,
    date: moment().format(),
    type: null,
    serie: "",
    correlativo: "",
    ruc: null,
    client: null,
    customer: null,
  });

  const createVoucher = async () => {
    let counter = 0;
    details.voucher.forEach(
      (row) => typeof row.account === "number" && counter++
    );
    if (counter !== 0) {
      notification["warning"]({
        message:
          "Todas los registros de los detalles tienen que tener una cuenta asignada.",
      });
      return;
    }

    try {
      setLoading(true);
      let formData = {
        correlative: data.correlativo,
        customer: data.customer,
        headquarters_id: sedeactual.get(),
        numComprobante: "",
        operation_date: moment(data.date).format("YYYY-MM-DD"),
        origin: data.origen,
        period: data.periodo,
        serie: data.serie,
        t_doc: data.type,
        voucher_id: 0,
        year: sedeactual.getAnio(),
        details: details.voucher,
      };

      await sendVoucher(formData, sedeactual.get());
      notification["success"]({
        message: `Voucher creado exitosamente!`,
      });

      setDetails(null);
      setData({
        origen: null,
        periodo: moment().month() + 1,
        date: moment().format(),
        type: null,
        serie: "",
        correlativo: "",
        ruc: null,
        client: null,
        customer: null,
      });
    } catch (error) {
      notification["error"]({
        message: `Ocurrió un error al realizar la operación`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Page
        helper="VOUCHERS"
        title="IMPORTACIÓN DE VOUCHER"
        itemComponent={
          <Import setDetails={setDetails} setLoading={setLoading} data={data} />
        }
      >
        <Head data={data} setData={setData} />
        <TableDetails
          details={details}
          setLoading={setLoading}
          setDetails={setDetails}
        />
        <br />
        <div align="center">
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={createVoucher}
            disabled={!details}
          >
            CONFIRMAR Y SUBIR VOUCHER
          </Button>
        </div>
      </Page>
    </Spin>
  );
};

export default ImportVoucher;
