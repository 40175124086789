import React from "react";
import {
  Grid,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";
import moment from "moment";

const PartOne = ({
  errors,
  register,
  typeReport,
  setTypeReport,
  listTypeReport,
  eInf,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} container alignItems="center">
        <TextField
          InputLabelProps={{ shrink: true }}
          size="small"
          fullWidth
          label="Fecha de registro *"
          name="dateNow"
          variant="outlined"
          type="date"
          disabled
          defaultValue={Boolean(eInf) ? eInf.dateRegister : moment().format("YYYY-MM-DD")}
          // value={moment().format("YYYY-MM-DD")}
          inputRef={register({
            required: true,
          })}
          error={Boolean(errors?.dateNow)}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <FormControl fullWidth fullWidth>
          <FormLabel component="legend">Tipo de reporte</FormLabel>
          <RadioGroup
            value={typeReport}
            onChange={(e) => setTypeReport(e.target.value)}
          >
            <Grid container justify="space-between">
              {listTypeReport.map((el, index) => (
                <Grid item key={index}>
                  <FormControlLabel
                    value={el}
                    control={<Radio color="primary" />}
                    label={el}
                  />
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default PartOne;
