import SessionStorageService from "../auth/SessionStorageService";
import SessionTimeService from "../auth/SessionTimeService";
import { isArray, log } from "util";
import { API_BASE_URL } from "../config/api";
import { SESSION_NAME } from "src/config/session";

class UserServices {
  static headersUpload() {
    let headers = {
      "X-Requested-With": "XMLHttpRequest",
    };
    const userData = SessionStorageService.get(SESSION_NAME);
    if (!!userData && typeof userData.value.api_token === "string") {
      headers["Authorization"] = userData.value["api_token"];
    }
    SessionTimeService.renewSession();
    return headers;
  }

  static createHeaders() {
    let headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };
    const authorization = this.getAuthorization();
    if (!!authorization) {
      headers["Authorization"] = authorization;
    }
    return headers;
  }

  static getAuthorization() {
    let authorization = null;
    const userData = SessionStorageService.get(SESSION_NAME);
    if (!!userData && typeof userData.value.api_token === "string") {
      authorization = userData.value["api_token"];
    }
    SessionTimeService.renewSession();
    return authorization;
  }

  static createInit(method) {
    const myInit = {
      method: method,
      headers: this.createHeaders(),
      responseType: "json",
    };
    return myInit;
  }
  static createCustomizedHeader(method, body) {
    const myInit = {
      method: method,
      headers: this.createHeaders(),
      responseType: "json",
      body: JSON.stringify(body),
    };
    return myInit;
  }
  static getAllUsers() {
    const url = `${API_BASE_URL}/users`;
    const res = [];
    fetch(url, this.createInit("GET"))
      .then((response) => response.json())
      .then((data) => {
        res = data;
      });
    return res;
  }
  static getRoleUser() {
    const userData = SessionStorageService.get(SESSION_NAME);
    if (!!userData && isArray(userData.value.roles)) {
      return userData.value.roles[0];
    }
    return null;
  }

  static isUser(userid) {
    const user = this.getUser();
    return user.id === userid;
  }

  static getUser() {
    const userData = SessionStorageService.get(SESSION_NAME);
    if (!!userData) {
      return userData.value;
    }
    return null;
  }
  static getHeadquaters() {
    const userData = SessionStorageService.get(SESSION_NAME);
    if (!!userData) {
      return userData.value.headquaters;
    }
    return {};
  }
}

export default UserServices;
