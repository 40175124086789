import React from "react";
import {
  makeStyles,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
} from "@material-ui/core";
import napformat from "../../../config/napformat";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  divider: {
    fontWeight: "bold",
    backgroundColor: "#01579b30",
  },
  th: {
    border: "1px solid #E8E8E8",
    textAlign: "center",
  },
  total: {
    fontWeight: "bold",
    backgroundColor: "#01579b30",
  },
  boldTotal: {
    fontWeight: "bold",
  },
  bold: {
    fontWeight: "bold",
    border: "1px solid #E8E8E8",
  },
  card: {
    padding: 20,
  },
  border: {
    border: "1px solid #E8E8E8",
  },
}));

const TablaPResumenGastos = (props) => {
  const classes = useStyles();
  const { dataTable } = props;
  dataTable.sort((a, b) => (a.code > b.code ? 1 : -1));
  return (
    <>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.th}>CÓDIGO</TableCell>
                  <TableCell className={classes.th}>DESCRIPCIÓN</TableCell>
                  <TableCell className={classes.th}>PORCENTAJE</TableCell>
                  <TableCell className={classes.th}>TOTAL</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataTable.length > 0 &&
                  dataTable.map((row, index) => ( row.description &&
                    <>
                      <TableRow>
                        <TableCell className={classes.border} align="right">
                          {row.code}
                        </TableCell>
                        <TableCell className={classes.border} align="left">
                          {row.description}
                        </TableCell>
                        <TableCell className={classes.border} align="right">
                          {napformat(row.rate)} %
                        </TableCell>
                        <TableCell className={classes.border} align="right">
                          {napformat(row.total)}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                {dataTable.length > 0 &&
                  dataTable.map((row, index) => ( !row.description &&
                    <>
                      <TableRow className={classes.total}>
                        <TableCell className={classes.boldTotal} colSpan={2}>
                          {row.code}
                        </TableCell>
                        <TableCell align="right" className={classes.boldTotal}>
                          {napformat(row.rate)} %
                        </TableCell>
                        <TableCell align="right" className={classes.boldTotal}>
                          {napformat(row.total)}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default TablaPResumenGastos;
