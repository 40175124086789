import React, { useState, useRef, useEffect } from "react";
import { notification } from "antd";
import UserServices from "../../services/UserServices";
import { apiCuentas } from "../../config/api.contable";
import sedeactual from "../../config/sedeactual";
import SelectSedes from "../Usuarios/SelectSedes";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  FormHelperText,
} from "@material-ui/core";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { Button } from "antd";
import axios from 'axios'

const urlComunidad =
  "https://filesdic.s3.us-east-2.amazonaws.com/excel/PLANTILLA_PRESUPUESTO_COMUNIDAD.xlsx";
const urlObra =
  "https://filesdic.s3.us-east-2.amazonaws.com/excel/PLANTILLA_PRESUPUESTO_OBRAS.xlsx";

const CardImportacion = ({ isSuperAdm, sedes }) => {
  const [sede, setsede] = useState(sedeactual.get());
  const [, setState] = useState();
  const [anho] = useState(sedeactual.getAnio());
  const refInput = useRef("");
  const [loading, setLoading] = useState(false);
  const [wMode, setWMode] = useState(sedeactual.digits());

  const enviarCuenta = async () => {
    try {
      if (refInput.current.files.length < 1) {
        const key = `open${Date.now()}`;
        notification.open({
          message: "Error de envío de datos",
          description: "No ha seleccionado el archivo",
        });
        return;
      }
      setLoading(true);
      let formData = new FormData();
      formData.append("file", refInput.current.files[0]);

      const { data } = await axios.post(
        `${apiCuentas}/budget/upload/${sede}/${anho}/${sedeactual.company().url}`,
        formData,
        { headers: { Authorization: sedeactual.token() } }
      )

      if (data?.faltantes?.length > 0) {
        notification["warning"]({
          message: "Existen cuentas faltantes",
          description: data?.faltantes?.join(', ')
        });
      } else {
        notification["success"]({
          message: "El presupuesto se importó correctamente",
        });
      }
    } catch (error) {
      if (error?.response?.data?.cuentasfaltantes) {
        notification["warning"]({
          message: "Existen cuentas faltantes",
          description: error?.response?.data?.cuentasfaltantes.join(', ')
        });
      } else {
        notification["error"]({
          message: "Error de envío de datos",
          description: "Ocurrio un eror al importar el presupuesto.",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const onChangeCuenta = (e) => {
    let files = e.target.files;
    setState(files.length != 0 ? files[0].name : "");
  };

  const WorkMode = (value) => {
    let finder = sedes.find((el) => el.id === value);
    setWMode(finder.digits);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" color="textSecondary">
          Importación de Presupuesto
        </Typography>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12}>
            {isSuperAdm ? (
              <>
                <FormHelperText>SEDES</FormHelperText>
                <SelectSedes
                  defaultValue={sede}
                  data={sedes}
                  onChangeSelect={(e) => {
                    setsede(e);
                    WorkMode(e);
                  }}
                />
              </>
            ) : null}
          </Grid>
          <Grid item xs={12} md={8}>
            <input
              type="file"
              name="file"
              id="file"
              accept=".csv,.xlsm, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              ref={refInput}
              className=""
              onChange={onChangeCuenta}
            />
          </Grid>
          <Grid item xs={12} md={4} align="right">
            <Button
              type="primary"
              size="large"
              loading={loading}
              disabled={
                refInput.current && refInput.current.files.length > 0
                  ? false
                  : true
              }
              onClick={enviarCuenta}
            >
              Importar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <FormHelperText>
              <BubbleChartIcon /> Recuerda que el nombre del archivo no debe
              contener espacios.
            </FormHelperText>
            <a href={wMode === 5 ? urlComunidad : urlObra}>
              <AttachFileIcon /> Plantilla presupuesto
            </a>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CardImportacion;
