/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment, useCallback } from "react";
import useForm from "react-hook-form";
import { Modal, notification, Icon, Select, Spin } from "antd";
import SelectDoc from "../../../components/Selects/SelectDoc.jsx";
import SelectOrigen from "../../../components/Selects/SelectOrigen.jsx";
import SelectCuenta from "../../../components/Selects/SelectCuenta.jsx";
import SelectCC from "../../../components/Selects/SelectCC";
import SelectPeriodo from "../../../components/Selects/SelectPeriodo";
import numeral from "numeral";
import { Link } from "react-router-dom";
import sedeactual from "../../../config/sedeactual";
import { message } from "antd";
import ModalTipoDoc from "./ModalTipoDoc";
import TableFormVoucher from "./TableFormVoucher";
import useFocus from "../../../hook/useFocus";
import ModalValidation from "./ModalValidation";
import Page from "../../../components/Page/Page";
import {
  Card,
  FormHelperText,
  CardContent,
  Grid,
  Button,
  TextField,
  IconButton,
  Box,
  InputLabel,
  Typography,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SearchIcon from "@material-ui/icons/Search";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/es";
import { v4 as uuidv4 } from "uuid";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import {
  postRuc,
  validateVoucher,
  getAccountPlan,
  getDateExchange_rate,
  lastNumberVoucher,
  getOrigins,
  getTipoDocs,
  getAccountAssistantById,
  getTypeCoins,
} from "../../../api/api.contable";
import SelectCostFormula from "../../../components/Selects/SelectCostFormula";
import SelectTypeDoc from "src/components/Selects/SelectTypeDoc.jsx";

const useStyles = makeStyles((theme) => ({
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));

const { Option } = Select;

let cuentas5D = [];
let afectacionBarCode2 = "GRAVADA";
let origen2 = "";

const CreateCuenta = (props) => {
  const classes = useStyles();
  const [actualizarcambio, setactualizarcambio] = useState(false);
  const [focus, setfocus] = useState(true);
  const [modalvalidation, setmodalvalidation] = useState({ isOpen: false, data: null });
  const [sumaTotal, setsumaTotal] = useState(0);
  let yeartrabajo = sedeactual.getAnio();
  const [dataCuentas, setdataCuentas] = useState([]);
  const [modalTipo, setModalTipo] = useState(false);
  const [correlativo, setCorrelativo] = useState("");
  const [dataTipos, setDataTipos] = useState([]);
  const [TipodeCambio, setTipodeCambio] = useState([]);
  const [isVisible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [validationcuenta, setValidationcuenta] = useState(false);
  const [cuenta5d, setCuentas5d] = useState([]);
  const { handleSubmit, register, errors } = useForm();
  //ESTADOS VOUCHER
  const [costo, setcosto] = useState(null);
  const [date, setDate] = useState("");
  const [dateMui, setdateMui] = useState(null);
  const [type, setType] = useState(null);
  const [origen, setOrigen] = useState(null);
  const [numDoc, setnumDoc] = useState("");
  const [inputRef, setInputFocus] = useFocus(true);
  const [exchange_Rate, setexchange_Rate] = useState();
  //ESTADOS MODAL
  const [visibleCambio, setVisibleCambio] = useState(false);

  const [cuenta, setCuenta] = useState(null);
  const [infoCuenta, setInfoCuenta] = useState(null);

  const [glosa, setGlosa] = useState("");
  const [cc, setCC] = useState(null);
  const [moneda, setMoneda] = useState(1);
  const [periodo, setPeriodo] = useState(props.edit ? props.data.period : moment(Date.now()).month() + 1);
  const [returnPeriod] = useState(props.edit ? props.data.period : moment(Date.now()).month() + 1);
  const [tipo, setTipo] = useState("DEBE");
  const [tipoDocM, setTipoDocM] = useState("");
  const [monto, setMonto] = useState("");
  const [numCom, setnumCom] = useState("-");
  const [afectacion, setAfectacion] = useState("GRAVADA");
  //SET MODAL
  const [costoEdit, setcostoEdit] = useState();
  const [cuentaEdit, setcuentaEdit] = useState();
  const [tipodocEdit, settipodocEdit] = useState("");
  const [monedaEdit, setmonedaEdit] = useState("");
  const [montoEdit, setmontoEdit] = useState("");
  const [tipoEdit, settipoEdit] = useState("");
  const [indexEdit, setindexEdit] = useState();
  const [glosaEdit, setGlosaEdit] = useState("");
  const [ccEdit, setccEdit] = useState(null);
  const [origins, setOrigins] = useState([]);
  //MODAL EDIT
  const [detail_id_Edit, setdetail_id_Edit] = useState("");
  const [modalEdit, setmodalEdit] = useState(false);
  const [CambiodelDia, setCambiodelDia] = useState(1);
  const [datacc, setdatacc] = useState([]);
  // CREDIT NOTE
  const [customer, setCustomer] = useState({
    ruc: "",
    nombre_o_razon_social: "",
    t_doc: "RUC",
  });
  const [creditNote, setCreditNote] = useState({
    emitionDate: null,
    typeDoc: "",
    serie: "",
    correlative: "",
  });
  // APROVICIONES
  const [isAprovicion, setIsAprovicion] = useState({
    provisioning: false,
    type_provisioning: null,
    number: null,
    initial: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  // QR CODE
  const [afectacionBarCode, setAfectacionBarCode] = useState("GRAVADA");

  useEffect(() => {
    obtenerPlan();
    getOriginsData();
    obtainDocTypes();
    obtainCoinType();
  }, []);

  const changeCN = (key, value) => {
    creditNote[key] = value;
    setCreditNote({ ...creditNote });
  };

  const getAuxAccountList = async (cuenta_id) => {
    let res = await getAccountAssistantById(cuenta_id);
    setdatacc(res.data);
  };

  useEffect(() => {
    if (props.edit) {
      if (cuentaEdit){
        getAuxAccountList(cuentaEdit);
        getInfoCuenta(cuentaEdit);
      }
    } else {
      if (cuenta){
        getAuxAccountList(cuenta);
        getInfoCuenta(cuenta);
      }
    }
  }, [cuenta, cuentaEdit]);

  const getOriginsData = async () => {
    const data = await getOrigins();
    setOrigins(data);
  };

  const obtainDocTypes = async () => {
    try {
      let result = await getTipoDocs();
      setDataTipos(result);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se pudo obtener los tipos de documentos.",
      });
    }
  };

  const obtainCoinType = async () => {
    try {
      let result = await getTypeCoins();
      setTipodeCambio(result);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se pudo obtener los tipos de monedas.",
      });
    }
  };

  const obtenerPlan = async () => {
    try {
      setIsLoading(true);
      let res = await getAccountPlan(sedeactual.get(), sedeactual.getAnio());
      const data = res.filter((account) => account.number > Math.pow(10, sedeactual.digits() - 1) - 1);
      setdataCuentas(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getExchange_Rate = async () => {
    let Date_rate = `${date.substr(6, 4)}-${date.substr(3, 2)}-${date.substr(0, 2)}`;
    if (!(Date_rate.includes("_") || Date_rate == "--" || Date_rate == "")) {
      let res = await getDateExchange_rate(Date_rate);
      setexchange_Rate(res);
    }
  };

  useEffect(() => {
    getExchange_Rate();
  }, [actualizarcambio]);

  useEffect(() => {
    let found = cuenta5d.find((e) => e._id === cuenta);
    if (found && found.cost) {
      if (found.cost.length == 1) {
        setcosto("0" + found.cost);
      } else {
        setcosto(found.cost);
      }
    } else {
      setcosto(null);
    }

    if (found) {
      setIsAprovicion({
        ...isAprovicion,
        number: found.number,
      });
    }
  }, [cuenta]);

  useEffect(() => {
    const result = dataCuentas.filter((e) => e.number < 900000);
    setCuentas5d(result);
  }, [dataCuentas]);

  useEffect(() => {
    setInputFocus({ ...true });
  }, [inputRef, props.nVoucher]);

  const getLastNumberVoucher = async () => {
    let res = await lastNumberVoucher(periodo);
    setnumCom(res.data);
  };

  useEffect(() => {
    if (props.edit == false) {
      getLastNumberVoucher();
    }
  }, [periodo, props.nVoucher]);

  function updateMonto(e) {
    if (Number(e.target.value) <= 999999999999) {
      setMonto(e.target.value);
    }
  }

  function updateCorrelativo(e) {
    if (e.target.value.length < 16 && e.target.value >= 0) {
      setCorrelativo(e.target.value);
    }
  }

  function updateTipo(e) {
    setTipo(e.target.value);
  }

  function updateMoneda(e) {
    if (Number(e.target.value) === 2) {
      setVisibleCambio(true);
      let found = cuenta5d.find((e) => e._id == cuenta);
      if (found.number.toString().startsWith("7")) {
        setCambiodelDia(exchange_Rate.precio_compra);
      } else {
        setCambiodelDia(exchange_Rate.precio_venta);
      }
    } else {
      setVisibleCambio(false);
      setCambiodelDia(1);
    }
    if (props.edit == true) {
      setmonedaEdit(e.target.value);
    } else {
      setMoneda(e.target.value);
    }
  }

  async function validarDoc() {
    if (customer.t_doc && customer.ruc) {
      let tDoc = customer.t_doc.toLowerCase();
      let nDoc = customer.ruc;
      if ((tDoc === "ruc" && nDoc.length === 11) || (tDoc === "dni" && nDoc.length === 8)) {
        try {
          const res = await postRuc(nDoc, tDoc);
          setCustomer({
            ...customer,
            nombre_o_razon_social: res.nombre_o_razon_social ?? res.nombre,
          });
        } catch (error) {
          notification["warning"]({ message: `${tDoc.toUpperCase()} NO ENCONTRADO` });
          return;
        }
      }
    }

    if (numDoc !== "" && correlativo !== "" && props.edit === false) {
      let res = await validateVoucher(customer.ruc, numDoc, correlativo, type);

      if (res.status) {
        setmodalvalidation({ isOpen: true, data: res.voucher });
      } else {
        showModal();
      }
    } else {
      showModal();
    }
  }

  //SET VOUCHER
  function updatenumDoc(e) {
    setnumDoc(e.target.value.toString().toUpperCase());
  }

  const Dataform =async () => {
    if (data.length > 0 && sumaTotal > -0.01 && sumaTotal < 0.01) {
      let mesp = periodo.toString().length == 1 ? `0${periodo}` : periodo;
      let anop = sedeactual.getAnio();
      let fechacompleta;
      if (date !== null) {
        let dia = date.substr(0, 2);
        let mes = date.substr(3, 2);
        let año = date.substr(6, 4);
        fechacompleta = `${año}-${mes}-${dia}`;
      } else {
        fechacompleta = "";
      }

      const arreglado = data.map((e) => {
        return {
          account: e.cuenta,
          comment: e.glosa,
          cost: e.cost,
          aux_account: e.aux_account,
          type_currency: e.moneda,
          period: Number(mesp),
          ref_payment: e.ref_payment,
          exchange_rate: e.tipoCambio,
          tipoDocumento: e.tipoDocumento,
          type_detail: e.tipo,
          amount: numeral(e.monto).value(),
          periodo: `${anop}-${mesp}-01`,
          afectacion: e.afectacion,
        };
      });
      // setData([...arreglado]);

      let voucher = {
        headquarters_id: sedeactual.get(),
        voucher_id: props.id,
        origin: origen,
        numComprobante: "",
        operation_date: fechacompleta,
        t_doc: type,
        serie: numDoc,
        period: Number(mesp),
        year: Number(anop),
        correlative: correlativo,
        customer,
        details: arreglado,
        creditNote,
      };

      if (isAprovicion.provisioning) {
        voucher = { ...voucher, ...isAprovicion };
      }

      if (voucher.operation_date.includes("_") || voucher.operation_date == "--" || voucher.operation_date == "") {
        voucher.operation_date = null;
      }

      const voucherCreated =await  props.sendFormData(voucher);
      if (voucherCreated.status) {
        setmodalvalidation({ isOpen: true, data: voucherCreated.voucher });
      } else {
        if (props.edit == false) {
          limpiarvoucher();
          setData([])
          setInputFocus({ ...true });
        }
      }
    } else {
      notification.open({
        message: "Error al crear Voucher",
        description: "La suma total no es 0 o no hay registros de cuenta",
        icon: <Icon type="close" style={{ color: "#FE2E27" }} />,
        key: `open${Date.now()}`,
      });
    }

    setMonto(0);
  };

  function limpiarvoucher() {
    setData([]);
    setDate("");
    setdateMui(null);
    setCustomer({ ruc: "", nombre_o_razon_social: "", t_doc: "DNI" });
    setType(null);
    setnumDoc("");
    setGlosa("");
    setCorrelativo("");
    setTipoDocM("");
  }

  function LimpiarModal() {
    setGlosa("");
    setCuenta(null);
    setMonto("");
    setTipoDocM("");
    setCC(null);
  }

  function seleccionarTipo(code) {
    setType(code);
    setModalTipo(false);
  }

  async function cancelEnter() {
    let tdoc = customer.t_doc.toLowerCase();
    let ndoc = customer.ruc;

    if ((tdoc === "ruc" && ndoc.length === 11) || (tdoc === "dni" && ndoc.length === 8)) {
      try {
        const res = await postRuc(ndoc, tdoc);
        setCustomer({
          ...customer,
          nombre_o_razon_social: res.nombre_o_razon_social ?? res.nombre,
        });
      } catch (error) {
        notification["warning"]({ message: `${tdoc.toUpperCase()} NO ENCONTRADO` });
      }
    } else {
      notification["info"]({
        message: `${tdoc.toUpperCase()} INVÁLIDO.`,
        description:
          "Recuerda que solo se permite busquedas de DNI (8 dígitos) y RUC (11 dígitos). Los demás tipos de documento se realizan por registro manual.",
      });
    }
  }

  function handleChange(date) {
    setdateMui(date);
    if (date != "Invalid Date" && date != null) {
      var date = moment(date).format("DD-MM-YYYY");
      setDate(date);
      setactualizarcambio({ ...true });
    }
  }

  function ChangePeriodo(event) {
    let value = "";
    if (event > 9) value = `${event}-${yeartrabajo}`;
    else value = `0${event}-${yeartrabajo}`;

    setPeriodo(event);
  }

  function optionsTipo() {
    return TipodeCambio.map((cliente, index) => {
      const { id, num, alp, currency } = cliente;
      return (
        <option key={id} value={id}>
          {alp}-{currency}
        </option>
      );
    });
  }

  function EditModal(index) {
    getAuxAccountList(data[index].cuenta);
    setcuentaEdit(data[index].cuenta);
    settipodocEdit(data[index].ref_payment ? data[index].ref_payment : "");
    setmontoEdit(data[index].monto);
    setcostoEdit(data[index].cost);
    settipoEdit(data[index].tipo);
    setmonedaEdit(data[index].moneda);
    setCambiodelDia(data[index].tipoCambio);
    setindexEdit(index);
    setccEdit(data[index].aux_account);
    setdetail_id_Edit(data[index].id);
    setGlosaEdit(data[index].glosa);
    setAfectacion(data[index].afectacion);
    setmodalEdit(true);

    getInfoCuenta(data[index].cuenta);
  }

  function actualizarcuentas(e) {
    let body = {
      id: detail_id_Edit,
      aux_account: ccEdit,
      glosa: glosaEdit,
      cuenta: cuentaEdit,
      tipoDocumento: tipodocEdit,
      monto: (Math.round(montoEdit * 100) / 100).toFixed(2),
      tipoCambio: CambiodelDia,
      tipo: tipoEdit,
      moneda: monedaEdit,
      cost: costoEdit,
      ref_payment: tipodocEdit,
      afectacion,
    };
    data[indexEdit] = body;
    setData([...data]);
    setmodalEdit(false);
  }

  function showModal() {
    setTipo("DEBE");
    if (
      ((true && (type === 1 || type === 12 || type === 17 || type === 16 || type === 42 || type === 43)) ||
        (customer.ruc && (customer.ruc.length === 11 || customer.ruc.length === 8) && type !== 1)) &&
      origen &&
      periodo &&
      !date.toString().includes("_") &&
      ((date &&
        ((numDoc && correlativo) ||
          type === 1 ||
          type === 2 ||
          type === 3 ||
          type === 4 ||
          type === 13 ||
          type === 17 ||
          type === 12)) ||
        origen === 2 ||
        origen === 3)
    ) {
      setfocus({ ...true });
      setVisible(true);
    } else {
      notification["warning"]({
        message: "CAMPOS VACIOS",
        description: "Completar campos para agregar detalles de cuenta.",
      });
    }
  }

  function handleOk(e, f) {
    if (monto && glosa) {
      setfocus({ ...true });

      let mes = periodo.toString().length == 1 ? `0${periodo}` : periodo;
      let año = sedeactual.getAnio();
      let valido = true;
      let cuentaNueva = {
        id: uuidv4(),
        cuenta: cuenta,
        glosa: glosa,
        aux_account: cc,
        moneda: moneda,
        tipoCambio: CambiodelDia,
        tipoDocumento: tipoDocM,
        ref_payment: tipoDocM,
        periodo: `${año}-${mes}-01`,
        month: mes,
        tipo: tipo,
        cost: costo,
        monto: (Math.round(monto * 100) / 100).toFixed(2),
      };

      if (
        (origen === 9 && String(infoCuenta?.number)[0] === "7") ||
        (origen === 6 && String(infoCuenta?.number)[0] === "6")
      ) {
        cuentaNueva.afectacion = afectacion;
      }

      setValidationcuenta(false);
      if (cuentaNueva.cuenta == null) {
        setValidationcuenta(true);
      }
      const cuentasRepetidas = cuenta5d.filter(
        (e) =>
          e.number.toString().startsWith("1") ||
          e.number.toString().startsWith("2") ||
          e.number.toString().startsWith("3") ||
          e.number.toString().startsWith("4") ||
          e.number.toString().startsWith("5") ||
          e.number.toString().startsWith("6") ||
          e.number.toString().startsWith("7") ||
          e.number.toString().startsWith("9") ||
          e.number.toString().startsWith("8")
      );
      const cuentaID = cuentasRepetidas.filter((e) => e._id == cuentaNueva.cuenta);
      data.forEach((d) => {
        if (cuentaNueva.cuenta == d.cuenta && cuentaID.length == 0) {
          message.error("La cuenta ya fue ingresada");
          valido = false;
        }
      });

      if (valido && cuentaNueva.monto >= 0 && cuentaNueva.cuenta != null && cuentaNueva.cuenta != "" && periodo) {
        let auxiliarData = data;
        auxiliarData.push(cuentaNueva);
        setData(auxiliarData);
        setCuenta(null);
        setCC(null);
        tipo == "DEBE" ? setTipo("HABER") : setTipo("DEBE");

        setMonto("");
        //setTipoDocM("")

        f.target.reset();

        if (
          isAprovicion.number.toString().startsWith("12") ||
          // isAprovicion.number.toString().startsWith("14") ||
          isAprovicion.number.toString().startsWith("16")
        ) {
          setIsAprovicion({
            provisioning: true,
            type_provisioning: "DEBE",
            number: isAprovicion.number,
            initial: true,
          });
        } else if (
          isAprovicion.number.toString().startsWith("41") ||
          isAprovicion.number.toString().startsWith("42") ||
          isAprovicion.number.toString().startsWith("46")
        ) {
          setIsAprovicion({
            provisioning: true,
            type_provisioning: "HABER",
            number: isAprovicion.number,
            initial: true,
          });
        }
      }
    } else {
      notification["info"]({ message: "La glosa y el monto son requeridos." });
    }
  }

  function handleCancel(e) {
    setVisible(false);
  }

  useEffect(() => {
    if (props.edit) {
      let date = props.data.operation_date;

      if (date != null) {
        let dia = date.substr(8, 2);
        let mes = date.substr(5, 2);
        let año = date.substr(0, 4);
        date = `${dia}-${mes}-${año}`;
      }
      if (date == "") {
        date = null;
      }

      let typeProv = props.data.type_provisioning;
      let value = 0;
      let valDebe = 0;
      let valHaber = 0;

      props.data.details.forEach((item) => {
        const amount = item.amount * item.exchange_rate;

        if (typeProv && typeProv === item.type_detail) {
          value += amount;
        }
        if (item.type_detail === "DEBE") valDebe += amount;
        else valHaber += amount;
      });

      if (valDebe.toFixed(2) === valHaber.toFixed(2)) typeProv = null;

      let arreglado = props.data.details.map((item) => {
        if (item.period < 10) {
          item.period = `0${item.period}`;
        }
        return {
          id: item._id,
          cuenta: item.account?._id ?? null,
          glosa: item.comment,
          aux_account: item.aux_account,
          moneda: item.type_currency,
          tipoDocumento: item.t_doc,
          tipoCambio: item.exchange_rate,
          tipo: item.type_detail,
          ref_payment: item.ref_payment,
          monto: typeProv ? (typeProv === item.type_detail ? item.amount : value) : item.amount,
          year: item.year,
          cost: item.cost,
          period: item.period,
          afectacion: item.afectacion,
        };
      });

      var periodoo = Number(arreglado[0].period);

      setnumCom(props.data.n_voucher);
      setType(props.data.t_doc);
      setnumDoc(props.data.serie?.toUpperCase());
      setData(arreglado);

      setCreditNote({ ...props.data.creditNote });

      if (props.data.customer != null) {
        if (typeof props.data.customer === "object") {
          setCustomer({
            ruc: props.data.customer.ruc,
            nombre_o_razon_social: props.data.customer.nombre_o_razon_social,
            t_doc: props.data.customer.t_doc,
          });
        } else {
          setCustomer({
            ruc: props.data.ruc,
            nombre_o_razon_social: props.data.customer,
            t_doc: props.data.ruc.toString().length === 8 ? "DNI" : "RUC",
          });
        }
      }

      setCorrelativo(props.data.correlative);
      if (date != null) {
        setDate(date);
        setdateMui(new Date(moment(date, "DD-MM-YYYY")));
      } else {
        setDate("");
        setdateMui(null);
      }
      setactualizarcambio({ ...true });
      setPeriodo(periodoo);

      setOrigen(props.data.origin);
    }
  }, [props.data]);

  useEffect(() => {
    if (datacc.length > 0 && cc) {
      let finder = datacc.find((ev) => ev._id === cc);
      if (finder) {
        setGlosa(finder.description);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cc]);

  // =========================== BarCode Reader =========================== //
  // [0] ruc
  // [1] tipo Documento
  // [2] serie
  // [3] correlativo
  // [4] igv
  // [5] total
  // [6] fecha
  // [7] tipo de Documento comprador
  // [8] numero de documento comprador

  const barcode = {
    timing: 1000,
    data: "",
  };

  cuentas5D = cuenta5d;
  afectacionBarCode2 = afectacionBarCode;
  origen2 = origen;
  async function barcodeReader() {
    if (barcode.data.length > 1) {
      let codigo = "";

      if (barcode.data.includes("Enter")) codigo = barcode.data.slice(0, barcode.data.length - 5);
      else codigo = barcode.data;

      if (codigo.includes("]")) {
        let arrayCodigo = [];
        arrayCodigo = codigo.split("]");
        arrayCodigo = arrayCodigo.filter((item) => item !== "");

        if (!origen2) setOrigen(6); // 6 -> COMPRAS

        setType(Number(arrayCodigo[1]) + 1);

        let tempNdoc = "";
        if (arrayCodigo[7] === "6" || arrayCodigo[7] === "06") tempNdoc = "RUC";
        else tempNdoc = "DNI";

        const fecha = moment(arrayCodigo[6]).toDate();

        handleChange(fecha);

        setMonto(Number(arrayCodigo[5]));

        setCustomer({
          ...customer,
          ruc: arrayCodigo[0],
          t_doc: tempNdoc,
        });
        setnumDoc(arrayCodigo[2]?.toUpperCase());
        setCorrelativo(arrayCodigo[3]);

        let res = await validateVoucher(arrayCodigo[0], arrayCodigo[2], arrayCodigo[3], Number(arrayCodigo[1]) + 1);

        let isValid = true;
        if (res.status === true) {
          setmodalvalidation({ isOpen: true, data: res.voucher });
          isValid = false;
        }
        if (!isValid) return;

        const cuenta60 = cuentas5D.find((ev) => ev.number === 603205);
        const cuenta40 = cuentas5D.find((ev) => ev.number === 401111);
        const cuenta10 = cuentas5D.find((ev) => ev.number === 101011);

        const igv = Number(arrayCodigo[4]);
        const total = Number(arrayCodigo[5]);

        const dataToSend = [
          {
            aux_account: null,
            cost: "04",
            cuenta: cuenta60?._id,
            glosa: "COMPRAS",
            id: uuidv4(),
            moneda: 1,
            month: "06",
            afectacion: afectacionBarCode2,
            monto: total - igv,
            periodo: "2022-06-01",
            ref_payment: "",
            tipo: "DEBE",
            tipoCambio: 1,
            tipoDocumento: "",
          },
          {
            aux_account: null,
            cost: "04",
            cuenta: cuenta40?._id,
            glosa: "COMPRAS",
            id: uuidv4(),
            moneda: 1,
            month: "06",
            monto: igv,
            periodo: "2022-06-01",
            ref_payment: "",
            tipo: "DEBE",
            tipoCambio: 1,
            tipoDocumento: "",
          },
          {
            aux_account: null,
            cost: "04",
            cuenta: cuenta10?._id,
            glosa: "COMPRAS",
            id: uuidv4(),
            moneda: 1,
            month: "06",
            monto: total,
            periodo: "2022-06-01",
            ref_payment: "",
            tipo: "HABER",
            tipoCambio: 1,
            tipoDocumento: "",
          },
        ];
        if (afectacionBarCode2 === "INAFECTA" || afectacionBarCode2 === "EXONERADA") {
          dataToSend[0].monto = total;
          dataToSend.splice(1, 1);
        }
        setData(dataToSend);
      }
      barcode.data = "";
    } else {
      barcode.data = "";
    }
  }

  let timeout = setTimeout(barcodeReader, 500);

  const keyPressEvent = useCallback((e) => {
    if (barcode.data.length === 0 || e.timeStamp - barcode.timing < 100) {
      barcode.data += e.key;
      barcode.timing = e.timeStamp;
      clearTimeout(timeout);
      timeout = setTimeout(barcodeReader, 500);
    } else barcode.data = "";
  }, []);

  useEffect(() => {
    window.addEventListener("keypress", keyPressEvent);

    return () => window.removeEventListener("keypress", keyPressEvent);
  }, [keyPressEvent]);
  // =========================== BarCode Reader =========================== //

  useEffect(() => {
    if (
      (customer.t_doc === "DNI" && customer.ruc.toString().length === 8) ||
      (customer.t_doc === "RUC" && customer.ruc.toString().length === 11)
    ) {
      cancelEnter();
    }
  }, [customer.ruc]); // eslint-disable-line

  const getInfoCuenta = (id) => {
    const cuenta = cuentas5D.find((ev) => {
      return ev._id === id;
    });

    setInfoCuenta(cuenta);
  };

  return (
    <Page
      className={classes.root}
      helper="VOUCHER"
      title={props.edit === false ? "CREAR VOUCHER" : "EDITAR VOUCHER"}
      itemComponent={
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              to={`/vouchers${props.edit ? `?period=${returnPeriod}` : ""}`}
              size="large"
            >
              <ArrowBackIosIcon />
              Volver
            </Button>
          </Grid>
          <Grid item>
            <Card>
              <CardContent style={{ padding: 10 }}>
                <TextField
                  disabled
                  type="Number"
                  value={props.edit ? numCom : numCom + 1}
                  variant="outlined"
                  label={props.edit ? "VOUCHER" : "VOUCHER ACTUAL"}
                  style={{ width: 140 }}
                  size="small"
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <FormHelperText>AFECTACION QR</FormHelperText>
            <Select
              onChange={(e) => setAfectacionBarCode(e)}
              size="large"
              style={{ minWidth: "250px" }}
              value={afectacionBarCode}
            >
              <Option value="GRAVADA">GRAVADA</Option>
              <Option value="EXONERADA">EXONERADA</Option>
              <Option value="INAFECTA">INAFECTA</Option>
              {origen === 6 && <Option value="IMPORTACION">IMPORTACIÓN</Option>}
              {origen === 9 && <Option value="EXPORTACION">EXPORTACIÓN</Option>}
            </Select>
          </Grid>
        </Grid>
      }
    >
      <Spin spinning={isLoading}>
        {modalvalidation.isOpen && <ModalValidation data={modalvalidation.data} setmodalvalidation={setmodalvalidation} />}
        <Grid container spacing={2} justify="flex-end">
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormHelperText>ORIGEN</FormHelperText>
                    <SelectOrigen
                      // ref={inputRef}
                      defaultValue={origen}
                      onChangeSelect={(e) => setOrigen(e)}
                      data={origins}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormHelperText>PERIODO</FormHelperText>
                    {periodo && (
                      <SelectPeriodo
                        edit={props.edit}
                        defaultValue={periodo}
                        onChangeSelect={(e) => ChangePeriodo(e)}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormHelperText>
                      {moment(`${new Date().getFullYear()}-${periodo}-${new Date().getDate()}`)
                        .utc(false)
                        .format("YYYY-MM-DD") !== moment(dateMui).utc(false).format("YYYY-MM-DD") ? (
                        <span style={{ color: "red" }}>LA FECHA ES DIFERENTE AL PERIODO. Puede continuar...</span>
                      ) : (
                        "FECHA DE OPERACIÓN"
                      )}
                    </FormHelperText>
                    <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        inputRef={inputRef}
                        inputVariant="outlined"
                        size="small"
                        format="dd/MM/yyyy"
                        margin="normal"
                        style={{ margin: 0 }}
                        maxDate={`${yeartrabajo + 1}-01-01`}
                        onChange={handleChange}
                        value={dateMui}
                        fullWidth
                        placeholder="DD/MM/YYYY"
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        maxDateMessage={`El rango máximo de fecha es hasta el 31 diciembre del ${yeartrabajo}`}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormHelperText>TIPO DOCUMENTO</FormHelperText>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "calc((100%) - 40px)" }}>
                        <SelectDoc
                          defaultValue={type}
                          onChangeSelect={(e) => {
                            setType(e);
                            setCustomer({
                              ruc: "",
                              nombre_o_razon_social: "",
                              t_doc: type === 1 || type === 6 || type === 4 ? "DNI" : "RUC",
                            });
                          }}
                          data={dataTipos}
                        />
                      </div>
                      <div style={{ width: 40, height: 40 }}>
                        <IconButton
                          onClick={() => {
                            setModalTipo(true);
                          }}
                          color="primary"
                        >
                          <SearchIcon />
                        </IconButton>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormHelperText />
                    <TextField
                      label="SERIE"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={numDoc}
                      onChange={updatenumDoc}
                      inputProps={{ maxLength: 4 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormHelperText />
                    <TextField
                      label="N° COMPROBANTE"
                      variant="outlined"
                      fullWidth
                      size="small"
                      onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                      value={correlativo}
                      onChange={updateCorrelativo}
                      type="number"
                    />
                  </Grid>

                  {type === 6 && (
                    <Grid item xs={12}>
                      <Box p={1} component="fieldset" style={{ border: "1px solid #ccc" }}>
                        <InputLabel component="legend" style={{ width: "auto" }}>
                          <Typography color="textSecondary" variant="body2">
                            DATOS DEL COMPROBANTE RELACIONADO
                          </Typography>
                        </InputLabel>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={3}>
                            <FormHelperText>FECHA DE EMISIÓN</FormHelperText>
                            <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                inputRef={inputRef}
                                inputVariant="outlined"
                                size="small"
                                format="dd/MM/yyyy"
                                margin="normal"
                                style={{ margin: 0 }}
                                onChange={(event) => changeCN("emitionDate", moment(event).format())}
                                value={creditNote.emitionDate}
                                fullWidth
                                placeholder="DD/MM/YYYY"
                                KeyboardButtonProps={{ "aria-label": "change date" }}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <FormHelperText>TIPO DOCUMENTO</FormHelperText>
                            <SelectDoc
                              defaultValue={creditNote.typeDoc}
                              onChangeSelect={(event) => changeCN("typeDoc", event)}
                              data={dataTipos}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <FormHelperText>SERIE</FormHelperText>
                            <TextField
                              label={false}
                              variant="outlined"
                              fullWidth
                              size="small"
                              value={creditNote.serie}
                              onChange={(event) => changeCN("serie", event.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <FormHelperText>CORRELATIVO</FormHelperText>
                            <TextField
                              label={false}
                              variant="outlined"
                              fullWidth
                              size="small"
                              value={creditNote.correlative}
                              onChange={(event) => changeCN("correlative", event.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  )}

                  {/* {type === 1 || type === 6 || type === 4 || (origen === 9 && type === 4) ? ( */}
                  <Fragment>
                    <Grid item xs={12} sm={4} md={3}>
                      <SelectTypeDoc
                        document={customer.t_doc}
                        setDocument={(e) => {
                          setCustomer({ ...customer, t_doc: e, nombre_o_razon_social: "" });
                        }}
                        filterBy={type === 1 || type === 6 || type === 4 ? null : "RUC"}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                      <TextField
                        label={`${"N° DOCUMENTO"} (digite y presione Enter)`}
                        variant="outlined"
                        fullWidth
                        size="small"
                        onKeyUp={(evt) => evt.key === "Enter" && cancelEnter()}
                        value={customer.ruc}
                        onChange={(e) => setCustomer({ ...customer, ruc: e.target.value, nombre_o_razon_social: "" })}
                        type="number"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={6}>
                      <TextField
                        label="CLIENTE"
                        variant="outlined"
                        fullWidth
                        size="small"
                        disabled={customer.t_doc === "DNI" || customer.t_doc === "RUC"}
                        value={customer.nombre_o_razon_social}
                        onChange={(e) => setCustomer({ ...customer, nombre_o_razon_social: e.target.value })}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Fragment>
                  <Grid item xs={12}>
                    <Button variant="outlined" color="primary" onClick={validarDoc}>
                      AGREGAR
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <TableFormVoucher
                      setsumaTotal={(e) => setsumaTotal(e)}
                      TipodeCambio={TipodeCambio}
                      EditModal={(e) => EditModal(e)}
                      data={data}
                      setData={(e) => setData(e)}
                      sumaTotal={sumaTotal}
                      cuenta5d={cuenta5d}
                      edit={props.edit}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={Dataform} size="large">
                      GRABAR
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* MODALES PARA AGREGAR Y EDITAR */}
        <div>
          <Modal title="AGREGAR" closable={false} visible={isVisible} footer={[]}>
            <form autoComplete="off" onSubmit={handleSubmit(handleOk)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormHelperText error={validationcuenta}>
                    {validationcuenta ? "CUENTA INVÁLIDA" : "CUENTA"}
                  </FormHelperText>
                  <SelectCuenta
                    caja={true}
                    defaultValue={cuenta}
                    onChangeSelect={(e) => {
                      console.log(e);
                      setCuenta(e);
                      setcuentaEdit(e); // solo para prueba
                      setCC(null);
                      setValidationcuenta(false);
                    }}
                    data={cuenta5d}
                    focus={focus}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormHelperText>FORMULA DE COSTOS</FormHelperText>
                  <SelectCostFormula
                    defaultValue={costo}
                    onChangeSelect={(e) => {
                      setcosto(e);
                    }}
                  />
                </Grid>
                {((origen === 9 && String(infoCuenta?.number)[0] === "7") ||
                  (origen === 6 && String(infoCuenta?.number)[0] === "6")) && (
                  <Grid item xs={12}>
                    <FormHelperText>Tipo de Afectación</FormHelperText>
                    <Select value={afectacion} onChange={(e) => setAfectacion(e)}>
                      <Option value="GRAVADA">GRAVADA</Option>
                      <Option value="EXONERADA">EXONERADA</Option>
                      <Option value="INAFECTA">INAFECTA</Option>
                      {origen === 6 && <Option value="IMPORTACION">IMPORTACIÓN</Option>}
                      {origen === 9 && <Option value="EXPORTACION">EXPORTACIÓN</Option>}
                    </Select>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <FormHelperText error={errors.cc ? true : false}>
                    {errors.cc ? errors.cc.message : "AUXILIAR DE CUENTAS"}
                  </FormHelperText>
                  <SelectCC
                    defaultValue={cc}
                    onChangeSelect={(e) => {
                      setCC(e);
                    }}
                    data={datacc}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormHelperText>REF. M. PAGO</FormHelperText>
                  <TextField
                    value={tipoDocM}
                    variant="outlined"
                    size="small"
                    onKeyDown={(evt) => evt.key === "Enter" && evt.preventDefault()}
                    onChange={(e) => {
                      setTipoDocM(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormHelperText error={glosa ? false : true}>
                    {glosa ? "GLOSA" : "LA GLOSA ES REQUERIDA"}
                  </FormHelperText>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={glosa}
                    onKeyDown={(evt) => evt.key === "Enter" && evt.preventDefault()}
                    inputProps={{
                      maxLength: 255,
                    }}
                    onChange={(e) => {
                      setGlosa(e.target.value);
                    }}
                  />
                  {/* <input
                  defaultValue={glosa}
                  className="form-control"
                  type="text"
                  onKeyDown={(evt) =>
                    evt.key === "Enter" && evt.preventDefault()
                  }
                  maxLength="255"
                  name="glosa"
                  onChange={(e) => {
                    setGlosa(e.target.value);
                  }}
                  ref={register({
                    required: "GLOSA ES REQUERIDO",
                  })}
                /> */}
                </Grid>
              </Grid>
              <div className="row">
                {cuenta && (
                  <div className="col-6">
                    <label htmlFor="">MONEDA</label>
                    <select value={moneda} onChange={updateMoneda} className="form-control">
                      {optionsTipo()}
                    </select>
                  </div>
                )}
                {visibleCambio ? (
                  <div className="col-6">
                    <label htmlFor="">TIPO DE CAMBIO</label>
                    <input disabled className="form-control" type="text" value={CambiodelDia} />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="row">
                <div className="col-6">
                  <label htmlFor="">TIPO</label>
                  <select value={tipo} onChange={updateTipo} className="form-control">
                    <option value={"DEBE"}>DEBE</option>
                    <option value={"HABER"}>HABER</option>
                  </select>
                </div>
                <div className="col-6">
                  <label htmlFor="">MONTO</label>
                  <input value={monto} onChange={updateMonto} className="form-control" type="text" name="\*d" />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-3">
                  <button className="btn btn-primary" type="submit">
                    Agregar
                  </button>
                </div>
                <div className="col-3">
                  <button
                    type="button"
                    onClick={() => {
                      handleCancel();
                    }}
                    className="btn btn-success"
                  >
                    Terminar
                  </button>
                </div>
                <div className="col-3">
                  <button
                    type="button"
                    onClick={() => {
                      LimpiarModal();
                    }}
                    className="btn btn-warning"
                  >
                    Limpiar
                  </button>
                </div>
                <div className="col-3">
                  <button
                    type="button"
                    onClick={() => {
                      setVisible(false);
                      Dataform();
                    }}
                    className="btn btn-info"
                  >
                    Grabar
                  </button>
                </div>
              </div>
            </form>
          </Modal>

          <Modal
            title="Editar"
            onCancel={() => {
              setmodalEdit(false);
            }}
            visible={modalEdit}
            footer={[]}
            closable={false}
          >
            <Spin spinning={isLoading}>
              <form autoComplete="off">
                <div className="row">
                  <div className="col-9">
                    <label htmlFor="">CUENTA</label>
                    <SelectCuenta defaultValue={cuentaEdit} onChangeSelect={(e) => setcuentaEdit(e)} data={cuenta5d} />
                    {validationcuenta == true ? <p className="text-danger"> Cuenta invalida</p> : <p></p>}
                  </div>{" "}
                  <div className="col-9">
                    <FormHelperText>FORMULA DE COSTOS</FormHelperText>
                    <SelectCostFormula
                      defaultValue={costoEdit}
                      onChangeSelect={(e) => {
                        setcostoEdit(e);
                      }}
                    />
                    {validationcuenta == true ? <p className="text-danger"> Cuenta invalida</p> : <p></p>}
                  </div>{" "}
                  {((origen === 9 && String(infoCuenta?.number).startsWith("7")) ||
                    (origen === 6 && String(infoCuenta?.number).startsWith("6"))) && (
                    <div className="col-9">
                      <FormHelperText>Tipo de Afectación</FormHelperText>
                      <Select value={afectacion} onChange={(e) => setAfectacion(e)}>
                        <Option value="GRAVADA">GRAVADA</Option>
                        <Option value="EXONERADA">EXONERADA</Option>
                        <Option value="INAFECTA">INAFECTA</Option>
                        {origen === 6 && <Option value="IMPORTACION">IMPORTACIÓN</Option>}
                        {origen === 9 && <Option value="EXPORTACION">EXPORTACIÓN</Option>}
                      </Select>
                    </div>
                  )}
                  <div className="col-4">
                    <label htmlFor="">A.C</label>
                    <SelectCC
                      defaultValue={ccEdit}
                      onChangeSelect={(e) => {
                        setccEdit(e);
                      }}
                      data={datacc}
                    />

                    <p className="text-danger"> {errors.cc && errors.cc.message}</p>
                  </div>
                  <div className="col-4">
                    {" "}
                    <label htmlFor="">REF. M. PAGO</label>
                    <input
                      name="tipodoc"
                      className="form-control"
                      type="text"
                      value={tipodocEdit ? tipodocEdit : ""}
                      onChange={(e) => {
                        settipodocEdit(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-9">
                    <label htmlFor="">GLOSA</label>
                    <input
                      value={glosaEdit}
                      className="form-control"
                      type="text"
                      maxLength="255"
                      name="glosa"
                      onChange={(e) => {
                        setGlosaEdit(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-5">
                    <label htmlFor="">MONEDA</label>
                    <select
                      name="moneda"
                      onChange={updateMoneda}
                      value={monedaEdit}
                      ref={register({
                        required: "Requerido",
                      })}
                      className="form-control"
                    >
                      {optionsTipo()}
                    </select>
                  </div>
                  {visibleCambio ? (
                    <div className="col-5">
                      <label htmlFor="">TIPO DE CAMBIO</label>
                      <input disabled className="form-control" type="text" value={CambiodelDia} />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="row">
                  <div className="col-5">
                    <label htmlFor="">TIPO</label>
                    <select
                      value={tipoEdit}
                      onChange={(e) => {
                        settipoEdit(e.target.value);
                      }}
                      className="form-control"
                    >
                      <option value={"DEBE"}>DEBE</option>
                      <option value={"HABER"}>HABER</option>
                    </select>
                  </div>
                  <div className="col-5">
                    <label htmlFor="">MONTO</label>
                    <input
                      value={montoEdit}
                      onChange={(e) => {
                        if (Number(e.target.value) <= 999999999999) {
                          setmontoEdit(e.target.value);
                        }
                      }}
                      className="form-control"
                      type="text"
                      name="\*d"
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-3">
                    <button className="btn btn-primary" type="button" onClick={actualizarcuentas}>
                      Actualizar
                    </button>
                  </div>
                  <div className="col-3">
                    <button
                      className="btn btn-danger"
                      type="button"
                      onClick={() => {
                        setmodalEdit(false);
                      }}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </form>
            </Spin>
          </Modal>
          <ModalTipoDoc
            setModalTipo={(e) => setModalTipo(e)}
            seleccionarTipo={(e) => seleccionarTipo(e)}
            modalTipo={modalTipo}
            dataTipos={dataTipos}
            title={"Tipo de Documento"}
          />
        </div>
      </Spin>
    </Page>
  );
};

export default CreateCuenta;
