import React, { useState, useEffect } from "react";
import { Select, notification } from "antd";
import { getUsers } from "../../api/api.contable";

const { Option } = Select;
const SelectDoc = (props) => {
  const { onChangeSelect, idDefaultUser, filterByHeadquarter, setAllUserInfo } = props;
  const [users, setUsers] = useState([]);

  const getAllUsers = async () => {
    try {
      let res = await getUsers();
      const result = res.filter((item) =>
        filterByHeadquarter ? item.headquarters.includes(filterByHeadquarter) : true
      );

      setUsers([...result.reverse()]);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se logró obtener a los usuarios.",
      });
    }
  };

  const handleChange = (code) => {
    if (onChangeSelect) onChangeSelect(code);
    if (setAllUserInfo) {
      const finder = users.find((row) => row._id === code);
      setAllUserInfo(finder);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const filterOptions = (inputValue, option) => {
    const title = option.props.title
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    return title.includes(inputValue.toLowerCase());
  };

  const content = (
    <Select
      size="large"
      style={{ width: "100%" }}
      showSearch
      value={idDefaultUser}
      onChange={handleChange}
      filterOption={filterOptions}
    >
      <Option key={0} value={null} title={""}>
        -- Ninguno --
      </Option>
      {users.map((i) => (
        <Option key={i._id} value={i._id} title={i.fullname}>
          {i.fullname.toUpperCase()}{" "}
          <sub>
            [{i.n_doc} » {i.rol}]
          </sub>
        </Option>
      ))}
    </Select>
  );
  return content;
};
export default SelectDoc;
