import React, { useState, useEffect, useRef } from "react";
import {
  Paper,
  Card,
  CardContent,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SelectSedes from "../../components/Usuarios/SelectSedes";
import sedeactual from "../../config/sedeactual";
import { makeStyles } from "@material-ui/styles";
import ReactToPrint from "react-to-print";
import { apiReportes } from "../../config/api.contable";
import napformat from "../../config/napformat";
import axios from "axios";
import moment from "moment";
import Spinner from "../../components/Global/Spinner";
import { getActiveSedes } from "../../api/api.contable";
import ExportarEEFFexcel from "../../components/Global/ExportarEEFF";
import { InputLabel, Select, MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  logo: {
    position: "absolute",
    top: 10,
    left: 25,
  },
  excel: {
    backgroundColor: "green",
    color: "white",
    width: 121,
    margin: 2,
    "&:hover": {
      backgroundColor: "green",
    },
  },
  select: {
    maxWidth: "calc(100% - 275px)",
    minWidth: 300,
    textAlign: "left",
  },
  selectsedes: {
    maxWidth: "calc(100% - 600px)",
    minWidth: 350,
    textAlign: "left",
  },
}));

const ExportarEEFF = (props) => {
  let anho = sedeactual.getAnio();
  const classes = useStyles();
  const componentRef = useRef();
  const rucHeadquarters = sedeactual.getRuc();
  const [dataRealYear, setDataRealYear] = useState(null);
  const [dataLastYear, setDataLastYear] = useState(null);
  const [reportModeSelected, setReportModeSelected] = useState(0);
  const [error, setError] = useState(false);
  const [sedeSelected, setSedeSelected] = useState(sedeactual.get());
  const [sedes, setSedes] = useState(null);
  const [titleReport, setTitleReport] = useState({
    title: sedeactual.description(),
  });

  const getTotalData = async () => {
    try {
      setDataRealYear(null);
      setDataLastYear(null);
      setError(false);
      const res = await axios.get(
        `${apiReportes}/eeff/${anho}${
          reportModeSelected == 0 ? "" : "/" + reportModeSelected
        }/${sedeSelected}/${sedeactual.company().url}`
      );
      setDataRealYear(res.data);

      const resp = await axios.get(
        `${apiReportes}/eeff/${anho - 1}/${sedeSelected}/${
          sedeactual.company().url
        }`
      );
      setDataLastYear(resp.data);
    } catch (error) {
      setError(true);
    }
  };

  useEffect(() => {
    getTotalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportModeSelected, sedeSelected]);

  const getSedes = async () => {
    try {
      let result = await getActiveSedes();
      setSedes(result);
    } catch (error) {}
  };

  useEffect(() => {
    getSedes();
  }, []);

  const content = (
    <Paper style={{ padding: 25, minHeight: "calc(100vh - 65px)" }}>
      <div align="right">
        <Link to={{ pathname: "/reportes" }}>
          <Button variant="outlined" color="primary" style={{ float: "left" }}>
            <ArrowBackIosIcon /> VOLVER
          </Button>
        </Link>
        {(sedeactual.getRol() === "supernap" ||
          sedeactual.getRol() === "superadmin") && (
          <FormControl
            variant="outlined"
            size="small"
            className={classes.selectsedes}
          >
            <SelectSedes
              defaultValue={sedeSelected}
              data={sedes}
              setTitle={setTitleReport}
              onChangeSelect={(e) => {
                setSedeSelected(e);
              }}
            />
          </FormControl>
        )}
        <FormControl variant="outlined" size="small" className={classes.select}>
          <InputLabel>Seleccione</InputLabel>
          <Select
            label="seleccione"
            value={reportModeSelected}
            onChange={(event) => setReportModeSelected(event.target.value)}
          >
            <MenuItem value={0}>Anual</MenuItem>
            <MenuItem value={1}>Enero</MenuItem>
            <MenuItem value={2}>Febrero</MenuItem>
            <MenuItem value={3}>Marzo</MenuItem>
            <MenuItem value={4}>Abril</MenuItem>
            <MenuItem value={5}>Mayo</MenuItem>
            <MenuItem value={6}>Junio</MenuItem>
            <MenuItem value={7}>Julio</MenuItem>
            <MenuItem value={8}>Agosto</MenuItem>
            <MenuItem value={9}>Setiembre</MenuItem>
            <MenuItem value={10}>Octubre</MenuItem>
            <MenuItem value={11}>Noviembre</MenuItem>
            <MenuItem value={12}>Diciembre</MenuItem>
          </Select>
        </FormControl>{" "}
        <ExportarEEFFexcel
          nombre={`EEFF_${moment(Date.now()).format(
            "dddd_DD_MMMM_YYYY_HH_mm_ss"
          )}_${Date.now()}`}
          ruta={`${apiReportes}/eeff/export-excel/${sedeactual.getAnio()}/${sedeSelected}/${
            sedeactual.company().url
          }`}
          body={{
            year1: dataLastYear,
            year2: dataRealYear,
          }}
        />
        <ReactToPrint
          trigger={() => (
            <Button
              color="primary"
              variant="outlined"
              style={{ marginRight: 10 }}
            >
              <PrintIcon /> IMPRIMIR
            </Button>
          )}
          content={() => componentRef.current}
        />
      </div>
      <Card ref={componentRef} align="center" style={{ position: "relative" }}>
        {/* <img
          src="https://hermanasdicperu.org/wp-content/uploads/2018/11/diclogo.png"
          height="120px"
          className={classes.logo}
        /> */}
        <CardContent>
          <Typography variant="h6" color="textPrimary">
            {titleReport.title && titleReport.title.toUpperCase()}
          </Typography>
          <Typography variant="subtitle1" color="textPrimary">
            N° RUC {rucHeadquarters}
          </Typography>
          <Typography variant="h6" color="textPrimary">
            ESTADO DE SITUACIÓN FINANCIERA
          </Typography>
          <Typography variant="subtitle2" color="textPrimary">
            Reporte{" "}
            {reportModeSelected == 0
              ? "anual " + anho
              : "mensual de " +
                moment(anho + "-" + reportModeSelected + "-01").format(
                  "MMMM YYYY"
                )}
            <br />
          </Typography>
          <Typography variant="subtitle2" color="textPrimary">
            (EXPRESADO EN SOLES)
          </Typography>
          {dataRealYear && dataLastYear ? (
            <Grid container spacing={1} align="left">
              <Grid item xs={12} lg={6}>
                <Grid
                  container
                  direction="column"
                  justify="space-between"
                  alignItems="stretch"
                  style={{ height: "100%" }}
                >
                  <Grid item>
                    <Typography variant="h3" color="textPrimary">
                      ACTIVO
                    </Typography>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: 400 }}>
                              ACTIVO CORRIENTE
                            </TableCell>
                            <TableCell style={{ width: 175 }}>NOTAS</TableCell>
                            <TableCell style={{ width: 175 }}>
                              {anho - 1}
                            </TableCell>
                            <TableCell style={{ width: 175 }}>{anho}</TableCell>
                          </TableRow>
                        </TableHead>
                        {dataRealYear.activos_corrientes &&
                        dataLastYear.activos_corrientes ? (
                          <TableBody>
                            {dataLastYear.activos_corrientes
                              .efectivoEquivalentes == 0 &&
                            dataRealYear.activos_corrientes
                              .efectivoEquivalentes == 0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>
                                  Efectivo y Equivalentes de Efectivo
                                </TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.activos_corrientes
                                      .efectivoEquivalentes
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.activos_corrientes
                                      .efectivoEquivalentes
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataLastYear.activos_corrientes
                              .inversionesFinancieras == 0 &&
                            dataRealYear.activos_corrientes
                              .inversionesFinancieras == 0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>Inversiones Financieras</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.activos_corrientes
                                      .inversionesFinancieras
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.activos_corrientes
                                      .inversionesFinancieras
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataLastYear.activos_corrientes
                              .ctsCobrarComercial == 0 &&
                            dataRealYear.activos_corrientes
                              .ctsCobrarComercial == 0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>
                                  Cuentas por Cobrar Comerciales (neto)
                                </TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.activos_corrientes
                                      .ctsCobrarComercial
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.activos_corrientes
                                      .ctsCobrarComercial
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataLastYear.activos_corrientes.ctsCobrarOtras ==
                              0 &&
                            dataRealYear.activos_corrientes.ctsCobrarOtras ==
                              0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>
                                  Otras Cuentas por Cobrar a Partes
                                  Relacionadas(neto)
                                </TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.activos_corrientes
                                      .ctsCobrarOtras
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.activos_corrientes
                                      .ctsCobrarOtras
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataLastYear.activos_corrientes
                              .ctsCobrarRelacionadas == 0 &&
                            dataRealYear.activos_corrientes
                              .ctsCobrarRelacionadas == 0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>
                                  Otras Cuentas por Cobrar (neto)
                                </TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.activos_corrientes
                                      .ctsCobrarRelacionadas
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.activos_corrientes
                                      .ctsCobrarRelacionadas
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataLastYear.activos_corrientes.existencias == 0 &&
                            dataRealYear.activos_corrientes.existencias == 0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>Existencias (neto)</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.activos_corrientes.existencias
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.activos_corrientes.existencias
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataLastYear.activos_corrientes
                              .activosBiologicos == 0 &&
                            dataRealYear.activos_corrientes.activosBiologicos ==
                              0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>Activos Biológicos</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.activos_corrientes
                                      .activosBiologicos
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.activos_corrientes
                                      .activosBiologicos
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataLastYear.activos_corrientes.gtsAnticipados ==
                              0 &&
                            dataRealYear.activos_corrientes.gtsAnticipados ==
                              0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>
                                  Gastos Contratados por Anticipado
                                </TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.activos_corrientes
                                      .gtsAnticipados
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.activos_corrientes
                                      .gtsAnticipados
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataRealYear.activos_corrientes.otsActivos == 0 &&
                            dataLastYear.activos_corrientes.otsActivos == 0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>Otros Activos</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.activos_corrientes.otsActivos
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.activos_corrientes.otsActivos
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            <TableRow>
                              <TableCell style={{ fontWeight: 600 }}>
                                TOTAL ACTIVO CORRIENTE
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell style={{ fontWeight: 600 }}>
                                S/
                                {napformat(
                                  dataLastYear.activos_corrientes
                                    .ttActivosCorriente
                                )}
                              </TableCell>
                              <TableCell style={{ fontWeight: 600 }}>
                                S/
                                {napformat(
                                  dataRealYear.activos_corrientes
                                    .ttActivosCorriente
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ) : (
                          <Typography
                            variant="p"
                            color="textSecondary"
                            align="center"
                          >
                            NO DATA
                          </Typography>
                        )}
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: 400 }}>
                              ACTIVO NO CORRIENTE
                            </TableCell>
                            <TableCell style={{ width: 175 }}>NOTAS</TableCell>
                            <TableCell style={{ width: 175 }}>
                              {anho - 1}
                            </TableCell>
                            <TableCell style={{ width: 175 }}>{anho}</TableCell>
                          </TableRow>
                        </TableHead>
                        {dataRealYear.activos_no_corrientes &&
                        dataLastYear.activos_no_corrientes ? (
                          <TableBody>
                            {(dataRealYear.activos_no_corrientes
                              .inversionesMeI ===
                              0) &
                            (dataLastYear.activos_no_corrientes
                              .inversionesMeI ===
                              0) ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>
                                  Inversiones Mobiliarias e Inmoviliarias
                                </TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.activos_no_corrientes
                                      .inversionesMeI
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.activos_no_corrientes
                                      .inversionesMeI
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {(dataRealYear.activos_no_corrientes
                              .InmuebleMyEDepre ===
                              0) &
                            (dataLastYear.activos_no_corrientes
                              .InmuebleMyEDepre ===
                              0) ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>Depreciación</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.activos_no_corrientes
                                      .InmuebleMyEDepre
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.activos_no_corrientes
                                      .InmuebleMyEDepre
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataLastYear.activos_no_corrientes.InmuebleMyE ==
                              0 &&
                            dataRealYear.activos_no_corrientes.InmuebleMyE ==
                              0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>
                                  Inmueble, Maquinaria y Equipo
                                </TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.activos_no_corrientes
                                      .InmuebleMyE
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.activos_no_corrientes
                                      .InmuebleMyE
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataLastYear.activos_no_corrientes
                              .activosIntangibles == 0 &&
                            dataRealYear.activos_no_corrientes
                              .activosIntangibles == 0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>Activos Intangibles</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.activos_no_corrientes
                                      .activosIntangibles
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.activos_no_corrientes
                                      .activosIntangibles
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            <TableRow>
                              <TableCell style={{ fontWeight: 600 }}>
                                TOTAL ACTIVO NO CORRIENTE
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell style={{ fontWeight: 600 }}>
                                S/
                                {napformat(
                                  dataLastYear.activos_no_corrientes
                                    .ttActivoNoCorriente
                                )}
                              </TableCell>
                              <TableCell style={{ fontWeight: 600 }}>
                                S/
                                {napformat(
                                  dataRealYear.activos_no_corrientes
                                    .ttActivoNoCorriente
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ) : (
                          <Typography
                            variant="p"
                            color="textSecondary"
                            align="center"
                          >
                            NO DATA
                          </Typography>
                        )}
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                fontSize: 15,
                                fontWeight: 600,
                                width: 400,
                              }}
                            >
                              TOTAL ACTIVO
                            </TableCell>
                            <TableCell style={{ width: 175 }}></TableCell>
                            <TableCell
                              style={{
                                fontSize: 18,
                                fontWeight: 600,
                                width: 175,
                              }}
                            >
                              S/{napformat(dataLastYear.total_activo)}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: 18,
                                fontWeight: 600,
                                width: 175,
                              }}
                            >
                              S/{napformat(dataRealYear.total_activo)}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid
                  container
                  direction="column"
                  justify="space-between"
                  alignItems="stretch"
                  style={{ height: "100%" }}
                >
                  <Grid item>
                    <Typography variant="h3" color="textPrimary">
                      PASIVO Y PATRIMONIO
                    </Typography>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: 400 }}>
                              PASIVO CORRIENTE
                            </TableCell>
                            <TableCell style={{ width: 175 }}>NOTAS</TableCell>
                            <TableCell style={{ width: 175 }}>
                              {anho - 1}
                            </TableCell>
                            <TableCell style={{ width: 175 }}>{anho}</TableCell>
                          </TableRow>
                        </TableHead>
                        {dataRealYear.pasivo_corriento &&
                        dataLastYear.pasivo_corriento ? (
                          <TableBody>
                            {dataLastYear.pasivo_corriento.sbgBancario == 0 &&
                            dataRealYear.pasivo_corriento.sbgBancario == 0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>Sobregiro Bancario</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.pasivo_corriento.sbgBancario
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.pasivo_corriento.sbgBancario
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataLastYear.pasivo_corriento
                              .rypPagarOFinancieras == 0 &&
                            dataRealYear.pasivo_corriento
                              .rypPagarOFinancieras == 0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>
                                  Remuneraciones y Participaciones por Pagar
                                </TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.pasivo_corriento
                                      .rypPagarOFinancieras
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.pasivo_corriento
                                      .rypPagarOFinancieras
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataLastYear.pasivo_corriento.obgFinancieras ==
                              0 &&
                            dataRealYear.pasivo_corriento.obgFinancieras ==
                              0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>Obligaciones Financieras</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.pasivo_corriento.obgFinancieras
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.pasivo_corriento.obgFinancieras
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataLastYear.pasivo_corriento
                              .cuentaxPagarComercial == 0 &&
                            dataRealYear.pasivo_corriento
                              .cuentaxPagarComercial == 0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>
                                  Cuentas por Pagar Comerciales
                                </TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.pasivo_corriento
                                      .cuentaxPagarComercial
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.pasivo_corriento
                                      .cuentaxPagarComercial
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataLastYear.pasivo_corriento.otsCuentasxPagar ==
                              0 &&
                            dataRealYear.pasivo_corriento.otsCuentasxPagar ==
                              0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>
                                  Otras Cuentas por Pagar a Partes Relacionadas
                                </TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.pasivo_corriento
                                      .otsCuentasxPagar
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.pasivo_corriento
                                      .otsCuentasxPagar
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataLastYear.pasivo_corriento
                              .ipstRentaParticipaciones == 0 &&
                            dataRealYear.pasivo_corriento
                              .ipstRentaParticipaciones == 0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>
                                  Impuesto a la Renta y Participaciones
                                  Corrientes
                                </TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.pasivo_corriento
                                      .ipstRentaParticipaciones
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.pasivo_corriento
                                      .ipstRentaParticipaciones
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataLastYear.pasivo_corriento.otsCuentasPagar ==
                              0 &&
                            dataRealYear.pasivo_corriento.otsCuentasPagar ==
                              0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>Otras Cuentas por Pagar</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.pasivo_corriento
                                      .otsCuentasPagar
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.pasivo_corriento
                                      .otsCuentasPagar
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            <TableRow>
                              <TableCell style={{ fontWeight: 600 }}>
                                TOTAL PASIVO CORRIENTE
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell style={{ fontWeight: 600 }}>
                                S/
                                {napformat(
                                  dataLastYear.pasivo_corriento
                                    .ttPasivoCorriente
                                )}
                              </TableCell>
                              <TableCell style={{ fontWeight: 600 }}>
                                S/
                                {napformat(
                                  dataRealYear.pasivo_corriento
                                    .ttPasivoCorriente
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ) : (
                          <Spinner />
                        )}
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: 400 }}>
                              PASIVO NO CORRIENTE
                            </TableCell>
                            <TableCell style={{ width: 175 }}>NOTAS</TableCell>
                            <TableCell style={{ width: 175 }}>
                              {anho - 1}
                            </TableCell>
                            <TableCell style={{ width: 175 }}>{anho}</TableCell>
                          </TableRow>
                        </TableHead>
                        {dataRealYear.pasivo_no_corriente &&
                        dataLastYear.pasivo_no_corriente ? (
                          <TableBody>
                            {dataLastYear.pasivo_no_corriente
                              .ingresosDiferidos == 0 &&
                            dataRealYear.pasivo_no_corriente
                              .ingresosDiferidos == 0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>Ingresos Diferidos</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.pasivo_no_corriente
                                      .ingresosDiferidos
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.pasivo_no_corriente
                                      .ingresosDiferidos
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            <TableRow>
                              <TableCell style={{ fontWeight: 600 }}>
                                TOTAL PASIVO NO CORRIENTE
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell style={{ fontWeight: 600 }}>
                                S/
                                {napformat(
                                  dataLastYear.pasivo_no_corriente
                                    .ttpasivoNoCorriente
                                )}
                              </TableCell>
                              <TableCell style={{ fontWeight: 600 }}>
                                S/
                                {napformat(
                                  dataRealYear.pasivo_no_corriente
                                    .ttpasivoNoCorriente
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ) : (
                          <Spinner />
                        )}
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: 400 }}>
                              PATRIMONIO
                            </TableCell>
                            <TableCell style={{ width: 175 }}>NOTAS</TableCell>
                            <TableCell style={{ width: 175 }}>
                              {anho - 1}
                            </TableCell>
                            <TableCell style={{ width: 175 }}>{anho}</TableCell>
                          </TableRow>
                        </TableHead>
                        {dataRealYear.patrimonio && dataLastYear.patrimonio ? (
                          <TableBody>
                            {dataRealYear.patrimonio.capital == 0 &&
                            dataLastYear.patrimonio.capital == 0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>Capital</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/{napformat(dataLastYear.patrimonio.capital)}
                                </TableCell>
                                <TableCell>
                                  S/{napformat(dataRealYear.patrimonio.capital)}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataRealYear.patrimonio.capitalAdicional == 0 &&
                            dataLastYear.patrimonio.capitalAdicional == 0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>Capital Adicional</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.patrimonio.capitalAdicional
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.patrimonio.capitalAdicional
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataRealYear.patrimonio.rstNoRealizado == 0 &&
                            dataLastYear.patrimonio.rstNoRealizado == 0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>Resultados no Realizados</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.patrimonio.rstNoRealizado
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.patrimonio.rstNoRealizado
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataRealYear.patrimonio.exedenteRevaluacion == 0 &&
                            dataLastYear.patrimonio.exedenteRevaluacion == 0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>Excedente de Reevaluación</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.patrimonio.exedenteRevaluacion
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.patrimonio.exedenteRevaluacion
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataRealYear.patrimonio.reservasLegales == 0 &&
                            dataLastYear.patrimonio.reservasLegales == 0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>Reservas Legales</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.patrimonio.reservasLegales
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.patrimonio.reservasLegales
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataRealYear.patrimonio.otsReservas == 0 &&
                            dataLastYear.patrimonio.otsReservas == 0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>Otras Reservas</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.patrimonio.otsReservas
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.patrimonio.otsReservas
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataRealYear.patrimonio.rstAcumulados == 0 &&
                            dataLastYear.patrimonio.rstAcumulados == 0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>Resultados Acumulados</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.patrimonio.rstAcumulados
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.patrimonio.rstAcumulados
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {dataRealYear.patrimonio.utlOPerdida == 0 &&
                            dataLastYear.patrimonio.utlOPerdida == 0 ? (
                              ""
                            ) : (
                              <TableRow>
                                <TableCell>
                                  Utilidad o Pérdida del Ejercicio
                                </TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataLastYear.patrimonio.utlOPerdida
                                  )}
                                </TableCell>
                                <TableCell>
                                  S/
                                  {napformat(
                                    dataRealYear.patrimonio.utlOPerdida
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            <TableRow>
                              <TableCell style={{ fontWeight: 600 }}>
                                TOTAL PATRIMONIO NETO
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell style={{ fontWeight: 600 }}>
                                S/
                                {napformat(
                                  dataLastYear.patrimonio.totalPatrimonio
                                )}
                              </TableCell>
                              <TableCell style={{ fontWeight: 600 }}>
                                S/
                                {napformat(
                                  dataRealYear.patrimonio.totalPatrimonio
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ) : (
                          <Spinner />
                        )}
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                fontSize: 15,
                                fontWeight: 600,
                                width: 400,
                              }}
                            >
                              TOTAL PASIVO Y PATRIMONIO
                            </TableCell>
                            <TableCell style={{ width: 175 }}></TableCell>
                            <TableCell
                              style={{
                                fontSize: 18,
                                fontWeight: 600,
                                width: 175,
                              }}
                            >
                              S/
                              {napformat(dataLastYear.total_pasivo_patrimonio)}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: 18,
                                fontWeight: 600,
                                width: 175,
                              }}
                            >
                              S/
                              {napformat(dataRealYear.total_pasivo_patrimonio)}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : error ? (
            <Typography color="error" variant="body2">
              <br />
              <br />
              La sede no existe. No se pudo obtener la información, intente mas
              tarde.
            </Typography>
          ) : (
            <Spinner />
          )}
        </CardContent>
      </Card>
    </Paper>
  );
  return content;
};

export default ExportarEEFF;
