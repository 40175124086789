import React, { Fragment } from "react";
import CustomTable from "src/components/CustomTable";
import { Fab } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import PrintIcon from "@material-ui/icons/Print";
import moment from "moment";
import napformat from "src/config/napformat";
import sedeactual from "src/config/sedeactual";
// import { Email } from "@material-ui/icons";

const TableIngresos = ({ data, loading, setModal, setDel, setDetails, head }) => {
  // handleSendEmail
  const rol = sedeactual.getRol();

  const columns = [
    {
      title: "CORRELATIVO",
      dataIndex: "correlative",
      key: "correlative",
      align: "center",
      width: 200,
      render: (value) => (value ? `${value.toString().padStart(10, "0")}` : ""),
    },
    {
      title: "NOMBRE ó RAZÓN SOCIAL",
      dataIndex: "destiny",
      key: "destiny",
      align: "center",
      width: 200,
      render: (value) => `${value.t_doc}: ${value.n_doc} ─ ${value.nombre_o_razon_social}`,
    },
    {
      title: "MONTO",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: 200,
      sorter: true,
      filter: false,
      render: (value) => (value ? `S/ ${napformat(value)}` : ""),
    },
    {
      title: "FECHA",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: 200,
      sorter: true,
      filter: false,
      render: (value) => (value ? moment(value).format("DD/MM/YYYY") : ""),
    },
    {
      title: "DESCRIPCIÓN",
      dataIndex: "concepto",
      key: "concepto",
      align: "center",
      width: 200,
      sorter: true,
      filter: true,
    },
    {
      title: "CORREO",
      dataIndex: "email",
      key: "email",
      align: "center",
      width: 200,
      sorter: true,
      filter: true,
    },
    {
      title: "ACCIONES",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      width: 180,
      sorter: false,
      filter: false,
      render: (value, row) => (
        <Fragment>
          {(rol === "supernap" || rol === "superadmin") && (
            <Fab
              size="small"
              color="primary"
              variant="extended"
              onClick={() => setModal({ open: true, data: row })}
            >
              <Edit />
            </Fab>
          )}
          {rol === "supernap" && (
            <Fab
              size="small"
              color="secondary"
              variant="extended"
              onClick={() => setDel({ open: true, id: value })}
            >
              <Delete />
            </Fab>
          )}
          <Fab
            size="small"
            variant="extended"
            color="secondary"
            onClick={() => setDetails({ open: true, data: row })}
          >
            <PrintIcon />
          </Fab>
        </Fragment>
      ),
    },
  ];

  const colCustom = [
    {
      title: "SEDE / EMPRESA",
      dataIndex: "headquarter",
      key: "headquarter",
      align: "center",
      width: 200,
      sorter: true,
      filter: false,
      render: (value) => value?.description ?? "---",
    },
  ];

  return (
    <CustomTable
      columns={head ? [...colCustom, ...columns] : columns}
      data={data}
      loading={loading}
      pagination={{ pageSize: 20 }}
    />
  );
};

export default TableIngresos;
