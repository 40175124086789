import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

export const colorsWorkerTypeIncidents = {
  FALTA: "#FF4842",
  AMONESTACIONES: "#FFC107",
  VACACIONES: "#1890FF",
  FELICITACIONES: "#54D62C",
  OTROS: "#4d4d4d",
};

const types = ["FALTA", "AMONESTACIONES", "VACACIONES", "FELICITACIONES", "OTROS"];

const SelectWorkerTypeIncidents = ({ value, setValue }) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>Tipo de Incidencia</InputLabel>
      <Select value={value} onChange={(e) => setValue(e.target.value)} label="Tipo de Incidencia">
        {types.map((e, index) => (
          <MenuItem key={index} value={e}>
            {e}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectWorkerTypeIncidents;
