import React, { useState, useEffect } from "react"
import TableEmpresa from "./TableEmpresa"
import {
  getHeadquartersFilter,
  getAllCompanies,
  getModules,
} from "../../api/api.contable"
import { Button, Grid } from "@material-ui/core"
import { notification } from "antd"
import { Link } from "react-router-dom"
import Page from "../../components/Page"
import SelectCompania from "../Selects/SelectCompania"
import sedeactual from "../../config/sedeactual"
import ModalHeadquarter from "./ModalHeadquarter"
import ExportarExcel from '../Global/ExportarExcel'

const Empresa = () => {
  const [ data, setData ] = useState( [] )
  const [ loading, setLoading ] = useState( false )
  const [ companies, setCompanies ] = useState( [] )
  const [ modules, setModules ] = useState( [] )
  const [ company, setCompany ] = useState( sedeactual.company()._id )
  const [ urlCompany, setUrlCompany ] = useState( sedeactual.company().url )
  const [ modal, setModal ] = useState( { open: false, data: null } )

  useEffect( () => {
    obtainCompanies()
    getHeadquartersData()
  }, [] )

  useEffect( () => {
    if ( company && companies.length > 0 ) {
      let filter = companies.find( ( ev ) => ev._id === company )
      getHeadquartersData( filter.url )
      setUrlCompany( filter.url )
    }
  }, [ company ] )

  const getHeadquartersData = async ( url = urlCompany ) => {
    setLoading( true )
    setModules( [] )
    try {
      let res = await getHeadquartersFilter( url )
      let result = await getModules( `modules/${ url }` )
      setModules( result )
      setData( [ ...res.data ] )
    } catch ( error ) {
      notification[ "error" ]( {
        message: "Ocurrió un error al obtener la información.",
      } )
    } finally {
      setLoading( false )
    }
  }

  const obtainCompanies = async () => {
    try {
      const res = await getAllCompanies()
      setCompanies( res )
    } catch ( error ) {
      notification[ "error" ]( {
        message: "Ocurrió un error al obtener los módulos.",
        description: `${ error }`,
      } )
    }
  }

  return (
    <Page
      helper="EMPRESA"
      title="SEDES/EMPRESAS"
      itemComponent={
        <Grid container spacing={ 1 }>
          { sedeactual.getRol() === "supernap" && (
            <Grid item style={ { minWidth: 320 } }>
              <SelectCompania
                defaultValue={ company }
                data={ companies }
                onChangeSelect={ ( e ) => setCompany( e ) }
              />
            </Grid>
          ) }
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={ () => setModal( { open: true, data: null } ) }
            >
              AGREGAR
            </Button>
          </Grid>
          {/* <Grid item>
            <ExportarExcel
              name={ `EMPRESAS_${ urlCompany }` }
              endpoint={ `export-headquarters-list/${ urlCompany }` }
              body={ {} }
              method="POST"
              disabled={ loading }
              small={ false }
              api={ 1 }
            />
          </Grid> */}
        </Grid>
      }
    >
      <TableEmpresa
        data={ data }
        loading={ loading }
        getHeadquartersData={ getHeadquartersData }
        setLoading={ setLoading }
        modules={ modules }
        setModal={ setModal }
      />
      { modal.open && (
        <ModalHeadquarter
          visible={ modal.open }
          setVisible={ setModal }
          data={ modal.data }
          url={ urlCompany }
          setLoading={ setLoading }
          loading={ loading }
          refreshFunction={ getHeadquartersData }
        />
      ) }
    </Page>
  )
}

export default Empresa
