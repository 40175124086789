import React, { useState } from "react";
import { Table } from "antd";
import napformat from "../../config/napformat";
import moment from "moment";

const TablaMayor = (props) => {
  const columns = [
    {
      title: "ORIGEN",
      dataIndex: "origin",
      key: "origin",
    },
    {
      title: "VOUCHER",
      dataIndex: "voucher_number",
      key: "voucher_number",
      align: "center",
      sorter: (a, b) => a.voucher_number - b.voucher_number,
    },
    {
      title: "CUENTA ASOC.",
      dataIndex: "associated_account",
      key: "associated_account",
    },
    {
      title: "N° DE RUC",
      dataIndex: "ruc",
      key: "ruc",
    },
    {
      title: "RAZON SOCIAL",
      dataIndex: "businessName",
      key: "businessName",
    },
    {
      title: "TIPO DOC.",
      /* dataIndex: "type_voucher_id",
      key: "type_voucher_id" */
      dataIndex: "type_document",
      key: "type_document",
      align: "center",
    },
    {
      title: "SERIE",
      dataIndex: "series",
      key: "series",
      align: "center",
    },
    {
      title: "N° DOC.",
      dataIndex: "correlative",
      key: "correlative",
    },

    {
      title: "REF. M. PAGO",
      dataIndex: "ref_payment",
      key: "ref_payment",
    },
    {
      title: "FECHA",
      dataIndex: "date",
      key: "date",
      align: "center",
      sorter: (a, b) =>
        new Date(moment(a.date, "YYYY-MM-DD")) -
        new Date(moment(b.date, "YYYY-MM-DD")),
    },
    {
      title: "CONCEPTO",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "DEBE",
      dataIndex: "debe",
      key: "debe",
      align: "right",
      render: (text) => (
        <p>{text ? napformat(parseFloat(text).toFixed(2)) : text}</p>
      ),
    },
    {
      title: "HABER",
      dataIndex: "haber",
      key: "haber",
      align: "right",
      render: (text) => (
        <p>{text ? napformat(parseFloat(text).toFixed(2)) : text}</p>
      ),
    },
  ];

  return (
    <>
      <Table
        pagination={false}
        columns={columns}
        dataSource={props.data}
        bordered
        loading={props.loading}
      />
    </>
  );
};

export default TablaMayor;
