import React from "react";
import { Select } from "antd";

const listReason = [
  "RENUNCIA",
  "RENUNCIA CON INCENTIVO",
  "DESPIDO O DESTITUCIÓN",
  "CESE COLECTIVO",
  "JUBILACIÓN",
  "INVALIDES ABSOLUTA PERMANENTE",
  "TERMINACIÓN DE LA OBRA O SERVICIO O VENCIMIENTO DEL PLAZO",
  "MUTUO DISENSO",
  "FALLECIMIENTO",
  "BAJA POR SUCESIÓN EN POSICIÓN DEL EMPLEADOR",
  "EXTINCIÓN O LIQUIDACIÓN DEL EMPLEADOR",
  "NO SE INICIO LA REL. LABORAL O PREST. DE SERVICIOS",
];

const SelectReasonDown = ({ setValue, value }) => {
  const { Option } = Select;

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const filterOptions = (inputValue, option) => {
    const title = removeAccents(option.props.title.toLowerCase());
    return title.includes(inputValue.toLowerCase());
  };

  return (
    <Select
      size="large"
      style={{ width: "100%" }}
      showSearch
      value={value}
      onChange={setValue}
      filterOption={filterOptions}
    >
      <Option key={0} value={null} title={""}>
        - Ninguno -
      </Option>
      {listReason.map((i) => (
        <Option value={i} title={i} key={i}>
          {i}
        </Option>
      ))}
    </Select>
  );
};
export default SelectReasonDown;
