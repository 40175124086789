import React, { Fragment, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  FormControl,
  Divider,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import useForm from "react-hook-form";
import { Alert, notification, Spin } from "antd";
import CloseIcon from "@material-ui/icons/Close";
import { postIncidenceDetail, putIncidenceDetail } from "src/utils/reqinc";

const baseList = [
  {
    date: "02/11/2021",
    action: "Se informó de los hechos y de las acciones realizadas a la UGEL.",
    detail: "Oficio Nro 001",
    type: "ACCIÓN",
  },
  {
    date: "04/11/2021",
    action: "Se comunicó el caso a la comisaría y fiscalía.",
    detail: "Oficio Nro 002",
    type: "DERIVACIÓN",
  },
  {
    date: "06/11/2021",
    action: "Se cambió de aula al agresor.",
    detail: "Oficio Nro 003",
    type: "SEGUIMIENTO",
  },
  {
    date: "08/11/2021",
    action:
      "Se convocó a los familiares de las o los involucrados para promover acciones en conjunto.",
    detail: "Oficio Nro 004",
    type: "ACCIÓN",
  },
  {
    date: "10/11/2021",
    action: "Se puso a dispoción de la UGEL al agresor.",
    detail: "Oficio Nro 005",
    type: "CIERRE",
  },
];
const steps = ["ACCIÓN", "DERIVACIÓN", "SEGUIMIENTO", "CIERRE"];
const defaultDate = moment().format("YYYY-MM-DD");

const FormTwo = ({ open, setOpen, data, loading, setLoading, incidence, reloadFunction, pass }) => {
  const list = steps.filter((el) => !pass.includes(el));
  const { register, handleSubmit, errors } = useForm();
  const [status, setStatus] = useState(Boolean(data) ? data.status : null);
  const [files, setFiles] = useState(null);
  const [actions, setActions] = useState(
    Boolean(data) ? data.actions : [{ date: defaultDate, action: "", aditional: "" }]
  );

  const sendRegister = async (items) => {
    try {
      setLoading(true);
      if (actions.length === 0) {
        return notification["warning"]({ message: "Debe rellenar por lo menos una acción" });
      }
      if (!status) {
        return notification["warning"]({ message: "Debe seleccionar un estado" });
      }

      const form = new FormData();
      form.append("actions", JSON.stringify(actions));
      form.append("incidence", incidence._id);
      form.append("status", status);
      form.append("end", list.length === 1);
      form.append("date_report", items.date_report);
      if (files) form.append("file", files);

      if (data) await putIncidenceDetail(form, data._id);
      else await postIncidenceDetail(form);
      notification["success"]({
        message: `El registró se ${data ? "actualizó" : "guardó"} correctamente`,
      });
      reloadFunction();
      setOpen({ open: false });
    } catch (error) {
      notification["error"]({
        message: `Ocurrió un error al ${data ? "actualizar" : "guardar"} la información`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeDate = (index, value, path) => {
    actions[index][path] = value;
    setActions([...actions]);
  };

  const addRecord = () => {
    actions.push({
      date: defaultDate,
      action: "",
      aditional: "",
    });
    setActions([...actions]);
  };

  const removeRecord = (index) => {
    actions.splice(index, 1);
    setActions([...actions]);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography align="center" variant="h4" component="p">
          FORMATO 2 DEL REGISTRO DE INCIDENCIAS
        </Typography>
        {list.length === 1 && (
          <Alert
            type="warning"
            message="Recuerda que después de guardar la INCIDENCIA DE CIERRE, la incidencia general se finalizará y ya no podrá realizar modificaciones posteriores."
          />
        )}
      </DialogTitle>
      <Spin spinning={loading}>
        <form onSubmit={handleSubmit(sendRegister)}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <SelectStatus
                  status={status}
                  setStatus={setStatus}
                  list={list}
                  edit={Boolean(data)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Fecha del reporte"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  name="date_report"
                  defaultValue={Boolean(data) ? data.date_report : defaultDate}
                  inputRef={register({ required: true })}
                  error={Boolean(errors?.date_report)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" color="textSecondary">
                  ACCIONES
                </Typography>
                <Divider />
              </Grid>
              {actions.map((el, index) => (
                <Grid item xs={12} container>
                  <Grid
                    container
                    spacing={0}
                    style={{
                      width: "calc((100%) - 50px)",
                    }}
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={3}>
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        label="FECHA"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={el.date}
                        onChange={(e) => handleChangeDate(index, e.target.value, "date")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        label="Acción"
                        InputLabelProps={{ shrink: true }}
                        value={el.action}
                        onChange={(e) => handleChangeDate(index, e.target.value, "action")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        label="Información adicional"
                        InputLabelProps={{ shrink: true }}
                        value={el.aditional}
                        onChange={(e) => handleChangeDate(index, e.target.value, "aditional")}
                      />
                    </Grid>
                  </Grid>
                  <div style={{ width: "50px" }}>
                    <IconButton color="secondary" onClick={() => removeRecord(index)}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </Grid>
              ))}
              <Grid item xs={12} align="center">
                <Button variant="outlined" color="primary" onClick={addRecord} size="small">
                  AGREGAR
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  label="ARCHIVO (opcional)"
                  InputLabelProps={{ shrink: true }}
                  type="file"
                  onChange={(e) => setFiles(e.target.files[0])}
                />
              </Grid>
              {status && (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="textSecondary">
                    EJEMPLOS
                  </Typography>
                  <Divider />
                  {baseList.map((el) =>
                    el.type === status ? (
                      <Fragment>
                        <Typography variant="caption" color="textSecondary">
                          El <b>{el.date}</b> {el.action} <b>{el.detail}</b>
                        </Typography>
                        <br />
                      </Fragment>
                    ) : (
                      ""
                    )
                  )}
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={() => setOpen({ open: false })}>
              CANCELAR
            </Button>
            <Button variant="contained" color="primary" type="submit">
              {data ? "ACTUALIZAR" : "GUARDAR"}
            </Button>
          </DialogActions>
        </form>
      </Spin>
    </Dialog>
  );
};

export default FormTwo;

const SelectStatus = ({ setStatus, status, list, edit }) => {
  const opts = edit ? steps : list;

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>ESTADO</InputLabel>
      <Select label="ESTADO" value={status} onChange={(e) => setStatus(e.target.value)}>
        {opts.map((el) => (
          <MenuItem value={el} key={el}>
            {el}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
