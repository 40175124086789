import axios from "axios";
import { apiContable } from "src/config/api.contable";
import sedeactual from "src/config/sedeactual";

// TRABAJADORES

export const getDeclaraciones = async (type) => {
  let res = await axios.get(`${apiContable}/declaration/${sedeactual.sede_id()}/${type}`);
  return res.data;
};

export const putIngresos = async (data, id) => {
  let res = await axios.put(`${apiContable}/declaration/${id}`, data);
  return res.data;
};
