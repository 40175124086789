import axios from "axios";
import { useEffect, useState } from "react";
import { apiCuentas } from "../config/api.contable";
import sedeactual from "../config/sedeactual";

export const napcontableApi = axios.create({
  baseURL: apiCuentas,
});

export const useAccounts = ({ sede, year }) => {
  const [accounts, setAccounts] = useState([]);
  const [loadingAccounts, setloading] = useState(true);

  const getAccountsApi = async () => {
    let res = await napcontableApi.get(
      `/accounting-plan/inventory/${sede}/${year}/${sedeactual.company().url}`
    );
    setAccounts(res.data);
    setloading(false);
  };

  useEffect(() => {
    getAccountsApi();
  }, []);

  return { dataCuentas: accounts, loadingAccounts };
};
