import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";

const PartSeven = ({ errors, register, eInf }) => {
  return (
    <Grid container>
      <Typography variant="body2" colot="textSecondary" gutterBottom>
        Indicar la fecha en que ocurrieron los hechos
      </Typography>
      <TextField
        InputLabelProps={{ shrink: true }}
        fullWidth
        label="Descripción *"
        name="descEvent"
        defaultValue={Boolean(eInf) ? eInf.descEvent : ""}
        variant="outlined"
        multiline
        rows={4}
        inputRef={register({ required: true })}
        error={Boolean(errors?.descEvent)}
      />
    </Grid>
  );
};

export default PartSeven;
