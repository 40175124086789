import { Button } from "@material-ui/core"
import { Menu, notification } from "antd"
import React, { useEffect, useState } from "react"
import ModalConfirmDelete from 'src/components/ModalConfirmDelete'
import Page from "src/components/Page"
import { apiInventario } from 'src/config/api.contable'
import sedeactual from "src/config/sedeactual"
import MemoDocument from "./components/Memorandums/MemoDocument"
import TableMemorandums from "./components/Memorandums/TableMemorandums"
import { getMemorandums } from "./requests"

const Memorandums = () => {
  const [ loading, setLoading ] = useState( false )
  const [ data, setData ] = useState( [] )
  const [ memo, setMemo ] = useState( { open: false, data: null } )
  const [ memoEra, setMemoEra ] = useState( { open: false, data: null } )

  useEffect( () => {
    obtainData()
  }, [] )

  const obtainData = async () => {
    try {
      setLoading( true )
      const res = await getMemorandums()
      setData( [ ...res.reverse() ] )
    } catch ( error ) {
      notification.error( { message: `Ocurrió un error al realizar la operación` } )
    } finally {
      setLoading( false )
    }
  }

  return (
    <Page title="MEMORANDUMS" helper="INVENTARIO">
      <TableMemorandums data={ data } loading={ loading } setMemo={ setMemo } setDeleteMemo={ setMemoEra } />

      { memo.open && <MemoDocument open={ memo.open } setOpen={ setMemo } data={ memo.data } /> }
      { memoEra.open && (
        <ModalConfirmDelete
          visible={ memoEra.open }
          setVisible={ setMemoEra }
          reload={ obtainData }
          endpoint={ `${ apiInventario }/memorandum/${ memoEra.data?._id }` }
          title="¿Seguro que desea eliminar a este memorandum?"
          subtitle="Ten en consideración que el registro ya no estará disponible"
          disable={ loading }
        />
      ) }
    </Page>
  )
}

export default Memorandums

export const firstPendingAction = ( data ) => {
  const temp = data.map( ( ev, index ) => ( { ...ev, key: index } ) )
  const toReturn = [
    ...temp?.filter( ( row ) => ( row?.memoInfo?.delete || row?.memoInfo?.transfer ) && row.memoInfo?.memorandum?.active ),
    ...temp?.filter( ( row ) => ( !row?.memoInfo?.delete && !row?.memoInfo?.transfer ) || !row.memoInfo?.memorandum?.active ),
  ]
  return toReturn
}

export const isHiddenByDelete = ( payload ) => {
  return payload.memoInfo?.delete && payload.memoInfo?.user === sedeactual.user_id() && payload.memoInfo?.memorandum?.active
}

export const isHiddenByTransfer = ( payload ) => {
  return payload.memoInfo?.transfer && payload.memoInfo?.user === sedeactual.user_id() && payload.memoInfo?.memorandum?.active
}

export const MenuDropDown = ( { toTransfer = true, toDelete = true, ...props } ) => (
  <Menu { ...props }>
    { toTransfer && (
      <Menu.Item key="transfer">
        <Button size="small" disabled={sedeactual.getRol() === "usuario"}>TRANSFERENCIA</Button>
      </Menu.Item>
    ) }
    { toDelete && (
      <Menu.Item key="delete">
        <Button size="small" disabled={sedeactual.getRol() === "usuario"}>DESHABILITACIÓN</Button>
      </Menu.Item>
    ) }
  </Menu>
)

export const selection = ( setter, prev ) => ( {
  selectedRowKeys: prev?.keys,
  onChange: ( selectedRowKeys, selectedRows ) => {
    setter( { ...prev, data: [ ...selectedRows ], keys: [ ...selectedRowKeys ] } )
  },
  getCheckboxProps: ( record ) => ( {
    disabled: ( record.memoInfo.transfer || record.memoInfo.delete ) && record.memoInfo?.memorandum?.active,
  } ),
} )
