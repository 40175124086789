import React, { useState, useEffect } from "react"
import { notification, Table, Tag } from "antd"
import { ButtonGroup, Button, TextField, Avatar, IconButton, Tooltip } from "@material-ui/core"
import AvatarGroup from "@material-ui/lab/AvatarGroup"
import Highlighter from "react-highlight-words"
import ClearIcon from "@material-ui/icons/Clear"
import SearchIcon from "@material-ui/icons/Search"
import SearchOutlined from "@material-ui/icons/SearchOutlined"
import DeleteIcon from "@material-ui/icons/Delete"
import MoreIcon from "@material-ui/icons/More"
import moment from "moment"
import ModalDetailsVehiculos from "../Vehiculos/components/ModalDetailsVehiculos"
import AddIcon from "@material-ui/icons/AddOutlined"
import PlusOneIcon from "@material-ui/icons/PlusOne"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import axios from "axios"
import { apiInventario } from "../../../../config/api.contable"
import EditIcon from "@material-ui/icons/Edit"
import EditVehiculos from "./components/EditVehiculos"
import ConfirmDelete from "../ModalConfirmDelete"
import { postEnableInventory } from "../../requests"
import napformat from "../../../../config/napformat"
import ModalConfirmDelete from "src/components/ModalConfirmDelete"
import CreateMemorandum from "../CreateMemorandum"
// import sedeactual from "src/config/sedeactual"
import { firstPendingAction, isHiddenByDelete, selection } from "../../Memorandums"

const noPhoto = "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"

const TableVehiculos = ( props ) => {
  const {
    dataTable,
    reloadFunction,
    idLocation,
    setLoading,
    loading,
    categories,
    onlyview = false,
    isActive,
    isCompact = false,
    memorandum,
    setMemorandum,
  } = props

  const [ searchedColumn, setsearchedColumn ] = useState( "" )
  const [ searchText, setsearchText ] = useState( "" )
  const [ openModalDetails, setOpenModalDetails ] = useState( false )
  const [ dataVehiculos, setDataVehiculos ] = useState( [] )
  const [ modalConfirmDelete, setModalConfirmDelete ] = useState( {
    idMueble: "",
    open: false,
  } )
  const [ modalEditVehiculos, setModalEditVehiculos ] = useState( {
    data: {},
    open: false,
  } )
  const [ delRecord, setdelRecord ] = useState( { open: false, id: null } )

  const getColumnSearchProps = ( dataIndex ) => ( {
    filterDropdown: ( { setSelectedKeys, selectedKeys, confirm, clearFilters } ) => (
      <div style={ { padding: 8 } }>
        <TextField
          variant="outlined"
          placeholder="Buscar..."
          size="small"
          value={ selectedKeys[ 0 ] }
          onChange={ ( e ) => {
            setSelectedKeys( e.target.value ? [ e.target.value ] : [] )
          } }
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSearch( selectedKeys, confirm, dataIndex ) 
            }
          }}
          style={ { width: 188, marginBottom: 8, display: "block" } }
        />
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={ () => handleReset( clearFilters, selectedKeys, setSelectedKeys ) }
          style={ { width: 90 } }
        >
          Limpiar <ClearIcon />
        </Button>{ " " }
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={ () => handleSearch( selectedKeys, confirm, dataIndex ) }
        >
          Buscar <SearchIcon />
        </Button>
      </div>
    ),
    filterIcon: ( filtered ) => <SearchOutlined style={ { color: filtered ? "" : "" } } />,
    onFilter: ( value, record ) =>
      record[ dataIndex ].toString().toLowerCase().includes( value.toLowerCase() ),
    onFilterDropdownVisibleChange: ( visible ) => {
      if ( visible ) {
      }
    },
    render: ( text ) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={ { backgroundColor: "#ffc069", padding: 0 } }
          searchWords={ [ searchText ] }
          autoEscape
          textToHighlight={ text.toString() }
        />
      ) : (
        text
      ),
  } )

  const handleSearch = ( selectedKeys, confirm, dataIndex ) => {
    confirm()
    setsearchText( selectedKeys[ 0 ] )
    setsearchedColumn( dataIndex )
  }

  const handleReset = ( clearFilters, selectedKeys, setSelectedKeys ) => {
    clearFilters()
    setsearchText( "" )
    setSelectedKeys( [ "" ] )
  }

  const enableInventory = async ( idRow, record ) => {
    setLoading( true )
    try {
      await postEnableInventory( "fixed-asset", idRow )
      reloadFunction()
      notification[ "success" ]( {
        message: "¡ÉXITO!",
        description: `Se habilitó correctamente el registro ${ record.correlative }.`,
      } )
    } catch ( error ) {
      notification[ "error" ]( {
        message: "¡ERROR!",
        description: `No se pudo habilitar el registro ${ record.correlative }.`,
      } )
    } finally {
      setLoading( false )
    }
  }

  const uploadDocs = async ( code, files ) => {
    if ( files.length > 0 ) {
      setLoading( true )
      let dataImages = new FormData()
      for ( let i = 0; i < files.length; i++ ) {
        dataImages.append( "docs", files[ i ] )
      }
      await axios
        .post( `${ apiInventario }/upload-doc-asset/${ code }`, dataImages )
        .then( ( res ) => {
          notification[ "success" ]( {
            message: "¡ÉXITO!",
            description: "El/Los documento(s) se guardaron correctamente.",
          } )
          reloadFunction()
        } )
        .catch( ( error ) => {
          notification[ "error" ]( {
            message: "¡ÉXITO!",
            description: `No se pudieron agregar el/los documento(s). ${ error }`,
          } )
        } )
        .finally( () => {
          setLoading( false )
        } )
    }
  }

  const uploadImages = async ( code, files ) => {
    if ( files.length > 0 ) {
      setLoading( true )
      let dataImages = new FormData()
      for ( let i = 0; i < files.length; i++ ) {
        dataImages.append( "images", files[ i ] )
      }
      await axios
        .post( `${ apiInventario }/upload-img-asset/${ code }`, dataImages )
        .then( ( res ) => {
          notification[ "success" ]( {
            message: "¡ÉXITO!",
            description: "La(s) imagen(es) se guardaron correctamente.",
          } )
          reloadFunction()
        } )
        .catch( ( error ) => {
          notification[ "error" ]( {
            message: "¡ÉXITO!",
            description: `No se pudieron agregar la(s) imagen(es). ${ error }`,
          } )
        } )
        .finally( () => {
          setLoading( false )
        } )
    }
  }

  const columns = [
    {
      title: "ACCIONES",
      dataIndex: "_id",
      key: "_id",
      width: 140,
      align: "center",
      render: ( text, record, index ) => (
        <ButtonGroup style={ { width: "100%", justifyContent: "center", background: "#fff" } }>
          { !onlyview && !isActive && (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={ () => setdelRecord( { open: true, id: text } ) }
            >
              <Tooltip title="ELIMINAR">
                <DeleteIcon />
              </Tooltip>
            </Button>
          ) }
          { onlyview === false &&
            ( isActive ? (
              isHiddenByDelete( record ) && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={ () => setModalConfirmDelete( { idMueble: text, open: true } ) }
                >
                  <Tooltip title="Eliminar">
                    <DeleteIcon />
                  </Tooltip>
                </Button>
              )
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={ { backgroundColor: "#4caf50" } }
                onClick={ () => enableInventory( text, record ) }
              >
                <Tooltip title="Habilitar">
                  <PlusOneIcon />
                </Tooltip>
              </Button>
            ) ) }
          { onlyview === false && isActive && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={ () => setModalEditVehiculos( { data: record, open: true } ) }
            >
              <Tooltip title="Editar">
                <EditIcon />
              </Tooltip>
            </Button>
          ) }
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={ () => {
              setOpenModalDetails( true )
              setDataVehiculos( record )
            } }
          >
            <Tooltip title="Detalles">
              <MoreIcon />
            </Tooltip>
          </Button>
        </ButtonGroup>
      ),
    },
    {
      title: "CORRELATIVO",
      dataIndex: "correlative",
      key: "correlative",
      width: 200,
      align: "center",
      sorter: ( a, b ) => {
        if ( a.correlative > b.correlative ) return 1
        if ( a.correlative < b.correlative ) return -1
      },
      ...getColumnSearchProps( "correlative" ),
      render: ( value, record ) => (
        <>
          { value }
          <br />
          { ( ( record.memoInfo?.delete || record.memoInfo?.transfer ) && record.memoInfo?.memorandum?.active ) && (
            <Tag style={ { background: "#FFC107", color: "#fff", borderColor: "#FFC107" } }>
              { `${ record.memoInfo?.delete ? "ELIMINACIÓN" : "" } 
                ${ record.memoInfo?.delete ? ( record.memoInfo?.transfer ? "Y" : "" ) : "" } 
                ${ record.memoInfo?.transfer ? "TRANSFERENCIA" : "" }` }
            </Tag>
          ) }
        </>
      ),
    },
    {
      title: "CATEGORÍA DEL BIEN",
      dataIndex: "_id",
      key: "_idCat",
      width: 200,
      align: "center",
      render: ( a, b ) => (
        <span>
          { b.category.name } <sub>{ b.category.account_number }</sub>
        </span>
      ),
    },
    {
      title: "SUBCATEGORÍA",
      dataIndex: "_id",
      key: "_idSub",
      width: 200,
      align: "center",
      render: ( a, b ) => b.subcategory?.name ?? "",
    },
    {
      title: "MARCA DEL ACTIVO FIJO",
      dataIndex: "trademark",
      key: "trademark",
      width: 200,
      align: "center",
      ...getColumnSearchProps( "trademark" ),
      sorter: ( a, b ) => {
        if ( a.trademark > b.trademark ) return 1
        if ( a.trademark < b.trademark ) return -1
      },
    },
    {
      title: "MODELO DEL ACTIVO FIJO",
      dataIndex: "model",
      key: "model",
      align: "center",
      width: 180,
      ...getColumnSearchProps( "model" ),
      sorter: ( a, b ) => {
        if ( a.model > b.model ) return 1
        if ( a.model < b.model ) return -1
      },
      render: ( text, record, index ) => <span>{ text }</span>,
    },
    {
      title: "N° de motor",
      dataIndex: "motor_number",
      key: "motor_number",
      width: 175,
      align: "center",
      ...getColumnSearchProps( "motor_number" ),
      sorter: ( a, b ) => {
        if ( a.motor_number > b.motor_number ) return 1
        if ( a.motor_number < b.motor_number ) return -1
      },
      render: ( text, record, index ) => <span>{ text }</span>,
    },
    {
      title: "Partida Registral",
      dataIndex: "registration_starting",
      key: "registration_starting",
      width: 175,
      align: "center",
      ...getColumnSearchProps( "registration_starting" ),
      sorter: ( a, b ) => {
        if ( a.registration_starting > b.registration_starting ) return 1
        if ( a.registration_starting < b.registration_starting ) return -1
      },
      render: ( text, record, index ) => <span>{ text }</span>,
    },
    {
      title: "DESCRIPCIÓN TÉCNICA",
      children: [
        {
          title: "N° DE EJES",
          dataIndex: "axles_number",
          key: "axles_number",
          width: 175,
          align: "center",
          ...getColumnSearchProps( "axles_number" ),
          sorter: ( a, b ) => {
            if ( a.axles_number > b.axles_number ) return 1
            if ( a.axles_number < b.axles_number ) return -1
          },
          render: ( text, record, index ) => (
            <span>{ record.axles_number ? record.axles_number + " EJES" : " - " }</span>
          ),
        },
        {
          title: "N° DE ASIENTOS",
          dataIndex: "seats_number",
          key: "seats_number",
          width: 175,
          align: "center",
          ...getColumnSearchProps( "seats_number" ),
          sorter: ( a, b ) => {
            if ( a.seats_number > b.seats_number ) return 1
            if ( a.seats_number < b.seats_number ) return -1
          },
          render: ( text, record, index ) => (
            <span>{ record.seats_number ? record.seats_number + " ASIENTOS" : " - " }</span>
          ),
        },
        {
          title: "N° DE RUEDAS",
          dataIndex: "wheels_number",
          key: "wheels_number",
          width: 175,
          align: "center",
          ...getColumnSearchProps( "wheels_number" ),
          sorter: ( a, b ) => {
            if ( a.wheels_number > b.wheels_number ) return 1
            if ( a.wheels_number < b.wheels_number ) return -1
          },
          render: ( text, record, index ) => (
            <span>{ record.wheels_number ? record.wheels_number + " RUEDAS" : " - " }</span>
          ),
        },
        {
          title: "CARROCERÍA",
          dataIndex: "bodywork",
          key: "bodywork",
          width: 175,
          align: "center",
          ...getColumnSearchProps( "bodywork" ),
          sorter: ( a, b ) => {
            if ( a.bodywork > b.bodywork ) return 1
            if ( a.bodywork < b.bodywork ) return -1
          },
          render: ( text, record, index ) => <span>{ record.bodywork ? record.bodywork : " - " }</span>,
        },
        {
          title: "COMBUSTIBLE",
          dataIndex: "fuel",
          key: "fuel",
          width: 175,
          align: "center",
          ...getColumnSearchProps( "fuel" ),
          sorter: ( a, b ) => {
            if ( a.fuel > b.fuel ) return 1
            if ( a.fuel < b.fuel ) return -1
          },
          render: ( text, record, index ) => <span>{ record.fuel ? record.fuel : " - " }</span>,
        },
        {
          title: "COLOR",
          dataIndex: "color",
          key: "color",
          width: 175,
          align: "center",
          ...getColumnSearchProps( "color" ),
          sorter: ( a, b ) => {
            if ( a.color > b.color ) return 1
            if ( a.color < b.color ) return -1
          },
          render: ( text, record, index ) => <span>{ record.color ? record.color : " - " }</span>,
        },
        {
          title: "AÑO DE FABRICACIÓN",
          dataIndex: "production_year",
          key: "production_year",
          width: 175,
          align: "center",
          ...getColumnSearchProps( "production_year" ),
          sorter: ( a, b ) => {
            if ( a.production_year > b.production_year ) return 1
            if ( a.production_year < b.production_year ) return -1
          },
          render: ( text, record, index ) => (
            <span>{ record.production_year ? record.production_year : " - " }</span>
          ),
        },
      ],
      /* dataIndex: "motor_number",
      key: "motor_number",
      width: 175,
      align: "center",
      ...getColumnSearchProps("motor_number"),
      sorter: (a, b) => {
        if (a.motor_number > b.motor_number) return 1;
        if (a.motor_number < b.motor_number) return -1;
      },
      render: (text, record, index) => <span>{text}</span>, */
    },
    {
      title: "PLACA DE VEHÍCULO",
      dataIndex: "vehicle_plate",
      key: "vehicle_plate",
      width: 175,
      align: "center",
      ...getColumnSearchProps( "vehicle_plate" ),
      sorter: ( a, b ) => {
        if ( a.vehicle_plate > b.vehicle_plate ) return 1
        if ( a.vehicle_plate < b.vehicle_plate ) return -1
      },
      render: ( text, record, index ) => <span>{ text }</span>,
    },
    {
      title: "FECHA ADQUISICIÓN",
      dataIndex: "acquisition_date",
      key: "acquisition_date",
      width: 150,
      align: "center",
      sorter: ( a, b ) => {
        if ( a.acquisition_date > b.acquisition_date ) return 1
        if ( a.acquisition_date < b.acquisition_date ) return -1
      },
      render: ( text ) => moment( text ).utc( false ).format( "DD/MM/YYYY" ),
    },
    {
      title: "VALOR HISTÓRICO",
      dataIndex: "historical_value",
      key: "historical_value",
      width: 175,
      align: "center",
      ...getColumnSearchProps( "historical_value" ),
      sorter: ( a, b ) => {
        if ( a.historical_value > b.historical_value ) return 1
        if ( a.historical_value < b.historical_value ) return -1
      },
      render: ( text, record, index ) => (
        <span>
          <sup>S/</sup>
          { napformat( text ) }
        </span>
      ),
    },

    /* {
      title: "NOMBRE DE LA CUENTA",
      dataIndex: "account_name",
      key: "account_name",
      width: 250,
      align: "center",
      ...getColumnSearchProps("account_name"),
      sorter: (a, b) => {
        if (a.account_name > b.account_name) return 1;
        if (a.account_name < b.account_name) return -1;
      },
      render: (text, record, index) => <span>{text.toUpperCase()}</span>,
    },
    {
      title: "CUENTA CONTABLE",
      dataIndex: "accounting_account",
      key: "accounting_account",
      width: 150,
      align: "center",
      ...getColumnSearchProps("accounting_account"),
      sorter: (a, b) => {
        if (a.accounting_account > b.accounting_account) return 1;
        if (a.accounting_account < b.accounting_account) return -1;
      },
    }, */
    {
      title: "HABILITADO",
      dataIndex: "active",
      key: "active",
      width: 125,
      // ...getColumnSearchProps( "active" ),
      align: "center",
      sorter: ( a, b ) => {
        if ( a.active > b.active ) return 1
        if ( a.active < b.active ) return -1
      },
      render: ( text, record, index ) =>
        text === true ? (
          <FiberManualRecordIcon style={ { color: "#4caf50" } } />
        ) : (
          <FiberManualRecordIcon style={ { color: "#e53935" } } />
        ),
    },
    {
      title: "ESTADO",
      dataIndex: "status",
      key: "status",
      width: 125,
      ...getColumnSearchProps( "status" ),
      align: "center",
      sorter: ( a, b ) => {
        if ( a.status > b.status ) return 1
        if ( a.status < b.status ) return -1
      },
    },
    {
      title: "IMAGENES",
      dataIndex: "img",
      key: "img",
      align: "center",
      width: 175,
      render: ( text, record, index ) => (
        <AvatarGroup max={ 4 } style={ { paddingLeft: 5 } }>
          { onlyview === false && (
            <IconButton
              style={ {
                padding: 0,
                height: 40,
                width: 40,
                backgroundColor: "#0688d1",
                color: "white",
              } }
              component="label"
            >
              <AddIcon />
              <input
                style={ { display: "none" } }
                accept="image/*"
                type="file"
                onChange={ ( event ) => uploadImages( record._id, event.target.files ) }
                multiple
              />
            </IconButton>
          ) }
          { record.images &&
            record.images
              .slice( 0, 3 )
              .map( ( image ) => <Avatar style={ { border: "1px solid #2196f3" } } src={ image } /> ) }
          { record.images && record.images.length > 0 ? (
            record.images.length > 3 && <Avatar>+{ record.images.length - 3 }</Avatar>
          ) : (
            <Avatar src={ noPhoto } />
          ) }
        </AvatarGroup>
      ),
    },
    {
      title: "DOCUMENTOS SUSTENTATORIOS",
      dataIndex: "img",
      key: "img",
      align: "center",
      width: 170,
      render: ( text, record, index ) => (
        <AvatarGroup>
          <>
            { onlyview === false && (
              <IconButton
                style={ {
                  padding: 0,
                  height: 40,
                  width: 40,
                  backgroundColor: "#0688d1",
                  color: "white",
                } }
                component="label"
              >
                <AddIcon />
                <input
                  style={ { display: "none" } }
                  accept="application/pdf, image/png, image/jpeg"
                  type="file"
                  onChange={ ( event ) => uploadDocs( record._id, event.target.files ) }
                  multiple
                />
              </IconButton>
            ) }
            <Avatar>{ record.docs.length }</Avatar>
          </>
        </AvatarGroup>
      ),
    },
  ]

  const [ columnas, setColumnas ] = useState( [ ...columns ] )

  useEffect( () => {
    if ( isCompact ) {
      const filtered = columns.filter(
        ( ev ) => ![ "CORRELATIVO", "IMAGENES", "DOCUMENTOS SUSTENTATORIOS" ].includes( ev.title )
      )
      setColumnas( [
        {
          title: "CANTIDAD",
          dataIndex: "quantity",
          key: "quantity",
          width: 150,
          align: "center",
          sorter: ( a, b ) => {
            if ( a.quantity > b.quantity ) return 1
            if ( a.quantity < b.quantity ) return -1
          },
        },
        ...filtered,
      ] )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  return (
    <>
      { modalEditVehiculos.open && (
        <EditVehiculos
          visible={ modalEditVehiculos.open }
          setVisible={ setModalEditVehiculos }
          data={ modalEditVehiculos.data }
          reloadFunction={ reloadFunction }
          categories={ categories }
          loading={ loading }
          setLoading={ setLoading }
        />
      ) }

      { modalConfirmDelete.open && (
        <ConfirmDelete
          disable={ true }
          visible={ modalConfirmDelete.open }
          setVisible={ setModalConfirmDelete }
          reload={ reloadFunction }
          endPoint={ `fixed-asset/${ modalConfirmDelete.idMueble }` }
          title="¿Esta seguro(a) que desea deshabilitar este registro?"
          subtitle="Tener en cuenta que después de realizar esta operación el registro estará en la lista de deshabilitado."
        />
      ) }
      <Table
        rowSelection={ { type: "checkbox", ...selection( setMemorandum, memorandum ) } }
        columns={ columnas }
        dataSource={ firstPendingAction( dataTable ) }
        bordered
        size="small"
        scroll={ { x: "100%" } }
        loading={ loading }
      />
      { openModalDetails && (
        <ModalDetailsVehiculos
          visible={ openModalDetails }
          setVisible={ setOpenModalDetails }
          reload={ reloadFunction }
          dataVehiculos={ dataVehiculos }
          nameLocation="[NOMBRE DE LA LOCACIÓN]"
          idLocation={ idLocation }
        />
      ) }
      { delRecord.open && (
        <ModalConfirmDelete
          visible={ delRecord.open }
          setVisible={ setdelRecord }
          reload={ reloadFunction }
          endpoint={ `${ apiInventario }/fixed-asset-remove/${ delRecord.id }` }
          title="¿SEGURO QUE DESEA ELIMINAR ESTE REGISTRO?"
          subtitle="TEN EN CONSIDERACIÓN QUE EL REGISTRO YA NO ESTARÁ DISPONIBLE PARA SU USO O MODIFICACIÓN."
          disable={ loading }
        />
      ) }

      { memorandum?.open && (
        <CreateMemorandum
          open={ memorandum.open }
          setOpen={ setMemorandum }
          data={ memorandum.data }
          type={ memorandum.type }
          handleReload={ reloadFunction }
          param="fixedasset"
          action={ `${ memorandum.type === "delete" ? "DESHABILITACIÓN" : "TRANSFERENCIA"
            } DE VEHÍCULO(S)` }
        />
      ) }
    </>
  )
}

export default TableVehiculos
