import React, { useState } from "react";
import { Table } from "antd";
import { Button, TextField } from "@material-ui/core";
import ModalEditCostos from "./ModalEditCostos";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import Highlighter from "react-highlight-words";

const TablaCostos = (props) => {
  const { data, reload, loading, setLoading } = props;
  const [searchText, setsearchText] = useState("");
  const [openModalEditCostos, setOpenModalEditCostos] = useState(false);
  const [searchedColumn, setsearchedColumn] = useState("");
  const [dataCostos, setDataCostos] = useState([]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setsearchText(selectedKeys[0]);
    setsearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, selectedKeys, setSelectedKeys) => {
    clearFilters();
    setsearchText("");
    setSelectedKeys([""]);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <TextField
          variant="outlined"
          placeholder="Buscar..."
          size="small"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onKeyPress={(e) => e.key === "Enter" && handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={() =>
            handleReset(clearFilters, selectedKeys, setSelectedKeys)
          }
          style={{ width: 90 }}
        >
          Limpiar <ClearIcon />
        </Button>{" "}
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
        >
          Buscar <SearchIcon />
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchIcon style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "CÓDIGO",
      dataIndex: "code",
      key: "code",
      align: "center",
    },
    {
      title: "DESCRIPCIÓN",
      dataIndex: "description",
      key: "description",
      align: "center",
      sorter: (a, b) => {
        if (a.description > b.description) return 1;
        if (a.description < b.description) return -1;
      },
      ...getColumnSearchProps("description"),
      render: (text, record, index) => text && text.toUpperCase(),
    },
    {
      title: "ACCIONES",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      render: (text, record, index) => (
        <Button
          variant="contained"
          color="primary"
          disabled={text ? false : true}
          size="small"
          onClick={() => {
            setDataCostos(record);
            setOpenModalEditCostos(true);
          }}
          style={{ margin: 2 }}
        >
          <EditIcon />
        </Button>
      ),
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={data} bordered loading={loading} />
      {openModalEditCostos && (
        <ModalEditCostos
          visible={openModalEditCostos}
          setVisible={setOpenModalEditCostos}
          dataCostos={dataCostos}
          reload={reload}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

export default TablaCostos;
