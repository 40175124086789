import React, { useState } from "react"
import { notification, Table, Tag } from "antd"
import { ButtonGroup, Button, TextField, Avatar, IconButton, Tooltip } from "@material-ui/core"
import AvatarGroup from "@material-ui/lab/AvatarGroup"
import Highlighter from "react-highlight-words"
import ClearIcon from "@material-ui/icons/Clear"
import SearchIcon from "@material-ui/icons/Search"
import SearchOutlined from "@material-ui/icons/SearchOutlined"
import AddIcon from "@material-ui/icons/AddToPhotos"
import EditIcon from "@material-ui/icons/Edit"
import MoreIcon from "@material-ui/icons/More"
import DeleteIcon from "@material-ui/icons/Delete"
import PlusOneIcon from "@material-ui/icons/PlusOne"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import ConfirmDelete from "../../ModalConfirmDelete"
import ModalEdit from "./ModalEdit"
import ModalDetail from "./ModalDetail"
import moment from "moment"
import { apiInventario } from "../../../../../config/api.contable"
import { postEnableInventory } from "../../../requests"
import axios from "axios"
import napformat from "../../../../../config/napformat"
import CreateMemorandum from "../../CreateMemorandum"
import { firstPendingAction, isHiddenByDelete, selection } from "src/views/Inventory/Memorandums"

const TableEdificaciones = ( props ) => {
  const {
    dataTable,
    reloadFunction,
    setLoading,
    loading,
    onlyview = false,
    isActive,
    memorandum,
    setMemorandum,
  } = props
  const [ searchedColumn, setsearchedColumn ] = useState( "" )
  const [ searchText, setsearchText ] = useState( "" )
  const [ visibleModalEdit, setVisibleModalEdit ] = useState( {
    data: {},
    open: false,
  } )
  const [ visibleModalDetail, setVisibleModalDetail ] = useState( {
    data: {},
    open: false,
  } )
  const [ modalConfirmDelete, setModalConfirmDelete ] = useState( {
    idEdificacion: "",
    open: false,
  } )

  const getColumnSearchProps = ( dataIndex ) => ( {
    filterDropdown: ( { setSelectedKeys, selectedKeys, confirm, clearFilters } ) => (
      <div style={ { padding: 8 } }>
        <TextField
          variant="outlined"
          placeholder="Buscar..."
          size="small"
          value={ selectedKeys[ 0 ] }
          onChange={ ( e ) => {
            setSelectedKeys( e.target.value ? [ e.target.value ] : [] )
          } }
          onPressEnter={ () => handleSearch( selectedKeys, confirm, dataIndex ) }
          style={ { width: 188, marginBottom: 8, display: "block" } }
        />
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={ () => handleReset( clearFilters, selectedKeys, setSelectedKeys ) }
          style={ { width: 90 } }
        >
          Limpiar <ClearIcon />
        </Button>{ " " }
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={ () => handleSearch( selectedKeys, confirm, dataIndex ) }
        >
          Buscar <SearchIcon />
        </Button>
      </div>
    ),
    filterIcon: ( filtered ) => <SearchOutlined style={ { color: filtered ? "" : "" } } />,
    onFilter: ( value, record ) =>
      record[ dataIndex ].toString().toLowerCase().includes( value.toLowerCase() ),
    onFilterDropdownVisibleChange: ( visible ) => {
      if ( visible ) {
      }
    },
    render: ( text ) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={ { backgroundColor: "#ffc069", padding: 0 } }
          searchWords={ [ searchText ] }
          autoEscape
          textToHighlight={ text.toString() }
        />
      ) : (
        text
      ),
  } )

  const enableInventory = async ( idRow, record ) => {
    setLoading( true )
    try {
      await postEnableInventory( "edification", idRow )
      notification[ "success" ]( {
        message: "¡EXITO!",
        description: `Se habilitó correctamente el correlativo ${ record.correlative }.`,
      } )
      reloadFunction()
    } catch ( error ) {
      notification[ "error" ]( {
        message: "¡ERROR!",
        description: "Ocurrió un error al actualizar la información.",
      } )
    } finally {
      setLoading( false )
    }
  }

  const uploadImages = async ( code, files ) => {
    if ( files.length > 0 ) {
      setLoading( true )
      let dataImages = new FormData()
      for ( let i = 0; i < files.length; i++ ) {
        dataImages.append( "images", files[ i ] )
      }
      await axios
        .post( `${ apiInventario }/upload-img-edification/${ code }`, dataImages )
        .then( ( res ) => {
          notification[ "success" ]( {
            message: "¡EXITO!",
            description: "La(s) imagen(es) se guardaron correctamente.",
          } )
          reloadFunction()
        } )
        .catch( ( error ) => {
          notification[ "success" ]( {
            message: "¡ERROR!",
            description: `No se pudieron agregar la(s) imagen(es). ${ error }`,
          } )
        } )
        .finally( () => {
          setLoading( false )
        } )
    }
  }

  const uploadDocs = async ( code, files ) => {
    if ( files.length > 0 ) {
      setLoading( true )
      let dataImages = new FormData()
      for ( let i = 0; i < files.length; i++ ) {
        dataImages.append( "docs", files[ i ] )
      }
      await axios
        .post( `${ apiInventario }/upload-doc-edification/${ code }`, dataImages )
        .then( ( res ) => {
          notification[ "success" ]( {
            message: "¡EXITO!",
            description: "El/Los documento(s) se guardaron correctamente.",
          } )
          reloadFunction()
        } )
        .catch( ( error ) => {
          notification[ "success" ]( {
            message: "¡ERROR!",
            description: `No se pudieron agregar el/los documento(s). ${ error }`,
          } )
        } )
        .finally( () => {
          setLoading( false )
        } )
    }
  }

  const handleSearch = ( selectedKeys, confirm, dataIndex ) => {
    confirm()

    setsearchText( selectedKeys[ 0 ] )
    setsearchedColumn( dataIndex )
  }

  const handleReset = ( clearFilters, selectedKeys, setSelectedKeys ) => {
    clearFilters()
    setsearchText( "" )
    setSelectedKeys( [ "" ] )
  }

  const columns = [
    {
      title: "ACCIONES",
      dataIndex: "_id",
      key: "_id",
      width: 140,
      align: "center",
      render: ( text, record, index ) => (
        <ButtonGroup style={ { width: "100%", background: "#fff" } }>
          { onlyview === false &&
            ( isActive ? (
              isHiddenByDelete( record ) && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={ () => setModalConfirmDelete( { idEdificacion: text, open: true } ) }
                >
                  <Tooltip title="Deshabilitar" aria-label="disable">
                    <DeleteIcon />
                  </Tooltip>
                </Button>
              )
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={ { backgroundColor: "#4caf50" } }
                onClick={ () => enableInventory( text, record ) }
              >
                <Tooltip title="Habilitar">
                  <PlusOneIcon />
                </Tooltip>
              </Button>
            ) ) }
          { isActive && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={ () => setVisibleModalEdit( { data: record, open: true } ) }
            >
              <EditIcon />
            </Button>
          ) }
          <Button
            variant="contained"
            color="primary"
            onClick={ () => setVisibleModalDetail( { data: record, open: true } ) }
          >
            <MoreIcon />
          </Button>
        </ButtonGroup>
      ),
    },
    {
      title: "CORRELATIVO",
      dataIndex: "correlative",
      key: "correlative",
      width: 200,
      align: "center",
      sorter: ( a, b ) => {
        if ( a.correlative > b.correlative ) return 1
        if ( a.correlative < b.correlative ) return -1
      },
      ...getColumnSearchProps( "correlative" ),
      render: ( value, record ) => (
        <>
          { value }
          <br />
          { ( ( record.memoInfo?.delete || record.memoInfo?.transfer ) && record.memoInfo?.memorandum?.active ) && (
            <Tag style={ { background: "#FFC107", color: "#fff", borderColor: "#FFC107" } }>
              { `${ record.memoInfo?.delete ? "ELIMINACIÓN" : "" } 
                ${ record.memoInfo?.delete ? ( record.memoInfo?.transfer ? "Y" : "" ) : "" } 
                ${ record.memoInfo?.transfer ? "TRANSFERENCIA" : "" }` }
            </Tag>
          ) }
        </>
      ),
    },
    {
      title: "DENOMINACIÓN DEL PREDIO",
      dataIndex: "property_name",
      key: "property_name",
      width: 200,
      align: "center",
      ...getColumnSearchProps( "property_name" ),
      sorter: ( a, b ) => {
        if ( a.property_name > b.property_name ) return 1
        if ( a.property_name < b.property_name ) return -1
      },
      render: ( text, record, index ) => (
        <span style={ { float: "left", marginLeft: "5px" } }>{ text.toUpperCase() }</span>
      ),
    },
    {
      title: "DIRECCIÓN",
      dataIndex: "address",
      key: "address",
      align: "center",
      width: 200,
      ...getColumnSearchProps( "address" ),
      sorter: ( a, b ) => {
        if ( a.address > b.address ) return 1
        if ( a.address < b.address ) return -1
      },
      render: ( text, record, index ) => (
        <span style={ { float: "left", marginLeft: "5px" } }>{ text.toUpperCase() }</span>
      ),
    },
    {
      title: "ÁREA DEL TERRENO",
      dataIndex: "area",
      key: "area",
      align: "center",
      width: 150,
      sorter: ( a, b ) => {
        if ( a.area > b.area ) return 1
        if ( a.area < b.area ) return -1
      },
      render: ( text, all, index ) => (
        <span>
          { napformat( text ) } mt<sup>2</sup>
        </span>
      ),
    },
    {
      title: "PERÍMETRO",
      children: [
        {
          title: "Lado A",
          dataIndex: "side_a",
          key: "side_a",
          width: 125,
          align: "center",
          sorter: ( a, b ) => {
            if ( a.side_a > b.side_a ) return 1
            if ( a.side_a < b.side_a ) return -1
          },
          render: ( text, all, index ) => ( text ? <span>{ napformat( text ) } mt</span> : "" ),
        },
        {
          title: "Lado B",
          dataIndex: "side_b",
          key: "side_b",
          width: 125,
          align: "center",
          sorter: ( a, b ) => {
            if ( a.side_b > b.side_b ) return 1
            if ( a.side_b < b.side_b ) return -1
          },
          render: ( text, all, index ) => ( text ? <span>{ napformat( text ) } mt</span> : "" ),
        },
        {
          title: "Lado C",
          dataIndex: "side_c",
          key: "side_c",
          width: 125,
          align: "center",
          sorter: ( a, b ) => {
            if ( a.side_c > b.side_c ) return 1
            if ( a.side_c < b.side_c ) return -1
          },
          render: ( text, all, index ) => ( text ? <span>{ napformat( text ) } mt</span> : "" ),
        },
        {
          title: "Lado D",
          dataIndex: "side_d",
          key: "side_d",
          width: 125,
          align: "center",
          sorter: ( a, b ) => {
            if ( a.side_d > b.side_d ) return 1
            if ( a.side_d < b.side_d ) return -1
          },
          render: ( text, all, index ) => ( text ? <span>{ napformat( text ) } mt</span> : "" ),
        },
        {
          title: "Lado E",
          dataIndex: "side_another",
          key: "side_another",
          width: 125,
          align: "center",
          sorter: ( a, b ) => {
            if ( a.side_another > b.side_another ) return 1
            if ( a.side_another < b.side_another ) return -1
          },
          render: ( text, all, index ) => ( text ? <span>{ napformat( text ) } mt</span> : "" ),
        },
      ],
    },
    {
      title: "ÁREA CONSTRUIDA",
      dataIndex: "built_area",
      key: "built_area",
      width: 150,
      align: "center",
      sorter: ( a, b ) => {
        if ( a.built_area > b.built_area ) return 1
        if ( a.built_area < b.built_area ) return -1
      },
      render: ( text, all, index ) =>
        text ? (
          <span>
            { napformat( text ) } mt<sup>2</sup>
          </span>
        ) : (
          ""
        ),
    },
    {
      title: "N° PARTIDA ELECTRÓNICA",
      dataIndex: "departure_number",
      key: "departure_number",
      width: 150,
      align: "center",
      sorter: ( a, b ) => {
        if ( a.departure_number > b.departure_number ) return 1
        if ( a.departure_number < b.departure_number ) return -1
      },
      render: ( text, all, index ) => ( text ? <span>N° { text }</span> : "" ),
    },
    {
      title: "FECHA ADQUISIÓN",
      dataIndex: "acquisition_date",
      key: "acquisition_date",
      width: 125,
      align: "center",
      sorter: ( a, b ) => {
        if ( a.acquisition_date > b.acquisition_date ) return 1
        if ( a.acquisition_date < b.acquisition_date ) return -1
      },
      render: ( text ) => moment( text ).utc( false ).format( "DD/MM/YYYY" ),
    },
    {
      title: "VALOR ADQUISIÓN DEL TERRENO",
      dataIndex: "acquisition_value",
      key: "acquisition_value",
      width: 180,
      align: "center",
      sorter: ( a, b ) => {
        if ( a.acquisition_value > b.acquisition_value ) return 1
        if ( a.acquisition_value < b.acquisition_value ) return -1
      },
      render: ( text, all, index ) =>
        text ? (
          <span>
            <sup>S/</sup> { napformat( text ) }
          </span>
        ) : (
          ""
        ),
    },
    {
      title: "VALOR ADQUISIÓN DE LA CONSTRUCCIÓN",
      dataIndex: "acquisition_build_value",
      key: "acquisition_build_value",
      width: 180,
      align: "center",
      sorter: ( a, b ) => {
        if ( a.acquisition_build_value > b.acquisition_build_value ) return 1
        if ( a.acquisition_build_value < b.acquisition_build_value ) return -1
      },
      render: ( text, all, index ) =>
        text ? (
          <span>
            <sup>S/</sup> { napformat( text ) }
          </span>
        ) : (
          ""
        ),
    },
    {
      title: "VALOR TOTAL DEL BIEN",
      dataIndex: "totalbien",
      key: "totalbien",
      width: 175,
      align: "center",
      sorter: ( a, b ) => {
        if ( a.totalbien > b.totalbien ) return 1
        if ( a.totalbien < b.totalbien ) return -1
      },
      render: ( text, row, index ) =>
        row.acquisition_value && row.acquisition_build_value ? (
          <span>
            <sup>S/</sup> { napformat( row.acquisition_value + row.acquisition_build_value ) }
          </span>
        ) : (
          "No definida"
        ),
    },
    {
      title: "AUTOVALUO MUNICIPALIDAD TERRENO",
      dataIndex: "ground_autovalue",
      key: "ground_autovalue",
      width: 200,
      align: "center",
      sorter: ( a, b ) => {
        if ( a.ground_autovalue > b.ground_autovalue ) return 1
        if ( a.ground_autovalue < b.ground_autovalue ) return -1
      },
      render: ( text, all, index ) =>
        text ? (
          <span>
            <sup>S/</sup> { napformat( text ) }
          </span>
        ) : (
          ""
        ),
    },
    {
      title: "AUTOVALUO MUNICIPALIDAD CONSTRUCCIÓN",
      dataIndex: "build_autovalue",
      key: "build_autovalue",
      width: 225,
      align: "center",
      sorter: ( a, b ) => {
        if ( a.build_autovalue > b.build_autovalue ) return 1
        if ( a.build_autovalue < b.build_autovalue ) return -1
      },
      render: ( text, all, index ) =>
        text ? (
          <span>
            <sup>S/</sup> { napformat( text ) }
          </span>
        ) : (
          ""
        ),
    },
    {
      title: "TOTAL VALOR AUTOVALUO",
      dataIndex: "totalautovaluo",
      key: "totalautovaluo",
      width: 150,
      align: "center",
      sorter: ( a, b ) => {
        if ( a.totalautovaluo > b.totalautovaluo ) return 1
        if ( a.totalautovaluo < b.totalautovaluo ) return -1
      },
      render: ( text, row, index ) =>
        row.ground_autovalue && row.build_autovalue ? (
          <span>
            <sup>S/</sup> { napformat( row.ground_autovalue + row.build_autovalue ) }
          </span>
        ) : (
          "No definida"
        ),
    },
    {
      title: "OBSERVACIONES",
      dataIndex: "observations",
      key: "observations",
      width: 250,
      align: "center",
      render: ( text, record, index ) => (
        <span style={ { float: "left", marginLeft: "5px" } }>{ text.toUpperCase() }</span>
      ),
    },
    {
      title: "ESTADO",
      dataIndex: "active",
      key: "active",
      width: 125,
      ...getColumnSearchProps( "active" ),
      align: "center",
      sorter: ( a, b ) => {
        if ( a.active > b.active ) return 1
        if ( a.active < b.active ) return -1
      },
      render: ( text, record, index ) =>
        text === true ? (
          <FiberManualRecordIcon style={ { color: "#4caf50" } } />
        ) : (
          <FiberManualRecordIcon style={ { color: "#e53935" } } />
        ),
    },
    {
      title: "IMAGENES",
      dataIndex: "images",
      key: "images",
      width: 175,
      align: "center",
      render: ( text, record, index ) => (
        <AvatarGroup max={ 4 } style={ { paddingLeft: 5 } }>
          <IconButton
            style={ {
              padding: 0,
              height: 40,
              width: 40,
              backgroundColor: "#0688d1",
              color: "white",
            } }
            component="label"
          >
            <AddIcon />
            <input
              style={ { display: "none" } }
              accept="image/*"
              type="file"
              onChange={ ( event ) => uploadImages( record._id, event.target.files ) }
              multiple
            />
          </IconButton>
          { text.slice( 0, 3 ).map( ( ev ) => (
            <Avatar src={ ev } />
          ) ) }
          { text.length > 3 && <Avatar>+{ text.length - 3 }</Avatar> }
        </AvatarGroup>
      ),
    },
    {
      title: "DOCUMENTOS SUSTENTATORIOS",
      dataIndex: "list_docs",
      key: "list_docs",
      align: "center",
      width: 125,
      render: ( text, record, index ) => (
        <AvatarGroup>
          <>
            <IconButton
              style={ {
                padding: 0,
                height: 40,
                width: 40,
                backgroundColor: "#0688d1",
                color: "white",
              } }
              component="label"
            >
              <AddIcon />
              <input
                style={ { display: "none" } }
                accept="application/pdf, image/png, image/jpeg"
                type="file"
                multiple
                onChange={ ( event ) => uploadDocs( record._id, event.target.files ) }
              />
            </IconButton>
            <Avatar>{ text.length }</Avatar>
          </>
        </AvatarGroup>
      ),
    },
  ]

  return (
    <>
      { visibleModalDetail.open && (
        <ModalDetail
          visible={ visibleModalDetail.open }
          setVisible={ setVisibleModalDetail }
          data={ visibleModalDetail.data }
        />
      ) }
      { visibleModalEdit.open && (
        <ModalEdit
          visible={ visibleModalEdit.open }
          setVisible={ setVisibleModalEdit }
          data={ visibleModalEdit.data }
          reloadFunction={ reloadFunction }
          loading={ loading }
          setLoading={ setLoading }
        />
      ) }

      { modalConfirmDelete.open && (
        <ConfirmDelete
          disable={ true }
          visible={ modalConfirmDelete.open }
          setVisible={ setModalConfirmDelete }
          reload={ reloadFunction }
          endPoint={ `edification/${ modalConfirmDelete.idEdificacion }` }
          title="¿Esta seguro(a) que desea deshabilitar este registro?"
          subtitle="Tener en cuenta que después de realizar esta operación el registro estará en la lista de deshabilitado."
        />
      ) }

      <Table
        columns={ columns }
        dataSource={ firstPendingAction( dataTable ) }
        bordered
        size="small"
        loading={ loading }
        scroll={ { x: "100%" } }
        rowSelection={ { type: "checkbox", ...selection( setMemorandum, memorandum ) } }
      />

      { memorandum.open && (
        <CreateMemorandum
          open={ memorandum.open }
          setOpen={ setMemorandum }
          data={ memorandum.data }
          type={ memorandum.type }
          handleReload={ reloadFunction }
          param="edification"
          action={ `${ memorandum.type === "delete" ? "DESHABILITACIÓN" : "TRANSFERENCIA"
            } DE EDIFICACION(ES)` }
        />
      ) }
    </>
  )
}

export default TableEdificaciones
