import React from "react";
import Button from '@material-ui/core/Button';
import Fab from "@material-ui/core/Fab";
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  }
}));
const BtnDelete = props => {
   
  const classes = useStyles();
  return (
    <>
      {props.small ? (
        <Fab
        style={{backgroundColor:"#dc004e"}}
          size="small"
          color="secondary"
          aria-label="add"
          className={classes.margin}
        >
          <DeleteIcon />
        </Fab>
      ) : (
        <Button
        style={{backgroundColor:"#dc004e"}}
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<DeleteIcon />}
        >
          ELIMINAR
        </Button>
      )}
    </>
  );
};

export default BtnDelete;