import React, { Fragment, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  IconButton,
  TextField,
  Grid,
} from "@material-ui/core";
import { notification, Spin } from "antd";
import { WhatsApp } from "@material-ui/icons";
import { getWorkers, sendNotifications } from "src/utils/req.boletas";
import { getActiveSedes } from "src/api/api.contable";
import SelectMultipleSedes from "src/components/Selects/SelectMultipleSedes";

const SendNotification = ({ open, setOpen, data, type }) => {
  const isEmail = type === "CORREO";
  const isWhatsapp = type === "WHATSAPP";
  const isCustom = type === "CUSTOM";
  const isSedes = data.length === 0;
  const [loading, setLoading] = useState(false);
  const [drafting, setDrafting] = useState("");
  const [info, setInfo] = useState([...data]);
  const [sedes, setSedes] = useState([]);
  const [selectedSedes, setSelectedSedes] = useState([]);

  const handleClose = () => setOpen({ open: false, data: [], type: "" });

  const handleChange = (index) => () => {
    info[index]["send"] = !info[index]["send"];
    setInfo([...info]);
  };

  const sendMessages = async () => {
    try {
      if (isCustom && drafting.length < 10) {
        return notification.info({
          message: "El contenido es requerido y la longitud mínima es de 10 caracteres.",
        });
      }

      setLoading(true);
      const filtered = info.filter((item) => item.send);

      const toSend = {};

      toSend.correos = filtered;

      if (isCustom) toSend.content = drafting;

      await sendNotifications(toSend);
      notification["success"]({ message: `Los correos se enviaron correctamente.` });
      handleClose();
    } catch (error) {
      notification["error"]({ message: `Ocurrió un error al realizar la operación` });
    } finally {
      setLoading(false);
    }
  };

  const sendWhatsapp = (wData) => () => {
    const mensaje = `Hola, *${wData.fullname}*. Se le recuerda que tiene boletas pendientes a ser visualizadas. Pueder ingresar al sistema haciendo click en el siguiente link: https://boletas.napcontable.com/napcontable/login`;
    window.open(`https://wa.me/51${wData.phone}?text=${mensaje}`, "_blank");
  };

  useEffect(() => {
    if (!isSedes) return;
    (async () => {
      try {
        setLoading(true);
        const result = await getActiveSedes();
        setSedes(result);
      } catch (error) {
        notification["error"]({ message: `No se logró obtener las sedes. ${error}` });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const obtainWorkers = async () => {
    try {
      setLoading(true);
      let tempData = [];
      for (let i = 0; i < selectedSedes.length; i++) {
        const result = await getWorkers(selectedSedes[i]._id);
        tempData = [...tempData, ...result];
      }

      const formatData = tempData.map((row) => ({
        code: row._id,
        email: row.email,
        fullname: `${row.f_name} ${row.l_name}`,
        phone: row.phone,
        send: true,
      }));

      setInfo([...formatData]);
    } catch (error) {
      notification["error"]({ message: `No se logró obtener las sedes. ${error}` });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle>
        <Typography align="center" variant="h3" component="p">
          {isCustom ? "REDACTAR CORREO" : `ENVIAR NOTIFICACIONES POR ${type}`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Spin spinning={loading}>
          <Fragment>
            <Grid container spacing={2}>
              {isSedes && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <SelectMultipleSedes
                        data={sedes}
                        sedes={selectedSedes}
                        setSedes={setSelectedSedes}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        disabled={selectedSedes.length === 0}
                        onClick={obtainWorkers}
                      >
                        OBTENER
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {(isCustom || isSedes) && (
                <Grid item xs={12}>
                  <TextField
                    label="Contenido"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Escriba aquí el contenido del correo"
                    multiline
                    rows={5}
                    variant="outlined"
                    fullWidth
                    value={drafting}
                    onChange={(event) => setDrafting(event.target.value)}
                  />
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              <List>
                {info.map((row, index) => (
                  <ListItem key={index} dense divider>
                    <ListItemText
                      primary={row.fullname}
                      secondary={isEmail ? row.email : row.phone}
                    />
                    <ListItemSecondaryAction>
                      {isWhatsapp ? (
                        <IconButton color="primary" onClick={sendWhatsapp(row)}>
                          <WhatsApp />
                        </IconButton>
                      ) : (
                        <Checkbox checked={row.send} onChange={handleChange(index)} />
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Fragment>
        </Spin>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={handleClose} disabled={loading}>
          SALIR
        </Button>
        {isEmail || isCustom ? (
          <Button
            color="primary"
            variant="contained"
            onClick={sendMessages}
            disabled={loading || info.length === 0}
          >
            ENVIAR {isCustom ? "CORREO PERSONALIZADO" : "NOTIFICACIONES"}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export default SendNotification;
