import React, { useState, useEffect, Fragment } from "react";
import Page from "src/components/Page";
import { notification } from "antd";
import { getIncidencesById, getDetailsByIncidence } from "src/utils/reqinc";
import { Link, useParams } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Spinner from "src/components/Global/Spinner";
import TableDetail from "./TableDetail";
import FormTwo from "./FormTwo";
import FinishedIncidence from "./FinishedIncidence";

const ByIncidence = () => {
  const { idIncidence } = useParams();
  const [data, setData] = useState(null);
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({ open: false, data: null });
  const [passStatus, setPassStatus] = useState([]);
  const [del, setDel] = useState({ open: false, id: idIncidence });

  useEffect(() => {
    obtainData();
  }, []);

  const obtainData = async () => {
    setLoading(true);
    try {
      const res = await getIncidencesById(idIncidence);
      const resDetails = await getDetailsByIncidence(idIncidence);
      let filter = resDetails.map((el) => el.status);
      setPassStatus([...filter]);
      setDetails(resDetails);
      setData(res);
    } catch (error) {
      notification["error"]({
        message: `Ocurrió un error al obtener la información del detalle`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page
      title="DETALLE"
      helper="INCIDENCIAS"
      itemComponent={
        <Fragment>
          <Grid container spacing={1} direction="row">
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                color="secondary"
                component={Link}
                to={{ pathname: "/incidencias" }}
                disabled={loading}
              >
                VOLVER
              </Button>
            </Grid>
            {data && data.status === "FINALIZADO" ? (
              ""
            ) : (
              <Fragment>
                <Grid item>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => setModal({ open: true, data: null })}
                    disabled={loading}
                  >
                    AGREGAR
                  </Button>
                </Grid>
                {/* <Grid item>
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                    onClick={() => setDel({ open: true, id: idIncidence })}
                  >
                    FINALIZAR
                  </Button>
                </Grid> */}
              </Fragment>
            )}
          </Grid>
        </Fragment>
      }
    >
      {loading ? (
        <Skeleton width="100%" variant="rect" height={250}>
          <Box pt={5} pb={5}>
            <Spinner />
          </Box>
        </Skeleton>
      ) : Boolean(data) ? (
        <TableDetail data={data} setLoading={setLoading} details={details} setModal={setModal} />
      ) : (
        <Skeleton width="100%" variant="rect" height={100}>
          <Box pt={5} pb={5}>
            <Typography variant="subtitle2" color="textSecondary" align="center">
              No se encontró información de la incidencia seleccionada
            </Typography>
          </Box>
        </Skeleton>
      )}

      {modal.open && (
        <FormTwo
          open={modal.open}
          setOpen={setModal}
          data={modal.data}
          loading={loading}
          setLoading={setLoading}
          incidence={data}
          reloadFunction={obtainData}
          pass={passStatus}
        />
      )}

      {del.open && <FinishedIncidence open={del.open} setOpen={setDel} incidenceId={del.id} />}
    </Page>
  );
};

export default ByIncidence;
