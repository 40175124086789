//================================================================================
//                           PARA PRODUCCIÓN
//================================================================================

// export const apiContable = `https://cuentas.napcontable.com/api`;
// export const apiInventario = `https://inventario.napcontable.com/api`;
// export const apiCuentas = `https://back.napcontable.com/api`;
// export const apiReportes = `https://reportes.napcontable.com/api`;
// export const apiReclamos = "https://back.reclamaciones.napnegocios.com/api";

//================================================================================
//                           PARA DESARROLLO
//================================================================================

export const apiContable = process.env.REACT_APP_APICONTABLE ?? `http://192.168.1.31:5001/api`;
export const apiInventario = process.env.REACT_APP_APIINVENTARIO ?? `http://192.168.1.31:5010/api`;
export const apiCuentas = process.env.REACT_APP_APICUENTAS ?? `http://192.168.1.31:5020/api`;
export const apiReportes = process.env.REACT_APP_APIREPORTES ?? `http://192.168.1.31:5020/api`;
export const apiReclamos = process.env.REACT_APP_APIRECLAMOS ?? "https://back.reclamaciones.napnegocios.com/api";

// export const apiInventario = `http://192.168.0.106:5010/api`;
// export const apiReclamos = "http://192.168.1.39:8080/api";
// export const apiCuentas = `http://192.168.1.39:5020/api`;
