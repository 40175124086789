import React, { useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import Button from "@material-ui/core/Button";
import { apiReportes } from "../../../config/api.contable";
import Fab from "@material-ui/core/Fab";
import Loader from "../../../components/Global/LoaderBackdrop";
import { notification } from "antd";

const Exportar = (props) => {
  const { nombre, ruta, body, disabled } = props;
  const [loading, setLoading] = useState(false);
  const buildInit = () => {
    return {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    };
  };

  const onClickLink = () => {
    setLoading(true);
    fetch(`${apiReportes}/${ruta}`, buildInit())
      .then((response) => {
        if (response.ok) {
          response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = `${nombre}.xlsx`;
            a.click();
          });
        }
      })
      .catch((err) => {
        notification["error"]({
          message: "Ocurrió un error",
          description: `${err}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  //Descargar archivo

  return (
    <>
      {loading && <Loader visible={loading} />}
      {props.small ? (
        <Fab
          size="small"
          onClick={onClickLink}
          style={{ backgroundColor: "#00bfa5", color: "#ffff" }}
        >
          <GetAppIcon />
        </Fab>
      ) : (
        <Button
          variant="contained"
          color="primary"
          startIcon={<GetAppIcon />}
          fullWidth
          onClick={onClickLink}
          disabled={disabled}
        >
          Exportar
        </Button>
      )}
    </>
  );
};

export default Exportar;
