import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Spinner from "./Spinner";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    zIndex: "99999999",
  },
  top: {
    color: "#fff",
    animationDuration: "400ms",
  },
});

export default function CustomizedProgressBars(props) {
  const classes = useStyles();

  return (
    <Backdrop open={true} className={classes.root}>
      <Spinner />
    </Backdrop>
  );
}
