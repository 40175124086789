import React from "react";
import napformat from "../../../config/napformat";
import CustomTable from "src/components/CustomTable";
import moment from "moment";

const TableCaja = (props) => {
  const { data, loading } = props;

  const columns = [
    {
      title: "N° VOUCHER",
      dataIndex: "n_voucher",
      key: "n_voucher",
      align: "center",
      width: 100,
      render: (text, record) => {
        return {
          props: {
            style: { background: record.n_voucher ? "" : "#01579b30" },
          },
          children: text,
        };
      },
    },
    {
      title: "FECHA DE OPERACIÓN",
      dataIndex: "operation_date",
      key: "operation_date",
      align: "center",
      width: 140,
      render: (text, record) => {
        return {
          props: {
            style: { background: record.n_voucher ? "" : "#01579b30" },
          },
          children: text ? moment(text).format("DD/MM/YYYY") : "",
        };
      },
    },
    {
      title: "N° CUENTA",
      dataIndex: "account",
      key: "account",
      align: "center",
      render: (text, record) => {
        return {
          props: {
            style: { background: record.n_voucher ? "" : "#01579b30" },
          },
          children: text,
        };
      },
    },
    {
      title: "DESCRIPCIÓN",
      dataIndex: "description",
      key: "description",
      align: "center",
      width: 500,
      render: (text, record) => {
        return {
          props: {
            style: { background: record.n_voucher ? "" : "#01579b30" },
          },
          children: text,
        };
      },
    },
    {
      title: "RUC",
      dataIndex: "ruc",
      key: "ruc",
      align: "center",
      render: (text, record) => {
        return {
          props: {
            style: { background: record.n_voucher ? "" : "#01579b30" },
          },
          children: text,
        };
      },
    },
    {
      title: "RAZÓN SOCIAL",
      dataIndex: "businessName",
      key: "businessName",
      align: "center",
      render: (text, record) => {
        return {
          props: {
            style: { background: record.n_voucher ? "" : "#01579b30" },
          },
          children: text,
        };
      },
    },
    {
      title: "TIPO DOC",
      dataIndex: "t_doc",
      key: "t_doc",
      align: "center",
      render: (text, record) => {
        return {
          props: {
            style: { background: record.n_voucher ? "" : "#01579b30" },
          },
          children: text,
        };
      },
    },
    {
      title: "SERIE",
      dataIndex: "serie",
      key: "serie",
      align: "center",
      render: (text, record) => {
        return {
          props: {
            style: { background: record.n_voucher ? "" : "#01579b30" },
          },
          children: text,
        };
      },
    },
    {
      title: "N° TIPO DOC / REFERENCIA",
      dataIndex: "correlative",
      key: "correlative",
      align: "center",
      render: (text, record) => {
        return {
          props: {
            style: { background: record.n_voucher ? "" : "#01579b30" },
          },
          children: text,
        };
      },
    },

    {
      title: "DEBE",
      dataIndex: "debit",
      key: "debit",
      align: "center",
      render: (text, record) => {
        return {
          props: {
            style: { background: record.n_voucher ? "" : "#01579b30" },
          },
          children: text ? napformat(text) : "",
        };
      },
    },
    {
      title: "HABER",
      dataIndex: "asset",
      key: "asset",
      align: "center",
      render: (text, record) => {
        return {
          props: {
            style: { background: record.n_voucher ? "" : "#01579b30" },
          },
          children: text ? napformat(text) : "",
        };
      },
    },
  ];

  return (
    <CustomTable
      columns={columns}
      data={data}
      loading={loading}
      pagination={{ pageSize: 100, position: "both" }}
    />
  );
};

export default TableCaja;
