import React, { useState } from "react";
import { notification } from "antd";
import useForm from "react-hook-form";
import sedeactual from "../../../config/sedeactual";
import axios from "axios";
import { apiContable } from "../../../config/api.contable";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import moment from "moment";

const AddFile = (props) => {
  const { visible, setvisible, typeFiles, setLoading, refresh } = props;
  const { handleSubmit, register, errors } = useForm();
  const [fileUpload, setfileUpload] = useState(null);
  const [type, setType] = useState(typeFiles[0]);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

  async function sendData(event, e) {
    if (fileUpload) {
      setLoading(true);
      let formData = new FormData();
      formData.append("imagen", fileUpload);
      formData.append("name", event.name);
      formData.append("date", date);
      formData.append("type", type);
      formData.append("dateUpload", Date.now());
      formData.append("period", moment(date).month() + 1);

      await axios
        .post(
          `${apiContable}/saveFiles/${
            sedeactual.company().url
          }/${sedeactual.get()}`,
          formData
        )
        .then(function (response) {
          notification["success"]({
            message: "ARCHIVO SUBIDO",
            description: "Se subió el archivo con exito",
          });
          refresh();
          e.target.reset();
          setvisible(false);
        })
        .catch(function (error) {
          notification["error"]({
            message: "ARCHIVO SUBIDO",
            description: "No se subió el archivo con exito",
          });
        })
        .finally(function () {
          setLoading(false);
        });
    } else {
      notification["info"]({ message: "Debe seleccionar un archivo." });
    }
  }

  return (
    <Dialog
      onClose={() => setvisible(false)}
      open={visible}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h4" component="h1" align="center">
          AGREGAR ARCHIVO
        </Typography>
      </DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit(sendData)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="name"
                variant="outlined"
                fullWidth
                label="Nombre"
                inputRef={register({
                  required: "Nombre Requerido",
                })}
              />
              {errors.name && (
                <FormHelperText error>
                  {errors.name && errors.name.message}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>TIPO</InputLabel>
                <Select
                  label="tipo"
                  value={type}
                  name="type"
                  onChange={(e) => setType(e.target.value)}
                >
                  {typeFiles.map((ev, i) => (
                    <MenuItem value={ev} hidden={typeFiles.length - 1 === i}>
                      {ev}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                type="date"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                onChange={(e) => setfileUpload(e.target.files[0])}
                type="file"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" type="submit">
            SUBIR
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddFile;
