import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import numeral from "numeral";
import moment from "moment";

const TableDetails = ({ data, origins }) => {
  return (
    <TableContainer>
      <Table className="tablefe">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">NÚMERO</TableCell>
            <TableCell align="center">FECHA DE OPERACIÓN</TableCell>
            <TableCell align="center">ORIGEN</TableCell>
            <TableCell align="center">SERIE</TableCell>
            <TableCell align="center">CORRELATIVO</TableCell>
            <TableCell align="center">CLIENTE</TableCell>
            <TableCell align="center">MONTO</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row key={row._id} row={row} origins={origins} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableDetails;

const Row = ({ row, origins }) => {
  const [open, setOpen] = useState(false);

  let sum = 0;

  row.details
    .filter((item) => item.type_detail === row.type_provisioning)
    .forEach((item) => (sum += Number(item.amount)));

  const origin = origins.find((item) => item.id === row.origin);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.n_voucher}</TableCell>
        <TableCell>{moment(row.operation_date).format("DD/MM/YYYY")}</TableCell>
        <TableCell>{origin?.description ?? row.origin}</TableCell>
        <TableCell>{row.serie}</TableCell>
        <TableCell>{row.correlative.toString().padStart(6, "0")}</TableCell>
        <TableCell>{`${row.customer.t_doc ?? ""}: ${row.customer.ruc} - ${
          row.customer.nombre_o_razon_social
        }`}</TableCell>
        <TableCell>S/{numeral(sum).format("0,0.00")}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={8} style={{ margin: 0, padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box ml={8}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>CUENTA</TableCell>
                    <TableCell>GLOSA</TableCell>
                    <TableCell>MONEDA</TableCell>
                    <TableCell>REF. M. PAGO</TableCell>
                    <TableCell>DEBE</TableCell>
                    <TableCell>HABER</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.details.map((det) => (
                    <TableRow key={det._id}>
                      <TableCell>{det.account.number}</TableCell>
                      <TableCell>{det.comment}</TableCell>
                      <TableCell>{det.type_currency === 1 ? "PEN" : "USD"}</TableCell>
                      <TableCell>{det.ref_payment}</TableCell>
                      <TableCell>
                        {det.type_detail === "DEBE" ? (
                          <span>S/{numeral(det.amount).format("0,0.00")}</span>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {det.type_detail === "HABER" ? (
                          <span>S/{numeral(det.amount).format("0,0.00")}</span>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
