import React, { useState } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Collapse,
  Typography,
  CardActionArea,
} from "@material-ui/core";
import { typesDocuments } from "src/utils/constants";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { Empty } from "antd";
import { CheckCircle, Cancel } from "@material-ui/icons";

const staticData = [{ label: "PERIODO", key: "month" }];
const columns = [...staticData, ...typesDocuments];

const DocumentsSuperAdmin = ({ data }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {data.length > 0 ? (
          <TableContainer>
            <Table className="tablefe">
              <TableBody>
                {data.map((row, index) => (
                  <Item data={row} key={index} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Empty description="AQUÍ SE MOSTRARÁ LA INFORMACIÓN DE DOCUMENTOS POR CADA UNA DE LAS EMPRESAS." />
        )}
      </Grid>
    </Grid>
  );
};

export default DocumentsSuperAdmin;

const Item = ({ data }) => {
  const [open, setOpen] = useState(false);
  const { headquarter, documents } = data;

  return (
    <React.Fragment>
      <TableRow>
        <TableCell component="th" style={{ padding: 0 }}>
          <CardActionArea style={{ padding: 10 }} onClick={() => setOpen((prev) => !prev)}>
            <Grid container spacing={1} justify="space-between">
              <Grid item>{open ? <ArrowDropUp /> : <ArrowDropDown />}</Grid>
              <Grid item>
                <Typography color="inherit" variant="h4">
                  {headquarter.description.toUpperCase()}
                </Typography>
              </Grid>
              <Grid item>{open ? <ArrowDropUp /> : <ArrowDropDown />}</Grid>
            </Grid>
          </CardActionArea>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} style={{ padding: 0, paddingBottom: open ? 25 : 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableBody>
                <TableRow>
                  {columns.map((row) => (
                    <TitleItem key={row.key} val={<b>{row.label.toUpperCase()}</b>} />
                  ))}
                </TableRow>
                {documents
                  .sort((a, b) => (a.order > b.order ? 1 : -1))
                  .map((row, idx) => (
                    <TableRow key={idx}>
                      {columns.map((column, inx) => {
                        let value = row[column.key];
                        if (column.key !== "month") {
                          value = value ? (
                            <CheckCircle style={{ color: "#54D62C" }} />
                          ) : (
                            <Cancel color="secondary" />
                          );
                        }

                        return <TitleItem val={value} key={inx} />;
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const TitleItem = ({ val }) => {
  return (
    <TableCell align="center">
      <Typography style={{ textAlign: "center" }} variant="caption" color="inherit" component="div">
        {val}
      </Typography>
    </TableCell>
  );
};
