import React, { useState } from "react";
import { Table, Input, Button, Icon } from "antd";
import Highlighter from "react-highlight-words";
import { Fab } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import EditAuxC from "./EditAuxC";
import sedeactual from "../.../../../../config/sedeactual";

const TableAc = (props) => {
  const { data, getData, loading } = props;
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");
  const [editAC, setEditAC] = useState({
    data: null,
    visible: false,
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyPress={(e) => e.key === "Enter" && handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Limpiar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();

    setsearchText(selectedKeys[0]);
    setsearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setsearchText("");
  }

  let columns = [
    {
      title: "CUENTA",
      dataIndex: "account_number",
      key: "account_number",
      width: 100,
      ...getColumnSearchProps("account_number"),
    },
    {
      title: "DESCRIPCIÓN CUENTA",
      dataIndex: "account_desc",
      key: "account_desc",
      width: 200,
      ...getColumnSearchProps("account_desc"),
    },
    {
      title: "DESCRIPCIÓN",
      dataIndex: "description",
      key: "description",
      width: 150,
      ...getColumnSearchProps("description"),
      render: (text, record, index) => (
        <span style={{ textTransform: "uppercase" }}>{text}</span>
      ),
    },
    {
      title: "Acciones",
      dataIndex: "_id",
      key: "_id",
      width: 100,
      render: (value, record, index) => (
        <>
          {sedeactual.getRol() !== "usuario" && (
            <Fab
              color="secondary"
              aria-label="edit"
              size="small"
              onClick={() => {
                setEditAC({
                  data: record,
                  visible: true,
                });
              }}
            >
              <EditIcon />
            </Fab>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        bordered
        dataSource={data}
        scroll={{ x: "100%" }}
        loading={loading}
      />
      {editAC.visible && (
        <EditAuxC
          visible={editAC.visible}
          setVisible={setEditAC}
          data={editAC.data}
          getData={getData}
        />
      )}
    </>
  );
};

export default TableAc;
