import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  FormHelperText,
  Button,
} from "@material-ui/core";
import { notification, Spin } from "antd";
import axios from "axios";
import { apiCuentas } from "../../config/api.contable";
import InputFile from "../../components/Global/components/InputFile/InputFile";
import SelectSedes from "../Usuarios/SelectSedes";
import sedeactual from "../../config/sedeactual";

const ImportacionVoucher = ({ isSuperAdm, sedes }) => {
  const [excelFile, setExcelFile] = useState(null);
  const [selectedFileList, setSelectedFileList] = useState([]);
  const [sede, setsede] = useState(sedeactual.get());
  const [loading, setLoading] = useState(false);

  const uploadExcel = async () => {
    setLoading(true);
    try {
      let form = new FormData();
      form.append("file", excelFile);

      const res = await axios.post(
        `${apiCuentas}/import/massive-vouchers/${sedeactual.getAnio()}/${sedeactual.get()}/${sedeactual.sede_id()}/${
          sedeactual.company().url
        }`,
        form
      );
      notification[res.data.success ? "success" : "warning"]({
        message: res.data.message,
      });
      setExcelFile(null);
      setSelectedFileList([]);
    } catch (error) {
      notification["error"]({
        message: `Ocurrió un error al realizar la operación`,
      });
    } finally {
      setLoading(false);
    }
  };

  function onChangeFile(info) {
    setExcelFile(info.file.originFileObj);
    switch (info.file.status) {
      case "uploading":
        setSelectedFileList([info.file]);
        break;
      case "done":
        setSelectedFileList([info.file]);
        break;
      case "removed":
        setSelectedFileList([]);
        break;
      default:
        console.log("error");
    }
  }

  const test = {
    correlative: "2332332323332",
    customer: {
      ruc: "71546119",
      nombre_o_razon_social: "ORDOÑEZ HILARIO GERMAN ALDO",
    },
    details: [
      {
        account: "60e607fa93dc9832d4bc8586",
        amount: 10,
        aux_account: null,
        comment: "GLOSA 1",
        cost: null,
        exchange_rate: 1,
        period: 12,
        periodo: "2021-12-01",
        ref_payment: "",
        tipoDocumento: "",
        type_currency: 1,
        type_detail: "DEBE",
      },
      {
        account: "60e607fa93dc9832d4bc85b2",
        amount: 20,
        aux_account: null,
        comment: "GLOSA 2",
        cost: null,
        exchange_rate: 1,
        period: 12,
        periodo: "2021-12-01",
        ref_payment: "",
        tipoDocumento: "",
        type_currency: 1,
        type_detail: "HABER",
      },
      {
        account: "60e607fa93dc9832d4bc85ab",
        amount: 10,
        aux_account: null,
        comment: "GLOSA 2",
        cost: null,
        exchange_rate: 1,
        period: 12,
        periodo: "2021-12-01",
        ref_payment: "",
        tipoDocumento: "",
        type_currency: 1,
        type_detail: "DEBE",
      },
    ],
    headquarters_id: "88",
    numComprobante: "",
    operation_date: "2021-12-31",
    origin: 1,
    period: 12,
    serie: "E001",
    t_doc: 1,
    voucher_id: "",
    year: 2021,
  };

  return (
    <Card>
      <Spin spinning={loading}>
        <CardContent>
          <Typography variant="h4" color="textSecondary">
            Importación de Voucher
          </Typography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12}>
              {isSuperAdm ? (
                <>
                  <FormHelperText>SEDES</FormHelperText>
                  <SelectSedes
                    defaultValue={sede}
                    data={sedes}
                    onChangeSelect={(e) => {
                      setsede(e);
                    }}
                  />
                </>
              ) : null}
            </Grid>
            <Grid item xs={8}>
              <InputFile
                onChange={onChangeFile}
                selectedFileList={selectedFileList}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={!excelFile || loading}
                onClick={uploadExcel}
              >
                Importar
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Spin>
    </Card>
  );
};

export default ImportacionVoucher;
