const { useState, useEffect } = require("react");

const useDebounce = (val, delay = 500) => {
  const [value, setValue] = useState(val);

  useEffect(() => {
    const handler = setTimeout(() => {
      setValue(val);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);

  return value;
};

export default useDebounce;
