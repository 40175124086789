import React from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@material-ui/core";

const TableReporteCambioPatrimonio = ({ year }) => {
  return (
    <TableContainer component={Paper}>
      <Table className="tablefe" size="small">
        <TableHead style={{height:60}}>
          <TableRow>
            <CellBold colSpan={5} value="EXPRESADO EN SOLES" />
            <CellBold value={year} />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell />
            <CellBold colSpan={4} value="FONDOS PROPIOS" />
            <CellBold rowSpan={2} value="TOTAL PATRIMONIO NETO" />
          </TableRow>
          <TableRow>
            <TableCell />
            <CellBold value="CAPITAL SOCIAL" />
            <CellBold value="CAPITAL ADICIONAL" />
            <CellBold value="RESULTADOS ACUMULADOS" />
            <CellBold value="RESULTADOS DEL EJERCICIO" />
          </TableRow>
          {[1, 2, 3, 4, 5].map((row, index) => (
            <TableRow key={index}>
              <TableCell>ASDASDASD</TableCell>
              <TableCell>ASDASDASD</TableCell>
              <TableCell>ASDASDASD</TableCell>
              <TableCell>ASDASDASD</TableCell>
              <TableCell>ASDASDASD</TableCell>
              <TableCell>ASDASDASD</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <CellBold value="SALDO AL FINAL DEL PERIODO" />
            <CellBold value="123" />
            <CellBold value="123" />
            <CellBold value="123" />
            <CellBold value="123" />
            <CellBold value="123" />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableReporteCambioPatrimonio;

const CellBold = ({ value, ...rest }) => (
  <TableCell align="center" {...rest}>
    <Typography variant="subtitle1" color="inherit">
      <b>{value}</b>
    </Typography>
  </TableCell>
);
