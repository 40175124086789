import React from "react";
import { Modal} from "antd";
const ModalTipoDoc = props => {

    function renderTableTipo() {
        return props.dataTipos.map((Tipos, index) => {
          let { id, code, description } = Tipos;
    
          return (
            <tr key={id}>
              <td>
                <button
                  onClick={() => props.seleccionarTipo(id)}
                  className="ml-3 btn btn-success"
                >
                  {code}
                </button>
              </td>
              <td>{description}</td>
            </tr>
          );
        });
      }


  return (
    <>
      <Modal
        title="Tipo de Documento"
        onCancel={() => {
          props.setModalTipo(false);
        }}
        className="modalTipo"
        style={{ minWidth: "40%", maxWidth: "1000px" }}
        visible={props.modalTipo}
        footer={[]}
      >
        <div style={{ height: "400px", overflowY: "scroll" }}>
          <table className="table table-bordered" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th style={{ width: "1%" }}>Código</th>
                <th style={{ width: "5%" }}>Descripción</th>
              </tr>
            </thead>
            <tbody>{renderTableTipo()}</tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

export default ModalTipoDoc;
