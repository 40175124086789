import React, { useState } from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { Select, Modal, notification } from "antd";
import axios from "axios";
import { apiCuentas } from "../../../config/api.contable";
import sedeactual from "../../../config/sedeactual";

const { Option } = Select;
const AddPresupuesto = (props) => {
  const {
    visible,
    setVisible,
    cuentas,
    sede,
    setLoading,
    loading,
    getBudgetList,
  } = props;
  const [amount, setAmount] = useState("");
  const [account, setAccount] = useState(null);

  const saveBudget = async () => {
    if (account && amount && amount > 0) {
      setLoading(true);
      try {
        await axios.post(
          `${apiCuentas}/budget/list/${sede}/${sedeactual.getAnio()}/${
            sedeactual.company().url
          }`,
          {
            estimateAmount: amount,
            account: account,
          }
        );
        notification["success"]({
          message: "El registro se agregó correctamente.",
        });
        getBudgetList();
        setVisible(false);
      } catch (error) {
        notification["error"]({
          message: `No se pudo guardar el registro. ${error}`,
        });
      }
      setLoading(false);
    } else {
      notification["info"]({ message: "Campos requeridos" });
    }
  };

  const filterOptions = (inputValue, option) => {
    const title = option.props.title.toLowerCase();
    return title.includes(inputValue.toLowerCase());
  };

  return (
    <Modal
      title={
        <Typography align="center" variant="h3">
          <b>AGREGAR PRESUPUESTO</b>
        </Typography>
      }
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setVisible(false)}
        >
          Cancelar
        </Button>,
        <Button
          variant="contained"
          color="primary"
          onClick={saveBudget}
          loading={loading}
          disabled={loading}
        >
          Guardar
        </Button>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Monto"
            variant="outlined"
            fullWidth
            size="small"
            value={amount}
            type="number"
            inputProps={{ step: "0.01", min: 0 }}
            onChange={(e) => setAmount(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            size="large"
            style={{ width: "100%" }}
            showSearch
            filterOption={filterOptions}
            value={account}
            onChange={(e) => setAccount(e)}
          >
            <Option value={null} key={null} title="ninguno">
              --NINGUNO--
            </Option>
            {cuentas.map((ev) => (
              <Option
                value={ev._id}
                key={ev._id}
                title={`${ev.number} - ${ev.description}`}
              >
                {ev.number} - {ev.description}
              </Option>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AddPresupuesto;
