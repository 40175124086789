import axios from "axios";
import { apiContable } from "src/config/api.contable";
import sedeactual from "src/config/sedeactual";

// TRABAJADORES

export const getCovid = async () => {
  let res = await axios.get(
    `${apiContable}/form-covid/${sedeactual.sede_id()}`
  );
  return res.data;
};

export const getCovidByDates = async (data) => {
  let res = await axios.post(
    `${apiContable}/form-covid/date/${sedeactual.sede_id()}`,
    data
  );
  return res.data;
};
