export const typesDocuments = [
  { label: "APORTE 10 % SUELDO DE HERMANAS", key: "aporte_10" },
  { label: "APORTE DE COMUNIDADES A CAJA GENERAL", key: "aporte_caja" },
  { label: "ALQUILER DE TERRENOS", key: "alquiler_terrenos" },
  { label: "ALQUILER DE INMUEBLES", key: "alquiler_inmuebles" },
  { label: "APORTE DE JUBILACIONES HERMANAS", key: "aporte_jubilacion" },
  { label: "LIQUIDACIONES DE HERMANAS", key: "liquidacion" },
  { label: "DEVOLUCIÓN DE CONTRIBUCIONES Y SUELDOS COMUNIDADES", key: "devolucion_contrbns" },
  { label: "DEVOLUCIÓN EPS SEGURO PACÍFICO", key: "devolucion_eps" },
  { label: "DEVOLUCIÓN SUELDOS TRABAJADORES", key: "devolucion_sueldos" },
  { label: "SEGURO VIDA LEY", key: "seguro_vida" },
  { label: "INGRESOS DE PARKINSONIA", key: "parkinsonia" },
  { label: "DONACIONES", key: "donaciones" },
  { label: "OTROS INGRESOS DIVERSOS", key: "otros" },
];

export const monthsList = [
  { label: "ENERO", key: "01" },
  { label: "FEBRERO", key: "02" },
  { label: "MARZO", key: "03" },
  { label: "ABRIL", key: "04" },
  { label: "MAYO", key: "05" },
  { label: "JUNIO", key: "06" },
  { label: "JULIO", key: "07" },
  { label: "AGOSTO", key: "08" },
  { label: "SETIEMBRE", key: "09" },
  { label: "OCTUBRE", key: "10" },
  { label: "NOVIEMBRE", key: "11" },
  { label: "DICIEMBRE", key: "12" },
];
