import React, { Fragment } from "react";
import {
  Grid,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";
import SelectSexo from "src/components/Selects/SelectSexo";
import SelectNivelEscolar from "src/components/Selects/SelectNivelEscolar";
import SelectGradoEscolar from "src/components/Selects/SelectGradoEscolar";
import SelectTurnoEscolar from "src/components/Selects/SelectTurnoEscolar";
import SelectSeccionEscolar from "src/components/Selects/SelectSeccionEscolar";

const listTeacherType = [
  "Director",
  "Docente",
  "Auxiliar",
  "Administrativo",
  "Personal de apoyo",
  "Otro",
];

const PartFour = ({
  errors,
  register,
  aggressor,
  setAggressor,
  listTypeAgresores,
  agsSexo,
  setAgsSexo,
  setAgsLevel,
  setAgsGrade,
  setAgsSection,
  agsLevel,
  agsGrade,
  agsSection,
  agsShift,
  setAgsShift,
  agsDiscapacidad,
  setAgsDiscapacidad,
  prntSchool,
  setPrntSchool,
  listPrtSchool,
  agsTDoc,
  setAgsTDoc,
  listTDocs,
  agsPersonal,
  setAgsPersonal,
  eInf,
  setValue,
}) => {
  const handleChangePersonal = (value) => {
    if (value === "Otro") setAgsPersonal({ text: "", value: true });
    else setAgsPersonal({ text: value, value: false });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} align="center">
        <FormControl component="fieldset">
          <FormLabel component="legend">
            {`EL AGRESOR ES ${
              aggressor === "Otro" ? "UNA PERSONA EXTERNA" : `UN ${aggressor.toUpperCase()}`
            }`}
          </FormLabel>
          <RadioGroup value={aggressor} onChange={(e) => setAggressor(e.target.value)}>
            <Grid container>
              {listTypeAgresores.map((el, index) => (
                <Grid item key={index}>
                  <FormControlLabel value={el} control={<Radio color="primary" />} label={el} />
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
        </FormControl>
      </Grid>
      {/* SI EL AGRESOR ES UN ESTUDIANTE.  */}
      <Grid item xs={12} md={5}>
        <FormControl fullWidth>
          <RadioGroup value={agsTDoc} onChange={(e) => setAgsTDoc(e.target.value)}>
            <Grid container justify="space-between">
              {listTDocs.map((el, index) => (
                <Grid item key={index}>
                  <FormControlLabel value={el} control={<Radio color="primary" />} label={el} />
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={7}>
        <TextField
          InputLabelProps={{ shrink: true }}
          size="small"
          fullWidth
          label="Documento *"
          name="agsNDoc"
          defaultValue={Boolean(eInf) ? eInf.agsNDoc : ""}
          variant="outlined"
          inputRef={register({
            required: false,
            minLength: 8,
            maxLength: 12,
            pattern: /^[0-9]+$/i,
          })}
          error={Boolean(errors?.agsNDoc)}
        />
      </Grid>
      <Grid item xs={12} md={8} container alignItems="center">
        <TextField
          InputLabelProps={{ shrink: true }}
          size="small"
          fullWidth
          label={aggressor === "Estudiante" ? "Iniciales*" : "Nombre Completo*"}
          name="agsFullname"
          onChange={(e) => setValue("agsFullname", e.target.value)}
          defaultValue={Boolean(eInf) ? eInf.agsFullname : ""}
          variant="outlined"
          inputRef={register({ required: true })}
          error={Boolean(errors?.agsFullname)}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        {agsDiscapacidad.value ? (
          <TextField
            InputLabelProps={{ shrink: true }}
            size="small"
            fullWidth
            label="Discapacidad* (especificar)"
            variant="outlined"
            value={agsDiscapacidad.text}
            onChange={(e) =>
              setAgsDiscapacidad({
                ...agsDiscapacidad,
                text: e.target.value,
              })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      setAgsDiscapacidad({
                        text: "",
                        value: false,
                      })
                    }
                  >
                    <ReplayIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Discapacidad*</FormLabel>
            <RadioGroup
              value={agsDiscapacidad.value}
              onChange={(e) =>
                setAgsDiscapacidad({
                  ...agsDiscapacidad,
                  value: e.target.value,
                })
              }
            >
              <Grid container justify="space-between">
                <Grid item>
                  <FormControlLabel value={false} control={<Radio color="primary" />} label="NO" />
                </Grid>
                <Grid item>
                  <FormControlLabel value={true} control={<Radio color="primary" />} label="SI" />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          InputLabelProps={{ shrink: true }}
          size="small"
          fullWidth
          label="Edad *"
          name="agsAge"
          defaultValue={Boolean(eInf) ? eInf.agsAge : ""}
          variant="outlined"
          inputRef={register({
            required: false,
            pattern: /^[0-9]/i,
          })}
          error={Boolean(errors?.agsAge)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectSexo sexo={agsSexo} setSexo={setAgsSexo} />
      </Grid>

      {aggressor === "Estudiante" ? (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <SelectNivelEscolar
                setLevel={(e) => {
                  setAgsLevel(e);
                  setAgsGrade(null);
                  setAgsSection(null);
                }}
                level={agsLevel}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SelectGradoEscolar
                setGrade={(e) => {
                  setAgsGrade(e);
                  setAgsSection(null);
                }}
                grade={agsGrade}
                level={agsLevel}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SelectSeccionEscolar
                setSection={setAgsSection}
                section={agsSection}
                grade={agsGrade}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SelectTurnoEscolar shift={agsShift} setShift={setAgsShift} />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">PARENTESCO ESCOLAR</FormLabel>
                <RadioGroup value={prntSchool} onChange={(e) => setPrntSchool(e.target.value)}>
                  <Grid container justify="space-between">
                    {listPrtSchool.map((el, index) => (
                      <Grid item key={index}>
                        <FormControlLabel
                          value={el}
                          control={<Radio color="primary" />}
                          label={el}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Fragment>
          {/* SI EL AGRESOR ES UN PERSONAL.  */}
          {aggressor === "Personal de la IE" && (
            <Grid item xs={12}>
              {agsPersonal.value ? (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  fullWidth
                  style={{ marginTop: 15 }}
                  label="Especificar *"
                  variant="outlined"
                  value={agsPersonal.text}
                  onChange={(e) =>
                    setAgsPersonal({
                      ...agsPersonal,
                      text: e.target.value,
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setAgsPersonal({
                              text: "",
                              value: false,
                            })
                          }
                        >
                          <ReplayIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <FormControl component="fieldset" fullWidth>
                  <RadioGroup
                    value={agsPersonal.text}
                    onChange={(e) => handleChangePersonal(e.target.value)}
                  >
                    <Grid container justify="space-between">
                      {listTeacherType.map((el, index) => (
                        <Grid item key={index}>
                          <FormControlLabel
                            value={el}
                            control={<Radio color="primary" />}
                            label={el}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </RadioGroup>
                </FormControl>
              )}
            </Grid>
          )}
        </Fragment>
      )}
    </Grid>
  );
};

export default PartFour;
