import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography, Grid } from "@material-ui/core";

const Page401 = () => {
  const content = (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item align="center">
        <Typography
          variant="h1"
          color="primary"
          style={{ color: "#336dff", fontSize: 150 }}
        >
          401
        </Typography>
        <br />
        <br />
        <Typography variant="h4" color="textSecondary" component="h2">
          USTED NO TIENE ACCESO PARA ESTE APARTADO DE LA PÁGINA.
        </Typography>
        <br />
        <Link to={"/"}>
          <Button variant="contained" color="primary">
            Regresar al inicio
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
  return content;
};

export default Page401;
