import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@material-ui/core";
import Axios from "axios";
import { apiInventario } from "../../../../config/api.contable";
import { notification } from "antd";

const EditSubCategory = ({ open, setOpen, data, reloadFunction }) => {
  const [name, setName] = useState(data.name);
  const [loading, setLoading] = useState(false);

  const saveData = async () => {
    setLoading(true);
    try {
      await Axios.put(`${apiInventario}/subcategory/${data._id}`, { name });
      notification["success"]({
        message: "La subcategoría se actualizó correctamente",
      });
      reloadFunction();
      setOpen(false);
    } catch (error) {
      notification["error"]({
        message: `Oops!`,
        description: `Ocurrió un error, ${error}`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle align="center">EDITAR SUBCATEGORÍA</DialogTitle>
      <DialogContent>
        <TextField
          label="Nombre"
          fullWidth
          variant="outlined"
          size="small"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => setOpen(false)}
        >
          CANCELAR
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={loading || !Boolean(name)}
          onClick={saveData}
        >
          {loading ? "CARGANDO..." : "ACTUALIZAR"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSubCategory;
