import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  makeStyles,
} from "@material-ui/core";
import { apiInventario } from "../../../config/api.contable";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableTransferencias from "./Transferencias/TableTransferencias";

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: "85%" },
}));

const DetailTransferencias = (props) => {
  const { visible, setVisible, idAsset } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  const getDetails = async () => {
    setLoading(true);
    await axios
      .get(`${apiInventario}/history-transfer-asset/${idAsset}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <Dialog open={visible} classes={{ paper: classes.paper }}>
      <DialogTitle>
        <Typography variant="h3" color="textSecondary" align="center">
          Historial de la transferencia del activo
        </Typography>
      </DialogTitle>
      {loading ? (
        <div style={{ margin: "0 auto" }}>
          <CircularProgress />
        </div>
      ) : error ? (
        <DialogContent>
          <DialogContentText align="center" style={{ color: "red" }}>
            Ocurrió un error el obtener el registro. Intente más tarde.
          </DialogContentText>
        </DialogContent>
      ) : (
        <DialogContent align="center">
          {data.length > 0 ? (
            <>
              <DialogContentText align="center">Activo: {data[0].item.description}</DialogContentText>
              <TableTransferencias dataTable={data} />
            </>
          ) : (
            <Typography variant="p" color="textSecondary" align="center">
              ESTE ACTIVO NO TIENE REGISTRO DE TRANSFERENCIAS DE AMBIENTES.
            </Typography>
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={() => setVisible({ open: false })}
          color="primary"
          variant="outlined"
          disabled={loading ? true : false}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailTransferencias;
