import React from "react";
import CustomTable from "src/components/CustomTable";
import { IconButton, Tooltip } from "@material-ui/core";
import { typesDocuments } from "src/utils/constants";
import { CloudUpload, Launch } from "@material-ui/icons";

const TableDocuments = ({ data, loading, uploadDocument, isSN }) => {
  const columns = [
    {
      title: "MES",
      dataIndex: "month",
      key: "month",
      align: "center",
      sorter: false,
      filter: false,
      width: 100,
    },
  ];
  typesDocuments.forEach((item) => {
    columns.push({
      title: item.label.toUpperCase(),
      dataIndex: item.key,
      key: item.key,
      align: "center",
      sorter: false,
      filter: false,
      width: 150,
      render: (value, row) => (
        <React.Fragment>
          {(isSN || !value) && (
            <Tooltip
              title={`${
                value ? "VOLVER A SUBIR EL DOCUMENTO." : "DOCUMENTO PENDIENTE A SUBIDA."
              } ${item.label.toUpperCase()} ─ ${row.month}`}
              placement="top"
              arrow
            >
              <IconButton
                component="label"
                style={{ color: value ? "#00940f" : "#e6962e", padding: 7 }}
              >
                <input
                  style={{ display: "none" }}
                  type="file"
                  onChange={(e) => uploadDocument(e.target.files[0], item.key, row._id)}
                />
                <CloudUpload />
              </IconButton>
            </Tooltip>
          )}
          {value && (
            <Tooltip title="VISUALIZAR DOCUMENTO" placement="top" arrow>
              <IconButton color="primary" component="a" href={value} target="_blank">
                <Launch />
              </IconButton>
            </Tooltip>
          )}
        </React.Fragment>
      ),
    });
  });

  return (
    <CustomTable columns={columns} data={data} loading={loading} pagination={false} size="small" />
  );
};

export default TableDocuments;
