import React, { useState, useEffect } from "react";
import TableClaims from "./components/TableClaims";
import Page from "src/components/Page";
import { notification } from "antd";
import { getClaims } from "src/utils/reqinc";

const ListClaims = () => {
  const [loading, setLoading] = useState(true);
  const [dataClaims, setDataClaims] = useState([]);

  const obtainClaims = async () => {
    setLoading(true);
    try {
      const res = await getClaims();
      setDataClaims(res.reverse());
    } catch (error) {
      notification["error"]({
        message: `Oops!`,
        description: `Ocurrió un error al obtener la información`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    obtainClaims();
  }, []);

  return (
    <Page title="LISTADO DE RECLAMOS" helper="RECLAMOS">
      <br />
      <TableClaims
        data={dataClaims}
        loading={loading}
        setLoading={setLoading}
        refreshFunction={obtainClaims}
      />
    </Page>
  );
};

export default ListClaims;
