import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  FormHelperText,
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LoginForm from "./components/LoginForm/LoginForm";
import moment from "moment";
import { useParams, useHistory, Redirect } from "react-router-dom";
import { getCompanyByUrl } from "../../api/api.contable";
import SessionStorageService from "../../auth/SessionStorageService";
import { SESSION_NAME } from "../../config/session";
import { Skeleton } from "@material-ui/lab";

const Login = (props) => {
  const history = useHistory();
  const { idCompany } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [redirectTo, setRedirectTo] = useState(false);

  useEffect(() => {
    obtainData();
  }, []);

  const obtainData = async () => {
    if (SessionStorageService.get(SESSION_NAME)) {
      setRedirectTo(true);
    } else {
      setLoading(true);
      try {
        let res = await getCompanyByUrl(idCompany);
        setData(res);
      } catch (error) {
        setTimeout(() => history.push("/"), 1000);
      } finally {
        setTimeout(() => setLoading(false), 1000);
      }
    }
  };

  if (redirectTo) {
    return <Redirect to="/" />;
  }

  return (
    <Grid container justify="center" alignItems="center" style={{ minHeight: "100vh" }}>
      <Grid item>
        <Container maxWidth="md">
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={12} align="center">
                  {loading ? (
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      style={{ width: "100%", height: "100%" }}
                    >
                      <Grid item xs={12}>
                        <Skeleton variant="circle" width={75} height={75} />
                        <Skeleton variant="text" />
                        <br />
                        <Skeleton variant="rect" width={350} height={75} />
                        <br />
                        <Skeleton variant="rect" width={350} height={75} />
                        <br />
                        <Skeleton variant="rect" width={350} height={75} />
                        <br />
                        <Skeleton variant="text" />
                        <br />
                        <Skeleton variant="text" />
                      </Grid>
                    </Grid>
                  ) : (
                    data && (
                      <span>
                        <Box pt={1} pb={1} style={{ width: 300, height: 200 }}>
                          <img
                            src={data.logo}
                            alt="Cover"
                            style={{ maxWidth: "100%", maxHeight: "80%" }}
                          />
                          <Typography variant="h5" gutterBottom>
                            {data.name}
                          </Typography>
                        </Box>
                        <br />
                        <LoginForm location={props.location} company={data} />
                        <Box p={1}>
                          <Link to={{ pathname: "/forgotpassword" }}>
                            <Button color="primary">¿Olvidaste tu contraseña?</Button>
                          </Link>
                          <br />
                          <FormHelperText
                            component="a"
                            target="_blank"
                            href="https://www.netappperu.com/"
                          >
                            NETAPPPERÚ &copy; 2021 - {moment().format("YYYY")}
                          </FormHelperText>
                        </Box>
                      </span>
                    )
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Login;
