import React, { useState, useEffect } from "react";
import { Grid, FormHelperText } from "@material-ui/core";
import Highlighter from "react-highlight-words";
import { Modal, notification, Icon, Input, Button, Tag, Select } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import Page from "../../components/Page";

import UserServices from "../../services/UserServices";
import useHttpNew from "../../hook/httpNew";
import sedeactual from "../../config/sedeactual";
import { Table } from "antd";
import BtnEdit from "../Global/components/Botones/BtnEdit";
import { apiContable } from "../../config/api.contable";
import EditCuenta from "../../components/Cuenta/EditCuentaNew";
import { getAccountPlan, getCostFormula } from "../../api/api.contable";
import SelectSedes from "../../components/Usuarios/SelectSedes";
import Exportar from "./ExportarExcel";
import ModalAddAccount from "../Cuenta/ModalAddAccount";

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectsedes: {
    width: "100%",
    textAlign: "left",
  },
}));

const { Option } = Select;

const digitos = [2, 3, 4, 5, 6];

const Cuentas = (props) => {
  const classes = useStyles();
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");
  //INPUTS
  const [digito, setDigito] = useState(sedeactual.digits());
  const [hasError, setErrors] = useState(false);
  const [visible, setVisible] = useState(false);
  const [id, setid] = useState(null);
  const [data, setData] = useState([]);
  const [sede, setsede] = useState(sedeactual.get());
  const [anioActual, setAnioActual] = useState(sedeactual.getAnio());
  const [cuentas, setCuentas] = useState([]);
  const [costos, setCostos] = useState([]);
  const [, sedes] = useHttpNew("headquarters-list", []);
  const [loading, setLoading] = useState(false);
  const [editAccount, setEditAccount] = useState({
    data: {},
    active: false,
  });
  const [modalAdd, setModalAdd] = useState(false);

  const getAccounts = async () => {
    setLoading(true);
    try {
      let result = await getAccountPlan(sede, anioActual);
      if (result.length > 0) {
        setCuentas(result);
      } else {
        setCuentas([]);
        notification["error"]({
          message: "Su plan de cuentas está vacío",
          description: "Por favor, subir plan de cuentas",
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al obtener las cuentas.",
      });
    }
    setLoading(false);
  };

  const getCostos = async () => {
    try {
      //let result = await getCentroCostos(sede, anioActual);
      let res = await getCostFormula(anioActual, sede);
      setCostos(res);
    } catch (error) {}
  };

  useEffect(() => {
    getAccounts();
    getCostos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sede, anioActual]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyPress={(e) =>
            e.key === "Enter" && handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Limpiar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();

    setsearchText(selectedKeys[0]);
    setsearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setsearchText("");
  }

  let columns = [
    {
      title: "NÚMERO",
      dataIndex: "number",
      key: "number",
      ...getColumnSearchProps("number"),
    },
    {
      title: "DESCRIPCIÓN",
      dataIndex: "description",
      key: "description",
      ...getColumnSearchProps("description"),
      render: (text, record, index) =>
        record.description ? record.description.toUpperCase() : "",
    },
    {
      title: "DEBE",
      dataIndex: "debit",
      key: "debit",
      ...getColumnSearchProps("debit"),
    },
    {
      title: "HABER",
      dataIndex: "asset",
      key: "asset",
      ...getColumnSearchProps("asset"),
    },
    {
      title: "VISIBLE",
      dataIndex: "visible",
      key: "visible",
      render: (text) => (
        <Tag color={text == 1 ? "green" : "red"}>
          {text == 1 ? "VISIBLE" : "NO VISIBLE"}
        </Tag>
      ),
    },
    {
      title: "COSTO",
      dataIndex: "cost",
      key: "cost",
    },
    {
      title: "ACCIONES",
      dataIndex: "_id",
      key: "_id",
      render: (e, record) => (
        <div>
          <span onClick={() => setEditAccount({ data: record, active: true })}>
            <BtnEdit
              small={true}
              disabled={sedeactual.getRol() === "usuario"}
            ></BtnEdit>
          </span>
          {/* <span onClick={() => showModal(e)}>
            <BtnDelete small={true}></BtnDelete>
          </span> */}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (cuentas.length > 0 && digito) {
      let newData = null;
      newData = cuentas.filter(
        (account) => account.number.toString().length == digito
      );
      let refactor = [];
      for (let i = 0; i < newData.length; i++) {
        let cost = costos.find(
          (el) =>
            el.id ===
            (newData[i].cost &&
              (newData[i].cost.length > 1
                ? newData[i].cost
                : "0" + newData[i].cost))
        );
        refactor.push({
          asset: newData[i].asset
            ? newData[i].asset.description.toUpperCase()
            : "",
          debit: newData[i].debit
            ? newData[i].debit.description.toUpperCase()
            : "",
          asset_id: newData[i].asset ? newData[i].asset._id : "",
          debit_id: newData[i].debit ? newData[i].debit._id : "",
          description: newData[i].description,
          number: newData[i].number,
          visible: newData[i].visible,
          cost: cost ? cost.description.toUpperCase() : "",
          cost_id: cost ? cost.id : null,
          year: newData[i].year,
          _id: newData[i]._id,
        });
      }
      setData(refactor);
    } else {
      setData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cuentas, digito, sede]);

  async function EliminarCuenta() {
    const res = await fetch(`${apiContable}/cuenta/${id}`, {
      method: "POST",
      headers: UserServices.createHeaders(),
    });
    res
      .json()
      .then((res) => {
        if (res === true) {
          notification.open({
            message: "Eliminar Cuenta",
            description: "La cuenta fue eliminada satisfactoriamente.",
            icon: <Icon type="check" style={{ color: "#49E02D" }} />,
            placement: "bottomRight",
            key: `open${Date.now()}`,
          });
        } else {
          notification.open({
            message: "Crear Cuenta",
            description: "Ocurrio un error al eliminar la cuenta.",
            placement: "bottomRight",
            icon: <Icon type="close" style={{ color: "#FE2E27" }} />,
            key: `open${Date.now()}`,
          });
        }
      })
      .catch((err) => setErrors(err));
  }

  function showModal(id) {
    setid(id);
    setVisible(true);
  }

  function handleOk(e) {
    EliminarCuenta(id);
    setVisible(false);
  }

  function handleCancel(e) {
    setVisible(false);
  }
  console.log(cuentas);
  const content = editAccount.active ? (
    <EditCuenta
      data={editAccount.data}
      setEditAccount={setEditAccount}
      getAccounts={getAccounts}
      cuentas={cuentas}
    />
  ) : (
    <Page
      className={classes.root}
      helper="PLAN DE CUENTAS"
      title="PLAN DE CUENTAS"
      itemComponent={
        <>
          <Button
            type="primary"
            onClick={() => setModalAdd(true)}
            disabled={sedeactual.getRol() === "usuario"}
          >
            AGREGAR
          </Button>
          &nbsp;
          <Exportar
            name={`PLAN_CUENTAS_${sedeactual.getAnio()}_${digito}_DIGITOS`}
            endpoint={`accounting-plan/download/${digito}/${sedeactual.getAnio()}/${sede}/${
              sedeactual.company().url
            }`}
            body={{}}
            method="POST"
            disabled={loading}
            small={false}
            api={4}
          />
        </>
      }
    >
      <Grid container spacing={2}>
        {(sedeactual.getRol() === "supernap" ||
          sedeactual.getRol() === "superadmin") && (
          <Grid item xs={12} md={8} lg={9}>
            <FormHelperText>SEDES</FormHelperText>
            <SelectSedes
              defaultValue={sede}
              data={sedes}
              onChangeSelect={(e) => {
                setsede(e);
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} md={4} lg={3}>
          <FormHelperText>DIGITOS</FormHelperText>
          <Select
            value={digito}
            onChange={(e) => setDigito(e)}
            size="large"
            style={{ width: "100%" }}
          >
            {digitos.map((e) => (
              <Option
                value={e}
                hidden={sedeactual.digits() === 5 ? e === 6 && true : false}
              >
                {e}
              </Option>
            ))}
          </Select>
        </Grid>
      </Grid>
      <br />
      <Table columns={columns} bordered dataSource={data} loading={loading} />
      <ModalAddAccount
        open={modalAdd}
        cuentas={cuentas}
        setOpen={setModalAdd}
      />
      <Modal
        onOk={handleOk}
        onCancel={handleCancel}
        title="Eliminar"
        visible={visible}
      >
        <p>¿Seguro que desea eliminar la cuenta?</p>
      </Modal>
    </Page>
  );
  return content;
};

export default Cuentas;
