import React, { useState, useEffect } from "react";
import SessionTimeService from "../../auth/SessionTimeService.js";
import SessionStorageService from "../../auth/SessionStorageService.js";
import anio from "../../config/anio";
import Page from "../../components/Page/Page";
import sedeactual from "../../config/sedeactual";
import {
  getMyCompanies,
  getAllCompanies,
  getHeadquartersByUserAndCompany,
  getHeadquartersFilter,
  getHeadquarters,
} from "../../api/api.contable";
import { notification, Select } from "antd";
import { Button, FormHelperText, Grid } from "@material-ui/core";
import SelectSedes from "../../components/Usuarios/SelectSedes";
import SelectCompania from "../Selects/SelectCompania";
import { SESSION_NAME, SESSION_YEAR } from "../../config/session";
import LoaderBackdrop from "../Global/LoaderBackdrop";
import moment from "moment";

const { Option } = Select;

const years = [];

for (let i = 2018; i <= moment().year() + 2; i++) {
  years.push(i);
}

const ConfiguracionAnio = () => {
  const [data, setData] = useState([]);
  const [usuarios, setusuarios] = useState([]);
  const [añotrabajo, setañotrabajo] = useState(anio.get());
  const [usuario, setusuario] = useState(sedeactual.get());
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(sedeactual.company()._id);
  const [loading, setLoading] = useState(false);

  const getCompanies = async () => {
    setLoading(true);
    try {
      let res = [];
      if (sedeactual.getRol() === "supernap") {
        let result = await getHeadquarters();
        res = result.data;
      } else {
        res = await getMyCompanies();
      }
      setData(res);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: `Se produjo un error al obtener la información.`,
      });
    } finally {
      setLoading(false);
    }
  };

  const obtainCompanies = async () => {
    setLoading(true);
    try {
      const res = await getAllCompanies();
      setCompanies(res);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al obtener la información.",
        description: `${error}`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompanies();
    obtainCompanies();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      setusuarios(data);
    }
  }, [data]);

  function ChangeSede() {
    let user = SessionStorageService.get(SESSION_NAME);
    let dataToModified = {
      expiresAt: user.expiresAt,
      value: user.value,
    };
    let search = data.find((e) => e.id === usuario);
    if (search) {
      dataToModified.value.headquarter = search.id;
      dataToModified.value["current_headquarter"] = search;
      SessionStorageService.remove(SESSION_NAME);
      SessionStorageService.set(SESSION_NAME, dataToModified);
      window.location.reload(false);
    } else {
      notification["error"]({
        message: "Ocurrió un error",
        description: `No se pudo cambiar de empresa.`,
      });
      window.location.reload(false);
    }
  }

  function ChangeYear() {
    SessionStorageService.set(SESSION_YEAR, {
      expiresAt: SessionTimeService.estimatedTime(),
      value: añotrabajo,
    });
    window.location.reload(true);
  }

  const changeCompany = async () => {
    let companyData = companies.find((e) => e._id === company);
    if (companyData) {
      setLoading(true);
      try {
        let mysedesNew = [];
        if (sedeactual.getRol() === "supernap") {
          let { data } = await getHeadquartersFilter(companyData.url);
          mysedesNew = data;
        } else {
          mysedesNew = await getHeadquartersByUserAndCompany(
            sedeactual.user_id(),
            company
          );
        }
        let idsArr = [];
        mysedesNew.forEach((ev) => {
          idsArr.push(ev._id);
        });
        let dataToModified = SessionStorageService.get(SESSION_NAME);
        dataToModified.value.company = companyData;
        dataToModified.value.current_headquarter = mysedesNew[0];
        dataToModified.value.headquarters = idsArr;
        SessionStorageService.remove(SESSION_NAME);
        SessionStorageService.set(SESSION_NAME, dataToModified);
        window.location.reload(true);
      } catch (error) {
        notification["error"]({
          message: "Ocurrió un error.",
          description: `No se logró obtener la información de la compañia. ${error}`,
        });
      } finally {
        setLoading(false);
      }
    } else {
      notification["error"]({
        message: "No se pudo cambiar de compañia.",
        description: "La compañia no existe.",
      });
    }
  };

  return (
    <Page helper="CONFIGURACIÓN" title="CONFIGURACIÓN DE TRABAJO">
      <br />
      {loading && <LoaderBackdrop />}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormHelperText>SELECCIONAR AÑO</FormHelperText>
        </Grid>
        <Grid
          item
          style={{
            width: "calc((100%) - 110px)",
          }}
        >
          <Select
            value={añotrabajo}
            onChange={(e) => setañotrabajo(e)}
            style={{ width: "100%" }}
            size="large"
          >
            {years.map((e) => (
              <Option value={e}>{e}</Option>
            ))}
          </Select>
        </Grid>
        <Grid item style={{ width: 100 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={ChangeYear}
            disabled={añotrabajo === sedeactual.getAnio()}
          >
            Cambiar
          </Button>
        </Grid>
      </Grid>
      {sedeactual.getRol() === "supernap" && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormHelperText>SELECCIONAR COMPAÑIA</FormHelperText>
          </Grid>
          <Grid
            item
            style={{
              width: "calc((100%) - 110px)",
            }}
          >
            <SelectCompania
              defaultValue={company}
              data={companies}
              onChangeSelect={(e) => setCompany(e)}
            />
          </Grid>
          <Grid item style={{ width: 100 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={changeCompany}
              disabled={company === sedeactual.company()._id}
            >
              Cambiar
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormHelperText>SELECCIONAR EMPRESA</FormHelperText>
        </Grid>
        <Grid
          item
          style={{
            width: "calc((100%) - 110px)",
          }}
        >
          <SelectSedes
            defaultValue={usuario}
            data={usuarios}
            onChangeSelect={(e) => {
              setusuario(e);
            }}
          />
          <FormHelperText component="span" style={{ color: "#0A94D8" }}>
            {(sedeactual.getRol() === "supernap" ||
              sedeactual.getRol() === "superadmin") &&
              "* El Usuario debe estar asignado la empresa para poder visualizarla."}
          </FormHelperText>
        </Grid>
        <Grid item style={{ width: 100 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={ChangeSede}
            disabled={usuario === sedeactual.get()}
          >
            Cambiar
          </Button>
        </Grid>
      </Grid>
    </Page>
  );
};

export default ConfiguracionAnio;
