import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormHelperText,
  InputAdornment,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  FormControlLabel,
} from "@material-ui/core";
import _ from "lodash/fp";
import { makeStyles } from "@material-ui/styles";
import useForm from "react-hook-form";
import moment from "moment";
import { apiInventario } from "../../../../config/api.contable";
import axios from "axios";
import NumberFormat from "react-number-format";
import Switch from "@material-ui/core/Switch";
import { notification } from "antd";

const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: "50%",
    maxWidth: "80%",
  },
  iconText: {
    color: "#aaa",
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //prefix="S/ "
    />
  );
}

const AddEquipos = (props) => {
  const classes = useStyles();
  const {
    visible,
    setVisible,
    reloadFunction,
    categories,
    idLocation,
    sede,
    loading,
    setLoading,
    company,
  } = props;
  const [status, setStatus] = useState("BUENO");
  const [inputValueCat, setInputValueCat] = useState("");
  const [customValueCat, setCustomValueCat] = useState(null);
  const [switchElem, setSwitchElem] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [subCategories, setSubCategories] = useState([]);
  const [subcategory, setSubcategory] = useState(null);
  const [values, setValues] = useState({
    unit_value: "",
    historical_value: "",
    amount: 1,
  });

  const addRegister = async (data) => {
    if (idLocation && customValueCat) {
      setLoading(true);
      try {
        if (values.unit_value && values.historical_value && values.amount) {
          data.category = customValueCat._id;
          data.subcategory = subcategory ? subcategory : null;
          data.location = idLocation;
          data.status = status;
          data.sede = sede;
          data.unit_value = values.unit_value;
          data.historical_value = values.historical_value;
          data.amount = 1;
          data.type = "machinery";
          data.model = data.model.toUpperCase();
          data.serie = data.serie.toUpperCase();
          data.trademark = data.trademark.toUpperCase();
          data.description = data.description.toUpperCase();

          if (
            (!data.model || !data.serie || !data.trademark) &&
            !data.description
          ) {
            notification["info"]({
              message:
                "La descripción es requerida en caso de no ingresar serie, modelo o marca.",
            });
            setLoading(false);
            return;
          }

          for (let x = 0; x < values.amount; x++) {
            await axios.post(
              `${apiInventario}/fixed-asset/${sede}/${company}`,
              data
            );
            await sleep(100);
          }
          notification["success"]({
            message: "El registro se guardó correctamente.",
          });
          reloadFunction();
          setVisible(false);
        } else {
          notification["info"]({
            message: "Campos requeridos que falta completar.",
          });
        }
      } catch (err) {
        notification["error"]({
          message: "Ocurrió un error al agregar el registro.",
          description: `${err}`,
        });
      } finally {
        setLoading(false);
      }
    } else {
      notification["info"]({
        message: "Debe seleccionar un nombre de cuenta.",
      });
    }
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleChange = (event) => {
    if (event.target.name == "unit_value") {
      values.historical_value = event.target.value.toUpperCase();
    }
    setValues({
      ...values,
      [event.target.name]: event.target.value.toUpperCase(),
    });
  };

  const handleChangeSwitch = (e) => {
    setValues({
      ...values,
      amount: 1,
    });
    setSwitchElem(!switchElem);
  };

  return (
    <Dialog open={visible} classes={{ paper: classes.dialog }}>
      <DialogTitle>
        <Typography
          variant="h2"
          component="h2"
          color="textPrimary"
          align="center"
        >
          AGREGAR EQUIPO
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(addRegister)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={8}>
              <Autocomplete
                id="combo-box-demo"
                options={categories}
                fullWidth
                getOptionLabel={(option) => option.name.toUpperCase()}
                renderOption={(option) => (
                  <React.Fragment>
                    <Typography component="span" variant="subtitle2">
                      {option.name.toUpperCase()}{" "}
                      <FormHelperText component="span">
                        {option.account_number} {option.account_name}
                      </FormHelperText>
                    </Typography>
                  </React.Fragment>
                )}
                inputValue={inputValueCat}
                onInputChange={(event, newInputValue) => {
                  setInputValueCat(newInputValue);
                }}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setCustomValueCat(newValue);
                    setSubCategories([...newValue.subcategory]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Categoria"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>SubCategorias</InputLabel>
                <Select
                  value={subcategory}
                  onChange={(event) => setSubcategory(event.target.value)}
                  label="SubCategorias"
                >
                  {subCategories.map((el) => (
                    <MenuItem value={el._id}>{el.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                label="Valor unitario *"
                fullWidth
                variant="outlined"
                name="unit_value"
                value={values.unit_value}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.iconText}
                    >
                      S/
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                label="Cantidad *"
                fullWidth
                variant="outlined"
                disabled={!switchElem ? true : false}
                name="amount"
                value={values.amount}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className={classes.iconText}>
                      units
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                label="Valor histórico"
                fullWidth
                variant="outlined"
                name="historical_value"
                disabled={true}
                value={values.historical_value}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.iconText}
                    >
                      S/
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                label="Fecha de adquisición"
                fullWidth
                variant="outlined"
                defaultValue={moment(Date.now()).format("YYYY-MM-DD")}
                type="date"
                name="acquisition_date"
                inputRef={register({
                  required: true,
                })}
              />
              <FormHelperText error>
                {_.get("acquisition_date.type", errors) === "required" &&
                  "La fecha de adquisición es requerida"}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                label="N° de serie o placa"
                fullWidth
                variant="outlined"
                name="serie"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.iconText}
                    >
                      N°
                    </InputAdornment>
                  ),
                }}
                inputRef={register({
                  required: false,
                  maxLength: 20,
                  // pattern: /^[a-zA-Z0-9 -_]+$/i,
                })}
              />
              <FormHelperText error>
                {_.get("serie.type", errors) === "required" &&
                  "La serie es requerida."}
                {_.get("serie.type", errors) === "maxLength" &&
                  "No debe exceder los 20 caracteres."}
                {_.get("serie.type", errors) === "pattern" &&
                  "Solo se acepta caracteres alfabéticos y números."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                label="Modelo"
                fullWidth
                variant="outlined"
                name="model"
                inputRef={register({
                  required: false,
                  maxLength: 20,
                  // // pattern: /^[A-Za-z0-9.,]+$/i,
                })}
              />
              <FormHelperText error>
                {_.get("model.type", errors) === "required" &&
                  "El modelo es requerido."}
                {_.get("model.type", errors) === "maxLength" &&
                  "No debe exceder los 20 caracteres."}
                {_.get("model.type", errors) === "pattern" &&
                  "Solo se acepta caracteres alfabéticos y números."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                label="Marca"
                fullWidth
                variant="outlined"
                name="trademark"
                inputRef={register({
                  required: false,
                  maxLength: 100,
                  // pattern: /^[A-Za-z0-9.,]+$/i,
                })}
              />
              <FormHelperText error>
                {_.get("trademark.type", errors) === "required" &&
                  "La marca comercial es requerida."}
                {_.get("trademark.type", errors) === "maxLength" &&
                  "No debe exceder los 100 caracteres."}
                {_.get("trademark.type", errors) === "pattern" &&
                  "Solo se acepta números y caracteres alfabeticos."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Estado</InputLabel>
                <Select
                  value={status}
                  onChange={(event) =>
                    setStatus(event.target.value.toUpperCase())
                  }
                  label="Estado"
                >
                  <MenuItem value="BUENO">BUENO</MenuItem>
                  <MenuItem value="REGULAR">REGULAR</MenuItem>
                  <MenuItem value="DETERIORADO">DETERIORADO</MenuItem>
                  <MenuItem value="FALTA ARREGLAR">FALTA ARREGLAR</MenuItem>
                  <MenuItem value="MALOGRADO">MALOGRADO</MenuItem>
                  <MenuItem value="DESUSO">DESUSO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                label="Descripción"
                fullWidth
                variant="outlined"
                name="description"
                inputRef={register({
                  required: false,
                  maxLength: 255,
                })}
              />
              <FormHelperText error>
                {_.get("description.type", errors) === "required" &&
                  "La descripción es requerida."}
                {_.get("description.type", errors) === "maxLength" &&
                  "No debe exceder los 255 caracteres."}
              </FormHelperText>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            control={
              <Switch
                checked={switchElem}
                onChange={handleChangeSwitch}
                color="primary"
                name="switchElem"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label="Multiples Registros"
          />
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => setVisible(false)}
          >
            Cerrar
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={(customValueCat && idLocation) || loading ? false : true}
          >
            Guardar
          </Button>
        </DialogActions>
        {categories.length > 0 || idLocation ? (
          ""
        ) : (
          <Typography align="right" color="secondary">
            No se encontraron categorías registradas. Registre categorías y
            vuelva a intentarlo.
          </Typography>
        )}
      </form>
    </Dialog>
  );
};

export default AddEquipos;
