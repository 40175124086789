import React, { Fragment, useState } from "react";
import {
  Grid,
  Collapse,
  Typography,
  Card,
  Box,
  CardActionArea,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  IconButton,
  Chip,
} from "@material-ui/core";
import FormIncidencias from "../Form";
import sedeactual from "src/config/sedeactual";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { AttachFile } from "@material-ui/icons";

const TableDetail = ({ data, setLoading, details, setModal }) => {
  return (
    <Box mt={1}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CustomCollapse title="FORMATO 1: REGISTRO DE INCIDENCIAS">
            <FormIncidencias
              name="---"
              id={sedeactual.sede_id()}
              setLoading={setLoading}
              data={data}
            />
          </CustomCollapse>
        </Grid>
        {details.map((el, ind1) => (
          <Grid item xs={12} md={6} key={ind1}>
            <CustomCollapse title={`FORMATO 2: ${el.status}`}>
              <List
                dense
                subheader={
                  <ListSubheader>
                    <Grid container justify="space-between">
                      <Grid item>Acciones del {moment(el.date_report).format("ddd D MMMM")}</Grid>
                      {data.status !== "FINALIZADO" && (
                        <Grid item>
                          <IconButton
                            color="primary"
                            onClick={() => setModal({ open: true, data: el })}
                          >
                            <EditIcon />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </ListSubheader>
                }
              >
                {el.actions.map((el, ind2) => (
                  <ListItem divider key={ind2}>
                    <ListItemText
                      primary={`${el.action} | ${el.aditional}`}
                      secondary={moment(el.date).format("DD/MM/YYYY")}
                    />
                  </ListItem>
                ))}
                {el.file && (
                  <ListItem>
                    <ListItemText
                      primary={
                        <Chip
                          label="Evidencia"
                          icon={<AttachFile />}
                          clickable
                          component="a"
                          href={el.file}
                          target="_blank"
                        />
                      }
                    />
                  </ListItem>
                )}
              </List>
            </CustomCollapse>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TableDetail;

const CustomCollapse = ({ title, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <Card>
        <CardActionArea onClick={() => setOpen(!open)}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{ backgroundColor: "#0288d1" }}
          >
            <IconButton style={{ color: open ? "#fff" : "#000" }}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
            <Typography align="center" variant="h5">
              <b style={{ color: open ? "#fff" : "#000" }}>{title}</b>
            </Typography>
            <IconButton style={{ color: open ? "#fff" : "#000" }}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Grid>
        </CardActionArea>
        <Collapse in={open}>
          <Typography component="div">{children}</Typography>
        </Collapse>
      </Card>
    </Fragment>
  );
};
