import React, { useState, useEffect } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/es";
import moment from "moment";
import Page from "../../components/Page/Page";
import sedeactual from "../../config/sedeactual";
import SelectSedes from "../Usuarios/SelectSedes";
import { apiContable } from "../../config/api.contable";
import axios from "axios";
import { notification } from "antd";
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import Spinner from "./Spinner";
import { getActiveSedes } from "../../api/api.contable";

const Balancefecha = () => {
  const [sede, setsede] = useState(sedeactual.get());
  const [sedes, setSedes] = useState([]);
  const [yearS, setYearS] = useState(new Date(Date.now()).getFullYear());
  const [periodosData, setPeriodosData] = useState([]);

  const obtainedSedes = async () => {
    try {
      const result = await getActiveSedes();
      setSedes(result);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error!",
        description: `No se logró obtener las sedes. ${error}`,
      });
    }
  };

  useEffect(() => {
    obtainedSedes();
  }, []);

  const getWorkingPeriod = async () => {
    let result = await axios.get(
      `${apiContable}/working-period/${
        sedeactual.company().url
      }/${sede}/${yearS}`
    );
    setPeriodosData(result.data);
  };

  useEffect(() => {
    if (sede && yearS) {
      getWorkingPeriod();
    } else {
      notification["error"]({
        message: "Año y Sede",
        description: `Debe seleccionar el año y la sede`,
      });
    }
    // eslint-disable-next-line
  }, [sede, yearS]);

  const setMonth = async (date, record) => {
    try {
      let data = {
        period: record.period,
        expiration_date: moment(date).format("YYYY-MM-DD"),
      };
      await axios.post(
        `${apiContable}/working-period/${
          sedeactual.company().url
        }/${sede}/${yearS}`,
        data
      );
      getWorkingPeriod();
      notification["success"]({
        message: "La fecha de expiración se grabó correctamente.",
        description: `Mes: ${record.description}, Fecha de expiración: ${data.expiration_date}`,
      });
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error!",
        description: "No se logró guardar la fecha de expiración.",
      });
    }
  };

  return (
    <Page helper="CONFIGURACIÓN" title="Configuración Periodo">
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={9} xl={10}>
          <SelectSedes
            defaultValue={sede}
            data={sedes}
            onChangeSelect={(e) => {
              setsede(e);
            }}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3} xl={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel>Año</InputLabel>
            <Select
              label="Año"
              value={yearS}
              onChange={(e) => setYearS(e.target.value)}
            >
              <MenuItem value={2019}>2019</MenuItem>
              <MenuItem value={2020}>2020</MenuItem>
              <MenuItem value={2021}>2021</MenuItem>
              <MenuItem value={2022}>2022</MenuItem>
              <MenuItem value={2023}>2023</MenuItem>
              <MenuItem value={2024}>2024</MenuItem>
              <MenuItem value={2025}>2025</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box mt={3}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ padding: 20 }} align="right">
                      MES
                    </TableCell>
                    <TableCell align="center">FECHA DE EXPIRACIÓN</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {periodosData.length > 0 ? (
                    periodosData.map((ev) => (
                      <TableRow>
                        <TableCell align="right">{ev.description}</TableCell>
                        <TableCell>
                          <MuiPickersUtilsProvider
                            locale={deLocale}
                            utils={DateFnsUtils}
                          >
                            <KeyboardDatePicker
                              format="dd/MM/yyyy"
                              size="small"
                              inputVariant="outlined"
                              value={
                                ev.expiration_date &&
                                moment(ev.expiration_date)
                                  .add(1, "days")
                                  .format("YYYY-MM-DD")
                              }
                              onChange={(date) => setMonth(date, ev)}
                              fullWidth
                            />
                          </MuiPickersUtilsProvider>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2} style={{ padding: "25px 0" }}>
                        <Spinner />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Balancefecha;
