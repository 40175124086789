import React, { useState, useEffect } from "react";
import { Select } from "antd";

const SelectSedes = (props) => {
  const [sedes, setSedes] = useState(props.data);
  const onChange = (number) => {
    props.onChangeSelect(number);
  };
  useEffect(() => {
    setSedes(props.data);
  }, [props]);

  /* const renderOptions = () => {
    return sedes.sort((a, b) => a["description"].localeCompare(b["description"]))
    .filter((e) => e.work_mode_id == 1).map((i) => (
      <Option key={i.id} value={i.id} title={i.description}>{`  ${
        i.work_mode_id == 1 ? "Comunidad" : "Obras"
      } - ${i.description} - ${i.address}`}</Option>
    ))
  }; */
  const filterOptions = (inputValue, option) => {
    const title = option.props.title.toLowerCase();
    return title.includes(inputValue.toLowerCase(), 0);
  };
  const { Option } = Select;
  const content = (
    <Select
      showSearch
      size="large"
      style={{ width: "100%" }}
      value={props.defaultValue}
      onChange={onChange}
      filterOption={filterOptions}
    >
      {/* {renderOptions()} */}
      {sedes &&
        sedes
          .sort((a, b) => a["description"].localeCompare(b["description"]))
          .filter((e) => e.digits == 5)
          .map((i) => (
            <Option
              key={i.id}
              value={i.id}
              title={i.description}
              onClick={() =>
                props.setTitle &&
                props.setTitle({
                  title: i.description,
                  address: i.address,
                })
              }
            >{`${i.digits == 5 ? "COMUNIDAD" : "OBRAS"} - ${
              i.description
            } - ${i.address}`}</Option>
          ))}
      {sedes &&
        sedes
          .sort((a, b) => a["description"].localeCompare(b["description"]))
          .filter((e) => e.digits == 6)
          .map((i) => (
            <Option
              key={i.id}
              value={i.id}
              title={i.description}
              onClick={() =>
                props.setTitle &&
                props.setTitle({
                  title: i.description,
                  address: i.address,
                })
              }
            >{`${i.digits == 5 ? "COMUNIDAD" : "OBRAS"} - ${
              i.description
            } - ${i.address}`}</Option>
          ))}
    </Select>
  );
  return content;
};
export default SelectSedes;
