import React from "react";
import {
  Grid,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";
import SelectSexo from "src/components/Selects/SelectSexo";
import SelectNivelEscolar from "src/components/Selects/SelectNivelEscolar";
import SelectGradoEscolar from "src/components/Selects/SelectGradoEscolar";
import SelectTurnoEscolar from "src/components/Selects/SelectTurnoEscolar";
import SelectSeccionEscolar from "src/components/Selects/SelectSeccionEscolar";

const PartThree = ({
  errors,
  register,
  agdSexo,
  setAgdSexo,
  agdLevel,
  setAgdLevel,
  setAgdGrade,
  setAgdSection,
  agdGrade,
  agdSection,
  agdShift,
  setAgdShift,
  agdDiscapacidad,
  setAgdDiscapacidad,
  agdForanero,
  setAgdForanero,
  eInf,
  setValue,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          InputLabelProps={{ shrink: true }}
          size="small"
          fullWidth
          label="Iniciales del o de la estudiante *"
          name="agdInitils"
          variant="outlined"
          onChange={(e) => setValue("agdInitils", e.target.value)}
          defaultValue={Boolean(eInf) ? eInf.agdInitils : ""}
          inputRef={register({
            required: true,
          })}
          error={Boolean(errors?.agdInitils)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          InputLabelProps={{ shrink: true }}
          size="small"
          fullWidth
          label="Edad *"
          name="agdAge"
          variant="outlined"
          defaultValue={Boolean(eInf) ? eInf.agdAge : ""}
          inputRef={register({
            required: false,
            pattern: /^[0-9]/i,
          })}
          error={Boolean(errors?.agdAge)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <SelectSexo sexo={agdSexo} setSexo={setAgdSexo} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <SelectNivelEscolar
          setLevel={(e) => {
            setAgdLevel(e);
            setAgdGrade(null);
            setAgdSection(null);
          }}
          level={agdLevel}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <SelectGradoEscolar
          setGrade={(e) => {
            setAgdGrade(e);
            setAgdSection(null);
          }}
          grade={agdGrade}
          level={agdLevel}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <SelectSeccionEscolar setSection={setAgdSection} section={agdSection} grade={agdGrade} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} container alignItems="center">
        <SelectTurnoEscolar shift={agdShift} setShift={setAgdShift} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} container alignItems="center">
        <TextField
          InputLabelProps={{ shrink: true }}
          size="small"
          fullWidth
          label="Celular *"
          name="agdPhone"
          defaultValue={Boolean(eInf) ? eInf.agdPhone : ""}
          variant="outlined"
          inputRef={register({
            required: false,
            minLength: 6,
            maxLength: 10,
            pattern: /^[0-9]+$/i,
          })}
          error={Boolean(errors?.agdPhone)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        {agdDiscapacidad.value ? (
          <TextField
            InputLabelProps={{ shrink: true }}
            size="small"
            fullWidth
            label="Discapacidad* (especificar)"
            variant="outlined"
            value={agdDiscapacidad.text}
            onChange={(e) =>
              setAgdDiscapacidad({
                ...agdDiscapacidad,
                text: e.target.value,
              })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      setAgdDiscapacidad({
                        text: "",
                        value: false,
                      })
                    }
                  >
                    <ReplayIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Discapacidad*</FormLabel>
            <RadioGroup
              value={agdDiscapacidad.value}
              onChange={(e) =>
                setAgdDiscapacidad({
                  ...agdDiscapacidad,
                  value: e.target.value,
                })
              }
            >
              <Grid container justify="space-between">
                <Grid item>
                  <FormControlLabel value={false} control={<Radio color="primary" />} label="NO" />
                </Grid>
                <Grid item>
                  <FormControlLabel value={true} control={<Radio color="primary" />} label="SI" />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        )}
      </Grid>
      <Grid item xs={12}>
        {agdForanero.value ? (
          <TextField
            InputLabelProps={{ shrink: true }}
            size="small"
            fullWidth
            label="Pertenece a un pueblo originario o afroperuano* (especificar)"
            variant="outlined"
            value={agdForanero.text}
            onChange={(e) => setAgdForanero({ ...agdForanero, text: e.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      setAgdForanero({
                        text: "",
                        value: false,
                      })
                    }
                  >
                    <ReplayIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              Pertenece a un pueblo originario o afroperuano*
            </FormLabel>
            <RadioGroup
              value={agdForanero.value}
              onChange={(e) => setAgdForanero({ ...agdForanero, value: e.target.value })}
            >
              <Grid container>
                <Grid item>
                  <FormControlLabel value={false} control={<Radio color="primary" />} label="NO" />
                </Grid>
                <Grid item>
                  <FormControlLabel value={true} control={<Radio color="primary" />} label="SI" />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        )}
      </Grid>
    </Grid>
  );
};

export default PartThree;
