import React, { Fragment, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Divider, Grid, FormHelperText, Box } from "@material-ui/core";

function Basic(props) {
  const { myFiles, setMyFiles, accept } = props;

  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    // eslint-disable-next-line
    [myFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accept,
  });

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const removeAll = () => {
    setMyFiles([]);
  };

  return (
    <Fragment>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{
          border: "2px dashed",
          backgroundColor: "#fafafa",
          color: "#bdbdbd",
          cursor: "pointer",
          width: "100%",
          padding: 2,
        }}
      >
        <Grid item component="div" {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Arrastre y suelte archivos aquí, o haga clic para seleccione</p>
        </Grid>
      </Grid>

      {myFiles.length > 0 && (
        <Fragment>
          {myFiles.map((file) => (
            <Grid container justify="space-between">
              <Grid item>
                <FormHelperText>
                  {file.name}
                  {" ─ "}
                  {parseFloat(file.size / 1024 / 1024).toFixed(3)}
                  {" MB"}
                </FormHelperText>
              </Grid>
              <Grid item>
                <CloseIcon
                  onClick={removeFile(file)}
                  color="secondary"
                  style={{ cursor: "pointer" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          ))}
        </Fragment>
      )}
      {myFiles.length > 0 && (
        <Box pt={1} style={{ textAlign: "center" }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={removeAll}
            size="small"
          >
            Borrar todos
          </Button>
        </Box>
      )}
    </Fragment>
  );
}

export default Basic;
