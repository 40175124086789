import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormHelperText,
  InputAdornment,
  Fab,
  Badge,
  Card,
  CardMedia,
} from "@material-ui/core";
import _ from "lodash/fp";
import useForm from "react-hook-form";
import moment from "moment";
import axios from "axios";
import { apiInventario } from "../../../../../config/api.contable";
import NumberFormat from "react-number-format";
import { notification } from "antd";
import { Delete } from "@material-ui/icons";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //prefix="S/ "
    />
  );
}

const ModalAdd = (props) => {
  const { visible, setVisible, data, reloadFunction, loading, setLoading } = props;
  const [images, setImages] = useState(data.images ?? []);
  const { register, handleSubmit, errors } = useForm();
  const [values, setValues] = useState({
    area: data.area ? data.area : "",
    side_a: data.side_a ? data.side_a : "",
    side_b: data.side_b ? data.side_b : "",
    side_c: data.side_c ? data.side_c : "",
    side_d: data.side_d ? data.side_d : "",
    side_another: data.side_another ? data.side_another : "",
    built_area: data.built_area ? data.built_area : "",
    acquisition_value: data.acquisition_value ? data.acquisition_value : "",
    acquisition_build_value: data.acquisition_build_value ? data.acquisition_build_value : "",
    ground_autovalue: data.ground_autovalue ? data.ground_autovalue : "",
    build_autovalue: data.build_autovalue ? data.build_autovalue : "",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value.toUpperCase(),
    });
  };

  const editRegister = async (dataEdit) => {
    setLoading(true);
    try {
      dataEdit.area = values.area;
      dataEdit.side_a = values.side_a;
      dataEdit.side_b = values.side_b;
      dataEdit.side_c = values.side_c;
      dataEdit.side_d = values.side_d;
      dataEdit.side_another = values.side_another;
      dataEdit.built_area = values.built_area;
      dataEdit.acquisition_value = values.acquisition_value;
      dataEdit.acquisition_build_value = values.acquisition_build_value;
      dataEdit.ground_autovalue = values.ground_autovalue;
      dataEdit.build_autovalue = values.build_autovalue;
      dataEdit.images = images;

      await axios.put(`${apiInventario}/update-edification/${data._id}`, dataEdit);
      reloadFunction();
      notification["success"]({
        message: `El registro se actualizó correctamente.`,
      });
      setVisible(false);
    } catch (err) {
      notification["error"]({
        message: `No se logró actualizar el registro`,
        description: `${err}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = (index) => {
    images.splice(index, 1);
    setImages([...images]);
  };

  return (
    <Dialog maxWidth="xl" fullWidth open={visible}>
      <DialogTitle>
        <Typography variant="h2" component="h2" color="textPrimary" align="center">
          ACTUALIZAR INFORMACIÓN
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(editRegister)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Denominación del predio*"
                fullWidth
                variant="outlined"
                name="property_name"
                defaultValue={data.property_name}
                inputRef={register({
                  required: true,
                  maxLength: 100,
                  // pattern: /^[A-Za-z0-9 áéóúíñ]+$/i,
                })}
              />
              <FormHelperText error>
                {_.get("property_name.type", errors) === "required" && "El título es requerido."}
                {_.get("property_name.type", errors) === "maxLength" &&
                  "No debe exceder los 100 caracteres."}
                {_.get("property_name.type", errors) === "pattern" &&
                  "Solo debe contener caracteres alfabéticos."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Dirección *"
                fullWidth
                variant="outlined"
                name="address"
                defaultValue={data.address}
                inputRef={register({
                  required: true,
                  maxLength: 100,
                })}
              />
              <FormHelperText error>
                {_.get("address.type", errors) === "required" && "La dirección es requerida."}
                {_.get("address.type", errors) === "maxLength" &&
                  "No debe exceder los 100 caracteres."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
              <TextField
                label="Área total del terreno*"
                fullWidth
                variant="outlined"
                value={values.area}
                required
                onChange={handleChange}
                name="area"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      mt<sup>2</sup>
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
              <TextField
                label="Lado A"
                fullWidth
                variant="outlined"
                value={values.side_a}
                required
                onChange={handleChange}
                name="side_a"
                InputProps={{
                  endAdornment: <InputAdornment position="end">mt</InputAdornment>,
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
              <TextField
                label="Lado B"
                fullWidth
                variant="outlined"
                onChange={handleChange}
                value={values.side_b}
                required
                name="side_b"
                InputProps={{
                  endAdornment: <InputAdornment position="end">mt</InputAdornment>,
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
              <TextField
                label="Lado C"
                fullWidth
                variant="outlined"
                name="side_c"
                value={values.side_c}
                required
                onChange={handleChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">mt</InputAdornment>,
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
              <TextField
                label="Lado D"
                fullWidth
                variant="outlined"
                name="side_d"
                onChange={handleChange}
                value={values.side_d}
                InputProps={{
                  endAdornment: <InputAdornment position="end">mt</InputAdornment>,
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
              <TextField
                label="Lado restante"
                fullWidth
                variant="outlined"
                name="side_another"
                onChange={handleChange}
                value={values.side_another}
                InputProps={{
                  endAdornment: <InputAdornment position="end">mt</InputAdornment>,
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                label="Área construida*"
                fullWidth
                variant="outlined"
                name="built_area"
                onChange={handleChange}
                value={values.built_area}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      mt<sup>2</sup>
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                label="N° de partida electrónica*"
                fullWidth
                variant="outlined"
                name="departure_number"
                defaultValue={data.departure_number}
                inputRef={register({
                  required: true,
                  maxLength: 20,
                  // pattern: /^[A-Za-z0-9.,]+$/i,
                })}
              />
              <FormHelperText error>
                {_.get("departure_number.type", errors) === "required" &&
                  "El número de partida electrónica es requerido."}
                {_.get("departure_number.type", errors) === "maxLength" &&
                  "No debe exceder los 20 caracteres."}
                {_.get("departure_number.type", errors) === "pattern" &&
                  "Solo se acepta números y caracteres alfabeticos."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                label="Fecha de adquisición del terreno*"
                fullWidth
                variant="outlined"
                type="date"
                defaultValue={moment(data.acquisition_date).format("YYYY-MM-DD")}
                inputProps={{
                  max: moment(Date.now()).format("YYYY-MM-DD"),
                }}
                name="acquisition_date"
                inputRef={register({
                  required: true,
                  maxLength: 20,
                })}
              />
              <FormHelperText error>
                {_.get("acquisition_date.type", errors) === "required" &&
                  "La fecha de adquisición del terreno es requerida."}
                {_.get("acquisition_date.type", errors) === "maxLength" &&
                  "No debe exceder los 20 caracteres."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                label="Valor de adquisición del terreno*"
                fullWidth
                variant="outlined"
                name="acquisition_value"
                onChange={handleChange}
                value={values.acquisition_value}
                required
                InputProps={{
                  startAdornment: <InputAdornment position="start">S/</InputAdornment>,
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                label="Valor de adquisición de la construcción*"
                fullWidth
                variant="outlined"
                name="acquisition_build_value"
                onChange={handleChange}
                value={values.acquisition_build_value}
                required
                InputProps={{
                  startAdornment: <InputAdornment position="start">S/</InputAdornment>,
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                label="Valor de autovaluo municipalidad - terreno*"
                fullWidth
                variant="outlined"
                name="ground_autovalue"
                onChange={handleChange}
                value={values.ground_autovalue}
                required
                InputProps={{
                  startAdornment: <InputAdornment position="start">S/</InputAdornment>,
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                label="Valor de autovaluo municipalidad - Construcción*"
                fullWidth
                variant="outlined"
                name="build_autovalue"
                onChange={handleChange}
                value={values.build_autovalue}
                required
                InputProps={{
                  startAdornment: <InputAdornment position="start">S/</InputAdornment>,
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Observaciones "
                fullWidth
                variant="outlined"
                name="observations"
                defaultValue={data.observations}
                inputRef={register({
                  required: false,
                  maxLength: 250,
                  // pattern: /^[A-Za-z0-9 áéóúíñ,.]+$/i,
                })}
              />
              <FormHelperText error>
                {_.get("observations.type", errors) === "required" &&
                  "La observación es requerida."}
                {_.get("observations.type", errors) === "maxLength" &&
                  "No debe exceder los 250 caracteres."}
                {_.get("observations.type", errors) === "pattern" &&
                  "Solo se acepta números y caracteres alfabéticos."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {images.map((img, index) => (
                  <Grid item key={index}>
                    <Badge
                      badgeContent={
                        <Fab
                          size="small"
                          color="secondary"
                          onClick={() => handleRemoveImage(index)}
                        >
                          <Delete />
                        </Fab>
                      }
                    >
                      <Card>
                        <CardMedia style={{ height: 100, width: 100 }} image={img} />
                      </Card>
                    </Badge>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="outlined" onClick={() => setVisible(false)}>
            Cerrar
          </Button>
          <Button color="primary" variant="contained" type="submit" disabled={loading}>
            Actualizar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalAdd;
