/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useForm from "react-hook-form";
import SelectCostFormula from "../../components/Selects/SelectCostFormula";
import { Modal, notification, Icon, Select } from "antd";
import { makeStyles } from "@material-ui/styles";
import IngresosEgresos from "../Voucher/IngresosEgresos";
import SelectDoc from "./SelectDoc.jsx";
import sedeactual from "../../config/sedeactual";
import CardSaldo from "./CardSaldo";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SearchIcon from "@material-ui/icons/Search";
import SelectCuenta from "../../components/Selects/SelectCuenta.jsx";
import SelectPeriodo from "../Selects/SelectPeriodo";
import SelectCC from "./SelectCC";
import ModalValidation from "./ModalValidation.jsx";
import Page from "../../components/Page/Page";
import moment from "moment";
import deLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import {
  registrarCliente,
  postRuc,
  lastNumberVoucher,
  getDateExchange_rate,
  getMajorCaja,
  getAccountPlan,
  getAccountAssistantById,
  // getCentroCostos,
  getTipoDocs,
  getTypeCoins,
  validateVoucher,
} from "../../api/api.contable";
import Spinner from "../Global/Spinner";

const { Option } = Select;
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));

const Caja = (props) => {
  const classes = useStyles();
  const [costo, setcosto] = useState(null);
  // const [costos, setcostos] = useState([]);
  const [modalCC, setModalCC] = useState(false);
  const [modalTipo, setModalTipo] = useState(false);
  const [actualizarcambio, setactualizarcambio] = useState(false);
  const [modalvalidation, setmodalvalidation] = useState(false);
  let sumaTotal = 0;
  let yeartrabajo = sedeactual.getAnio();
  // const [updateCuentas, setUpdateCuentas] = useState(false);
  const [tipo, setTipo] = useState("DEBE");
  // const [tipocuenta, setTipocuenta] = useState("ingreso");
  const [dataCuentas, setDataCuentas] = useState([]);
  const [dataCuentasDB, setDataCuentasDB] = useState([]);
  const [dateMui, setdateMui] = useState(null);
  const [dataTipos, setDataTipos] = useState([]);
  //ESTADOS VOUCHER
  const [correlativo, setCorrelativo] = useState("");
  const [date, setDate] = useState("");
  const [ruc, setRuc] = useState("");
  const [cliente, setCliente] = useState("");
  const [clienteId, setClienteId] = useState(null);
  const [type, setType] = useState(null);
  // const [origen, setOrigen] = useState(null);
  const [numDoc, setnumDoc] = useState("");
  const [numCom, setnumCom] = useState("");
  const [loading, setLoading] = useState(false);
  // const [actualizarMayor, setactualizarMayor] = useState(false);

  //ESTADOS MODAL
  const [visibleCambio, setVisibleCambio] = useState(false);
  const [cambiodeldia, setCambiodelDia] = useState(1);
  const [cuenta, setCuenta] = useState(null);
  const [glosa, setGlosa] = useState("");
  const [cc, setCC] = useState(null);
  const [moneda, setMoneda] = useState(1);
  const [periodo, setPeriodo] = useState(moment(Date.now()).month() + 1);
  // const [periodoMayor, setperiodoMayor] = useState(`${yeartrabajo}/${periodo.toString().substr(0, 2)}`);

  const [validationtype, setvalidationtype] = useState(false);

  // const [validationDate, setvalidationDate] = useState(false);
  const [validationMonto, setvalidationMonto] = useState(false);
  const [tipoDocM, setTipoDocM] = useState("");
  const [monto, setMonto] = useState("");
  const [verification, setVerification] = useState(true);
  const [visible, setVisibleB] = useState(false);
  const [accountCaja, setaccountCaja] = useState();
  const [flag, setFlag] = useState(false);
  const [data, setData] = useState([]);
  const [TipodeCambio, setTipodeCambio] = useState([]);
  const { handleSubmit, register, errors } = useForm();
  const [validationcuenta, setValidationcuenta] = useState(false);
  const [exchange_Rate, setexchange_Rate] = useState();
  const [cardSaldo, setCardSaldo] = useState({
    saldoAnterior: 0,
    ingresos: 0,
    gastos: 0,
  });
  // const [numberAccount, setNumberAccount] = useState(null);
  const [dataDEBE, setDataDEBE] = useState([]);
  const [dataHABER, setDataHABER] = useState([]);
  const [datacc, setdatacc] = useState([]);

  const getExchange_Rate = async () => {
    let Date_rate = `${date.substr(6, 4)}-${date.substr(3, 2)}-${date.substr(
      0,
      2
    )}`;
    if (!(Date_rate.includes("_") || Date_rate == "--" || Date_rate == "")) {
      let res = await getDateExchange_rate(Date_rate);
      setexchange_Rate(res);
    }
  };

  // const getCostos = async () => {
  //   let res = await getCentroCostos(sedeactual.get());
  //   setcostos(res.data);
  // };

  const getMayorCajaData = async () => {
    setLoading(true);
    try {
      setDataDEBE([]);
      setDataHABER([]);
      setCardSaldo({ saldoAnterior: 0, ingresos: 0, gastos: 0 });
      let res = await getMajorCaja(periodo);
      let dat = res.data.mayorList;

      // SETEAR DATA PARA LAS TABLAS
      let tempDebe = [];
      let tempHaber = [];
      let totalIngresos = 0;
      let totalGastos = 0;
      for (let i = 1; i < dat.length - 3; i++) {
        if (dat[i].debe) {
          tempDebe.push(dat[i]);
          totalIngresos += dat[i].debe;
        } else {
          totalGastos += dat[i].haber;
          tempHaber.push(dat[i]);
        }
      }
      setDataDEBE(tempDebe);
      setDataHABER(tempHaber);

      // SETEAR DATA PARA EL CARD
      let saldoAnterior = dat[0].debe ? dat[0].debe : dat[0].haber * -1;
      setCardSaldo({
        saldoAnterior: saldoAnterior,
        ingresos: totalIngresos,
        gastos: totalGastos,
      });
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No existe la cuenta Efectivo en soles.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMayorCajaData();
  }, [periodo]);

  const obtainDocTypes = async () => {
    try {
      let result = await getTipoDocs();
      setDataTipos(result);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se pudo obtener los tipos de documentos.",
      });
    }
  };

  const obtainCoinType = async () => {
    try {
      let result = await getTypeCoins();
      setTipodeCambio(result);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se pudo obtener los tipos de monedas.",
      });
    }
  };

  const getDataAccountsPlan = async () => {
    try {
      let result = await getAccountPlan(sedeactual.get(), sedeactual.getAnio());
      let dataAccountPlan = result.filter(
        (element) => element.number.toString().length === sedeactual.digits()
      );
      setDataCuentasDB(dataAccountPlan);
      let found101011 = result.find(
        (e) => e.number === (sedeactual.digits() === 5 ? 10101 : 101011)
      );
      if (found101011) setaccountCaja(found101011._id);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "Error al obtener la información.",
      });
    }
  };

  useEffect(() => {
    getDataAccountsPlan();
    // getCostos();
    obtainDocTypes();
    obtainCoinType();
  }, []);

  const getAuxAccountList = async (cuenta_id) => {
    let res = await getAccountAssistantById(cuenta_id);
    setdatacc(res.data);
  };

  useEffect(() => {
    getAuxAccountList(cuenta);
  }, [cuenta]);

  useEffect(() => {
    getExchange_Rate();
  }, [actualizarcambio]);

  const clearInputs = () => {
    setCliente("");
  };

  function updateruc(e) {
    if (flag) {
      setRuc("");
      setCliente("");
      setVerification(false);
    }

    if (e.target.value.length < 12 && e.target.value >= 0) {
      setVisibleB(false);
      setVerification(true);
      setRuc(e.target.value);
    }
    if (e.target.value.length > 11) {
      setVisibleB(false);
      setVerification(true);
    }

    if (e.target.value.length !== 11) {
      setClienteId(null);
      setCliente("");
    }
  }

  function ChangePeriodo(event) {
    let valued = "";
    let value = "";
    if (event > 9) {
      value = `${event}-${yeartrabajo}`;
    } else {
      value = `0${event}-${yeartrabajo}`;
    }

    setPeriodo(event);

    if (value.slice(-1) != "_" && value.length == 7) {
      if (Number(value.substring(0, 2)) < 10) {
        valued = value.substring(1, 2);
      } else {
        valued = value.substring(0, 2);
      }

      // setperiodoMayor(`${value.substring(3, 7)}/${valued}`);
      // setactualizarMayor({ ...true });
    }
  }

  function updateCorrelativo(e) {
    if (e.target.value.length < 16 && e.target.value >= 0) {
      setCorrelativo(e.target.value);
    }
  }

  function updateMonto(e) {
    if (Number(e.target.value) <= 9999999) {
      setMonto(e.target.value);
    }
    setvalidationMonto(false);
  }

  async function cancelEnter(evt, type) {
    if (evt.key === "Enter") {
      evt.preventDefault();
      if (ruc.length >= 7) {
        //Buscar
        try {
          setVerification(true);
          const res = await postRuc(ruc, type);
          if (Object.keys(res).length === 0) {
            setVisibleB(true);
            setVerification(false);
          } else {
            setCliente(res.nombre_o_razon_social);
            setClienteId(res._id);
            setVerification(true);
            setFlag(true);
          }
        } catch (error) {
          notification["info"]({
            message: `${type === "ruc" ? "RUC" : "DNI"} NO ENCONTRADO`,
            description: `El ${
              type === "ruc" ? "RUC" : "DNI"
            } no se encuentró.`,
          });
        }
      } else {
        clearInputs();
      }
    }
  }

  function handleChange(date) {
    setdateMui(date);
    if (date != "Invalid Date" && date != null) {
      var date = moment(date).format("DD-MM-YYYY");
      setDate(date);
      // setvalidationDate(false);
      setactualizarcambio({ ...true });
    }
  }

  async function sendCliente() {
    if (cliente !== "") {
      let newcliente = {
        ruc: ruc,
        nombre_o_razon_social: cliente,
      };

      setVerification(true);

      setVisibleB(false);

      const res = await registrarCliente(newcliente);

      if (res === true) {
        notification.open({
          message: "Crear Cliente",
          description: "El Cliente fue creado satisfactoriamente.",
          icon: <Icon type="check" style={{ color: "#49E02D" }} />,
          key: `open${Date.now()}`,
        });
      } else {
        notification.open({
          message: "Crear Cliente",
          description: "Ocurrio un error al crear el Cliente.",
          icon: <Icon type="close" style={{ color: "#FE2E27" }} />,
          key: `open${Date.now()}`,
        });
      }
    }
  }

  function optionsTipo() {
    return TipodeCambio.map((cliente, index) => {
      const { id, num, alp, currency } = cliente;
      return (
        <Option key={id} value={id}>
          {alp}-{currency}
        </Option>
      );
    });
  }

  function seleccionarTipo(code) {
    setType(code);
    setvalidationtype(false);
    setModalTipo(false);
  }

  function seleccionarCC(code) {
    setCC(code);
    setModalCC(false);
  }

  useEffect(() => {
    if (clienteId !== null && numDoc !== "" && correlativo !== "") {
      validarDoc();
    }
  }, [clienteId, numDoc, correlativo]);

  async function validarDoc() {
    let res = await validateVoucher(clienteId, numDoc, correlativo, type);
    if (res.status == true) {
      setmodalvalidation(true);
    }
  }
  /* async function validarDoc() {
    let res = await gethttps(
      `voucher/validar/${clienteId}/${numDoc}/${correlativo}`
    );

    if (res != "true") {
      setmodalvalidation(true);
    }
  } */

  function renderTableTipo() {
    return dataTipos.map((Tipos, index) => {
      let { id, code, description } = Tipos;

      return (
        <tr key={id}>
          <td>
            <button
              onClick={() => seleccionarTipo(id)}
              className="ml-3 btn btn-success"
            >
              {code}
            </button>
          </td>
          <td>{description}</td>
        </tr>
      );
    });
  }

  function renderTableCC() {
    return datacc.map((Tipos, index) => {
      let { id, description } = Tipos;

      return (
        <tr key={id}>
          <td>
            <button
              onClick={() => seleccionarCC(id)}
              className="ml-3 btn btn-success"
            >
              {id}
            </button>
          </td>
          <td>{description}</td>
        </tr>
      );
    });
  }

  useEffect(() => {
    if (moment(dateMui).format() > moment(Date.now()).format()) {
      updateMoneda(1);
    }
  }, [dateMui]);

  function updateMoneda(e) {
    if (e == 2) {
      setVisibleCambio(true);
      let found = dataCuentas.find((e) => e._id == cuenta);
      if (found.number.toString().startsWith("7")) {
        setCambiodelDia(exchange_Rate.precio_compra);
      } else {
        setCambiodelDia(exchange_Rate.precio_venta);
      }
    } else {
      setVisibleCambio(false);
      setCambiodelDia(1);
    }
    setMoneda(e);
  }

  const Dataform = () => {
    if (
      data.length > 0 &&
      sumaTotal == 0 &&
      !date.toString().includes("_") &&
      date !== "" &&
      ((numDoc !== "" && correlativo !== "") || type == 1 || type == 3)
    ) {
      let mesp = periodo;
      let anop = sedeactual.getAnio();

      let dia = date.substr(0, 2);
      let mes = date.substr(3, 2);
      let año = date.substr(6, 4);

      let voucher = {
        headquarters_id: sedeactual.get(),
        voucher_id: Number(props.id),
        origin: 1,
        operation_date: `${año}-${mes}-${dia}`,
        t_doc: type,
        serie: numDoc,
        period: Number(mesp),
        year: Number(anop),
        correlative: correlativo,
        customer: {
          ruc: ruc ? ruc : "",
          nombre_o_razon_social: cliente ? cliente : "",
          clienteId: clienteId ? clienteId : "",
        },
        details: data,
      };
      /* 
      if (
        voucher.fecCancelacion.includes("_") ||
        voucher.fecCancelacion == "--"
      ) {
        voucher.fecCancelacion = null;
      }
 */
      props.sendFormData(voucher);
      if (props.edit == false) {
        limpiarvoucher();
      }
      getMayorCajaData();
    } else {
      notification.open({
        message: "Error al crear Voucher",
        description: "La suma total no es 0 o no hay registros de cuenta",
        icon: <Icon type="close" style={{ color: "#FE2E27" }} />,
        key: `open${Date.now()}`,
      });
    }
  };

  function limpiarvoucher() {
    setData([]);
    setDate("");
    setdateMui(null);
    setRuc("");
    setCliente("");
    setClienteId(null);
    setType(null);
    // setOrigen(0);
    setnumDoc("");
    setGlosa("");

    setCorrelativo("");
    setTipoDocM("");

    if (props.edit == false) {
      getLastNumberVoucher();
    }
  }

  useEffect(() => {
    changeTipoCaja(tipo);
  }, [dataCuentasDB]);

  const changeTipoCaja = (value) => {
    setDataCuentas([]);
    setTipo(value);
    if (value == "DEBE") {
      // setTipocuenta("ingreso");
      let filter = dataCuentasDB.filter(
        (el) =>
          el.number.toString().startsWith("70") ||
          el.number.toString().startsWith("71") ||
          el.number.toString().startsWith("72") ||
          el.number.toString().startsWith("73") ||
          el.number.toString().startsWith("74") ||
          el.number.toString().startsWith("75") ||
          el.number.toString().startsWith("76") ||
          el.number.toString().startsWith("77") ||
          el.number.toString().startsWith("78")
      );
      setDataCuentas(filter);
    }
    if (value == "HABER") {
      // setTipocuenta("gasto");
      let filter = dataCuentasDB.filter(
        (el) =>
          el.number.toString().startsWith("6") ||
          el.number.toString().startsWith("33")
      );
      setDataCuentas(filter);
    }
    // setUpdateCuentas({ ...true });
    setCuenta(null);
  };

  // const getNumberAccount = idAccount => {
  //   setNumberAccount(null);
  //   let filterNumber = dataCuentasDB.find(element => element._id == idAccount);
  //   if(filterNumber){
  //     let result = oldDataCuentas.find(element => element.number == filterNumber.number);
  //     setNumberAccount(result.id)
  //   }else{
  //     notification.open({
  //       message: "Alerta",
  //       description: "La cuenta no existe.",
  //       icon: <Icon type="close" style={{ color: "#FE2E27" }} />,
  //       key: `open${Date.now()}`,
  //     });
  //   }
  // }

  const getLastNumberVoucher = async () => {
    let res = await lastNumberVoucher(periodo.toString().substr(0, 2));
    setnumCom(res.data);
  };
  useEffect(() => {
    if (props.edit == false) {
      getLastNumberVoucher();
    }
  }, [periodo, props.forced]);

  function handleOk(e, f) {
    let tiporapido = 0;
    let mes = periodo.toString().length == 1 ? `0${periodo}` : periodo;
    let año = sedeactual.getAnio();

    let cuentaNueva = {
      account: cuenta,
      comment: glosa,
      cost: e.cost,
      aux_account: cc,
      type_currency: Number(moneda),
      exchange_rate: Number(cambiodeldia),
      ref_payment: tipoDocM,
      cost: costo,
      period: Number(mes),
      periodo: `${año}-${mes}-01`,
      type_detail: tipo == "DEBE" ? "HABER" : "DEBE",
      amount: (Math.round(monto * 100) / 100).toFixed(2),
    };

    let cuentaRapida = {
      account: accountCaja,
      comment: glosa,
      cost: e.cost,
      aux_account: null,
      type_currency: Number(moneda),
      exchange_rate: Number(cambiodeldia),
      ref_payment: tipoDocM,
      period: Number(mes),
      periodo: `${año}-${mes}-01`,
      type_detail: tipo,
      amount: (Math.round(monto * 100) / 100).toFixed(2),
    };
    setValidationcuenta(false);
    if (cuentaNueva.account == null) {
      setValidationcuenta(true);
    }
    if (dateMui == null) {
      // setvalidationDate(true);
    }
    if (type == null) {
      setvalidationtype(true);
    }
    if (monto == "") {
      setvalidationMonto(true);
    }

    if (
      cuentaNueva.amount >= 0 &&
      cuentaNueva.account != null &&
      cuentaNueva.account != "" &&
      !cuentaRapida.periodo.includes("_") &&
      dateMui != null &&
      monto != "" &&
      type != null
    ) {
      data.push(cuentaNueva);
      data.push(cuentaRapida);
      Dataform();
      // setactualizarMayor({ ...true });
      setCuenta(null);
      setGlosa("");
      setCC(null);
      setMonto("");
      setTipoDocM("");

      f.target.reset();
    } else {
      notification.open({
        message: "Validación",
        description: "Hay campos que requiren ser completados.",
        icon: <Icon type="close" style={{ color: "#FE2E27" }} />,
        key: `open${Date.now()}`,
      });
    }
  }

  const changeCuenta = (value) => {
    setCuenta(value);
    // getNumberAccount(value);
    let finderAccount = dataCuentas.find((ev) => ev._id === value);
    if (finderAccount && finderAccount.cost) {
      if (finderAccount.cost.length == 1) {
        setcosto("0" + finderAccount.cost);
      } else if (finderAccount.cost == "") {
        setcosto(null);
      } else {
        setcosto(finderAccount.cost);
      }
    }
    setCC(null);
    setValidationcuenta(false);
  };

  return (
    <Page className={classes.root} helper="VOUCHER" title="CAJA RÁPIDA">
      {modalvalidation && <ModalValidation />}
      <Grid container spacing={2}>
        <Grid item xs={12} container justify="space-between">
          <Link to={{ pathname: "/vouchers" }}>
            <Button variant="outlined" color="primary">
              <ArrowBackIosIcon /> Volver
            </Button>
          </Link>
          <Card style={{ maxWidth: 250 }}>
            <CardContent>
              <TextField
                variant="outlined"
                fullWidth
                label="N° Voucher"
                value={numCom}
                type="number"
                disabled
                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(handleOk)}
                style={{ width: "100%" }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} lg={4}>
                    <FormHelperText>TIPO DE CAJA</FormHelperText>
                    <Select
                      value={tipo}
                      onChange={(e) => changeTipoCaja(e)}
                      size="large"
                    >
                      <Option value="DEBE">INGRESOS</Option>
                      <Option value="HABER">GASTOS</Option>
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <FormHelperText>PERIODO</FormHelperText>
                    <SelectPeriodo
                      defaultValue={periodo}
                      onChangeSelect={(e) => ChangePeriodo(e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <FormHelperText>
                      {moment(
                        `${new Date().getFullYear()}-${periodo}-${new Date().getDate()}`
                      )
                        .utc(false)
                        .format("YYYY-MM-DD") !==
                      moment(dateMui).utc(false).format("YYYY-MM-DD") ? (
                        <span style={{ color: "red" }}>
                          LA FECHA ES DIFERENTE AL PERIODO. Puede continuar...
                        </span>
                      ) : (
                        "FECHA DE OPERACIÓN"
                      )}
                    </FormHelperText>
                    <MuiPickersUtilsProvider
                      locale={deLocale}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        inputVariant="outlined"
                        style={{ margin: 0 }}
                        size="small"
                        format="dd/MM/yyyy"
                        margin="normal"
                        onChange={handleChange}
                        value={dateMui}
                        fullWidth
                        placeholder="DD/MM/YYYY"
                        maxDate={`${yeartrabajo + 1}-01-01`}
                        maxDateMessage={`El rango máximo de fecha es hasta el 31 diciembre del ${yeartrabajo}`}
                        KeyboardButtonProps={{ "aria-label": "change date" }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <FormHelperText error={validationtype}>
                      {validationtype == true
                        ? "TIPO DOCUMENTO INVÁLIDO"
                        : "TIPO DOCUMENTO"}
                    </FormHelperText>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "calc((100%) - 40px)" }}>
                        <SelectDoc
                          defaultValue={type}
                          onChangeSelect={(e) => {
                            setType(e);
                            setvalidationtype(false);
                            setCliente("");
                            setRuc("");
                          }}
                          data={dataTipos}
                        />
                      </div>
                      <div style={{ width: 40, height: 40 }}>
                        <IconButton
                          onClick={() => {
                            setModalTipo(true);
                          }}
                          color="primary"
                        >
                          <SearchIcon />
                        </IconButton>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <FormHelperText />
                    <TextField
                      label="Serie"
                      fullWidth
                      variant="outlined"
                      value={numDoc}
                      onChange={(e) => {
                        setnumDoc(e.target.value.toString().toUpperCase());
                      }}
                      size="small"
                      inputProps={{ maxLength: 4 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <FormHelperText />
                    <TextField
                      label="N° Comprobante"
                      fullWidth
                      variant="outlined"
                      value={correlativo}
                      onChange={updateCorrelativo}
                      type="number"
                      size="small"
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                    />
                  </Grid>
                  {type === 1 && (
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormHelperText />
                          <TextField
                            label="DNI"
                            variant="outlined"
                            fullWidth
                            size="small"
                            onKeyPress={(evt) => cancelEnter(evt, "dni")}
                            onKeyDown={(evt) =>
                              evt.key === "e" && evt.preventDefault()
                            }
                            value={ruc}
                            onChange={updateruc}
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={8}>
                          <FormHelperText />
                          <TextField
                            label="CLIENTE"
                            variant="outlined"
                            fullWidth
                            size="small"
                            disabled
                            value={cliente}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {type == 1 ? null : (
                    <Grid item xs={12} md={4} lg={3}>
                      <FormHelperText>
                        DIGITE EL RUC Y PRESIONE ENTER
                      </FormHelperText>
                      <TextField
                        label="RUC"
                        fullWidth
                        variant="outlined"
                        onKeyPress={(evt) => cancelEnter(evt, "ruc")}
                        onKeyDown={(evt) =>
                          evt.key === "e" && evt.preventDefault()
                        }
                        type="number"
                        value={ruc}
                        onChange={updateruc}
                        size="small"
                      />
                    </Grid>
                  )}
                  {type == 1 ? null : (
                    <Grid item xs={12} md={8} lg={9}>
                      <FormHelperText />
                      {verification == true ? (
                        <TextField
                          label="CLIENTE Ó PROVEEDOR"
                          fullWidth
                          size="small"
                          variant="outlined"
                          value={cliente}
                          disabled
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {visible == false ? (
                                  <div />
                                ) : (
                                  <IconButton
                                    style={{ height: 40, width: 40 }}
                                    color="primary"
                                    onClick={sendCliente}
                                  >
                                    +
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      ) : (
                        <TextField
                          label="CLIENTE Ó PROVEEDOR"
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={cliente}
                          onChange={(e) => {
                            setCliente(e.target.value);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {visible == false ? (
                                  <div />
                                ) : (
                                  <IconButton
                                    style={{ height: 40, width: 40 }}
                                    color="primary"
                                    onClick={sendCliente}
                                  >
                                    +
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormHelperText error={validationcuenta}>
                      {validationcuenta ? "CUENTA INVÁLIDA" : "CUENTA"}
                    </FormHelperText>
                    <SelectCuenta
                      caja={props.caja}
                      defaultValue={cuenta}
                      onChangeSelect={(e) => changeCuenta(e)}
                      data={dataCuentas}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <FormHelperText>AUXILIAR DE CUENTAS</FormHelperText>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "calc((100%) - 40px)" }}>
                        <SelectCC
                          defaultValue={cc}
                          onChangeSelect={(e) => {
                            setCC(e);
                          }}
                          data={datacc}
                        />
                      </div>
                      <div style={{ width: 40, height: 40 }}>
                        <IconButton
                          onClick={() => {
                            setModalCC(true);
                          }}
                          color="primary"
                        >
                          <SearchIcon />
                        </IconButton>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <FormHelperText>FORMULA DE COSTOS</FormHelperText>
                    {/* <SelectCentroCostos
                      defaultValue={costo}
                      onChangeSelect={(e) => {
                        setcosto(e);
                      }}
                      data={costos}
                    /> */}
                    <SelectCostFormula
                      defaultValue={costo}
                      onChangeSelect={(e) => {
                        setcosto(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <FormHelperText />
                    <TextField
                      label="REF. M. PAGO"
                      onKeyDown={(evt) =>
                        evt.key === "Enter" && evt.preventDefault()
                      }
                      value={tipoDocM}
                      onChange={(e) => {
                        setTipoDocM(e.target.value);
                      }}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormHelperText />
                    <TextField
                      label={errors.glosa ? errors.glosa.message : "GLOSA"}
                      onKeyDown={(evt) =>
                        evt.key === "Enter" && evt.preventDefault()
                      }
                      defaultValue={glosa}
                      variant="outlined"
                      fullWidth
                      error={errors.glosa}
                      size="small"
                      onChange={(e) => {
                        setGlosa(e.target.value);
                      }}
                      name="glosa"
                      inputProps={{ maxLength: 255 }}
                      inputRef={register({
                        required: "LA GLOSA ES REQUERIDA",
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <FormHelperText>MONEDA</FormHelperText>
                    <Select
                      value={moneda}
                      onChange={updateMoneda}
                      size="large"
                      disabled={
                        cuenta &&
                        dateMui &&
                        moment(dateMui).format() < moment(Date.now()).format()
                          ? false
                          : true
                      }
                    >
                      {optionsTipo()}
                    </Select>
                  </Grid>
                  {visibleCambio && (
                    <Grid item xs={12} sm={6} lg={4}>
                      <FormHelperText />
                      <TextField
                        label="Tipo de cambio"
                        variant="outlined"
                        fullWidth
                        size="small"
                        disabled
                        value={cambiodeldia}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} lg={4}>
                    <FormHelperText error>
                      {validationMonto && "EL MONTO ES REQUERIDO"}
                    </FormHelperText>
                    <TextField
                      label="Monto"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={monto}
                      onChange={updateMonto}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={sedeactual.getRol() === "usuario"}
                    >
                      AGREGAR
                    </Button>
                    <CardSaldo data={cardSaldo} />
                  </Grid>
                </Grid>
              </form>
              <hr />
              <Grid container spacing={1}>
                {loading ? (
                  <Grid item xs={12}>
                    <Spinner />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12} lg={6}>
                      {/* {dataDEBE.length > 0 && ( */}
                      <IngresosEgresos
                        data={dataDEBE}
                        title="INGRESOS"
                        saldos={cardSaldo}
                      />
                      {/* )} */}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      {/* {dataHABER.length > 0 && ( */}
                      <IngresosEgresos
                        data={dataHABER}
                        title="GASTOS"
                        saldos={cardSaldo}
                      />
                      {/* )} */}
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Modal
        title="Tipo de Documento"
        onCancel={() => {
          setModalTipo(false);
        }}
        className="modalTipo"
        style={{ minWidth: "40%", maxWidth: "1000px" }}
        visible={modalTipo}
        footer={[]}
      >
        <div style={{ height: "400px", overflowY: "scroll" }}>
          <table className="table table-bordered" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th style={{ width: "1%" }}>Código</th>
                <th style={{ width: "5%" }}>Descripción</th>
              </tr>
            </thead>
            <tbody>{renderTableTipo()}</tbody>
          </table>
        </div>
      </Modal>

      <Modal
        title="Auxiliar de Cuentas"
        onCancel={() => {
          setModalCC(false);
        }}
        className="modalTipo"
        style={{ minWidth: "40%", maxWidth: "1000px" }}
        visible={modalCC}
        footer={[]}
      >
        <div style={{ height: "400px", overflowY: "scroll" }}>
          <table className="table table-bordered" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th style={{ width: "2%" }}>Cuenta</th>

                <th style={{ width: "5%" }}>Descripción</th>
              </tr>
            </thead>
            <tbody>{renderTableCC()}</tbody>
          </table>
        </div>
      </Modal>
    </Page>
  );
};

export default Caja;
