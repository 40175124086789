import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { getInventorySubCategory } from "../../requests";

import ExportarExcel from "src/components/Global/ExportarExcel";
import sedeactual from "src/config/sedeactual";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Page from "src/components/Page";

const SubCategoryReport = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    obtainData();
  }, []);

  const obtainData = async () => {
    setIsLoading(true);

    try {
      const res = await getInventorySubCategory();
      setData(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Page
      title="Reporte por SubCategoria"
      helper="Inventario"
      itemComponent={
        <ExportarExcel
          name={`REPORTE INVENTARIO DETALLADO`}
          endpoint={`report/export/inventario/detallado/${sedeactual.sede_id()}`}
          method="GET"
          disabled={isLoading || !data}
          api={2}
        />
      } >
      <Spin spinning={isLoading}>
        <br />
        {data.length > 0 && !isLoading && (
          <TableContainer style={{ maxHeight: "80vh" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {data[0].map((item, index) => (
                    <TableCell key={index}>{item}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.slice(1).map((item, index) => {
                  return (
                    <TableRow key={index} style={{ background: item[0] !== "" && "#f5f5f5" }}>
                      {item.map((item, index) => (
                        <TableCell key={index}>{item}</TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Spin>
    </Page>
  );
};

export default SubCategoryReport;
