import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { notification } from "antd";
import axios from "axios";
import { apiContable } from "../../../../config/api.contable";
import sedeactual from "../../../../config/sedeactual";
import AddBoxIcon from "@material-ui/icons/AddBox";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { getCentroCostos } from "../../../../api/api.contable";

const AddCostFormula = (props) => {
  const { visible, setVisible, getData, data, setLoading } = props;
  const [description, setDescription] = useState("");
  const [id, setId] = useState("");
  const [list, setList] = useState([{ cost: "", value: 100 }]);
  const [costos, setCostos] = useState([]);

  const getCostos = async () => {
    try {
      const result = await getCentroCostos(sedeactual.get());
      setCostos(result.data);
    } catch (err) {
      notification["error"]({
        message: `${err}`,
        description: "No se logró obtener los costos",
      });
    }
  };

  useEffect(() => {
    getCostos();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      let value = parseInt(data[data.length - 1].id) + 1;
      if (value.toString().length > 1) {
        setId(value.toString());
      } else {
        setId("0" + value.toString());
      }
    } else {
      setId("01");
    }
  }, [data]);

  const handleSumit = async () => {
    setLoading(true);
    try {
      let count = 0;
      let sum = 0;
      for (let i = 0; i < list.length; i++) {
        sum += parseInt(list[i].value);
        if (list[i].cost || list[i].value) {
          count++;
        }
      }

      if (count === 0 && sum === 100 && description !== "") {
        let costsFormula = { description: description, detail: list, id: id };
        await axios.post(
          `${apiContable}/cost-formula/${
            sedeactual.company().url
          }/${sedeactual.getAnio()}/${sedeactual.get()}`,
          costsFormula
        );
        notification["success"]({
          message: `ÉXITO!`,
          description: "La formula se agregó correctamente.",
        });
        getData();
        setVisible(false);
      } else {
        notification["info"]({
          message: "No pueden quedar campos vacios.",
          description: "LA SUMA DE LOS VALORES DEBE SER IGUAL AL 100%",
        });
      }
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se logró guardar la formula.",
      });
    } finally {
      setLoading(false);
    }
  };

  function addInput() {
    list.push({ cost: "", value: 100 });
    setList([...list]);
  }

  function deleteInput() {
    list.pop();
    setList([...list]);
  }

  return (
    <Dialog open={visible} fullWidth>
      <DialogContent align="center">
        <DialogTitle>AGREGAR NUEVA FORMULA DE COSTO</DialogTitle>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              label="Nro"
              fullWidth
              size="small"
              disabled
              variant="outlined"
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              label="Descripción"
              fullWidth
              size="small"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
          {list.map((n, i) => (
            <>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small" variant="outlined">
                  <InputLabel>Costos</InputLabel>
                  <Select
                    label="Costos"
                    value={n.cost}
                    onChange={(e) => {
                      list[i].cost = e.target.value;
                      setList([...list]);
                    }}
                  >
                    {costos.length > 0 &&
                      costos.map((ev) => (
                        <MenuItem value={ev.code}>{ev.description}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Valor"
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={n.value}
                  onChange={(e) => {
                    list[i].value = e.target.value;
                    setList([...list]);
                  }}
                />
              </Grid>
            </>
          ))}
          <Grid item xs={6} align="right">
            <Button
              variant="contained"
              color="primary"
              onClick={() => addInput()}
            >
              <AddBoxIcon />
            </Button>
          </Grid>
          <Grid item xs={6} align="left">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => deleteInput()}
            >
              <BackspaceIcon />
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setVisible(false)}
        >
          cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handleSumit}>
          guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCostFormula;
