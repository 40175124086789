import React, { useState, useEffect, useRef } from "react";
import {
  Paper,
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import { Empty, notification } from "antd";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import PrintIcon from "@material-ui/icons/Print";
import sedeactual from "src/config/sedeactual";
import { makeStyles } from "@material-ui/styles";
import { apiReportes } from "src/config/api.contable";
import ReactToPrint from "react-to-print";
import axios from "axios";
import { Link } from "react-router-dom";
import SelectSedes from "src/components/Usuarios/SelectSedes";
import ExportarExcel from "src/components/Global/ExportarExcel";
import Spinner from "src/components/Global/Spinner";
import { getHeadquarters } from "src/api/api.contable";
import moment from "moment";
import TableEEGGPPN from "./TableEEGGPPN";

const useStyles = makeStyles((theme) => ({
  logo: {
    position: "absolute",
    top: 10,
    left: 25,
  },
  excel: {
    backgroundColor: "green",
    color: "white",
    width: 121,
    margin: 2,
    "&:hover": {
      backgroundColor: "green",
    },
  },
  select: {
    maxWidth: "calc(100% - 275px)",
    minWidth: 300,
    textAlign: "left",
  },
  selectsedes: {
    maxWidth: "calc(100% - 650px)",
    minWidth: 300,
    textAlign: "left",
  },
}));

const year = sedeactual.getAnio();

const ExportarERIN = () => {
  const classes = useStyles();
  const componentRef = useRef();
  const rucHeadquarters = sedeactual.getRuc();
  const [period, setPeriod] = useState();
  const [data, setData] = useState(null);
  const [sedeSelected, setSedeSelected] = useState(sedeactual.get());
  const [titleReport, setTitleReport] = useState({
    title: sedeactual.description(),
  });
  const [loading, setLoading] = useState(false)
  const [sedes, setSedes] = useState([]);
  const [init, setInit] = useState(false)

  const getData = async () => {
    try {
      if (!init) {
        setInit(true)
        return
      }
      setLoading(true)
      setData(null);
      let res = await axios.get(
        `${apiReportes}/eeggpp/detallado/${year}/${period}/${sedeSelected}/${sedeactual.company().url
        }`
      );

      setData({
        egresos: res.data.infoFinalEgresos,
        ingresos: res.data.infoFinalIngresos,
        ejercicio: res.data.infoFinalEjercicio,
      });
      console.log(res.data);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se logró obtener la información del reporte.",
      });
    } finally {
      setLoading(false)
    }
  };

  const getSedes = async () => {
    try {
      let result = await getHeadquarters();
      setSedes(result.data);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se logró obtener las sedes.",
      });
    }
  };

  useEffect(() => {
    getSedes();
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, sedeSelected]);

  return (
    <Paper style={{ padding: 25 }}>
      <div align="right">
        <Link to={{ pathname: "/reportes" }}>
          <Button variant="outlined" color="primary" style={{ float: "left" }}>
            <ArrowBackIosIcon /> VOLVER
          </Button>
        </Link>
        {(sedeactual.getRol() === "supernap" ||
          sedeactual.getRol() === "superadmin") && (
            <FormControl
              variant="outlined"
              size="small"
              className={classes.selectsedes}
            >
              <SelectSedes
                defaultValue={sedeSelected}
                data={sedes}
                setTitle={setTitleReport}
                onChangeSelect={(e) => {
                  setSedeSelected(e);
                }}
              />
            </FormControl>
          )}{" "}
        <FormControl variant="outlined" size="small" className={classes.select}>
          <InputLabel>Seleccione</InputLabel>
          <Select
            label="seleccione"
            value={period}
            onChange={(event) => setPeriod(event.target.value)}
          >
            <MenuItem value={1}>Enero</MenuItem>
            <MenuItem value={2}>Febrero</MenuItem>
            <MenuItem value={3}>Marzo</MenuItem>
            <MenuItem value={4}>Abril</MenuItem>
            <MenuItem value={5}>Mayo</MenuItem>
            <MenuItem value={6}>Junio</MenuItem>
            <MenuItem value={7}>Julio</MenuItem>
            <MenuItem value={8}>Agosto</MenuItem>
            <MenuItem value={9}>Setiembre</MenuItem>
            <MenuItem value={10}>Octubre</MenuItem>
            <MenuItem value={11}>Noviembre</MenuItem>
            <MenuItem value={12}>Diciembre</MenuItem>
          </Select>
        </FormControl>{" "}
        <ExportarExcel
          name={`ESTADO_GANANCIAS_PERDIDAS_POR_NATURALEZA_${Date.now().toString()}`}
          endpoint={`eeggpp/detallado/export-excel/${year}/${period}/${sedeactual.get()}/${sedeactual.company().url
            }`}
          // body={}
          method="GET"
          disabled={false}
          small={false}
          api={4}
        />
        <ReactToPrint
          trigger={() => (
            <Button
              color="primary"
              variant="outlined"
              style={{ marginRight: 10 }}
            >
              <PrintIcon /> IMPRIMIR
            </Button>
          )}
          content={() => componentRef.current}
        />
      </div>
      <Card
        ref={componentRef}
        align="center"
        style={{ position: "relative", minHeight: "100vh" }}
      >
        <CardContent>
          <div>
            <Typography variant="h5" color="textPrimary">
              {titleReport.title && titleReport.title.toUpperCase()}
            </Typography>
            <Typography variant="subtitle1" color="textPrimary">
              N° RUC {rucHeadquarters}
            </Typography>
            <br />
            <Typography variant="h5" color="textPrimary">
              ESTADO DE GANANCIAS Y PÉRDIDAS POR NATURALEZA - DETALLADO
            </Typography>
            {period && (
              <Typography variant="subtitle2" color="textPrimary">
                Reporte mensual de{" "}
                {moment(year + "-" + period + "-01").format("MMMM YYYY")}
              </Typography>
            )}
          </div>
          <br />
          {loading ? (
            <Spinner />
          ) : data ? (
            <TableEEGGPPN data={data} year={year} />
          ) : (
            <Empty />
          )}
        </CardContent>
      </Card>
    </Paper>
  );
};

export default ExportarERIN;
