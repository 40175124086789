import React from "react";
import CustomTable from "src/components/CustomTable";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { Delete, Edit, GetApp } from "@material-ui/icons";

const TableIncidents = ({ data, loading, setModal, setDel }) => {
  const columns = [
    {
      title: "TIPO DE INCIDENCIA",
      dataIndex: "incidence_type",
      key: "incidence_type",
      align: "center",
      sorter: false,
      filter: false,
      render: (val, row) => (
        <Typography style={{ color: row.color }} variant="inherit">
          <b>{val}</b>
        </Typography>
      ),
    },
    {
      title: "TRABAJADOR",
      dataIndex: "fullname",
      key: "fullname",
      align: "center",
      sorter: true,
      filter: true,
    },
    {
      title: "DETALLE",
      dataIndex: "detail",
      key: "detail",
      align: "center",
      sorter: false,
      filter: false,
      render: (val) => (
        <Tooltip title={val} arrow placement="top">
          <Typography variant="inherit" color="inherit">
            {val.substring(0, 50)}...
          </Typography>
        </Tooltip>
      ),
    },
    {
      title: "FECHA DE REGISTRO",
      dataIndex: "date_upload",
      key: "date_upload",
      align: "center",
      sorter: true,
      filter: true,
    },
    {
      title: "EVIDENCIA",
      dataIndex: "file",
      key: "file",
      align: "center",
      sorter: false,
      filter: false,
      render: (value) =>
        value ? (
          <IconButton color="primary" component="a" href={value} target="_blank">
            <GetApp />
          </IconButton>
        ) : (
          <span />
        ),
    },
    {
      title: "ACCIONES",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      sorter: false,
      filter: false,
      render: (value, row) => (
        <React.Fragment>
          <IconButton color="primary" onClick={() => setModal({ open: true, data: row })}>
            <Edit />
          </IconButton>
          <IconButton color="secondary" onClick={() => setDel({ open: true, id: value })}>
            <Delete />
          </IconButton>
        </React.Fragment>
      ),
    },
  ];

  return <CustomTable columns={columns} data={data} loading={loading} pagination={false} />;
};

export default TableIncidents;
