import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Navigation from "./Navigation";
import InputIcon from "@material-ui/icons/Input";
import sedeactual from "../../../../config/sedeactual";
import AuthenticationService from "../../../../auth/AuthenticationService";
import { SESSION_NAME, SESSION_NAV } from "../../../../config/session";
import SessionStorageService from "../../../../auth/SessionStorageService";
import {
  Button,
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Typography,
  makeStyles,
  Grid,
  CardActionArea,
  FormHelperText,
  Tooltip,
} from "@material-ui/core";
import listnav from "./listnav";

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  navbar: {
    zIndex: 3,
    width: 257,
    minWidth: 257,
    [theme.breakpoints.down("md")]: {
      width: 0,
      minWidth: 0,
    },
  },
  module: {
    border: "1px solid #0288d1",
    color: "#0288d1",
    writingMode: "vertical-lr",
    transform: "rotate(180deg)",
    textAlign: "center",
    alignItems: "center",
    width: "100%",
  },
  moduleActive: {
    backgroundColor: "#0288d1",
    color: "#fff",
    writingMode: "vertical-lr",
    transform: "rotate(180deg)",
    textAlign: "center",
    alignItems: "center",
    width: "100%",
  },
}));

let userProfile = SessionStorageService.get(SESSION_NAME)
  ? SessionStorageService.get(SESSION_NAME).value.profile_picture
  : null;

const NavBar = ({ onMobileClose, openMobile, modules = [] }) => {
  const classes = useStyles();
  const location = useLocation();
  const [navModule, setNavModule] = useState(
    SessionStorageService.get(SESSION_NAV)
  );

  const handleLogout = () => {
    let url = sedeactual.company().url;
    AuthenticationService.logout().then(() => {
      window.location.href = `/${url}/login`;
    });
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const user = {
    first_name: sedeactual.getFullName(),
    username: sedeactual.getUsername(),
    avatar: userProfile ? userProfile : sedeactual.company().logo,
    rol: sedeactual.getRol(),
  };

  const setModule = (items) => {
    SessionStorageService.set(SESSION_NAV, items);
    setNavModule(items);
  };

  let allModules = listnav.map((el) => el.title);
  let newModules = sedeactual.getRol() === "supernap" ? allModules : modules;
  let filterActive = listnav.find((el) => el.title === navModule);

  const content = (
    <React.Fragment>
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          alt="Person"
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/perfil"
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {user.first_name.toUpperCase()}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.username}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.rol === "accountant" ? "CONTADOR" : user.rol.toUpperCase()}
        </Typography>
      </Box>
      <Divider style={{ borderBottom: "1px solid #0288d1" }} />
      <Box p={0} style={{ height: "100%" }}>
        <Grid container style={{ height: "100%" }}>
          <Grid item xs={2}>
            {newModules.map((item) => (
              <Tooltip
                title={
                  <Typography variant="h5" color="inherit" component="span">
                    {item}
                  </Typography>
                }
                placement="left"
                arrow
                key={item}
              >
                <CardActionArea onClick={() => setModule(item)}>
                  <Box
                    align="center"
                    pt={1}
                    pb={1}
                    pr={0}
                    pl={1}
                    component={Typography}
                    noWrap
                    className={
                      item === filterActive?.title ?? ""
                        ? classes.moduleActive
                        : classes.module
                    }
                    style={{
                      height: `calc(((100% - 218px)/${newModules.length}))`,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>{item}</b>
                  </Box>
                </CardActionArea>
              </Tooltip>
            ))}
          </Grid>
          <Grid item xs={10}>
            {Boolean(navModule) ? (
              <Navigation
                pages={filterActive?.pages ?? []}
                title={filterActive?.title ?? ""}
              />
            ) : (
              <Grid
                container
                height="100%"
                justify="center"
                alignItems="center"
                style={{ height: "100%" }}
              >
                <Grid item align="center">
                  <Typography variant="body1" gutterBottom>
                    <b>NAPCONTABLE</b>
                  </Typography>
                  <FormHelperText style={{ textAlign: "center" }}>
                    Bienvenido {user.first_name.toUpperCase()}
                  </FormHelperText>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
      <Hidden lgUp>
        <Box p={1}>
          <Divider />
          <Button color="inherit" fullWidth size="large" onClick={handleLogout}>
            <InputIcon style={{ paddingRight: 10 }} />
            <Typography color="textSecondary" variant="h6">
              Cerrar Sesión
            </Typography>
          </Button>
        </Box>
      </Hidden>
    </React.Fragment>
  );

  return (
    <div className={classes.navbar}>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </div>
  );
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
