import React from "react"
import napformat from "../../../config/napformat"

const getColumns = ( number ) => {
  switch ( number ) {
    case 1:
      return [
        {
          title: "Nº CUENTA",
          dataIndex: "number",
          key: "number",
          align: "center",
          width: 100,
          render: ( text, record, index ) =>
            record.type == "clase" ? (
              <p>
                <strong>{ text }</strong>
              </p>
            ) : (
              <p>{ text }</p>
            ),
        },
        {
          title: "DESCRIPCIÓN",
          dataIndex: "description",
          key: "description",
          width: 200,
          render: ( text, record, index ) =>
            record.type == "clase" ? (
              <p>
                <strong>{ text }</strong>
              </p>
            ) : (
              <p>{ text }</p>
            ),
        },
        {
          title: "ENERO",
          dataIndex: "enero",
          key: "enero",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "FEBRERO",
          dataIndex: "febrero",
          key: "febrero",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "MARZO",
          dataIndex: "marzo",
          key: "marzo",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "GASTADO AL 1ER TRIMESTRE",
          dataIndex: "primer",
          key: "primer",
          align: "right",
          width: 215,
          render: ( text ) => <strong>{ text != null ? napformat( text ) : "" }</strong>,
        },
      ]

    case 2:
      return [
        {
          title: "Nº CUENTA",
          dataIndex: "number",
          key: "number",
          align: "center",
          width: 100,
          render: ( text, record, index ) =>
            record.type == "clase" ? (
              <p>
                <strong>{ text }</strong>
              </p>
            ) : (
              <p>{ text }</p>
            ),
        },
        {
          title: "DESCRIPCIÓN",
          dataIndex: "description",
          key: "description",
          width: 200,
          render: ( text, record, index ) =>
            record.type == "clase" ? (
              <p>
                <strong>{ text }</strong>
              </p>
            ) : (
              <p>{ text }</p>
            ),
        },
        {
          title: "GASTADO AL 1ER TRIMESTRE",
          dataIndex: "primer",
          key: "primer",
          align: "right",
          width: 215,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "ABRIL",
          dataIndex: "abril",
          key: "abril",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "MAYO",
          dataIndex: "mayo",
          key: "mayo",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "JUNIO",
          dataIndex: "junio",
          key: "junio",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "GASTADO AL 2DO TRIMESTRE",
          dataIndex: "segundo",
          key: "segundo",
          align: "right",
          width: 215,
          render: ( text ) => <strong>{ text != null ? napformat( text ) : "" }</strong>,
        },
      ]

    case 3:
      return [
        {
          title: "Nº CUENTA",
          dataIndex: "number",
          key: "number",
          align: "center",
          width: 100,
          render: ( text, record, index ) =>
            record.type == "clase" ? (
              <p>
                <strong>{ text }</strong>
              </p>
            ) : (
              <p>{ text }</p>
            ),
        },
        {
          title: "DESCRIPCIÓN",
          dataIndex: "description",
          key: "description",
          width: 200,
          render: ( text, record, index ) =>
            record.type == "clase" ? (
              <p>
                <strong>{ text }</strong>
              </p>
            ) : (
              <p>{ text }</p>
            ),
        },
        {
          title: "GASTADO AL 2DO TRIMESTRE",
          dataIndex: "segundo",
          key: "segundo",
          align: "right",
          width: 215,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "JULIO",
          dataIndex: "julio",
          key: "julio",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "AGOSTO",
          dataIndex: "agosto",
          key: "agosto",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "SETIEMBRE",
          dataIndex: "setiembre",
          key: "setiembre",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "GASTADO AL 3ER TRIMESTRE",
          dataIndex: "tercero",
          key: "tercero",
          align: "right",
          width: 215,
          render: ( text ) => <strong>{ text != null ? napformat( text ) : "" }</strong>,
        },
      ]

    case 4:
      return [
        {
          title: "Nº CUENTA",
          dataIndex: "number",
          key: "number",
          align: "center",
          width: 100,
          render: ( text, record, index ) =>
            record.type == "clase" ? (
              <p>
                <strong>{ text }</strong>
              </p>
            ) : (
              <p>{ text }</p>
            ),
        },
        {
          title: "DESCRIPCIÓN",
          dataIndex: "description",
          key: "description",
          width: 200,
          render: ( text, record, index ) =>
            record.type == "clase" ? (
              <p>
                <strong>{ text }</strong>
              </p>
            ) : (
              <p>{ text }</p>
            ),
        },
        {
          title: "GASTADO AL 3ER TRIMESTRE",
          dataIndex: "tercero",
          key: "tercero",
          align: "right",
          width: 215,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "OCTUBRE",
          dataIndex: "octubre",
          key: "octubre",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "NOVIEMBRE",
          dataIndex: "noviembre",
          key: "noviembre",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "DICIEMBRE",
          dataIndex: "diciembre",
          key: "diciembre",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "GASTADO AL 4TO TRIMESTRE",
          dataIndex: "cuarto",
          key: "cuarto",
          align: "right",
          width: 215,
          render: ( text ) => <strong>{ text != null ? napformat( text ) : "" }</strong>,
        },
      ]

    case 5:
      return [
        {
          title: "Nº CUENTA",
          dataIndex: "number",
          key: "number",
          align: "center",
          width: 100,
          render: ( text, record, index ) =>
            <div>
              { record.type == "clase"
                ? ( <p><strong>{ text }</strong></p> )
                : ( <p>{ text }</p> )
              }
            </div>
        },
        {
          title: "DESCRIPCIÓN",
          dataIndex: "description",
          key: "description",
          width: 200,
          render: ( text, record, index ) =>
            <div>
              { record.type == "clase"
                ? ( <p><strong>{ text }</strong></p> )
                : ( <p>{ text }</p> )
              }
            </div>
        },
        {
          title: "ENERO",
          dataIndex: "enero",
          key: "enero",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "FEBRERO",
          dataIndex: "febrero",
          key: "febrero",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "MARZO",
          dataIndex: "marzo",
          key: "marzo",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "GASTADO AL 1ER TRIMESTRE",
          dataIndex: "primer",
          key: "primer",
          align: "right",
          width: 135,
          render: ( text ) => <strong>{ text != null ? napformat( text ) : "" }</strong>,
        },
        {
          title: "ABRIL",
          dataIndex: "abril",
          key: "abril",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "MAYO",
          dataIndex: "mayo",
          key: "mayo",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "JUNIO",
          dataIndex: "junio",
          key: "junio",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "GASTADO AL 2DO TRIMESTRE",
          dataIndex: "segundo",
          key: "segundo",
          align: "right",
          width: 135,
          render: ( text ) => <strong>{ text != null ? napformat( text ) : "" }</strong>,
        },
        {
          title: "JULIO",
          dataIndex: "julio",
          key: "julio",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "AGOSTO",
          dataIndex: "agosto",
          key: "agosto",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "SETIEMBRE",
          dataIndex: "setiembre",
          key: "setiembre",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "GASTADO AL 3ER TRIMESTRE",
          dataIndex: "tercero",
          key: "tercero",
          align: "right",
          width: 135,
          render: ( text ) => <strong>{ text != null ? napformat( text ) : "" }</strong>,
        },
        {
          title: "OCTUBRE",
          dataIndex: "octubre",
          key: "octubre",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "NOVIEMBRE",
          dataIndex: "noviembre",
          key: "noviembre",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "DICIEMBRE",
          dataIndex: "diciembre",
          key: "diciembre",
          align: "right",
          width: 125,
          render: ( text ) => <div>{ text != null ? napformat( text ) : "" }</div>,
        },
        {
          title: "GASTADO AL 4TO TRIMESTRE",
          dataIndex: "cuarto",
          key: "cuarto",
          align: "right",
          width: 135,
          render: ( text ) => <strong>{ text != null ? napformat( text ) : "" }</strong>,
        },
        {
          title: "TOTAL",
          dataIndex: "total",
          key: "total",
          align: "right",
          width: 125,
          render: ( text ) => <strong>{ text != null ? napformat( text ) : "" }</strong>,
        },
      ]

    default:
      return []
  }
}

export default getColumns
