import React from "react";
import { Select } from "antd";

const { Option } = Select;

const listTurnos = ["MAÑANA", "TARDE", "NOCHE"];

const SelectTurnoEscolar = ({ shift, setShift }) => {
  return (
    <Select
      value={shift}
      onChange={setShift}
      style={{ width: "100%" }}
      size="large"
    >
      <Option value={null}>- NINGUNO -</Option>
      {listTurnos.map((el, index) => (
        <Option value={el} key={index}>
          {el}
        </Option>
      ))}
    </Select>
  );
};

export default SelectTurnoEscolar;
