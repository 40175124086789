import React, { useEffect, useState } from "react";
import { notification, Spin } from "antd";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Menu,
  Box,
  CardActionArea,
  Divider,
} from "@material-ui/core";
import DropZone from "src/components/Global/DropZone";
import PublishIcon from "@material-ui/icons/Publish";
import sedeactual from "src/config/sedeactual";
import moment from "moment";
import { uploadWorkerDocuments } from "src/utils/req.boletas";
import { getHeadquartersFilter } from "src/api/api.contable";
import sleep from "src/utils/sleep";
import { ArrowRight } from "@material-ui/icons";

const year = sedeactual.getAnio();

const ImportAttendanceWorkersByCompany = () => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [urlHdqt, setUrlHdqt] = useState([]);

  useEffect(() => {
    const obtainCompanies = () => {
      setLoading(true);
      getHeadquartersFilter(sedeactual.company().url)
        .then((res) => {
          const result = res.data.map((el) => ({
            url: el.url ?? "No especificado",
            name: el.description,
            _id: el._id,
          }));
          setUrlHdqt([...result]);
        })
        .finally(() => setLoading(false));
    };

    obtainCompanies();
  }, []);

  const formatData = (filename) => {
    let parse = filename.split("-");
    let urlH = parse[2].slice(0, parse[2].length - 4);
    let finder = urlHdqt.find((e) => e.url === urlH);

    let n_doc = parse[1],
      headquarterId = finder?._id ?? null,
      detail = `FORMATO DE ASISTENCIA - ${n_doc} - ${finder?.name ?? ""}`;

    return { n_doc, headquarterId, detail, urlH };
  };

  const sendData = async () => {
    try {
      setLoading(true);
      const m = (moment().month() + 1).toString();
      const date_upload = `${year}-${m.length === 1 ? `0${m}` : m}-${moment().format("DD")}`;

      for (let i = 0; i < files.length; i++) {
        const { n_doc, headquarterId, detail, urlH } = formatData(files[i].name);
        if (!n_doc && !headquarterId && !detail) {
          notification["warning"]({
            message: `${urlH}. No pertenece a ninguna sede. Este archivo no se subirá.`,
            description: `DATOS: ${n_doc} - ${detail} - ${date_upload}`,
            duration: 30000,
          });
          continue;
        }

        let form = new FormData();
        form.append("file", files[i]);
        form.append("date_upload", date_upload);
        form.append("detail", detail);
        form.append("type", "files");
        form.append("n_doc", n_doc);
        form.append("headquarter", headquarterId);
        form.append("company", sedeactual.company()._id);

        await uploadWorkerDocuments(form);
        await sleep(100);

        notification["info"]({ message: `${detail} subido!` });
      }

      notification["success"]({
        message: `LA IMPORTACIÓN CONCLUYÓ EXITOSAMENTE!`,
        description: `Se subieron ${files.length} archivos.`,
      });

      setFiles([]);
    } catch (error) {
      notification["error"]({ message: `Ocurrió un error al realizar la operación` });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} align="center">
              <Typography variant="h4">
                <b>IMPORTAR FORMATOS DE ASISTENCIA DE TRABAJADORES POR COMPAÑIA</b>
              </Typography>
            </Grid>
            <ContentTypes data={urlHdqt} />
            <Grid item xs={12}>
              <DropZone myFiles={files} setMyFiles={setFiles} accept="application/pdf" />
            </Grid>
            <Grid item xs={12} align="center">
              <Button
                onClick={sendData}
                disabled={files.length === 0}
                variant="contained"
                color="primary"
                size="large"
              >
                IMPORTAR <PublishIcon />
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Spin>
  );
};

export default ImportAttendanceWorkersByCompany;

const ContentTypes = ({ data = [] }) => {
  const [headTooltip, setHeadTooltip] = useState(null);

  return (
    <Grid item xs={12}>
      <Typography variant="caption" component="div">
        - Recuerde que los archivos deben estar en formato <b>PDF.</b> <br />- El nombre del archivo
        debe tener el formato: [enumeracion]-[dni]-[sedeUrl].pdf | Ejemplo: 01-42586542-prueba.pdf
        <br />
        <Button
          onClick={(e) => setHeadTooltip(e.currentTarget)}
          size="small"
          color="primary"
          variant="outlined"
        >
          VER PARÁMETROS DE LAS SEDES
        </Button>
      </Typography>

      <Menu
        anchorEl={headTooltip}
        keepMounted
        open={Boolean(headTooltip)}
        onClose={() => setHeadTooltip(null)}
      >
        <Box px={3} py={1}>
          {data.map((e) => (
            <CardActionArea>
              <ArrowRight /> {e.url} - {e.name}
              <Divider />
            </CardActionArea>
          ))}
        </Box>
      </Menu>
    </Grid>
  );
};
