import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormHelperText,
  InputAdornment,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@material-ui/core";
import _ from "lodash/fp";
import { makeStyles } from "@material-ui/styles";
import useForm from "react-hook-form";
import moment from "moment";
import { apiInventario } from "../../../../../config/api.contable";
import axios from "axios";
import NumberFormat from "react-number-format";
import { notification } from "antd";

const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: "50%",
    maxWidth: "80%",
  },
  iconText: {
    color: "#aaa",
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //prefix="S/ "
    />
  );
}

const ModalAddIntangibles = (props) => {
  const classes = useStyles();
  const {
    visible,
    setVisible,
    reloadFunction,
    categories,
    idLocation,
    sede,
    token,
    dataIntangibles,
    loading,
    setLoading,
  } = props;

  const { register, handleSubmit, errors } = useForm();
  const [status, setStatus] = useState(dataIntangibles.status);
  const [customValue, setCustomValue] = useState(dataIntangibles.category);
  const [subCategories, setSubCategories] = useState(
    dataIntangibles.category.subcategory
  );
  const [subcategory, setSubcategory] = useState(
    dataIntangibles.subcategory?._id ?? ""
  );
  const [selectedDate, setSelectedDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [values, setValues] = useState({
    unit_value: dataIntangibles.unit_value,
    historical_value: dataIntangibles.historical_value,
  });

  const handleChange = (event) => {
    if (event.target.name == "unit_value") {
      values.historical_value = event.target.value.toUpperCase();
    }

    setValues({
      ...values,
      [event.target.name]: event.target.value.toUpperCase(),
    });
  };

  const addRegister = async (data) => {
    if (customValue != null && idLocation != null) {
      setLoading(true);
      try {
        if (values.unit_value != "" || values.historical_value != "") {
          data.category = customValue._id;
          data.subcategory = subcategory ? subcategory : null;
          data.type = "intangibles";
          data.description = data.description.toUpperCase();
          data.serie = data.serie;
          data.acquisition_date = selectedDate;
          data.unit_value = values.unit_value;
          data.historical_value = values.historical_value;
          data.status = status;
          data.location = idLocation;
          data.token = token;
          data.sede = sede;

          await axios.put(
            `${apiInventario}/fixed-asset/${dataIntangibles._id}`,
            data
          );
          notification["success"]({
            message: "El registro se actualizó correctamente.",
          });
          reloadFunction();
          setVisible(false);
        } else {
          notification["info"]({
            message: "Campos requeridos que falta completar.",
          });
        }
      } catch (err) {
        notification["error"]({
          message: "Ocurrió un error al agregar el registro.",
          description: `${err}`,
        });
      } finally {
        setLoading(false);
      }
    } else {
      notification["info"]({
        message: "Debe seleccionar un nombre de cuenta.",
      });
    }
  };

  return (
    <Dialog open={visible} classes={{ paper: classes.dialog }}>
      <DialogTitle>
        <Typography
          variant="h2"
          component="h2"
          color="textPrimary"
          align="center"
        >
          EDITAR INTANGIBLE
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(addRegister)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={8}>
              <Autocomplete
                id="combo-box-demo"
                options={categories}
                fullWidth
                getOptionLabel={(option) => option.name.toUpperCase()}
                renderOption={(option) => (
                  <React.Fragment>
                    <Typography component="span" variant="subtitle2">
                      {option.name.toUpperCase()}{" "}
                      <FormHelperText component="span">
                        {option.account_number} {option.account_name}
                      </FormHelperText>
                    </Typography>
                  </React.Fragment>
                )}
                onChange={(event, newValue) => {
                  setCustomValue(newValue);
                  setSubCategories([...newValue.subcategory]);
                }}
                defaultValue={customValue}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Categoria"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>SubCategorias</InputLabel>
                <Select
                  value={subcategory}
                  onChange={(event) => setSubcategory(event.target.value)}
                  label="SubCategorias"
                >
                  {subCategories.map((el) => (
                    <MenuItem value={el._id}>{el.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                label="Código de licencia"
                fullWidth
                variant="outlined"
                name="serie"
                defaultValue={dataIntangibles.serie}
                inputRef={register({
                  required: false,
                  maxLength: 20,
                  // pattern: /^[A-Za-z0-9]+$/i,
                })}
              />
              <FormHelperText error>
                {_.get("serie.type", errors) === "required" &&
                  "El código de licencia es requerido."}
                {_.get("serie.type", errors) === "maxLength" &&
                  "No debe exceder los 20 caracteres."}
                {_.get("serie.type", errors) === "pattern" &&
                  "Solo se acepta caracteres alfabéticos y números."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                label="Descripción técnica"
                fullWidth
                variant="outlined"
                name="description"
                defaultValue={dataIntangibles.description}
                inputRef={register({
                  required: true,
                  maxLength: 255,
                })}
              />
              <FormHelperText error>
                {_.get("description.type", errors) === "required" &&
                  "La descripción es requerida."}
                {_.get("description.type", errors) === "maxLength" &&
                  "No debe exceder los 255 caracteres."}
              </FormHelperText>
            </Grid>

            <br />

            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                autoFocus
                label="Fecha de Adquisición"
                required
                variant="outlined"
                fullWidth
                name="acquisition_date"
                defaultValue={dataIntangibles.acquisition_date}
                type="date"
                value={selectedDate}
                onChange={(event) =>
                  setSelectedDate(event.target.value.toUpperCase())
                }
                inputProps={{
                  max: moment(Date.now()).format("YYYY-MM-DD"),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                label="Valor unitario"
                fullWidth
                variant="outlined"
                name="unit_value"
                value={values.unit_value}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.iconText}
                    >
                      S/.
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                label="Valor total"
                fullWidth
                variant="outlined"
                name="historical_value"
                disabled
                value={values.historical_value}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.iconText}
                    >
                      S/.
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Estado</InputLabel>
                <Select
                  value={status}
                  defaultValue={status}
                  onChange={(event) =>
                    setStatus(event.target.value.toUpperCase())
                  }
                  label="Estado"
                >
                  <MenuItem value="BUENO">BUENO</MenuItem>
                  <MenuItem value="REGULAR">REGULAR</MenuItem>
                  <MenuItem value="DETERIORADO">DETERIORADO</MenuItem>
                  <MenuItem value="FALTA ARREGLAR">FALTA ARREGLAR</MenuItem>
                  <MenuItem value="MALOGRADO">MALOGRADO</MenuItem>
                  <MenuItem value="DESUSO">DESUSO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => setVisible(false)}
          >
            Cerrar
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={
              dataIntangibles.category_id == "" || idLocation == "" || loading
                ? true
                : false
            }
          >
            Guardar
          </Button>
        </DialogActions>
        {categories.length > 0 || idLocation == "" ? (
          ""
        ) : (
          <Typography align="right" color="secondary">
            No se encontraron categorías registradas. Registre categorías y
            vuelva a intentarlo.
          </Typography>
        )}
      </form>
    </Dialog>
  );
};

export default ModalAddIntangibles;
