import React, { useState, useEffect, Fragment } from "react";
import {
  Paper,
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Divider,
} from "@material-ui/core";
import { notification } from "antd";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import sedeactual from "src/config/sedeactual";
import { makeStyles } from "@material-ui/styles";
import { apiReportes } from "src/config/api.contable";
import axios from "axios";
import { Link } from "react-router-dom";
import SelectSedes from "src/components/Usuarios/SelectSedes";
import Spinner from "src/components/Global/Spinner";
import { getHeadquarters } from "src/api/api.contable";
import moment from "moment";
import TableEEGGPPN from "../ExportarEEGGPPN/TableEEGGPPN";
import TableBalanceDetallado from "../BalanceDetallado/TableBalanceDetallado";

const useStyles = makeStyles((theme) => ({
  logo: {
    position: "absolute",
    top: 10,
    left: 25,
  },
  excel: {
    backgroundColor: "green",
    color: "white",
    width: 121,
    margin: 2,
    "&:hover": {
      backgroundColor: "green",
    },
  },
  select: {
    maxWidth: "calc(100% - 275px)",
    minWidth: 300,
    textAlign: "left",
  },
  selectsedes: {
    maxWidth: "calc(100% - 650px)",
    minWidth: 300,
    textAlign: "left",
  },
}));

const year = sedeactual.getAnio();

const ExportarERIN = () => {
  const classes = useStyles();
  const rucHeadquarters = sedeactual.getRuc();
  const [period, setPeriod] = useState(moment().month() + 1);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [sedeSelected, setSedeSelected] = useState(sedeactual.get());
  const [titleReport, setTitleReport] = useState({
    title: sedeactual.description(),
  });
  const [sedes, setSedes] = useState([]);
  const [balance, setBalance] = useState([]);

  const getData = async () => {
    try {
      setError(false);
      setData(null);
      let res = await axios.get(
        `${apiReportes}/eeggpp/detallado/${year}/${period}/${sedeSelected}/${
          sedeactual.company().url
        }`
      );

      setData({
        egresos: res.data.infoFinalEgresos,
        ingresos: res.data.infoFinalIngresos,
        ejercicio: res.data.infoFinalEjercicio,
      });

      const res_two = await axios.get(
        `${apiReportes}/general-balance/${year}/${
          period == 0 ? 12 : period
        }/${sedeSelected}/${sedeactual.company().url}`
      );
      setBalance(res_two.data);
    } catch (error) {
      notification["warning"]({
        message: `NO SE LOGRÓ OBTENER LA INFORMACIÓN COMPLETA DEL REPORTE...`,
      });
    }
  };

  const getSedes = async () => {
    try {
      let result = await getHeadquarters();
      setSedes(result.data);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se logró obtener las sedes.",
      });
    }
  };

  useEffect(() => {
    getSedes();
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, sedeSelected]);

  return (
    <Paper style={{ padding: 25 }}>
      <div align="right">
        <Link to={{ pathname: "/reportes" }}>
          <Button variant="outlined" color="primary" style={{ float: "left" }}>
            <ArrowBackIosIcon /> VOLVER
          </Button>
        </Link>
        {(sedeactual.getRol() === "supernap" ||
          sedeactual.getRol() === "superadmin") && (
          <FormControl
            variant="outlined"
            size="small"
            className={classes.selectsedes}
          >
            <SelectSedes
              defaultValue={sedeSelected}
              data={sedes}
              setTitle={setTitleReport}
              onChangeSelect={(e) => {
                setSedeSelected(e);
              }}
            />
          </FormControl>
        )}{" "}
        <FormControl variant="outlined" size="small" className={classes.select}>
          <InputLabel>Seleccione</InputLabel>
          <Select
            label="seleccione"
            value={period}
            onChange={(event) => setPeriod(event.target.value)}
          >
            <MenuItem value={1}>Enero</MenuItem>
            <MenuItem value={2}>Febrero</MenuItem>
            <MenuItem value={3}>Marzo</MenuItem>
            <MenuItem value={4}>Abril</MenuItem>
            <MenuItem value={5}>Mayo</MenuItem>
            <MenuItem value={6}>Junio</MenuItem>
            <MenuItem value={7}>Julio</MenuItem>
            <MenuItem value={8}>Agosto</MenuItem>
            <MenuItem value={9}>Setiembre</MenuItem>
            <MenuItem value={10}>Octubre</MenuItem>
            <MenuItem value={11}>Noviembre</MenuItem>
            <MenuItem value={12}>Diciembre</MenuItem>
          </Select>
        </FormControl>{" "}
      </div>
      <Card align="center" style={{ position: "relative", minHeight: "100vh" }}>
        <CardContent>
          <div>
            <Typography variant="h5" color="textPrimary">
              {titleReport.title && titleReport.title.toUpperCase()}
            </Typography>
            <Typography variant="subtitle1" color="textPrimary">
              N° RUC {rucHeadquarters}
            </Typography>
            <br />
            <Typography variant="h5" color="textPrimary">
              LIBRO DE INVENTARIOS Y BALANCES
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
              Reporte mensual de{" "}
              {moment(year + "-" + period + "-01").format("MMMM YYYY")}
            </Typography>
          </div>
          <br />
          {data ? (
            <Fragment>
              <TableBalanceDetallado
                data={balance}
                year={year}
                month={period}
              />
              <Divider />
              <Divider />
              <Divider />
              <Divider />
              <Divider />
              <Divider />
              <Divider />
              <TableEEGGPPN data={data} year={year} />
            </Fragment>
          ) : error ? (
            <Typography color="error" variant="body2">
              <br />
              <br />
              No se pudo obtener la información, intente mas tarde.
            </Typography>
          ) : (
            <Spinner />
          )}
        </CardContent>
      </Card>
    </Paper>
  );
};

export default ExportarERIN;
