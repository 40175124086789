import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core";
import { apiInventario } from "../../../../../config/api.contable";
import sedeactual from "../../../../../config/sedeactual";
import NumberFormat from "react-number-format";
import useForm from "react-hook-form";
import { notification } from "antd";
import moment from "moment";
import axios from "axios";
import _ from "lodash/fp";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //prefix="S/ "
    />
  );
}

const ModalAdd = (props) => {
  const { visible, setVisible, reloadFunction, setLoading, loading } = props;
  const { register, handleSubmit, errors } = useForm();
  const [values, setValues] = useState({
    area: "",
    side_a: "",
    side_b: "",
    side_c: "",
    side_d: "",
    side_another: "",
    built_area: "",
    acquisition_value: "",
    acquisition_build_value: "",
    ground_autovalue: "",
    build_autovalue: "",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const addRegister = async (data) => {
    setLoading(true);
    try {
      data.area = values.area;
      data.side_a = values.side_a;
      data.side_b = values.side_b;
      data.side_c = values.side_c;
      data.side_d = values.side_d;
      data.side_another = values.side_another;
      data.built_area = values.built_area;
      data.acquisition_value = values.acquisition_value;
      data.acquisition_build_value = values.acquisition_build_value;
      data.ground_autovalue = values.ground_autovalue;
      data.build_autovalue = values.build_autovalue;
      data.property_name = data.property_name.toUpperCase();
      data.address = data.address.toUpperCase();
      data.observations = data.observations.toUpperCase();
      data.token = sedeactual.token();
      data.sede = sedeactual.get();

      await axios.post(
        `${apiInventario}/edification/${sedeactual.get()}/${
          sedeactual.company().url
        }`,
        data
      );
      reloadFunction();
      notification["success"]({
        message: `El registro se registró correctamente.`,
      });
      setVisible(false);
    } catch (err) {
      notification["error"]({
        message: `No se logró guardar el registro`,
        description: `${err}`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={visible} maxWidth="xl" fullWidth>
      <DialogTitle>
        <Typography
          variant="h2"
          component="h2"
          color="textPrimary"
          align="center"
        >
          AGREGAR EDIFICACIÓN
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(addRegister)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Denominación del predio*"
                fullWidth
                variant="outlined"
                name="property_name"
                inputRef={register({
                  required: true,
                  maxLength: 100,
                  // pattern: /^[A-Za-z0-9 áéóúíñ]+$/i,
                })}
              />
              <FormHelperText error>
                {_.get("property_name.type", errors) === "required" &&
                  "El título es requerido."}
                {_.get("property_name.type", errors) === "maxLength" &&
                  "No debe exceder los 100 caracteres."}
                {_.get("property_name.type", errors) === "pattern" &&
                  "Solo debe contener caracteres alfabéticos."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Dirección *"
                fullWidth
                variant="outlined"
                name="address"
                inputRef={register({
                  required: true,
                  maxLength: 100,
                })}
              />
              <FormHelperText error>
                {_.get("address.type", errors) === "required" &&
                  "La dirección es requerida."}
                {_.get("address.type", errors) === "maxLength" &&
                  "No debe exceder los 100 caracteres."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
              <TextField
                label="Área total del terreno*"
                fullWidth
                variant="outlined"
                name="area"
                value={values.area}
                required
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      mt<sup>2</sup>
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
              <TextField
                label="Lado A"
                fullWidth
                variant="outlined"
                name="side_a"
                value={values.side_a}
                required
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mt</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
              <TextField
                label="Lado B"
                fullWidth
                variant="outlined"
                name="side_b"
                onChange={handleChange}
                value={values.side_b}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mt</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
              <TextField
                label="Lado C"
                fullWidth
                variant="outlined"
                name="side_c"
                value={values.side_c}
                required
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mt</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
              <TextField
                label="Lado D"
                fullWidth
                variant="outlined"
                name="side_d"
                onChange={handleChange}
                value={values.side_d}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mt</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
              <TextField
                label="Lado restante"
                fullWidth
                variant="outlined"
                name="side_another"
                onChange={handleChange}
                value={values.side_another}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mt</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                label="Área construida*"
                fullWidth
                variant="outlined"
                name="built_area"
                required
                onChange={handleChange}
                value={values.built_area}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      mt<sup>2</sup>
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                label="N° de partida electrónica*"
                fullWidth
                variant="outlined"
                name="departure_number"
                inputRef={register({
                  required: true,
                  maxLength: 20,
                  // pattern: /^[A-Za-z0-9.,]+$/i,
                })}
              />
              <FormHelperText error>
                {_.get("departure_number.type", errors) === "required" &&
                  "El número de partida electrónica es requerido."}
                {_.get("departure_number.type", errors) === "maxLength" &&
                  "No debe exceder los 20 caracteres."}
                {_.get("departure_number.type", errors) === "pattern" &&
                  "Solo se acepta números y caracteres alfabeticos."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                label="Fecha de adquisición del terreno*"
                fullWidth
                variant="outlined"
                type="date"
                defaultValue={moment(Date.now())
                  .subtract(1, "years")
                  .format("YYYY-MM-DD")}
                inputProps={{
                  max: moment(Date.now()).format("YYYY-MM-DD"),
                }}
                name="acquisition_date"
                inputRef={register({
                  required: true,
                  maxLength: 20,
                })}
              />
              <FormHelperText error>
                {_.get("acquisition_date.type", errors) === "required" &&
                  "La fecha de adquisición del terreno es requerida."}
                {_.get("acquisition_date.type", errors) === "maxLength" &&
                  "No debe exceder los 20 caracteres."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                label="Valor de adquisición del terreno*"
                fullWidth
                variant="outlined"
                name="acquisition_value"
                required
                onChange={handleChange}
                value={values.acquisition_value}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">S/</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                label="Valor de adquisición de la construcción*"
                fullWidth
                variant="outlined"
                name="acquisition_build_value"
                required
                onChange={handleChange}
                value={values.acquisition_build_value}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">S/</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                label="Valor de autovaluo municipalidad - terreno*"
                fullWidth
                variant="outlined"
                name="ground_autovalue"
                required
                onChange={handleChange}
                value={values.ground_autovalue}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">S/</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                label="Valor de autovaluo municipalidad - Construcción*"
                fullWidth
                variant="outlined"
                name="build_autovalue"
                onChange={handleChange}
                value={values.build_autovalue}
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">S/</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Observaciones "
                fullWidth
                variant="outlined"
                name="observations"
                inputRef={register({
                  required: false,
                  maxLength: 250,
                  // pattern: /^[A-Za-z0-9 áéóúíñ,.]+$/i,
                })}
              />
              <FormHelperText error>
                {_.get("observations.type", errors) === "required" &&
                  "La observación es requerida."}
                {_.get("observations.type", errors) === "maxLength" &&
                  "No debe exceder los 250 caracteres."}
                {_.get("observations.type", errors) === "pattern" &&
                  "Solo se acepta números y caracteres alfabéticos."}
              </FormHelperText>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => setVisible(false)}
          >
            Cerrar
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={loading}
          >
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalAdd;
