import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@material-ui/core";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { notification } from "antd";

const ModalConfirmDelete = (props) => {
  const { visible, setVisible, reload, endpoint, title, subtitle, disable } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const __delete = async () => {
    setLoading(true);
    try {
      await axios.delete(endpoint);
      notification["success"]({
        message: `El registro se eliminó correctamente.`,
      });
      reload();
      setVisible(false);
    } catch (error) {
      setError(true);
      notification["error"]({
        message: `Ocurrió un error al realizar la operación`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={visible} fullWidth>
      <DialogTitle>
        <Typography variant="h3" color="textSecondary" align="center">
          Confirmar {disable ? "deshabilitado" : "eliminación"}
        </Typography>
      </DialogTitle>
      {loading ? (
        <div style={{ margin: "0 auto" }}>
          <CircularProgress />
        </div>
      ) : error ? (
        <DialogContent>
          <DialogContentText align="center" style={{ color: "red" }}>
            Ocurrió un error
          </DialogContentText>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText align="center">{title}</DialogContentText>
          <Typography variant="p" color="textSecondary" align="center">
            {subtitle}
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={() => setVisible(false)}
          color="primary"
          variant="outlined"
          disabled={loading ? true : false}
        >
          Cancelar
        </Button>
        <Button
          onClick={() => __delete()}
          color="secondary"
          variant="contained"
          disabled={loading ? true : false}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirmDelete;
