import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { FormHelperText, Typography } from "@material-ui/core";

const SelectSedes = (props) => {
  const { Option } = Select;
  const [sedes, setSedes] = useState(props.data);

  const onChange = (number) => props.onChangeSelect(number);

  useEffect(() => {
    setSedes(props.data);
  }, [props]);

  const filterOptions = (inputValue, option) => {
    const title = option.props.title.toLowerCase();
    return title.includes(inputValue.toLowerCase(), 0);
  };

  return (
    <Select
      showSearch
      size="large"
      style={{ width: "100%" }}
      value={props.defaultValue}
      onChange={onChange}
      filterOption={filterOptions}
    >
      {sedes &&
        sedes.map((i) => (
          <Option key={i.id} value={i.id} title={i.description}>
            <Typography variant="h6" component="span">
              {i.description}
            </Typography>
            {" - "}
            <FormHelperText component="span">
              {i.address} » {i.rubro}
            </FormHelperText>
          </Option>
        ))}
    </Select>
  );
};
export default SelectSedes;
