import React from "react";
import { Button, Typography } from "@material-ui/core";
import { Modal, notification } from "antd";
import axios from "axios";
import { apiCuentas } from "../../../config/api.contable";
import sedeactual from "../../../config/sedeactual";

const DeletePresupuesto = (props) => {
  const { visible, setVisible, loading, setLoading, getBudgetList, sede } =
    props;

  const deleteAllBudget = async () => {
    setLoading(true);
    try {
      await axios.delete(
        `${apiCuentas}/budget/list/${sede}/${sedeactual.getAnio()}/${
          sedeactual.company().url
        }`
      );
      notification["success"]({
        message: "El presupuesto se eliminó correctamente",
      });
      getBudgetList();
      setVisible(false);
    } catch (error) {
      notification["error"]({
        message: `No se pudo eliminar el presupuesto. ${error}`,
      });
    }
    setTimeout(() => setLoading(false), 1000);
  };

  return (
    <Modal
      title={
        <Typography align="center" variant="h3">
          <b>ELIMINAR PRESUPUESTO</b>
        </Typography>
      }
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setVisible(false)}
        >
          Cancelar
        </Button>,
        <Button
          variant="contained"
          color="primary"
          onClick={deleteAllBudget}
          loading={loading}
        >
          confirmar
        </Button>,
      ]}
    >
      <Typography variant="h5" align="center">
        <b>¿Seguro que desea eliminar todo el presupuesto?</b>
      </Typography>
      <Typography variant="body1" color="textSecondary" align="center">
        Tener en cuenta que todo el presupuesto ya no estará disponible y será
        necesario agregar y/o importar un nuevo presupuesto.
      </Typography>
    </Modal>
  );
};

export default DeletePresupuesto;
