import React from "react";
import {
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@material-ui/core";

const PartFive = ({ typeAggression, setTypeAggression }) => {
  const handleChangeTypeAggression = (index) => {
    typeAggression[index].check = !typeAggression[index].check;
    setTypeAggression([...typeAggression]);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl component="fieldset" fullWidth>
          <FormGroup row style={{ justifyContent: "space-between" }}>
            {typeAggression.map((el, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={el.check}
                    color="primary"
                    onChange={() => handleChangeTypeAggression(index)}
                    name={el.name}
                  />
                }
                label={el.name}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default PartFive;
