import React, { useState } from "react";
import Highlighter from "react-highlight-words";
import { Table, Input, Icon } from "antd";
import { Chip, Button, Tooltip, ButtonGroup, Avatar } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SettingsOverscanIcon from "@material-ui/icons/SettingsOverscan";
import AsignModulesModal from "../Global/AsignModulesModal";

const TableEmpresa = (props) => {
  const { data, loading, getHeadquartersData, setLoading, modules, setModal } = props;
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");
  const [asignarModulos, setAsignarModulos] = useState({
    data: null,
    open: false,
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onKeyPress={(e) => e.key === "Enter" && handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          color="primary"
          variant="contained"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
          color="primary"
          variant="outlined"
        >
          Limpiar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setsearchText(selectedKeys[0]);
    setsearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setsearchText("");
  }

  let columns = [
    {
      title: "RUC",
      dataIndex: "ruc",
      key: "ruc",
      align: "center",
      width: "120px",
      ...getColumnSearchProps("ruc"),
    },
    {
      title: "RAZÓN SOCIAL",
      dataIndex: "description",
      key: "description",
      align: "center",
      ...getColumnSearchProps("description"),
    },
    {
      title: "DIRECCIÓN",
      dataIndex: "address",
      key: "address",
      align: "center",
      ...getColumnSearchProps("address"),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      align: "center",
      width: 125,
      ...getColumnSearchProps("email"),
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      align: "center",
      width: 125,
      ...getColumnSearchProps("url"),
    },
    {
      title: "TELÉFONO",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      width: "100px",
    },
    {
      title: "LOGO",
      dataIndex: "logo",
      key: "logo",
      align: "center",
      width: "100px",
      render: (value) => <Avatar src={value} alt="logo headquarter" />,
    },
    {
      title: "ESTADO",
      dataIndex: "active",
      key: "active",
      align: "center",
      width: "100px",
      render: (text, record, index) => (
        <div align="center">
          {text === true ? (
            <Chip style={{ backgroundColor: "green", color: "#fff" }} size="small" label="Activo" />
          ) : (
            <Chip
              variant="outlined"
              style={{ color: "red", borderColor: "red" }}
              size="small"
              label="Inactivo"
            />
          )}
        </div>
      ),
    },
    {
      title: "ACCIONES",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      width: "150px",
      render: (text, record, index) => (
        <ButtonGroup size="small" color="primary">
          <Button
            variant="outlined"
            onClick={() =>
              setModal({
                data: record,
                open: true,
              })
            }
          >
            <Tooltip title="Editar">
              <EditIcon />
            </Tooltip>
          </Button>
          <Button
            onClick={() => setAsignarModulos({ data: record, open: true })}
            variant="contained"
          >
            <Tooltip title="Asignar módulos">
              <SettingsOverscanIcon />
            </Tooltip>
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        bordered
        pagination={false}
        dataSource={data}
        loading={loading}
        scroll={{ x: "100%" }}
      />
      {asignarModulos.open && (
        <AsignModulesModal
          visible={asignarModulos.open}
          setVisible={setAsignarModulos}
          modulosActivos={asignarModulos.data.modules}
          modules={modules}
          setLoading={setLoading}
          reload={getHeadquartersData}
          endpoint={`headquarter-modules/${asignarModulos.data._id}`}
        />
      )}
    </React.Fragment>
  );
};

export default TableEmpresa;
