/* eslint-disable react/no-multi-comp */
import React from "react";
import { matchPath } from "react-router-dom";
import PropTypes from "prop-types";
import { List, Typography } from "@material-ui/core";
import sedeactual from "../../../../config/sedeactual";
import NavigationListItem from "./NavigationListItem";

const NavigationList = (props) => {
  const { pages, ...rest } = props;

  return (
    <List style={{ paddingTop: 0 }}>
      {pages.reduce(
        (items, page) => reduceChildRoutes({ items, page, ...rest }),
        []
      )}
    </List>
  );
};

NavigationList.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array,
};

const reduceChildRoutes = (props) => {
  const { router, items, page, depth } = props;

  if (page.children) {
    const open = matchPath("/", {
      path: page.href,
      exact: false,
    });

    items.push(
      <NavigationListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        label={page.label}
        open={Boolean(open)}
        title={page.title}
      >
        <NavigationList
          depth={depth + 1}
          pages={page.children}
          router={router}
        />
      </NavigationListItem>
    );
  } else {
    items.push(
      <NavigationListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        label={page.label}
        title={page.title}
      />
    );
  }

  return items;
};

const Navigation = (props) => {
  const { title, pages, ...rest } = props;
  const router = "router";
  let modules = sedeactual.modules()
    ? sedeactual.modules()[sedeactual.sede_id()]
    : [];

  return (
    <div {...rest}>
      {sedeactual.getRol() === "supernap" ? (
        <React.Fragment>
          <Typography
            variant="overline"
            component="span"
            style={{ borderBottom: "1px solid #0288d1", marginLeft: 10 }}
          >
            {title}
          </Typography>
          <NavigationList depth={0} pages={pages} router={router} />
        </React.Fragment>
      ) : (
        modules &&
        modules.includes(title) && (
          <React.Fragment>
            <Typography
              variant="overline"
              component="span"
              style={{ borderBottom: "1px solid #0288d1", marginLeft: 10 }}
            >
              {title}
            </Typography>
            <NavigationList depth={0} pages={pages} router={router} />
          </React.Fragment>
        )
      )}
    </div>
  );
};

Navigation.propTypes = {
  pages: PropTypes.array.isRequired,
  title: PropTypes.string,
};

export default Navigation;
