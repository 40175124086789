import React, { useEffect, useState } from "react";
import Page from "src/components/Page";
import { getWorkerInformation } from "src/utils/req.boletas";
import { notification } from "antd";
import TableAttendanceFormats from "./components/TableAttendanceFormats";
import ModalConfirmDelete from "src/components/ModalConfirmDelete";
import { apiContable } from "src/config/api.contable";
import sedeactual from "src/config/sedeactual";
import moment from "moment";

const AttendanceFormats = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [del, setDel] = useState({ open: false, id: null });

  useEffect(() => {
    obtainData();
  }, []);

  const obtainData = async () => {
    setLoading(true);
    try {
      const res = await getWorkerInformation("files");
      const result = res.map((row) => ({
        ...row,
        date_upload: moment(row.date_upload).format("DD/MM/YYYY"),
        fullname: `${row.worker?.l_name ?? ""} ${row.worker?.f_name ?? ""}`,
      }));
      setData([...result]);
    } catch (error) {
      notification["error"]({ message: `Ocurrió un error al realizar la operación` });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page title="FORMATOS DE ASISTENCIA" helper="COLABORADORES">
      <TableAttendanceFormats
        data={data}
        loading={loading}
        setDel={setDel}
        rol={sedeactual.getRol()}
      />

      {del.open && (
        <ModalConfirmDelete
          visible={del.open}
          setVisible={setDel}
          reload={obtainData}
          endpoint={`${apiContable}/worker/general/information/${del.id}`}
          title="CONFIRMACIÓN DE ELIMINACIÓN DE ARCHIVO"
          subtitle="¿Seguro que desea eliminar este archivo? Recuerda que después ya no estará disponible."
          disable={false}
        />
      )}
    </Page>
  );
};

export default AttendanceFormats;
