import React, { useEffect, useState } from "react";
import Page from "src/components/Page";
import { notification } from "antd";
import { useParams } from "react-router-dom";
import {
  Grid,
  TextField,
  FormHelperText,
  Typography,
  Box,
  Card,
  Divider,
  Button,
  CardContent,
  Tooltip,
} from "@material-ui/core";
import numeral from "numeral";
import Spinner from "src/components/Global/Spinner";
import SelectOrigen from "src/components/Selects/SelectOrigen";
import SelectPeriodo from "src/components/Selects/SelectPeriodo";
import SelectDoc from "src/components/Selects/SelectDoc";
import axios from "axios";
import TableDetail from "./TableDetails";
import { apiCuentas } from "src/config/api.contable";
import { getOrigins, getTipoDocs } from "src/api/api.contable";
import ModalAddProvisioning from "./ModalAddProvisioning";
import moment from "moment";
import sedeactual from "src/config/sedeactual";

const DetailCP = () => {
  const { idVoucher, type } = useParams();
  const [loading, setLoading] = useState(false);
  const [voucher, setVoucher] = useState(null);
  const [otherVouchers, setOtherVouchers] = useState([]);
  const [modalAddVoucher, setModalAddVoucher] = useState(false);
  const [dataNewVoucher, setDataNewVoucher] = useState({});
  const [origins, setOrigins] = useState([]);
  const [dataTipos, setDataTipos] = useState([]);
  const [voucherAprov, setVoucherAprov] = useState(null);
  const [montoRestante, setMontoRestante] = useState({
    pagado: 0,
    restante: 0,
  });

  useEffect(() => {
    getProvisioning();
  }, []);

  const getProvisioning = async () => {
    setLoading(true);
    try {
      const resOrigins = await getOrigins();
      const resTypes = await getTipoDocs();
      setDataTipos(resTypes);
      setOrigins(resOrigins);

      const res = await axios.get(`${apiCuentas}/voucher/${idVoucher}`);
      if (res.data) {
        const result = await axios.post(`${apiCuentas}/voucher-provisioning/${idVoucher}`, {
          n_voucher: res.data.n_voucher,
          headquarter: res.data.headquarter,
        });
        setVoucher(result.data.voucher);
        setOtherVouchers(result.data.others);
        let tmpDetails = result.data.voucher.details.filter((el) =>
          ["12", "14", "16", "41", "42", "46"].includes(
            el.account.number.toString().substring(0, 2)
          )
        );

        let suma = 0;
        let sum = 0;

        result.data.others.forEach((el) => {
          el.details
            .filter((ev) => ev.type_detail === result.data.voucher.type_provisioning)
            .forEach((ev) => (suma += ev.amount));
        });

        tmpDetails.forEach((el) => (sum += Number(el.amount)));

        setVoucherAprov(tmpDetails);
        setMontoRestante({ pagado: suma, restante: sum - suma });
      } else {
        notification["warning"]({
          message: "No se encontró información del voucher",
        });
      }
    } catch (error) {
      notification["error"]({ message: `Ocurrió un error. ${error}` });
    } finally {
      setLoading(false);
    }
  };

  const setNewVoucher = () => {
    if (voucher) {
      let tempVoucher = { ...voucher };
      tempVoucher["details"] = [];
      setDataNewVoucher(tempVoucher);
    }
  };

  useEffect(() => {
    setNewVoucher();
  }, [voucher]);

  return (
    <Page
      helper="DETALLE DE VOUCHER"
      title={type.toUpperCase()}
      itemComponent={
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setNewVoucher();
            setModalAddVoucher(true);
          }}
          disabled={!Boolean(voucher) ||sedeactual.getRol() === "usuario" || montoRestante.restante === 0 ? true : false}
        >
          AGREGAR
        </Button>
      }
    >
      {loading ? (
        <Spinner />
      ) : (
        <Grid container spacing={1}>
          {Boolean(voucher) ? (
            <React.Fragment>
              <Grid item xs={12} />
              <Grid item xs={12} sm={6} lg={4}>
                <CustomText title="ORIGEN" />
                <SelectOrigen
                  edit={true}
                  defaultValue={voucher.origin}
                  onChangeSelect={(e) => console.clear()}
                  data={origins}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <CustomText title="PERIODO" />
                <SelectPeriodo
                  edit={true}
                  defaultValue={voucher.period}
                  onChangeSelect={(e) => console.clear()}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <CustomText title="FECHA DE OPERACIÓN" />
                <CustomTexField value={moment(voucher.operation_date).format("DD/MM/YYYY")} />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <CustomText title="TIPO DE DOCUMENTO" />
                <SelectDoc
                  edit={true}
                  defaultValue={voucher.t_doc}
                  onChangeSelect={(e) => console.clear()}
                  data={dataTipos}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <CustomText title="SERIE" />
                <CustomTexField value={voucher.serie} />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <CustomText title="N° COMPROBANTE" />
                <CustomTexField value={voucher.correlative} />
              </Grid>
              <Grid item xs={12} md={5} lg={3}>
                <CustomText title="RUC / DNI" />
                <CustomTexField
                  value={typeof voucher.customer === "object" ? voucher.customer.ruc : voucher.ruc}
                />
              </Grid>
              <Grid item xs={12} md={7} lg={9}>
                <CustomText title="CLIENTE Ó PROVEEDOR" />
                <CustomTexField
                  value={
                    typeof voucher.customer === "object"
                      ? voucher.customer.nombre_o_razon_social
                      : voucher.customer
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {voucher.details &&
                    voucher.details.map(
                      (detail, index) =>
                        detail.isDestiny === false && (
                          <Grid item key={index}>
                            <Card>
                              <Box p={1}>
                                <Tooltip
                                  title={`${detail.account.number} • ${detail.account.description}`}
                                  placement="top"
                                >
                                  <Typography
                                    variant="h6"
                                    component="h2"
                                    noWrap
                                    style={{ maxWidth: 300 }}
                                  >
                                    {detail.account.number}
                                    {" • "}
                                    {detail.account.description}
                                  </Typography>
                                </Tooltip>
                                <Typography color="textSecondary" gutterBottom>
                                  {detail.type_detail}
                                  {" • "}S/
                                  {numeral(detail.amount).format("0,0.00")}
                                </Typography>
                                <Tooltip title={`${detail.comment}`} placement="top">
                                  <Typography
                                    color="textSecondary"
                                    noWrap
                                    style={{ maxWidth: 300 }}
                                  >
                                    {detail.comment}
                                  </Typography>
                                </Tooltip>
                                <Typography variant="body2" component="p">
                                  Periodo: {moment(`2022-${detail.period}-15`).format("MMMM")}
                                </Typography>
                              </Box>
                            </Card>
                          </Grid>
                        )
                    )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={8} md={9} lg={10} xl={11}>
                {otherVouchers.length > 0 && <TableDetail data={otherVouchers} origins={origins} />}
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
                <Card style={{ height: "100%" }} elevation={2}>
                  <CardContent style={{ height: "100%" }}>
                    <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
                      <Grid item xs={12} align="center">
                        <Typography variant="h5">DIFERENCIA</Typography>
                        <Typography variant="h4">
                          S/
                          {numeral(montoRestante.restante).format("1,0.00")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item xs={12} align="center">
              <Box m={5}>
                <Typography variant="caption">NO SE ENCONTRÓ INFORMACIÓN DEL VOUCHER</Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
      {modalAddVoucher && (
        <ModalAddProvisioning
          visible={modalAddVoucher}
          setVisible={setModalAddVoucher}
          newVoucher={dataNewVoucher}
          setNewVoucher={setDataNewVoucher}
          counterDetail={voucherAprov}
          refreshFunction={getProvisioning}
          restante={montoRestante.restante}
          pagado={montoRestante.pagado}
          origins={origins}
          defaultOrigin={voucher?.origin ?? null}
        />
      )}
    </Page>
  );
};

export default DetailCP;

const CustomTexField = ({ value }) => {
  return (
    <TextField
      disabled
      variant="outlined"
      fullWidth
      value={value}
      size="small"
      InputLabelProps={{ shrink: true }}
    />
  );
};

const CustomText = ({ title }) => {
  return <FormHelperText style={{ marginTop: 0 }}>{title}</FormHelperText>;
};
