
import React, { useState, useEffect } from "react"
import { notification } from "antd"
import axios from "axios"
import {
  Button,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import { apiCuentas } from "../../config/api.contable"
import sedeactual from "../../config/sedeactual"
import SelectCuenta from "../Selects/SelectCuenta"
import SelectCostFormula from "../Selects/SelectCostFormula"

const ModalAddAccount = ( { open, setOpen, data, setEditAccount, getAccounts, cuentas } ) => {
  const [ number, setNumber ] = useState( null )
  const [ visible, setVisible ] = useState( true )
  const [ accounts, setAccounts ] = useState( [] )
  const [ loading, setLoading ] = useState( false )
  const [ description, setDescription ] = useState( '' )
  const [ costoSelected, setCostoSelected ] = useState( null )
  const [ debeSelected, setDebeSelected ] = useState( null )
  const [ assetSelected, setAssetSelected ] = useState( null )

  useEffect( () => {
    let sixDigits = cuentas.filter(
      ( element ) => element.number.toString().length === sedeactual.digits()
    )
    setAccounts( sixDigits )
  }, [ cuentas ] )

  const SendData = async () => {
    try {
      setLoading( true )

      if ( number === '' ) return notification[ "warning" ]( {
        message: "Actualizar Cuenta",
        description: "Cuenta necesaria.",
      } )
      if ( description === '' ) return notification[ "warning" ]( {
        message: "Actualizar Cuenta",
        description: "Descripción necesaria.",
      } )

      let datos = {
        description: description,
        number: number,
        visible: visible,
        asset: assetSelected,
        debit: debeSelected,
        cost: costoSelected,
      }

      await axios.post( `${ apiCuentas }/accounting-plan/insert/${ sedeactual.getAnio() }/${ sedeactual.get() }/${ sedeactual.company().url }`, datos )
      notification[ "success" ]( {
        message: "Actualizar Cuenta",
        description: "La cuenta fue creada satisfactoriamente.",
      } )
    } catch ( error ) {
      console.log( error )
      notification[ "error" ]( {
        message: "Actualizar Cuenta",
        description: "Ocurrio un error al crear la cuenta.",
      } )
    } finally {
      setLoading( false )
      setOpen( false )
    }
  }

  return (
    <Dialog open={ open } maxWidth="lg">
      <DialogTitle>
        <Typography
          variant="h2"
          component="h2"
          color="textPrimary"
          align="center"
        >
          AGREGAR CUENTA CUENTA
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={ 3 }>
          <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
            <FormHelperText>CUENTA</FormHelperText>
            <TextField
              fullWidth
              size='small'
              type='number'
              value={ number }
              variant='outlined'
              required={ true }
              onChange={ ( e ) => setNumber( e.target.value ) }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
            <FormHelperText>DESCRIPCIÓN</FormHelperText>
            <TextField
              variant="outlined"
              size="small"
              value={ description }
              onChange={ ( e ) => setDescription( e.target.value ) }
              fullWidth
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
            <FormHelperText>FORMULA DE COSTOS</FormHelperText>
            <SelectCostFormula
              defaultValue={ costoSelected }
              onChangeSelect={ ( e ) => {
                setCostoSelected( e )
              } }
            />
          </Grid>

          <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
            <FormHelperText>DEBE</FormHelperText>
            <SelectCuenta
              defaultValue={ debeSelected }
              onChangeSelect={ ( e ) => setDebeSelected( e ) }
              data={ accounts }
            />
          </Grid>

          <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
            <FormHelperText>HABER</FormHelperText>
            <SelectCuenta
              defaultValue={ assetSelected }
              onChangeSelect={ ( e ) => setAssetSelected( e ) }
              data={ accounts }
            />
          </Grid>

          <Grid item xs={ 12 } md={ 6 } lg={ 4 } align="center">
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={ visible }
                  onChange={ () => setVisible( !visible ) }
                />
              }
              label="Visible"
              labelPlacement="end"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          onClick={ () => setOpen( false ) }
        >
          CERRAR
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={ loading }
          onClick={ SendData }
        >
          GUARDAR
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalAddAccount
