import React, { useState, useEffect, useRef } from "react";
import {
  Paper,
  Card,
  CardContent,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
} from "@material-ui/core";
import { notification } from "antd";
import { Link } from "react-router-dom";
import sedeactual from "../../../config/sedeactual";
import { makeStyles } from "@material-ui/styles";
import { apiReportes } from "../../../config/api.contable";
import axios from "axios";
import moment from "moment";
import Spinner from "../../../components/Global/Spinner";
import TableDiario from "./TableMayor";
import ExportarExcel from "../../../components/Global/ExportarExcel";
import SelectSedes from "../../../components/Usuarios/SelectSedes";
import { getHeadquarters } from "../../../api/api.contable";

const useStyles = makeStyles((theme) => ({
  select: {
    maxWidth: "calc(100% - 275px)",
    minWidth: 300,
    textAlign: "left",
  },
  selectsedes: {
    maxWidth: "calc(100% - 750px)",
    minWidth: 300,
    textAlign: "left",
    marginRight: "5px",
  },
}));

const MayorGeneral = (props) => {
  const classes = useStyles();
  const componentRef = useRef();
  let anho = sedeactual.getAnio();
  const rucHeadquarters = sedeactual.getRuc();
  const [reportModeSelected, setReportModeSelected] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dataDiario, setDataDiario] = useState([]);
  const [error, setError] = useState(false);
  const [sedes, setSedes] = useState([]);
  const [sede, setSede] = useState(sedeactual.get());

  const getSedes = async () => {
    try {
      let result = await getHeadquarters();
      setSedes(result.data);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se logró obtener las sedes.",
      });
    }
  };

  useEffect(() => {
    getSedes();
  }, []);

  const getTotalData = async () => {
    setLoading(true);
    setError(false);
    let data = {
      period: reportModeSelected,
      kind: reportModeSelected === 0 ? "anual" : "mensual",
    };
    await axios
      .post(
        `${apiReportes}/report-major-accounts/${anho}/${sede}/${
          sedeactual.company().url
        }`,
        data
      )
      .then((res) => {
        setDataDiario(res.data);
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const content = (
    <Paper style={{ padding: 25 }}>
      <div align="right">
        <Link to={{ pathname: "/reportes" }}>
          <Button variant="contained" style={{ float: "left" }}>
            Volver
          </Button>
        </Link>
        {(sedeactual.getRol() === "supernap" ||
          sedeactual.getRol() === "superadmin") && (
          <FormControl
            variant="outlined"
            size="small"
            className={classes.selectsedes}
          >
            <SelectSedes
              defaultValue={sede}
              data={sedes}
              onChangeSelect={setSede}
            />
          </FormControl>
        )}{" "}
        <FormControl variant="outlined" size="small" className={classes.select}>
          <InputLabel>Seleccione</InputLabel>
          <Select
            label="seleccione"
            value={reportModeSelected}
            onChange={(event) => setReportModeSelected(event.target.value)}
          >
            <MenuItem value={0}>Anual</MenuItem>
            <MenuItem value={1}>Enero</MenuItem>
            <MenuItem value={2}>Febrero</MenuItem>
            <MenuItem value={3}>Marzo</MenuItem>
            <MenuItem value={4}>Abril</MenuItem>
            <MenuItem value={5}>Mayo</MenuItem>
            <MenuItem value={6}>Junio</MenuItem>
            <MenuItem value={7}>Julio</MenuItem>
            <MenuItem value={8}>Agosto</MenuItem>
            <MenuItem value={9}>Setiembre</MenuItem>
            <MenuItem value={10}>Octubre</MenuItem>
            <MenuItem value={11}>Noviembre</MenuItem>
            <MenuItem value={12}>Diciembre</MenuItem>
          </Select>
        </FormControl>
        <Button
          color="primary"
          variant="contained"
          style={{ margin: "0 10px" }}
          disabled={!Boolean(sede && anho)}
          onClick={getTotalData}
        >
          BUSCAR
        </Button>
        <ExportarExcel
          name={`LIBRO_MAYOR_${anho}_${moment().format("MMMM")}`}
          endpoint={`excel-report-major-accounts/${anho}/${sedeactual.get()}/${
            sedeactual.company().url
          }`}
          body={{
            period: reportModeSelected,
            kind: reportModeSelected === 0 ? "anual" : "mensual",
          }}
          method="POST"
          disabled={!Boolean(sede && anho)}
          small={false}
          api={4}
        />
        {/* <ReactToPrint
          trigger={() => (
            <Button
              color="primary"
              variant="outlined"
              style={{ marginRight: 10 }}
            >
              <PrintIcon /> IMPRIMIR
            </Button>
          )}
          content={() => componentRef.current}
        /> */}
      </div>
      <Card ref={componentRef} align="center">
        <CardContent>
          <Typography variant="h3" color="textPrimary">
            LIBRO MAYOR
          </Typography>
          <Typography
            variant="subtitle2"
            color="textPrimary"
            style={{ textTransform: "capitalize" }}
          >
            {reportModeSelected === 0 ? (
              <>Reporte anual {anho}</>
            ) : (
              `${moment(anho + "-" + reportModeSelected + "-01").format(
                "MMMM"
              )} del ${anho}`
            )}
          </Typography>
          <Typography variant="h6" color="textPrimary">
            {sedeactual.description()}
          </Typography>
          <Typography variant="subtitle1" color="textPrimary">
            RUC: {rucHeadquarters}
          </Typography>
          <div style={{ marginTop: 25 }}>
            {loading ? (
              <Spinner />
            ) : (
              <>
                {error ? (
                  <Typography variant="h6" color="error">
                    Ocurrió un error al obtener la información. Intente más
                    tarde.
                  </Typography>
                ) : (
                  <TableDiario data={dataDiario} />
                )}
              </>
            )}
          </div>
        </CardContent>
      </Card>
    </Paper>
  );
  return content;
};

export default MayorGeneral;
