import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import { notification, Icon, Button, Spin } from "antd";
import axios from "axios";
import { apiCuentas } from "../../config/api.contable";
import anioactual from "../../config/anio";
import InputFile from "../../components/Global/components/InputFile/InputFile";
import SelectSedes from "../Usuarios/SelectSedes";
import sedeactual from "../../config/sedeactual";

const ImportacionPlanCuentas = ({ isSuperAdm, sedes }) => {
  const [excelFile, setExcelFile] = useState(null);
  const [sede, setsede] = useState(sedeactual.get());
  const [selectedFileList, setSelectedFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  const uploadExcel = async () => {
    if (excelFile) {
      setLoading(true);
      let formData = new FormData();
      formData.append("file", excelFile);
      await axios
        .post(
          `${apiCuentas}/accounting-plan/upload/${anioactual.get()}/${sede}/${
            sedeactual.company().url
          }`,
          formData
        )
        .then((res) => {
          notification.open({
            message: "Éxito",
            description: "El archivo se importó correctamente.",
            icon: <Icon type="check" style={{ color: "#49E02D" }} />,
            placement: "bottomRight",
            key: `open${Date.now()}`,
          });
        })
        .catch((err) => {
          notification["error"]({
            message: `${err.response.data.msg}`,
            description: `${err.response.data.cuentasfaltantes.join(", ")}`,
          });
        })
        .finally(() => {
          setExcelFile(null);
          setLoading(false);
        });
    }
  };

  function onChangeFile(info) {
    setExcelFile(info.file.originFileObj);
    switch (info.file.status) {
      case "uploading":
        setSelectedFileList([info.file]);
        break;
      case "done":
        setSelectedFileList([info.file]);
        break;
      case "removed":
        setSelectedFileList([]);
      default:
        console.log("error");
    }
  }

  return (
    <Card style={{ height: "100%" }}>
      <Spin spinning={loading}>
        <CardContent>
          <Typography variant="h4" color="textSecondary">
            Importación de Cuentas
          </Typography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12}>
              {isSuperAdm ? (
                <>
                  <FormHelperText>SEDES</FormHelperText>
                  <SelectSedes
                    defaultValue={sede}
                    data={sedes}
                    onChangeSelect={(e) => {
                      setsede(e);
                    }}
                  />
                </>
              ) : null}
            </Grid>
            <Grid item xs={8}>
              <InputFile
                onChange={onChangeFile}
                selectedFileList={selectedFileList}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                style={{ marginTop: "13px" }}
                variant="contained"
                fullWidth
                disabled={excelFile ? false : true}
                onClick={() => uploadExcel()}
              >
                Importar
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Spin>
    </Card>
  );
};
export default ImportacionPlanCuentas;
