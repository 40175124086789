import React, { useState } from "react";
import { Modal } from "antd";
import moment from "moment";

const ModalValidation = ({ data, setmodalvalidation }) => {
  const [visible, setvisible] = useState(true);
  function handleOk() {
    setmodalvalidation({ isOpen: false, data: null });
    setvisible(false);
    // window.location.reload();
  }
  return (
    <Modal
      title="OCURRIO UN ERROR"
      footer={null}
      closable={false}
      visible={visible}
      onOk={handleOk}
    >
      <p>EL VOUCHER YA FUE INGRESADO ANTERIORMENTE</p>

      <h4 style={{ textAlign: "center" }}>Información del voucher duplicado</h4>
      <h6>N° Voucher: {data.n_voucher}</h6>
      {data?.serie && <h6>Serie: {data.serie}</h6>}
      {data?.correlative && <h6>Correlativo: {data.correlative}</h6>}
      <h6>
        Periodo:{" "}
        {moment()
          .set("month", data.period - 1)
          .format("MMMM")}{" "}
      </h6>
      {data.customer && (
        <h6>
          Cliente: {data.customer.nombre_o_razon_social} ({data.customer.ruc})
        </h6>
      )}
      <button
        type="button"
        onClick={() => handleOk()}
        className="btn btn-primary"
      >
        Aceptar
      </button>
    </Modal>
  );
};

export default ModalValidation;
