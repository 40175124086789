import React from "react"
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Fab,
} from "@material-ui/core"
import moment from "moment"
import { Print } from "@material-ui/icons"

const MemoDocument = ( { open, setOpen, data } ) => {
  const handleClose = () => setOpen( { open: false, data: null } )

  return (
    <Dialog open={ open } fullWidth maxWidth="sm" onClose={ handleClose }>
      <DialogTitle style={ { position: "relative" } }>
        <Fab color="primary" style={ { position: "absolute", top: 10, right: 10 } }>
          <Print />
        </Fab>
      </DialogTitle>
      <DialogContent>
        <Typography align="center" variant="h3" component="p">
          <b>MEMORANDUM</b>
        </Typography>
        <br />
        <Typography variant="h6" gutterBottom>
          Lima, { moment( data.date ).format( "DD [de] dddd [de] YYYY" ) }
        </Typography>
        <br />
        <Typography variant="body1">
          <b>PARA:</b> { data.to.fullname }
        </Typography>
        <br />
        <Typography variant="body1">
          <b>DE:</b> { data.from.fullname }
        </Typography>
        <br />
        <Typography variant="body1">
          <b>ASUNTO:</b> { data.subject }
        </Typography>
        <br />
        <br />
        <Typography variant="body1" style={ { padding: "0 20px" } }>
          Mediante el presente documento, me permito informar que el día{ " " }
          { moment( data.date ).format( "dddd DD [del] MMMM [del] YYYY" ) }, el{ " " }
          <b>sr(a) { data.to.fullname }</b>, identificado con{ " " }
          <b>{ `${ data.to.t_doc }: ${ data.to.n_doc }` }</b>{ " " }
          { data.status === "PENDIENTE" ? "realizará" : "realizó" } la siguiente acción
          correspondiente a las funciones asignadas:
          <br />
          <br />
          { data[ data.param ].map( ( row ) => (
            <p style={ { backgroundColor: "#eee", padding: "5px 10px" } }>
              &#9679;{ ` ${ data.action } ─ CÓDIGO DEL ACTIVO ${ row.correlative }` }
            </p>
          ) ) }
          <br />
          Por tal motivo me es grato informar a fin de que quede evidencia de la realización de
          dicha acción.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={ handleClose }>
          CERRAR
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MemoDocument
