import React from "react";
import { useState, useEffect } from "react";
import { notification, Select } from "antd";
import { getAvailablePeriods } from "../../api/api.contable";
import moment from "moment";
import sedeactual from "../../config/sedeactual";

let months = [
  { key: 1, month: "Enero" },
  { key: 2, month: "Febrero" },
  { key: 3, month: "Marzo" },
  { key: 4, month: "Abril" },
  { key: 5, month: "Mayo" },
  { key: 6, month: "Junio" },
  { key: 7, month: "Julio" },
  { key: 8, month: "Agosto" },
  { key: 9, month: "Setiembre" },
  { key: 10, month: "Octubre" },
  { key: 11, month: "Noviembre" },
  { key: 12, month: "Diciembre" },
];

const SelectPeriodo = (props) => {
  const { defaultValue, onChangeSelect, allOptions = false } = props;
  const [periods, setPeriods] = useState([]);
  const [selected, setSelected] = useState(defaultValue);

  const filterOptions = (inputValue, option) => {
    const title = option.props.children.toString().toLowerCase();
    return title.includes(inputValue.toString().toLowerCase());
  };

  const ChangeSelect = (event) => {
    onChangeSelect(event);
    setSelected(event);
  };

  const getPeriodos = async () => {
    try {
      let result = await getAvailablePeriods(moment(Date.now()).format("YYYY-MM-DD"));
      if (result.length > 0) {
        let filter = [];
        for (let i = 0; i < months.length; i++) {
          for (let e = 0; e < result.length; e++) {
            if (months[i].month === result[e]) {
              filter.push(months[i]);
            }
          }
        }
        setPeriods(filter);
      }
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se pudo obtener los periodos",
      });
    }
  };

  useEffect(() => {
    if (sedeactual.getRol() === "supernap" || sedeactual.getRol() === "superadmin") {
      setPeriods(months);
    } else {
      getPeriodos();
    }
  }, []);

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  const { Option } = Select;
  return (
    <Select
      size="large"
      style={{ width: "100%" }}
      showSearch
      value={selected}
      onChange={ChangeSelect}
      disabled={props.edit}
      filterOption={filterOptions}
    >
      {allOptions && (
        <Option value={null} key="TODOS">
          Todos
        </Option>
      )}
      {periods.map((ev) => (
        <Option value={ev.key} key={ev.key}>
          {ev.month}
        </Option>
      ))}
    </Select>
  );
};

export default SelectPeriodo;
