import React from "react";
import { Grid, FormControlLabel, Checkbox } from "@material-ui/core";

const PartSix = ({ reasons, setReasons }) => {
  const handleChangeReason = (index) => {
    reasons[index].check = !reasons[index].check;
    setReasons([...reasons]);
  };

  return (
    <Grid container>
      {reasons.map((el, index) => (
        <Grid item xs={12} sm={6} md={4}>
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={el.check}
                color="primary"
                onChange={() => handleChangeReason(index)}
                name={el.name}
              />
            }
            label={el.name}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default PartSix;
