import { Box, Card, CardActionArea, CardContent, Grid, Typography } from "@material-ui/core";
import { CategoryRounded, ChangeHistoryRounded } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
import Page from "src/components/Page";

const REPORTS = [
  {
    label: "Categoria",
    path: "reportes/categoria",
    icon: CategoryRounded,
  },
  {
    label: "SubCategoria",
    path: "reportes/subcategoria",
    icon: ChangeHistoryRounded,
  },
];

const Reports = () => {
  return (
    <Page title="REPORTES" helper="INVENTARIO">
      <Grid container spacing={2}>
        {REPORTS.map((report, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Link to={report.path} style={{ textDecoration: "none" }}>
              <Card>
                <CardActionArea>
                  <CardContent>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <report.icon style={{ width: 70, height: 70, color: "#0288D1" }} />
                      <Typography variant="h3" component="h2">
                        {report.label}
                      </Typography>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Page>
  );
};

export default Reports;
