import axios from "axios";
import sedeactual from "../../config/sedeactual";
import { apiInventario } from "../../config/api.contable";

let url = sedeactual.company() ? sedeactual.company().url : null;

export const getCategories = async () => {
  let res = await axios.get(`${apiInventario}/categories/${sedeactual.get()}/${url}`);
  return res.data;
};

export const getCategoryType = async (type) => {
  let res = await axios.get(`${apiInventario}/category-type/${type}/${sedeactual.get()}/${url}`);
  return res.data;
};

export const getLocations = async () => {
  let res = await axios.get(`${apiInventario}/location/${sedeactual.get()}/${url}`);
  return res.data;
};

export const getGrounds = async (isActive) => {
  let res = await axios.get(`${apiInventario}/grounds/${sedeactual.get()}/${url}`);
  let result = res.data.data.filter((ground) => ground.active === isActive);
  return result;
};

export const getEdifications = async (isActive) => {
  let res = await axios.get(`${apiInventario}/edifications/${sedeactual.get()}/${url}`);
  let result = res.data.data.filter((edification) => edification.active === isActive);
  return result;
};

export const getFixedAssets = async (type, sede, isActive, location) => {
  let res = await axios.get(`${apiInventario}/fixed-asset-type/${sede}/${type}/${location}/${url}`);
  let activeData = res.data.filter((element) => element.active === isActive);
  return activeData;
};

export const getLocationDetail = async (location) => {
  let res = await axios.get(`${apiInventario}/list-fixed-asset/${location}`);
  return res.data;
};

export const postEnableInventory = async (type, idRow) => {
  let res = await axios.post(`${apiInventario}/${type}-enable/${idRow}`);
  return res;
};

export const uploadCategories = async (type, data) => {
  let res = await axios.post(
    `${apiInventario}/upload-categories/${type}/${sedeactual.get()}/${
      sedeactual.company().url
    }/${sedeactual.getAnio()}`,
    data
  );
  return res;
};

export const getInventoryCategory = async () => {
  let res = await axios.get(`${apiInventario}/report/inventario/categoria/${sedeactual.sede_id()}`);
  return res.data;
};

export const getExportInventoryCategory = async () => {
  let res = await axios.get(
    `${apiInventario}/report/export/inventario/categoria/${sedeactual.sede_id()}`
  );
  return res.data;
};

export const getInventorySubCategory = async () => {
  let res = await axios.get(`${apiInventario}/report/inventario/detallado/${sedeactual.sede_id()}`);
  return res.data;
};

// MEMORANDUMS

export const getMemorandums = async () => {
  const res = await axios.get(`${apiInventario}/memorandum/${sedeactual.get()}/${url}`);
  return res.data;
};

export const postMemorandum = async (data) => {
  const res = await axios.post(`${apiInventario}/memorandum`, data);
  return res.data;
};

export const putMemorandums = async (memorandumId, data) => {
  const res = await axios.put(`${apiInventario}/memorandum/${memorandumId}`, data);
  return res.data;
};

export const deleteMemorandums = async (memorandumId) => {
  const res = await axios.delete(`${apiInventario}/memorandum/${memorandumId}`);
  return res.data;
};
