import React, { useRef } from "react";
import {
  Fab,
  Dialog,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  DialogContent,
  Paper,
  Grid,
  Box,
  Checkbox,
} from "@material-ui/core";
import { Close, Print, SubdirectoryArrowRight } from "@material-ui/icons";
import ReactToPrint from "react-to-print";
import moment from "moment";

const DetailsCovid = ({ open, setOpen, data }) => {
  const component = useRef(null);
  let antecedente = data.comorbilidad ? JSON.parse(data.comorbilidad) : {};
  const { worker } = data;
  const years = worker.f_nac
    ? `${moment(Date.now()).diff(worker.f_nac, "years")} años`
    : "─";

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogContent>
        <ReactToPrint
          trigger={() => (
            <Fab
              color="primary"
              size="small"
              style={{ position: "absolute", top: 10, left: 10 }}
            >
              <Print />
            </Fab>
          )}
          content={() => component.current}
        />
        <Fab
          onClick={() => setOpen({ open: false })}
          color="secondary"
          size="small"
          style={{ position: "absolute", top: 10, right: 10 }}
        >
          <Close />
        </Fab>
        <Paper variant="outlined">
          <Box p={5} ref={component}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" component="h2" align="center">
                  Ficha de sintomatología de la COVID-19 para regreso al trabajo
                </Typography>
                <Typography variant="body1" align="center">
                  Declaración Jurada
                </Typography>
                <Typography variant="body2" align="center">
                  Resolución Ministerial Nº 972-2020-MINSA
                </Typography>
                <Typography variant="body2">
                  He recibido explicación del objetivo de esta evaluación y me
                  comprometo a responder con la verdad
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <List>
                  <CustomList title="Tipo de documento" val={worker.t_doc} />
                  <CustomList title="Número de documento" val={worker.n_doc} />
                  <CustomList title="Nombre completo" val={data.fullname} />
                  <CustomList title="Celular" val={worker.phone} />
                  <CustomList title="Dirección" val={worker.address} />
                  <CustomList title="Correo" val={worker.email} />
                  <CustomList title="Edad" val={years} />
                </List>

                <Typography>
                  ¿Presenta alguna comorbilidad o Antecedente personal de
                  enfermedad?
                </Typography>

                <List>
                  <CustomList
                    title="Hipertensión arterial"
                    check={antecedente.hipertension}
                  />
                  <CustomList
                    title="Enfermedad cardiovascular"
                    check={antecedente.cardiovascular}
                  />
                  <CustomList
                    title="Diabetes Mellitus"
                    check={antecedente.diabetes}
                  />
                  <CustomList
                    title="Obesidad con IMC de 40 a más"
                    check={antecedente.obesidad}
                  />
                  <CustomList title="Asma" check={antecedente.asma} />
                  <CustomList
                    title="Enfermedad pulmonar crónica"
                    check={antecedente.pulmonar}
                  />
                  <CustomList
                    title="Insuficiencia renal crónica"
                    check={antecedente.renal}
                  />
                  <CustomList
                    title="Enfermedad o tratamiento inmunosupresor"
                    check={antecedente.inmunosupresor}
                  />
                  <CustomList title="Cáncer" check={antecedente.cancer} />
                  <CustomList
                    title="Otra condición de riesgo"
                    check={antecedente.otra}
                  />
                </List>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  ¿En los <b>últimos 14 días</b> calendario ha tenido alguno de
                  los siguientes síntomas?
                </Typography>
                <List>
                  <CustomList
                    title="Sensación de alza térmica, fiebre o escalofríos"
                    check={data.fiebre}
                  />
                  <CustomList
                    title="Dolor de garganta o tos"
                    check={data.garganta}
                  />
                  <CustomList
                    title="Estornudos o congestión nasal"
                    check={data.estornudos}
                  />
                  <CustomList
                    title="Dificultad para respirar"
                    check={data.respirar}
                  />
                  <CustomList
                    title="Expectoración o flema amarilla o verdosa"
                    check={data.expectoracion}
                  />
                  <CustomList
                    title="Dolor de músculos o articulaciones"
                    check={data.musculos}
                  />
                  <CustomList title="Dolor de cabeza" check={data.cabeza} />
                  <CustomList
                    title="Fatiga o malestar general"
                    check={data.fatiga}
                  />
                  <CustomList
                    title="Pérdida de gusto o de olfato"
                    check={data.gusto}
                  />
                  <CustomList title="Diarrea" check={data.diarrea} />
                  <CustomList
                    title="Contacto con persona(s) con un caso confirmado de COVID-19"
                    check={data.contactoConfirm}
                  />
                  <CustomList
                    title="Contacto con un caso sospechoso de COVID - 19"
                    check={data.contactoSospe}
                  />
                  <CustomList
                    title="¿Esta tomando alguna medicación?"
                    check={data.medicacion}
                  />
                </List>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom variant="body1">
                  Todos los datos expresados en esta ficha constituyen
                  declaración jurada de mi parte.
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Al llenar esta ficha he sido informado que de omitir o falsear
                  información puedo perjudicar la salud de mis compañeros, y la
                  mía propia, lo cual constituiría una falta grave a la salud
                  pública, asumiendo las responsabilidades que ello acarrea.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default DetailsCovid;

const CustomList = ({ title, val, check }) => {
  return (
    <ListItem dense divider>
      <ListItemIcon>
        {check === undefined ? (
          <SubdirectoryArrowRight />
        ) : (
          <Checkbox checked={check} color="primary" />
        )}
      </ListItemIcon>
      <ListItemText primary={title} secondary={val ?? null} />
    </ListItem>
  );
};
