import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";

const PartEight = ({ errors, register, eInf }) => {
  return (
    <Grid container>
      <Typography variant="body2" colot="textSecondary" gutterBottom>
        Acorde a los protocolos para la atención de la violencia escolar.
      </Typography>
      <TextField
        InputLabelProps={{ shrink: true }}
        fullWidth
        label="Procedimiento *"
        name="procedure"
        defaultValue={Boolean(eInf) ? eInf.procedure : ""}
        variant="outlined"
        multiline
        rows={4}
        inputRef={register({ required: true })}
        error={Boolean(errors?.procedure)}
      />
    </Grid>
  );
};

export default PartEight;
