import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@material-ui/core";
import numeral from "numeral";
import moment from "moment";

const IngresosEgresos = (props) => {
  const { data, title, saldos } = props;

  return (
    <div style={{overflow: 'auto'}}>
      <Typography variant="h4" color="textSecondary">
        {title}
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">N° VOUCHER</TableCell>
            <TableCell align="center">N° DOC</TableCell>
            <TableCell align="center">FECHA</TableCell>
            <TableCell align="center">CONCEPTO</TableCell>
            <TableCell align="center">MONTO</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
{/*           {data.length > 0 ? (
            <> */}
              {title == "INGRESOS" && (
                <TableRow style={{backgroundColor: '#0688d133'}}>
                  <TableCell colSpan={3}></TableCell>
                  <TableCell>SALDO ANTERIOR</TableCell>
                  <TableCell align="right">S/{numeral(saldos.saldoAnterior).format("0,0.00")}</TableCell>
                </TableRow>
              )}
              {data.map((ev) => (
                <TableRow>
                  <TableCell>{ev.voucher_number}</TableCell>
                  <TableCell>{ev.correlative}</TableCell>
                  <TableCell>{moment(ev.date).format("DD/MM/YYYY")}</TableCell>
                  <TableCell>{ev.description}</TableCell>
                  <TableCell align="right">S/{numeral(ev.amount).format("0,0.00")}</TableCell>
                </TableRow>
              ))}
              <TableRow style={{backgroundColor: '#0688d133'}}>
                <TableCell colSpan={4}>TOTAL</TableCell>
                <TableCell align="right">S/{numeral(title == "INGRESOS" ? (saldos.ingresos) : (saldos.gastos)).format("0,0.00")}</TableCell>
              </TableRow>
          {/*   </>
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">No se encontró información</TableCell>
            </TableRow>
          )} */}
        </TableBody>
      </Table>
    </div>
  );
};

export default IngresosEgresos;
