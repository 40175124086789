import axios from "axios";
import { apiReclamos } from "src/config/api.contable";
import sedeactual from "src/config/sedeactual";

// INCIDENCIAS

export const getIncidences = async () => {
  let res = await axios.get(`${apiReclamos}/incidence/${sedeactual.sede_id()}`);
  return res.data;
};

export const getIncidencesById = async (id) => {
  let res = await axios.get(`${apiReclamos}/incidence/id/${id}`);
  return res.data;
};

export const postIncidence = async (data, headquarter) => {
  let res = await axios.post(`${apiReclamos}/incidence/${headquarter}`, data);
  return res.data;
};

export const putIncidence = async (data, idIncidence) => {
  let res = await axios.put(`${apiReclamos}/incidence/${idIncidence}`, data);
  return res.data;
};

export const sendEmailWithDetails = async (data) => {
  const res = await axios.post(`${apiReclamos}/incidence/send/email`, data);
  return res.data;
};

// DETALLE DE LAS INCIDENCIAS
export const postIncidenceDetail = async (data) => {
  let res = await axios.post(`${apiReclamos}/incidence/save/detail`, data);
  return res.data;
};

export const getDetailsByIncidence = async (idIncidence) => {
  let res = await axios.get(`${apiReclamos}/incidence/show-details/${idIncidence}`);
  return res.data;
};

export const putIncidenceDetail = async (data, idIncidence) => {
  let res = await axios.put(`${apiReclamos}/incidence/update-detail/${idIncidence}`, data);
  return res.data;
};

// RECLAMOS

export const getClaims = async () => {
  let res = await axios.get(`${apiReclamos}/claims/${sedeactual.sede_id()}/napcontable`);
  return res.data;
};

export const postClaim = async (data) => {
  let res = await axios.post(`${apiReclamos}/create/claim`, data);
  return res.data;
};

export const putClaim = async (data, idClaim) => {
  let res = await axios.put(`${apiReclamos}/claim/${idClaim}`, data);
  return res.data;
};
