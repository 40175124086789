import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import { putIncidence } from "src/utils/reqinc";
import { notification } from "antd";
import { useHistory } from "react-router-dom";

const FinishedIncidence = ({ open, setOpen, incidenceId }) => {
  const [loading, setLoading] = useState(false);
  const handleClose = () => setOpen({ open: false });
  const history = useHistory();

  const finishedAction = async () => {
    try {
      setLoading(true);
      await putIncidence({ status: "FINALIZADO" }, incidenceId);
      notification["success"]({ message: `Se finalizó la incidencia con éxito.` });
      history.push("/incidencias");
    } catch (error) {
      notification["error"]({ message: `Ocurrió un error al realizar la operación.` });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="xs" onClose={handleClose}>
      <DialogTitle>
        <Typography align="center" variant="h4" component="p">
          <b>CONFIRMACIÓN DE FINALIZACIÓN DE INCIDENCIAS</b>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="textSecondary">
          RECUERDA QUE UNA VEZ FINALIZADO EL REGISTRO, YA NO ESTARÁ DISPONIBLE PARA SU POSTERIOR
          MODIFICACIÓN.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={handleClose} disabled={loading}>
          CANCELAR
        </Button>
        <Button color="secondary" variant="contained" onClick={finishedAction} disabled={loading}>
          CONFIRMAR ELIMINACIÓN
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FinishedIncidence;
