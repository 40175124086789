import axios from "axios";
import sedeactual from "../config/sedeactual";
import { apiContable, apiCuentas } from "../config/api.contable";
import anio from "../config/anio";

let url = sedeactual.company() ? sedeactual.company().url : null;

export const getHeadquarters = async () => {
  let res = await axios.get(`${apiContable}/headquarters-list/${url}`);
  return res;
};

export const getHeadquartersFilter = async (param) => {
  let res = await axios.get(`${apiContable}/headquarters-list/${param}`);
  return res;
};

export const getUsersPerHeadquarter = async (headquarterId) => {
  let res = await axios.get(`${apiContable}/users-business/${headquarterId}`);
  return res;
};

export const getMovements = async (sede, digit) => {
  let res = await axios.post(`${apiContable}/movements/${anio.get()}/${sede}`, {
    digits: digit,
  });
  return res;
};

export const postHeadquarters = async (data, urlCompany) => {
  let res = await axios.post(`${apiContable}/headquarter/${urlCompany}`, data);
  return res;
};

export const postDuplicateAccountPlan = async (
  headquarterId,
  yearParam,
  from
) => {
  let res = await axios.post(
    `${apiCuentas}/accounting-plan/duplicate/${headquarterId}/${yearParam}/${
      sedeactual.company().url
    }`,
    { from }
  );
  return res;
};

export const editHeadquarters = async (data, idHeadquarters, url) => {
  let res = await axios.put(
    `${apiContable}/headquarter/${url}/${idHeadquarters}`,
    data
  );
  return res;
};

export const getAccountAssistantById = async (account_id) => {
  let res = await axios.post(`${apiCuentas}/aux-account/account`, {
    account_id,
  });
  return res;
};

export const getCharts = async () => {
  let res = await axios.get(
    `${apiCuentas}/charts/${anio.get()}/${sedeactual.get()}/${url}`
  );
  return res;
};

export const getVouchersPeriod = async (
  headquarterId,
  yearParam,
  periodParam
) => {
  let vouchersInfo = await axios.get(
    `${apiCuentas}/vouchers/${headquarterId}/${yearParam}/${periodParam}/${url}`
  );

  return vouchersInfo.data;
};

export const getVouchersProvisioning = async (
  headquarterId,
  yearParam,
  company
) => {
  let provisioningInfo = await axios.get(
    `${apiCuentas}/vouchers-provisioning/${headquarterId}/${yearParam}/${company}`
  );
  return provisioningInfo.data;
};

export const getOrigins = async () => {
  let res = await axios.get(`${apiCuentas}/origenes`);

  return res.data;
};

export const validateVoucher = async (ruc, serie, correlative, t_doc) => {
  let res = await axios.post(`${apiCuentas}/duplicate/vouchers`, {
    ruc,
    serie,
    correlative,
    t_doc,
    year: sedeactual.getAnio(),
    headquarter: sedeactual.sede_id(),
  });

  return res.data;
};

export const deleteVoucher = async (id) => {
  let res = await axios.delete(`${apiCuentas}/voucher/${id}`);

  return res;
};

export const getDateExchange_rate = async (date) => {
  let res = await axios.post(`${apiContable}/exchange-rate`, { date });
  return res.data;
};

export const postRuc = async (ruc, type) => {
  let res = await axios.post(`${apiContable}/ruc`, {
    ruc,
    type,
  });

  return res.data;
};

export const registrarCliente = async (newcliente) => {
  let res = await axios.post(`${apiContable}/customer-register`, newcliente);

  return res.data;
};

export const getAccountPlan = async (headquarter, year) => {
  let res = await axios.get(
    `${apiCuentas}/accounting-plan/${headquarter}/${year}/${url}`
  );

  return res.data;
};

export const getAccountPlanMayor = async (headquarter, year) => {
  let res = await axios.get(
    `${apiCuentas}/accounting-plan/mayor/${headquarter}/${year}/${url}`
  );

  return res.data;
};

export const sendVoucher = async (voucher, sede) => {
  let res = await axios.post(
    `${apiCuentas}/voucher/${sede}/${sedeactual.getAnio()}/${
      sedeactual.company().url
    }`,
    voucher
  );
  return res.data;
};

export const sendProvicioning = async (voucher, sede, v_id) => {
  let res = await axios.post(
    `${apiCuentas}/voucher-provisioning/${sede}/${sedeactual.getAnio()}/${
      sedeactual.company().url
    }/${v_id}`,
    voucher
  );
  return res.data;
};

export const updateVoucher = async (voucher, id) => {
  let res = await axios.put(
    `${apiCuentas}/voucher/${id}/${sedeactual.get()}/${sedeactual.getAnio()}/${
      sedeactual.company().url
    }`,
    voucher
  );
  return res;
};

export const getVoucher = async (id) => {
  let res = await axios.get(`${apiCuentas}/voucher/${id}`);
  return res;
};

export const lastNumberVoucher = async (period) => {
  let res = await axios.get(
    `${apiCuentas}/last-vouchers/${sedeactual.get()}/${sedeactual.getAnio()}/${period}/${url}`
  );
  return res;
};

export const getRealVoucher = async (id) => {
  let res = await axios.get(`${apiCuentas}/real-voucher/${id}`);
  return res;
};

export const getBalanceReport = async (period, sede, year, digit) => {
  let res = await axios.post(`${apiCuentas}/balance/${sede}/${year}/${url}`, {
    period: period,
    digit: digit,
  });
  return res;
};

export const getBalanceCloseReport = async (sede, year, period, digit) => {
  let res = await axios.post(
    `${apiCuentas}/balance/close-seat/${sede}/${year}/${url}`,
    {
      period: period,
      digit: digit,
    }
  );
  return res;
};

export const getAuxCuentas = async (headquarter, year) => {
  let res = await axios.get(
    `${apiCuentas}/aux-account/list/${headquarter}/${year}/${url}`
  );
  return res;
};

export const postAuxCuentas = async (
  description,
  account,
  headquarter,
  year
) => {
  let res = await axios.post(
    `${apiCuentas}/aux-account/${headquarter}/${year}/${
      sedeactual.company().url
    }`,
    {
      description: description,
      account: account,
    }
  );
  return res;
};

export const putAuxCuentas = async (id, description) => {
  let res = await axios.put(`${apiContable}/aux-account/${id}`, {
    description: description,
  });
  return res;
};

export const getMajorReport = async ({
  period,
  sede,
  anio,
  kind,
  account_id,
  from,
  to,
}) => {
  let res = await axios.post(
    `${apiCuentas}/report-major/${anio}/${sede}/${url}`,
    {
      period: period,
      kind: kind,
      account_id: account_id,
      from: from,
      to: to,
    }
  );
  return res;
};

export const getMajorCaja = async (period) => {
  let res = await axios.post(
    `${apiCuentas}/major-caja/${sedeactual.getAnio()}/${sedeactual.get()}/${url}`,
    {
      period: Number(period),
    }
  );
  return res;
};

export const getCentroCostos = async (sede) => {
  let res = await axios.get(
    `${apiContable}/costs/${url}/${sede}/${sedeactual.getAnio()}`
  );
  return res;
};

export const getActiveSedes = async (cod = url) => {
  let res = await axios.get(`${apiContable}/headquarters-list-active/${cod}`);
  return res.data;
};

export const getMyCompanies = async () => {
  let res = await axios.get(`${apiContable}/my-companies`, {
    headers: { Authorization: sedeactual.token() },
  });
  return res.data;
};

export const getHeadquartersByUserAndCompany = async (id, company) => {
  let res = await axios.get(`${apiContable}/my-headquarters/${id}/${company}`, {
    headers: { Authorization: sedeactual.token() },
  });
  return res.data;
};

export const getUsers = async () => {
  let res = await axios.get(`${apiContable}/users`);
  return res.data;
};

export const getUsersByHeadquarter = async (sedeId) => {
  let res = await axios.get(`${apiContable}/users/${sedeId}`);
  return res.data;
};

export const getAvailablePeriods = async (datenow) => {
  let res = await axios.post(
    `${apiContable}/period-available/${
      sedeactual.company().url
    }/${sedeactual.get()}/${sedeactual.getAnio()}`,
    { date: datenow }
  );
  return res.data;
};

export const getTipoDocs = async () => {
  let res = await axios.get(`${apiCuentas}/tipos`);
  return res.data;
};

export const getTypeCoins = async () => {
  let res = await axios.get(`${apiCuentas}/monedas`);
  return res.data;
};

export const deleteFile = async (idFile) => {
  let res = await axios.delete(`${apiContable}/file/${idFile}`);
  return res;
};

export const getAllFiles = async (period) => {
  let res = await axios.get(
    `${apiContable}/getallfiles/${sedeactual.getAnio()}/${period}`
  );
  return res.data;
};

export const getCompanyFiles = async (period) => {
  let res = await axios.get(
    `${apiContable}/companyFiles/${
      sedeactual.company().url
    }/${sedeactual.get()}/${sedeactual.getAnio()}/${period}`
  );
  return res.data;
};

export const changeStatusFile = async (row, value) => {
  let res = await axios.put(`${apiContable}/file/${row}`, {
    status: value,
  });
  return res;
};

export const getCostFormula = async (year, sede) => {
  let res = await axios.get(
    `${apiContable}/cost-formula/${url}/${year}/${sede}`
  );
  return res.data;
};

export const recoveryPasswordByEmail = async (idUser) => {
  let res = await axios.post(
    `${apiContable}/auth/recovery-password/${idUser}`,
    {},
    { headers: { Authorization: sedeactual.token() } }
  );
  return res;
};

export const getBudget = async (sede, year) => {
  let res = await axios.get(`${apiCuentas}/budget/list/${sede}/${year}/${url}`);
  return res.data;
};

export const getCompanyByUrl = async (url) => {
  let res = await axios.get(`${apiContable}/company/${url}`);
  return res.data;
};

export const getAllCompanies = async () => {
  let res = await axios.get(`${apiContable}/company`);
  return res.data.data;
};

export const getModules = async (endpoint) => {
  let res = await axios.get(`${apiContable}/${endpoint}`);
  return res.data;
};

export const getHeadquarterByUrl = async (url) => {
  let res = await axios.get(`${apiContable}/headquarter-url/${url}`);
  return res.data;
};
