import React from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

const SelectRangeTime = ({
  date,
  setDate,
  date1,
  setDate1,
  type,
  setType,
  types,
}) => {
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item>
        <FormControl fullWidth>
          <InputLabel>Tipo</InputLabel>
          <Select
            value={type}
            label="Tipo"
            onChange={(e) => setType(e.target.value)}
          >
            {types.map((cd, index) => (
              <MenuItem value={cd} key={index}>
                {cd}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <TextField
          id="date"
          label={type === "DIARIO" ? "Fecha" : "Desde"}
          type="date"
          defaultValue={date}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setDate(e.target.value);
          }}
        />
      </Grid>
      {type === "RANGO DE FECHAS" && (
        <Grid item>
          <TextField
            id="date"
            label="Hasta"
            type="date"
            defaultValue={date1}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setDate1(e.target.value);
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SelectRangeTime;
