import React, { useRef } from "react";
import {
  Box,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import moment from "moment";
import PrintIcon from "@material-ui/icons/Print";
import CloseIcon from "@material-ui/icons/Close";
import ReactToPrint from "react-to-print";

const pageStyle = `
  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    html, body {
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-bottom: 1rem;
      display: block;
      page-break-before: always;
    }
  }

  @page {
    size: 1in 4in;
    margin: 0mm;
  }
`;

const DetailPersonal = ({ open, setOpen, data }) => {
  const componentRef = useRef(null);
  const { information, worker, createdAt } = data;
  console.log(data);

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={() => setOpen({ open: false })}>
      <DialogContent>
        <Paper style={{ backgroundColor: "#eee" }}>
          <div align="right">
            <ReactToPrint
              trigger={() => (
                <Button color="primary" variant="outlined" size="large">
                  <PrintIcon /> IMPRIMIR
                </Button>
              )}
              content={() => componentRef.current}
              documentTitle={`COMPROBANTE_INGRESOS`}
              removeAfterPrint={true}
              pageStyle={pageStyle}
            />
          </div>
          <Box p={10} style={{ position: "relative" }} ref={componentRef} component="div">
            <Grid container spacing={5}>
              <Grid item xs={12} align="center">
                <Typography variant="h3">DECLARACIÓN JURADA</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" align="justify">
                  Yo,{" "}
                  <b>
                    {worker.f_name} {worker.l_name}
                  </b>{" "}
                  Identificado(a) con <b>{worker.t_doc}</b> N° <b>{worker.n_doc}</b> y domicilio en{" "}
                  <b>{worker.address}</b>, correo electrónico <b>{worker.email}</b> teléfono{" "}
                  <b>{worker.phone}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  <b>DECLARO BAJO JURAMENTO QUE:</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table className="tablefe">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">SI</TableCell>
                        <TableCell align="center">NO</TableCell>
                        <TableCell align="center">ASPECTO</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {information.map((e, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {e.status === true ? <CloseIcon color="primary" /> : ""}
                          </TableCell>
                          <TableCell>
                            {e.status === false ? <CloseIcon color="primary" /> : ""}
                          </TableCell>
                          <TableCell>{e.label}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" align="justify" gutterBottom>
                  Doy a conocer y autorizo 1 al Director de la Institución Educativa, a ser
                  notificado/a a través del correo electrónico y teléfono señalado en la presente,
                  del resultado de la evaluación.
                </Typography>
                <Typography variant="body1" align="justify" gutterBottom>
                  En caso de comprobar fraude o falsedad en la declaración jurada por la persona que
                  realizó la declaración jurada, la institución educativa considerará no satisfecha
                  la exigencia respectiva para todos sus efectos, procediendo a declarar la nulidad
                  de su contrato y vínculo laboral.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" align="right" gutterBottom>
                  Dado en la ciudad de Lima{", "}
                  {moment(createdAt).format("[a los] D [días del mes de] MMMM [del] YYYY")}.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    container
                    alignItems="center"
                    justify="flex-end"
                    direction="column"
                  >
                    <Typography color="textSecondary" style={{ marginTop: 25 }}>
                      __________________________________________________________________
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      FIRMA
                    </Typography>
                    <Typography color="textSecondary">
                      {worker.t_doc}: {worker.n_doc}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    container
                    alignItems="center"
                    justify="flex-end"
                    direction="column"
                  >
                    <Box style={{ border: "1px solid #aaa", width: 125, height: 150 }} />
                    <Typography color="textSecondary">HUELA DACTILAR</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption">
                  Ley N°29733 Ley de Protección de Datos, Titulo I artículo 5 Principio de
                  consentimiento
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={() => setOpen({ open: false })}>
          CERRAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailPersonal;
