import React, { useState } from "react";
import CustomTable from "src/components/CustomTable";
import Page from "src/components/Page";
import sedeactual from "src/config/sedeactual";
import SelectSedes from "src/components/Usuarios/SelectSedes.jsx";
import { Button, FormControl, FormHelperText, Grid, MenuItem, Select } from "@material-ui/core";
import { notification, Spin } from "antd";
import { getHeadquarters } from "src/api/api.contable";
import { useEffect } from "react";
import { apiReportes } from "src/config/api.contable.js";
import moment from "moment";
import napformat from "src/config/napformat";
import { Search } from "@material-ui/icons";
import ExportarTXT from "src/components/Global/ExportarTXT";

let ranges = [
  { value: 1, name: "Enero" },
  { value: 2, name: "Febrero" },
  { value: 3, name: "Marzo" },
  { value: 4, name: "Abril" },
  { value: 5, name: "Mayo" },
  { value: 6, name: "Junio" },
  { value: 7, name: "Julio" },
  { value: 8, name: "Agosto" },
  { value: 9, name: "Setiembre" },
  { value: 10, name: "Octubre" },
  { value: 11, name: "Noviembre" },
  { value: 12, name: "Diciembre" },
];

const ReporteVentas = () => {
  const [sede, setSede] = useState(sedeactual.get());
  const [kind, setKind] = useState("mensual");
  const [desde, setDesde] = useState(1);
  const [hasta, setHasta] = useState(2);

  const [mes, setMes] = useState(new Date().getMonth() + 1);
  const [sedes, setSedes] = useState([]);

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSedes();
  }, []);

  const getSedes = async () => {
    try {
      let result = await getHeadquarters();
      setSedes(result.data);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se logró obtener las sedes.",
      });
    }
  };

  const columns = [
    {
      title: "N° VOUCHER",
      dataIndex: "n_voucher",
      key: "voucher",
      align: "center",
      width: 80
    },
    {
      title: "FECHA",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: 80
    },
    {
      title: "N° RUC",
      dataIndex: "ruc",
      key: "ruc",
      align: "center",
      width: 80
    },
    {
      title: "SERIE",
      dataIndex: "serie",
      key: "serie",
      align: "center",
      width: 80
    },
    {
      title: "CORRELATIVO",
      dataIndex: "correlative",
      key: "correlative",
      align: "center",
      width: 80
    },
    {
      title: "RAZÓN SOCIAL",
      dataIndex: "razonSocial",
      key: "razonSocial",
      align: "center",
      width: 150,
    },
    {
      title: "CONCEPTO",
      dataIndex: "concepto",
      key: "concepto",
      align: "center",
      width: 300,
    },
    {
      title: "MONTO BRUTO",
      dataIndex: "bruto",
      key: "bruto",
      align: "right",
      width: 100,
      render: (text) => napformat(text)
    },
    {
      title: "RETENCIÓN",
      dataIndex: "retencion",
      key: "retencion",
      align: "right",
      width: 100,
      render: (text) => napformat(text)
    },
    {
      title: "LIQUIDO",
      dataIndex: "neto",
      key: "neto",
      align: "right",
      width: 100,
      render: (text) => napformat(text)
    },

  ];

  const getReport = async () => {
    try {
      setLoading(true);

      let obj = { kind };
      if (kind === 'mensual') obj = { kind, mes }
      else if (kind === "intervalos") obj = { kind, desde, hasta }

      const response = await fetch(
        `${apiReportes}/rr-hh/${sede}/${sedeactual.company().url}/${sedeactual.getAnio()}`,
        {
          method: "POST",
          body: JSON.stringify(obj),
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        }
      );

      let data = await response.json();
      if (response.status === 200) setData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page
      helper="REPORTES"
      title="REPORTE DE RECIBOS POR HONORARIOS"
      itemComponent={
        <>
          <Button variant="contained" color="primary" onClick={() => window.history.back()}>
            VOLVER
          </Button>
          &nbsp;&nbsp;
          <ExportarTXT
            name={`0601${moment([sedeactual.getAnio(), mes - 1, 1]).format('YYYYMM')}${sedeactual.company().ruc}`}
            endpoint={`rr-hh/export-4ta/${sede}/${sedeactual.company().url}/${sedeactual.getAnio()}`}
            body={{
              mes: mes,
              kind: kind,
              desde: kind === "intervalos" ? Number(desde) : null,
              hasta: kind === "intervalos" ? Number(hasta) : null,
            }}
            method="POST"
            disabled={data.length === 0 || kind !== 'mensual'}
            small={false}
            api={4}
            title=".4TA"
            extension="4TA"
          />
          &nbsp;&nbsp;
          <ExportarTXT
            name={`0601${moment([sedeactual.getAnio(), mes - 1, 1]).format('YYYYMM')}${sedeactual.company().ruc}`}
            endpoint={`rr-hh/export-ps4/${sede}/${sedeactual.company().url}/${sedeactual.getAnio()}`}
            body={{
              mes: mes,
              kind: kind,
              desde: kind === "intervalos" ? Number(desde) : null,
              hasta: kind === "intervalos" ? Number(hasta) : null,
            }}
            method="POST"
            disabled={data.length === 0 || kind !== 'mensual'}
            small={false}
            api={4}
            title=".PS4"
            extension="PS4"
          />
        </>
      }
    >
      <Spin spinning={loading}>
        <Grid container spacing={1}>

          {(sedeactual.getRol() === "supernap" ||
            sedeactual.getRol() === "superadmin") && (
              <Grid item xs={12} sm={6} md={6} lg={6} xl={2}>
                <FormHelperText>SEDE</FormHelperText>
                <SelectSedes
                  defaultValue={sede}
                  data={sedes}
                  onChangeSelect={(e) => {
                    setSede(e);
                  }}
                />
              </Grid>
            )}
          <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
            <FormHelperText>MODO</FormHelperText>
            <FormControl variant="outlined" size="small" fullWidth>
              <Select value={kind} onChange={(e) => setKind(e.target.value)}>
                <MenuItem value="mensual">Mensual</MenuItem>
                <MenuItem value="intervalos">Intervalos</MenuItem>
                <MenuItem value="anual">Anual</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {kind === "intervalos" && (
            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
              <FormHelperText>DESDE</FormHelperText>
              <FormControl variant="outlined" size="small" fullWidth>
                <Select value={desde} onChange={(e) => setDesde(e.target.value)}>
                  {ranges.map((e) => (
                    <MenuItem value={e.value}>{e.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {kind === "intervalos" && (
            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
              <FormHelperText>HASTA</FormHelperText>
              <FormControl variant="outlined" size="small" fullWidth>
                <Select value={hasta} onChange={(e) => setHasta(e.target.value)}>
                  {ranges.map((e) => {
                    if (e.value > desde) {
                      return <MenuItem value={e.value}>{e.name}</MenuItem>;
                    }
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
          {kind === "mensual" && (
            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
              <FormHelperText>MES</FormHelperText>
              <FormControl variant="outlined" size="small" fullWidth>
                <Select value={mes} onChange={(e) => setMes(e.target.value)}>
                  <MenuItem value="1">Enero</MenuItem>
                  <MenuItem value="2">Febrero</MenuItem>
                  <MenuItem value="3">Marzo</MenuItem>
                  <MenuItem value="4">Abril</MenuItem>
                  <MenuItem value="5">Mayo</MenuItem>
                  <MenuItem value="6">Junio</MenuItem>
                  <MenuItem value="7">Julio</MenuItem>
                  <MenuItem value="8">Agosto</MenuItem>
                  <MenuItem value="9">Setiembre</MenuItem>
                  <MenuItem value="10">Octubre</MenuItem>
                  <MenuItem value="11">Noviembre</MenuItem>
                  <MenuItem value="12">Diciembre</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
            <FormHelperText />
            <Button
              onClick={() => getReport()}
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
            >
              <Search />
              BUSCAR
            </Button>
          </Grid>
          <Grid item xs={12}>

            <CustomTable columns={columns} data={data} pagination={false} />
          </Grid>
        </Grid>
      </Spin>
    </Page>
  );
};

export default ReporteVentas;
