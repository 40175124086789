import React from "react";
import { Redirect, Route } from "react-router-dom";
import NotAuthorized from "./Errors/Page401";
import sedeactual from "../config/sedeactual";
import DashboardLayout from "./Global/components/DashboardLayout";
// import AuthenticationService from "src/auth/AuthenticationService";

const AppRoutes = (props) => {
  let {
    component: Component,
    path,
    listRoles,
    isPrivate,
    exact,
    module,
    ...rest
  } = props;

  let flag = false;
  let modules = [];
//  if (sedeactual.company()?.url === 'ym')  {
//   AuthenticationService.logout().then(() => {
//       window.location.href = `/ym/login`;
//     });
//  }
  if (sedeactual.modules() && sedeactual.sede_id()) {
    modules = sedeactual.modules()[sedeactual.sede_id()];
    if (modules && modules.includes(module)) flag = true;
  }
  if (sedeactual.getRol() === "supernap") flag = true;

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        isPrivate && !Boolean(sedeactual.token()) ? (
          <Redirect to={{ pathname: "/nap" }} />
        ) : isPrivate ? (
          listRoles.includes(sedeactual.getRol()) &&
          (module === "GLOBAL" || flag) ? (
            <DashboardLayout modules={modules}>
              <Component {...props} />
            </DashboardLayout>
          ) : (
            <NotAuthorized />
          )
        ) : (
          <Component {...props} />
        )
      }
      {...rest}
    />
  );
};

export default AppRoutes;
