import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import _ from "lodash/fp";
import useForm from "react-hook-form";
import axios from "axios";
import { apiContable } from "../../../config/api.contable";
import { notification } from "antd";

const ModalCompany = (props) => {
  const {
    visible,
    setVisible,
    setLoading,
    obtainCompanies,
    edit = false,
    dataEdit = false,
  } = props;
  const [logoImage, setLogoImage] = useState(null);
  const { handleSubmit, register, errors } = useForm();

  const sendData = async (data) => {
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("name", data.name);
      formData.append("url", data.url);
      formData.append("ruc", data.ruc);
      formData.append("address", data.address);
      formData.append("phone", data.phone);
      formData.append("active", true);
      if (logoImage) formData.append("logo", logoImage);

      await axios.post(`${apiContable}/company`, formData);
      notification["success"]({
        message: "¡ÉXITO!",
        description: "Se agregó correctamente la compañia",
      });
      obtainCompanies();
      setVisible(false);
    } catch (error) {
      notification["error"]({
        message: "¡ERROR!",
        description: "No se agregó el registro.",
      });
    } finally {
      setLoading(false);
    }
  };

  const updateData = async (data) => {
    if (logoImage || dataEdit.logo) {
      try {
        let formData = new FormData();
        formData.append("name", data.name);
        formData.append("address", data.address);
        formData.append("phone", data.phone);
        if (logoImage) {
          formData.append("logo", logoImage);
        }

        await axios.put(`${apiContable}/company/${dataEdit._id}`, formData);
        notification["success"]({
          message: "¡ÉXITO!",
          description: "Se actualizó correctamente la compañia",
        });
        setVisible({ open: false });
        obtainCompanies();
      } catch (error) {
        notification["error"]({
          message: "¡ERROR!",
          description: "No se actualizó el registro.",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={visible} onClose={() => setVisible(false)} maxWidth="md">
      <DialogTitle>
        <Typography align="center" variant="h2">
          <b>{edit ? "EDITAR" : "AGREGAR"} COMPAÑIA</b>
        </Typography>
      </DialogTitle>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(edit ? updateData : sendData)}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="NOMBRE"
                name="name"
                fullWidth
                variant="outlined"
                defaultValue={dataEdit.name}
                inputRef={register({
                  required: true,
                  minLength: 3,
                  maxLength: 100,
                })}
              />
              <FormHelperText error>
                {_.get("name.type", errors) === "required" &&
                  "El nombre es requerido."}
                {_.get("name.type", errors) === "minLength" &&
                  "La longitud mínima son 3 caracteres."}
                {_.get("name.type", errors) === "maxLength" &&
                  "La longitud máxima son 100 caracteres."}
              </FormHelperText>
            </Grid>
            {edit ? null : (
              <Grid item xs={12} md={6}>
                <TextField
                  label="URL"
                  name="url"
                  fullWidth
                  variant="outlined"
                  inputRef={register({
                    required: true,
                    minLength: 3,
                    maxLength: 100,
                    pattern: /^[A-Z0-9]+$/i,
                  })}
                />
                <FormHelperText error>
                  {_.get("url.type", errors) === "required" &&
                    "Campo requerido."}
                  {_.get("url.type", errors) === "minLength" &&
                    "La longitud mínima son 3 caracteres."}
                  {_.get("url.type", errors) === "maxLength" &&
                    "La longitud máxima son 100 caracteres."}
                  {_.get("url.type", errors) === "pattern" &&
                    "Solo se admite letras y números enteros."}
                </FormHelperText>
              </Grid>
            )}
            {edit ? null : (
              <Grid item xs={12} md={6}>
                <TextField
                  label="RUC"
                  name="ruc"
                  fullWidth
                  variant="outlined"
                  inputRef={register({
                    required: true,
                    minLength: 11,
                    maxLength: 15,
                    pattern: /^[0-9]+$/i,
                  })}
                />
                <FormHelperText error>
                  {_.get("ruc.type", errors) === "required" &&
                    "Campo requerido."}
                  {_.get("ruc.type", errors) === "minLength" &&
                    "La longitud mínima son 11 caracteres."}
                  {_.get("ruc.type", errors) === "maxLength" &&
                    "La longitud máxima son 15 caracteres."}
                  {_.get("ruc.type", errors) === "pattern" &&
                    "El formato ingresado no es admitido."}
                </FormHelperText>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <TextField
                label="DIRECCIÓN"
                name="address"
                fullWidth
                defaultValue={dataEdit.address}
                variant="outlined"
                inputRef={register({
                  required: true,
                  minLength: 3,
                  maxLength: 100,
                })}
              />
              <FormHelperText error>
                {_.get("address.type", errors) === "required" &&
                  "Campo requerido."}
                {_.get("address.type", errors) === "minLength" &&
                  "La longitud mínima son 3 caracteres."}
                {_.get("address.type", errors) === "maxLength" &&
                  "La longitud máxima son 100 caracteres."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="TELÉFONO / CELULAR"
                name="phone"
                fullWidth
                variant="outlined"
                defaultValue={dataEdit.phone}
                inputRef={register({
                  required: true,
                  minLength: 6,
                  maxLength: 15,
                })}
              />
              <FormHelperText error>
                {_.get("phone.type", errors) === "required" &&
                  "Campo requerido."}
                {_.get("phone.type", errors) === "minLength" &&
                  "La longitud mínima son 6 caracteres."}
                {_.get("phone.type", errors) === "maxLength" &&
                  "La longitud máxima son 15 caracteres."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="LOGO"
                fullWidth
                variant="outlined"
                type="file"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setLogoImage(e.target.files[0])}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setVisible(false)}
          >
            CANCELAR
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {edit ? "ACTUALIZAR" : "GUARDAR"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalCompany;
