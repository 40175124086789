import React from "react";
import Caja from "../Voucher/Caja.jsx";
import { notification, Icon } from "antd";
import sedeactual from "../../config/sedeactual";
import { sendVoucher } from "../../api/api.contable.js";

const CajaRapida = () => {
  const sendData = async (voucher) => {
    const res = await sendVoucher(voucher, sedeactual.get());
    if (res) {
      notification.open({
        message: "Crear Voucher",
        description: "El Voucher fue creado satisfactoriamente.",
        icon: <Icon type="check" style={{ color: "#49E02D" }} />,
        key: `open${Date.now()}`,
      });
    } else {
      notification.open({
        message: "Crear Voucher",
        description: "Ocurrio un error al crear el Voucher.",
        icon: <Icon type="close" style={{ color: "#FE2E27" }} />,
        key: `open${Date.now()}`,
      });
    }
  };

  return (
    <Caja
      caja={false}
      edit={false}
      forced={true}
      sendFormData={(e) => sendData(e)}
    />
  );
};

export default CajaRapida;
