import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import Page from "../../components/Page";
import { getCostFormula } from "../../api/api.contable";
import sedeactual from "../../config/sedeactual";
import TableCostFormula from "./components/CostFormula/TableCostFormula";
import AddCostFormula from "./components/CostFormula/AddCostFormula";
import { notification } from "antd";
import LoaderBackdrop from "../../components/Global/LoaderBackdrop";

const CostFormulas = () => {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      let res = await getCostFormula(sedeactual.getAnio(), sedeactual.get());
      setData(res);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al obtener la información.",
        description: `${error}`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Page
      helper="FORMULAS DE COSTOS"
      title="FORMULAS DE COSTOS"
      itemComponent={
        <Button
          variant="contained"
          color="primary"
          onClick={() => setVisible(true)}
          size="large"
          disabled={sedeactual.getRol() === "usuario"}
        >
          AGREGAR
        </Button>
      }
    >
      <TableCostFormula data={data} />
      {visible && (
        <AddCostFormula
          visible={visible}
          setVisible={setVisible}
          getData={getData}
          data={data}
          setLoading={setLoading}
        />
      )}
      {loading && <LoaderBackdrop />}
    </Page>
  );
};

export default CostFormulas;
