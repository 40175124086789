import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import napformat from "../../../config/napformat";

const useStyles = makeStyles({
  table: {
    minWidth: 768,
  },
  title: {
    backgroundColor: "#01579b50",
  },
  total: {
    fontWeight: "bold",
    background: '#01579b15',
    borderBottom: '2px solid #0688d1'
  },
  bd: {
    border: "1px solid #E8E8E8",
  },
});

const TableDetail = (props) => {
  const { data, type } = props;
  const cls = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={cls.table} aria-label="simple table" size="small">
        <TableHead>
        <TableRow>
            <TableCell className={cls.bd} align="center">CÓDIGO</TableCell>
            <TableCell className={cls.bd} align="center">DESCRIPCIÓN</TableCell>
            <TableCell className={cls.bd} align="center">MONTO PRESUPUESTADO</TableCell>
            <TableCell className={cls.bd} align="center">{type === "ingreso" ? "INGRESADO" : "GASTADO"} AL MES ANTERIOR</TableCell>
            <TableCell className={cls.bd} align="center">{type === "ingreso" ? "INGRESADO" : "GASTADO"} AL MES ACTUAL</TableCell>
            <TableCell className={cls.bd} align="center">TOTAL {type === "ingreso" ? "INGRESADO" : "GASTADO"}</TableCell>
            <TableCell className={cls.bd} align="center">SALDO A LA FECHA</TableCell>
            <TableCell className={cls.bd} align="center">PORCENTAJE {type === "ingreso" ? "INGRESO" : "GASTO"}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? (
            data.map((row) => (
              <TableRow className={row.title ? cls.title : row.number ? cls.total : ""}>
                <TableCell className={cls.bd} align="center">{row.code}</TableCell>
                <TableCell className={cls.bd} colSpan={row.title ? 7 : 1}>{row.description.toUpperCase()}</TableCell>
                {row.title ? <></> : (
                  <>
                    <TableCell className={cls.bd} align="right">{napformat(row.budgeted_amount)}</TableCell>
                    <TableCell className={cls.bd} align="right">{napformat(row.expendedPreviousMonth)}</TableCell>
                    <TableCell className={cls.bd} align="right">{napformat(row.depositMonth)}</TableCell>
                    <TableCell className={cls.bd} align="right">{napformat(row.totalEntered)}</TableCell>
                    <TableCell className={cls.bd} align="right">{napformat(row.balanceToday)}</TableCell>
                    <TableCell className={cls.bd} align="right">{napformat(row.rate)}%</TableCell>
                  </>
                )}
              </TableRow>
            ))
          ) : (
            <TableRow className={cls.title}>
              <TableCell className={cls.bd} colSpan={8} align="center">NO SE ENCONTRÓ INFORMACIÓN</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableDetail;
