import React, { useState } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import axios from "axios";
import { apiCuentas } from "src/config/api.contable";
import sedeactual from "src/config/sedeactual";
import { notification } from "antd";
import moment from "moment";
import { AttachFile } from "@material-ui/icons";

const Import = ({ setDetails, setLoading, data }) => {
  const [file, setFile] = useState(null);

  const sendFile = async () => {
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("correlative", data.correlativo);
      formData.append("customer", data.customer);
      formData.append("headquarters_id", sedeactual.get());
      formData.append("numComprobante", "");
      formData.append("operation_date", moment(data.date).format("YYYY-MM-DD"));
      formData.append("origin", data.origen);
      formData.append("period", data.periodo);
      formData.append("serie", data.serie);
      formData.append("t_doc", data.type);
      formData.append("voucher_id", 0);
      formData.append("year", sedeactual.getAnio());

      const res = await axios.post(
        `${apiCuentas}/accounting-plan/importar-voucher/${sedeactual.get()}/${
          sedeactual.company().url
        }/${sedeactual.getAnio()}`,
        formData
      );
      setDetails(res.data);
      setFile(null);
    } catch (error) {
      notification["error"]({
        message: `Ocurrió un error al realizar la operación`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={1} style={{ width: 300 }}>
      <Grid item xs={12} md={7}>
        <Button component="label" color="primary" fullWidth variant="outlined">
          <input
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            style={{ display: "none" }}
            onChange={(e) => setFile(e.target.files[0])}
          />
          SELECCIONAR
        </Button>
        {file && (
          <Typography component="div" variant="caption" align="center">
            {file.name}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} md={5}>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={sendFile}
          disabled={
            !file || !data.origen || !data.periodo || !data.date || !data.type
          }
        >
          IMPORTAR
        </Button>
      </Grid>
      <Grid item xs={12}>
        <a
          href="https://filesym.s3.us-east-2.amazonaws.com/MODELO-IMPORTAR-VOUCHER.xlsx"
          target="_blank"
        >
          <AttachFile /> Plantilla de importación de voucher
        </a>
      </Grid>
    </Grid>
  );
};

export default Import;
