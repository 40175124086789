import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import Page from "src/components/Page";
import { ModalIngresos, TableIngresos, ModalDetails } from "./components";
import { notification } from "antd";
import { getIngresos } from "src/utils/req.ingresos";
import ModalConfirmDelete from "src/components/ModalConfirmDelete";
import { apiContable } from "src/config/api.contable";
import { typesDocuments } from "src/utils/constants";
import sedeactual from "src/config/sedeactual";

const Ingresos = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({ open: false, data: null });
  const [del, setDel] = useState({ open: false, id: null });
  const [details, setDetails] = useState({ open: false, data: {} });

  useEffect(() => {
    obtainData();
  }, []);

  const obtainData = async () => {
    try {
      setLoading(true);
      const res = await getIngresos();
      const result = res.map((item) => ({
        ...item,
        concepto:
          typesDocuments.find((td) => td.key === item.observation)?.label ??
          "-",
      }));
      setData([...result.reverse()]);
    } catch (error) {
      notification["error"]({
        message: `Ocurrió un error al realizar la operación`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page
      helper="INGRESOS"
      title="REGISTRO DE INGRESOS"
      itemComponent={
        <Button
          variant="contained"
          color="primary"
          onClick={() => setModal({ open: true, data: null })}
          disabled={sedeactual.getRol() === "usuario"}
        >
          REGISTRAR
        </Button>
      }
    >
      <TableIngresos
        data={data}
        loading={loading}
        setModal={setModal}
        setDel={setDel}
        setDetails={setDetails}
        head={false}
      />
      {modal.open && (
        <ModalIngresos
          open={modal.open}
          setOpen={setModal}
          data={modal.data}
          refreshFunction={obtainData}
          loading={loading}
          setLoading={setLoading}
        />
      )}
      {del.open && (
        <ModalConfirmDelete
          visible={del.open}
          setVisible={setDel}
          reload={obtainData}
          endpoint={`${apiContable}/entry/${del.id}`}
          title="¿Está seguro que desea eliminar este registro?"
          subtitle="En en consideración que el registro ya no estará disponible."
          disable={loading}
        />
      )}
      {details.open && (
        <ModalDetails
          open={details.open}
          setOpen={setDetails}
          data={details.data}
        />
      )}
    </Page>
  );
};

export default Ingresos;
