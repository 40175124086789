import React from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));
const Exportar = (props) => {
  const classes = useStyles();
  const buildInit = () => {
    return {
      method: "POST",
      body: JSON.stringify({
        period: props.periodo,
        digit: props.digitos,
      }),
      headers: {
        "Content-Type": "application/json",
        /* Authorization: UserServices.getAuthorization() */
      },
    };
  };

  const onClickLink = () => {
    if (!(props.ruta.toString().includes("null") || props.ruta.toString().includes("undefined"))) {
      fetch(props.ruta, buildInit()).then(
        (response) => {
          if (response.ok) {
            response.blob().then((blob) => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement("a");
              a.href = url;
              a.download = `${props.nombre}.xlsx`;
              a.click();
            });
          }
        }
      );
    }
    //Descargar archivo
  };

  return (
    <>
      {props.small ? (
        <Fab
          className={classes.margin}
          size="small"
          onClick={onClickLink}
          style={{ backgroundColor: "#00bfa5", color: "#ffff" }}
        >
          <GetAppIcon />
        </Fab>
      ) : (
        <Button
          variant="contained"
          className="{classes.button}"
          startIcon={<GetAppIcon />}
          onClick={onClickLink}
          style={{ backgroundColor: "#00bfa5", color: "#ffff" }}
        >
          Exportar
        </Button>
      )}
    </>
  );
};

export default Exportar;
