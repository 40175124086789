import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Box,
  Button,
  ButtonGroup,
  Grid,
} from "@material-ui/core";
import { notification } from "antd";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import _ from "lodash/fp";
import AddIcon from "@material-ui/icons/Add";
import Page from "../../components/Page";
import AddCategory from "./components/Categorias/components/AddCategory";
import TableCategory from "./components/Categorias/components/TableCategory";
import sedeactual from "../../config/sedeactual";
import Spinner from "../../components/Global/Spinner";
import { useAccounts } from "../../hook/useAccouts";
import FileUploadIcon from "@material-ui/icons/CloudUpload";
import { getCategories, uploadCategories } from "./requests";
import FileIcon from "@material-ui/icons/AttachFileOutlined";
import Exportar from "../../components/Global/ExportarExcel";

const Categorias = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { dataCuentas } = useAccounts({
    sede: sedeactual.get(),
    year: sedeactual.getAnio(),
  });

  useEffect(() => {
    obtainData();
  }, []);

  const obtainData = async () => {
    setLoading(true);
    try {
      const res = await getCategories();
      setData(res);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al obtener la información.",
        description: `${error}`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page title="CATEGORIAS" helper="INVENTARIO">
      <Container maxWidth="lg">
        <TableContainer>
          <Table style={{ border: "1px solid #f2f2f2" }} size="small">
            <TableBody>
              {data.length > 0 ? (
                data.map((res, index) => (
                  <Row
                    row={res}
                    index={index}
                    reloadFunction={obtainData}
                    setLoading={setLoading}
                    loading={loading}
                    accounts={dataCuentas}
                  />
                ))
              ) : (
                <Box p={2}>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      align="center"
                    >
                      <b>NO SE ENCONTRÓ INFORMACIÓN</b>
                    </Typography>
                  )}
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Grid container spacing={1} justify="space-between" alignItems="center">
          <Grid item>
            <a
              href="https://filesym.s3.us-east-2.amazonaws.com/PLANTILLA_IMPORTAR_CATEGORIAS_INVENTARIO.xlsx"
              target="_blank"
              rel="noreferrer"
            >
              <FileIcon />
              Plantilla de importación de categorías
            </a>
          </Grid>
          <Grid item>
            <Exportar
              name={`REPORTE-CATEGORIAS-INVENTARIO-${Date.now()}`}
              endpoint={`export-categories/${sedeactual.get()}/${
                sedeactual.company().url
              }`}
              body={{}}
              method="POST"
              small={false}
              api={2}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Categorias;

function Row(props) {
  const { row, index, reloadFunction, loading, setLoading, accounts } = props;
  const [open, setOpen] = React.useState(false);
  const [modalAddCategory, setModalAddCategory] = useState(false);

  const ImportData = async (tipo, file) => {
    if (tipo && file) {
      setLoading(true);
      try {
        let formData = new FormData();
        formData.append("file", file);
        await uploadCategories(tipo, formData);
        reloadFunction();
        notification["success"]({
          message: "Las categorias se importaron exitosamente.",
        });
      } catch (error) {
        notification["error"]({
          message: "Ocurrió un error al subir el archivo",
          description: `${error}`,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      {modalAddCategory && (
        <AddCategory
          visible={modalAddCategory}
          setVisible={setModalAddCategory}
          token={sedeactual.token()}
          reloadFunction={reloadFunction}
          typeCategory={row.type}
          nameCategory={row.name}
          setLoading={setLoading}
          accounts={accounts}
        />
      )}
      <TableRow>
        <TableCell
          style={{ padding: 0, margin: 0 }}
          onClick={() => setOpen(!open)}
        >
          <IconButton color="primary">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell align="right">
          <ButtonGroup disabled={loading} variant="contained">
            <Button
              color="primary"
              onClick={() => setModalAddCategory(true)}
              variant="outlined"
              disabled={sedeactual.getRol() === "usuario"}
            >
              <AddIcon />
            </Button>
            <Button component="label" color="primary">
              <input
                type="file"
                onChange={(file) => ImportData(row.type, file.target.files[0])}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                style={{ display: "none" }}
              />
              <FileUploadIcon />
            </Button>
          </ButtonGroup>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableCategory
              dataTable={row.categories}
              reloadFunction={reloadFunction}
              setLoading={setLoading}
              loading={loading}
              index={index}
              accounts={accounts}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
