import React, { useState, useEffect } from "react";
import { Modal, notification } from "antd";
import {
  Grid,
  FormHelperText,
  TextField,
  Card,
  CardContent,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@material-ui/core";
import {
  getAccountPlan,
  getDateExchange_rate,
  getAccountAssistantById,
  getTypeCoins,
  sendProvicioning,
} from "src/api/api.contable";
import sedeactual from "src/config/sedeactual";
import SelectCuenta from "src/components/Selects/SelectCuenta";
import SelectCostFormula from "src/components/Selects/SelectCostFormula";
import SelectPeriodo from "src/components/Selects/SelectPeriodo";
import SelectCC from "src/components/Selects/SelectCC";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import numeral from "numeral";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/es";
import napformat from "src/config/napformat";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import SelectOrigen from "src/components/Selects/SelectOrigen";
import _ from "lodash/fp";

const ModalAddProvisioning = (props) => {
  const {
    visible,
    setVisible,
    newVoucher,
    setNewVoucher,
    counterDetail,
    refreshFunction,
    restante,
    pagado,
    origins,
    defaultOrigin,
  } = props;
  const [moneda, setMoneda] = useState("1");
  const [cuenta, setCuenta] = useState(null);
  const [cc, setCC] = useState(null);
  const [CambiodelDia, setCambiodelDia] = useState(1);
  const [tipoDocM, setTipoDocM] = useState(null);
  const [tipo, setTipo] = useState(null);
  const [costo, setcosto] = useState(null);
  const [monto, setMonto] = useState("");
  const [cuenta5d, setCuentas5d] = useState([]);
  const [datacc, setdatacc] = useState([]);
  const [exchange_Rate, setexchange_Rate] = useState(null);
  const [TipodeCambio, setTipodeCambio] = useState([]);
  const [sum, setSum] = useState({ debe: 0, haber: 0, diff: 0 });
  const [loading, setLoading] = useState(false);
  const [glosa, setGlosa] = useState(null);
  const [allCorrect, setAllCorrect] = useState(false);
  const [focus, setfocus] = useState(false);
  const [periodo, setPeriodo] = useState(moment().month() + 1);
  const [operationDate, setOperationDate] = useState(
    moment().format("YYYY-MM-DDTHH:mm:ss")
  );
  const [origin, setOrigin] = useState(defaultOrigin);
  const [val, setVal] = useState({
    account: false,
    glosa: false,
    amount: false,
  });

  const initialConf = () => {
    let countFails = false;
    counterDetail.map((el) => {
      if (counterDetail[0].type_detail !== el.type_detail) countFails = true;
      delete el._id;
      delete el._id;
      newVoucher.initial = false;
      newVoucher.details.push({
        ...el,
        account: el.account._id,
        cuenta: el.account.number,
        amount: el.amount - pagado,
        type_detail: el.type_detail === "DEBE" ? "HABER" : "DEBE",
      });
    });
    setTipo(counterDetail[0].type_detail);
    if (countFails) {
      notification["warning"]({
        message:
          "El aprovisionamiento solo debe contener vouchers del mismo tipo (DEBE ó HABER)",
      });
      setVisible(false);
    }
  };

  useEffect(() => {
    obtenerPlan();
    obtainCoinType();
    getExchange_Rate();
    initialConf();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAuxAccountList();
  }, [cuenta]);

  useEffect(() => {
    if (newVoucher.details) {
      let sum1 = 0;
      let sum2 = 0;
      newVoucher.details.forEach((det) => {
        if (det.type_detail === "HABER") {
          sum2 += Number(det.amount);
        } else {
          sum1 += Number(det.amount);
        }
      });
      setSum({
        debe: sum1,
        haber: sum2,
        diff:
          sum2 < sum1
            ? Number((sum1 - sum2).toFixed(2))
            : Number((sum2 - sum1).toFixed(2)),
      });
    }
  }, [newVoucher]);

  const removeDetail = (index) => {
    let tmpDelVoucher = { ...newVoucher };
    tmpDelVoucher.details.splice(index, 1);
    setNewVoucher({ ...tmpDelVoucher });
  };

  const addDetailVoucher = () => {
    if (Boolean(cuenta)) {
      if (Boolean(glosa)) {
        if (Boolean(monto)) {
          let finderAccount = cuenta5d.find((el) => el._id === cuenta);
          let finderRepeat = newVoucher.details.find(
            (el) => el.cuenta === finderAccount.number
          );
          if (!Boolean(finderRepeat)) {
            let tmpAmount = parseFloat(
              Number(monto) * Number(CambiodelDia)
            ).toFixed(2);
            if (Number(sum.diff) >= Number(tmpAmount)) {
              let newDetail = {
                account: finderAccount._id,
                cuenta: finderAccount.number,
                amount: tmpAmount,
                aux_account: cc,
                comment: glosa,
                cost: costo,
                exchange_rate: CambiodelDia,
                period: periodo,
                periodo: moment().format("YYYY-MM-DD"),
                ref_payment: tipoDocM,
                tipoDocumento: tipoDocM,
                type_currency: moneda,
                type_detail: tipo,
              };
              newVoucher.details.push(newDetail);
              setNewVoucher({ ...newVoucher });
              ClearInputs();
              setAllCorrect(true);
              setfocus({ ...true });
            } else {
              notification["warning"]({
                message: "Validación...",
                description: "EL MONTO NO DEBE SER SUPERIOR.",
              });
              setAllCorrect(false);
            }
          } else {
            notification["warning"]({
              message: "Validación...",
              description: "La cuenta ya fué ingresada.",
            });
          }
        } else {
          setVal({ ...val, amount: true });
        }
      } else {
        setVal({ ...val, glosa: true });
      }
    } else {
      setVal({ ...val, account: true });
    }
  };

  const ClearInputs = () => {
    setCuenta(null);
    setcosto(null);
    setTipoDocM(null);
    setMonto("");
  };

  const Dataform = async () => {
    if (allCorrect) {
      setLoading(true);
      try {
        if (newVoucher.type_provisioning === "DEBE") {
          newVoucher.details[0].amount = sum.debe;
        } else {
          newVoucher.details[0].amount = sum.haber;
        }
        newVoucher["operation_date"] = operationDate;
        newVoucher["period"] = periodo;
        newVoucher["identify"] = newVoucher.n_voucher;
        newVoucher["diferencia"] = sum.diff;
        newVoucher["origin"] = origin;

        let id = newVoucher._id;
        delete newVoucher._id;

        await sendProvicioning(newVoucher, sedeactual.get(), id);
        notification["success"]({
          message: "Éxito!",
          description: `El voucher se creó correctamente.`,
        });
        refreshFunction();
        setVisible(false);
      } catch (error) {
        notification["warning"]({
          message: "Error!",
          description: `Ocurrió un error al agregar el voucher. ${error}`,
        });
      } finally {
        setLoading(false);
      }
    } else {
      notification["warning"]({
        message: "Validación...",
        description: "No todo esta correcto :(",
      });
    }
  };

  const getExchange_Rate = async () => {
    let res = await getDateExchange_rate(moment().format("YYYY-MM-DD"));
    setexchange_Rate(res);
  };

  const obtenerPlan = async () => {
    let res = await getAccountPlan(sedeactual.get(), sedeactual.getAnio());
    let result = res.filter(
      (e) =>
        e.number.toString().length === sedeactual.digits() && e.number < 900000
    );
    setCuentas5d(result);
  };
  const getAuxAccountList = async (cuenta_id) => {
    let res = await getAccountAssistantById(cuenta_id);
    setdatacc(res.data);
  };

  const obtainCoinType = async () => {
    try {
      let result = await getTypeCoins();
      setTipodeCambio(result);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se pudo obtener los tipos de monedas.",
      });
    }
  };

  function updateMoneda(e) {
    if (e.target.value === "2") {
      let found = cuenta5d.find((e) => e._id == cuenta);
      if (found.number.toString().startsWith("7")) {
        setCambiodelDia(exchange_Rate.precio_compra);
      } else {
        setCambiodelDia(exchange_Rate.precio_venta);
      }
    } else {
      setCambiodelDia(1);
    }

    setMoneda(e.target.value);
  }

  const OptionsTipo = () => (
    <React.Fragment>
      {TipodeCambio.map((cliente, index) => (
        <option key={index} value={cliente.id}>
          {cliente.alp}-{cliente.currency}
        </option>
      ))}
    </React.Fragment>
  );

  function updateMonto(e) {
    if (Number(e.target.value) <= 999999999999) {
      setMonto(e.target.value);
      setVal({ ...val, amount: false });
    }
  }

  return (
    <Modal
      title={
        <Grid container spacing={2}>
          <Grid item xs={12} align="center">
            <Typography variant="h4">AGREGAR VOUCHER</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormHelperText>PERIODO</FormHelperText>
            <SelectPeriodo
              edit={false}
              defaultValue={periodo}
              onChangeSelect={(e) => setPeriodo(e)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormHelperText>ORIGEN</FormHelperText>
            <SelectOrigen
              defaultValue={origin}
              onChangeSelect={setOrigin}
              data={origins}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormHelperText>FECHA DE OPERACIÓN</FormHelperText>
            <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                inputVariant="outlined"
                size="small"
                format="dd/MM/yyyy"
                margin="normal"
                style={{ margin: 0 }}
                maxDate={`${sedeactual.getAnio() + 1}-01-01`}
                onChange={(a) =>
                  setOperationDate(moment(a).format("YYYY-MM-DDTHH:mm:ss"))
                }
                value={operationDate}
                fullWidth
                placeholder="DD/MM/YYYY"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                maxDateMessage={`El rango máximo de fecha es hasta el 31 diciembre del ${sedeactual.getAnio()}`}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      }
      closable={false}
      visible={visible}
      footer={[
        <button
          className="btn btn-primary"
          onClick={addDetailVoucher}
          disabled={loading}
        >
          {loading ? "CARGANDO..." : "AGREGAR"}
        </button>,
        <button
          type="button"
          onClick={() => setVisible(false)}
          className="btn btn-success"
          disabled={loading}
        >
          {loading ? "CARGANDO..." : "CANCELAR"}
        </button>,
        <button
          type="button"
          onClick={ClearInputs}
          className="btn btn-warning"
          disabled={loading}
        >
          {loading ? "CARGANDO..." : "LIMPIAR"}
        </button>,
        <button
          type="button"
          onClick={Dataform}
          className="btn btn-info"
          disabled={loading}
        >
          {loading ? "CARGANDO..." : "GRABAR"}
        </button>,
      ]}
      width={1300}
      style={{ maxWidth: "99%" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={7}>
          <TableContainer style={{ maxHeight: 450 }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>CUENTA</TableCell>
                  <TableCell>GLOSA</TableCell>
                  <TableCell>MONEDA</TableCell>
                  <TableCell>REF. M. PAGO</TableCell>
                  <TableCell>DEBE</TableCell>
                  <TableCell>HABER</TableCell>
                  <TableCell>ACCIONES</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newVoucher.details.map((el, ind) => (
                  <TableRow key={ind}>
                    <TableCell>{el.cuenta}</TableCell>
                    <TableCell>{el.comment}</TableCell>
                    <TableCell>
                      {el.type_currency.toString() === "1" ? "PEN" : "USD"}
                    </TableCell>
                    <TableCell>{el.ref_payment}</TableCell>
                    <TableCell>
                      {el.type_detail === "DEBE"
                        ? numeral(el.amount).format("0,0.00")
                        : null}
                    </TableCell>
                    <TableCell>
                      {el.type_detail === "HABER"
                        ? numeral(el.amount).format("0,0.00")
                        : null}
                    </TableCell>
                    <TableCell>
                      {newVoucher.details.length === 1 ? null : (
                        <IconButton
                          size="small"
                          onClick={() => removeDetail(ind)}
                        >
                          <CloseIcon style={{ color: "#ff1100" }} />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4}>TOTALES</TableCell>
                  <TableCell>S/{napformat(sum.debe)}</TableCell>
                  <TableCell>S/{napformat(sum.haber)}</TableCell>
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4}>
                    <b>DIFERENCIA</b>
                  </TableCell>
                  <TableCell colSpan={2} align="center">
                    <b>S/{napformat(sum.diff)}</b>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <Card
            onKeyPress={(evt) => {
              if (evt.key === "Enter") {
                addDetailVoucher();
              }
            }}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormHelperText error={val.account}>
                    {val.account ? "CUENTA INVÁLIDA" : "CUENTA"}
                  </FormHelperText>
                  <SelectCuenta
                    caja={true}
                    focus={focus}
                    defaultValue={cuenta}
                    onChangeSelect={(e) => {
                      setCuenta(e);
                      setCC(null);
                      setVal({ ...val, account: false });
                    }}
                    data={cuenta5d}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormHelperText>FORMULA DE COSTOS</FormHelperText>
                  <SelectCostFormula
                    defaultValue={costo}
                    onChangeSelect={(e) => {
                      setcosto(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormHelperText>AUXILIAR DE CUENTAS</FormHelperText>
                  <SelectCC
                    defaultValue={cc}
                    onChangeSelect={(e) => {
                      setCC(e);
                    }}
                    data={datacc}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormHelperText>REF. M. PAGO</FormHelperText>
                  <TextField
                    value={tipoDocM}
                    variant="outlined"
                    size="small"
                    fullWidth
                    onKeyDown={(evt) =>
                      evt.key === "Enter" && evt.preventDefault()
                    }
                    onChange={(e) => {
                      setTipoDocM(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormHelperText error={val.glosa}>
                    {val.glosa ? "LA GLOSA ES REQUERIDA" : "GLOSA"}
                  </FormHelperText>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={glosa}
                    onChange={(e) => {
                      setVal({ ...val, glosa: false });
                      setGlosa(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormHelperText>MONEDA</FormHelperText>
                  <select
                    value={moneda}
                    onChange={updateMoneda}
                    className="form-control"
                  >
                    <OptionsTipo />
                  </select>
                </Grid>
                <Grid item xs={6}>
                  <FormHelperText>TIPO DE CAMBIO</FormHelperText>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={CambiodelDia}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormHelperText>TIPO</FormHelperText>
                  <select
                    value={tipo}
                    onChange={(e) => setTipo(e.target.value)}
                    className="form-control"
                    disabled
                  >
                    <option value={"DEBE"}>DEBE</option>
                    <option value={"HABER"}>HABER</option>
                  </select>
                </Grid>
                <Grid item xs={6}>
                  <FormHelperText error={val.amount}>
                    {val.amount ? "EL MONTO ES REQUERIDO" : "MONTO"}
                  </FormHelperText>
                  <input
                    value={monto}
                    onChange={updateMonto}
                    className="form-control"
                    type="text"
                    name="\*d"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ModalAddProvisioning;
