import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@material-ui/core";
import Axios from "axios";
import { apiInventario } from "../../../../config/api.contable";
import { notification } from "antd";

const AddSubCategory = ({ open, setOpen, data, reloadFunction }) => {
  console.log(data);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const saveData = async () => {
    setLoading(true);
    try {
      let newData = {
        name,
        headquarter: data.headquarter,
        company: data.company,
        category: data._id,
      };
      await Axios.post(`${apiInventario}/subcategory/${data._id}`, newData);
      notification["success"]({
        message: "La subcategoría se agregó correctamente",
      });
      reloadFunction();
      setOpen(false);
    } catch (error) {
      notification["error"]({
        message: `Oops!`,
        description: `Ocurrió un error, ${error}`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle align="center">AGREGAR SUBCATEGORÍA</DialogTitle>
      <DialogContent>
        <TextField
          label="Nombre"
          fullWidth
          variant="outlined"
          size="small"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={() => setOpen(false)}>
          CANCELAR
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={loading || !Boolean(name)}
          onClick={saveData}
        >
          {loading ? "CARGANDO..." : "AGREGAR"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSubCategory;
