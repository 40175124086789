import React from "react"
import {
  Grid,
  Typography,
  DialogActions,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  Toolbar,
} from "@material-ui/core"
import moment from "moment"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import { GridList, GridListTile } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import napformat from "../../../../../config/napformat"

const useStyles = makeStyles( ( theme ) => ( {
  table: { background: "#fff" },
  subtitle: { justifyContent: "center" },
  imagen: {
    border: "5px solid #eee",
    maxWidth: "55%",
    marginLeft: "22%",
    borderRadius: 5,
    marginBottom: 5,
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
} ) )

const ModalDetailsVehiculos = ( props ) => {
  const classes = useStyles()
  const { visible, setVisible, dataVehiculos } = props

  return (
    <Dialog maxWidth="lg" fullScreen open={ visible }>
      <DialogTitle>
        <Grid container alignItems='flex-end'>
          <Grid item xs={ 1 }>
          </Grid>
          <Grid item xs={ 10 }>
            <Typography
              variant="h2"
              component="h2"
              color="textSecondary"
              align="center"
            >
              DETALLES
            </Typography>
          </Grid>
          <Grid item xs={ 1 }>
            <Button
              color="secondary"
              variant="contained"
              onClick={ () => setVisible( false ) }
            >
              Cerrar
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={ 1 }>
          <Grid item xs={ 12 }>
            <Grid container spacing={ 1 }>
              <Grid item xs={ 12 } md={ 6 }>
                <TableContainer>
                  <Toolbar className={ classes.subtitle }>
                    <Typography
                      variant="h4"
                      color="textSecondary"
                      gutterBottom
                      align="center"
                    >
                      Información General
                    </Typography>
                  </Toolbar>
                  <Table
                    className={ classes.table }
                    aria-label="simple table"
                    size="small"
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          MARCA DEL ACTIVO FIJO:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataVehiculos.trademark
                            ? dataVehiculos.trademark
                            : "-" }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          MODELO DEL ACTIVO FIJO:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataVehiculos.model ? dataVehiculos.model : "-" }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          N° de motor
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataVehiculos.motor_number
                            ? dataVehiculos.motor_number
                            : "-" }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          Partida Registral
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataVehiculos.registration_starting
                            ? dataVehiculos.registration_starting
                            : "-" }
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                <TableContainer>
                  <Toolbar className={ classes.subtitle }>
                    <Typography
                      variant="h4"
                      color="textSecondary"
                      gutterBottom
                      align="center"
                    ></Typography>
                  </Toolbar>
                  <Table
                    className={ classes.table }
                    aria-label="simple table"
                    size="small"
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          Placa del Vehículo:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataVehiculos.vehicle_plate
                            ? dataVehiculos.vehicle_plate
                            : "-" }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          Fecha de adquisición:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataVehiculos.acquisition_date
                            ? moment( dataVehiculos.acquisition_date ).format(
                              "DD-MM-YYYY"
                            )
                            : "-" }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          Valor histórico:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataVehiculos.historical_value ? (
                            <>S/{ napformat( dataVehiculos.historical_value ) }</>
                          ) : (
                            " - "
                          ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={ { width: 175 } }>Categoria</TableCell>
                        <TableCell align="right">
                          { dataVehiculos.category.name }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={ { width: 175 } }>
                          Sub Categoria
                        </TableCell>
                        <TableCell align="right">
                          { dataVehiculos.subcategory?.name ?? "" }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={ { width: 175 } }>
                          Cuenta contable
                        </TableCell>
                        <TableCell align="right">
                          { dataVehiculos.category.account_number }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={ { width: 175 } }>
                          Nombre de la cuenta
                        </TableCell>
                        <TableCell align="right">
                          { dataVehiculos.category.account_name }
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={ 12 }>
            <Grid container spacing={ 1 }>
              <Grid item xs={ 12 } md={ 6 }>
                <TableContainer>
                  <Toolbar className={ classes.subtitle }>
                    <Typography
                      variant="h4"
                      color="textSecondary"
                      gutterBottom
                      align="center"
                    >
                      Descripción Técnica
                    </Typography>
                  </Toolbar>
                  <Table
                    className={ classes.table }
                    aria-label="simple table"
                    size="small"
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          N° de ejes:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataVehiculos.axles_number
                            ? dataVehiculos.axles_number + " EJES"
                            : "-" }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          N° de asientos:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataVehiculos.seats_number
                            ? dataVehiculos.seats_number + " ASIENTOS"
                            : "-" }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          N° de ruedas:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataVehiculos.wheels_number
                            ? dataVehiculos.wheels_number + " RUEDAS"
                            : "-" }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          Carrocería:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataVehiculos.bodywork
                            ? dataVehiculos.bodywork
                            : "-" }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          Combustible:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataVehiculos.fuel ? dataVehiculos.fuel : "-" }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          Color:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataVehiculos.color ? dataVehiculos.color : "-" }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" scope="row">
                          Año de fabricación:
                        </TableCell>
                        <TableCell align="right" scope="row">
                          { dataVehiculos.production_year
                            ? dataVehiculos.production_year
                            : "-" }
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                <Grid
                  container
                  spacing={ 1 }
                  direction="column"
                  alignItems="center"
                  justify="center"
                >
                  <div className={ classes.root }>
                    <GridList
                      cellHeight={ 160 }
                      className={ classes.gridList }
                      cols={ 3 }
                    >
                      { dataVehiculos.images.map( ( tile, index ) => (
                        <GridListTile key={ tile } cols={ 1 }>
                          <img src={ tile } alt={ tile } />
                        </GridListTile>
                      ) ) }
                    </GridList>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={ 12 }>
            <Grid container spacing={ 1 }>
              { dataVehiculos.docs &&
                dataVehiculos.docs.length > 0 &&
                dataVehiculos.docs.map( ( ev, index ) => (
                  <Grid item xs={ 12 } md={ 6 }>
                    <embed
                      src={ ev }
                      type="application/pdf"
                      width="100%"
                      height="400px"
                    />
                  </Grid>
                ) ) }
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ModalDetailsVehiculos
