import React from "react"
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import moment from "moment"
import napformat from "../../../../../config/napformat"

const useStyles = makeStyles( ( theme ) => ( {
  imagen: {
    border: "5px solid #eee",
    maxWidth: 250,
    maxHeight: "100%",
    borderRadius: 5,
    marginBottom: 5,
  },
} ) )

const ModalDetail = ( props ) => {
  const classes = useStyles()
  const { visible, setVisible, data } = props

  return (
    <Dialog open={ visible } fullScreen onClose={ () => setVisible( false ) }>
      <DialogTitle>
        <Grid container>
          <Grid item xs={ 1 }>
          </Grid>
          <Grid item xs={ 10 }>
            <Typography
              variant="h2"
              component="h2"
              color="textSecondary"
              align="center"
            >
              DETALLES
            </Typography>
          </Grid>
          <Grid item xs={ 1 }>
            <Button
              color="secondary"
              variant="contained"
              onClick={ () => setVisible( false ) }
            >
              Cerrar
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContent>
          <Grid container spacing={ 2 }>
            <Grid item xs={ 12 } md={ 6 }>
              <Typography
                variant="h4"
                component="h3"
                color="textSecondary"
                align="center"
              >
                Información general
              </Typography>
              <Table size="small" style={ { border: "1px solid #eee" } }>
                <TableBody>
                  <TableRow>
                    <TableCell style={ { width: 250 } }>
                      Denominación del predio:{ " " }
                    </TableCell>
                    <TableCell align="right">{ data.property_name }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={ { width: 250 } }>Dirección: </TableCell>
                    <TableCell align="right">{ data.address }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={ { width: 250 } }>
                      N° de partida electrónica:{ " " }
                    </TableCell>
                    <TableCell align="right">
                      N° { data.departure_number }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={ { width: 250 } }>
                      Fecha de adquisición del terreno:{ " " }
                    </TableCell>
                    <TableCell align="right">
                      { moment( data.acquisition_date ).format( "D MMM [del] YYYY" ) }
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <br />
              <Typography
                variant="h4"
                component="h3"
                color="textSecondary"
                align="center"
              >
                Costos{ " " }
              </Typography>
              <Table size="small" style={ { border: "1px solid #eee" } }>
                <TableBody>
                  <TableRow>
                    <TableCell style={ { width: 350 } }>
                      Valor de adquisición del terreno:
                    </TableCell>
                    <TableCell align="right">
                      <span>S/</span>
                      { napformat( data.acquisition_value ) }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={ { width: 350 } }>
                      Valor de adquisición de la construcción:
                    </TableCell>
                    <TableCell align="right">
                      <span>S/</span>
                      { napformat( data.acquisition_build_value ) }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={ { width: 350 } }>
                      Valor de autovaluo municipalidad - terreno:
                    </TableCell>
                    <TableCell align="right">
                      <span>S/</span>
                      { napformat( data.ground_autovalue ) }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={ { width: 350 } }>
                      Valor de autovaluo municipalidad - Construcción:
                    </TableCell>
                    <TableCell align="right">
                      <span>S/</span>
                      { napformat( data.build_autovalue ) }
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <br />
              <Typography
                variant="h4"
                component="h3"
                color="textSecondary"
                align="center"
              >
                Otros{ " " }
              </Typography>
              <Table size="small" style={ { border: "1px solid #eee" } }>
                <TableBody>
                  <TableRow>
                    <TableCell style={ { width: 100 } }>
                      Observaciones:{ " " }
                    </TableCell>
                    <TableCell align="right">
                      { data.observations
                        ? data.observations
                        : "No especificado" }
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={ 12 } md={ 6 }>
              <Typography
                variant="h4"
                component="h3"
                color="textSecondary"
                align="center"
              >
                Área total: { napformat( data.area ) } mt<sup>2</sup>
              </Typography>
              <Grid container>
                <Grid item sm={ 6 } xs={ 12 }>
                  <Table size="small" style={ { border: "1px solid #eee" } }>
                    <TableBody>
                      <TableRow>
                        <TableCell style={ { width: 145 } }>Lado A</TableCell>
                        <TableCell align="right">
                          { data.side_a ? (
                            <span>{ napformat( data.side_a ) } mt</span>
                          ) : (
                            "-"
                          ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={ { width: 145 } }>Lado C</TableCell>
                        <TableCell align="right">
                          { data.side_c ? (
                            <span>{ napformat( data.side_c ) } mt</span>
                          ) : (
                            "-"
                          ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={ { width: 145 } }>
                          Lado restante
                        </TableCell>
                        <TableCell align="right">
                          { data.side_another ? (
                            <span>{ napformat( data.side_another ) } mt</span>
                          ) : (
                            "-"
                          ) }
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item sm={ 6 } xs={ 12 }>
                  <Table size="small" style={ { border: "1px solid #eee" } }>
                    <TableBody>
                      <TableRow>
                        <TableCell style={ { width: 145 } }>Lado B</TableCell>
                        <TableCell align="right">
                          { data.side_b ? (
                            <span>{ napformat( data.side_b ) } mt</span>
                          ) : (
                            "-"
                          ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={ { width: 145 } }>Lado D</TableCell>
                        <TableCell align="right">
                          { data.side_d ? (
                            <span>{ napformat( data.side_d ) } mt</span>
                          ) : (
                            "-"
                          ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={ { width: 145 } }>
                          Área Construida
                        </TableCell>
                        <TableCell align="right">
                          { napformat( data.built_area ) } mt<sup>2</sup>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
              { data.images.length > 0 ? (
                <Grid
                  container
                  direction="row-reverse"
                  justify="space-around"
                  alignItems="center"
                >
                  { data.images.map( ( ev ) => (
                    <a href={ ev } target="_blank">
                      <img
                        src={ ev }
                        alt="Foto del terreno"
                        className={ classes.imagen }
                      />
                    </a>
                  ) ) }
                </Grid>
              ) : (
                <div style={ { textAlign: "center" } }>
                  <Typography variant="p" color="textSecondary">
                    <br />
                    <br />
                    No se adjuntaron imagenes de la edificación.
                  </Typography>
                </div>
              ) }
            </Grid>
            <Grid item xs={ 12 }>
              <Grid container spacing={ 1 }>
                { data.list_docs &&
                  data.list_docs.length > 0 &&
                  data.list_docs.map( ( ev, index ) => (
                    <Grid item xs={ 12 } md={ 6 }>
                      <embed
                        src={ ev }
                        type="application/pdf"
                        width="100%"
                        height="400px"
                      />
                    </Grid>
                  ) ) }
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </DialogContent>
    </Dialog>
  )
}

export default ModalDetail
