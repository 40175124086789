import Home from "./components/Global/Home";
import Page404 from "./components/Global/Page404";
import Login from "./views/Login/Login";
import Cuentas from "./components/Global/CuentasNew";
import Reportes from "./components/Global/Reportes";
import ExportarBalance from "./components/Reporte/ExportarBalance";
import ExportarMayor from "./components/Reporte/ExportarMayor";
import ExportarIngresos from "./components/Reporte/EP/Ingresos";
import CajaRapida from "./components/Voucher/CajaRapida";
import Empresa from "./components/Empresa/Empresa";
import TablaUsuarios from "./components/Usuarios/TablaUsuarios";
import UsersDisabled from "./components/Usuarios/UsersDisabled";
import Perfil from "./components/Global/Perfil";
import ExportarEEGGPP from "./views/Reportes/ExportarEEGGPP";
import ExportarEEFF from "./views/Reportes/ExportarEEFF";
import CreateUsuario from "./components/Usuarios/CreateUsuario";
import Inicio from "./components/Global/inicio";
import Importacion from "./components/Importacion/Importacion";
import Companies from "./components/Companies";
import CC from "./components/Global/CC";
import ExportarMovimientos from "./components/Reporte/ExportarMovimientos";
import ConfiguracionAnio from "./components/Global/ConfiguracionAnio";
import GestionUsuarios from "./components/Global/GestionUsuarios";
import Balancefecha from "./components/Global/Balancefecha";
import forgotPassword from "./views/forgotPassword/forgotPassword";
import ConfirmRecoveredPassword from "./views/forgotPassword/ConfirmRecoveredPassword";
import Archivos from "./views/Archivos/Archivos";
import Trimestral from "./views/ReporteTrimestral/ReporteTrimestral";
import Inventory from "./views/Inventory";
import Edificaciones from "./views/Inventory/components/Edificaciones";
import Terrenos from "./views/Inventory/components/Terrenos";
import Equipos from "./views/Inventory/Equipos";
import Categorias from "./views/Inventory/Categorias";
import Locacion from "./views/Inventory/Locacion";
import Muebles from "./views/Inventory/Muebles";
import Vehiculos from "./views/Inventory/Vehiculos";
import Intangibles from "./views/Inventory/Intangibles";
import Enseres from "./views/Inventory/Enseres";
import ReporteDiario from "./views/Reportes//Diario/ReporteDiario";
import ReporteCaja from "./views/Reportes/Caja/ReporteCaja";
import MayorGeneral from "./views/Reportes/MayorGeneral/MayorGeneral";
import Costos from "./views/configuracion/Costos";
import ListVoucher from "./views/Vouchers/ListVoucher/ListVoucher";
import EditarVoucherList from "./views/Vouchers/EditarVoucher/EditarVoucher";
import CrearVoucher from "./components/Voucher/CrearVoucher";
import DetailsVoucher from "./views/Vouchers/DetailsVoucher/InvoiceVoucher";
import Presupuesto from "./components/Reporte/PresupuestoNew/Presupuesto";
import EEGGPPNN from "./views/Reportes/ExportarEEGGPPN";
import ListPresupuesto from "./views/Presupuesto";
import PlanCuentas from "./views/configuracion/PlanCuentas";
import CostFormulas from "./views/configuracion/CostFormulas";
import DetailLocation from "./views/Inventory/components/Locacion/DetailLocation";
import PagosCobros from "./views/Vouchers/PagosCobros/PagosCobros";
import DetailCP from "./views/Vouchers/PagosCobros/components/DetailCP";
import Reclamos from "./views/Reclamos";
import ReclamosList from "./views/Reclamos/List";
import Incidencias from "./views/Incidencias";
import IncidenciasPublicView from "./views/Incidencias/PublicView";
import ByIncidence from "./views/Incidencias/components/ByIncidence";
import ReportIncidence from "./views/Incidencias/Report";
import Boletas from "./views/Boletas/Boletas";
import BoletasByWorker from "./views/Boletas/BoletasByWorker";
import WorkersHistorical from "./views/Boletas/WorkersHistorical";
import WorkersByDates from "./views/Boletas/WorkersByDates";
import WorkersByCompany from "./views/Boletas/WorkersByCompany";
import BoletasByHeadquarter from "./views/Boletas/BoletasByHeadquarter";
import ImportacionBoletas from "./views/Boletas/Importacion";
import ImportVoucher from "./views/Vouchers/ImportVoucher";
import ReporteBalanceDeatallado from "./views/Reportes/BalanceDetallado/ReporteBalanceDeatallado";
import ReporteInvetariosBalances from "./views/Reportes/InventariosBalances";
import CambioPatrimonio from "./views/Reportes/CambioPatrimonio";
import EstadoFlujoEfectivo from "./views/Reportes/EstadoFlujoEfectivo";
import ReporteInventario from "./views/Inventory/components/Reports";
import ReporteInventarioCategory from "./views/Inventory/components/Reports/CategoryReport";
import ReporteInventarioSubCategory from "./views/Inventory/components/Reports/SubCategoryReport";
import ReporteVentas from "./views/Reportes/Ventas";
import ReporteCompras from "./views/Reportes/Compras";
import ReporteHonorarios from "./views/Reportes/Honorarios";
// WORKERS
import Covid19 from "./views/Workers/Covid19";
import DeclaracionPersonal from "./views/Workers/DeclaracionPersonal";
import DeclaracionSalud from "./views/Workers/DeclaracionSalud";
import WorkerInformation from "./views/Boletas/General/WorkerInformation";
import Incidents from "./views/Boletas/General/Incidents";
import Documents from "./views/Ingresos/Documents";
import AttendanceFormats from "./views/Boletas/General/AttendanceFormats";
// INGRESOS
import Ingresos from "./views/Ingresos";
import ReporteBalanceAsiento from "./components/Reporte/ReporteBalanceAsiento";
import IngresosCompany from "./views/Ingresos/IngresosCompany";
import Memorandums from "./views/Inventory/Memorandums";

const routes = [
  {
    path: "/forgotpassword",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: forgotPassword,
    isPrivate: false,
    name: "recuperarcontrasena",
    module: "GLOBAL",
  },
  {
    path: "/auth/reset_password/:token",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ConfirmRecoveredPassword,
    isPrivate: false,
    name: "resetpassword",
    module: "GLOBAL",
  },
  {
    path: "/nap",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Home,
    isPrivate: false,
    name: "home",
    module: "GLOBAL",
  },
  {
    path: "/:idCompany/login",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Login,
    isPrivate: false,
    name: "login",
    module: "GLOBAL",
  },
  {
    path: "/:url/reclamos",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Reclamos,
    isPrivate: false,
    name: "reclamos",
    module: "GLOBAL",
  },
  {
    path: "/:url/incidencias",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: IncidenciasPublicView,
    isPrivate: false,
    name: "incidencias",
    module: "GLOBAL",
  },
  {
    path: "/",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Inicio,
    isPrivate: true,
    name: "inicio",
    module: "GLOBAL",
  },
  {
    path: "/perfil",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Perfil,
    isPrivate: true,
    name: "perfil",
    module: "GLOBAL",
  },
  {
    path: "/cuentas",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Cuentas,
    isPrivate: true,
    name: "cuentas",
    module: "CUENTAS",
  },
  {
    path: "/reportes",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Reportes,
    isPrivate: true,
    name: "reportes",
    module: "CONTABILIDAD",
  },
  {
    path: "/reporte/balance",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ExportarBalance,
    isPrivate: true,
    name: "reportebalance",
    module: "CONTABILIDAD",
  },
  {
    path: "/reporte/balance-asiento",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ReporteBalanceAsiento,
    isPrivate: true,
    name: "reportebalance",
    module: "CONTABILIDAD",
  },
  {
    path: "/reporte/ventas",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ReporteVentas,
    isPrivate: true,
    name: "reporteventas",
    module: "CONTABILIDAD",
  },
  {
    path: "/reporte/compras",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ReporteCompras,
    isPrivate: true,
    name: "reportecompras",
    module: "CONTABILIDAD",
  },
  {
    path: "/cc",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: CC,
    isPrivate: true,
    name: "cc",
    module: "CONTABILIDAD",
  },
  {
    path: "/archivos",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Archivos,
    isPrivate: true,
    name: "archivos",
    module: "CONTABILIDAD",
  },
  {
    path: "/reporte/mayor",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ExportarMayor,
    isPrivate: true,
    name: "reportemayor",
    module: "CONTABILIDAD",
  },
  {
    path: "/reporte/trimestral",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Trimestral,
    isPrivate: true,
    name: "reportetrimestral",
    module: "CONTABILIDAD",
  },
  {
    path: "/reporte/ep_ingresos",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ExportarIngresos,
    isPrivate: true,
    name: "reporteep_ingresos",
    module: "CONTABILIDAD",
  },
  {
    path: "/reporte/eeff",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ExportarEEFF,
    isPrivate: true,
    name: "reporteeeff",
    module: "CONTABILIDAD",
  },
  {
    path: "/reporte/eeggpp",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ExportarEEGGPP,
    isPrivate: true,
    name: "reporteeeggpp",
    module: "CONTABILIDAD",
  },
  {
    path: "/reporte/balance-detallado",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ReporteBalanceDeatallado,
    isPrivate: true,
    name: "reportebalncedetallado",
    module: "CONTABILIDAD",
  },
  {
    path: "/reporte/diario",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ReporteDiario,
    isPrivate: true,
    name: "reportediario",
    module: "CONTABILIDAD",
  },
  {
    path: "/reporte/caja",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ReporteCaja,
    isPrivate: true,
    name: "reportecaja",
    module: "CONTABILIDAD",
  },
  {
    path: "/reporte/mayor-general",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: MayorGeneral,
    isPrivate: true,
    name: "reportemayorgeneral",
    module: "CONTABILIDAD",
  },
  {
    path: "/reporte/movimientos",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ExportarMovimientos,
    isPrivate: true,
    name: "reportemovimientos",
    module: "CONTABILIDAD",
  },
  {
    path: "/reporte/presupuesto",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Presupuesto,
    isPrivate: true,
    name: "reportepresupuesto",
    module: "CONTABILIDAD",
  },
  {
    path: "/reporte/recibo-honorario",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ReporteHonorarios,
    isPrivate: true,
    name: "reporterecibohonorario",
    module: "CONTABILIDAD",
  },
  {
    path: "/reporte/eeggppnn",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: EEGGPPNN,
    isPrivate: true,
    name: "eeggppnn",
    module: "CONTABILIDAD",
  },
  {
    path: "/reporte/inventorios-balances",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ReporteInvetariosBalances,
    isPrivate: true,
    name: "inventoriosbalances",
    module: "CONTABILIDAD",
  },
  {
    path: "/presupuesto",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ListPresupuesto,
    isPrivate: true,
    name: "presupuesto",
    module: "CONTABILIDAD",
  },
  {
    path: "/vouchers",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ListVoucher,
    isPrivate: true,
    name: "vouchers",
    module: "CONTABILIDAD",
  },
  {
    path: "/import-voucher",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant"],
    component: ImportVoucher,
    isPrivate: true,
    name: "importvoucher",
    module: "CONTABILIDAD",
  },
  {
    path: "/configuracionanio",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ConfiguracionAnio,
    isPrivate: true,
    name: "configuracionanio",
    module: "CONTABILIDAD",
  },
  {
    path: "/createvoucher",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant"],
    component: CrearVoucher,
    isPrivate: true,
    name: "createvoucher",
    module: "CONTABILIDAD",
  },
  {
    path: "/importacion",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant"],
    component: Importacion,
    isPrivate: true,
    name: "importacion",
    module: "CUENTAS",
  },
  {
    path: "/cajarapida",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant"],
    component: CajaRapida,
    isPrivate: true,
    name: "cajarapida",
    module: "CONTABILIDAD",
  },
  {
    path: "/configperiodo",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin"],
    component: Balancefecha,
    isPrivate: true,
    name: "configperiodo",
    module: "CONTABILIDAD",
  },
  {
    path: "/createusuario/:idCompany/:url",
    exact: true,
    list_roles: ["supernap", "superadmin"],
    component: CreateUsuario,
    isPrivate: true,
    name: "createusuario",
    module: "CONFIGURACIÓN",
  },
  {
    path: "/edit-voucher/:id",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant"],
    component: EditarVoucherList,
    isPrivate: true,
    name: "editvoucher",
    module: "CONTABILIDAD",
  },
  {
    path: "/ver-voucher/:id",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: DetailsVoucher,
    isPrivate: true,
    name: "vervoucher",
    module: "CONTABILIDAD",
  },
  {
    path: "/configcostos",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Costos,
    isPrivate: true,
    name: "configcostos",
    module: "CONTABILIDAD",
  },
  {
    path: "/plan-cuentas",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: PlanCuentas,
    isPrivate: true,
    name: "plancuentas",
    module: "CONTABILIDAD",
  },
  {
    path: "/formulas",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: CostFormulas,
    isPrivate: true,
    name: "formulas",
    module: "CONTABILIDAD",
  },
  {
    path: "/reporte/flujos-efectivo",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: EstadoFlujoEfectivo,
    isPrivate: true,
    name: "flujosefectivo",
    module: "CONTABILIDAD",
  },
  {
    path: "/reporte/cambio-patrimonio",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: CambioPatrimonio,
    isPrivate: true,
    name: "cambiopatrimonio",
    module: "CONTABILIDAD",
  },
  {
    path: "/inventario",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Inventory,
    isPrivate: true,
    name: "inventario",
    module: "INVENTARIO",
  },
  {
    path: "/inventario/edificaciones",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Edificaciones,
    isPrivate: true,
    name: "edificaciones",
    module: "INVENTARIO",
  },
  {
    path: "/inventario/terrenos",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Terrenos,
    isPrivate: true,
    name: "terrenos",
    module: "INVENTARIO",
  },
  {
    path: "/inventario/memorandums",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Memorandums,
    isPrivate: true,
    name: "memorandums",
    module: "INVENTARIO",
  },
  {
    path: "/inventario/equipos",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Equipos,
    isPrivate: true,
    name: "equipos",
    module: "INVENTARIO",
  },
  {
    path: "/inventario/muebles",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Muebles,
    isPrivate: true,
    name: "muebles",
    module: "INVENTARIO",
  },
  {
    path: "/inventario/intangibles",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Intangibles,
    isPrivate: true,
    name: "intangibles",
    module: "INVENTARIO",
  },
  {
    path: "/inventario/vehiculos",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Vehiculos,
    isPrivate: true,
    name: "vehiculos",
    module: "INVENTARIO",
  },
  {
    path: "/inventario/enseres",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Enseres,
    isPrivate: true,
    name: "enseres",
    module: "INVENTARIO",
  },
  {
    path: "/inventario/reportes",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ReporteInventario,
    isPrivate: true,
    name: "reportes",
    module: "INVENTARIO",
  },
  {
    path: "/inventario/reportes/categoria",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ReporteInventarioCategory,
    isPrivate: true,
    name: "reportes",
    module: "INVENTARIO",
  },
  {
    path: "/inventario/reportes/subcategoria",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ReporteInventarioSubCategory,
    isPrivate: true,
    name: "reportes",
    module: "INVENTARIO",
  },
  {
    path: "/gestion/categorias",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Categorias,
    isPrivate: true,
    name: "categorias",
    module: "INVENTARIO",
  },
  {
    path: "/gestion/locacion",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Locacion,
    isPrivate: true,
    name: "locacion",
    module: "INVENTARIO",
  },
  {
    path: "/gestion/locacion/:idLocation",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: DetailLocation,
    isPrivate: true,
    name: "detaillocacion",
    module: "INVENTARIO",
  },
  {
    path: "/pagoscobros",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: PagosCobros,
    isPrivate: true,
    name: "pagoscobros",
    module: "CONTABILIDAD",
  },
  {
    path: "/pagoscobros/:idVoucher/:type",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: DetailCP,
    isPrivate: true,
    name: "pagoscobrosdetail",
    module: "CONTABILIDAD",
  },
  {
    path: "/ingresos/registro",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Ingresos,
    isPrivate: true,
    name: "ingresos_registro",
    module: "INGRESOS",
  },
  {
    path: "/ingresos/registro/company",
    exact: true,
    list_roles: ["supernap"],
    component: IngresosCompany,
    isPrivate: true,
    name: "ingresos_registro_company",
    module: "INGRESOS",
  },
  {
    path: "/ingresos/documentos",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant"],
    component: Documents,
    isPrivate: true,
    name: "trabajadordocumentos",
    module: "INGRESOS",
  },
  {
    path: "/reclamos",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ReclamosList,
    isPrivate: true,
    name: "reclamos",
    module: "RECLAMOS",
  },
  {
    path: "/incidencias",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Incidencias,
    isPrivate: true,
    name: "incidencias",
    module: "INCIDENCIAS",
  },
  {
    path: "/incidencias/:idIncidence",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ByIncidence,
    isPrivate: true,
    name: "incidencias",
    module: "INCIDENCIAS",
  },
  {
    path: "/incidencias/report/:idIncidence",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: ReportIncidence,
    isPrivate: true,
    name: "incidencias",
    module: "INCIDENCIAS",
  },
  {
    path: "/boletas/list",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Boletas,
    isPrivate: true,
    name: "boletas",
    module: "COLABORADORES",
  },
  {
    path: "/boletas/list/:idWorker/:from",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: BoletasByWorker,
    isPrivate: true,
    name: "boletas",
    module: "COLABORADORES",
  },
  {
    path: "/boletas/trabajadores/historico",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: WorkersHistorical,
    isPrivate: true,
    name: "trabajadores",
    module: "COLABORADORES",
  },
  {
    path: "/boletas/trabajadores/xfechas",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: WorkersByDates,
    isPrivate: true,
    name: "trabajadores",
    module: "COLABORADORES",
  },
  {
    path: "/boletas/trabajadores/company",
    exact: true,
    list_roles: ["supernap", "superadmin"],
    component: WorkersByCompany,
    isPrivate: true,
    name: "trabajadores",
    module: "COLABORADORES",
  },
  {
    path: "/boletas/trabajadores/headquarter",
    exact: true,
    list_roles: ["supernap", "superadmin"],
    component: BoletasByHeadquarter,
    isPrivate: true,
    name: "trabajadores",
    module: "COLABORADORES",
  },
  {
    path: "/boletas/importacion",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant"],
    component: ImportacionBoletas,
    isPrivate: true,
    name: "ImportacionBoletas",
    module: "COLABORADORES",
  },
  {
    path: "/sintomatologia-covid19",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Covid19,
    isPrivate: true,
    name: "sintomatologiacovid19",
    module: "COLABORADORES",
  },
  {
    path: "/declaracion-salud",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: DeclaracionSalud,
    isPrivate: true,
    name: "declaracionsalud",
    module: "COLABORADORES",
  },
  {
    path: "/declaracion-personal",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: DeclaracionPersonal,
    isPrivate: true,
    name: "declaracionpersonal",
    module: "COLABORADORES",
  },
  {
    path: "/trabajador/informacion",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: WorkerInformation,
    isPrivate: true,
    name: "trabajadorinformacion",
    module: "COLABORADORES",
  },
  {
    path: "/trabajador/incidencias-register",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant"],
    component: Incidents,
    isPrivate: true,
    name: "trabajadorincidencias",
    module: "COLABORADORES",
  },
  {
    path: "/formatos/asistencia",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: AttendanceFormats,
    isPrivate: true,
    name: "trabajadorasistencia",
    module: "COLABORADORES",
  },
  {
    path: "/usuarios",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin"],
    component: TablaUsuarios,
    isPrivate: true,
    name: "usuarios",
    module: "CONFIGURACIÓN",
  },
  {
    path: "/usuarios/dehabilitados",
    exact: true,
    list_roles: ["supernap", "superadmin"],
    component: UsersDisabled,
    isPrivate: true,
    name: "usuarios",
    module: "CONFIGURACIÓN",
  },
  {
    path: "/usersassign",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin"],
    component: GestionUsuarios,
    isPrivate: true,
    name: "usersassign",
    module: "CONFIGURACIÓN",
  },
  {
    path: "/empresa",
    exact: true,
    list_roles: ["supernap", "superadmin"],
    component: Empresa,
    isPrivate: true,
    name: "empresa",
    module: "CONFIGURACIÓN",
  },
  {
    path: "/companies",
    exact: true,
    list_roles: ["supernap"],
    component: Companies,
    isPrivate: true,
    name: "companies",
    module: "CONFIGURACIÓN",
  },
  {
    path: "",
    exact: true,
    list_roles: ["supernap", "superadmin", "admin", "accountant", "usuario"],
    component: Page404,
    isPrivate: false,
    name: "page404",
    module: "GLOBAL",
  },
];

export default routes;
