/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { notification, Spin } from "antd";
import useForm from "react-hook-form";
import SelectDocTypes from "src/components/Selects/SelectDocTypes";
import moment from "moment";
import { uploadFileAndGetURL, postIngresos, putIngresos } from "src/utils/req.ingresos";
import { getWorkerDocuments, postWorkerDocuments } from "src/utils/req.boletas";
import { Add, AttachFile } from "@material-ui/icons";
import Delete from "@material-ui/icons/Delete";
import Search from "@material-ui/icons/Search";
import consultRuc from "src/utils/ruc";
import numberToString from "src/utils/numberToString";
import SelectConcepto from "src/components/Selects/SelectConcepto";
import SelectMonths from "src/components/Selects/SelectMonths";
import SelectYear from "src/components/Selects/SelectYear";
import { monthsList, typesDocuments } from "src/utils/constants";
import sedeactual from "src/config/sedeactual";

const ModalIngresos = ({ open, data, setOpen, refreshFunction, loading, setLoading }) => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const [concept, setConcept] = useState(data ? data.observation : null);
  const [description, setDescription] = useState(data ? data.description : "");
  const [details, setDetails] = useState(
    data ? data.details : [{ amount: "", month: "ENERO", year: moment().year(), file: "" }]
  );
  const [destiny, setDestiny] = useState(data ? data.destiny : { t_doc: "DNI", n_doc: "", nombre_o_razon_social: "" });

  console.log(details);

  useEffect(() => {
    verifyDocuments();
  }, []);

  const verifyDocuments = async () => {
    try {
      setLoading(true);
      const res = await getWorkerDocuments();
      if (res.length === 0) {
        const toSave = [];
        monthsList.forEach((mth, iOne) => {
          let toPush = {
            order: iOne + 1,
            date_upload: "",
            year: sedeactual.getAnio(),
            month: mth.label,
            headquarter: sedeactual.sede_id(),
            company: sedeactual.company()._id,
          };
          typesDocuments.forEach((type) => Object.assign(toPush, { [type.key]: "" }));
          toSave.push(toPush);
        });

        await postWorkerDocuments({ data: toSave });
      }
    } catch (error) {
      notification["error"]({
        message: "OCURRIÓ UN ERROR AL BUSCAR LOS DOCUMENTOS",
        description: "No se crearon los documentos, por favor evitar el registro.",
      });
    } finally {
      setLoading(false);
    }
  };

  const sendData = async (items) => {
    setLoading(true);
    try {
      let errors = 0;
      details.forEach((e) => (!e.amount || !e.file) && errors++);

      if (errors !== 0) {
        return notification["warning"]({
          message: "El monto y archivo como evidencia por cada detalle son requeridos.",
        });
      }

      items["destiny"] = destiny;
      items["details"] = details;
      items["number_string"] = numberToString(Number(items.amount));
      items["observation"] = concept;
      items["year"] = moment(items.date).year();
      items["period"] = moment(items.date).month() + 1;

      if (concept === "otros") items["description"] = description;

      let res = {};

      if (data) res = await putIngresos(items, data._id);
      else res = await postIngresos(items, items.year);

      refreshFunction();
      notification["success"]({
        message: `${res.msg}`,
        description: `${
          res.errors !== 0
            ? `No se logró asociar ${res.errors} archivos a sus documentos porque no están generados.`
            : "Todo bien..."
        }`,
      });
      setOpen({ open: false });
    } catch (error) {
      notification["error"]({ message: `Ocurrió un error al realizar la operación` });
    } finally {
      setLoading(false);
    }
  };

  const handleSeach = async () => {
    const { t_doc, n_doc } = destiny;
    try {
      if (!((t_doc === "DNI" && n_doc.length === 8) || (t_doc === "RUC" && n_doc.length === 11))) {
        notification["info"]({ message: `${t_doc} inválido...` });
        return null;
      }
      setLoading(true);
      const res = await consultRuc(n_doc, t_doc.toLowerCase());
      if (res.success) {
        setDestiny({
          t_doc: t_doc,
          n_doc: res[t_doc.toLowerCase()],
          nombre_o_razon_social: res.nombre ?? res.nombre_o_razon_social,
        });
      }
    } catch (error) {
      notification["warning"]({ message: `${t_doc} inválido...` });
    } finally {
      setLoading(false);
    }
  };

  const uploadFile = async (file, index) => {
    try {
      if (!file) {
        notification["warning"]({ message: "Tiene que adjuntar un archivo" });
        return null;
      }
      setLoading(true);
      let fData = new FormData();
      fData.append("file", file);
      const res = await uploadFileAndGetURL(fData);
      handleChange(res, index, "file");
    } catch (error) {
      notification["error"]({ message: "No se logró subir el archivo" });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (value, index, type) => {
    let total = 0;
    details[index][type] = value;
    setDetails([...details]);
    if (type === "amount") {
      details.forEach((det) => (total += Number(det.amount)));
      setValue("amount", parseFloat(total).toFixed(2));
    }
  };

  const addDetail = () => {
    details.push({ amount: "", month: "ENERO", year: moment().year(), file: "" });
    setDetails([...details]);
  };

  const removeDetail = (index) => () => {
    details.splice(index, 1);
    setDetails([...details]);

    let total = 0;
    details.forEach((det) => (total += Number(det.amount)));
    setValue("amount", parseFloat(total).toFixed(2));
  };

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <DialogTitle>
        <Typography align="center" variant="h4" component="p">
          {data ? "EDITAR" : "AGREGAR"} INGRESO
        </Typography>
      </DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit(sendData)}>
        <DialogContent>
          <Spin spinning={loading}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} lg={4}>
                <SelectDocTypes value={destiny.t_doc} setValue={(e) => setDestiny({ ...destiny, t_doc: e })} />
              </Grid>
              {destiny.t_doc !== "COMUNIDAD" && (
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    variant="outlined"
                    label={`Número de documento: ${destiny.t_doc === "RUC" ? "11" : "8"} dítigos`}
                    value={destiny.n_doc}
                    onChange={(e) => setDestiny({ ...destiny, n_doc: e.target.value })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Fab size="small" onClick={handleSeach}>
                            <Search />
                          </Fab>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6} lg={destiny.t_doc !== "COMUNIDAD" ? 4 : 8}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  variant="outlined"
                  label={`${destiny.t_doc === "DNI" ? "Nombre" : "Razón social"}`}
                  value={destiny.nombre_o_razon_social}
                  onChange={(e) => {
                    setDestiny({ ...destiny, nombre_o_razon_social: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  variant="outlined"
                  label="Fecha"
                  name="date"
                  type="date"
                  defaultValue={Boolean(data) ? moment(data.date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                  inputRef={register({ required: true })}
                  error={Boolean(errors?.date)}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  variant="outlined"
                  label="Correo"
                  name="email"
                  type="email"
                  defaultValue={Boolean(data) ? data.email : ""}
                  inputRef={register({ required: true })}
                  error={Boolean(errors?.email)}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  variant="outlined"
                  label="Monto (autocalculado)"
                  disabled
                  name="amount"
                  type="number"
                  inputProps={{ min: 0 }}
                  defaultValue={Boolean(data) ? data.amount : ""}
                  inputRef={register({ required: true })}
                  error={Boolean(errors?.amount)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">S/.</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormHelperText>CONCEPTO</FormHelperText>
                <SelectConcepto value={concept} setValue={setConcept} />
              </Grid>
              {concept === "otros" && (
                <Grid item xs={12}>
                  <TextField
                    value={description}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    variant="outlined"
                    label="Descripción"
                    size="small"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <FormHelperText>DETALLE DEL INGRESO</FormHelperText>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {details.map((e, i) => (
                    <Grid item xs={12}>
                      <Grid container direction="row" spacing={1} justify="space-between">
                        <Grid item>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ min: 0, step: 0.01 }}
                            label="Monto"
                            size="small"
                            fullWidth
                            type="number"
                            variant="outlined"
                            value={e.amount}
                            onChange={(event) => handleChange(event.target.value, i, "amount")}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">S/.</InputAdornment>,
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <SelectMonths
                            month={e.month}
                            setMonth={(event) => handleChange(event, i, "month")}
                            width="200px"
                            aditional={["reintegro"]}
                          />
                        </Grid>
                        <Grid item>
                          <SelectYear year={e.year} setYear={(event) => handleChange(event, i, "year")} width="200px" />
                        </Grid>
                        <Grid item>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            label="Evidencia"
                            size="small"
                            fullWidth
                            variant="outlined"
                            type="file"
                            onChange={(event) => uploadFile(event.target.files[0], i)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {e.file && (
                                    <Avatar>
                                      <AttachFile />
                                    </Avatar>
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Fab color="secondary" size="small" onClick={removeDetail(i)}>
                            <Delete />
                          </Fab>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid item xs={12} align="center">
                    <Button variant="contained" color="primary" onClick={addDetail}>
                      <Add />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Spin>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => setOpen({ open: false })}>
            CANCELAR
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={loading || !destiny.nombre_o_razon_social || details.length === 0 || !concept}
            type="submit"
          >
            {data ? "ACTUALIZAR" : "GUARDAR"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalIngresos;
