import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Paper, CardContent, Typography, FormControl, Button } from "@material-ui/core";
import { Select, notification } from "antd";
import moment from "moment";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import TableResume from "./TableResume";
import TableDetail from "./TableDetail";
import { Link } from "react-router-dom";
import sedeactual from "../../../config/sedeactual.js";
import Spinner from "../../../components/Global/Spinner";
import { apiCuentas } from "../../../config/api.contable";
import axios from "axios";
import SelectSedes from "../../../components/Usuarios/SelectSedes";
import { getHeadquarters } from "../../../api/api.contable";
import ExportarEXCEL from "../../Global/ExportarExcel";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles(() => ({
  padd: {
    padding: "25px 0 0 0",
  },
  selectsedes: {
    maxWidth: "calc(100% - 650px)",
    minWidth: 300,
    textAlign: "left",
    marginRight: "5px",
  },
}));

const { Option } = Select;
const EjecucionPresupuestaria = (props) => {
  const classes = useStyles();
  const componentRef = useRef();
  const [sedes, setSedes] = useState([]);
  const [sede, setSede] = useState(sedeactual.get());
  const anho = sedeactual.getAnio();
  const rucHeadquarters = sedeactual.getRuc();
  const [montReport, setMontReport] = useState(moment(Date.now()).month() + 1);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expenseResume, setExpenseResume] = useState([]);
  const [expenseDetail, setExpenseDetail] = useState([]);
  const [incomeResume, setIncomeResume] = useState([]);
  const [incomeDetail, setIncomeDetail] = useState([]);

  const getData = async () => {
    try {
      setLoading(true);
      setExpenseDetail([]);
      setExpenseResume([]);
      setIncomeDetail([]);
      setIncomeResume([]);
      setError(false);
      let res = await axios.post(
        `${apiCuentas}/execute-budget/${sede}/${anho}/${sedeactual.company().url}`,
        { period: montReport, digit: 6 }
      );
      if (!res.data.msg) {
        setExpenseDetail(res.data.expensesDetailsEx);
        setExpenseResume(res.data.expensesResumeEx);
        setIncomeDetail(res.data.incomeDetailsEx);
        setIncomeResume(res.data.incomeResumeEx);
      }
    } catch (error) {
      setError(true);
      notification["error"]({
        message: `${error}`,
        description: "No se logró obtener las sedes.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [montReport, sede]);

  const getSedes = async () => {
    try {
      let result = await getHeadquarters();
      setSedes(result.data);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se logró obtener las sedes.",
      });
    }
  };

  useEffect(() => {
    getSedes();
  }, []);

  return (
    <Paper style={{ padding: 25 }}>
      <div align="right">
        <Link to={{ pathname: "/reportes" }}>
          <Button style={{ float: "left" }} variant="contained">
            <ArrowBackIosIcon /> Volver
          </Button>
        </Link>
        <div>
          {(sedeactual.getRol() === "supernap" || sedeactual.getRol() === "superadmin") && (
            <FormControl variant="outlined" className={classes.selectsedes}>
              <SelectSedes
                defaultValue={sede}
                data={sedes}
                onChangeSelect={(e) => {
                  setSede(e);
                }}
              />
            </FormControl>
          )}{" "}
          <Select
            defaultValue={montReport}
            style={{ width: 120, marginRight: "5px" }}
            onChange={(value) => setMontReport(value)}
            size="large"
          >
            <Option value={1}>Enero</Option>
            <Option value={2}>Febrero</Option>
            <Option value={3}>Marzo</Option>
            <Option value={4}>Abril</Option>
            <Option value={5}>Mayo</Option>
            <Option value={6}>Junio</Option>
            <Option value={7}>Julio</Option>
            <Option value={8}>Agosto</Option>
            <Option value={9}>Setiembre</Option>
            <Option value={10}>Octubre</Option>
            <Option value={11}>Noviembre</Option>
            <Option value={12}>Diciembre</Option>
          </Select>
          <ExportarEXCEL
            name={`EJECUCIÓN_PRESUPUESTARIA_${moment(Date.now()).format()}`}
            endpoint={`execute-budget/export-excel/${sede}/${sedeactual.getAnio()}/${
              sedeactual.company().url
            }`}
            body={{ period: montReport, digit: 6 }}
            method="POST"
            disabled={loading}
            small={false}
            api={3}
          />{" "}
          <ReactToPrint
            trigger={() => (
              <Button color="primary" variant="outlined" style={{ width: 125 }} disabled={loading}>
                <PrintIcon /> IMPRIMIR
              </Button>
            )}
            content={() => componentRef.current}
          />
        </div>
      </div>
      <CardContent ref={componentRef} align="center" className={classes.padd}>
        <Typography variant="h3" color="textPrimary">
          EJECUCIÓN PRESUPUESTARIA
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          Reporte de {moment(anho + "-" + montReport + "-01").format("MMMM")}
        </Typography>
        <Typography variant="h6" color="textPrimary">
          {sedeactual.description()}
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          RUC: {rucHeadquarters}
        </Typography>
        <div style={{ marginTop: 25 }}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {error ? (
                <Typography variant="body2" color="error">
                  Ocurrió un error al obtener la información. Intente más tarde.
                </Typography>
              ) : (
                <div>
                  <Typography variant="h4" color="textPrimary">
                    RESUMEN DE INGRESOS
                  </Typography>
                  <TableResume data={incomeResume} type="ingreso" />
                  <br />
                  <Typography variant="h4" color="textPrimary">
                    DETALLE DE INGRESOS
                  </Typography>
                  <TableDetail data={incomeDetail} type="ingreso" />
                  <br />
                  <hr />
                  <Typography variant="h4" color="textPrimary">
                    RESUMEN DE GASTOS
                  </Typography>
                  <TableResume data={expenseResume} type="gasto" />
                  <br />
                  <Typography variant="h4" color="textPrimary">
                    DETALLE DE GASTOS
                  </Typography>
                  <TableDetail data={expenseDetail} type="gasto" />
                  <hr />
                </div>
              )}
            </>
          )}
        </div>
      </CardContent>
    </Paper>
  );
};

export default EjecucionPresupuestaria;
