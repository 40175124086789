import React, { useEffect, useState } from "react";
import Page from "src/components/Page";
import { getWorkerInformation } from "src/utils/req.boletas";
import { notification } from "antd";
import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import TableIncidents from "./components/TableIncidents";
import ModalIncidents from "./components/ModalIncidents";
import moment from "moment";
import ModalConfirmDelete from "src/components/ModalConfirmDelete";
import { apiContable } from "src/config/api.contable";

const Incidents = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState({ open: false, data: null });
  const [del, setDel] = useState({ open: false, id: null });

  useEffect(() => {
    obtainData();
  }, []);

  const obtainData = async () => {
    setLoading(true);
    try {
      const res = await getWorkerInformation("incidents");
      const result = res.map((e) => ({
        ...e,
        date_upload: moment(e.date_upload).format("DD/MM/YYYY"),
        fullname: `${e.worker.f_name} ${e.worker.l_name}`,
      }));
      setData([...result]);
    } catch (error) {
      notification["error"]({ message: `Ocurrió un error al realizar la operación` });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page
      title="INCIDENCIAS DE TRABAJADORES"
      helper="COLABORADORES"
      itemComponent={
        <Button
          variant="contained"
          size="large"
          color="primary"
          fullWidth
          onClick={() => setModal({ open: true, data: null })}
        >
          <Add /> AGREGAR
        </Button>
      }
    >
      <TableIncidents data={data} loading={loading} setModal={setModal} setDel={setDel} />

      {modal.open && (
        <ModalIncidents
          open={modal.open}
          setOpen={setModal}
          data={modal.data}
          loading={loading}
          setLoading={setLoading}
          handleRefresh={obtainData}
        />
      )}

      {del.open && (
        <ModalConfirmDelete
          visible={del.open}
          setVisible={setDel}
          reload={obtainData}
          endpoint={`${apiContable}/worker/general/information/${del.id}`}
          title="CONFIRMACIÓN DE ELIMINACIÓN DE ARCHIVO"
          subtitle="¿Seguro que deseea eliminar este archivo? Recuerda que después ya no estará disponible."
          disable={false}
        />
      )}
    </Page>
  );
};

export default Incidents;
