import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import { Spin, notification } from "antd";
import { postWorkerInformation, putWorkerInformation } from "src/utils/req.boletas";
import moment from "moment";
import sedeactual from "src/config/sedeactual";
import { AttachFile } from "@material-ui/icons";
import useWorkers from "src/hook/useWorkers";
import SelectWorkers from "src/components/Selects/SelectWorkers";
import SelectWorkerTypeIncidents, {
  colorsWorkerTypeIncidents,
} from "src/components/Selects/SelectWorkerTypeIncidents";

const ModalIncidents = ({ open, setOpen, data, loading, setLoading, handleRefresh }) => {
  const { workers, loading: load } = useWorkers();
  const [worker, setWorker] = useState(data ? data.worker._id : null);
  const [file, setFile] = useState(null);
  const [type, setType] = useState(data ? data.incidence_type : "");
  const [detail, setDetail] = useState(data ? data.detail : "");
  const [details, setDetails] = useState({});

  const saveRegister = async () => {
    try {
      setLoading(true);

      let form = new FormData();
      if (file) form.append("file", file);
      form.append("detail", detail);
      form.append("worker", worker);
      form.append("incidence_type", type);
      form.append("color", colorsWorkerTypeIncidents[type]);
      if (!data) {
        form.append("date_upload", moment().format("YYYY-MM-DD"));
        form.append("type", "incidents");
        form.append("headquarter", sedeactual.sede_id());
        form.append("company", sedeactual.company()._id);
      }

      if (data) await putWorkerInformation(form, data._id);
      else await postWorkerInformation(form);

      notification["success"]({
        message: `El registro se ${data ? "actualizó" : "guardó"} correctamente.`,
      });
      handleRefresh();
      setOpen({ open: false });
    } catch (error) {
      notification["error"]({ message: `Ocurrió un error al realizar la operación.` });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography align="center" variant="h4" component="p">
          {data ? "EDITAR" : "AGREGAR"} INCIDENCIA
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Spin spinning={loading || load}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormHelperText>TRABAJADORES</FormHelperText>
              <SelectWorkers
                setValue={(e) => {
                  const finder = workers.find((wk) => wk._id === e);
                  setDetails({ ...finder });
                  setWorker(e);
                }}
                value={worker}
                data={workers}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormHelperText>
                <b>NOMBRE:</b> {`${details.f_name ?? "---"} ${details.l_name ?? ""}`}
                <br />
                <b>{details.t_doc ?? "DNI"}:</b> {details.n_doc ?? "---"}
                <br />
                <b>DIRECCIÓN:</b> {details.address ?? "---"}
                <br />
                <b>CELULAR:</b> {details.phone ?? "---"}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectWorkerTypeIncidents value={type} setValue={setType} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Detalle"
                fullWidth
                variant="outlined"
                multiline
                rows={3}
                value={detail}
                onChange={(e) => setDetail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Evidencia"
                variant="outlined"
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {data && data.file ? (
                        <IconButton component="a" target="_blank" href={data.file} rel="noreferrer">
                          <AttachFile />
                        </IconButton>
                      ) : (
                        <span />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Spin>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={() => setOpen({ open: false })}>
          CANCELAR
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={saveRegister}
          disabled={loading || !detail || !worker || !type}
        >
          {data ? "ACTUALIZAR" : "AGREGAR"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalIncidents;
