import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ExportarExcel from "src/components/Global/ExportarExcel";
import { Spin } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Page from "src/components/Page";
import { getExportInventoryCategory, getInventoryCategory } from "../../requests";
import "./Report.css";
import sedeactual from "src/config/sedeactual";

const CategoryReport = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    obtainData();
  }, []);

  const obtainData = async () => {
    setIsLoading(true);

    try {
      const res = await getInventoryCategory();
      setData(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Page
      title="Reporte por Categoria"
      helper="Inventario"
      itemComponent={
        <ExportarExcel
          name={`REPORTE INVENTARIO POR CATEGORÍAS`}
          endpoint={`report/export/inventario/categoria/${sedeactual.sede_id()}`}
          method="GET"
          disabled={isLoading || !data}
          api={2}
        />
      }
    >
      <Spin spinning={isLoading}>
        <br />
        {data.length > 0 && !isLoading && (
          <TableContainer style={{ maxHeight: "80vh" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {data[0].map((item, index) => {
                    if (index === 0)
                      return (
                        <TableCell id="sticky-header-inventory-1" style={{ zIndex: 11 }}>
                          {item}
                        </TableCell>
                      );
                    else if (index === 1)
                      return (
                        <TableCell id="sticky-header-inventory-2" style={{ zIndex: 11 }}>
                          {item}
                        </TableCell>
                      );
                    return <TableCell>{item}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => {
                  if (index === 0) return null;

                  return (
                    <TableRow key={index} style={{}}>
                      {item.map((item, index) => {
                        if (index === 0)
                          return <TableCell id="sticky-header-inventory-1">{item}</TableCell>;
                        else if (index === 1)
                          return <TableCell id="sticky-header-inventory-2">{item}</TableCell>;
                        return <TableCell>{item}</TableCell>;
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Spin>
    </Page>
  );
};

export default CategoryReport;
