import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Paper,
  Card,
  CardContent,
  Typography,
  FormControl,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import TablaPResumenIngresos from "./TablaPResumenIngresos.jsx";
import TablaPResumenGastos from "./TablaPResumenGastos.jsx";
import TablaPDetalle from "./TablaPDetalle.jsx";
import { Link } from "react-router-dom";
import { Button, notification } from "antd";
import sedeactual from "../../../config/sedeactual.js";
import UserServices from "../../../services/UserServices";
import SelectSedes from "../../Usuarios/SelectSedes";
import Spinner from "../../../components/Global/Spinner";
import { apiCuentas } from "../../../config/api.contable";
import axios from "axios";
import { getHeadquarters } from "../../../api/api.contable";
import ExportarExcel from "../../../components/Global/ExportarExcel";

const useStyles = makeStyles(() => ({
  excel: {
    backgroundColor: "#1B9637",
    color: "white",
    "&:hover": {
      backgroundColor: "#0B8025",
    },
  },
  select: {
    textAlign: "left",
  },
  subtitle: {
    margin: "60px 0 30px",
  },
  selectsedes: {
    maxWidth: "calc(100% - 650px)",
    minWidth: 300,
    textAlign: "left",
    marginRight: "5px",
  },
}));

const Presupuesto = (props) => {
  let anho = sedeactual.getAnio();
  const classes = useStyles();
  const componentRef = useRef();
  const rucHeadquarters = sedeactual.getRuc();
  const [sede, setSede] = useState(sedeactual.get());
  const [reportModeSelected, setReportModeSelected] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sedes, setSedes] = useState([]);

  const [incomeResume, setIncomeResume] = useState([]);
  const [incomeDetails, setIncomeDetails] = useState([]);
  const [expensesResume, setExpensesResume] = useState([]);
  const [expensesDetails, setExpensesDetails] = useState([]);

  const getData = async () => {
    setLoading(true);
    try {
      let income = await axios.get(
        `${apiCuentas}/budget/${sede}/${anho}/${sedeactual.company().url}`
      );
      setIncomeResume(income.data.incomeResume);
      setIncomeDetails(income.data.incomeDetails);
      setExpensesDetails(income.data.expensesDetails);
      setExpensesResume(income.data.expensesResume);
      setLoading(false);
    } catch (error) {
      setError(true);
      notification["error"]({
        message: `${error}`,
        description: "No se logró obtener la información del reporte.",
      });
      setLoading(false);
    }
  };

  const getSedes = async () => {
    try {
      let result = await getHeadquarters();
      setSedes(result.data);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se logró obtener las sedes.",
      });
    }
  };

  useEffect(() => {
    getSedes();
  }, []);

  useEffect(() => {
    getData();
  }, [sede]);

  const content = (
    <Paper style={{ padding: 25 }}>
      <div align="right">
        <Link to={{ pathname: "/reportes" }}>
          <Button icon="left" style={{ float: "left" }} variant="outlined">
            Volver
          </Button>
        </Link>

        {(sedeactual.getRol() === "supernap" ||
          sedeactual.getRol() === "superadmin") && (
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            className={classes.selectsedes}
          >
            <SelectSedes
              defaultValue={sede}
              data={sedes}
              onChangeSelect={(e) => {
                setSede(e);
              }}
            />
          </FormControl>
        )}
        <ExportarExcel
          name={`PRESUPUESTO_${moment(Date.now()).format()}`}
          endpoint={`budget/export-excel/${sede}/${sedeactual.getAnio()}/${
            sedeactual.company().url
          }`}
          body={{}}
          method="POST"
          disabled={loading}
          small={false}
          api={3}
        />

        <ReactToPrint
          trigger={() => (
            <Button fullWidth color="primary" variant="outlined">
              <PrintIcon /> IMPRIMIR
            </Button>
          )}
          content={() => componentRef.current}
        />
      </div>
      <Card ref={componentRef} align="center">
        <CardContent>
          <Typography variant="h3" color="textPrimary">
            PRESUPUESTO
          </Typography>
          <Typography
            variant="subtitle2"
            color="textPrimary"
            style={{ textTransform: "capitalize" }}
          >
            {reportModeSelected === 0 ? (
              <>Reporte anual {anho}</>
            ) : (
              `${moment(anho + "-" + reportModeSelected + "-01").format(
                "MMMM"
              )} del ${anho}`
            )}
          </Typography>
          <Typography variant="h6" color="textPrimary">
            {sedeactual.description()}
          </Typography>
          <Typography variant="subtitle1" color="textPrimary">
            RUC: {rucHeadquarters}
          </Typography>
          <div style={{ marginTop: 25 }}>
            {loading ? (
              <Spinner />
            ) : (
              <>
                {error ? (
                  <Typography variant="h6" color="error">
                    Ocurrió un error al obtener la información. Intente más
                    tarde.
                  </Typography>
                ) : (
                  <div>
                    <Typography variant="h5" color="textSecondary">
                      INGRESOS - PRESUPUESTO DE FUENTES DE FINANCIAMIENTO -
                      RESUMEN
                    </Typography>
                    {incomeResume.length > 0 && (
                      <TablaPResumenIngresos dataTable={incomeResume} />
                    )}
                    <br />
                    <Typography variant="h5" color="textSecondary">
                      INGRESOS - PRESUPUESTO DE FUENTES DE FINANCIAMIENTO -
                      DETALLE
                    </Typography>
                    {incomeDetails.length > 0 && (
                      <TablaPDetalle dataTable={incomeDetails} />
                    )}
                    <br />
                    <hr />
                    <Typography variant="h5" color="textSecondary">
                      RESUMEN DEL PRESUPUESTO DE OPERACIÓN E INVERSIÓN
                    </Typography>
                    {expensesResume.length > 0 && (
                      <TablaPResumenGastos dataTable={expensesResume} />
                    )}
                    <br />
                    <Typography variant="h5" color="textSecondary">
                      PRESUPUESTO DE OPERACIÓN
                    </Typography>
                    {expensesDetails.length > 0 && (
                      <TablaPDetalle dataTable={expensesDetails} />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </CardContent>
      </Card>
    </Paper>
  );
  return content;
};

export default Presupuesto;
