import React from "react";
import { Select } from "antd";

export const listCivilStatus = ["SOLTERO(A)", "CASADO(A)", "VIUDO(A)", "DIVORCIADO(A)"];

const SelectCivilStatus = ({ setValue, value }) => {
  const { Option } = Select;

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const filterOptions = (inputValue, option) => {
    const title = removeAccents(option.props.title.toLowerCase());
    return title.includes(inputValue.toLowerCase());
  };

  return (
    <Select
      size="large"
      style={{ width: "100%" }}
      showSearch
      value={value}
      onChange={setValue}
      filterOption={filterOptions}
    >
      <Option key={0} value={null} title={""}>
        - Ninguno -
      </Option>
      {listCivilStatus.map((i) => (
        <Option value={i} title={i} key={i}>
          {i}
        </Option>
      ))}
    </Select>
  );
};
export default SelectCivilStatus;
