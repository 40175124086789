import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  DialogActions,
} from "@material-ui/core";
import React from "react";

const ModalPolitica = ({ visible, setVisible, company }) => {
  return (
    <Dialog open={visible} onClose={() => setVisible(false)}>
      <DialogTitle>
        <Typography component="h1" variant="h6" align="center">
          <b>POLÍTICA DE PRIVACIDAD DE PROTECCIÓN DE DATOS DEL CLIENTE</b>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" align="justify" color="textSecondary">
          El Cliente autoriza expresamente a {company.description} a hacer uso y tratar
          los datos personales que le proporciona en la contratación del
          Producto o Servicio, así como en la información que se derive de su
          uso. El tratamiento de la información tiene como finalidad la
          ejecución del presente Contrato. El cliente puede ejercer los derechos
          de acceso, actualización, inclusión, rectificación, cancelación y
          oposición de sus datos personales enviando una comunicación escrita a{" "}
          {company.description}.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => setVisible(false)}
        >
          cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalPolitica;
