import React from "react";
import { Table } from "antd";

const TablaBalance = (props) => {
  const columns = [
    {
      title: "N° Cuenta",
      dataIndex: "NroCuenta",
      key: "NroCuenta",
    },
    {
      title: "Nombre de la Cuenta",
      dataIndex: "NameCuenta",
      key: "NameCuenta",
      width: "25%",
    },
    {
      title: "SALDOS ACUMULADOS",
      children: [
        {
          title: "Debe",
          dataIndex: "DebeS",
          key: "DebeS",
          align: "right",
          render: (text, record, index) =>
            props.data.length - 3 == index ? (
              <div
                className={
                  record.DebeS == text ? "text-success" : "text-danger"
                }
              >
                {text}
              </div>
            ) : (
              <div>{text}</div>
            ),
        },
        {
          title: "Haber",
          dataIndex: "HaberS",
          key: "HaberS",
          align: "right",
          render: (text, record, index) =>
            props.data.length - 3 == index ? (
              <div
                className={
                  record.HaberS == text ? "text-success" : "text-danger"
                }
              >
                {text}
              </div>
            ) : (
              <div>{text}</div>
            ),
        },
      ],
    },
    {
      title: "MOVIMIENTOS DEL MES",
      children: [
        {
          title: "Debe",
          dataIndex: "DebeMes",
          key: "DebeMes",
          align: "right",
        },
        {
          title: "Haber",
          dataIndex: "HaberMes",
          key: "HaberMes",
          align: "right",
        },
      ],
    },
    {
      title: "CUENTAS DE ACTIVO",
      children: [
        {
          title: "Debe",
          dataIndex: "DebeActivo",
          key: "DebeActivo",
          align: "right",
        },
        {
          title: "Haber",
          dataIndex: "HaberActivo",
          key: "HaberActivo",
          align: "right",
        },
      ],
    },
    {
      title: "GG PP NATURALEZA",
      children: [
        {
          title: "Debe",
          dataIndex: "DebeGG",
          key: "DebeGG",
          align: "right",
        },
        {
          title: "Haber",
          dataIndex: "HaberGG",
          key: "HaberGG",
          align: "right",
        },
      ],
    },
    {
      title: "GG PP FUNCIÓN",
      children: [
        {
          title: "Debe",
          dataIndex: "DebePP",
          key: "DebePP",
          align: "right",
        },
        {
          title: "Haber",
          dataIndex: "HaberPP",
          key: "HaberPP",
          align: "right",
        },
      ],
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={props.data}
        bordered
        pagination={false}
        loading={props.loading}
      />
    </>
  );
};

export default TablaBalance;
