import React, { useState } from "react";
import axios from "axios";
import { apiContable } from "../../../config/api.contable";
import {
  Grid,
  Typography,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
} from "@material-ui/core";
import { notification } from "antd";

const ModalEditCostos = (props) => {
  const { visible, setVisible, reload, dataCostos, setLoading } = props;
  const [code, setCode] = useState(dataCostos.code);
  const [description, setDescription] = useState(dataCostos.description);

  const editCost = async () => {
    if (
      code !== "" &&
      code != "01" &&
      code != "03" &&
      code != "03" &&
      code.toString().length > 0 &&
      code.toString().length < 3 &&
      description !== ""
    ) {
      setLoading(true);
      try {
        await axios.put(`${apiContable}/cost/${dataCostos._id}`, {
          code: code,
          description: description,
        });
        reload();
        notification["success"]({
          message: "ÉXITO!",
          description: "El registro de guardó correctamente.",
        });
        setVisible(false);
      } catch (error) {
        notification["error"]({
          message: "ERROR!",
          description: `El código ya fue ingresado. Ocurrió un error al agregar el registro. ${error}`,
        });
      } finally {
        setLoading(false);
      }
    } else {
      notification["info"]({
        message: "El código y descripción son requeridos!",
        description:
          "El código es único. El código ya fué ingresado. La longitud máxima es 2 caracteres.",
      });
    }
  };

  return (
    <Dialog open={visible} onClose={() => setVisible(false)} fullWidth>
      <DialogTitle>
        <Typography variant="h3" color="textSecondary">
          EDITAR COSTOS
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Código"
              variant="outlined"
              fullWidth
              type="number"
              value={code}
              onChange={(ev) => setCode(ev.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Descripción"
              variant="outlined"
              fullWidth
              value={description}
              onChange={(ev) => setDescription(ev.target.value)}
            />
          </Grid>
        </Grid>
        <br />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setVisible(false)}
        >
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={() => editCost()}>
          ACTUALIZAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditCostos;
