import React from "react";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));
const BtnView = (props) => {
  const classes = useStyles();
  return (
    <>
      {props.small ? (
        <Fab
          size="small"
          style={{ backgroundColor: "#00bfa5", color: "white" }}
          aria-label="add"
          className={classes.margin}
        >
          <VisibilityIcon />
        </Fab>
      ) : (
        <Button
          variant="contained"
          style={{ backgroundColor: "#00bfa5", color: "white" }}
          className={classes.button}
          startIcon={<VisibilityIcon />}
        >
          EDITAR
        </Button>
      )}
    </>
  );
};

export default BtnView;
