import axios from "axios";

const consultRuc = async (ruc, type = "ruc") => {
  let res = await axios.post(`https://api.migo.pe/api/v1/${type}`, {
    token: "sV9ao0onev11xHrWkJSKdLH24QDvYAI6OPpTYVzTLzobxFIBzi5RY8SY6EjM",
    [type]: ruc,
  });
  return res.data;
};
export default consultRuc;

export const customConsultRuc = async (ndoc) => {
  if (!ndoc && !Number(ndoc)) return { success: false };
  const ln = ndoc.toString().length;

  const t_doc = ln === 11 ? "ruc" : ln === 8 ? "dni" : null;
  const n_doc = ndoc.toString().trim();

  if (!t_doc) return { success: false };

  const res = await axios.post(`https://api.migo.pe/api/v1/${t_doc}`, {
    token: "sV9ao0onev11xHrWkJSKdLH24QDvYAI6OPpTYVzTLzobxFIBzi5RY8SY6EjM",
    [t_doc]: n_doc,
  });

  return {
    success: true,
    name: res.data.nombre_o_razon_social ?? res.data.nombre,
    t_doc,
    n_doc: res.data.ruc ?? res.data.dni,
    address: res.data.direccion ?? "",
  };
};
