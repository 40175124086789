import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core/";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const HomeOne = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h1" color="textSecondary">
        Sistema Contable
      </Typography>
      <Typography variant="h2">
        <b>NAPCONTABLE</b>
        <br />
        <br />
      </Typography>
      <Button
        href="https://www.netappperu.com/"
        target="_blank"
        color="primary"
      >
        Netapp Perú
      </Button>
    </div>
  );
};

export default HomeOne;
