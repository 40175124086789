import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Modal, notification, Icon } from "antd";
import anio from "src/config/anio";
import sedeactual from "src/config/sedeactual";
import SelectPeriodo from "src/components/Selects/SelectPeriodo";
import TableVoucher from "./TableVoucher";
import SelectSedes from "src/components/Selects/SelectSedes";
import {
  getOrigins,
  deleteVoucher,
  getHeadquarters,
} from "src/api/api.contable";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import Page from "src/components/Page/Page";
import moment from "moment";

const ListVoucher = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [periodo, setperiodo] = useState(
    Number(search.split("=")[1]) || moment(Date.now()).month() + 1
  );
  const [sede, setsede] = useState(sedeactual.get());
  const [origins, setorigins] = useState([]);
  const [id, setid] = useState(null);
  const [sedes, setSedes] = useState([]);
  const [actualizar, setactualizar] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    if (queryParams.has("period")) {
      queryParams.delete("period");
      history.replace({
        search: queryParams.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function showModal(id) {
    setid(id);
    setVisible(true);
  }

  function handleCancel() {
    setVisible(false);
  }

  function handleOk() {
    EliminarVoucher(id);
    setVisible(false);
  }

  const getSedes = async () => {
    let result = await getHeadquarters();
    setSedes(result.data);
  };

  const getOriginsApi = async () => {
    let res = await getOrigins();
    setorigins(res);
  };

  console.log("sedeactual.getRol():", sedeactual.getRol());
  useEffect(() => {
    getOriginsApi();
    if (
      sedeactual.getRol() === "supernap" ||
      sedeactual.getRol() === "superadmin"
    ) {
      getSedes();
    }
  }, []);

  async function EliminarVoucher(id) {
    const res = await deleteVoucher(id);

    if (res.status === 200) {
      notification.open({
        message: "Eliminar Voucher",
        description: "La cuenta fue eliminada satisfactoriamente.",
        icon: <Icon type="check" style={{ color: "#49E02D" }} />,
        placement: "bottomRight",
        key: `open${Date.now()}`,
      });
      setactualizar({ ...true });
    } else {
      notification.open({
        message: "Eliminar Voucher",
        description: "Ocurrio un error al eliminar la cuenta.",
        placement: "bottomRight",
        icon: <Icon type="close" style={{ color: "#FE2E27" }} />,
        key: `open${Date.now()}`,
      });
    }
  }
  return (
    <Page helper="VOUCHER" title="LISTADO DE VOUCHERS">
      <Grid container spacing={2}>
        {(sedeactual.getRol() === "supernap" ||
          sedeactual.getRol() === "superadmin") && (
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="textSecondary">
              Seleccionar empresa
            </Typography>
            <SelectSedes
              defaultValue={sede}
              data={sedes}
              onChangeSelect={(e) => {
                setsede(e);
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Typography variant="body1" color="textSecondary">
            Seleccionar periodo
          </Typography>
          <SelectPeriodo
            defaultValue={periodo}
            onChangeSelect={(e) => {
              setperiodo(e);
              setactualizar({ ...true });
            }}
          />
        </Grid>
        {sedeactual.validar(sede) && sedeactual.getRol() !== "usuario" ? (
          <Grid item xs={12} container justify="space-between">
            <Box>
              <Link to="/cajarapida">
                <Button color="primary" variant="contained">
                  Caja Rápida
                </Button>
              </Link>{" "}
              <Link to="/createvoucher">
                <Button color="primary" variant="outlined">
                  Agregar Voucher
                </Button>
              </Link>
            </Box>
            <Link to="/import-voucher">
              <Button color="primary" variant="outlined">
                IMPORTAR VOUCHER
              </Button>
            </Link>
          </Grid>
        ) : null}
      </Grid>
      <br />
      <Modal
        onOk={handleOk}
        onCancel={handleCancel}
        title="Eliminar"
        visible={visible}
      >
        <p>¿Seguro que desea eliminar el Voucher?</p>
      </Modal>
      {origins.length > 0 && (
        <TableVoucher
          sede={sede}
          origins={origins}
          actualizar={actualizar}
          year={anio.get()}
          showModal={(e) => showModal(e)}
          loading={true}
          period={periodo}
        />
      )}
    </Page>
  );
};

export default ListVoucher;
