import React from "react";
import { TextField } from "@material-ui/core";

const PartNine = ({ errors, register, eInf }) => {
  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      fullWidth
      label="Medidas a tomar *"
      name="metrics"
      defaultValue={Boolean(eInf) ? eInf.metrics : ""}
      variant="outlined"
      multiline
      rows={4}
      inputRef={register({ required: true })}
      error={Boolean(errors?.metrics)}
    />
  );
};

export default PartNine;
