import React, { useState, useEffect } from "react";
import { makeStyles, Box, Button, FormHelperText } from "@material-ui/core";
import Page from "../../components/Page";
import TableIntangibles from "./components/Intangibles/TableIntangibles";
import AddIcon from "@material-ui/icons/Add";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import sedeactual from "../../config/sedeactual";
import ModalAddIntangibles from "./components/Intangibles/components/ModalAddIntangibles";
import { getFixedAssets, getCategoryType, getLocations } from "./requests";
import SelectLocations from "./SelectLocations";
import LoaderBackdrop from "../../components/Global/LoaderBackdrop";
import ExportarExcel from "../../components/Global/ExportarExcel";
import { Dropdown, notification } from "antd";
import { MenuDropDown } from "./Memorandums";

const useStyles = makeStyles((theme) => ({
  buttonDisable: {
    margin: "10px 0",
    backgroundColor: "#f0f0f0",
    color: "#BD1214",
  },
  buttonEnable: {
    margin: "10px 0",
    backgroundColor: "#f0f0f0",
    color: "#4caf50",
  },
}));

const typeAsset = "intangibles";

const Intangibles = () => {
  const classes = useStyles();
  const [idLocation, setIdLocation] = useState(null);
  const [intangibles, setIntangibles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleModalAdd, setVisibleModalAdd] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [locations, setLocations] = useState([]);
  const [memorandum, setMemorandum] = useState({ open: false, type: "", data: [], keys: [] });

  const obtainData = async () => {
    try {
      const res = await getCategoryType(typeAsset);
      res.sort((a, b) => (a.name < b.name ? -1 : 1));
      setCategories(res);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al obtener la información.",
        description: `${error}`,
      });
    }
  };

  const obtainLocation = async () => {
    setLoading(true);
    try {
      const res = await getLocations();
      setLocations(res.data);
      if (res.data.length > 0) {
        setIdLocation(res.data[0]._id);
      }
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al obtener la información.",
        description: `${error}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const obtainAssets = async () => {
    setLoading(true);
    try {
      const result = await getFixedAssets(typeAsset, sedeactual.get(), isActive, idLocation);
      setIntangibles(result);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al obtener la información.",
        description: `${error}`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    obtainData();
    obtainLocation();
  }, []);

  useEffect(() => {
    if (idLocation) {
      obtainAssets();
    }
  }, [idLocation, isActive]);

  return (
    <Page
      title="INTANGIBLES"
      helper="INVENTARIO"
      itemComponent={
        <>
          <Dropdown
            overlay={
              <MenuDropDown
                onClick={(e) => setMemorandum({ ...memorandum, open: true, type: e.key })}
                toTransfer={false}
              />
            }
            disabled={
              memorandum.data.length === 0 || sedeactual.getRol() === "usuario"
            }
          >
            <Button color="primary" variant="contained">
              MEMORANDUM
            </Button>
          </Dropdown>{" "}
          <Button
            className={!isActive ? classes.buttonEnable : classes.buttonDisable}
            variant="contained"
            onClick={() => setIsActive(!isActive)}
            disabled={!idLocation ? true : false}
          >
            {!isActive ? <PersonAddIcon /> : <PersonAddDisabledIcon />}
            {!isActive ? "HABILITADO" : "DESHABILITADO"}
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            onClick={() => setVisibleModalAdd(true)}
            disabled={ sedeactual.getRol() === "usuario" || (idLocation ? false : true)}
          >
            <AddIcon /> AGREGAR
          </Button>{" "}
          <ExportarExcel
            name={`INTANGIBLES_${Date.now().toString()}`}
            endpoint={`export-fixed-asset/${sedeactual.get()}/${typeAsset}/${idLocation}/${
              sedeactual.company().url
            }`}
            method="GET"
            disabled={loading || idLocation ? false : true}
            small={false}
            api={2}
          />
        </>
      }
    >
      {loading && <LoaderBackdrop />}
      {visibleModalAdd && (
        <ModalAddIntangibles
          visible={visibleModalAdd}
          setVisible={setVisibleModalAdd}
          reloadFunction={obtainAssets}
          categories={categories}
          idLocation={idLocation}
          sede={sedeactual.get()}
          company={sedeactual.company().url}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      <FormHelperText>LOCACIONES</FormHelperText>
      <SelectLocations
        defaultValue={idLocation}
        onChangeSelect={(e) => setIdLocation(e)}
        data={locations}
      />
      <Box pb={1} />
      <TableIntangibles
      onlyview={sedeactual.getRol() === "usuario"}
        dataTable={intangibles}
        reloadFunction={obtainAssets}
        idLocation={idLocation}
        categories={categories}
        setLoading={setLoading}
        loading={loading}
        isActive={isActive}
        memorandum={memorandum}
        setMemorandum={setMemorandum}
      />
    </Page>
  );
};

export default Intangibles;
