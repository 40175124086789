import React from "react";
import { Box, Grid, Typography, Divider } from "@material-ui/core";
import moment from "moment";

const Header = ({ company }) => {
  return (
    <Box p={4}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h1" align="center">
            <b>REGISTRO DE INCIDENCIAS</b>
          </Typography>
        </Grid>
        {company.logo && (
          <Grid item xs={12} align="center">
            <img src={company.logo} height="80px" alt={company.description} />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Typography component="h3" variant="h6" align="left">
            {company.description}
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography component="h3" variant="h6" align="right">
            RUC: {company.ruc}
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" align="left">
            {company.address}
            {" - "}
            {moment(Date.now()).format("DD [de] MMMM [del] YYYY")}
          </Typography>
          <Divider />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Header;
