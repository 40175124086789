import React, { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash/fp";
import useForm from "react-hook-form";
import moment from "moment";
import { apiInventario } from "../../../../config/api.contable";
import { notification } from "antd";
import {
  Grid,
  FormHelperText,
  Typography,
  CardActions,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { getLocations } from "../../requests";

const ModalTransferEquipos = (props) => {
  const { visible, setVisible, reload, dataSource, nameLocation, idLocation } =
    props;
  const { register, handleSubmit, errors } = useForm();
  const [selectDestino, setSelectDestino] = useState("");
  const [dataLocacion, setDataLocacion] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );

  const obtainLocation = async () => {
    try {
      const res = await getLocations();
      const result = res.data.filter((e) => e._id !== idLocation);
      setDataLocacion(result);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al obtener la información.",
        description: `${error}`,
      });
    }
  };

  useEffect(() => {
    obtainLocation();
    // eslint-disable-next-line
  }, []);

  const addTerreno = async (newData) => {
    const dataTerreno = {
      origin: idLocation,
      destination: selectDestino,
      description: newData.description,
      dateTransfer: selectedDate,
    };

    await axios
      .post(`${apiInventario}/transfer-asset/${dataSource._id}`, dataTerreno)
      .then((res) => {
        notification["success"]({
          message: "¡ÉXITO!",
          description: "El activo se transfirió correctamente.",
        });
        reload();
      })
      .catch((error) => {
        notification["error"]({
          message: "¡ERROR!",
          description: `Ocurrió un error al transferir el activo. ${error}`,
        });
      })
      .finally(() => {
        setVisible(false);
      });
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={visible}>
      <DialogTitle>
        <Typography variant="h3">Transferir</Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(addTerreno)} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                label="Origen"
                variant="outlined"
                fullWidth
                name="origin"
                value={nameLocation}
                defaultValue={nameLocation}
                disabled
                inputRef={register({
                  required: true,
                  maxLength: 120,
                  minLength: 1,
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Destino</InputLabel>
                <Select
                  label="Destino"
                  fullWidth
                  value={selectDestino}
                  onChange={(e) => setSelectDestino(e.target.value)}
                >
                  {dataLocacion &&
                    dataLocacion.map((dataL) => (
                      <MenuItem
                        value={dataL._id}
                        disabled={dataL._id === idLocation}
                      >
                        {dataL.name + " - " + dataL.description}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Fecha de operación"
                variant="outlined"
                fullWidth
                type="date"
                value={selectedDate}
                onChange={(event) =>
                  setSelectedDate(event.target.value.toUpperCase())
                }
                inputProps={{ max: moment(Date.now()).format("YYYY-MM-DD") }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                label="Motivo"
                variant="outlined"
                fullWidth
                name="description"
                inputRef={register({
                  required: true,
                  maxLength: 150,
                })}
              />
              {_.get("description.type", errors) === "required" && (
                <FormHelperText error>El motivo es requerido.</FormHelperText>
              )}
              {_.get("description.type", errors) === "maxLength" && (
                <FormHelperText error>
                  La longitud máxima de caracteres es 150.
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                label="Equipo o maquinaria"
                variant="outlined"
                fullWidth
                name="account_name"
                value={dataSource.description}
                defaultValue={dataSource.description}
                disabled
                inputRef={register({
                  required: true,
                  maxLength: 120,
                  minLength: 1,
                })}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <CardActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setVisible(false)}
          >
            Cancelar
          </Button>

          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={!selectDestino}
          >
            Registrar
          </Button>
        </CardActions>
      </form>
    </Dialog>
  );
};

export default ModalTransferEquipos;
