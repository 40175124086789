import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import TablePagosCobros from "./components/TablePagosCobros";
import { getVouchersProvisioning, getOrigins } from "../../../api/api.contable";
import sedeactual from "../../../config/sedeactual";
import { notification } from "antd";
import napformat from "../../../config/napformat";
import { Box, Tab, Tabs, Grid } from "@material-ui/core";
import SelectMonthsNum from "src/components/Selects/SelectMonthsNum";

const PagosCobros = () => {
  const [value, setValue] = useState(0);
  const [month, setMonth] = useState(1);
  const [loading, setLoading] = useState(false);
  const [voucherProvisioning, setVoucherProvisioning] = useState({
    debe: null,
    haber: null,
  });
  const [filterVoucherProvisioning, setFilterVoucherProvisioning] = useState({
    debe: null,
    haber: null,
  });

  const getProvisioning = async () => {
    setLoading(true);
    try {
      let provisioning = await getVouchersProvisioning(
        sedeactual.get(),
        sedeactual.getAnio(),
        sedeactual.company().url
      );
      let resOrigins = await getOrigins();

      let newdebe = provisioning.debe.map((el) => {
        let findAmount = el.details.find((ev) =>
          ["12", "14", "16", "41", "42", "46"].includes(
            ev.account.number.toString().substring(0, 2)
          )
        );
        let findOrigin = resOrigins.find((ev) => ev.id === el.origin);

        return {
          ...el,
          origin: findOrigin.description,
          ruc: el.customer.ruc,
          comment: el.details[0].comment,
          amount: findAmount ? napformat(findAmount.amount) : null,
        };
      });

      let newhaber = provisioning.haber.map((el) => {
        let amountAccount = el.details.find((el) =>
          ["12", "14", "16", "41", "42", "46"].includes(
            el.account.number.toString().substring(0, 2)
          )
        );
        let findOrigin = resOrigins.find((ev) => ev.id === el.origin);

        return {
          ...el,
          origin: findOrigin.description,
          ruc: el.customer.ruc,
          comment: el.details[0].comment,
          amount: amountAccount ? napformat(amountAccount.amount) : null,
        };
      });

      setVoucherProvisioning({ debe: newdebe, haber: newhaber });
      filterData();
    } catch (error) {
      notification["error"]({ message: "Ocurrió un error" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProvisioning();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterData = () => {
    const tempdebe = voucherProvisioning?.debe?.filter(
      (ev) => ev.period === month
    );
    const temphaber = voucherProvisioning?.haber?.filter(
      (ev) => ev.period === month
    );
    setFilterVoucherProvisioning({
      debe: tempdebe,
      haber: temphaber,
    });
  };

  useEffect(() => {
    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, voucherProvisioning]);

  return (
    <Page
      helper="VOUCHER"
      title="Pagos y Cobros"
      itemComponent={
        <Grid container style={{ width: 150 }}>
          <Grid item xs={12}>
            <SelectMonthsNum month={month} setMonth={setMonth} />
          </Grid>
        </Grid>
      }
    >
      <Tabs
        value={value}
        onChange={(e, i) => setValue(i)}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label={<b>COBROS</b>} {...a11yProps(0)} />
        <Tab label={<b>PAGOS</b>} {...a11yProps(1)} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <TablePagosCobros
          data={filterVoucherProvisioning && filterVoucherProvisioning.debe}
          loading={loading}
          type="cobros"
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TablePagosCobros
          data={filterVoucherProvisioning && filterVoucherProvisioning.haber}
          loading={loading}
          type="pagos"
        />
      </TabPanel>
    </Page>
  );
};

export default PagosCobros;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index ? <Box pt={1}>{children}</Box> : null}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
