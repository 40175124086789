import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button, TextField } from "@material-ui/core";
import { notification } from "antd";
import AuthenticationService from "../../../../auth/AuthenticationService";

const useStyles = makeStyles((theme) => ({
  root: {},
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

const LoginForm = (props) => {
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const { className, company, ...rest } = props;

  const classes = useStyles();

  const [user, setuser] = useState("");
  const [password, setpassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    let obj = {
      username: user,
      password: password,
      url: company.url,
      company: company,
    };
    notification["info"]({
      message: "Espere...",
      description: "Estamos validando la información.",
    });
    AuthenticationService.login(obj)
      .then((res) => {
        notification["success"]({
          message: "Login exitoso",
          description: "Autentificación correcta.",
        });
        setRedirectToReferrer(true);
      })
      .catch((error) => {
        notification["error"]({
          message: "Datos erróneos",
          description: "El usuario y/o la contraseña es incorrecta.",
        });
      });
  };

  if (redirectToReferrer) {
    window.location.reload();
  }

  return (
    <form
      {...rest}
      className={clsx(classes.root, className)}
      onSubmit={handleSubmit}
    >
      <div className={classes.fields}>
        <TextField
          fullWidth
          label="Usuario"
          name="email"
          onChange={(e) => setuser(e.target.value)}
          value={user}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Contraseña"
          name="password"
          onChange={(e) => setpassword(e.target.value)}
          type="password"
          value={password}
          variant="outlined"
        />
      </div>
      <Button
        className={classes.submitButton}
        color="primary"
        type="submit"
        variant="contained"
      >
        Ingresar
      </Button>
    </form>
  );
};

LoginForm.propTypes = {
  className: PropTypes.string,
};

export default LoginForm;
