import React, { useEffect, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import { notification } from "antd";
import napformat from "../../../../config/napformat";
import sedeactual from "../../../../config/sedeactual";
import {
  Box,
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  colors,
  FormHelperText,
} from "@material-ui/core";
import logo from "../../../../assets/img/logoadmin.png";
import { getTipoDocs, getOrigins } from "../../../../api/api.contable";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(6),
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  dates: {
    padding: theme.spacing(2),
    backgroundColor: colors.grey[100],
  },
  marginBottom: {
    marginBottom: theme.spacing(4),
  },
}));

const Details = ({ invoice }) => {
  const classes = useStyles();
  const sede = useState(sedeactual.description());
  const [typeDocDesc, setTypeDocDesc] = useState("");
  const [originDesc, setOriginDesc] = useState("");

  let typeProv = invoice.type_provisioning;
  let value = 0;
  let valDebe = 0;
  let valHaber = 0;

  invoice.details.forEach((item) => {
    if (typeProv && typeProv === item.type_detail) {
      value += item.amount;
    }
    if (item.type_detail === "DEBE") valDebe += item.amount;
    else valHaber += item.amount;
  });

  if (valDebe === valHaber) typeProv = null;

  const obtainDocTypes = async () => {
    try {
      let result = await getTipoDocs();
      const typeDoc = result.filter((type) => parseInt(type.id) === invoice.t_doc);
      setTypeDocDesc(typeDoc[0].description);
    } catch (error) {
      notification["error"]({
        message: `${error}`,
        description: "No se pudo obtener los tipos de documentos.",
      });
    }
  };

  const getOriginsData = async () => {
    const data = await getOrigins();
    const oriVoucher = data.filter((origin) => origin.id === invoice.origin);
    setOriginDesc(oriVoucher[0].description);
  };

  useEffect(() => {
    obtainDocTypes();
    getOriginsData();
  }, []);

  return (
    <Box p={10}>
      <Grid container justify="space-between">
        <Grid item>
          <img alt="Brand" src={logo} />
        </Grid>
        <Grid item>
          <Typography align="right" component="h3" variant="h1">
            N° VOUCHER {invoice.n_voucher}
          </Typography>
        </Grid>
      </Grid>
      <Grid alignItems="center" container justify="space-between">
        <Grid item>
          <Typography variant="h5">{`napcontable.com/${sedeactual.company().url}`}</Typography>
        </Grid>
        <br />
        <Grid item xs={12}>
          <Typography align="left">
            <b>INSTITUCIÓN: </b>
            {sede} - CONTABILIDAD {sedeactual.getAnio()}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={clsx(classes.marginTop, classes.dates)} container justify="space-between">
        <Grid item>
          <Typography component="h4" gutterBottom variant="overline">
            Período
          </Typography>
          <Typography>
            {moment(`${invoice.period}-01-${invoice.year}`).format("MMM YYYY")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h4" gutterBottom variant="overline" align="right">
            Fecha de Operación
          </Typography>
          <Typography align="right">
            {moment(invoice.operation_date).format("DD MMM YYYY")}
          </Typography>
        </Grid>
      </Grid>
      <div>
        <br />
        <Typography component="h4" gutterBottom variant="overline">
          DATOS DEL VOUCHER
        </Typography>
        <Typography>
          <b>ORIGEN:</b> {originDesc.toUpperCase()} <br />
          <b>TIPO DE DOCUMENTO:</b> {typeDocDesc.toUpperCase()} <br />
          {/* <b>SERIE:</b> {invoice.serie.toUpperCase()} <br /> */}
          {/* <b>CORRELATIVO:</b> {invoice.correlative} */}
          {invoice.serie != null && invoice.serie != "" && (
            <>
              <b>SERIE:</b> {invoice.serie.toUpperCase()}
            </>
          )}
          <br />
          {invoice.correlative != null && invoice.correlative != "" && (
            <>
              <b>CORRELATIVO:</b> {invoice.correlative}
            </>
          )}
          <br />
          {typeof invoice.customer === "object" ? (
            <>
              {invoice.customer && (
                <>
                  <b>{invoice.customer.ruc.toString().length > 9 ? "RUC:" : "DNI:"}</b>{" "}
                  {invoice.customer.ruc}
                </>
              )}
              <br />
              {invoice.customer && (
                <>
                  <b>
                    {invoice.customer.ruc.toString().length > 9
                      ? "RAZÓN SOCIAL: "
                      : "NOMBRE COMPLETO: "}
                  </b>
                  {invoice.customer.nombre_o_razon_social.toUpperCase()}
                </>
              )}
            </>
          ) : (
            <>
              {invoice.ruc && (
                <>
                  <b>{invoice.ruc.toString().length > 9 ? "RUC:" : "DNI:"}</b> {invoice.ruc}
                </>
              )}
              <br />
              {invoice.customer && invoice.ruc && (
                <>
                  <b>
                    {invoice.ruc.toString().length > 9 ? "RAZÓN SOCIAL: " : "NOMBRE COMPLETO: "}
                  </b>
                  {invoice.customer.toUpperCase()}
                </>
              )}
            </>
          )}
        </Typography>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Cuenta</TableCell>
            <TableCell align="center">Glosa</TableCell>
            <TableCell align="center">Ref. M. Pago</TableCell>
            <TableCell align="center">Moneda</TableCell>
            <TableCell align="center">Debe</TableCell>
            <TableCell align="center">Haber</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoice.details.map((product) => {
            let { id, account, comment, ref_payment, type_currency, type_detail, amount } = product;

            const valor = typeProv ? (typeProv === type_detail ? amount : value) : amount;

            return (
              <TableRow key={id}>
                <TableCell align="center">
                  {account ? (
                    account.number
                  ) : (
                    <FormHelperText error>
                      <b>CUENTA NO EXISTENTE</b>
                    </FormHelperText>
                  )}
                </TableCell>
                <TableCell>{comment.toUpperCase()}</TableCell>
                <TableCell align="center">{ref_payment}</TableCell>
                <TableCell align="center">{type_currency == 1 ? "PEN" : "USD"}</TableCell>
                <TableCell align="right">
                  {type_detail == "DEBE" ? napformat(valor) : "-"}
                </TableCell>
                <TableCell align="right">
                  {type_detail == "HABER" ? napformat(valor) : "-"}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default Details;
