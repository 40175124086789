import React, { useRef } from "react";
import {
  Box,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import moment from "moment";
import PrintIcon from "@material-ui/icons/Print";
import CloseIcon from "@material-ui/icons/Close";
import ReactToPrint from "react-to-print";

const pageStyle = `
  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    html, body {
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-bottom: 1rem;
      display: block;
      page-break-before: always;
    }
  }

  @page {
    size: 1in 4in;
    margin: 0mm;
  }
`;

const DetailSalud = ({ open, setOpen, data }) => {
  const componentRef = useRef(null);
  const { information, worker, createdAt } = data;
  console.log(data);

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={() => setOpen({ open: false })}>
      <DialogContent>
        <Paper style={{ backgroundColor: "#eee" }}>
          <div align="right">
            <ReactToPrint
              trigger={() => (
                <Button color="primary" variant="outlined" size="large">
                  <PrintIcon /> IMPRIMIR
                </Button>
              )}
              content={() => componentRef.current}
              documentTitle={`COMPROBANTE_INGRESOS`}
              removeAfterPrint={true}
              pageStyle={pageStyle}
            />
          </div>
          <Box p={10} style={{ position: "relative" }} ref={componentRef} component="div">
            <Grid container spacing={5}>
              <Grid item xs={12} align="center">
                <Typography variant="h3">DECLARACIÓN JURADA</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography>
                      <b>Nombres y apellidos:</b> {worker.f_name} {worker.l_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography>
                      <b>{worker.t_doc}:</b> {worker.n_doc}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography>
                      <b>Celular:</b> {worker.phone}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography>
                      <b>Correo:</b> {worker.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography>
                      <b>Dirección:</b> {worker.address}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Por medio de la presente, <b>DECLARON BAJO JURAMENTO</b>, encontrarme dentro del
                  grupo de servidores con riesgo vulnerable por tener:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table className="tablefe" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell rowSpan={2} colSpan={2} align="center">
                          ASPECTO A EVALUAR
                        </TableCell>
                        <TableCell colSpan={2} align="center">
                          MARQUE LO CORRESPONDIENTE
                        </TableCell>
                        <TableCell rowSpan={2} align="center">
                          TRATADO y/o CONTROLADO
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">SI</TableCell>
                        <TableCell align="center">NO</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {information.map((e, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{e.label}</TableCell>
                          <TableCell>
                            {e.status === true ? <CloseIcon color="primary" /> : ""}
                          </TableCell>
                          <TableCell>
                            {e.status === false ? <CloseIcon color="primary" /> : ""}
                          </TableCell>
                          <TableCell>{e.comment}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" align="justify" gutterBottom>
                  La información brindada en la presente <b>Declaración jurada</b> es verdadera, en
                  consecuencia, asumo la responsabilidad que pudiera devenir de la comprobación de
                  su falsedad o inexactitud, así como la presentación de los documentos que
                  acreditan tal condición a solicitud del <b>Ministerio de Salud.</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  Lima, {moment().format("dddd D [de] MMMM [del] YYYY")}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={() => setOpen({ open: false })}>
          CERRAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailSalud;
