import React, { useState, useEffect } from "react";
import Page from "src/components/Page";
import { notification } from "antd";
import TableIncidences from "./components/TableIncidences";
import { getIncidences, sendEmailWithDetails } from "src/utils/reqinc";

const ListClaims = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    obtainData();
  }, []);

  const obtainData = async () => {
    try {
      setLoading(true);
      const res = await getIncidences();
      setData(res.reverse());
    } catch (error) {
      notification["error"]({
        message: `No se logró obtener la información!`,
      });
    } finally {
      setLoading(false);
    }
  };

  const sendEmail = (row) => async () => {
    try {
      setLoading(true);
      const sendData = { incidence: row._id };

      await sendEmailWithDetails(sendData);
      notification["success"]({ message: "Correo enviado correctamente..." });
    } catch (error) {
      notification["error"]({ message: `Ocurrió un error al realizar la operación` });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page title="LISTADO DE INCIDENCIAS" helper="INCIDENCIAS">
      <TableIncidences data={data} loading={loading} sendEmail={sendEmail} />
    </Page>
  );
};

export default ListClaims;
