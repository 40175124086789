import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import Page from "src/components/Page";
import TableWorkerInformation from "./components/TableWorkerInformation";
import { getWorkerInformation } from "src/utils/req.boletas";
import { notification } from "antd";
import ModalWorkerInformation from "./components/ModalWorkerInformation";
import moment from "moment";
import ModalConfirmDelete from "src/components/ModalConfirmDelete";
import { apiContable } from "src/config/api.contable";
import DetailsWorkerSee from "./components/DetailsWorkerSee";
import sedeactual from "src/config/sedeactual";

const WorkerInformation = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState({ open: false, data: null });
  const [del, setDel] = useState({ open: false, id: null });
  const [view, setView] = useState({ open: false, data: null });

  useEffect(() => {
    obtainData();
  }, []);

  const obtainData = async () => {
    setLoading(true);
    try {
      const res = await getWorkerInformation("general");
      const result = res.map((e) => ({
        ...e,
        date_upload: moment(e.date_upload).format("DD/MM/YYYY"),
      }));
      setData([...result]);
    } catch (error) {
      notification["error"]({
        message: `Ocurrió un error al realizar la operación`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page
      title="INFORMACIÓN GENERAL PARA LOS TRABAJADORES"
      helper="COLABORADORES"
      itemComponent={
        <Button
          variant="contained"
          size="large"
          color="primary"
          fullWidth
          disabled={sedeactual.getRol() === "usuario"}
          onClick={() => setModal({ open: true, data: null })}
        >
          <Add /> AGREGAR
        </Button>
      }
    >
      <TableWorkerInformation
        data={data}
        loading={loading}
        setModal={setModal}
        setDel={setDel}
        setView={setView}
      />

      {modal.open && (
        <ModalWorkerInformation
          open={modal.open}
          setOpen={setModal}
          data={modal.data}
          loading={loading}
          setLoading={setLoading}
          handleRefresh={obtainData}
        />
      )}

      {del.open && (
        <ModalConfirmDelete
          visible={del.open}
          setVisible={setDel}
          reload={obtainData}
          endpoint={`${apiContable}/worker/general/information/${del.id}`}
          title="CONFIRMACIÓN DE ELIMINACIÓN DE ARCHIVO"
          subtitle="¿Seguro que deseea eliminar este archivo? Recuerda que después ya no estará disponible."
          disable={false}
        />
      )}

      {view.open && (
        <DetailsWorkerSee open={view.open} setOpen={setView} data={view.data} />
      )}
    </Page>
  );
};

export default WorkerInformation;
