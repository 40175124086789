import React from "react";
import { Select } from "antd";
import { FormHelperText, Typography } from "@material-ui/core";

const { Option } = Select;

const SelectWorkers = (props) => {
  const { setValue, value, data } = props;

  const filterOptions = (inputValue, option) => {
    const title = option.props.title
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    return title.includes(inputValue.toLowerCase());
  };

  return (
    <Select
      size="large"
      style={{ width: "100%" }}
      showSearch
      value={value}
      onChange={setValue}
      filterOption={filterOptions}
    >
      <Option key="" value={null} title={""}>
        -- Ninguno --
      </Option>
      {data.map((i) => (
        <Option
          key={i._id}
          value={i._id}
          title={`${i.f_name} ${i.l_name} ${i.t_doc} ${i.n_doc}`}
        >
          <Typography variant="body1" component="span">
            <b>
              {i.f_name} {i.l_name}
            </b>
          </Typography>{" "}
          <FormHelperText component="span">
            {i.n_doc} » {i.t_doc}
          </FormHelperText>
        </Option>
      ))}
    </Select>
  );
};
export default SelectWorkers;
