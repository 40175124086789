import React, { useState } from "react";
import Page from "src/components/Page";
import { Button, Grid } from "@material-ui/core";
import TableWorkers from "./components/TableWorkers";
import AddIcon from "@material-ui/icons/AddOutlined";
import ModalWorkers from "./components/ModalWorkers";
import ExportarExcel from "src/components/Global/ExportarExcel";
import sedeactual from "../../config/sedeactual";
import useWorkers from "src/hook/useWorkers";
import ModalConfirmDelete from "src/components/ModalConfirmDelete";
import { apiContable } from "src/config/api.contable";
import SendNotification from "./components/SendNotification";
import { Tooltip } from "antd";

const WorkersHistorical = () => {
  const [modal, setModal] = useState({ open: false, data: null });
  const { workers, loading, obtainWorkers, setLoading } = useWorkers();
  const [erase, setErase] = useState({ open: false, id: null });
  const [disable, setDisable] = useState({ open: false, id: null });
  const [customEmail, setCustomEmail] = useState({
    open: false,
    data: [],
    type: "",
  });
  const [active, setActive] = useState(true);

  return (
    <Page
      title="DATOS DE TRABAJADORES"
      helper="COLABORADORES"
      itemComponent={
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <Tooltip
              title={`POR ${
                customEmail.data.length === 0 ? "SEDES" : "TRABAJADORES"
              } SELECCIONAD${customEmail.data.length === 0 ? "A" : "0"}S`}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={sedeactual.getRol() === "usuario"}
                onClick={() =>
                  setCustomEmail({ ...customEmail, type: "CUSTOM", open: true })
                }
              >
                REDACTAR EMAIL
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color={active ? "primary" : "secondary"}
              size="large"
              onClick={() => setActive(!active)}
            >
              {active ? "ACTIVOS" : "INACTIVOS"}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => setModal({ open: true, data: null })}
              disabled={sedeactual.getRol() === "usuario"}
            >
              <AddIcon /> Agregar
            </Button>
          </Grid>
          <Grid item>
            <ExportarExcel
              name={`TRABAJADORES`}
              endpoint={`workers/excel/${sedeactual.sede_id()}`}
              body={{}}
              method="POST"
              small={false}
              api={1}
            />
          </Grid>
        </Grid>
      }
    >
      <TableWorkers
        data={workers}
        loading={loading}
        setModal={setModal}
        view="historical"
        setDisable={setDisable}
        setErase={setErase}
        active={active}
        setInfo={setCustomEmail}
        info={customEmail}
      />

      {modal.open && (
        <ModalWorkers
          open={modal.open}
          setOpen={setModal}
          data={modal.data}
          loading={loading}
          setLoading={setLoading}
          refresh={obtainWorkers}
          disable={modal.disable}
        />
      )}

      {erase.open && (
        <ModalConfirmDelete
          visible={erase.open}
          setVisible={setErase}
          reload={obtainWorkers}
          endpoint={`${apiContable}/worker/delete/${erase.id}`}
          title="¿Seguro que desea eliminar a este trabajador?"
          subtitle="Ten en consideración que el registro ya no estará disponible"
          disable={loading}
        />
      )}

      {disable.open && (
        <ModalConfirmDelete
          visible={disable.open}
          setVisible={setDisable}
          reload={obtainWorkers}
          endpoint={`${apiContable}/worker/${disable.id}`}
          title="¿Seguro que desea desabilitar a este trabajador?"
          subtitle="Ten en consideración que el registro ya no estará disponible"
          disable={loading}
        />
      )}

      {customEmail.open && (
        <SendNotification
          open={customEmail.open}
          setOpen={setCustomEmail}
          data={customEmail.data.map((row) => ({
            code: row._id,
            email: row.email,
            fullname: `${row.f_name} ${row.l_name}`,
            phone: row.phone,
            send: true,
          }))}
          type={customEmail.type}
        />
      )}
    </Page>
  );
};

export default WorkersHistorical;
