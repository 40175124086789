import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogActions,
  Button,
  DialogContent,
  Container,
} from "@material-ui/core";
import moment from "moment";
import "src/assets/reclamos.css";

const ModalClaims = (props) => {
  const { open, setOpen, dataClaim: data, before } = props;
  const componentRef = useRef();

  console.log(data);

  return (
    <Dialog open={open} fullScreen>
      <DialogTitle>
        <Typography align="center" variant="h3">
          {"Hoja de Reclamación del Libro de Reclamaciones"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Container maxWidth="md">
          <section ref={componentRef} className="content">
            <table className="tableClaims">
              <thead>
                <tr>
                  <th colSpan={5} className="titleHead">
                    <b>LIBRO DE RECLAMACIONES</b>
                  </th>
                  <th colSpan={3} className="titleHead">
                    HOJA DE RECLAMACIÓN
                  </th>
                </tr>
                <tr>
                  <th colSpan={1}>
                    <p>FECHA</p>
                  </th>
                  <th colSpan={4}>
                    <span>{moment(data.date).format("DD/MM/YYYY")}</span>
                  </th>
                  <th colSpan={3}>
                    <p>Nº 00000000{data.paper}</p>
                  </th>
                </tr>
                <tr>
                  <th colSpan={8}>
                    <h4>
                      <b>{data.sede.description}</b>
                    </h4>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={8} className="title">
                    1. IDENTIFICACIÓN DEL CONSUMIDOR RECLAMANETE
                  </td>
                </tr>
                <tr>
                  <td colSpan={1} className="freeCell" />
                  <td colSpan={1}>
                    <p>NOMBRE</p>
                  </td>
                  <td colSpan={6}>
                    <span>{data.csmName}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={1} className="freeCell" />
                  <td colSpan={1}>
                    <p>DIRECCIÓN</p>
                  </td>
                  <td colSpan={6}>
                    <span>{data.csmAddress}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={1} className="freeCell" />
                  <td colSpan={1}>
                    <p>{data.csmDocumentKind}</p>
                  </td>
                  <td colSpan={6}>
                    <span>{data.csmDocumentNumber}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={1} className="freeCell" />
                  <td colSpan={1}>
                    <p>CELULAR</p>
                  </td>
                  <td colSpan={2}>
                    <span>{data.csmPhone}</span>
                  </td>
                  <td colSpan={2}>
                    <p>CORREO</p>
                  </td>
                  <td colSpan={2}>
                    <span>{data.csmEmail}</span>
                  </td>
                </tr>
                {data.csmMinors ? (
                  <tr>
                    <td colSpan={1} className="freeCell" />
                    <td colSpan={1}>
                      <p>PADRE O MADRE</p>
                    </td>
                    <td colSpan={6}>
                      <span>{data.csmMinors}</span>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                <tr>
                  <td colSpan={8} className="title">
                    2. IDENTIFICACIÓN DEL BIEN CONTRATADO
                  </td>
                </tr>
                <tr>
                  <td colSpan={1} className="freeCell" />
                  <td colSpan={1}>
                    {data.properly === "Product" ? (
                      <p>PRODUCTO</p>
                    ) : (
                      <p>SERVICIO</p>
                    )}
                  </td>
                  <td colSpan={3} className="text-center">
                    <p>MONTO</p> <span>S/ {data.reclaimedAmount}</span>
                  </td>
                  <td colSpan={3}>
                    <p>DESCRIPCIÓN</p>
                    <span>{data.properlyDescrip}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={8} className="title">
                    3. DETALLE DE LA RECLAMACIÓN Y PEDIDO DEL CONSUMIDOR
                  </td>
                </tr>
                <tr>
                  <td colSpan={1} className="freeCell" />
                  <td colSpan={1}>
                    <p>TIPO</p>
                  </td>
                  <td colSpan={6}>
                    {data.claimKind === "Claim" ? (
                      <span>RECLAMO</span>
                    ) : (
                      <span>QUEJA</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan={1} className="freeCell" />
                  <td colSpan={1}>
                    <p>DETALLE</p>
                  </td>
                  <td colSpan={6}>
                    <span>{data.claimDetails}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={1} className="freeCell" />
                  <td colSpan={1}>
                    <p>PEDIDO</p>
                  </td>
                  <td colSpan={6}>
                    <span>{data.claim_order}</span>
                  </td>
                </tr>
                {data.dateResponse && before && (
                  <tr>
                    <td colSpan={8} className="title">
                      4. OBSERVACIONES Y ACCIONES ADOPTADAS POR EL PROVEEDOR
                    </td>
                  </tr>
                )}
                {data.dateResponse && before && (
                  <tr>
                    <td colSpan={1} className="freeCell" />
                    <td colSpan={1}>
                      <p>FECHA DE RESPUESTA</p>
                    </td>
                    <td colSpan={6}>
                      <span>
                        {moment(data.dateResponse).format("DD/MM/YYYY")}
                      </span>
                    </td>
                  </tr>
                )}
                {data.dateResponse && before && (
                  <tr>
                    <td colSpan={1} className="freeCell" />
                    <td colSpan={1}>
                      <p>RESPUESTA</p>
                    </td>
                    <td colSpan={6}>
                      <span>{data.detailResponse}</span>
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={8}></td>
                </tr>
                <tr>
                  <td colSpan={8}></td>
                </tr>
                <tr>
                  <td colSpan={8}></td>
                </tr>
                <tr>
                  <td colSpan={1}>
                    <span className="caption">RECLAMO</span>
                  </td>
                  <td colSpan={7}>
                    <span className="caption">
                      Disconformidad relacionada a los productos o servicios.
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={1}>
                    <span className="caption">QUEJA</span>
                  </td>
                  <td colSpan={7}>
                    <span className="caption">
                      Disconformidad no relacionada a los productos o servicios;
                      o, malestar o descontento respecto a la atención al
                      público.
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={8}>
                    <span className="caption">
                      Destinatario (consumidor, proveedor o INDECOPI según
                      corresponda)
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={8}>
                    <span className="caption">
                      *.La formulación del reclamo no impide acudir a otras vías
                      de solución de controversias ni es requisito previo para
                      interponer una denuncia ante el INDECOPI.
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={8}>
                    <span className="caption">
                      * El proveedor deberá dar respuesta al reclamo en un plazo
                      no mayor a treinta (30) días calendario, pudiendo ampliar
                      el plazo hasta por treinta (30) días más, previa
                      comunicación al consumidor.
                    </span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </section>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setOpen({ open: false })}
        >
          Cancelar
        </Button>
        <ReactToPrint
          trigger={() => (
            <Button color="primary" variant="contained">
              {" "}
              DESCARGAR / IMPRIMIR
            </Button>
          )}
          content={() => componentRef.current}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ModalClaims;
