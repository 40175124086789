import React, { useState, useEffect } from "react"
import { Table } from "antd"
import { ButtonGroup, Button, TextField, Tooltip } from "@material-ui/core"
import ModalEditLocacion from "./components/ModalEditLocacion"
import { Link } from "react-router-dom"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import SearchIcon from "@material-ui/icons/Search"
import ClearIcon from "@material-ui/icons/Clear"
import Highlighter from "react-highlight-words"
import ModalConfirmDelete from "../ModalConfirmDelete"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import sedeactual from "src/config/sedeactual"

const TablaLocacion = ( props ) => {
  const { data, reload, setLoading, loading } = props
  const [ searchText, setsearchText ] = useState( "" )
  const [ openModalEditLocacion, setOpenModalEditLocacion ] = useState( false )
  const [ openModalDelete, setOpenModalDelete ] = useState( {
    idLoc: "",
    open: false,
  } )
  const [ searchedColumn, setsearchedColumn ] = useState( "" )
  const [ dataLocacion, setDataLocacion ] = useState( [] )

  const handleSearch = ( selectedKeys, confirm, dataIndex ) => {
    confirm()

    setsearchText( selectedKeys[ 0 ] )
    setsearchedColumn( dataIndex )
  }

  const handleReset = ( clearFilters, selectedKeys, setSelectedKeys ) => {
    clearFilters()
    setsearchText( "" )
    setSelectedKeys( [ "" ] )
  }

  const getColumnSearchProps = ( dataIndex ) => ( {
    filterDropdown: ( {
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    } ) => (
      <div style={ { padding: 8 } }>
        <TextField
          variant="outlined"
          placeholder="Buscar..."
          size="small"
          value={ selectedKeys[ 0 ] }
          onChange={ ( e ) => {
            setSelectedKeys( e.target.value ? [ e.target.value ] : [] )
          } }
          onPressEnter={ () => handleSearch( selectedKeys, confirm, dataIndex ) }
          style={ { width: 188, marginBottom: 8, display: "block" } }
        />
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={ () =>
            handleReset( clearFilters, selectedKeys, setSelectedKeys )
          }
          style={ { width: 90 } }
        >
          Limpiar <ClearIcon />
        </Button>{ " " }
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={ () => handleSearch( selectedKeys, confirm, dataIndex ) }
        >
          Buscar <SearchIcon />
        </Button>
      </div>
    ),
    filterIcon: ( filtered ) => (
      <SearchIcon style={ { color: filtered ? "#1890ff" : undefined } } />
    ),
    onFilter: ( value, record ) =>
      record[ dataIndex ].toString().toLowerCase().includes( value.toLowerCase() ),
    onFilterDropdownVisibleChange: ( visible ) => {
      if ( visible ) {
      }
    },
    render: ( text ) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={ { backgroundColor: "#ffc069", padding: 0 } }
          searchWords={ [ searchText ] }
          autoEscape
          textToHighlight={ text.toString() }
        />
      ) : (
        text
      ),
  } )

  const columns = [
    {
      title: "N°",
      dataIndex: "_id",
      key: "_id",
      width: 50,
      align: "center",
      render: ( text, record, index ) => <div>{ index + 1 } </div>,
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      align: "left",
      width: 200,
      sorter: ( a, b ) => {
        if ( a.name > b.name ) return 1
        if ( a.name < b.name ) return -1
      },
      ...getColumnSearchProps( "name" ),
      render: ( text, record, index ) => ( text ? text : " - " ),
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
      align: "left",
      width: 250,
      sorter: ( a, b ) => {
        if ( a.description > b.description ) return 1
        if ( a.description < b.description ) return -1
      },
      ...getColumnSearchProps( "description" ),
      render: ( text, record, index ) => ( text ? text : " - " ),
    },
    {
      title: "Acciones",
      dataIndex: "_id",
      key: "",
      width: 150,
      align: "center",
      render: ( text, record, index ) => (
        <ButtonGroup>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={ () => {
              setOpenModalEditLocacion( true )
              setDataLocacion( record )
            } }
            disabled={sedeactual.getRol() === "usuario"}
          >
            <Tooltip title="Editar">
              <EditIcon />
            </Tooltip>
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            component={ Link }
            to={ { pathname: `/gestion/locacion/${record._id}` } }
          >
            <Tooltip title="Detalles">
              <MoreHorizIcon />
            </Tooltip>
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={ () => {
              setOpenModalDelete( {
                idLoc: record._id,
                open: true,
              } )
            } }
            disabled={sedeactual.getRol() === "usuario"}
          >
            <Tooltip title="Eliminar">
              <DeleteIcon />
            </Tooltip>
          </Button>
        </ButtonGroup>
      ),
    },
  ]

  return (
    <>
      <Table
        columns={ columns }
        dataSource={ data }
        bordered
        pagination={ false }
        scroll={ { x: "100%" } }
        loading={ loading }
      />

      { openModalDelete.open && (
        <ModalConfirmDelete
          visible={ openModalDelete.open }
          setVisible={ setOpenModalDelete }
          reload={ reload }
          endPoint={ `location/${openModalDelete.idLoc}` }
          title="¿Esta seguro(a) que desea eliminar esta Locación?"
          subtitle="Tener en cuenta que la Locación ya no estará disponible."
        />
      ) }
      { setOpenModalEditLocacion && (
        <ModalEditLocacion
          visible={ openModalEditLocacion }
          setVisible={ setOpenModalEditLocacion }
          dataLocacion={ dataLocacion }
          reload={ reload }
          setLoading={ setLoading }
        />
      ) }
    </>
  )
}

export default TablaLocacion
