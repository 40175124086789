import React from "react";
import { Upload, Button } from "antd";

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const InputFile = ({ onChange, selectedFileList }) => {
  return (
    <div className="App">
      <Upload
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        fileList={selectedFileList}
        customRequest={dummyRequest}
        onChange={onChange}
      >
        <Button>Seleccionar archivo</Button>
      </Upload>
    </div>
  );
};

export default InputFile;
