import React, { useState, useEffect, useRef } from "react";
import {
  Paper,
  Card,
  CardContent,
  Typography,
  FormControl,
  Box,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SelectSedes from "../../../components/Usuarios/SelectSedes";
import sedeactual from "../../../config/sedeactual";
import { makeStyles } from "@material-ui/styles";
import ReactToPrint from "react-to-print";
import { apiReportes } from "../../../config/api.contable";
import axios from "axios";
import moment from "moment";
import Spinner from "../../../components/Global/Spinner";
import { getActiveSedes } from "../../../api/api.contable";
import ExportarEEFFexcel from "../../../components/Global/ExportarEEFF";
import { InputLabel, Select, MenuItem } from "@material-ui/core";
import { notification } from "antd";
import TableBalanceDetallado from "./TableBalanceDetallado";

const useStyles = makeStyles((theme) => ({
  select: {
    maxWidth: "calc(100% - 275px)",
    minWidth: 300,
    textAlign: "left",
  },
  selectsedes: {
    maxWidth: "calc(100% - 600px)",
    minWidth: 350,
    textAlign: "left",
  },
}));

const ReporteBalanceDeatallado = () => {
  const anho = sedeactual.getAnio();
  const classes = useStyles();
  const componentRef = useRef();
  const rucHeadquarters = sedeactual.getRuc();
  const [balance, setBalance] = useState([]);
  const [reportModeSelected, setReportModeSelected] = useState(0);
  const [sedeSelected, setSedeSelected] = useState(sedeactual.get());
  const [sedes, setSedes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [titleReport, setTitleReport] = useState({
    title: sedeactual.description(),
  });

  const getTotalData = async () => {
    try {
      setBalance([]);
      setLoading(true);
      const res = await axios.get(
        `${apiReportes}/general-balance/${anho}/${
          reportModeSelected == 0 ? 12 : reportModeSelected
        }/${sedeSelected}/${sedeactual.company().url}`
      );
      setBalance(res.data);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al obtener la información.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTotalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportModeSelected, sedeSelected]);

  const getSedes = async () => {
    try {
      let result = await getActiveSedes();
      setSedes(result);
    } catch (error) {}
  };

  useEffect(() => {
    getSedes();
  }, []);

  const content = (
    <Paper style={{ padding: 25, minHeight: "calc(100vh - 65px)" }}>
      <div align="right">
        <Link to={{ pathname: "/reportes" }}>
          <Button variant="outlined" color="primary" style={{ float: "left" }}>
            <ArrowBackIosIcon /> VOLVER
          </Button>
        </Link>
        {(sedeactual.getRol() === "supernap" ||
          sedeactual.getRol() === "superadmin") && (
          <FormControl
            variant="outlined"
            size="small"
            className={classes.selectsedes}
          >
            <SelectSedes
              defaultValue={sedeSelected}
              data={sedes}
              setTitle={setTitleReport}
              onChangeSelect={(e) => {
                setSedeSelected(e);
              }}
            />
          </FormControl>
        )}
        <FormControl variant="outlined" size="small" className={classes.select}>
          <InputLabel>Seleccione</InputLabel>
          <Select
            label="seleccione"
            value={reportModeSelected}
            onChange={(event) => setReportModeSelected(event.target.value)}
          >
            <MenuItem value={0}>Anual</MenuItem>
            <MenuItem value={1}>Enero</MenuItem>
            <MenuItem value={2}>Febrero</MenuItem>
            <MenuItem value={3}>Marzo</MenuItem>
            <MenuItem value={4}>Abril</MenuItem>
            <MenuItem value={5}>Mayo</MenuItem>
            <MenuItem value={6}>Junio</MenuItem>
            <MenuItem value={7}>Julio</MenuItem>
            <MenuItem value={8}>Agosto</MenuItem>
            <MenuItem value={9}>Setiembre</MenuItem>
            <MenuItem value={10}>Octubre</MenuItem>
            <MenuItem value={11}>Noviembre</MenuItem>
            <MenuItem value={12}>Diciembre</MenuItem>
          </Select>
        </FormControl>{" "}
        <ExportarEEFFexcel
          nombre={`BBGGDD${moment(Date.now()).format(
            "dddd_DD_MMMM_YYYY_HH_mm_ss"
          )}_${Date.now()}`}
          ruta={`${apiReportes}/general-balance/export-excel/${sedeactual.getAnio()}/${
            reportModeSelected == 0 ? 12 : reportModeSelected
          }/${sedeSelected}/${sedeactual.company().url}`}
          body={{}}
          disable
        />
        <ReactToPrint
          trigger={() => (
            <Button
              color="primary"
              variant="outlined"
              style={{ marginRight: 10 }}
            >
              <PrintIcon /> IMPRIMIR
            </Button>
          )}
          content={() => componentRef.current}
        />
      </div>
      {loading ? (
        <Box pt={5}>
          <Spinner />
        </Box>
      ) : (
        <Card
          ref={componentRef}
          align="center"
          style={{ position: "relative" }}
        >
          <CardContent>
            <Typography variant="h6" color="textPrimary">
              {titleReport.title && titleReport.title.toUpperCase()}
            </Typography>
            <Typography variant="subtitle1" color="textPrimary">
              N° RUC {rucHeadquarters}
            </Typography>
            <Typography variant="h6" color="textPrimary">
              BALANCE GENERAL DETALLADO
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
              Reporte{" "}
              {reportModeSelected == 0
                ? "anual " + anho
                : "mensual de " +
                  moment(anho + "-" + reportModeSelected + "-01").format(
                    "MMMM YYYY"
                  )}
              <br />
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
              (EXPRESADO EN SOLES)
            </Typography>
            <TableBalanceDetallado
              data={balance}
              year={anho}
              month={reportModeSelected}
            />
          </CardContent>
        </Card>
      )}
    </Paper>
  );
  return content;
};

export default ReporteBalanceDeatallado;
