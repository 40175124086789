import React from "react";
import { Grid, TextField } from "@material-ui/core";
import sedeactual from "src/config/sedeactual";

const data = sedeactual.current_headquarter();

const PartOne = ({ errors, register, eInf }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          InputLabelProps={{ shrink: true }}
          size="small"
          fullWidth
          label="Número del caso *"
          name="correlative"
          variant="outlined"
          disabled
          defaultValue={eInf.correlative}
          inputRef={register({
            required: true,
          })}
          error={Boolean(errors?.correlative)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          InputLabelProps={{ shrink: true }}
          size="small"
          fullWidth
          label="N° de registro SíseVe"
          name="siseve"
          variant="outlined"
          defaultValue={eInf.siseve}
          inputRef={register({
            required: true,
          })}
          error={Boolean(errors?.siseve)}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          InputLabelProps={{ shrink: true }}
          size="small"
          fullWidth
          label="Institución Educativa"
          variant="outlined"
          disabled
          value={data.description}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          InputLabelProps={{ shrink: true }}
          size="small"
          fullWidth
          label="DRE"
          variant="outlined"
          disabled
          name="dre"
          value={data.dre}
          inputRef={register({
            required: true,
          })}
          error={Boolean(errors?.dre)}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          InputLabelProps={{ shrink: true }}
          size="small"
          fullWidth
          label="UGEL"
          variant="outlined"
          disabled
          name="ugel"
          value={data.ugel}
          inputRef={register({
            required: true,
          })}
          error={Boolean(errors?.ugel)}
        />
      </Grid>
    </Grid>
  );
};

export default PartOne;
