import React from "react";
import CustomTable from "src/components/CustomTable";
import moment from "moment";
import { ButtonGroup, Button, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { Alert } from "antd";
import { Send } from "@material-ui/icons";

const TableIncidences = ({ data, loading, sendEmail }) => {
  const columns = [
    {
      title: "Nro",
      dataIndex: "correlative",
      key: "correlative",
      align: "center",
      sorter: true,
      filter: false,
      width: 75,
    },
    {
      title: "TIPO",
      dataIndex: "typeReport",
      key: "typeReport",
      align: "center",
      sorter: true,
      filter: false,
      width: 150,
    },
    {
      title: "AGREDIDO",
      dataIndex: "agdInitils",
      key: "agdInitils",
      align: "center",
      sorter: false,
      filter: true,
      width: 125,
    },
    {
      title: "AGRESOR",
      dataIndex: "agsFullname",
      key: "agsFullname",
      align: "center",
      sorter: false,
      filter: true,
      width: 125,
    },
    {
      title: "INFORMANTE",
      dataIndex: "infFullname",
      key: "infFullname",
      align: "center",
      sorter: false,
      filter: true,
      width: 130,
    },
    {
      title: "FECHA",
      dataIndex: "dateRegister",
      key: "dateRegister",
      align: "center",
      sorter: false,
      filter: false,
      width: 125,
      render: (value) => moment(value).format("ddd D MMM YYYY"),
    },
    {
      title: "ESTADO",
      dataIndex: "status",
      key: "status",
      align: "center",
      sorter: false,
      filter: false,
      width: 140,
      render: (value, record) =>
        value === "PENDIENTE" ? (
          <Alert message={value} type="info" showIcon />
        ) : (
          <Alert message={value} type="success" showIcon />
        ),
    },
    {
      title: "ACCIONES",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      sorter: false,
      filter: false,
      width: 175,
      render: (value, row) => (
        <ButtonGroup size="small">
          <Button
            color="primary"
            variant="outlined"
            component={Link}
            to={{ pathname: `/incidencias/${value}` }}
          >
            <Tooltip title="Seguimiento y modificaciones" placement="top-end" arrow>
              <span>DETALLES </span>
            </Tooltip>
          </Button>
          <Button color="primary" variant="contained" onClick={sendEmail(row)}>
            <Tooltip
              title={`Enviar incidencia y detalles al correo ${row.infEmail}`}
              placement="top-end"
              arrow
            >
              <Send />
            </Tooltip>
          </Button>
          <Button
            color="secondary"
            variant="contained"
            component={Link}
            to={{ pathname: `/incidencias/report/${value}` }}
          >
            <Tooltip title="PDF" placement="top-end" arrow>
              <PictureAsPdfIcon style={{ color: "#fff" }} />
            </Tooltip>
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  return (
    <CustomTable columns={columns} data={data} loading={loading} pagination={{ pageSize: 20 }} />
  );
};

export default TableIncidences;
