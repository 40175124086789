import React, { useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {
  Select,
  TextField,
  Grid,
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import { postHeadquarters, editHeadquarters } from "../../api/api.contable";
import LoaderBackdrop from "../../components/Global/LoaderBackdrop";
import useForm from "react-hook-form";
import { notification } from "antd";

const ModalHeadquarter = ({
  visible,
  setVisible,
  data,
  url,
  setLoading,
  loading,
  refreshFunction,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [logo, setLogo] = useState(null);
  const [digits, setDigits] = useState(Boolean(data) ? data.digits : 6);

  const createHeadquarters = async (items, event) => {
    event.preventDefault();
    try {
      setLoading(true);

      let formData = new FormData();
      formData.append("address", items.address);
      formData.append("description", items.description);
      formData.append("digits", digits);
      formData.append("phone", items.phone);
      formData.append("rubro", items.rubro);
      formData.append("ruc", items.ruc);
      formData.append("url", items.url);
      formData.append("dre", items.dre);
      formData.append("ugel", items.ugel);
      formData.append("email", items.email);
      if (logo) formData.append("logo", logo);

      if (data) await editHeadquarters(formData, data.id, url);
      else await postHeadquarters(formData, url);
      notification["success"]({
        message: `La empresa se ${data ? "actualizó" : "guardó"} correctamente`,
      });
      refreshFunction();
      setVisible({ open: false });
    } catch (error) {
      notification["error"]({
        message: `Oops!`,
        description: `Ocurrió un error, error`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={visible} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography variant="h3" component="p" align="center">
          <b>{data ? "EDITAR" : "CREAR"} EMPRESA</b>
        </Typography>
      </DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit(createHeadquarters)}>
        <DialogContent>
          {loading && <LoaderBackdrop />}
          <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                label="RUBRO"
                fullWidth
                variant="outlined"
                name="rubro"
                defaultValue={Boolean(data) ? data.rubro : ""}
                inputRef={register({
                  required: true,
                })}
                error={Boolean(errors?.rubro)}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>DÍGITOS</InputLabel>
                <Select
                  label="DÍGITOS"
                  value={digits}
                  onChange={(e) => setDigits(Number(e.target.value))}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                label="RAZÓN SOCIAL"
                fullWidth
                variant="outlined"
                name="description"
                defaultValue={Boolean(data) ? data.description : ""}
                inputRef={register({
                  required: true,
                })}
                error={Boolean(errors?.description)}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                label="URL"
                fullWidth
                variant="outlined"
                name="url"
                defaultValue={Boolean(data) ? data.url : ""}
                inputRef={register({
                  required: true,
                })}
                error={Boolean(errors?.url)}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                label="RUC"
                fullWidth
                variant="outlined"
                name="ruc"
                defaultValue={Boolean(data) ? data.ruc : ""}
                inputRef={register({
                  required: true,
                  minLength: 10,
                  maxLength: 12,
                  pattern: /^[0-9]+$/i,
                })}
                error={Boolean(errors?.ruc)}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                label="TELÉFONO"
                fullWidth
                variant="outlined"
                name="phone"
                defaultValue={Boolean(data) ? data.phone : ""}
                inputRef={register({
                  required: true,
                  minLength: 5,
                  maxLength: 12,
                  pattern: /^[0-9]+$/i,
                })}
                error={Boolean(errors?.phone)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="DIRECCIÓN"
                fullWidth
                variant="outlined"
                name="address"
                defaultValue={Boolean(data) ? data.address : ""}
                inputRef={register({ required: true })}
                error={Boolean(errors?.address)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="EMAIL"
                fullWidth
                variant="outlined"
                name="email"
                defaultValue={Boolean(data) ? data.email : ""}
                inputRef={register({ required: true })}
                error={Boolean(errors?.email)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="DRE"
                fullWidth
                variant="outlined"
                name="dre"
                defaultValue={Boolean(data) ? data.dre : ""}
                inputRef={register({
                  required: false,
                })}
                error={Boolean(errors?.dre)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="UGEL"
                fullWidth
                variant="outlined"
                name="ugel"
                defaultValue={Boolean(data) ? data.ugel : ""}
                inputRef={register({
                  required: false,
                })}
                error={Boolean(errors?.ugel)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="LOGO"
                fullWidth
                variant="outlined"
                type="file"
                onChange={(e) => setLogo(e.target.files[0])}
                InputLabelProps={{ shrink: true }}
                inputProps={{ accept: "image/*" }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="outlined" onClick={() => setVisible({ open: false })}>
            CANCELAR
          </Button>
          <Button color="primary" variant="contained" type="submit">
            {data ? "Actualizar" : "Guardar"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalHeadquarter;
