import React, { useState, useEffect, Fragment } from "react";
import { Container, Typography, Paper, Box, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useParams } from "react-router-dom";
import Header from "./components/Header";
import Form from "./components/Form";
import { getHeadquarterByUrl } from "../../api/api.contable";

const Claims = () => {
  const { url } = useParams();
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCompany();
  }, []);

  const getCompany = async () => {
    setLoading(true);
    try {
      let res = await getHeadquarterByUrl(url);
      setCompany(res);
    } catch (error) {
      redirectToNap();
    } finally {
      setLoading(false);
    }
  };

  const redirectToNap = () => {
    setTimeout(() => {
      window.location.href = "https://netappperu.com/";
    }, 5000);
  };

  return (
    <Container maxWidth="md">
      <Box p={2}>
        <Paper elevation={2}>
          {loading ? (
            <Fragment>
              <Box p={5}>
                <br />
                <Skeleton variant="rect" width="100%" height={500}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{ height: "100%" }}
                  >
                    <Grid item>
                      <Typography align="center">
                        <b>CARGANDO...</b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Skeleton>
              </Box>
            </Fragment>
          ) : company ? (
            <Fragment>
              <Header company={company} />
              <Form company={company} setLoading={setLoading} />
            </Fragment>
          ) : (
            <Box p={5}>
              <Typography align="center" variant="subtitle1">
                <b>
                  La organización no se encuentra afiliada a nuestros servicios
                </b>
              </Typography>
            </Box>
          )}
        </Paper>
      </Box>
    </Container>
  );
};

export default Claims;
