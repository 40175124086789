import React, { useState } from "react";
import {
  TextField,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { uploadVouchers } from "src/utils/req.boletas";
import LoaderBackdrop from "src/components/Global/LoaderBackdrop";
import useForm from "react-hook-form";
import { notification } from "antd";
import types from "src/utils/typesComprobants";
import { Refresh } from "@material-ui/icons";

const ModalBoletas = (props) => {
  const { open, setOpen, data, worker, setLoading, loading, refresh } = props;
  const { register, handleSubmit, errors } = useForm();
  const [detail, setDetail] = useState({ open: false, text: "" });
  const [file, setFile] = useState(null);

  const sendData = async (items, event) => {
    event.preventDefault();
    try {
      if (!file) {
        notification["warning"]({ message: `El archivo es requerido` });
        return;
      }
      setLoading(true);

      let formData = new FormData();
      formData.append("file", file);
      formData.append("n_doc", worker.n_doc);
      formData.append("date", items.date);
      formData.append("detail", detail.text);
      formData.append("date_upload", Date.now());

      if (data) {
        console.log("falta");
      } else await uploadVouchers(formData);

      notification["success"]({
        message: `Se ${Boolean(data) ? "actualizó" : "guardó"} correctamente`,
      });
      refresh();
      setOpen({ open: false });
    } catch (error) {
      notification["error"]({
        message: `Ocurrió un error al realizar la operación.`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle align="center">
        <h2>
          <b>REGISTRAR COMPROBANTE</b>
        </h2>
      </DialogTitle>
      {loading && <LoaderBackdrop />}
      <form autoComplete="off" onSubmit={handleSubmit(sendData)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Fecha de pago"
                fullWidth
                autoFocus
                variant="outlined"
                type="date"
                name="date"
                defaultValue={Boolean(data) ? data.date : ""}
                inputRef={register({ required: true })}
                error={Boolean(errors?.date)}
              />
            </Grid>
            <Grid item xs={12}>
              {detail.open ? (
                <TextField
                  label="Descripción"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  onChange={(e) => {
                    setDetail({ open: true, text: e.target.value });
                  }}
                  value={detail.text}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton color="primary" onClick={() => setDetail({ open: false, text: "" })}>
                          <Refresh />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Descripción</InputLabel>
                  <Select
                    label="Descripción"
                    value={detail.text}
                    onChange={(e) => {
                      setDetail({
                        open: e.target.value === "OTRO" ? true : false,
                        text: e.target.value,
                      });
                    }}
                  >
                    {Object.values(types).map((el, i) => (
                      <MenuItem key={i} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                    <MenuItem value="OTRO">OTROS</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Archivo"
                fullWidth
                variant="outlined"
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
                inputProps={{ accept: "application/pdf" }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="outlined" onClick={() => setOpen({ open: false })}>
            CANCELAR
          </Button>
          <Button color="primary" variant="contained" type="submit" disabled={!detail.text || !file}>
            {data ? "Actualizar" : "Guardar"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalBoletas;
