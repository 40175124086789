import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormHelperText,
  InputAdornment,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@material-ui/core";
import _ from "lodash/fp";
import { makeStyles } from "@material-ui/styles";
import useForm from "react-hook-form";
import moment from "moment";
import { apiInventario } from "../../../../../config/api.contable";
import axios from "axios";
import NumberFormat from "react-number-format";
import { notification } from "antd";

const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: "50%",
    maxWidth: "80%",
  },
  iconText: {
    color: "#aaa",
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //prefix="S/ "
    />
  );
}

const ModalAddVehiculos = (props) => {
  const classes = useStyles();
  const {
    visible,
    setVisible,
    reloadFunction,
    categories,
    data,
    loading,
    setLoading,
  } = props;

  const { register, handleSubmit, errors } = useForm();
  const [status, setStatus] = useState(data.status);
  const [customValue, setCustomValue] = useState(data.category);
  const [values, setValues] = useState({
    historical_value: data.historical_value,
  });
  const [subCategories, setSubCategories] = useState(data.category.subcategory);
  const [subcategory, setSubcategory] = useState(data.subcategory?._id ?? "");

  const addRegister = async (dataEdit) => {
    setLoading(true);
    try {
      if (customValue != null && values.historical_value != "") {
        dataEdit.category = customValue._id;
        dataEdit.subcategory = subcategory ? subcategory : null;
        dataEdit.status = status;
        dataEdit.historical_value = values.historical_value;
        dataEdit.bodywork = dataEdit.bodywork.toUpperCase();
        dataEdit.fuel = dataEdit.fuel.toUpperCase();
        dataEdit.color = dataEdit.color.toUpperCase();
        dataEdit.model = dataEdit.model.toUpperCase();
        dataEdit.vehicle_plate = dataEdit.vehicle_plate.toUpperCase();
        dataEdit.trademark = dataEdit.trademark.toUpperCase();

        if (
          (dataEdit.model == "") ^
          (dataEdit.vehicle_plate == "") ^
          (dataEdit.trademark == "")
        ) {
          notification["info"]({
            message:
              "La descripción es requerida en caso de no ingresar placa, modelo o marca. Verifique y vuelva a intentarlo.",
          });
          setLoading(false);
          return null;
        } else {
          await axios.put(`${apiInventario}/fixed-asset/${data._id}`, dataEdit);
          notification["success"]({
            message: "El registro se actualizó correctamente.",
          });
          reloadFunction();
          setVisible(false);
        }
      } else {
        notification["info"]({
          message: "Campos requeridos que falta completar.",
        });
      }
    } catch (err) {
      notification["error"]({
        message: "Ocurrió un error al agregar el registro.",
        description: `${err}`,
      });
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value.toUpperCase(),
    });
  };

  return (
    <Dialog open={visible} classes={{ paper: classes.dialog }}>
      <DialogTitle>
        <Typography
          variant="h2"
          component="h2"
          color="textPrimary"
          align="center"
        >
          EDITAR VEHÍCULO
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(addRegister)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={8}>
              <Autocomplete
                id="combo-box-demo"
                options={categories}
                fullWidth
                getOptionLabel={(option) => option.name.toUpperCase()}
                renderOption={(option) => (
                  <React.Fragment>
                    <Typography component="span" variant="subtitle2">
                      {option.name.toUpperCase()}{" "}
                      <FormHelperText component="span">
                        {option.account_number} {option.account_name}
                      </FormHelperText>
                    </Typography>
                  </React.Fragment>
                )}
                onChange={(event, newValue) => {
                  setCustomValue(newValue);
                  setSubCategories([...newValue.subcategory]);
                }}
                defaultValue={customValue}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Categoria"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>SubCategorias</InputLabel>
                <Select
                  value={subcategory}
                  onChange={(event) => setSubcategory(event.target.value)}
                  label="SubCategorias"
                >
                  {subCategories.map((el) => (
                    <MenuItem value={el._id}>{el.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Marca"
                fullWidth
                variant="outlined"
                name="trademark"
                defaultValue={data.trademark}
                inputRef={register({
                  required: false,
                  maxLength: 100,
                  // pattern: /^[A-Za-z0-9.,]+$/i,
                })}
              />
              {_.get("trademark.type", errors) === "required" && (
                <FormHelperText className={classes.error}>
                  La marca comercial es requerida.
                </FormHelperText>
              )}
              {_.get("trademark.type", errors) === "maxLength" && (
                <FormHelperText className={classes.error}>
                  No debe exceder los 100 caracteres.
                </FormHelperText>
              )}
              {_.get("trademark.type", errors) === "pattern" && (
                <FormHelperText className={classes.error}>
                  Solo se acepta números y caracteres alfabeticos.
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                label="Modelo"
                fullWidth
                variant="outlined"
                name="model"
                defaultValue={data.model}
                inputRef={register({
                  required: false,
                  maxLength: 20,
                  // // pattern: /^[A-Za-z0-9.,]+$/i,
                })}
              />
              {_.get("model.type", errors) === "required" && (
                <FormHelperText className={classes.error}>
                  El modelo es requerido.
                </FormHelperText>
              )}
              {_.get("model.type", errors) === "maxLength" && (
                <FormHelperText className={classes.error}>
                  No debe exceder los 20 caracteres.
                </FormHelperText>
              )}
              {_.get("model.type", errors) === "pattern" && (
                <FormHelperText className={classes.error}>
                  Solo se acepta caracteres alfabéticos y números.
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                label="Placa del Vehículo"
                fullWidth
                variant="outlined"
                name="vehicle_plate"
                defaultValue={data.vehicle_plate}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.iconText}
                    >
                      #
                    </InputAdornment>
                  ),
                }}
                inputRef={register({
                  required: false,
                  maxLength: 20,
                  // pattern: /^[a-zA-Z0-9 -_]+$/i,
                })}
              />
              {_.get("vehicle_plate.type", errors) === "required" && (
                <FormHelperText className={classes.error}>
                  La vehicle_plate es requerida.
                </FormHelperText>
              )}
              {_.get("vehicle_plate.type", errors) === "maxLength" && (
                <FormHelperText className={classes.error}>
                  No debe exceder los 20 caracteres.
                </FormHelperText>
              )}
              {_.get("vehicle_plate.type", errors) === "pattern" && (
                <FormHelperText className={classes.error}>
                  Solo se acepta caracteres alfabéticos y números.
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                label="Número de motor"
                fullWidth
                variant="outlined"
                name="motor_number"
                defaultValue={data.motor_number}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.iconText}
                    >
                      #
                    </InputAdornment>
                  ),
                }}
                inputRef={register({
                  required: false,
                  maxLength: 20,
                  // pattern: /^[a-zA-Z0-9 -_]+$/i,
                })}
              />
              {_.get("motor_number.type", errors) === "required" && (
                <FormHelperText className={classes.error}>
                  El número de motor es requerida.
                </FormHelperText>
              )}
              {_.get("motor_number.type", errors) === "maxLength" && (
                <FormHelperText className={classes.error}>
                  No debe exceder los 20 caracteres.
                </FormHelperText>
              )}
              {_.get("motor_number.type", errors) === "pattern" && (
                <FormHelperText className={classes.error}>
                  Solo se acepta caracteres alfabéticos y números.
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                label="Partida Registral"
                fullWidth
                variant="outlined"
                type="number"
                name="registration_starting"
                defaultValue={data.registration_starting}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.iconText}
                    >
                      #
                    </InputAdornment>
                  ),
                }}
                inputRef={register({
                  required: false,
                  maxLength: 20,
                })}
              />
              {_.get("registration_starting.type", errors) === "required" && (
                <FormHelperText className={classes.error}>
                  El número de motor es requerida.
                </FormHelperText>
              )}
              {_.get("registration_starting.type", errors) === "maxLength" && (
                <FormHelperText className={classes.error}>
                  No debe exceder los 20 caracteres.
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    component="h5"
                    color="textPrimary"
                    align="center"
                  >
                    Descripción técnica
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    label="N° de Ejes"
                    fullWidth
                    variant="outlined"
                    type="number"
                    name="axles_number"
                    defaultValue={data.axles_number}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.iconText}
                        >
                          EJES
                        </InputAdornment>
                      ),
                    }}
                    inputRef={register({
                      required: false,
                      maxLength: 20,
                      min: 1,
                      max: 10,
                    })}
                  />
                  {_.get("axles_number.type", errors) === "required" && (
                    <FormHelperText className={classes.error}>
                      El número de motor es requerida.
                    </FormHelperText>
                  )}
                  {_.get("axles_number.type", errors) === "maxLength" && (
                    <FormHelperText className={classes.error}>
                      No debe exceder los 20 caracteres.
                    </FormHelperText>
                  )}
                  {_.get("axles_number.type", errors) === "min" && (
                    <FormHelperText className={classes.error}>
                      El valor mínimo es 1.
                    </FormHelperText>
                  )}
                  {_.get("axles_number.type", errors) === "max" && (
                    <FormHelperText className={classes.error}>
                      El valor máximo es 10.
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    label="N° de Asientos"
                    fullWidth
                    variant="outlined"
                    type="number"
                    name="seats_number"
                    defaultValue={data.seats_number}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.iconText}
                        >
                          ASIENTOS
                        </InputAdornment>
                      ),
                    }}
                    inputRef={register({
                      required: false,
                      maxLength: 20,
                      min: 1,
                      max: 500,
                    })}
                  />
                  {_.get("seats_number.type", errors) === "required" && (
                    <FormHelperText className={classes.error}>
                      El número de motor es requerida.
                    </FormHelperText>
                  )}
                  {_.get("seats_number.type", errors) === "maxLength" && (
                    <FormHelperText className={classes.error}>
                      No debe exceder los 20 caracteres.
                    </FormHelperText>
                  )}
                  {_.get("seats_number.type", errors) === "min" && (
                    <FormHelperText className={classes.error}>
                      El valor mínimo es 1.
                    </FormHelperText>
                  )}
                  {_.get("seats_number.type", errors) === "max" && (
                    <FormHelperText className={classes.error}>
                      Ingrese un número válido.
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    label="N° de ruedas"
                    fullWidth
                    variant="outlined"
                    type="number"
                    name="wheels_number"
                    defaultValue={data.wheels_number}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.iconText}
                        >
                          RUEDAS
                        </InputAdornment>
                      ),
                    }}
                    inputRef={register({
                      required: false,
                      maxLength: 20,
                      min: 1,
                      max: 20,
                    })}
                  />
                  {_.get("wheels_number.type", errors) === "required" && (
                    <FormHelperText className={classes.error}>
                      El número de motor es requerida.
                    </FormHelperText>
                  )}
                  {_.get("wheels_number.type", errors) === "maxLength" && (
                    <FormHelperText className={classes.error}>
                      No debe exceder los 20 caracteres.
                    </FormHelperText>
                  )}
                  {_.get("wheels_number.type", errors) === "min" && (
                    <FormHelperText className={classes.error}>
                      El valor mínimo es 1.
                    </FormHelperText>
                  )}
                  {_.get("wheels_number.type", errors) === "max" && (
                    <FormHelperText className={classes.error}>
                      Ingrese un número válido.
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    label="Año de fabricación"
                    fullWidth
                    variant="outlined"
                    name="production_year"
                    type="number"
                    defaultValue={data.production_year}
                    inputRef={register({
                      required: false,
                      maxLength: 4,
                      min: 1800,
                      max: moment(Date.now()).format("YYYY"),
                    })}
                  />
                  {_.get("production_year.type", errors) === "required" && (
                    <FormHelperText className={classes.error}>
                      El número de motor es requerida.
                    </FormHelperText>
                  )}
                  {_.get("production_year.type", errors) === "maxLength" && (
                    <FormHelperText className={classes.error}>
                      No debe exceder los 4 caracteres.
                    </FormHelperText>
                  )}
                  {_.get("production_year.type", errors) === "min" && (
                    <FormHelperText className={classes.error}>
                      El valor mínimo es 1800.
                    </FormHelperText>
                  )}
                  {_.get("production_year.type", errors) === "max" && (
                    <FormHelperText className={classes.error}>
                      El año ingresado no debe superar{" "}
                      {moment(Date.now()).format("YYYY")}.
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    label="Carrocería"
                    fullWidth
                    variant="outlined"
                    name="bodywork"
                    defaultValue={data.bodywork}
                    inputRef={register({
                      required: false,
                      maxLength: 20,
                    })}
                  />
                  {_.get("bodywork.type", errors) === "required" && (
                    <FormHelperText className={classes.error}>
                      La carrocería es requerida.
                    </FormHelperText>
                  )}
                  {_.get("bodywork.type", errors) === "maxLength" && (
                    <FormHelperText className={classes.error}>
                      No debe exceder los 20 caracteres.
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    label="Combustible"
                    fullWidth
                    variant="outlined"
                    name="fuel"
                    defaultValue={data.fuel}
                    inputRef={register({
                      required: false,
                      maxLength: 20,
                    })}
                  />
                  {_.get("fuel.type", errors) === "required" && (
                    <FormHelperText className={classes.error}>
                      El combustible es requerido.
                    </FormHelperText>
                  )}
                  {_.get("fuel.type", errors) === "maxLength" && (
                    <FormHelperText className={classes.error}>
                      No debe exceder los 20 caracteres.
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    label="Color"
                    fullWidth
                    variant="outlined"
                    name="color"
                    defaultValue={data.color}
                    inputRef={register({
                      required: false,
                      maxLength: 20,
                    })}
                  />
                  {_.get("color.type", errors) === "required" && (
                    <FormHelperText className={classes.error}>
                      El color es requerido.
                    </FormHelperText>
                  )}
                  {_.get("color.type", errors) === "maxLength" && (
                    <FormHelperText className={classes.error}>
                      No debe exceder los 20 caracteres.
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    label="Fecha de adquisición"
                    fullWidth
                    variant="outlined"
                    defaultValue={moment(data.acquisition_date).format(
                      "YYYY-MM-DD"
                    )}
                    type="date"
                    name="acquisition_date"
                    inputRef={register({
                      required: true,
                    })}
                  />
                  {_.get("acquisition_date.type", errors) === "required" && (
                    <FormHelperText className={classes.error}>
                      La fecha de adquisición es requerida
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    label="Valor histórico"
                    fullWidth
                    variant="outlined"
                    name="historical_value"
                    value={values.historical_value}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.iconText}
                        >
                          S/
                        </InputAdornment>
                      ),
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Estado</InputLabel>
                    <Select
                      value={status}
                      onChange={(event) =>
                        setStatus(event.target.value.toUpperCase())
                      }
                      label="Estado"
                    >
                      <MenuItem value="BUENO">BUENO</MenuItem>
                      <MenuItem value="REGULAR">REGULAR</MenuItem>
                      <MenuItem value="DETERIORADO">DETERIORADO</MenuItem>
                      <MenuItem value="FALTA ARREGLAR">FALTA ARREGLAR</MenuItem>
                      <MenuItem value="MALOGRADO">MALOGRADO</MenuItem>
                      <MenuItem value="DESUSO">DESUSO</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <br />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => setVisible(false)}
          >
            Cerrar
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={data.category._id == "" || loading ? true : false}
          >
            Guardar
          </Button>
        </DialogActions>
        {categories.length > 0 ? (
          ""
        ) : (
          <Typography align="right" color="secondary">
            No se encontraron categorías registradas. Registre categorías y
            vuelva a intentarlo.
          </Typography>
        )}
      </form>
    </Dialog>
  );
};

export default ModalAddVehiculos;
