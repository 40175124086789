import React from "react";
import sedeactual from "../../config/sedeactual";
import Report from "./Report";
import { Typography, Box } from "@material-ui/core";

const Inicio = () => {
  let data = sedeactual.company();
  return (
    <Box p={1} style={{ height: "calc((100vh) - 80px)" }}>
      <Typography
        variant="h3"
        color="textPrimary"
        align="center"
        component="h1"
      >
        <b>{data && data.name}</b>
      </Typography>
      <div align="center">
        <figure style={{ width: 150, height: 150 }}>
          <img
            src={data && data.logo}
            style={{ maxHeight: "100%", maxWidth: "100%" }}
            alt="Logo"
          />
        </figure>
      </div>
      <Typography
        variant="h4"
        color="textPrimary"
        align="center"
        component="h2"
      >
        {sedeactual.description()}
      </Typography>
      <Typography
        variant="h3"
        color="textSecondary"
        align="center"
        component="h3"
      >
        SISTEMA CONTABLE
      </Typography>
      <br />
      <br />
      <Report />
    </Box>
  );
};

export default Inicio;
