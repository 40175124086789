import React, { Fragment, useState, useEffect } from "react";
import CustomTable from "src/components/CustomTable";
import moment from "moment";
import { Button } from "@material-ui/core";

const TableCovid = ({ data, loading, setDetails }) => {
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      let tmp = data.map((e) => ({
        ...e,
        t_doc: e.worker.t_doc,
        n_doc: e.worker.n_doc,
        phone: e.worker.phone,
        address: e.worker.address,
        email: e.worker.email,
        fullname: `${e.worker.f_name} ${e.worker.l_name}`,
      }));
      setNewData(tmp);
    }
  }, [data]);

  const columns = [
    {
      title: "ACCIONES",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      sorter: false,
      filter: false,
      width: 150,
      render: (val, row) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setDetails({ open: true, data: row })}
        >
          VER DETALLES
        </Button>
      ),
    },
    {
      title: "TIPO DOCUMENTO",
      dataIndex: "t_doc",
      key: "t_doc",
      align: "center",
      sorter: false,
      filter: true,
      width: 125,
    },
    {
      title: "NÚMERO DOCUMENTO",
      dataIndex: "n_doc",
      key: "n_doc",
      align: "center",
      sorter: false,
      filter: true,
      width: 125,
    },
    {
      title: "NOMBRE COMPLETO",
      dataIndex: "fullname",
      key: "fullname",
      align: "center",
      sorter: true,
      filter: true,
      width: 250,
    },
    {
      title: "CELULAR",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      sorter: true,
      filter: true,
      width: 125,
    },
    {
      title: "DIRECCIÓN",
      dataIndex: "address",
      key: "address",
      align: "center",
      sorter: false,
      filter: true,
      width: 200,
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      align: "center",
      sorter: false,
      filter: true,
      width: 200,
    },
    {
      title: "FECHA",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      sorter: false,
      filter: false,
      width: 125,
      render: (value) => moment(value).format("ddd D MMM YYYY"),
    },
  ];

  return (
    <Fragment>
      <CustomTable
        columns={columns}
        data={newData}
        loading={loading}
        pagination={{ pageSize: 20 }}
      />
    </Fragment>
  );
};

export default TableCovid;
