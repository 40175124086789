import React, { useState } from "react";
import Highlighter from "react-highlight-words";
import { Table, Input, Icon } from "antd";
import { Chip, Button, Tooltip, Avatar, ButtonGroup } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ModalCompany from "./ModalCompany";
import SettingsOverscanIcon from "@material-ui/icons/SettingsOverscan";
import AsignModulesModal from "../../Global/AsignModulesModal";

const TableCompany = (props) => {
  const { data, loading, setLoading, obtainCompanies, modules } = props;
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");
  const [edit, setEdit] = useState({ data: null, open: false });
  const [asignarModulos, setAsignarModulos] = useState({
    data: null,
    open: false,
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyPress={(e) => e.key === "Enter" && handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          color="primary"
          variant="contained"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
          color="primary"
          variant="outlined"
        >
          Limpiar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setsearchText(selectedKeys[0]);
    setsearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setsearchText("");
  }

  let columns = [
    {
      title: "NOMBRE",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: 300,
      ...getColumnSearchProps("name"),
    },
    {
      title: "RUC",
      dataIndex: "ruc",
      key: "ruc",
      align: "center",
      width: 125,
      ...getColumnSearchProps("ruc"),
    },
    {
      title: "DIRECCIÓN",
      dataIndex: "address",
      key: "address",
      align: "center",
      width: 200,
      ...getColumnSearchProps("address"),
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      align: "center",
      width: 150,
      ...getColumnSearchProps("url"),
    },
    {
      title: "LOGO",
      dataIndex: "logo",
      key: "logo",
      align: "center",
      width: 75,
      render: (value) => <Avatar src={value} />,
    },
    {
      title: "ESTADO",
      dataIndex: "active",
      key: "active",
      align: "center",
      width: "100px",
      render: (text) => (
        <div align="center">
          {text === true ? (
            <Chip
              style={{ backgroundColor: "green", color: "#fff" }}
              size="small"
              label="Activo"
            />
          ) : (
            <Chip
              variant="outlined"
              style={{ color: "red", borderColor: "red" }}
              size="small"
              label="Inactivo"
            />
          )}
        </div>
      ),
    },
    {
      title: "ACCIONES",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "150px",
      render: (text, record, index) => (
        <ButtonGroup color="primary" size="small">
          <Button onClick={() => setEdit({ data: record, open: true })}>
            <Tooltip title="Editar">
              <EditIcon />
            </Tooltip>
          </Button>
          <Button
            onClick={() => setAsignarModulos({ data: record, open: true })}
            variant="contained"
          >
            <Tooltip title="Asignar módulos">
              <SettingsOverscanIcon />
            </Tooltip>
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        bordered
        dataSource={data}
        loading={loading}
        scroll={{ x: "100%" }}
      />
      {edit.open && (
        <ModalCompany
          visible={edit.open}
          setVisible={setEdit}
          setLoading={setLoading}
          obtainCompanies={obtainCompanies}
          edit={true}
          dataEdit={edit.data}
        />
      )}
      {asignarModulos.open && (
        <AsignModulesModal
          visible={asignarModulos.open}
          setVisible={setAsignarModulos}
          modulosActivos={asignarModulos.data.modules}
          modules={modules}
          setLoading={setLoading}
          reload={obtainCompanies}
          endpoint={`company/modules/${asignarModulos.data._id}`}
        />
      )}
    </React.Fragment>
  );
};
export default TableCompany;
