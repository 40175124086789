import { useEffect, useState } from "react";
import { getWorkers } from "src/utils/req.boletas";
import { notification } from "antd";

const useWorkers = () => {
  const [workers, setWorkers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    obtainWorkers();
  }, []);

  const obtainWorkers = async () => {
    setLoading(true);
    try {
      const res = await getWorkers();
      setWorkers([...res]);
    } catch (error) {
      notification["error"]({ message: `Ocurrió un error al obtener la lista de trabajadores.` });
    } finally {
      setLoading(false);
    }
  };

  return { workers, loading, obtainWorkers, setLoading };
};

export default useWorkers;
