import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import SelectCuenta from "src/components/Selects/SelectCuenta";
import napformat from "src/config/napformat";
import { getAccountPlan } from "src/api/api.contable";
import sedeactual from "src/config/sedeactual";
import { notification } from "antd";

const TableDetails = ({ details, setLoading, setDetails }) => {
  const [accounts, setAccounts] = useState([]);
  const [sum, setSum] = useState({ debe: 0, haber: 0 });

  useEffect(() => {
    obtainData();
  }, []);

  useEffect(() => {
    if (details) {
      let tmpDebe = 0;
      let tmpHaber = 0;
      details.voucher.forEach((el) => {
        if (el.type_detail === "DEBE") tmpDebe += Number(el.amount);
        if (el.type_detail === "HABER") tmpHaber += Number(el.amount);
      });
      setSum({
        debe: parseFloat(tmpDebe).toFixed(2),
        haber: parseFloat(tmpHaber).toFixed(2),
      });
    }
  }, [details]);

  const obtainData = async () => {
    setLoading(true);
    try {
      let res = await getAccountPlan(sedeactual.get(), sedeactual.getAnio());
      const result = res.filter(
        (e) =>
          e.number.toString().length === sedeactual.digits() &&
          e.number < 900000
      );
      setAccounts(result);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al realizar la importación.",
      });
    } finally {
      setLoading(false);
    }
  };

  const assignVoucher = (value, index) => {
    details.voucher[index].account = value;
    setDetails({ cuentas: details.cuentas, voucher: details.voucher });
  };

  return (
    <TableContainer>
      <Table className="tablefe" size="small">
        <TableHead>
          <TableRow style={{ height: 60 }}>
            <TableCell align="center">CUENTA</TableCell>
            <TableCell align="center">GLOSA</TableCell>
            <TableCell align="center">REF. M. PAGO</TableCell>
            <TableCell align="center">MONEDA</TableCell>
            <TableCell align="center">DEBE</TableCell>
            <TableCell align="center">HABER</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {details &&
            details.voucher.map((row, i) => (
              <TableRow key={i}>
                <TableCell style={{ width: 300 }}>
                  {typeof row.account === "number" ? (
                    <SelectCuenta
                      defaultValue={row.account}
                      onChangeSelect={(e) => assignVoucher(e, i)}
                      data={accounts}
                    />
                  ) : (
                    details.cuentas[i].data?.number ?? "CUENTA ASIGNADA"
                  )}
                </TableCell>
                <TableCell>{row.comment}</TableCell>
                <TableCell>{row.ref_payment}</TableCell>
                <TableCell>{row.type_currency}</TableCell>
                <TableCell align="right">
                  {row.type_detail === "DEBE" ? napformat(row.amount) : ""}
                </TableCell>
                <TableCell align="right">
                  {row.type_detail === "HABER" ? napformat(row.amount) : ""}
                </TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell colSpan={4} />
            <TableCell>
              <b>{sum.debe}</b>
            </TableCell>
            <TableCell>
              <b>{sum.haber}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={4}>
              <b>DIFERENCIA</b>
            </TableCell>
            <TableCell colSpan={2} align="center">
              <b>{napformat(Number(sum.debe) - Number(sum.haber))}</b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableDetails;
