import React from "react";
import { Table } from "antd";
import Typography from "@material-ui/core/Typography";

const Tables = (props) => {
  const { loading, columns, ingresos, egresos, inversion, saldos } = props;

  return (
    <>
      <br />
      <Typography variant="h4">INGRESOS</Typography>
      <Table
        bordered
        columns={columns}
        pagination={false}
        loading={loading}
        dataSource={ingresos}
        scroll={{ x: "100%" }}
      />
      <br />
      <Typography variant="h4">EGRESOS</Typography>
      <Table
        bordered
        columns={columns}
        pagination={false}
        loading={loading}
        dataSource={egresos}
        scroll={{ x: "100%" }}
      />
      <br />
      <Typography variant="h4">INVERSIÓN</Typography>
      <Table
        bordered
        columns={columns}
        pagination={false}
        loading={loading}
        dataSource={inversion}
        scroll={{ x: "100%" }}
      />
      <br />
      <Typography variant="h4">SALDOS</Typography>
      <Table
        bordered
        columns={columns}
        pagination={false}
        loading={loading}
        dataSource={saldos}
        scroll={{ x: "100%" }}
      />
    </>
  );
};

export default Tables;
