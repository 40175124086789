import React, { useState } from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  apiContable,
  apiInventario,
  apiCuentas,
  apiReportes,
} from "../../config/api.contable";
import { notification } from "antd";
import Spinner from "./LoaderBackdrop";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00bfa5",
    },
  },
});

const Exportar = (props) => {
  const [loading, setLoading] = useState(false);
  const {
    name,
    endpoint,
    body,
    method,
    disabled,
    small,
    api,
    title = "DESCARGAR",
  } = props;

  const buildInit = () => {
    return {
      method: method,
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    };
  };

  let apiRequest =
    api === 1
      ? apiContable
      : api === 2
      ? apiInventario
      : api === 3
      ? apiCuentas
      : api === 4
      ? apiReportes
      : apiContable;

  const onClickLink = () => {
    setLoading(true);
    fetch(`${apiRequest}/${endpoint}`, buildInit())
      .then((response) => {
        if (response.ok) {
          response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = `${name}.xlsx`;
            a.click();
          });
        }
      })
      .catch((error) => {
        notification["error"]({
          message: "Ocurrió un error al generar el excel",
          description: `${error}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Button
        variant="contained"
        disabled={disabled}
        color="primary"
        onClick={onClickLink}
        style={{ color: "#fff" }}
      >
        <GetAppIcon /> {small ? "" : title}
      </Button>
      {loading && <Spinner visible={loading} />}
    </ThemeProvider>
  );
};

export default Exportar;
