import React from "react";
import moment from "moment";
import napformat from "../../../config/napformat";
import CustomTable from "src/components/CustomTable";

const TableDiario = (props) => {
  const { data, loading } = props;

  const columns = [
    {
      title: "CUENTA",
      dataIndex: "account_plan",
      key: "account_plan",
      align: "center",
      render: (text, record) => {
        return {
          props: {
            style: {
              background: record.account_plan
                ? ""
                : record.description.includes("MOVIMIENTO") &&
                  record.description.includes("DE LA CUENTA")
                ? "#0288d190"
                : "#0288d130",
            },
          },
          children: text,
        };
      },
    },
    {
      title: "DESCRIPCIÓN",
      dataIndex: "description",
      key: "description",
      align: "center",
      width: 500,
      render: (text, record) => {
        return {
          props: {
            style: {
              background: record.account_plan
                ? ""
                : record.description.includes("MOVIMIENTO") &&
                  record.description.includes("DE LA CUENTA")
                ? "#0288d190"
                : "#0288d130",
            },
          },
          children: text,
        };
      },
    },
    {
      title: "ORIGEN",
      dataIndex: "origin",
      key: "origin",
      align: "center",
      render: (text, record) => {
        return {
          props: {
            style: {
              background: record.account_plan
                ? ""
                : record.description.includes("MOVIMIENTO") &&
                  record.description.includes("DE LA CUENTA")
                ? "#0288d190"
                : "#0288d130",
            },
          },
          children: text,
        };
      },
    },
    {
      title: "N° VOUCHER",
      dataIndex: "n_voucher",
      key: "n_voucher",
      align: "center",
      width: 100,
      render: (text, record) => {
        return {
          props: {
            style: {
              background: record.account_plan
                ? ""
                : record.description.includes("MOVIMIENTO") &&
                  record.description.includes("DE LA CUENTA")
                ? "#0288d190"
                : "#0288d130",
            },
          },
          children: text,
        };
      },
    },
    {
      title: "RUC",
      dataIndex: "ruc",
      key: "ruc",
      align: "center",
      render: (text, record) => {
        return {
          props: {
            style: {
              background: record.account_plan
                ? ""
                : record.description.includes("MOVIMIENTO") &&
                  record.description.includes("DE LA CUENTA")
                ? "#0288d190"
                : "#0288d130",
            },
          },
          children: text,
        };
      },
    },
    {
      title: "N° T.DOC",
      dataIndex: "t_doc",
      key: "t_doc",
      align: "center",
      render: (text, record) => {
        return {
          props: {
            style: {
              background: record.account_plan
                ? ""
                : record.description.includes("MOVIMIENTO") &&
                  record.description.includes("DE LA CUENTA")
                ? "#0288d190"
                : "#0288d130",
            },
          },
          children: text,
        };
      },
    },
    {
      title: "FECHA",
      dataIndex: "operation_date",
      key: "operation_date",
      align: "center",
      width: 100,
      render: (text, record) => {
        return {
          props: {
            style: {
              background: record.account_plan
                ? ""
                : record.description.includes("MOVIMIENTO") &&
                  record.description.includes("DE LA CUENTA")
                ? "#0288d190"
                : "#0288d130",
            },
          },
          children: text ? moment(text).format("DD/MM/YYYY") : "",
        };
      },
    },
    {
      title: "DEBE",
      dataIndex: "debit",
      key: "debit",
      align: "center",
      render: (text, record) => {
        return {
          props: {
            style: {
              background: record.account_plan
                ? ""
                : record.description.includes("MOVIMIENTO") &&
                  record.description.includes("DE LA CUENTA")
                ? "#0288d190"
                : "#0288d130",
            },
          },
          children: text ? napformat(text) : "",
        };
      },
    },
    {
      title: "HABER",
      dataIndex: "asset",
      key: "asset",
      align: "center",
      render: (text, record) => {
        return {
          props: {
            style: {
              background: record.account_plan
                ? ""
                : record.description.includes("MOVIMIENTO") &&
                  record.description.includes("DE LA CUENTA")
                ? "#0288d190"
                : "#0288d130",
            },
          },
          children: text ? napformat(text) : "",
        };
      },
    },
    {
      title: "SALDO",
      dataIndex: "operation_date",
      key: "operation_date",
      align: "center",
      width: 100,
      render: (text, record) => {
        return {
          props: {
            style: {
              background: record.account_plan
                ? ""
                : record.description.includes("MOVIMIENTO") &&
                  record.description.includes("DE LA CUENTA")
                ? "#0288d190"
                : "#0288d130",
            },
          },
          children:
            record.debit || record.asset
              ? napformat(Number(record.debit) + Number(record.asset))
              : "",
        };
      },
    },
  ];

  return (
    <CustomTable
      columns={columns}
      data={data}
      loading={loading}
      pagination={{ pageSize: 100, position: "both" }}
    />
  );
};

export default TableDiario;
