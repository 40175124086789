import React from "react"
import { Typography } from "@material-ui/core"
import TableEnseres from "../../Enseres/TableEnseres"
import TableEquipos from "../../Equipos/TableEquipos"
import TableMuebles from "../../Muebles/TableMuebles"
import TableVehiculos from "../../Vehiculos/TableVehiculos"
import TableIntangibles from "../../Intangibles/TableIntangibles"

const TableDetailLocation = ( props ) => {
  const { equipos, muebles, vehiculos, enseres, intangibles } = props

  return (
    <>
      {/* ===== ENSERES ===== */ }
      { enseres && enseres.length > 0 && (
        <>
          <Typography variant="h3" color="textSecondary" align="center">
            <b>ENSERES</b>
          </Typography>
          <TableEnseres dataTable={ enseres } onlyview={ true } isCompact={ true } />
        </>
      ) }

      {/* ===== EQUIPOS ===== */ }
      { equipos && equipos.length > 0 && (
        <>
          <Typography variant="h3" color="textSecondary" align="center">
            <b>EQUIPOS</b>
          </Typography>
          <TableEquipos dataTable={ equipos } onlyview={ true } isCompact={ true } />
        </>
      ) }

      {/* ===== MUEBLES ===== */ }
      { muebles && muebles.length > 0 && (
        <>
          <Typography variant="h3" color="textSecondary" align="center">
            <b>MUEBLES</b>
          </Typography>
          <TableMuebles dataTable={ muebles } onlyview={ true } isCompact={ true } />
        </>
      ) }

      {/* ===== VEHÍCULOS ===== */ }
      { vehiculos && vehiculos.length > 0 && (
        <>
          <Typography variant="h3" color="textSecondary" align="center">
            <b>VEHÍCULOS</b>
          </Typography>
          <TableVehiculos dataTable={ vehiculos } onlyview={ true } isCompact={ true } />
        </>
      ) }

      {/* ===== INTANGIBLES ===== */ }
      { intangibles && intangibles.length > 0 && (
        <>
          <Typography variant="h3" color="textSecondary" align="center">
            <b>INTANGIBLES</b>
          </Typography>
          <TableIntangibles dataTable={ intangibles } onlyview={ true } isCompact={ true } />
        </>
      ) }

      { intangibles &&
        intangibles.length <= 0 &&
        enseres &&
        enseres.length <= 0 &&
        vehiculos &&
        vehiculos.length <= 0 &&
        muebles &&
        muebles.length <= 0 &&
        equipos &&
        equipos.length <= 0 && (
          <Typography color="textSecondary" variant="body1" align="center">
            <br />
            NO SE ENCONTRARON REGISTROS EN ESTA LOCACIÓN
          </Typography>
        ) }
    </>
  )
}

export default TableDetailLocation
