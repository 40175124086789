import React, { useState, useEffect } from "react";
import { notification } from "antd";
import UserServices from "../../services/UserServices";
import profile from "../../assets/img/profile.jpg";
import {
  Box,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
  TextField,
  Button,
  Collapse,
  IconButton,
  InputAdornment,
  Avatar,
  Card,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import { apiContable } from "../../config/api.contable";
import sedeactual from "../../config/sedeactual";
import LoaderBackdrop from "../Global/LoaderBackdrop";
import { SESSION_NAME } from "../../config/session";
import SessionStorageService from "../../auth/SessionStorageService";

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  profile: {
    border: "5px solid #cdcccc",
    width: 250,
    height: 250,
  },
}));

const Perfil = () => {
  const classes = useStyles();
  let datax = UserServices.getUser();
  const [loading, setLoading] = useState(false);

  // CAMBIAR INFORMACIÓN GENERAL
  const [fullname, setFullName] = useState(datax.fullname);
  const [phone, setPhone] = useState(datax.phone);
  const [address, setAddress] = useState(datax.address);
  const [email, setEmail] = useState(datax.email);

  // CAMBIAR CONTRASEÑA
  const [expanded, setExpanded] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [userName, setUserName] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const updatePassword = async () => {
    if (newPassword.length > 7 && newPassword === confirmPassword) {
      setLoading(true);
      try {
        await axios.post(`${apiContable}/auth/change_password`, {
          user: userName,
          password: currentPassword,
          newPassword: newPassword,
        });
        notification["success"]({
          message: "La contraseña se actualizó correctamente.",
          description:
            "Procura anotarlo y usarlo la siguiente vez que inicie sesión en el sistema.",
        });
        setUserName("");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setExpanded(false);
      } catch (error) {
        notification["error"]({
          message: "No se logró actualizar la contraseña.",
          description:
            "El usuario y/o contraseña no coindiden con nuestros registros.",
        });
      } finally {
        setLoading(false);
      }
    } else {
      notification["info"]({
        message:
          "La longitud mínima es 8 caracteres ó las contraseñas no coinciden.",
      });
    }
  };

  async function onSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      let data = {
        address: address,
        email: email,
        phone: phone,
        fullname: fullname,
      };
      let res = await axios.put(
        `${apiContable}/edit-user/${sedeactual.company().url}`,
        data,
        {
          headers: { Authorization: sedeactual.token() },
        }
      );
      let user = SessionStorageService.get(SESSION_NAME);
      let object = {
        expiresAt: user.expiresAt,
        value: user.value,
      };
      object.value.address = res.data.address;
      object.value.email = res.data.email;
      object.value.fullname = res.data.fullname;
      object.value.phone = res.data.phone;
      SessionStorageService.remove(SESSION_NAME);
      SessionStorageService.set(SESSION_NAME, object);
      window.location.reload(false);
    } catch (error) {
      notification["error"]({
        message: "Error!",
        description: "No se logró actualizar su información",
      });
    } finally {
      setLoading(false);
    }
  }

  const handleChangeImg = async (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      setLoading(true);
      try {
        let image = new FormData();
        image.append("profile", fileUploaded);
        let result = await axios.post(
          `${apiContable}/upload-img-profile/${sedeactual.company().url}`,
          image,
          {
            headers: { Authorization: sedeactual.token() },
          }
        );
        let user = SessionStorageService.get(SESSION_NAME);
        let object = {
          expiresAt: user.expiresAt,
          value: user.value,
        };
        object.value.profile_picture = result.data.profile;
        SessionStorageService.remove(SESSION_NAME);
        SessionStorageService.set(SESSION_NAME, object);
        window.location.reload(false);
      } catch (error) {
        notification["error"]({
          message: "Error!",
          description: "Ocurrió un error al intentar guardar la imagen",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const content = (
    <Box p={3}>
      <Card>
        {loading && <LoaderBackdrop />}
        <CardContent>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              lg={4}
              xl={3}
              container
              alignItems="center"
              justify="center"
            >
              <Avatar
                src={datax.profile_picture ? datax.profile_picture : profile}
                className={classes.profile}
              />
              <Button
                variant="outlined"
                color="secondary"
                component="label"
                fullWidth
                style={{ width: "calc(100% - 50px)" }}
              >
                <input
                  type="file"
                  onChange={handleChangeImg}
                  style={{ display: "none" }}
                  accept="image/*"
                />
                SUBIR FOTO
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={7} lg={8} xl={9}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography variant="h3" color="textSecondary">
                    <b style={{ borderBottom: "2px solid #546e7a" }}>
                      Información de perfíl
                    </b>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Nombre completo"
                    required
                    value={fullname}
                    onChange={(ev) => setFullName(ev.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Correo"
                    required
                    value={email}
                    onChange={(ev) => setEmail(ev.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Tel/Cel"
                    required
                    value={phone}
                    onChange={(ev) => setPhone(ev.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Dirección"
                    required
                    value={address}
                    onChange={(ev) => setAddress(ev.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={onSubmit}
                  >
                    Actualizar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <br />
              <br />
              <CardActionArea
                onClick={() => setExpanded(!expanded)}
                style={{
                  border: "1px solid #ddd",
                  borderBottom: expanded ? "none" : "1px solid #ddd",
                }}
              >
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  style={{ padding: "10px 25px" }}
                >
                  <div>
                    <ExpandMoreIcon
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                      })}
                    />
                  </div>

                  <Typography variant="h5" color="textSecondary">
                    ACTUALIZAR CONTRASEÑA
                  </Typography>
                  <div>
                    <ExpandMoreIcon
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                      })}
                    />
                  </div>
                </Grid>
              </CardActionArea>
              <Collapse
                in={expanded}
                style={{ border: "1px solid #ddd", borderTop: "none" }}
              >
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        fullWidth
                        label="Usuario"
                        variant="outlined"
                        value={userName}
                        onChange={(ev) => setUserName(ev.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        fullWidth
                        label="Contraseña actual"
                        variant="outlined"
                        value={currentPassword}
                        onChange={(ev) => setCurrentPassword(ev.target.value)}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        fullWidth
                        label="Contraseña nueva"
                        variant="outlined"
                        value={newPassword}
                        onChange={(ev) => setNewPassword(ev.target.value)}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        fullWidth
                        label="Confirme la contraseña"
                        variant="outlined"
                        value={confirmPassword}
                        onChange={(ev) => setConfirmPassword(ev.target.value)}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} align="center">
                      <Button
                        size="large"
                        color="secondary"
                        variant="contained"
                        onClick={() => updatePassword()}
                      >
                        Actualizar contraseña
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Collapse>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
  return content;
};

export default Perfil;
