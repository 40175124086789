import React from "react";
import CustomTable from "src/components/CustomTable";
import { Avatar, IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import GetAppIcon from "@material-ui/icons/GetApp";

const TableBoletasByHeadquarter = ({ data, loading }) => {
  const columns = [
    {
      title: "#",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      width: 60,
      render: (val, row, index) => (
        <Avatar style={{ backgroundColor: "#0683c9" }}>{index + 1}</Avatar>
      ),
    },
    {
      title: "TRABAJADOR",
      dataIndex: "colaborador",
      key: "colaborador",
      align: "center",
      width: 150,
      sorter: true,
      filter: true,
    },
    {
      title: "FECHA DE SUBIDA",
      dataIndex: "date_upload",
      key: "date_upload",
      align: "center",
      width: 150,
      sorter: true,
      filter: true,
    },
    {
      title: "FECHA DE PAGO",
      dataIndex: "date_voucher",
      key: "date_voucher",
      align: "center",
      width: 150,
      sorter: true,
      filter: true,
    },
    {
      title: "COMPROBANTE",
      dataIndex: "detail",
      key: "detail",
      align: "center",
      width: 400,
      sorter: true,
      filter: true,
    },
    {
      title: "ESTADO",
      dataIndex: "isView",
      key: "isView",
      align: "center",
      width: 150,
      sorter: true,
      filter: false,
      render: (value) => (
        <Alert severity={value ? "success" : "info"}>{value ? "VISTO" : "PENDIENTE"}</Alert>
      ),
    },
    {
      title: "ARCHIVO",
      dataIndex: "file",
      key: "file",
      align: "center",
      width: 100,
      sorter: false,
      filter: false,
      render: (value) => (
        <IconButton size="small" color="primary" component="a" href={value} target="_blank">
          <GetAppIcon />
        </IconButton>
      ),
    },
  ];

  return <CustomTable columns={columns} data={data} loading={loading} pagination={false} />;
};

export default TableBoletasByHeadquarter;
