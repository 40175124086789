import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import routes from "./routes";
import ApplicationRoute from "./components/ApplicationRoute";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          {routes.map((route) => (
            <ApplicationRoute
              exact={route.exact}
              key={route.path}
              path={route.path}
              listRoles={route.list_roles}
              component={route.component}
              isPrivate={route.isPrivate}
              module={route.module}
            />
          ))}
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
