import React, { useState, useEffect } from "react";
import { notification } from "antd";
import Page from "../../components/Page";
import { getLocations } from "./requests";
import AddIcon from "@material-ui/icons/Add";
import TablaLocacion from "./components/Locacion/TablaLocacion";
import { Container, Button } from "@material-ui/core";
import ModalAddLocacion from "./components/Locacion/components/ModalAddLocacion";
import sedeactual from "src/config/sedeactual";

const Locacion = () => {
  const [openModalAddLocacion, setOpenModalAddLocacion] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    obtainData();
  }, []);

  const obtainData = async () => {
    setLoading(true);
    try {
      const res = await getLocations();
      res.data.sort((a, b) =>
        a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1
      );
      setData(res.data);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al obtener la información.",
        description: `${error}`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page
      title="LOCACIONES"
      helper="INVENTARIO"
      itemComponent={
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpenModalAddLocacion(true)}
          disabled={sedeactual.getRol() === "usuario"}
        >
          AGREGAR
          <AddIcon />
        </Button>
      }
    >
      <Container maxWidth="md">
        <TablaLocacion
          data={data}
          reload={obtainData}
          setLoading={setLoading}
          loading={loading}
        />
      </Container>
      {openModalAddLocacion && (
        <ModalAddLocacion
          visible={openModalAddLocacion}
          setVisible={setOpenModalAddLocacion}
          reload={obtainData}
          setLoading={setLoading}
        />
      )}
    </Page>
  );
};

export default Locacion;
