import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const SelectMultipleSedes = ({ data, sedes, setSedes }) => {
  return (
    <Autocomplete
      multiple
      options={data}
      getOptionLabel={(option) => option.description}
      filterSelectedOptions
      value={sedes}
      disableCloseOnSelect
      onChange={(one, two) => setSedes([...two])}
      renderInput={(params) => (
        <TextField {...params} fullWidth variant="outlined" label="Sedes" placeholder="Favorites" />
      )}
    />
  );
};

export default SelectMultipleSedes;
