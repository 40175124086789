import React from "react";
import { Select } from "antd";
import { Typography } from "@material-ui/core";

const SelectLocations = (props) => {
  const { onChangeSelect, defaultValue = null, data = [] } = props;

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const filterOptions = (inputValue, option) => {
    const title = removeAccents(option.props.title.toLowerCase());
    return title.includes(inputValue.toLowerCase());
  };

  const { Option } = Select;

  return (
    <Select
      size="large"
      style={{ width: "100%" }}
      showSearch
      value={defaultValue}
      onChange={(e) => onChangeSelect(e)}
      filterOption={filterOptions}
    >
      <Option key={0} value={null} title={""}>
        - Ninguno -
      </Option>
      {data.map((i) => (
        <Option
          key={i._id}
          value={i._id}
          title={`${i.name} - ${i.description}`}
        >
          <Typography variant="caption" color="textSecondary">
            <b>{i.name.toUpperCase()}</b> - {i.description.toUpperCase()}
          </Typography>
        </Option>
      ))}
    </Select>
  );
};
export default SelectLocations;
