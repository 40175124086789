import React, { useState } from "react"
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import moment from "moment"
import napformat from "../../../../config/napformat"

const useStyles = makeStyles( ( theme ) => ( {
  imagen: {
    border: "5px solid #eee",
    maxWidth: 250,
    maxHeight: "100%",
    borderRadius: 5,
    marginBottom: 5,
  },
} ) )

const DetailEquipos = ( props ) => {
  const classes = useStyles()
  const { visible, setVisible, data } = props

  return (
    <Dialog open={ visible } fullScreen classes={ { paper: classes.dialog } }>
      <DialogTitle>
        <Grid container>
          <Grid item xs={ 1 }>
          </Grid>
          <Grid item xs={ 10 }>
            <Typography
              variant="h2"
              component="h2"
              color="textSecondary"
              align="center"
            >
              DETALLES
            </Typography>
          </Grid>
          <Grid item xs={ 1 }>
            <Button
              color="secondary"
              variant="contained"
              onClick={ () => setVisible( false ) }
            >
              Cerrar
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContent>
          <Grid container spacing={ 2 }>
            <Grid item xs={ 12 } md={ 6 }>
              <Typography
                variant="h4"
                component="h3"
                color="textSecondary"
                align="center"
              >
                Información general
              </Typography>
              <Table size="small" style={ { border: "1px solid #eee" } }>
                <TableBody>
                  <TableRow>
                    <TableCell style={ { width: 175 } }>Categoria</TableCell>
                    <TableCell align="right">{ data.category.name }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={ { width: 175 } }>Sub Categoria</TableCell>
                    <TableCell align="right">
                      { data.subcategory?.name ?? "" }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={ { width: 175 } }>
                      Cuenta contable
                    </TableCell>
                    <TableCell align="right">
                      { data.category.account_number }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={ { width: 175 } }>
                      Nombre de la cuenta
                    </TableCell>
                    <TableCell align="right">
                      { data.category.account_name }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={ { width: 175 } }>Marca</TableCell>
                    <TableCell align="right">{ data.trademark }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={ { width: 175 } }>Modelo</TableCell>
                    <TableCell align="right">{ data.model }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={ { width: 175 } }>
                      N° de serie o placa
                    </TableCell>
                    <TableCell align="right">{ data.serie }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={ { width: 175 } }>
                      Fecha de adquisición:
                    </TableCell>
                    <TableCell align="right">
                      { moment( data.acquisition_date ).format(
                        "D MMMM [del] YYYY"
                      ) }
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <br />
              <Typography
                variant="h4"
                component="h3"
                color="textSecondary"
                align="center"
              >
                Valor
              </Typography>
              <Table size="small" style={ { border: "1px solid #eee" } }>
                <TableBody>
                  <TableRow>
                    <TableCell style={ { width: 175 } }>Valor unitario</TableCell>
                    <TableCell align="right">
                      <span>S/</span>
                      { napformat( data.unit_value ) }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={ { width: 175 } }>Cantidad</TableCell>
                    <TableCell align="right">{ data.amount } unidades</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={ { width: 175 } }>Valor total</TableCell>
                    <TableCell align="right">
                      <span>S/</span>
                      { napformat( data.historical_value ) }
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <br />
              <Typography
                variant="h4"
                component="h3"
                color="textSecondary"
                align="center"
              >
                Otros
              </Typography>
              <Table size="small" style={ { border: "1px solid #eee" } }>
                <TableBody>
                  <TableRow>
                    <TableCell style={ { width: 175 } }>Descripción:</TableCell>
                    <TableCell align="right">
                      { data.description ? data.description : "No especificado" }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={ { width: 175 } }>Estado:</TableCell>
                    <TableCell align="right">{ data.status }</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={ 12 } md={ 6 }>
              { data.images.length > 0 ? (
                <Grid
                  container
                  direction="row-reverse"
                  justify="space-around"
                  alignItems="center"
                >
                  { data.images.map( ( ev ) => (
                    <a href={ ev } target="_blank">
                      <img
                        src={ ev }
                        alt="Foto del terreno"
                        className={ classes.imagen }
                      />
                    </a>
                  ) ) }
                </Grid>
              ) : (
                <div style={ { textAlign: "center" } }>
                  <Typography variant="p" color="textSecondary">
                    <br />
                    <br />
                    No se adjuntaron imagenes.
                  </Typography>
                </div>
              ) }
            </Grid>
            <Grid item xs={ 12 }>
              <Grid container spacing={ 1 }>
                { data.docs &&
                  data.docs.length > 0 &&
                  data.docs.map( ( ev, index ) => (
                    <Grid item xs={ 12 } md={ 6 }>
                      <embed
                        src={ ev }
                        type="application/pdf"
                        width="100%"
                        height="400px"
                      />
                    </Grid>
                  ) ) }
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </DialogContent>
    </Dialog>
  )
}

export default DetailEquipos
