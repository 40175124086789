import React, { useEffect, useState } from "react";
import { Select } from "antd";
import moment from "moment";

const SelectYear = ({ setYear, year, width = "100%" }) => {
  const { Option } = Select;
  const [list, setList] = useState([]);

  // const removeAccents = (str) => {
  //   return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  // };

  // const filterOptions = (inputValue, option) => {
  //   const title = removeAccents(option.props.title.toLowerCase());
  //   return title.includes(inputValue.toLowerCase());
  // };

  useEffect(() => {
    for (let i = 0; i <= 10; i++) {
      list.push(moment().year() - 5 + i);
    }
    setList([...list]);
  }, []);

  return (
    <Select
      size="large"
      style={{ width }}
      showSearch
      value={year}
      onChange={(e) => setYear(e)}
      // filterOption={filterOptions}
    >
      <Option key={0} value={null} title={""}>
        - Ninguno -
      </Option>
      {list.map((el) => (
        <Option key={el} value={el} title={el}>
          {el}
        </Option>
      ))}
    </Select>
  );
};
export default SelectYear;
