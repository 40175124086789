import React, { Fragment, useState } from "react"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import {
  Select,
  TextField,
  Grid,
  Button,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import { postWorkers, putWorkers } from "src/utils/req.boletas"
import LoaderBackdrop from "src/components/Global/LoaderBackdrop"
import useForm from "react-hook-form"
import { notification } from "antd"
import sedeactual from "src/config/sedeactual"
import SelectSexo from "src/components/Selects/SelectSexo"
import SelectCivilStatus, { listCivilStatus } from "src/components/Selects/SelectCivilStatus"
import SelectReasonDown from "src/components/Selects/SelectReasonDown"
import moment from "moment"
import ModalShowDates from "./ModalShowDates"

const ModalWorkers = ( props ) => {
  console.log( sedeactual.getRol() )
  const { open, setOpen, data, setLoading, loading, refresh, disable } = props
  const { register, handleSubmit, errors } = useForm()
  const [ info, setInfo ] = useState( {
    t_doc: data ? data.t_doc : "DNI",
    sexo: data ? data.sexo : "M",
    civilStatus: data ? data.civilStatus : listCivilStatus[ 0 ],
    reason: data ? data.reason : null,
    dates: data ? data.dates ?? [] : [],
  } )

  const [ modalShowDates, setModalShowDates ] = useState( { isOpen: false, data: null } )

  const sendData = async ( items, event ) => {
    event.preventDefault()
    try {
      setLoading( false )

      if ( !disable ) {
        items[ "user" ] = items.n_doc
        items[ "f_name" ] = items.f_name.toUpperCase()
        items[ "l_name" ] = items.l_name.toUpperCase()
      }

      if ( !data ) {
        items[ "headquarter" ] = sedeactual.sede_id()
        items[ "company" ] = sedeactual.company()._id
      }

      if ( disable ) items[ "status" ] = false

      if ( data ) await putWorkers( { ...items, ...info }, data._id )
      else await postWorkers( { ...items, ...info } )

      notification[ "success" ]( {
        message: `Se ${ Boolean( data ) ? "actualizó" : "guardó" } correctamente`,
      } )
      refresh()
      setOpen( { open: false } )
    } catch ( error ) {
      console.log( error )
      notification[ "error" ]( {
        message: `Ocurrió un error al realizar la operación.`,
      } )
    } finally {
      setLoading( false )
    }
  }

  const handleAdd = () => {
    info.dates.push( { dateStart: "", dateEnd: "" } )
    setInfo( { ...info } )
  }

  const handleRemove = ( indx ) => () => {
    info.dates.splice( indx, 1 )
    setInfo( { ...info } )
  }

  const handleChange = ( index, key, value ) => {
    info.dates[ index ][ key ] = value
    setInfo( { ...info } )
  }

  return (
    <Dialog open={ open } fullWidth maxWidth="md">
      <DialogTitle align="center">
        <h2>
          <b>{ `${ disable ? "DESHABILITAR" : Boolean( data ) ? "EDITAR" : "AGREGAR" }` } TRABAJADOR</b>
        </h2>
      </DialogTitle>
      { loading && <LoaderBackdrop /> }
      <form autoComplete="off" onSubmit={ handleSubmit( sendData ) }>
        <DialogContent>
          <Grid container spacing={ 1 }>
            { !disable && (
              <React.Fragment>
                <Grid item xs={ 12 } sm={ 6 }>
                  <TextField
                    InputLabelProps={ { shrink: true } }
                    label="Apellidos"
                    fullWidth
                    autoFocus
                    variant="outlined"
                    name="f_name"
                    defaultValue={ Boolean( data ) ? data.f_name : "" }
                    inputRef={ register( { required: true } ) }
                    error={ Boolean( errors?.f_name ) }
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 }>
                  <TextField
                    InputLabelProps={ { shrink: true } }
                    label="Nombres"
                    fullWidth
                    variant="outlined"
                    name="l_name"
                    defaultValue={ Boolean( data ) ? data.l_name : "" }
                    inputRef={ register( { required: true } ) }
                    error={ Boolean( errors?.l_name ) }
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } lg={ 4 }>
                  <TextField
                    InputLabelProps={ { shrink: true } }
                    label="Correo"
                    fullWidth
                    variant="outlined"
                    name="email"
                    defaultValue={ Boolean( data ) ? data.email : "" }
                    inputRef={ register( {
                      required: false,
                      pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                    } ) }
                    error={ Boolean( errors?.email ) }
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } lg={ 8 }>
                  <TextField
                    InputLabelProps={ { shrink: true } }
                    label="Dirección"
                    fullWidth
                    variant="outlined"
                    name="address"
                    defaultValue={ Boolean( data ) ? data.address : "" }
                    inputRef={ register( { required: false } ) }
                    error={ Boolean( errors?.address ) }
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } lg={ 4 }>
                  <TextField
                    InputLabelProps={ { shrink: true } }
                    label="Celular"
                    fullWidth
                    variant="outlined"
                    name="phone"
                    type="number"
                    defaultValue={ Boolean( data ) ? data.phone : "" }
                    inputRef={ register( {
                      required: false,
                      pattern: /^[0-9]+$/i,
                      minLength: 6,
                    } ) }
                    error={ Boolean( errors?.phone ) }
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } lg={ 4 }>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Tipo documento</InputLabel>
                    <Select
                      label="Tipo documento"
                      value={ info.t_doc }
                      onChange={ ( e ) => setInfo( { ...info, t_doc: e.target.value } ) }
                    >
                      <MenuItem value="DNI">DNI</MenuItem>
                      <MenuItem value="PTP">PTP</MenuItem>
                      <MenuItem value="PASSPORT">PASAPORTE</MenuItem>
                      <MenuItem value="CNT_EXT">CARNÉ EXTRANJERÍA</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } lg={ 4 }>
                  <TextField
                    InputLabelProps={ { shrink: true } }
                    label="Nro. Documento"
                    fullWidth
                    variant="outlined"
                    name="n_doc"
                    type="number"
                    defaultValue={ Boolean( data ) ? data.n_doc : "" }
                    inputRef={ register( {
                      required: true,
                      pattern: /^[0-9]+$/i,
                      minLength: 8,
                    } ) }
                    error={ Boolean( errors?.n_doc ) }
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } lg={ 4 }>
                  <FormHelperText>SEXO</FormHelperText>
                  <SelectSexo sexo={ info.sexo } setSexo={ ( e ) => setInfo( { ...info, sexo: e } ) } />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } lg={ 4 }>
                  <FormHelperText>ESTADO CIVIL</FormHelperText>
                  <SelectCivilStatus
                    value={ info.civilStatus }
                    setValue={ ( e ) => setInfo( { ...info, civilStatus: e } ) }
                  />
                </Grid>
              </React.Fragment>
            ) }

            <Grid container spacing={ 1 } style={ { marginTop: "20px" } }>
              { info.dates.length > 0 ? (
                <Fragment>
                  <Fragment>
                    <Grid item xs={ 12 } md={ 3 }>
                      <FormHelperText> </FormHelperText>
                      <TextField
                        InputLabelProps={ { shrink: true } }
                        label="Fecha de inicio"
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="date"
                        value={ moment( info.dates[ info.dates.length - 1 ].dateStart ).format(
                          "YYYY-MM-DD"
                        ) }
                        onChange={ ( e ) =>
                          handleChange( info.dates.length - 1, "dateStart", e.target.value )
                        }
                      />
                    </Grid>
                    <Grid item xs={ 12 } md={ 3 }>
                      <FormHelperText> </FormHelperText>
                      <TextField
                        InputLabelProps={ { shrink: true } }
                        label="Fecha de finalización"
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="date"
                        value={ moment( info.dates[ info.dates.length - 1 ].dateEnd ).format(
                          "YYYY-MM-DD"
                        ) }
                        onChange={ ( e ) =>
                          handleChange( info.dates.length - 1, "dateEnd", e.target.value )
                        }
                      />
                    </Grid>
                    <Grid item xs={ 12 } md={ 4 }>
                      <FormHelperText>MOTIVO DE BAJA</FormHelperText>
                      <SelectReasonDown
                        value={ info.dates[ info.dates.length - 1 ].motivoBaja }
                        setValue={ ( e ) => handleChange( info.dates.length - 1, "motivoBaja", e ) }
                      />
                    </Grid>
                    <Grid item xs={ 12 } md={ 2 }>
                      <FormHelperText> </FormHelperText>
                      <Button
                        color="primary"
                        fullWidth
                        onClick={ () => setModalShowDates( { isOpen: true, data: info.dates } ) }
                      >
                        Ver detalles
                      </Button>
                    </Grid>
                  </Fragment>
                </Fragment>
              ) : (
                <>
                  <Grid item xs={ 12 }>
                    <FormHelperText style={ { textAlign: "center" } }>
                      <b>NO HAY FECHAS LABORALES REGISTRADAS.</b>
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={ 12 } align="center">
                    <Button color="primary" variant="contained" onClick={ handleAdd }>
                      AGREGAR
                    </Button>
                  </Grid>
                </>
              ) }
            </Grid>

            { sedeactual.getRol() === 'supernap' && (
              <Grid container spacing={ 1 } style={ { marginTop: "20px" } }>
                <Grid item xs={ 12 } >
                  <FormHelperText> </FormHelperText>
                  <TextField
                    InputLabelProps={ { shrink: true } }
                    label="Nueva contraseña"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name='password'
                    inputRef={ register( {
                      required: false,
                      minLength: 8,
                    } ) }
                    error={ Boolean( errors?.password ) }
                  />
                </Grid>
              </Grid>
            ) }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={ () => setOpen( { open: false } ) }
            size="large"
          >
            CANCELAR
          </Button>
          <Button
            color={ disable ? "secondary" : "primary" }
            variant="contained"
            type="submit"
            size="large"
          >
            { disable ? "Deshabilitar" : data ? "Actualizar" : "Guardar" }
          </Button>
        </DialogActions>
      </form>

      {/* Modal ver fechas laborales */ }
      { modalShowDates.isOpen && (
        <ModalShowDates
          isOpen={ modalShowDates.isOpen }
          data={ modalShowDates.data }
          setModal={ setModalShowDates }
          info={ info }
          setInfo={ setInfo }
        />
      ) }
    </Dialog>
  )
}

export default ModalWorkers
