import React, { useState } from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { Modal, notification } from "antd";
import axios from "axios";
import { apiCuentas } from "../../../config/api.contable";

const EditPresupuesto = (props) => {
  const { visible, setVisible, loading, setLoading, getBudgetList, data } =
    props;
  const [amount, setAmount] = useState(data.amount);

  const updateBudget = async () => {
    if (amount && amount > 0) {
      setLoading(true);
      try {
        await axios.put(`${apiCuentas}/budget/list/${data._id}`, {
          estimateAmount: amount,
        });
        notification["success"]({
          message: "El registro se actualizó.",
        });
        getBudgetList();
        setVisible(false);
      } catch (error) {
        notification["error"]({
          message: `No se pudo actualizar el registro. ${error}`,
        });
      }
      setTimeout(() => setLoading(false), 1000);
    } else {
      notification["info"]({ message: "El monto es requerido." });
    }
  };

  return (
    <Modal
      title={
        <Typography align="center" variant="h3">
          <b>ACTUALIZAR PRESUPUESTO</b> <br />
          <Typography variant="body1" color="textSecondary">
            {data.number} - {data.description && data.description.toUpperCase()}
          </Typography>
        </Typography>
      }
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setVisible(false)}
        >
          Cancelar
        </Button>,
        <Button
          variant="contained"
          color="primary"
          onClick={updateBudget}
          loading={loading}
        >
          actualizar
        </Button>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            autoFocus
            label="Monto"
            variant="outlined"
            fullWidth
            size="small"
            value={amount}
            type="number"
            inputProps={{ step: "0.01", min: 0 }}
            onChange={(e) => setAmount(e.target.value)}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default EditPresupuesto;
