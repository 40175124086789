import React from "react";
import CustomTable from "src/components/CustomTable";
import { Button, ButtonGroup } from "@material-ui/core";
import { CloudDownload, Delete } from "@material-ui/icons";

const TableWorkerInformation = ({ data, loading, setDel, rol }) => {
  const columns = [
    {
      title: "NOMBRE DEL DOCUMENTO",
      dataIndex: "detail",
      key: "detail",
      align: "center",
      width: 400,
      sorter: true,
      filter: true,
    },
    {
      title: "TRABAJADOR",
      dataIndex: "fullname",
      key: "fullname",
      align: "center",
      width: 250,
      sorter: true,
      filter: true,
    },
    {
      title: "FECHA DE SUBIDA",
      dataIndex: "date_upload",
      key: "date_upload",
      align: "center",
      type: 'date',
      width: 175,
      sorter: true,
      filter: true,
    },
    {
      title: "ACCIONES",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      width: 150,
      sorter: false,
      filter: false,
      render: (value, row) => (
        <ButtonGroup variant="contained">
          {rol === "supernap" && (
            <Button onClick={() => setDel({ open: true, id: value })} color="secondary">
              <Delete />
            </Button>
          )}
          <Button color="primary" variant="outlined" component="a" target="_blank" href={row.file}>
            <CloudDownload />
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  return <CustomTable columns={columns} data={data} loading={loading} pagination={false} />;
};

export default TableWorkerInformation;
