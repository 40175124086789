import React, { useState, Fragment, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormHelperText,
  InputAdornment,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@material-ui/core";
import _ from "lodash/fp";
import { makeStyles } from "@material-ui/styles";
import useForm from "react-hook-form";
import moment from "moment";
import { apiInventario } from "../../../../config/api.contable";
import axios from "axios";
import NumberFormat from "react-number-format";
import { notification, Tooltip } from "antd";
import SelectVoucherType from "src/components/Selects/SelectVoucherType";
import { customConsultRuc } from "src/utils/ruc";
import AddSubCategory from "../Categorias/AddSubCategory";
import { Add } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: "50%",
    maxWidth: "80%",
  },
  iconText: {
    color: "#aaa",
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //prefix="S/ "
    />
  );
}

const AddEquipos = (props) => {
  const classes = useStyles();
  const {
    visible,
    setVisible,
    reloadFunction,
    categories,
    data,
    setLoading,
    loading,
    reloadCategories,
  } = props;
  const { register, handleSubmit, errors } = useForm();
  const [status, setStatus] = useState(data.status);
  const [customValue, setCustomValue] = useState(data.category);
  const [values, setValues] = useState({
    unit_value: data.unit_value,
    historical_value: data.historical_value,
    height: data.height,
    length: data.length,
    depth: data.depth,
  });
  const [subCategories, setSubCategories] = useState(data.category.subcategory);
  const [subcategory, setSubcategory] = useState(data.subcategory?._id ?? "");
  const [aditional, setAditional] = useState({
    type: data.aditional?.type ?? "",
    serie: data.aditional?.serie ?? "",
    correlative: data.aditional?.correlative ?? "",
    t_doc: data.aditional?.t_doc ?? "",
    n_doc: data.aditional?.n_doc ?? "",
    name: data.aditional?.name ?? "",
  });

  const [modalAddSubtegory, setModalAddSubtegory] = useState({ isOpen: false, data: null });

  const addRegister = async (dataEdit) => {
    setLoading(true);
    try {
      if (values.unit_value != "" && values.historical_value != "") {
        dataEdit.category = customValue._id;
        dataEdit.subcategory = subcategory ? subcategory : null;
        dataEdit.status = status;
        dataEdit.unit_value = values.unit_value;
        dataEdit.historical_value = values.historical_value;
        dataEdit.height = values.height;
        dataEdit.length = values.length;
        dataEdit.depth = values.depth;
        dataEdit.model = dataEdit.model.toUpperCase();
        dataEdit.trademark = dataEdit.trademark.toUpperCase();
        dataEdit.description = dataEdit.description.toUpperCase();
        dataEdit.aditional = aditional;

        if (
          (dataEdit.model == "") ^ (dataEdit.trademark == "") ^ (dataEdit.description == "") ||
          dataEdit.height == "" ||
          dataEdit.length == ""
        ) {
          notification["info"]({
            message:
              "La descripción es requerida en caso de no ingresar modelo o marca. La altura y longitud son requeridos.",
          });
          setLoading(false);
          return null;
        } else {
          await axios.put(`${apiInventario}/fixed-asset/${data._id}`, dataEdit);
          notification["success"]({
            message: "El registro se actualizó correctamente.",
          });
          reloadFunction();
          setVisible(false);
        }
      } else {
        notification["info"]({
          message: "Campos requeridos que falta completar.",
        });
      }
    } catch (err) {
      notification["error"]({
        message: "Ocurrió un error al actualizar el registro.",
        description: `${err}`,
      });
    }
  };

  const handleChange = (event) => {
    if (event.target.name == "unit_value") {
      values.historical_value = event.target.value.toUpperCase();
    }
    setValues({
      ...values,
      [event.target.name]: event.target.value.toUpperCase(),
    });
  };

  const changeAditional = (key, value) => setAditional({ ...aditional, [key]: value });

  const consultRuc = async () => {
    try {
      setLoading(true);
      const res = await customConsultRuc(aditional.n_doc);
      if (res.success) {
        notification["success"]({ message: "INFORMACIÓN ENCONTRADA" });
        setAditional({
          ...aditional,
          t_doc: res.t_doc,
          n_doc: res.n_doc,
          name: res.name,
        });
      } else {
        notification["info"]({
          message: `${aditional.t_doc} INVÁLIDO`,
          description: "Solo se permite la busqueda de RUC (11 dígitos) y DNI (8 dígitos)",
        });
      }
    } catch (error) {
      console.log(error);
      notification["warning"]({ message: "Ocurrió un error al realizar la consulta..." });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (customValue && categories) {
      const findCategory = categories.find((item) => item._id === customValue._id);
      if (!findCategory) return;
      setSubCategories([...findCategory.subcategory]);
    }
  }, [categories]); // eslint-disable-line

  const handleChangeModal = (data) => setModalAddSubtegory({ isOpen: true, data });

  return (
    <Dialog open={visible} classes={{ paper: classes.dialog }}>
      <DialogTitle>
        <Typography variant="h2" component="h2" color="textPrimary" align="center">
          EDITAR MUEBLE
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(addRegister)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={7}>
              <Autocomplete
                id="combo-box-demo"
                options={categories}
                fullWidth
                getOptionLabel={(option) => option.name.toUpperCase()}
                renderOption={(option) => (
                  <React.Fragment>
                    <Typography component="span" variant="subtitle2">
                      {option.name.toUpperCase()}{" "}
                      <FormHelperText component="span">
                        {option.account_number} {option.account_name}
                      </FormHelperText>
                    </Typography>
                  </React.Fragment>
                )}
                onChange={(event, newValue) => {
                  setCustomValue(newValue);
                  setSubCategories([...newValue.subcategory]);
                }}
                defaultValue={customValue}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Categoria" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>SubCategorias</InputLabel>
                <Select
                  value={subcategory}
                  onChange={(event) => setSubcategory(event.target.value)}
                  label="SubCategorias"
                >
                  {subCategories.map((el) => (
                    <MenuItem value={el._id}>{el.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={1} style={{ display: "flex", alignItems: "flex-end" }}>
              <Tooltip title="Crear subcategoria" placement="bottom">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleChangeModal(customValue)}
                  size="large"
                  fullWidth
                  disabled={!customValue}
                  style={{ minHeight: "55px" }}
                >
                  <Add />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                label="Valor unitario *"
                fullWidth
                variant="outlined"
                name="unit_value"
                value={values.unit_value}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className={classes.iconText}>
                      S/
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                label="Valor total*"
                fullWidth
                variant="outlined"
                name="historical_value"
                value={values.historical_value}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className={classes.iconText}>
                      S/
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                label="Fecha de adquisición"
                fullWidth
                variant="outlined"
                defaultValue={moment(data.acquisition_date).format("YYYY-MM-DD")}
                type="date"
                name="acquisition_date"
                inputRef={register({
                  required: true,
                })}
              />
              {_.get("acquisition_date.type", errors) === "required" && (
                <FormHelperText className={classes.error}>
                  La fecha de adquisición es requerida
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <TextField
                label="Altura*"
                fullWidth
                variant="outlined"
                name="height"
                onChange={handleChange}
                value={values.height}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className={classes.iconText}>
                      cm
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <TextField
                label="Longitud*"
                fullWidth
                variant="outlined"
                name="length"
                onChange={handleChange}
                value={values.length}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className={classes.iconText}>
                      cm
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <TextField
                label="Profundidad"
                fullWidth
                variant="outlined"
                name="depth"
                onChange={handleChange}
                value={values.depth}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className={classes.iconText}>
                      cm
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                label="Modelo"
                fullWidth
                variant="outlined"
                name="model"
                defaultValue={data.model}
                inputRef={register({
                  required: false,
                  maxLength: 20,
                  // // pattern: /^[A-Za-z0-9., ]+$/i,
                })}
              />
              <FormHelperText error>
                {_.get("model.type", errors) === "required" && "El modelo es requerido."}
                {_.get("model.type", errors) === "maxLength" &&
                  "No debe exceder los 20 caracteres."}
                {_.get("model.type", errors) === "pattern" &&
                  "Solo se acepta caracteres alfabéticos y números."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                label="Marca"
                fullWidth
                variant="outlined"
                name="trademark"
                defaultValue={data.trademark}
                inputRef={register({
                  required: false,
                  maxLength: 100,
                  // pattern: /^[A-Za-z0-9., ]+$/i,
                })}
              />
              <FormHelperText error>
                {_.get("trademark.type", errors) === "required" &&
                  "La marca comercial es requerida."}
                {_.get("trademark.type", errors) === "maxLength" &&
                  "No debe exceder los 100 caracteres."}
                {_.get("trademark.type", errors) === "pattern" &&
                  "Solo se acepta números y caracteres alfabeticos."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Estado</InputLabel>
                <Select
                  value={status}
                  onChange={(event) => setStatus(event.target.value.toUpperCase())}
                  label="Estado"
                >
                  <MenuItem value="BUENO">BUENO</MenuItem>
                  <MenuItem value="REGULAR">REGULAR</MenuItem>
                  <MenuItem value="DETERIORADO">DETERIORADO</MenuItem>
                  <MenuItem value="FALTA ARREGLAR">FALTA ARREGLAR</MenuItem>
                  <MenuItem value="MALOGRADO">MALOGRADO</MenuItem>
                  <MenuItem value="DESUSO">DESUSO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={8}>
              <TextField
                label="Descripción"
                fullWidth
                variant="outlined"
                defaultValue={data.description}
                name="description"
                inputRef={register({
                  required: false,
                  maxLength: 255,
                })}
              />
              <FormHelperText error>
                {_.get("description.type", errors) === "required" && "La descripción es requerida."}
                {_.get("description.type", errors) === "maxLength" &&
                  "No debe exceder los 255 caracteres."}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <FormHelperText style={{ borderBottom: "1px solid" }}>
                INFORMACIÓN ADICIONAL
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SelectVoucherType
                value={aditional.type}
                setValue={(value) => changeAditional("type", value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Serie"
                variant="outlined"
                fullWidth
                value={aditional.serie}
                onChange={(e) => changeAditional("serie", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Correlativo"
                variant="outlined"
                fullWidth
                value={aditional.correlative}
                onChange={(e) => changeAditional("correlative", e.target.value)}
              />
            </Grid>
            {aditional.type !== "OTROS" && (
              <Fragment>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="RUC o DNI (Escriba y presione el botón buscar)"
                    variant="outlined"
                    fullWidth
                    value={aditional.n_doc}
                    onChange={(e) => changeAditional("n_doc", e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={consultRuc}
                          >
                            BUSCAR
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="Nombre o razón social"
                    variant="outlined"
                    fullWidth
                    value={aditional.name}
                    disabled
                    onChange={(e) => changeAditional("name", e.target.value)}
                  />
                </Grid>
              </Fragment>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="outlined" onClick={() => setVisible(false)}>
            Cerrar
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={data.category._id == "" || loading ? true : false}
          >
            ACTUALIZAR
          </Button>
        </DialogActions>
        {categories.length > 0 ? (
          ""
        ) : (
          <Typography align="right" color="secondary">
            No se encontraron categorías registradas. Registre categorías y vuelva a intentarlo.
          </Typography>
        )}
      </form>

      {modalAddSubtegory.isOpen && (
        <AddSubCategory
          open={modalAddSubtegory.isOpen}
          setOpen={setModalAddSubtegory}
          data={modalAddSubtegory.data}
          reloadFunction={reloadCategories}
        />
      )}
    </Dialog>
  );
};

export default AddEquipos;
