import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import Page from "../../../components/Page/Page";
import GetAppIcon from "@material-ui/icons/GetApp";
import ReactToPrint from "react-to-print";
import Details from "./Details/Details";
import { getRealVoucher } from "../../../api/api.contable";
import { notification } from "antd";
import Spinner from "../../../components/Global/Spinner";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: "100%",
    margin: "0 auto",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const InvoiceDetails = (props) => {
  const classes = useStyles();
  const componentRef = useRef();
  const [loading, setloading] = useState(false);
  const [datavoucher, setdatavoucher] = useState(null);

  useEffect(() => {
    getDatax();
  }, []);

  async function getDatax() {
    setloading(true);
    try {
      const response = await getRealVoucher(props.match.params.id);
      setdatavoucher(response.data);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al obtener la información del voucher.",
      });
    } finally {
      setloading(false);
    }
  }

  return (
    <Page
      title="DETALLE DE VOUCHER"
      helper="VOUCHER"
      itemComponent={
        <ReactToPrint
          trigger={() => (
            <Button color="primary" variant="contained">
              <GetAppIcon className={classes.getAppIcon} /> DESCARGAR / IMPRIMIR
            </Button>
          )}
          content={() => componentRef.current}
        />
      }
    >
      {loading ? (
        <Spinner />
      ) : datavoucher ? (
        <div ref={componentRef}>
          <Details invoice={datavoucher} />
        </div>
      ) : (
        <Spinner />
      )}
    </Page>
  );
};

export default InvoiceDetails;
