import React, { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash/fp";
import useForm from "react-hook-form";
import { apiInventario } from "../../../../../config/api.contable";
import {
  Grid,
  FormHelperText,
  Typography,
  DialogActions,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  TextField,
  Chip,
} from "@material-ui/core";
import { notification } from "antd";
import Autocomplete from "@material-ui/lab/Autocomplete";

const ModalEditCategoria = (props) => {
  const { visible, setVisible, reloadFunction, data, setLoading, accounts } =
    props;
  const { register, handleSubmit, errors } = useForm();
  const [customValue, setCustomValue] = useState(null);
  const [cuentas, setCuentas] = useState([]);

  useEffect(() => {
    let finder = accounts.find(
      (el) => Number(el.number) === Number(data.account_number)
    );
    setCustomValue(finder);
    filterAccounts(data.type);
  }, [data]);

  const filterAccounts = (type) => {
    let res = [];
    if (type === "machinery") {
      res = accounts.filter(
        (el) =>
          el.number.toString().startsWith("336") ||
          el.number.toString().startsWith("337")
      );
    } else if (type === "vehicles") {
      res = accounts.filter((el) => el.number.toString().startsWith("3341"));
    } else if (type === "furniture") {
      res = accounts.filter((el) => el.number.toString().startsWith("3351"));
    } else if (type === "equipment") {
      res = accounts.filter((el) => el.number.toString().startsWith("3352"));
    } else if (type === "intangibles") {
      res = accounts.filter(
        (el) =>
          el.number.toString().startsWith("34") ||
          el.number.toString().startsWith("38")
      );
    }
    setCuentas(res);
  };

  const editCategoria = async (newData) => {
    setLoading(true);
    const dataCat = {
      name: newData.name.toUpperCase(),
      account_number: customValue.number,
      account_name: customValue.description,
    };
    await axios
      .put(`${apiInventario}/update-category/${data._id}`, dataCat)
      .then(() => {
        notification["success"]({ message: `Se actualizó correctamente.` });
        setVisible(false);
        reloadFunction();
      })
      .catch((error) => {
        notification["error"]({
          message: `No se pudo actualizar el registro.`,
          description: `${error}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog fullWidth open={visible}>
      <DialogTitle>
        <Typography variant="h3">Actualizar Categoria</Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(editCategoria)} autoComplete="off">
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                label="Nombre de categoría"
                variant="outlined"
                fullWidth
                defaultValue={data.name}
                name="name"
                inputRef={register({
                  required: true,
                  maxLength: 120,
                })}
              />
              {_.get("name.type", errors) === "required" && (
                <FormHelperText error>
                  El nombre de la categoría es requerido.
                </FormHelperText>
              )}
              {_.get("name.type", errors) === "maxLength" && (
                <FormHelperText error>
                  La longitud máxima de caracteres es 120.
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={cuentas}
                fullWidth
                getOptionLabel={(option) =>
                  option.number + " " + option.description
                }
                defaultValue={customValue}
                renderOption={(option) => (
                  <>
                    {option.number} ({option.description})
                  </>
                )}
                onChange={(event, newValue) => {
                  setCustomValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Nombre de la cuenta"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              {data.subcategory.map((el) => (
                <Chip label={el.name} color="primary" style={{ margin: 1 }} />
              ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setVisible(false)}
          >
            Cancelar
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Actualizar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalEditCategoria;
