import React, { Fragment, useEffect, useState } from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormHelperText,
  InputAdornment,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  FormControlLabel,
  Switch,
} from "@material-ui/core"
import _ from "lodash/fp"
import { makeStyles } from "@material-ui/styles"
import useForm from "react-hook-form"
import moment from "moment"
import { apiInventario } from "../../../../config/api.contable"
import axios from "axios"
import NumberFormat from "react-number-format"
import { notification, Tooltip } from "antd"
import SelectVoucherType from "src/components/Selects/SelectVoucherType"
import { customConsultRuc } from "src/utils/ruc"
import { Add } from "@material-ui/icons"
import AddSubCategory from "../Categorias/AddSubCategory"

const useStyles = makeStyles( ( theme ) => ( {
  dialog: {
    minWidth: "50%",
    maxWidth: "80%",
  },
  iconText: {
    color: "#aaa",
  },
} ) )

function NumberFormatCustom( props ) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      { ...other }
      getInputRef={ inputRef }
      onValueChange={ ( values ) => {
        onChange( {
          target: {
            name: props.name,
            value: values.value,
          },
        } )
      } }
      thousandSeparator
      isNumericString
    //prefix="S/ "
    />
  )
}

const AddEquipos = ( props ) => {
  const classes = useStyles()
  const {
    visible,
    setVisible,
    reloadFunction,
    reloadCategories,
    categories,
    idLocation,
    sede,
    company,
    setLoading,
    loading,
  } = props
  const [ aditional, setAditional ] = useState( {
    type: "BOLETA",
    serie: "",
    correlative: "",
    t_doc: "",
    n_doc: "",
    name: "",
  } )
  const { register, handleSubmit, errors } = useForm()
  const [ status, setStatus ] = useState( "BUENO" )
  const [ inputValueCat, setInputValueCat ] = useState( "" )
  const [ customValueCat, setCustomValueCat ] = useState( null )
  const [ activeAdvanced, setActiveAdvanced ] = useState( false )
  const [ subCategories, setSubCategories ] = useState( [] )
  const [ subcategory, setSubcategory ] = useState( null )
  const [ values, setValues ] = useState( {
    unit_value: "",
    historical_value: "1",
    amount: "1",
    height: "",
    length: "",
    depth: "",
  } )

  const [ modalAddSubtegory, setModalAddSubtegory ] = useState( { isOpen: false, data: null } )

  const changeAditional = ( key, value ) => setAditional( { ...aditional, [ key ]: value } )

  const addRegister = async ( data, event ) => {
    event.preventDefault()
    if ( idLocation !== null && customValueCat !== null ) {
      setLoading( true )
      try {
        if ( values.unit_value !== "" && values.historical_value !== "" && values.amount !== "" ) {
          data.category = customValueCat._id
          data.subcategory = subcategory ? subcategory : null
          data.location = idLocation
          data.status = status
          data.sede = sede
          data.unit_value = values.unit_value
          data.historical_value = values.historical_value
          data.amount = 1
          data.height = values.height
          data.length = values.length
          data.depth = values.depth
          data.type = "furniture"
          data.model = data.model.toUpperCase()
          data.trademark = data.trademark.toUpperCase()
          data.description = data.description.toUpperCase()
          data.aditional = aditional
          if ( data.height !== "" && data.length !== "" ) {
            if ( data.model !== "" || data.trademark !== "" || data.description !== "" ) {
              for ( let x = 0; x < values.amount; x++ ) {
                await axios.post( `${ apiInventario }/fixed-asset/${ sede }/${ company }`, data )
                await sleep( 200 )
              }
              notification[ "success" ]( {
                message: "El registro se guardó correctamente.",
              } )
              reloadFunction()
              setVisible( false )
            } else {
              notification[ "error" ]( {
                message: "La descripción es requerida en caso de no ingresar modelo o marca.",
              } )
              setLoading( false )
              return null
            }
          } else {
            notification[ "error" ]( {
              message: "La altura y longitud son requeridos.",
            } )
            setLoading( false )
            return null
          }
        } else {
          notification[ "info" ]( {
            message: "Campos requeridos que falta completar.",
          } )
        }
      } catch ( err ) {
        notification[ "error" ]( {
          message: "Ocurrió un error al agregar el registro.",
          description: `${ err }`,
        } )
      } finally {
        setLoading( false )
      }
    } else {
      notification[ "info" ]( {
        message: "Debe seleccionar un nombre de cuenta.",
      } )
    }
  }

  function sleep( ms ) {
    return new Promise( ( resolve ) => setTimeout( resolve, ms ) )
  }

  const handleChange = ( event ) => {
    if ( event.target.name == "unit_value" ) {
      values.historical_value = event.target.value.toUpperCase()
    }
    setValues( {
      ...values,
      [ event.target.name ]: event.target.value.toUpperCase(),
    } )
  }

  const consultRuc = async () => {
    try {
      setLoading( true )
      const res = await customConsultRuc( aditional.n_doc )
      if ( res.success ) {
        notification[ "success" ]( { message: "INFORMACIÓN ENCONTRADA" } )
        setAditional( {
          ...aditional,
          t_doc: res.t_doc,
          n_doc: res.n_doc,
          name: res.name,
        } )
      } else {
        notification[ "info" ]( {
          message: `${ aditional.t_doc } INVÁLIDO`,
          description: "Solo se permite la busqueda de RUC (11 dígitos) y DNI (8 dígitos)",
        } )
      }
    } catch ( error ) {
      setAditional( {
        ...aditional,
        name: "",
      } )
      notification[ "warning" ]( { message: "Ocurrió un error al realizar la consulta..." } )
    } finally {
      setLoading( false )
    }
  }

  useEffect( () => {
    if ( customValueCat ) {
      const findCategory = categories.find( ( item ) => item._id === customValueCat._id )
      setSubCategories( [ ...findCategory.subcategory ] )
    }
  }, [ categories ] ) // eslint-disable-line

  const handleChangeModal = ( data ) => setModalAddSubtegory( { isOpen: true, data } )

  return (
    <Dialog open={ visible } classes={ { paper: classes.dialog } }>
      <DialogTitle>
        <Typography variant="h2" component="h2" color="textPrimary" align="center">
          AGREGAR MUEBLE
        </Typography>
      </DialogTitle>
      <form onSubmit={ handleSubmit( addRegister ) } >
        <DialogContent>
          <Grid container spacing={ 2 }>
            <Grid item xs={ 12 } sm={ 6 } lg={ 7 }>
              <Autocomplete
                id="combo-box-demo"
                options={ categories }
                fullWidth
                onKeyPress={ e => { if ( e.key === 'Enter' ) e.preventDefault() } }
                getOptionLabel={ ( option ) => option.name.toUpperCase() }
                renderOption={ ( option ) => (
                  <React.Fragment>
                    <Typography component="span" variant="subtitle2">
                      { option.name.toUpperCase() }{ " " }
                      <FormHelperText component="span">
                        { option.account_number } { option.account_name }
                      </FormHelperText>
                    </Typography>
                  </React.Fragment>
                ) }
                inputValue={ inputValueCat }
                onInputChange={ ( event, newInputValue ) => {
                  setInputValueCat( newInputValue )
                } }
                onChange={ ( event, newValue ) => {
                  setCustomValueCat( newValue )
                  setSubCategories( [ ...newValue.subcategory ] )
                } }
                renderInput={ ( params ) => (
                  <TextField
                    InputLabelProps={ { shrink: true } }
                    { ...params }
                    fullWidth
                    label="Categoria"
                    variant="outlined"
                  />
                ) }
              />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } lg={ 4 }>
              <FormControl fullWidth variant="outlined">
                <InputLabel>SubCategorias</InputLabel>
                <Select
                  value={ subcategory }
                  onChange={ ( event ) => setSubcategory( event.target.value ) }
                  label="SubCategorias"
                >
                  { subCategories.map( ( el ) => (
                    <MenuItem value={ el._id }>{ el.name }</MenuItem>
                  ) ) }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } lg={ 1 } style={ { display: "flex", alignItems: "flex-end" } }>
              <Tooltip title="Crear subcategoria" placement="bottom">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={ () => handleChangeModal( customValueCat ) }
                  size="large"
                  fullWidth
                  disabled={ !inputValueCat }
                  style={ { minHeight: "55px" } }
                >
                  <Add />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } lg={ 3 }>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Estado</InputLabel>
                <Select
                  value={ status }
                  onChange={ ( event ) => setStatus( event.target.value.toUpperCase() ) }
                  label="Estado"
                >
                  <MenuItem value="BUENO">BUENO</MenuItem>
                  <MenuItem value="REGULAR">REGULAR</MenuItem>
                  <MenuItem value="DETERIORADO">DETERIORADO</MenuItem>
                  <MenuItem value="FALTA ARREGLAR">FALTA ARREGLAR</MenuItem>
                  <MenuItem value="MALOGRADO">MALOGRADO</MenuItem>
                  <MenuItem value="DESUSO">DESUSO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } lg={ 3 }>
              <TextField
                InputLabelProps={ { shrink: true } }
                label="Valor unitario *"
                fullWidth
                variant="outlined"
                name="unit_value"
                value={ values.unit_value }
                onChange={ handleChange }
                onKeyPress={ e => { if ( e.key === 'Enter' ) e.preventDefault() } }
                InputProps={ {
                  startAdornment: (
                    <InputAdornment position="start" className={ classes.iconText }>
                      S/
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                } }
              />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } lg={ 3 }>
              <TextField
                onKeyPress={ e => { if ( e.key === 'Enter' ) e.preventDefault() } }
                InputLabelProps={ { shrink: true } }
                label="Cantidad *"
                fullWidth
                variant="outlined"
                name="amount"
                disabled={ activeAdvanced ? false : true }
                value={ values.amount }
                onChange={ handleChange }
                InputProps={ {
                  endAdornment: (
                    <InputAdornment position="end" className={ classes.iconText }>
                      units
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                } }
              />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } lg={ 3 }>
              <TextField
                onKeyPress={ e => { if ( e.key === 'Enter' ) e.preventDefault() } }
                InputLabelProps={ { shrink: true } }
                label="Valor total*"
                fullWidth
                variant="outlined"
                name="historical_value"
                disabled={ true }
                value={ values.historical_value }
                onChange={ handleChange }
                InputProps={ {
                  startAdornment: (
                    <InputAdornment position="start" className={ classes.iconText }>
                      S/
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                } }
              />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } lg={ 3 }>
              <TextField
                onKeyPress={ e => { if ( e.key === 'Enter' ) e.preventDefault() } }
                InputLabelProps={ { shrink: true } }
                label="Fecha de adquisición"
                fullWidth
                variant="outlined"
                defaultValue={ moment( Date.now() ).format( "YYYY-MM-DD" ) }
                type="date"
                name="acquisition_date"
                inputRef={ register( {
                  required: true,
                } ) }
              />
              <FormHelperText error>
                { _.get( "acquisition_date.type", errors ) === "required" &&
                  "La fecha de adquisición es requerida" }
              </FormHelperText>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } lg={ 3 }>
              <TextField
                onKeyPress={ e => { if ( e.key === 'Enter' ) e.preventDefault() } }
                InputLabelProps={ { shrink: true } }
                label="Altura*"
                fullWidth
                variant="outlined"
                name="height"
                onChange={ handleChange }
                InputProps={ {
                  endAdornment: (
                    <InputAdornment position="end" className={ classes.iconText }>
                      cm
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                } }
              />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } lg={ 3 }>
              <TextField
                onKeyPress={ e => { if ( e.key === 'Enter' ) e.preventDefault() } }
                InputLabelProps={ { shrink: true } }
                label="Longitud*"
                fullWidth
                variant="outlined"
                name="length"
                onChange={ handleChange }
                InputProps={ {
                  endAdornment: (
                    <InputAdornment position="end" className={ classes.iconText }>
                      cm
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                } }
              />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } lg={ 3 }>
              <TextField
                onKeyPress={ e => { if ( e.key === 'Enter' ) e.preventDefault() } }
                InputLabelProps={ { shrink: true } }
                label="Profundidad"
                fullWidth
                variant="outlined"
                name="depth"
                onChange={ handleChange }
                InputProps={ {
                  endAdornment: (
                    <InputAdornment position="end" className={ classes.iconText }>
                      cm
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                } }
              />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } lg={ 6 }>
              <TextField
                onKeyPress={ e => { if ( e.key === 'Enter' ) e.preventDefault() } }
                InputLabelProps={ { shrink: true } }
                label="Modelo"
                fullWidth
                variant="outlined"
                name="model"
                inputRef={ register( {
                  required: false,
                  maxLength: 20,
                  // // pattern: /^[A-Za-z0-9., ]+$/i,
                } ) }
              />
              <FormHelperText error>
                { _.get( "model.type", errors ) === "required" && "El modelo es requerido." }
                { _.get( "model.type", errors ) === "maxLength" &&
                  "No debe exceder los 20 caracteres." }
                { _.get( "model.type", errors ) === "pattern" &&
                  "Solo se acepta caracteres alfabéticos y números." }
              </FormHelperText>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } lg={ 6 }>
              <TextField
                onKeyPress={ e => { if ( e.key === 'Enter' ) e.preventDefault() } }
                InputLabelProps={ { shrink: true } }
                label="Marca"
                fullWidth
                variant="outlined"
                name="trademark"
                inputRef={ register( {
                  required: false,
                  maxLength: 100,
                  // pattern: /^[A-Za-z0-9., ]+$/i,
                } ) }
              />
              <FormHelperText error>
                { _.get( "trademark.type", errors ) === "required" &&
                  "La marca comercial es requerida." }
                { _.get( "trademark.type", errors ) === "maxLength" &&
                  "No debe exceder los 100 caracteres." }
                { _.get( "trademark.type", errors ) === "pattern" &&
                  "Solo se acepta números y caracteres alfabeticos." }
              </FormHelperText>
            </Grid>
            <Grid item xs={ 12 }>
              <TextField
                onKeyPress={ e => { if ( e.key === 'Enter' ) e.preventDefault() } }
                InputLabelProps={ { shrink: true } }
                label="Descripción"
                fullWidth
                variant="outlined"
                name="description"
                inputRef={ register( {
                  required: false,
                  maxLength: 255,
                } ) }
              />
              <FormHelperText error>
                { _.get( "description.type", errors ) === "required" && "La descripción es requerida." }
                { _.get( "description.type", errors ) === "maxLength" &&
                  "No debe exceder los 255 caracteres." }
              </FormHelperText>
            </Grid>
            <Grid item xs={ 12 }>
              <FormHelperText style={ { borderBottom: "1px solid" } }>
                INFORMACIÓN ADICIONAL
              </FormHelperText>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
              <SelectVoucherType
                value={ aditional.type }
                setValue={ ( value ) => changeAditional( "type", value ) }
              />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
              <TextField
                onKeyPress={ e => { if ( e.key === 'Enter' ) e.preventDefault() } }
                InputLabelProps={ { shrink: true } }
                label="Serie"
                variant="outlined"
                fullWidth
                value={ aditional.serie }
                onChange={ ( e ) => changeAditional( "serie", e.target.value ) }
              />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
              <TextField
                onKeyPress={ e => { if ( e.key === 'Enter' ) e.preventDefault() } }
                InputLabelProps={ { shrink: true } }
                label="Correlativo"
                variant="outlined"
                fullWidth
                value={ aditional.correlative }
                onChange={ ( e ) => changeAditional( "correlative", e.target.value ) }
              />
            </Grid>
            { aditional.type !== "OTROS" && (
              <Fragment>
                <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                  <TextField
                    InputLabelProps={ { shrink: true } }
                    label="RUC o DNI (Escriba y presione el botón buscar)"
                    variant="outlined"
                    fullWidth
                    value={ aditional.n_doc }
                    onChange={ ( e ) => {
                      console.log( e.target.value )
                      changeAditional( "n_doc", e.target.value )

                      // if ( e.target.value.length === 8 || e.target.value.length === 11 ) consultRuc( e.target.value )
                    } }
                    onKeyPress={ e => {
                      if ( e.key === 'Enter' ) {
                        e.preventDefault()
                        consultRuc()
                      }
                    } }
                  // onKeyUp={ ( evt ) => evt.key === "Enter" && consultRuc() }
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <Button
                  //         variant="contained"
                  //         color="primary"
                  //         size="small"
                  //         onClick={consultRuc}
                  //       >
                  //         BUSCAR
                  //       </Button>
                  //     </InputAdornment>
                  //   ),
                  // }}
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } md={ 8 }>
                  <TextField
                    InputLabelProps={ { shrink: true } }
                    label="Nombre o razón social"
                    variant="outlined"
                    fullWidth
                    value={ aditional.name }
                    disabled
                    onChange={ ( e ) => changeAditional( "name", e.target.value ) }
                  />
                </Grid>
              </Fragment>
            ) }
          </Grid>
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            control={
              <Switch
                checked={ activeAdvanced }
                onChange={ () => {
                  setValues( {
                    ...values,
                    amount: 1,
                  } )
                  setActiveAdvanced( !activeAdvanced )
                } }
                color="primary"
              />
            }
            label="Agregado múltiple"
          />
          <Button color="secondary" variant="outlined" onClick={ () => setVisible( false ) }>
            Cerrar
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={
              customValueCat == "" || inputValueCat == "" || idLocation == "" || loading
                ? true
                : false
            }
          >
            Guardar
          </Button>
        </DialogActions>
        { categories.length > 0 || idLocation == "" ? (
          ""
        ) : (
          <Typography align="right" color="secondary">
            No se encontraron categorías registradas. Registre categorías y vuelva a intentarlo.
          </Typography>
        ) }
      </form>
      { modalAddSubtegory.isOpen && (
        <AddSubCategory
          open={ modalAddSubtegory.isOpen }
          setOpen={ setModalAddSubtegory }
          data={ modalAddSubtegory.data }
          reloadFunction={ reloadCategories }
        />
      ) }
    </Dialog>
  )
}

export default AddEquipos
