import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

const list = ["DNI", "RUC", "COMUNIDAD"];

const SelectDocTypes = ({ value, setValue }) => {
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>Tipo</InputLabel>
      <Select value={value} label="Tipo" onChange={(e) => setValue(e.target.value)}>
        {list.map((e) => (
          <MenuItem value={e} key={e}>
            {e}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectDocTypes;
